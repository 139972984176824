import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  modalContent: {
    display: "grid",
    gridTemplateColumns: "2fr 1fr",
    gridTemplateAreas: `
      "header logo"
      "message ."
      ". button"
    `,
    gridGap: "36px",
  },
  modalHeader: {
    gridArea: "header",
    fontSize: "19px",
    fontWeight: 600,
    color: "#323232",
  },
  tykWoodLogo: {
    gridArea: "logo",
  },
  successIcon: {
    color: "#74B369",
    width: "36px",
    height: "36px",
  },
  successMessage: {
    gridArea: "message",
    display: "flex",
    gap: "8px",
    color: "#34305D",
    fontSize: "16px",
    lineHeight: "16px",
  },
  okButton: {
    gridArea: "button",

    "&.root": {
      height: "32px",
      padding: "0 27px",
      border: `2px solid ${theme.palette.diagram?.purple}`,
      borderRadius: "16px",
      textTransform: "unset",
      color: theme.palette.diagram?.white,
      borderColor: theme.palette.diagram?.blue,
      background: theme.palette.diagram?.blue,
    },
  },
}));
