export const CAMPAIGN_MODAL_STEPS = {
  INFORMATION_STEP: 0,
  TRIGGER_STEP: 1,
  REWARD_STEP: 2,
  SUMMARY_STEP: 3,
};

export const CAMPAIGN_TYPE_TAGS = {
  HOLIDAYS: "Holidays",
  DOUBLE_TRIPLE: "Double / Triple",
  LOW_TRAFFIC_DAYS: "Low Traffic Days",
  SURPRISE: "Surprise",
  PRODUCT_SERVICE: "Product / Service",
  SPECIAL_EVENTS: "Special Events",
};
