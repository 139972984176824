import { FC, useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { AddressSelectionModal } from "../AddressSelectionModal";
import { useAddressSelectionModal } from "../AddressSelectionModal/useAddressSelectionModal.hook";
import { PageLoader } from "../PageLoader";
import { useCartCheckoutUrl } from "./useCartCheckoutUrl.hook";

export const UpdatePaymentMethod: FC = () => {
  const [checkoutUrl, loading, isLoaded, fetchCart] = useCartCheckoutUrl();
  const { goBack } = useHistory();
  const [addressSelectionModalProps, showAddressSelectionModal] = useAddressSelectionModal();

  useEffect(() => {
    if (!isLoaded && !loading && !addressSelectionModalProps.isActive) {
      showAddressSelectionModal(fetchCart);
    }
  }, [showAddressSelectionModal, fetchCart, addressSelectionModalProps.isActive, isLoaded, loading]);

  if (loading) {
    return <PageLoader />;
  }

  if (isLoaded) {
    return <Redirect to={checkoutUrl} push={false} />;
  }

  return <AddressSelectionModal {...addressSelectionModalProps} onClose={goBack} />;
};
