import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    experiencesTableWrapper: {
      marginTop: "20px",

      "& .experiencesTableTitle": {
        color: theme.palette.experiences.darkPurple,
        fontFamily: theme.typography.fontFamily,
        fontSize: "0.875rem",
        fontWeight: "600",
      },

      "& .MuiTableCell-head": {
        padding: "6px 10px",
        color: theme.palette.experiences.grey,
        fontFamily: theme.typography.fontFamily,
        fontSize: "0.75rem",
        lineHeight: "normal",
      },

      "& .MuiTableCell-body": {
        padding: "10px",
        color: theme.palette.black,
        fontFamily: theme.typography.fontFamily,
        fontSize: "0.75rem",
        cursor: "pointer",
        lineHeight: "normal",

        "& .experience-title-wrapper": {
          width: "200px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",

          "& .avatar-icon": {
            width: "30px",
            height: "30px",
            marginRight: "6px",
            borderRadius: "50%",
            backgroundColor: "#8986AD",
          },

          "& .experience-title": {
            display: "flex",
            flexDirection: "column",
            textAlign: "start",

            "& .main-text": {
              fontFamily: theme.typography.fontFamily,
              fontWeight: 500,
              fontSize: "0.875rem",
              color: "#12111A",
            },
            "& .extra-text": {
              fontFamily: theme.typography.fontFamily,
              fontSize: "0.6875rem",
              color: theme.palette.experiences.grey,
            },
          },
        },
      },
    },
    paginationContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "center",
      marginTop: "30px",
      [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
        justifyContent: "center",
      },

      "& .MuiTypography-root": {
        color: theme.palette.experiences.blue,
        fontSize: "0.75rem",
        textDecoration: "underline",
        cursor: "pointer",
      },
    },
  };
});

export default useStyles;
