import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    header: {
      width: "375px",
      padding: "80px 20px 40px",
      fontSize: "1rem",
      color: "#34305D",
    },
    tableContainer: {
      "& .avatar-image": {
        width: "42px",
        height: "42px",
        backgroundColor: "#8986AD",
        borderRadius: "50%",
      },

      "& .MuiTableRow-root": {
        cursor: "pointer",

        "& .MuiTableCell-body": {
          padding: "10px",
        },

        "& .MuiTableCell-body:first-child": {
          padding: "10px 6px 10px 20px",
        },

        "& .MuiTableCell-body:last-child": {
          paddingRight: "40px",
        },

        "& .chat-content": {
          width: "150px",
          maxHeight: "60px",
          "& h2": {
            color: "#34305D",
            fontSize: "0.875rem",
          },
          "& p": {
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            color: "#797882",
            fontSize: "0.875rem",
          },
        },

        "& .date-column": {
          color: "#797882",
          fontSize: "0.75rem",
        },
      },

      "& .empty-message": {
        textAlign: "center",
      },
    },
  };
});

export default useStyles;
