import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      padding: "20px 40px 40px",
      backgroundColor: "#FAFAFA",

      "& .tykIconWrapper": {
        width: "240px",
        height: "240px",
        backgroundColor: "#E7E6EC",
        borderRadius: "50%",
        marginBottom: "20px",

        [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
          width: "140px",
          height: "140px",
          "& .tykIcon": {
            height: "80px",
          },
        },
      },

      [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
        padding: "20px 20px 20px",
      },
    },
    buttonsWrapper: {
      padding: "20px 0",
      alignSelf: "center",

      "& .MuiButtonBase-root": {
        height: "32px",
        padding: "0 32px",
        fontFamily: theme.typography.fontFamily,
        fontSize: "0.875rem",
        fontWeight: 400,
        borderRadius: "16px",
        boxShadow: "none",
        textTransform: "none",
      },

      "& .MuiButtonBase-root.secondary-button": {
        marginRight: "12px",
        backgroundColor: theme.palette.white,
        border: "2px solid #747474",
        color: "#797882",
        "&:hover": {
          backgroundColor: "#74747419",
        },
      },
      "& .MuiButtonBase-root.primary-button": {
        backgroundColor: "#1473E6",
        color: theme.palette.white,
        "&:hover": {
          backgroundColor: "#203ED4",
        },
      },
    },
  };
});

export default useStyles;
