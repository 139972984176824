import styled from "@emotion/styled";

export const MainContainer = styled.div`
  padding-top: 105px;
`;

export const GoBackButtonContainer = styled.div`
  width: 90%;
  margin: 0 auto;
`;

export const GoBackButton = styled.span`
  padding: 12px;
  display: inline-block;
  cursor: pointer;

  > * {
    vertical-align: middle;
  }

  > svg {
    margin-right: 8px;
  }
`;

export const PolicyContainer = styled.div`
  margin: 0 auto;
  padding-top: 24px;
  position: relative;
  font-size: 16px;
  width: 75%;

  > div {
    width: 70%;
  }

  > svg {
    position: absolute;
    right: 0;
    top: 25px;
    opacity: 0.25;
    pointer-events: none;
    user-select: none;
  }

  @media (max-width: 1123px) {
    > div {
      width: 100%;
    }

    > svg {
      display: none;
    }
  }
`;

export const SectionTitle = styled.h3`
  font-size: 1.4rem;
`;

export const SectionList = styled.ul`
  padding-left: 1.56rem;
`;

export const SectionContent = styled.p`
  margin: 20px 0;
`;
