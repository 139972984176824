import { FC, HTMLAttributes, useMemo } from "react";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import { LineItemsSection, LineItemsSectionProps } from "./LineItemsSection";
import { TotalsSection } from "./TotalsSection";
import { useStyles } from "./styles";

export interface CartProps extends LineItemsSectionProps, HTMLAttributes<HTMLDivElement> {
  taxInCents?: number | null;
  isDraft?: boolean;
}

const useCartTotals = (lineItems: LineItemsSectionProps["lineItems"], taxInCents?: number | null) =>
  useMemo(() => {
    const subtotal = lineItems.reduce((acc, curr) => acc + curr.priceInCents, 0);
    const tax = taxInCents ?? lineItems.reduce((acc, curr) => acc + curr.taxInCents, 0);
    const total = subtotal + tax;

    return { subtotal, tax, total };
  }, [lineItems, taxInCents]);

export const Cart: FC<CartProps> = ({ lineItems, className, cartTitleId, taxInCents, isDraft, ...divAttributes }) => {
  const { subtotal, tax, total } = useCartTotals(lineItems, taxInCents);
  const styles = useStyles();

  return (
    <div className={clsx(styles.container, className)} {...divAttributes}>
      <h1 className={styles.title}>
        <FormattedMessage id={cartTitleId ?? "cart_title"} />
      </h1>
      <LineItemsSection lineItems={lineItems} />
      <TotalsSection subtotal={subtotal} tax={tax} total={total} isDraft={isDraft} />
    </div>
  );
};
