import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    rulesTable: {
      "&.MuiGrid-root": {
        width: "calc(100% + 50px)",
        marginLeft: "-50px",
      },
      "& .tableHeadRow": {
        paddingRight: "10px",
        marginBottom: "3px",
        "& .tableHeadRowContent": {
          padding: "2rem 0px 1rem 0",
          borderBottom: "1px solid #D4D4D4",

          "& .MuiBox-root:last-child": {
            paddingRight: "12px",
          },

          "& .MuiBox-root .MuiTypography-root": {
            fontSize: "0.9rem",
            color: "#797882",
          },

          "& .MuiBox-root:first-child .MuiTypography-body1": {
            color: "#12111A",
            fontWeight: "600",
          },
        },
      },
      "& .MuiTableRow-root": {
        "& .MuiTableCell-root:not(:first-child)": {
          textAlign: "center",
        },
      },
      "& .MuiTableHead-root": {
        "& .MuiTableCell-root:first-child .MuiTypography-body1": {
          color: "#12111A",
        },
        padding: 16,
      },
      "& .MuiTableBody-root": {
        "& .MuiTableCell-root": {
          padding: 0,
        },
      },
    },
    headCheckBox: {
      "&.MuiCheckbox-root": {
        color: "#797882",
        padding: "1rem 0",
        marginBottom: "2px",

        "@media(max-width: 600px)": {
          marginLeft: "20px",
        },
      },
      "& .MuiCheckbox-colorSecondary.Mui-checked": {
        color: "#4DA17E",
      },
      "& .PrivateSwitchBase-input:checked + svg.MuiSvgIcon-root": {
        fill: "#4DA17E",
      },
    },
    checkBox: {
      "& .MuiCheckbox-colorSecondary.Mui-checked": {
        color: "#4DA17E",
      },
      "& .MuiCheckbox-root": {
        color: "#797882",
      },
      "& .PrivateSwitchBase-input:checked + svg.MuiSvgIcon-root": {
        fill: "#4DA17E",
      },
    },
    ruleControls: {
      color: "#4262FF",
      fontSize: "1rem",
      fontWeight: 600,
      transition: "all 300ms ease-in-out",
      position: "relative",
    },
  };
});

export default useStyles;
