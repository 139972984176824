import { FC } from "react";
import { Divider, Grid } from "@mui/material";
import { Info } from "@mui/icons-material";
import useStyles from "./styles";
import { Winner } from "../types";

interface WinnerDetailsProps {
  winner: Winner;
}

const WinnerDetails: FC<WinnerDetailsProps> = ({ winner }) => {
  const { detailsContainer } = useStyles();

  return (
    <Grid className={detailsContainer}>
      <div className="title-wrapper">
        <Info />
        <h2 className="title">Winner details</h2>
      </div>

      <Divider />

      <h4 className="winner-name">{winner.name}</h4>

      <Divider />

      <div className="section-item">
        <h4 className="section-value">Jackpot settings</h4>
      </div>

      <div className="section-item">
        <h4 className="section-label">Audience</h4>

        <p className="section-value">General</p>
      </div>

      <div className="section-item">
        <p className="section-label">Chances</p>

        <h4 className="section-value">2</h4>
      </div>

      <div className="section-item">
        <p className="section-label">Release</p>

        <h4 className="section-value">Amount 395</h4>
      </div>

      <div className="section-item">
        <p className="section-label">Release date:</p>

        <h4 className="section-value">Oct 9th, 2021</h4>
      </div>
    </Grid>
  );
};

export default WinnerDetails;
