import { FC } from "react";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import { Grid } from "@mui/material";
import withTabsBillingPaymentsLayout from "./HOCs/withTabsBillingPaymentsLayout";
import { Billing } from "./Billing";
import { Payments } from "./Payments";
import { Routes } from "../../constants";

export const BillingPayments: FC = () => {
  const { path } = useRouteMatch();

  return (
    <Grid>
      <Switch>
        <Route path={`${path}${Routes.PAYMENTS}`} component={withTabsBillingPaymentsLayout(Payments)} />
        <Route exact path={Routes.BILLING} component={withTabsBillingPaymentsLayout(Billing)} />
        <Route>
          <Redirect to={Routes.BILLING} />
        </Route>
      </Switch>
    </Grid>
  );
};
