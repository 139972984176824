import { FC, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import SimpleAdvertisingBust from "../../../assets/images/simpleAdvertisingBust@2x.png";
import AdvancedAdvertisingBust from "../../../assets/images/advancedAdverstisingBust@2x.png";
import AdManagerRedirectionModal from "../AdManagerRedirectionModal";
import useStyles from "./styles";

const PromotionTypes: FC = () => {
  const { promotionContainer } = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSimplePromotionClick = () => {
    history.push({
      pathname: `${location.pathname}/Simple`,
    });
  };

  return (
    <Grid className={promotionContainer}>
      <Typography className="promotion-title">
        <FormattedMessage id="marketing.promotion_type.title" />
      </Typography>

      <Grid className="promotion-container">
        <Grid className="promotion-item-container" onClick={handleSimplePromotionClick}>
          <Box className="promotion-item-header">
            <img src={SimpleAdvertisingBust} alt="simple-advertising-logo" />

            <div className="title-wrapper">
              <h4>
                <FormattedMessage id="marketing.promotion_type.simple_advertising" />
              </h4>
            </div>
          </Box>

          <p className="promotion-item-description">
            <FormattedMessage id="marketing.promotion_type.advertise" />
          </p>
        </Grid>

        <Grid className="promotion-item-container" onClick={() => setIsModalOpen(true)}>
          <Box className="promotion-item-header">
            <img src={AdvancedAdvertisingBust} alt="advanced-advertising-logo" />
            <div className="title-wrapper">
              <h4>
                <FormattedMessage id="marketing.promotion_type.advanced_advertising" />
              </h4>
            </div>
          </Box>

          <p className="promotion-item-description">
            <FormattedMessage id="marketing.promotion_type.skyrocket_your_campaigns" />
          </p>
        </Grid>
      </Grid>

      <AdManagerRedirectionModal isOpen={isModalOpen} onClose={handleCloseModal} />
    </Grid>
  );
};

export default PromotionTypes;
