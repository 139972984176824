import { Campaign } from "./types";
import dummyImage from "../../assets/images/NoPath.png";

export const campaigns: Campaign[] = [
  {
    id: "0",
    name: "Marlins Vs. Pittsburg...",
    image: dummyImage,
    views: 200,
    clicks: 500,
    spent: 1540,
  },
  {
    id: "1",
    name: "Marlins Vs. Pittsburg...",
    image: dummyImage,
    views: 200,
    clicks: 500,
    spent: 1540,
  },
  {
    id: "2",
    name: "Marlins Vs. Pittsburg...",
    image: dummyImage,
    views: 200,
    clicks: 500,
    spent: 1540,
  },
];
