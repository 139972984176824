import { FC, useMemo } from "react";
import ReactECharts from "echarts-for-react";
import { IEChartsOption } from "../../../types/charts";
import { Values } from "../types";

interface AnalyticsChartProps {
  values: Values;
  getOptions: (values: Values, height?: number) => IEChartsOption;
  height?: number;
}

const AnalyticsChart: FC<AnalyticsChartProps> = ({ values, getOptions, height = 160 }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedOptions = useMemo(() => getOptions(values, height), [values, height]);

  return <ReactECharts option={memoizedOptions} style={{ height, width: "100%" }} />;
};

export default AnalyticsChart;
