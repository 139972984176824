export enum CardMessageStatus {
  Invalid = "Invalid",
  Empty = "Empty",
  Complete = "Complete",
}

export enum CardElementType {
  CardNumber = "cardNumber",
  CardExpiry = "cardExpiry",
  CardCvc = "cardCvc",
}

export interface CardInputsMessageStatus {
  cardNumberMessageStatus: CardMessageStatus | null;
  cardExpiryMessageStatus: CardMessageStatus | null;
  cardCvcMessageStatus: CardMessageStatus | null;
  cardCardholderMessageStatus: CardMessageStatus | null;
}

export interface CustomInputStyleOptions {
  placeholder: string;
  style: {
    base: {
      fontSize: string;
      fontWeight: string;
      "::placeholder": {
        color: string;
        fontWeight: string;
      };
    };
  };
}

export interface EventStripe {
  complete?: boolean;
  brand?: string;
  elementType: string;
  empty?: boolean;
  error?: {
    code: string;
    message: string;
    type: string;
  };
}
