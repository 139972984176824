import { FC, useCallback, useState, KeyboardEvent } from "react";
import { ButtonBase, Divider, Grid, Modal } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import WinnersTable from "./WinnersTable";
import { Views, Winner } from "./types";
import ChatsTable from "./ChatsTable";
import WinnerDetails from "./WinnerDetails";
import Chat from "./Chat";
import useStyles from "./styles";
import { winners } from "./data";

interface WinnersHistoryProps {
  isActive: boolean;
}

const WinnersHistory: FC<WinnersHistoryProps> = ({ isActive }) => {
  const { openHistoryButtonContainer, modal, modalContent } = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentView, setCurrentView] = useState(Views.MESSAGES);
  const [selectedWinnerData, setSelectedWinnerData] = useState<Winner | null>(null);

  const resetCurrentViewState = () => {
    setSelectedWinnerData(null);
    setCurrentView(Views.MESSAGES);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    resetCurrentViewState();
    setIsModalOpen(false);
  };

  const handleCloseEscape = (e: KeyboardEvent) => {
    if (e.keyCode) {
      handleCloseModal();
    }
  };

  const handleChangeView = useCallback((data, view) => {
    setSelectedWinnerData(data);
    setCurrentView(view);
  }, []);

  const BackToMessagesButton = () => (
    <ButtonBase className="backto-button" onClick={resetCurrentViewState}>
      <ArrowBack />
      <p className="backto-text">Back to messages</p>
    </ButtonBase>
  );

  const renderView = () => {
    if (!selectedWinnerData) {
      return <ChatsTable winners={winners} onChangeView={handleChangeView} />;
    }

    switch (currentView) {
      case Views.CHAT:
        return <Chat chatsData={selectedWinnerData} />;
      case Views.DETAILS:
        return <WinnerDetails winner={selectedWinnerData} />;
      default:
        return <ChatsTable winners={winners} onChangeView={handleChangeView} />;
    }
  };

  return (
    <>
      <div className={openHistoryButtonContainer}>
        <ButtonBase
          onClick={handleOpenModal}
          className={isActive ? "active" : ""}
          disabled={!isActive}
        >
          Winner history
        </ButtonBase>
      </div>

      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        className={modal}
        onKeyPress={handleCloseEscape}
      >
        <Grid className={modalContent}>
          <Grid className="left-section">
            <div className="left-section-header">
              <div className="left-section-header__title left">
                <h1>Winner History</h1>
                <h3>A complete list of your Jackpot winners</h3>
              </div>

              <div className="left-section-header__title">
                <h1>4</h1>
                <h3>Total Jackpot released</h3>
              </div>
            </div>

            <div className="left-section-body">
              <WinnersTable winners={winners} onChangeView={handleChangeView} />
            </div>
          </Grid>

          <Divider orientation="vertical" />

          <Grid className="right-section">
            <div className="right-section-header">
              {currentView !== Views.MESSAGES && selectedWinnerData && <BackToMessagesButton />}
            </div>

            <div className="right-section-content">{renderView()}</div>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default WinnersHistory;
