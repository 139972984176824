import { makeStyles } from "@mui/styles";
import theme from "../../../../theme";

const useStyles = makeStyles(() => ({
  qrForm: {
    padding: `0 ${theme.spacing(6)} ${theme.spacing(5)}`,
    [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
      padding: 0,
    },
    "& .mainContent": {
      backgroundColor: theme.palette.technology.white,
      width: "100%",
      padding: `${theme.spacing(3)} ${theme.spacing(8)}`,
      [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
        padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
      },
    },
    "& .buttonArea": {
      display: "flex",
      justifyContent: "flex-end",
      maxWidth: "915px",
      marginBottom: "20px",
    },

    "& .btn": {
      height: "31px",
      width: "89px",
      borderRadius: "20px",
      boxShadow: "none",
    },
    "& .btn .MuiTypography-root": {
      fontSize: "0.75rem",
      textTransform: "none",
    },

    "& .cancle-btn": {
      border: `2px solid ${theme.palette.purple}`,
      color: theme.palette.technology.lightGrey1,
      "&:hover": {
        backgroundColor: theme.palette.technology.lightGrey2,
      },
    },
    "& .next-btn": {
      backgroundColor: theme.palette.technology.blue,
      "&:hover": {
        backgroundColor: theme.palette.technology.darkBlue,
      },
    },
    "& .remove-btn": {
      height: "40px",
      width: "156px",
      backgroundColor: theme.palette.technology.red,
      color: theme.palette.technology.white,
      "&:hover": {
        backgroundColor: theme.palette.technology.lightRed,
      },
    },
    "& .remove-btn .MuiTypography-root": {
      fontWeight: 700,
    },
    "& .input": {
      width: "100%",
      height: "32px",
      padding: theme.spacing(1),
      boxSizing: "border-box",
    },
    "& .MuiTypography-root": {
      fontFamily: `${theme.typography.fontFamily}`,
    },
  },
}));

export { useStyles };
