import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    brokerSelectionModal: {
      "& .modal": {
        padding: `${theme.spacing(6)} ${theme.spacing(6)} ${theme.spacing(4)}`,
      },

      "& .modal-title": {
        display: "flex",
        justifyContent: "space-between",
        paddingBottom: theme.spacing(1),
        "& .modal-title-text": {
          fontSize: "1.25rem",
          fontWeight: 700,
        },
      },

      "& .modal-content": {
        padding: `${theme.spacing(2)} 0 ${theme.spacing(3)}`,
      },

      "& .btn": {
        width: "150px",
        fontWeight: 700,
        borderRadius: "2rem",
        textTransform: "none",
      },

      "& .cancel-btn": {
        backgroundColor: "#C8000000",
        border: "2px solid #747474",
        color: "#747474",
        "&:hover": {
          backgroundColor: "#47474712",
        },
      },

      "& .select-btn": {
        backgroundColor: "#1473E6",
        color: theme.palette.white,
        "&:hover": {
          backgroundColor: "#203ED4",
        },
        "&:disabled": {
          opacity: "0.5",
        },
      },

      "& .loading-indicator": {
        left: "18px",
        color: "#fff",
      },
    },
  };
});

export default useStyles;
