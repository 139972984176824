import { makeStyles } from "@mui/styles";
import happyGirls from "../../assets/images/happyGirls.png";

export const useStyles = makeStyles(() => ({
  parent: {
    backgroundColor: "#fafafa",
    minHeight: "100vh",
    paddingTop: "99px",
    "& .MuiTypography-root": {
      fontFamily: "Poppins",
    },
  },
  enrollBtn: {
    "&.MuiButton-root": {
      backgroundColor: "#0364FF",
      color: "#fff",
      textTransform: "none",
      borderRadius: 10,
      fontFamily: "Poppins",
      fontSize: "0.9rem",
      padding: "0.6rem 2.5rem",
      marginTop: "3rem",
      maxWidth: "300px",
      height: "50px",
      "&:hover": {
        backgroundColor: "#F6C40E",
      },
    },
  },
  createBusiness: {
    border: "1px solid #203ED4",
    color: "#203ED4",
    padding: "1rem 2rem",
    borderRadius: "10px",
    justifyContent: "space-around",
    marginTop: "2.5rem",

    "&.noProfileFound": {
      position: "relative",
      padding: "118px 0 1rem",
      maxWidth: "110px",
      border: "none",
      color: "#3E3E3E",
      cursor: "pointer",

      "& .MuiBadge-root": {
        height: "110px",
        width: "110px",
        borderRadius: "50%",
        backgroundColor: "#5972FE",
        position: "absolute",
        top: "0px",
        left: "50%",
        transform: "translateX(-50%)",
        display: "flex",
        flexDirection: "column-reverse",
        alignItems: "center",
        justifyContent: "center",

        "& > *": {
          position: "relative",
          top: "unset",
          left: "unset",
          transform: "unset",
        },

        "& .MuiBadge-anchorOriginTopLeftRectangular": {
          border: "none",
          backgroundColor: "transparent",
          marginBottom: ".3rem",

          "&::before, &::after": {
            height: "3px",
            width: "20px",
            borderRadius: "1px",
            backgroundColor: "#E3E3E3",
          },
        },

        "& .MuiSvgIcon-root": {
          fill: "#E3E3E3",
          color: "#E3E3E3",
        },
      },
    },

    "& .MuiBadge-anchorOriginTopLeftRectangular": {
      top: "3px",
      left: "3px",
      backgroundColor: "#203ED4",
      border: "2px solid #fefefe",
      color: "transparent",
      height: "20px",
      width: "20px",

      "&::before": {
        content: '""',
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        display: "block",
        height: "1px",
        width: "7px",
        backgroundColor: "#fefefe",
      },
      "&::after": {
        content: '""',
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%) rotate(90deg)",
        display: "block",
        height: "1px",
        width: "7px",
        backgroundColor: "#fefefe",
      },
    },
  },
  createProfileButton: {
    display: "flex",
    justifyContent: "center",
    gap: "1rem",
    alignItems: "center",
    color: "#4262FF",
    width: "22.75rem",
    height: "3.8rem",
    border: "1px solid #203FD4",
    borderRadius: "5px",
    padding: "0 1rem",
    backgroundColor: "#fff",
    cursor: "pointer",

    "&:hover": {
      backgroundColor: "#4262FF",
      color: "#fff",
      "& svg": {
        fill: "#fff",
        "& path": {
          fill: "#fff",
        },
        "& g": {
          fill: "#fff",
        },
      },
    },
  },
  imageChild: {
    padding: "7.75rem",
    color: "#C9D7F4",
    background: `no-repeat center/100% url(${happyGirls})`,
    backgroundSize: "cover",
  },
  logoContainer: {
    padding: "0 5rem",
    backgroundColor: "#FAFAFA",
    "@media all and (max-width: 700px)": {
      padding: "5rem",
    },
  },
  businessLabel: {},
  business: {
    "&.MuiGrid-root": {
      margin: "0 1rem",
    },
    padding: "36px 24px",
    borderRadius: 8,
    userSelect: "none",
    cursor: "pointer",
    maxWidth: 210,

    "&.disabled": {
      pointerEvents: "none",
    },

    "&.selected": {
      color: "#4567f6",
      backgroundColor: "#F0F0F0",
      "& .imageWrapper": {
        outline: "#cbd7f1 solid 8px",
        borderColor: "#4567f6",
      },
      "& .textWrapper > svg .color0": { fill: "#4567f6" },
      "& .textWrapper > svg .color1": { fill: "#cbd7f1" },
      "& .textWrapper > svg .color2": { fill: "#4567f6" },
    },
    "&.unselected": {
      "&:hover": {
        "& .imageWrapper": {
          backgroundColor: "#e3eaf8",
          borderColor: "#97a3e4",
          "& > img": {
            opacity: 0.53,
          },
        },
      },
    },
    "& .textWrapper": {
      marginTop: 16,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    "& .imageWrapper": {
      backgroundColor: "#fff",
      border: "2px solid #CDCCD7",
      borderRadius: "50%",
      padding: "20px",
      height: "150px",
      width: "150px",
      overflow: "hidden",
      transition: "background-color 0.2s, opacity 0.2s",

      "& > img": {
        height: "100%",
        width: "100%",
        objectFit: "cover",
        borderRadius: "100%",
      },
    },
  },
  continueWithSelectedProfile: {
    display: "flex",
    justifyContent: "center",
    gap: "1rem",
    alignItems: "center",
    width: "22.75rem",
    height: "3.8rem",
    color: "#fff",
    backgroundColor: "#4262FF",
    borderRadius: "5px",
    padding: "0 1rem",
    cursor: "pointer",
    transition: "background-color 0.2s, opacity 0.2s",

    "&[disabled]": {
      pointerEvents: "none",
      cursor: "default",
      opacity: 0.8,
    },

    "&:hover": {
      backgroundColor: "#203ED4",
    },
  },
  noBusinessProfileFound: {
    backgroundColor: "#EEEEEE",
    borderRadius: "6px",
    width: "100%",
    padding: "1rem 5rem",

    "& .MuiSvgIcon-root": {
      height: "4rem",
      width: "4rem",
      fill: "#BFBEBE",
      marginRight: "2rem",
    },

    "& .MuiTypography-root": {
      color: "#808080",
      display: "inline",
      margin: ".25em",
    },
  },
}));
