import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    wrap: {
      overflowX: "auto",
      "& .rowWrap": {
        display: "grid",
        gridTemplateColumns:
          "30px minmax(50px, 1fr) minmax(113px, 1fr) minmax(134px, 1fr) minmax(160px, 1fr) 88px 63px",
        alignItems: "center",
        flexGrow: "1",
        cursor: "pointer",
        marginRight: "-20px",
        "& > .MuiRadio-root, & > .MuiTypography-root, & > .MuiGrid-root": {
          paddingRight: "20px",
        },
        "& .power": {
          fill: theme.palette.white,
        },

        "& .more": {
          position: "relative",
          "& .content": {
            position: "absolute",
            top: "30px",
            right: "50px",
            opacity: "0",
            visibility: "hidden",
            background: theme.palette.analytics?.lightGrey,

            transition: "300ms ease-in-out",

            "&.active": {
              opacity: "1",
              visibility: "visible",
            },
          },

          "& .moreDecor": {
            position: "relative",
            height: "60px",
            cursor: "pointer",
            "& .moreItem": {
              position: "absolute",
              left: "50%",
              transform: "translate(-50%, -50%)",
              height: "4px",
              width: "4px",
              borderRadius: "100px",
              backgroundColor: theme.palette.analytics?.grey,
            },
          },
        },
      },

      "& .rowWrap.head": {
        alignItems: "end",
        marginLeft: "0",
        "& .MuiTypography-root": {
          height: "max-content",
          color: theme.palette.diagram?.purple,
          fontSize: "0.75rem",
          borderBottom: `1px solid ${theme.palette.analytics?.grey}`,
          paddingBottom: "7px",
        },
      },
      "& .MuiButtonBase-root": {
        color: theme.palette.diagram?.grey,
      },
      "& .selected": {
        "& .MuiButtonBase-root": {
          color: theme.palette.blue,
        },
      },

      "& .selected.actionOn": {
        "& .power": {
          background: theme.palette.analytics?.lightGreen,
        },
      },
    },
  };
});

export default useStyles;
