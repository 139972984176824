import { FC, useEffect, useState } from "react";
import { Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Box } from "@mui/material";
import { FormattedMessage } from "react-intl";
import Centor from "../../../../assets/icons/tykIcons/Centor-icon@2x.png";
import LoneStar from "../../../../assets/icons/tykIcons/LoneStar-icon@2x.png";
import { ExclamationTriangle } from "../../../../utils/icon";
import AnalyticsCard from "../../AnalyticsCard";
import ViewDetailsButton from "../ViewDetailsButton";
import { rules as data, Rule } from "./data";
import theme from "../../../../theme";
import useStyles from "../Campaigns/styles";

const Rules: FC = () => {
  const { tableContainer, emptyStateWrap } = useStyles(true)();
  const [rules, setRules] = useState<{
    count: number;
    rows: Rule[];
  } | null>(null);
  useEffect(() => {
    setRules(data);
  }, []);

  return (
    <AnalyticsCard
      headerTitle="analytics.rules.title"
      headerSubtitle="analytics.rules.sub_title"
      cardColor={rules?.rows?.length ? theme.palette.white : "#F8F8F8"}
      dataLength={rules?.count || 0}
      withDivider={false}
    >
      <TableContainer className={tableContainer}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead className="tableHeadRow">
            <TableRow>
              <TableCell>
                <FormattedMessage id="analytics.rules.table_names.name" />
              </TableCell>

              <TableCell align="right">
                <FormattedMessage id="analytics.rules.table_names.spent" />
              </TableCell>

              <TableCell>
                <FormattedMessage id="analytics.rules.table_names.distributed_tyks" />
              </TableCell>

              <TableCell>
                <FormattedMessage id="analytics.rules.table_names.behavior" />
              </TableCell>

              <TableCell>
                <FormattedMessage id="analytics.rules.table_names.details" />
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rules &&
              rules.rows.length > 0 &&
              rules.rows.slice(0, 4).map((rule) => (
                <TableRow key={rule.id} className="tableBodyRow">
                  <TableCell>{rule.name}</TableCell>

                  <TableCell align="right">{`$${rule.spent?.toLocaleString()}`}</TableCell>

                  <TableCell>
                    <Box display="flex" flexDirection="row" marginRight="10px">
                      <Box display="flex" flexDirection="row" alignItems="flex-end" marginRight="16px">
                        <Typography
                          fontFamily={theme.typography.fontFamily}
                          fontSize="0.75rem"
                          fontWeight="bold"
                          marginRight="6px"
                          minWidth="24px"
                        >
                          {rule.distributed?.tyks.toLocaleString()}
                        </Typography>

                        <img src={Centor} alt="Tyk" height={20} />
                      </Box>

                      <Box display="flex" flexDirection="row" alignItems="flex-end">
                        <Typography
                          fontFamily={theme.typography.fontFamily}
                          fontSize="0.75rem"
                          fontWeight="bold"
                          marginRight="6px"
                          minWidth="20px"
                        >
                          {rule.distributed?.loneStars.toLocaleString()}
                        </Typography>

                        <img src={LoneStar} alt="Tyk" height={20} />
                      </Box>
                    </Box>
                  </TableCell>

                  <TableCell>
                    <img src={rule.behavior} alt="" height={20} />
                  </TableCell>

                  <TableCell>
                    <ViewDetailsButton detailsData={rule} isRules />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {!rules && (
        <Box className={emptyStateWrap}>
          <ExclamationTriangle fill="#818FD4" />

          <Typography marginLeft="10px" color="#797882" fontFamily={theme.typography.fontFamily} fontSize="0.75rem">
            <FormattedMessage id="analytics.rules.no_rules" />
          </Typography>
        </Box>
      )}
    </AnalyticsCard>
  );
};

export default Rules;
