import promotionImage from "../../assets/images/suggestedExperiences/6.png";
import { Promotions } from "./types";

export const rows: Promotions[] = [
  {
    id: 1,
    promotionImage,
    promotionName: "This is a really Great Campaign",
    type: "Simple Promotion",
    startDate: "Jan 3",
    endDate: "Jan 30",
    spent: 180,
    budget: 500,
    ads: 4,
    views: 2500,
    clicks: 50,
    promotions: [
      {
        id: 1,
        promotionImage,
        promotionName: "This is a really Great Campaign",
        type: "Campaign",
        startDate: "Jan 3",
        endDate: "Jan 30",
        spent: 180,
        budget: 500,
        views: 2500,
        clicks: 50,
      },
      {
        id: 2,
        promotionImage,
        promotionName: "Business Active Jackpot",
        type: "Jackpot",
        startDate: "Jan 3",
        endDate: "Jan 30",
        spent: 180,
        budget: 500,
        views: 2500,
        clicks: 50,
      },
      {
        id: 3,
        promotionImage,
        promotionName: "This is a really Great Experience",
        type: "Experience",
        startDate: "Jan 3",
        endDate: "Jan 30",
        spent: 180,
        budget: 500,
        views: 2500,
        clicks: 50,
      },
    ],
  },
  {
    id: 2,
    promotionImage,
    promotionName: "This is a really Great Campaign",
    startDate: "Jan 3",
    endDate: "Jan 30",
    spent: 180,
    budget: 500,
    ads: 2,
    views: 2500,
    clicks: 250,
  },
  {
    id: 3,
    promotionImage,
    promotionName: "This is a really Great Campaign",
    startDate: "Jan 3",
    endDate: "Jan 30",
    spent: 180,
    budget: 500,
    ads: 1,
    views: 2500,
    clicks: 300,
  },
];
