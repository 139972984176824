import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  tabsContainer: {
    position: "relative",
    maxWidth: "100% !important",
    paddingTop: "28px",
    padding: `0 ${theme.spacing(6)}`,
    [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
      padding: "0 12px",
    },

    "& .MuiTabs-scroller": {
      [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
        overflowX: "scroll !important",
      },
    },

    msOverflowStyle: "none",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },

    "& .MuiTabs-indicator": {
      backgroundColor: theme.palette.blue,
    },

    "& .MuiTab-root": {
      fontFamily: theme.typography.fontFamily,
      fontWeight: 600,
      color: theme.palette.black,
      textTransform: "none",
      opacity: 0.5,

      "&:hover": {
        color: theme.palette.black,
        opacity: 1,
      },

      "&:focus": {
        color: theme.palette.black,
        opacity: 1,
      },
    },

    "& .Mui-selected": {
      color: theme.palette.black,
      borderBottom: `2px solid ${theme.palette.blue}`,
      opacity: 1,
    },
  },
}));

export default useStyles;
