import { Redirect, Route, Switch } from "react-router-dom";

import { Box, CircularProgress } from "@mui/material";
import useStyles from "./styles";
import Dashboard from "../../Dashboard/index";
import Rewards from "../../Rewards";
import { Locations } from "../../Locations";
import Membership from "../../Membership";
import { MembershipSummary } from "../../Membership/MembershipSummary";
import PaymentSuccess from "../../Membership/PaymentSuccess";
import Analytics from "../../Analytics";
import Marketing from "../../Marketing";
import Technology from "../../Technology";
import { BillingPayments } from "../../BillingPayments";
import PromotionTypes from "../../Marketing/PromotionTypes";
import SimpleAdvertising from "../../Marketing/SimpleAdvertising";
import AdvertisementSummary from "../../Marketing/AdvertisementSummary";
import AbsoluteRedirect from "../../Routing/AbsoluteRedirect";
import ErrorPage from "../../ErrorPage";
import { CheckoutPage } from "../../CheckoutPage";

import { Routes } from "../../../constants";
import { useBusinessProfile } from "../../../hooks/useBusinessProfile.hook";
import { UpdatePaymentMethod } from "../../UpdatePaymentMethod";
import { TykChaseMembershipNotification } from "../../TykChaseMembershipNotification";

const Main = (props) => {
  const { content } = useStyles(props);

  const [businessProfile, isLoading, isSubscribed] = useBusinessProfile();

  if (isLoading) {
    return (
      <Box width="100vw" height="100vh" display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }
  // FIXME: Routes should not use space as separator, these should be changed to either hyphen (-) or underscore (_). Reafd https://stackoverflow.com/a/497972/2411798
  return (
    <main className={content}>
      <TykChaseMembershipNotification businessProfileInfo={businessProfile} />
      <Switch>
        <Route exact path={Routes.DASHBOARD} component={Dashboard} />
        <Route exact path={Routes.MEMBERSHIPS} component={Membership} />
        <Route exact path={Routes.YOUR_REWARDS} component={Rewards} />
        <Route exact path={Routes.LOCATIONS}>
          {isSubscribed ? <Locations /> : <Redirect to={Routes.MEMBERSHIPS} />}
        </Route>
        <Route path={Routes.ANALYTICS} component={Analytics} />
        <Route exact path={Routes.MARKETING} component={Marketing} />
        <Route path={Routes.TECHNOLOGY} component={Technology} />

        <Route exact path="/Memberships/:membership" component={MembershipSummary} />
        <Route exact path="/Memberships/:membership/payment-success" component={PaymentSuccess} />
        <Route exact path="/Marketing/Promotions" component={PromotionTypes} />
        <Route exact path="/Marketing/Promotions/Simple" component={SimpleAdvertising} />
        <Route exact path="/Marketing/Promotions/Simple/Summary" component={AdvertisementSummary} />
        <Route exact path="/Marketing/Promotions/Simple/Summary/Payment Success" component={PaymentSuccess} />

        <Route exact path="/checkout/update-payment-method" component={UpdatePaymentMethod} />
        <Route exact path="/checkout/:cartId" component={CheckoutPage} />

        <Route exact path="/Error" component={ErrorPage} />
        <Route exact path="/" render={() => <AbsoluteRedirect to="/enroll" />} />
        <Route path={Routes.BILLING} component={BillingPayments} />
      </Switch>
    </main>
  );
};

export default Main;
