import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    rootContainer: {
      backgroundColor: "#F2F2F2",
      minHeight: "100vh",
      paddingTop: 0,
    },
  };
});

export default useStyles;
