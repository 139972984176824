import { Grid, Typography, Divider, Button } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { FormattedMessage } from "react-intl";
import { useStyles } from "./styles";
import Icon from "../../utils/icon";
import Header from "../Layout/HeaderAlternative";

const Enroll = () => {
  const { parent, imageChild, logoContainer, enrollBtn } = useStyles();
  return (
    <Grid container className={parent}>
      <Header />
      <Grid container justifyContent="center" direction="column" item md={5} className={logoContainer}>
        <Typography
          style={{
            marginBottom: "2rem",
            marginTop: "-2rem",
            fontSize: "3.125rem",
            fontWeight: 600,
            lineHeight: "4rem",
          }}
        >
          <FormattedMessage id="enroll.welcome_page.keep_customers" />
        </Typography>
        <Typography variant="h6" color="#797882">
          <FormattedMessage id="enroll.welcome_page.game_changing_rewards_program" />
        </Typography>
        <Button className={enrollBtn} component={Link} to="/choose-business">
          <FormattedMessage id="enroll.welcome_page.enroll_in_tykchase" />
        </Button>
      </Grid>
      <Grid container item md={7} className={imageChild} direction="column" justifyContent="center" alignSelf="stretch">
        <Grid container alignItems="center" justifyContent="space-between">
          <Typography variant="h3" style={{ lineHeight: "3rem" }}>
            <FormattedMessage id="enroll.welcome_page.retain_your_customers" />
          </Typography>
          <Icon.Recycle />
        </Grid>
        <Divider
          style={{
            backgroundColor: "#C9D7F4",
            margin: "2rem 0",
          }}
        />
        <Typography>
          <FormattedMessage id="enroll.welcome_page.description" />
        </Typography>
        <Grid container item direction="row" my=".3rem">
          <ArrowUpwardIcon style={{ fontSize: 44 }} />
          <Typography variant="h3">20%</Typography>
        </Grid>
        <Typography>
          <FormattedMessage id="enroll.welcome_page.customer_retention" />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Enroll;
