import { useState } from "react";
import { Grid, Tooltip, Typography } from "@mui/material";

import { useIntl } from "react-intl";
import useStyles from "./styles";

import { ReactComponent as RewardDistributionIcon } from "../../../assets/icons/tykIcons/rewardDistribution.svg";
import RewardDistributionModal from "../Modals/RewardDistributionModal";
import { IRewardDistribution } from "./types";
import { FeatureTooltip, featureTooltipStyleProps } from "../../Tooltips";
import { useFeatureFlagValue } from "../../../hooks";
import { FeatureFlagEnum } from "../../../types/contexts";
import { YourRewardsItems } from "../../../types/yourRewards";
import { TooltipType } from "../../../types/tooltips";

const RewardDistribution = ({ customTykAwardsDistribution, handleOnRewardDistributionSave }: IRewardDistribution) => {
  const { wrap } = useStyles();
  const intl = useIntl();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isRewardDistributionV2Enabled = useFeatureFlagValue(FeatureFlagEnum.REWARD_DISTRIBUTION_V2_ENABLED);

  const rewardDistributionHandler = () => {
    if (isRewardDistributionV2Enabled) {
      setIsModalOpen(true);
    }
  };

  return (
    <Grid className={wrap}>
      <Grid className="row">
        <RewardDistributionIcon />
        <Tooltip
          arrow
          title={!isRewardDistributionV2Enabled && <FeatureTooltip type={TooltipType.COMING_SOON} />}
          placement="bottom"
          componentsProps={featureTooltipStyleProps}
        >
          <Typography
            onClick={rewardDistributionHandler}
            className={isRewardDistributionV2Enabled ? "enabled" : "disabled"}
          >
            {YourRewardsItems(intl).REWARD_DISTRIBUTION}
          </Typography>
        </Tooltip>
      </Grid>

      <RewardDistributionModal
        reward="3"
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        customTykAwardsDistribution={customTykAwardsDistribution}
        handleOnRewardDistributionSave={handleOnRewardDistributionSave}
      />
    </Grid>
  );
};

export default RewardDistribution;
