import { FC } from "react";
import { Grid, Typography } from "@mui/material";

import { useIntl } from "react-intl";

import useStyles from "./styles";

import { ReactComponent as PurchasedIcon } from "../../../../../assets/icons/dashboard/purchased-icon.svg";
import { ReactComponent as ScannedReceiptIcon } from "../../../../../assets/icons/dashboard/Scanned-receipt-icon.svg";
import { ReactComponent as AverageReceiptIcon } from "../../../../../assets/icons/dashboard/average-receipt-icon.svg";
import { ReactComponent as TotalScannedIcon } from "../../../../../assets/icons/dashboard/Total-Scanned-icon.svg";

import { ScannedReceiptsPayload } from "../../../../../generated/types";
import { MainWidgetTitles, MainWidgetItem } from "../../../../../types/dashboard";

import { CURRENCY_DISPLAY_NARROW_SYMBOL, formatDollars } from "../../../../shared/PriceFormatters";

interface MainWidgetProps {
  items?: MainWidgetItem[];
  receipts?: ScannedReceiptsPayload;
}

export const defaultElements = (intl) => {
  return [
    {
      icon: <ScannedReceiptIcon />,
      title: MainWidgetTitles(intl).SCANNED_RECEIPTS,
    },
    {
      icon: <TotalScannedIcon />,
      title: MainWidgetTitles(intl).TOTAL_SCANNED,
    },
    {
      icon: <AverageReceiptIcon />,
      title: MainWidgetTitles(intl).AVERAGE_PER_RECEIPT,
    },
    {
      icon: <PurchasedIcon />,
      title: MainWidgetTitles(intl).MOST_PURCHASED_ITEM,
    },
  ];
};

const ThisMonthMainWidget: FC<MainWidgetProps> = ({ items, receipts }) => {
  const { mainWidget } = useStyles();
  const intl = useIntl();

  const renderMainWidget = (item: MainWidgetItem) => {
    switch (item.title) {
      case MainWidgetTitles(intl).SCANNED_RECEIPTS:
        return receipts?.scannedReceipts;
      case MainWidgetTitles(intl).TOTAL_SCANNED:
        return formatDollars(receipts?.totalScannedReceipts || 0, CURRENCY_DISPLAY_NARROW_SYMBOL);
      default:
        return null;
    }
  };

  return (
    <Grid
      container
      p={{ xs: "15px 10px", lg: "36px 10px" }}
      justifyContent="center"
      alignItems="center"
      className={mainWidget}
    >
      <Grid container justifyContent="center" className="widgetItemContainer">
        {items?.map((item) => (
          <Grid key={Math.random()} className="widgetItem">
            <Grid container>
              <Grid container justifyContent="center" alignItems="center" className="widgetItemIcon">
                {item.icon}
              </Grid>
              <Grid marginLeft="15px">
                <Grid>
                  <Typography className="widgetItemValue">{renderMainWidget(item) ?? "0"}</Typography>
                </Grid>
                <Grid>
                  <Typography className="widgetItemTitle">{item.title}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default ThisMonthMainWidget;
