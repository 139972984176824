import { useState, KeyboardEvent } from "react";
import { FormattedMessage } from "react-intl";
import useStyles from "./styles";
import Icon from "../../../utils/icon";
import Paymodal from "./Paymodal";
import { ReactComponent as MastercardLogo } from "../../../assets/icons/paymentIcons/mastercardLogo.svg";

const Payment = () => {
  const { wrap, topSection, bellowSection } = useStyles();
  const [modalActive, setModalActive] = useState(false);

  const handleModal = () => setModalActive(!modalActive);

  const handleCloseEscape = (e: KeyboardEvent) => {
    if (e.keyCode) {
      handleModal();
    }
  };
  return (
    <>
      <Paymodal modalActive={modalActive} handleModal={handleModal} />
      <div className={wrap}>
        <div className={topSection}>
          <p>
            <FormattedMessage id="billing_payments_payment_method" />
          </p>
          <span onClick={handleModal} onKeyPress={handleCloseEscape} role="button" tabIndex={0}>
            <FormattedMessage id="btn_text_edit" />
          </span>
        </div>

        <div className={bellowSection}>
          <div className="left">
            <div className="wrap">
              <Icon.CreditCardIcon fill="white" />
            </div>
          </div>

          {/* NOTE: PLACEHOLDER VALUE */}
          <p>BANK OF AMERICA - 5027</p>

          <MastercardLogo className="mastercardIcon" />
        </div>
      </div>
    </>
  );
};

export default Payment;
