import { graphql } from "../../../../gql";

export const createAccountAddressMutation = graphql(`
  mutation CreateAccountAddressMutation ($input: CreateAccountAddressInput!) {
    createAccountAddress(input: $input) {
      payload {
        id
      }
    }
  }
`);
