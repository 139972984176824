import { FC, useState } from "react";
import { Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import DetailsModal from "../DetailsModal";
import { Campaign } from "../Campaigns/data";
import { Rule } from "../Rules/data";
import theme from "../../../../theme";
import useStyles from "./styles";

interface ViewDetailsButtonProps {
  detailsData: Campaign | Rule;
  isRules?: boolean;
}

const ViewDetailsButton: FC<ViewDetailsButtonProps> = ({ detailsData, isRules = false }) => {
  const { viewDetailsButton } = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleButtonClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Typography
        className={viewDetailsButton}
        fontFamily={theme.typography.fontFamily}
        fontSize="0.75rem"
        onClick={handleButtonClick}
      >
        <FormattedMessage id="view" />
      </Typography>

      <DetailsModal isOpen={isModalOpen} onClose={handleCloseModal} detailsData={detailsData} isShowFooter={!isRules} />
    </>
  );
};

export default ViewDetailsButton;
