import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    tableContainer: {
      "& .MuiTableCell-head": {
        padding: "10px",
        color: "#707070",
        fontFamily: theme.typography.fontFamily,
        fontSize: "0.75rem",
        fontWeight: "medium",
        lineHeight: "normal",
      },

      "& tbody tr": {
        "&:nth-of-type(even)": {
          backgroundColor: "#F6F6F6",
        },

        "&:hover": {
          backgroundColor: "#F6F6F666",
        },

        "& .MuiTableCell-body": {
          padding: "10px",
          border: "none",
          color: theme.palette.primary.dark,
          fontFamily: theme.typography.fontFamily,
          fontSize: "0.75rem",
          fontWeight: "medium",
          cursor: "pointer",
          lineHeight: "normal",

          "& .promotionImageWrapper": {
            width: "68px",
            height: "64px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            borderTopLeftRadius: "6px",
            borderTopRightRadius: "6px",
            backgroundColor: "#8986AD",

            "& img": {
              width: "100%",
              height: "100%",
            },
          },
        },
      },
    },

    searchContainer: {
      display: "flex",
      margin: "24px 0 10px",
      alignItems: "center",

      "& .inputWrapper": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: "31px",
        width: "100%",
        padding: "0 6px",
        marginRight: "15px",
        borderRadius: "3px",
        border: `2px solid ${theme.palette.experiences.lightGrey}`,

        "& .searchIcon": {
          color: theme.palette.experiences.lightGrey,
        },

        "& .MuiInputBase-root": {
          width: "100%",
          "& .MuiInputBase-input": {
            paddingLeft: "10px",
            paddingRight: "10px",
            fontFamily: theme.typography.fontFamily,
            fontSize: "0.75rem",
          },
        },
      },

      "& .searchButton": {
        width: "165px",
        height: "31px",
        padding: "0 48px",
        border: "1px solid #34305D",
        borderRadius: "1rem",
        backgroundColor: theme.palette.experiences.darkPurple,
        fontFamily: theme.typography.fontFamily,
        fontSize: "0.875rem",
        color: theme.palette.white,
        "&:hover": {
          backgroundColor: theme.palette.experiences.darkPurple,
        },

        [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
          padding: "0 14px",
          borderRadius: "20px",
        },
      },
    },

    filterContainer: {
      "& .MuiFormControl-root": {
        margin: 0,
        flexDirection: "row",
        "& .MuiFormHelperText-root": {
          fontFamily: theme.typography.fontFamily,
        },

        "& .MuiInputBase-root": {
          height: "32px",
          width: "120px",

          "& .MuiSelect-select": {
            fontFamily: theme.typography.fontFamily,
            fontSize: "0.75rem",
          },
        },
      },
    },

    paginationContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "center",
      marginTop: "30px",
      [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
        justifyContent: "center",
      },

      "& .MuiTypography-root": {
        color: theme.palette.experiences.blue,
        fontSize: "0.75rem",
        textDecoration: "underline",
        cursor: "pointer",
      },
    },
  };
});

export default useStyles;
