import { FC } from "react";
import { useIntl } from "react-intl";
import { makeStyles } from "@mui/styles";

import { Grid } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import clsx from "clsx";
import { Labels } from "../../types/labels";

 type ComingSoonProps = {
   isTable?: boolean;

 };
const useStyles = makeStyles(() => {
  return {
    comingSoonTag: {
      fontSize: "0.6rem",
      justifyContent: "center",
      color: "#DD7575",
    },

    lockIcon: {
      width: "0.94rem",
      marginRight: "0.3125rem",
    },
    comingSoonTagTable: {
      fontSize: "0.6rem",
      justifyContent: "flex-start",
      color: "#DD7575",
    },
  };
});




const ComingSoonLabel = ( { isTable }:  ComingSoonProps) => {
  const intl = useIntl();


  const { comingSoonTag, lockIcon, comingSoonTagTable } = useStyles();

  return (
    <Grid container alignItems="center" className={clsx(comingSoonTag, isTable && comingSoonTagTable)}>
      <LockIcon className={lockIcon} />
      <p>{Labels(intl).COMING_SOON}</p>
    </Grid>
  );
};

export default ComingSoonLabel;
