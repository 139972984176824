import { FC } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import { useUnifiedLogin } from "@stagewood/unified-login-library";
import { CookieConsentProvider } from "../CookieConsent";
import Menu from "../Menu";
import { ChooseBusiness, Enroll } from "../Enroll";
import HowToCreateBusinessProfile from "../HowToCreateBusinessProfile";
import PrivateRoute from "../Routing/PrivateRoute";
import CookiePolicies from "../CookiePolicies";
import { Routes } from "../../constants";
import { TermsOfService } from "../TermsOfService/terms-of-service.component";
import { useQueryLanguage } from "../../hooks/useQueryLanguage.hook";

const App: FC = () => {
  const { isLoading, isAuthenticated } = useUnifiedLogin();
  const [isGetAccountLanguageLoading] = useQueryLanguage();

  const showSpinner = isLoading || isGetAccountLanguageLoading;
  if (showSpinner) {
    return (
      <Box width="100vw" height="100vh" display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  console.log("isAuthenticated", isAuthenticated);

  return (
    <Router>
      <CookieConsentProvider>
        <Switch>
          <Route exact path={Routes.ENROLL} component={Enroll} />
          <PrivateRoute exact path={Routes.CHOOSE_BUSINESS} component={ChooseBusiness} />
          <PrivateRoute exact path={Routes.HOW_TO_CREATE_BUSINESS_PROFILE} component={HowToCreateBusinessProfile} />
          <Route exact path={Routes.COOKIES} component={CookiePolicies} />
          <Route exact path={Routes.TERMS_OF_SERVICE} component={TermsOfService} />
          {!isAuthenticated && <Route path="*" component={Enroll} />}
          {isAuthenticated && <Route path="*" component={Menu} />}
        </Switch>
      </CookieConsentProvider>
    </Router>
  );
};

export default App;
