import { graphql } from "../../../gql";


export const createLocationSetupCartMutation = graphql(`
  mutation CreateLocationSetupCart($input: CreateLocationSetupCartInput!) {
    createLocationSetupCart(input: $input) {
      message
      errorMessageId
      payload {
        id
      }
    }
  }
`);
