import styled from "@emotion/styled";

export const ConsentWrapper = styled.div`
  position: fixed;
  left: 50%;
  transform: translateX(calc(-50% - 24px));
  bottom: 16px;
  padding: 24px;
  background-color: #fff;
  border-radius: 6px;
  margin-left: 24px;
  margin-right: 24px;
  box-shadow: 0 3px 6px #00000029;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 1123px) {
    left: 0;
    transform: none;
    flex-direction: column;
  }
`;

export const TextContainer = styled.div`
  width: 650px;
  color: #2b2b2b;
  font-size: 16px;
  letter-spacing: 0;

  @media (max-width: 1123px) {
    width: auto;
  }
`;

export const ButtonContainer = styled.div`
  margin-left: 32px;
  white-space: nowrap;

  @media (max-width: 1123px) {
    margin-top: 24px;
    margin-left: 0;
    text-align: right;
  }
`;

const ButtonBase = styled.span`
  display: inline-block;
  user-select: none;
  font-size: 16px;
  cursor: pointer;
  padding: 13px 28px;
  border-radius: 6px;
  transition: background-color 0.2s;
`;

export const CookiesSettingButton = styled(ButtonBase)`
  color: #050038;
  background-color: #fff;
  border: 1px solid #050038;
  padding-top: 12px;
  padding-bottom: 12px;

  &:focus,
  &:active,
  &:hover {
    background-color: #d7d5eb;
  }
`;

export const AcceptAllCookiesButton = styled(ButtonBase)`
  color: #fff;
  background-color: #4262ff;
  margin-left: 16px;

  &:focus,
  &:active,
  &:hover {
    background-color: #203ed4;
    box-shadow: -3px 4px 4px #00000029;
  }
`;
