import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    wrap: {
      opacity: ".7",
      transition: "300ms ease-in-out",

      "&.active": {
        opacity: "1",

        "& input": {
          color: theme.palette.blue,

          "&::-webkit-input-placeholder": {
            opacity: "1",
          },
        },
      },

      "& .MuiTypography-root": {
        lineHeight: "1",
      },

      "& input": {
        flexDirection: "row-reverse",
        padding: "16px 10px",
        fontSize: "1rem",
        color: theme.palette.diagram?.purple,
        background: "transparent",
        border: `2px solid ${theme.palette.diagram?.purple}`,
        borderRadius: "3px",
        textAlign: "center",
        "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
          WebkitAppearance: "none",
          margin: "0",
        },

        [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
          maxWidth: "60px",
          padding: "6px",
        },
      },

      '& input[type="date"]': {
        maxWidth: "100px",
        width: "100%",
        "&::-webkit-calendar-picker-indicator": {
          marginTop: "2px",
          opacity: "0.3",
        },

        [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
          maxWidth: "60px",
        },
      },

      "& .MuiInputBase-root": {
        maxWidth: "70px",
      },

      "& .MuiSwitch-root": {
        height: "45px",
        width: "65px",

        "& .MuiSwitch-switchBase": {
          top: "3px",
          "&.Mui-checked": {
            transform: "translateX(27px)",
            [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
              transform: "translateX(16px)",
            },
            "& .MuiSwitch-thumb": {
              background: theme.palette.blue,
            },

            "& + .MuiSwitch-track": {
              background: theme.palette.ruleToggle.blue,
            },
          },

          "& .MuiSwitch-thumb": {
            background: theme.palette.diagram?.purple,
            [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
              height: "16px",
              width: "16px",
            },
          },
        },

        "& .MuiSwitch-track": {
          background: theme.palette.diagram?.grey,
          borderRadius: "10px",
        },

        [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
          height: "40px",
          width: "50px",
        },
      },

      "& .btn": {
        position: "relative",
        height: "24px",
        width: "24px",
        borderRadius: "100px",
        background: theme.palette.diagram?.grey,
        cursor: "pointer",
        transition: "300ms ease-in-out 0ms",
        "&:hover": {
          color: theme.palette.white,
          background: theme.palette.blue,
        },

        "&::before, &::after": {
          content: '""',
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          height: "3px",
          width: "11px",
          background: theme.palette.diagram?.purple,
        },

        "&.plus": {
          "&::after": {
            height: "11px",
            width: "3px",
          },
        },
      },
    },
  };
});

export default useStyles;
