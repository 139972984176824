import { useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { useMutation } from "@apollo/client";
import { useUser } from "@stagewood/unified-login-library";
import { FormattedMessage } from "react-intl";
import theme from "../../../../theme";
import { useStyles } from "./styles";
import { CREATE_QR_CODE } from "../../../../apollo/mutations";
import QrForm from "../QrForm";
import { ITtykchaseCampaign, QrType } from "../../types";

const QrCreate = () => {
  const { qrCreateWrap } = useStyles();

  const { path } = useRouteMatch();
  const history = useHistory();
  const user = useUser();
  const [name, setName] = useState("");
  const [trigger, setTrigger] = useState<ITtykchaseCampaign | null>(null);
  const [qrType, setQrType] = useState<QrType | null>(null);
  const [formIsDirty, setFormIsDirty] = useState(false);

  const [createQrCode, { loading: qrCodeLoading }] = useMutation(CREATE_QR_CODE);

  const handleSubmit = (isValid: boolean) => {
    if (!isValid) {
      setFormIsDirty(true);
      return;
    }

    createQrCode({
      variables: {
        name,
        qrType,
        tykchaseCampaignId: (trigger as ITtykchaseCampaign).id,
        userId: user?.id,
      },
      onCompleted: (qrCodeData) => {
        history.push({
          pathname: `${path}/Success`,
          state: { ...qrCodeData.createQrCode.qrCodePayload },
        });
      },
    });
  };

  return (
    <Box className={qrCreateWrap}>
      <Box className="mainContent">
        <Typography component="span" display="block" color={theme.palette.technology.darkPurple} mb={6}>
          <FormattedMessage id="technology.qr_codes.create" />
        </Typography>

        <QrForm
          name={name}
          setName={setName}
          trigger={trigger}
          setTrigger={setTrigger}
          setQrType={setQrType}
          onSubmit={handleSubmit}
          isDirty={formIsDirty}
          isLoading={qrCodeLoading}
        />
      </Box>
    </Box>
  );
};

export default QrCreate;
