import { capitalize } from "@mui/material";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { CartHistory } from "../../../generated/types";
import { MessageIds } from "../../../i18n";

const TRANSACTION_ITEM_TITLE: Record<string, MessageIds> = {
  MEMBERSHIP: "billing.invoice_items.membership",
  SETUP_FEE: "billing.invoice_items.setup_fee",
  DEPOSIT: "billing.invoice_items.deposit",
  DEFAULT: "billing.invoice_items.default",
};

export function useItemTitle() {
  const { formatMessage } = useIntl();

  return {
    getItemTitle: useCallback(
      (cartHistory: CartHistory) => {
        return (
          cartHistory.title ??
          cartHistory.cartLineItemsHistory
            .map((cartItem) => {
              const translationKey = TRANSACTION_ITEM_TITLE[cartItem.metadata?.itemType];

              if (translationKey) {
                return formatMessage({ id: translationKey });
              }

              return (
                capitalize(cartItem.metadata?.itemType?.toLowerCase() || "") ||
                formatMessage({ id: TRANSACTION_ITEM_TITLE.DEFAULT })
              );
            })
            .join(", ")
        );
      },
      [formatMessage],
    ),
  };
}

export function useBilling() {
  return {
    getTotal: useCallback((cart: CartHistory) => {
      return cart.cartLineItemsHistory.reduce((prev, curr) => prev + curr.priceInCents, 0) + cart.taxInCents;
    }, []),
  };
}
