import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    secondaryWidget: {
      padding: "1.25rem",
      borderRadius: "0.375rem",
      flexGrow: "1",

      "& .title": {
        fontSize: "0.75rem",
        fontWeight: "medium",
      },

      "& .totalCentorAmount": {
        fontWeight: "bold",
        color: theme.palette.primary.dark,
        fontSize: "1.25rem",
      },   "& .widgetItemTitle": {
        fontSize: "0.625rem",
        textAlign:'center',
        color: theme.palette.dashBoard?.grey,

      },

      "& .seeAllRules": {
        height: "max-content",
        fontSize: "0.75rem",
        color: theme.palette.dashBoard?.blue,

        "& .link:visited": {
          color: theme.palette.dashBoard?.blue,
        },

        "& .textLink": {
          marginLeft: "0.3125rem",
        },
      },
    },
  };
});

export default useStyles;
