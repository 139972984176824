import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    campaignItemWrap: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: "24px 16px",
      margin: "8px 0",
      borderRadius: "6px",

      [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
        margin: "4px 0",
      },

      "& .MuiTypography-root": {
        maxWidth: "120px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",

        [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
          maxWidth: "100px",
        },

        [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
          maxWidth: "80px",
        },
      },

      "& .campaignItemImageWrap": {
        width: "40px",
        minWidth: "40px",
        height: "40px",
        backgroundColor: "#8986AD",
        borderRadius: "50%",
        marginRight: "16px",

        "& .campaignImage": {
          width: "100%",
          height: "100%",
          borderRadius: "50%",
        },
      },

      "& .campaignItemContentWrap": {
        display: "flex",
        flexDirection: "column",

        "& .campaignItemContent": {
          display: "flex",
          flexDirection: "row",
          marginTop: "4px",

          "& .campaignItemStatistic": {
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "center",

            "& .smallImage": {
              marginBottom: "4px",
            },
          },
        },

        "& .campaignItemStatisticDivider": {
          height: "14px",
          width: "1px",
          backgroundColor: "#34305D",
          alignSelf: "end",
          margin: "0 16px",
        },
      },
    },
  };
});

export default useStyles;
