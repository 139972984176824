import { graphql } from "../../../gql";

export const getTykchaseLocationsQuery = graphql(`
  query GetTykchaseLocations($userId: String!) {
    getTykchaseLocations(userId: $userId) {
      id
      isPrimaryLocation
      createdAt
      updatedAt
      tykchaseLocationReceiptVariants {
        id
        storeTitle
        storeAddressNormalized
        receiptUrl
        phoneNumber
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`);
