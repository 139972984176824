import {
  Container,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  Typography,
  useTheme,
  TableRow,
  TableCell,
  Box,
  ListItem,
  Link,
} from "@mui/material";
import { AlphabeticList } from "./alphabetic-list.component";
import { RewardsSublist } from "./rewards-sublist.component";

export function TermsOfService(): JSX.Element {
  const theme = useTheme();

  return (
    <Container component="section" maxWidth="lg">
      <Stack spacing={2}>
        <Typography
          sx={{ textDecoration: "underline" }}
          variant="h5"
          component="h1"
          textAlign="center"
        >
          TykChase Rewards
        </Typography>
        <Typography
          sx={{ textDecoration: "underline" }}
          variant="h5"
          component="h1"
          textAlign="center"
        >
          Terms and Conditions
        </Typography>
        <Typography textTransform="uppercase" textAlign="center">
          <Stack direction="row" spacing={1}>
            <Typography component="span">Effective Date:</Typography>
            <Typography component="span">[insert date]</Typography>
          </Stack>
        </Typography>
        <Typography fontWeight="bold">
          PLEASE READ THIS AGREEMENT CAREFULLY. IT SETS FORTH THE LEGALLY BINDING TERMS AND
          CONDITIONS FOR YOUR PARTICIPATION IN THE TYKCHASE REWARDS PROGRAM.
        </Typography>
        <Typography fontWeight="bold">
          AS FURTHER DETAILED IN SECTION 12 OF THE TYKET TERMS OF SERIVCE [LINK TO SECTION 12 of the
          TYKET TERMS OF SERVICE], THESE TERMS REQUIRE YOU TO ARBITRATE ANY DISPUTES YOU HAVE WITH
          US ON AN INDIVIDUAL BASIS ONLY, AND BY USING THE SERVICE AND ENTERING INTO THESE TERMS,
          YOU WAIVE THE ABILITY TO BRING CLAIMS AGAINST US IN ANY COURT, TO HAVE ANY DISPUTE HEARD
          BY A JUDGE OR JURY, OR TO BRING ANY CLAIMS AGAINST US IN A CLASS ACTION FORMAT (INCLUDING
          AS A CLASS REPRESENTATIVE OR MEMBER OF ANY PUTATIVE CLASS).
        </Typography>
        <Typography
          sx={{ textDecoration: "underline" }}
          variant="h5"
          component="h1"
          textAlign="center"
        >
          Rewards Program Summary
        </Typography>
        <TableContainer component={Paper} elevation={4}>
          <Table sx={{ minWidth: 350 }} aria-label="simple table">
            <TableBody>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row">
                  AM I A TYKCHASE MEMBER?
                </TableCell>
                <TableCell>
                  <Typography>
                    You must fully enroll at Tykchase.com, via the Tykchase app, or in-store at
                    certain partners to become a Member and redeem Tyks for Awards. If you have
                    established a login and password at TykChase.com or on the Tyket app, then you
                    are enrolled.
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row">
                  HOW DO I EARN AND USE TYKS?
                </TableCell>
                <TableCell>
                  <Stack spacing={2}>
                    <Box>
                      <Typography fontWeight="bold">TO EARN TYKS:</Typography>
                      <Typography>
                        Scan your receipt into your Tyket app when you make qualifying purchases
                        with Tyket partners.
                      </Typography>
                    </Box>
                    <Box>
                      <Typography fontWeight="bold">TO EARN MORE TYKS:</Typography>
                      <Typography>
                        Learn about other offers at TykChase.com or on the Tykchase app Go to
                        TykChase.com/partners to see all the places you can earn Tykchase Tyks.
                      </Typography>
                    </Box>
                    <Box>
                      <Typography fontWeight="bold">TO USE TYKS:</Typography>
                      <Typography>
                        Once you are a fully enrolled Member, you can redeem Tyks for rewards. Go to
                        TykChase.com/rewards to see what rewards Tyks can be redeemed for.
                      </Typography>
                    </Box>
                  </Stack>
                </TableCell>
              </TableRow>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row">
                  DO TYKS EXPIRE?
                </TableCell>
                <TableCell>
                  <Typography>
                    Yes. Tyks have been credited to your account for and that have not been used
                    within one (1) year will expire.
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row">
                  DO YOU SHARE MY INFORMATION WITH TYKCHASE PARTNERS?
                </TableCell>
                <TableCell>
                  <Typography>
                    Yes. You give us permission to share your personal information with TykChase
                    partners when you sign up for the Program. For more information on data sharing
                    and how we protect your privacy, please read the Tyket Privacy Policy (link).
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row">
                  WHOM DO I CONTACT IF I HAVE A PROBLEM?
                </TableCell>
                <TableCell>
                  <Typography>
                    Contact us through TykChase.com/contact or call ([Insert Number]).
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Typography
          sx={{ textDecoration: "underline" }}
          variant="h5"
          component="h1"
          textAlign="center"
        >
          Introduction to Terms
        </Typography>
        <Typography>
          MyN3 Corporation, d/b/a Tyket (<b>“Tyket”</b>, “we,” <b>“us,”</b> and <b>“our”</b>) offers
          the Tykchase Rewards program (this “Program”), which is a loyalty rewards program and
          platform where eligible Tyket customers have an opportunity to accumulate Tyks (defined
          below) by participating in offered activities and/or by making qualifying purchases at
          certain retail, e-commerce and service companies (“Sponsors,” whom we may also refer to as
          “partners”) and through other promotions and offers that may be provided by Sponsors,
          subject to the terms and conditions set forth in these Terms and Conditions (these
          “Terms”).
        </Typography>
        <Typography>
          By accessing or participating in the Program, you accept and agree to be legally bound by
          these Terms, whether or not you sign-up as a Member (defined below) of the Program. If you
          wish to become a Member and participate in the Program, please read these Terms and
          indicate your acceptance to these Terms by following the instructions in the Program
          sign-up process. It is important that you review these Terms regularly. We may impose
          limits on certain features, activities, promotions or services and may restrict, suspend,
          terminate your access to the Program, in whole or in part, at any time and for any or no
          reason, with or without prior notice and without liability. For purposes of clarity, these
          Terms are considered “Additional Terms” (as such term is defined in the Tyket Terms of
          Service) and incorporate by reference all of the terms and conditions set forth in our
          Tyket Terms of Service including, without limitation, the arbitration clause and class
          action waiver provisions set forth therein (See Section 12 [click to link] for full
          details on arbitration and class action waiver provisions that apply to this Program).
          Capitalized terms herein that are undefined shall have their meaning as specifically set
          forth in the Tyket Terms of Service. In the event of any conflict between these Terms and
          the Tyket Terms of Service, these Terms will control.
        </Typography>
        <Typography>
          Each time you access and/or use the Program, you agree to be bound by and comply with
          these Terms and any Additional Terms (as defined in the Tyket Terms of Service). If you do
          not agree to these Terms, please do not participate in the Program. The business realities
          associated with operating the Program are such that, without the limitations that are set
          forth in these Terms -- such as your grants and waivers of rights, the limitations on our
          liability, your indemnity of us, and our arbitration of certain disputes – we would not
          make the Program available to you. Any information Members provide to us in connection
          with this Program or any contest, sweepstakes or other promotion or offer operated in
          connection with this Program is subject to the Tyket Privacy Policy
        </Typography>
        <Typography
          sx={{ textDecoration: "underline" }}
          variant="h5"
          component="h1"
          textAlign="center"
          id="linkable-table-of-contents"
        >
          Linkable Table of Contents
        </Typography>
        <Box sx={{ ml: `${theme.spacing(8)} !important` }} fontWeight="bold">
          <ol>
            <ListItem sx={{ display: "list-item" }}>
              <Link href="#elegibility-section">Eligibility</Link>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <Link href="#program-sign-up-section">Program Sign-Up</Link>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <Link href="#earning-tyks-section">Earning Tyks</Link>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <Link href="#mrrw-section">
                Member-Required Disclosures and Representations and Warranties
              </Link>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <Link href="#rewards-section">Rewards</Link>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <Link href="#timing-and-termination-section">Timing and Termination</Link>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <Link href="#disclaimers-section">Disclaimers</Link>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <Link href="#limitation-of-liability-section">Limitation on Liability</Link>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <Link href="#our-rights-section">Our Rights</Link>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <Link href="#fraud-section">Fraud</Link>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <Link href="#indemnity-section">Indemnity</Link>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <Link href="#update-to-terms-section">Update to Terms</Link>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <Link href="#communications-notices-customer-service-section">
                Communications, Notices and Customer Service
              </Link>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <Link href="#general-provision-section">General Provisions</Link>
            </ListItem>
          </ol>
        </Box>
        <Typography>
          <Typography component="span" fontWeight="bold" id="eligibility-section">
            1. Eligibility.{" "}
          </Typography>
          <Typography component="span">
            To participate in the Program, you must be at the time of Program enrollment at least
            eighteen (18) years of age; (ii) a legal resident and physically located in one of the
            fifty (50) United States or the District of Columbia. Employees, officers and directors
            of Tyket, their parent companies, and each of their respective affiliated companies,
            subsidiaries, sales representatives, prize providers, advertising and promotion agencies
            of any of the above listed organizations, including any entities engaged in the
            development, execution or fulfillment of this Program, together with the immediate
            family members and persons living in the same household (whether legally related or not)
            as such individuals, are not eligible to participate in this Program. For purposes of
            these Terms, ‘immediate family members’ are defined as spouse, partner, parents, legal
            guardians, in-laws, grandparents, siblings, children and grandchildren and those living
            in the same household shall mean people who share the same residence at least three (3)
            months a year, whether legally related or not. In order to access and use the Program,
            Members must have a Device (defined below) that is capable of accessing the Internet.
            Members under the age of majority in the state in which they reside (which is eighteen
            (18) in most states but is nineteen (19) in Alabama and Nebraska and twenty-one (21) in
            Mississippi) and who register for this Program represent to Tyket that they have secured
            their parents’ or legal guardians’ permission to participate. In the event that Tyket is
            notified by a parent or legal guardian of a minor that the parent or legal guardian does
            not wish his/her minor child to participate in this Program, Tyket will delete the minor
            Member’s account and will void all points in the account at the time of deletion. Minor
            participants must have a parent or legal guardian agree to these Terms as a condition of
            participation. There is no cost to join the Program. However, you must fully enroll in
            order to earn and use Tyks.
          </Typography>
        </Typography>
        <Link href="#linkable-table-of-contents">Return to Terms Table of Contents</Link>
        <Typography>
          <Typography component="span" fontWeight="bold" id="program-sign-up-section">
            2. Program Sign-Up.{" "}
          </Typography>
          <Typography component="span">
            To become a Program Member, eligible individuals may sign-up for this Program by
            visiting [Insert URL] and clicking on the links and following the instructions on the
            sign-up form. Registration is free. Once registered you will have an online account to
            track your Program activity (referred to herein as “Member Account”). Individuals who
            sign-up for the Program and receive a Member Account are referred to in these Terms as,
            “Members.” Participation in the Program constitutes Member’s full and unconditional
            agreement to these Terms and Tyket’s decisions, which are final and binding in all
            matters related to the Program. In order to earn Tyks or otherwise participate in
            certain Qualifying Activities (defined below) offered as part of the Program, Members
            may be required to provide access to their accounts at social networking sites (such as,
            for example, Twitter and Facebook) and/or provide their mobile telephone number.
          </Typography>
        </Typography>
        <Typography>
          In connection with your Member Account, you agree that: (i) You will provide true,
          accurate, current, and complete information about yourself in connection with the sign-up
          process and, as permitted, to maintain and update it continuously and promptly to keep it
          accurate, current, and complete; (ii) You are solely responsible for all activities that
          occur under your Member Account – whether or not you authorized the activity; (iii) You
          are solely responsible for maintaining the confidentiality of your Member Account password
          and for restricting access to your Device so that others may not access the Program or
          your Member Account using your name, username, or password; (iv) You will immediately
          notify us of any unauthorized use of your Member Account, password, or username, or any
          other breach of security by calling us at the following phone number: [Insert Number]; and
          (v) You will not sell, transfer, or assign your Member Account. We will not be liable for
          any loss or damage (of any kind and under any legal theory) to you or any third party
          arising from your inability or failure for any reason to comply with any of the foregoing
          obligations. If any information that you provide, or if we have reasonable grounds to
          suspect that any information that you provide, is false, inaccurate, outdated, incomplete,
          or violates these Terms, or any applicable law, then we may suspend or terminate your
          participation in the Program. We also reserve the more general and broad right to
          terminate your Member Account or suspend or otherwise deny you access to either of them or
          their benefits – all in our sole discretion, for any reason, and without advance notice or
          liability.
        </Typography>
        <Link href="#linkable-table-of-contents">Return to Terms Table of Contents</Link>
        <Typography>
          <Typography component="span" fontWeight="bold" id="earning-tyks-section">
            3. Earning Tyks.{" "}
          </Typography>
          <Typography component="span">
            The Program offers Members the ability to accumulate and redeem loyalty rewards points
            (“Tyks”), on an individual basis and solely for Member’s own Member Account. We may
            offer different tiers of Tyks, such as Centors and Lone-Stars that have different values
            associated with them or eligible towards the redemption of different reward categories.
            The Program is structured to offer certain benefits based on the Member’s current
            balance of “redeemable” Tyks (that is, Tyks that have not previously been redeemed) or
            “lifetime” Tyks (that is, all prior Tyks accumulated by Member, whether previously
            redeemed or not). From time to time, we and Sponsors may establish, at our sole
            discretion, activities pursuant to which Members may “earn” Tyks (“Qualifying
            Activities”), the amount of Tyks which can be earned will be identified with the
            promotion of the Qualifying Activity. Examples of “Qualifying Activities” include, but
            are not limited to:
          </Typography>
        </Typography>
        <Typography>
          Qualifying Activities may be added, discontinued or be made available again, at any time,
          and from time to time in Tyket’s sole and absolute discretion. Each Sponsor will have
          different ways for you to earn Tyks, including through making qualifying purchases. You
          will not receive Tyks for: items excluded by law; shipping amounts; the value of any
          merchant discounts or coupons; items and services that a particular Sponsor or Tyket deems
          ineligible; or, in most cases, taxes. Please go to [Insert URL to Page Listing Sponsors]
          for more information about how and where you can earn Tyks at Sponsors, including which
          items or services do not constitute qualifying purchases at that Sponsor. Sponsors are
          subject to change at any time. Purchases can generally be made with any form of payment
          accepted by that Sponsor, except that, in some instances, you will only earn Tyks if you
          use a linked credit or debit card. (For more information on linking, see the following
          paragraph.) In addition, at some Sponsors, you may need to link your Program Member
          Account with the account that you have with that Sponsor in order to earn Tyks on
          qualifying purchases. To earn Tyks on qualifying purchases, you need to present and use a
          Tyket identifier (“Tyket Identifer”) prior to purchasing qualifying items or services from
          participating Sponsor locations. Your primary Tyket Identifier is [Insert Details]. Other
          Tyket Identifiers, which you may be able to use at select Sponsor locations, can include
          the QR code we provide to you, your 16 digit Tyket account number, a phone number
          associated with your Program account, the digital Tyket rewards card contained within the
          Tyket app, or, in some cases, your linked credit or debit card. See [Insert URL Details]
          for more information about which Tyket Identifiers can be used at each Sponsor and if
          there are any additional identifiers that can be used for a particular Sponsor.
        </Typography>
        <Typography>
          Some of our Sponsors may provide you with additional ways to receive Tyks through offers,
          promotions, and other programs. Such offers and promotions may be subject to additional
          terms and conditions. Many offers or promotions will require activation by you on the
          Program website or the app. Certain offers or promotions may require you to link a credit
          or debit card to your Program Member Account and use that card to pay when you make a
          qualifying purchase. Where applicable, details will be provided on the Program website or
          app regarding how to link your credit or debit card. In addition to and independent of
          these Terms, Sponsors may have their own terms and conditions, which describe specifically
          how the Program works at their locations. These terms and conditions may describe things
          such as how you earn Tyks at their locations, which items are excluded from earning Tyks
          and whether or not you need to link your Member Account with the Sponsor’s account in
          order to earn Tyks. In certain cases, the Sponsor may also provide you with additional
          benefits that are not directly related to the Program (e.g., price discounts on certain
          items). Tyket bears no liability under or responsibility for any Sponsor-provided terms
          and conditions, or for any associated additional benefits, which are offered solely by the
          applicable Sponsor, and may be changed or discontinued at that Sponsor’s exclusive
          discretion.
        </Typography>
        <Typography>
          Certain payment cards associated with Tyket may be available to you (“Associated Payment
          Cards”). You are not required to have an Associated Payment Card to be a Member, but
          having an Associated Payment Card may provide you with additional ways to earn Tyks. You
          will need to separately apply for any Associated Payment Card, which will require you to
          undergo credit approval and will be subject to separate terms. Among other things, these
          separate terms may describe how and where you can earn Tyks, whether you need to link your
          Tyket rewards Member Account with your Associated Payment Card, and/or whether you need to
          present both your Tyket Identifier and the Associated Payment Card in order to earn Tyks.
        </Typography>
        <Typography>
          All Tyks, under all tiers are rewarded on a “first-come, first-served” basis. Also, there
          are often limits on many times you can be eligible for Tyks in various time periods (a
          day, a week, a month, etc.) for each action, and for each offer (e.g., limits on how many
          times you may walk-in or scan a product). Eligibility requirements for all offers may
          change without prior warning. Be sure to check the Tyket website or app, or relevant pages
          within the Program for the most up-to-date information. Tyket will not be liable for a
          failure to award Tyks or other under any circumstance, for any reason, including due to
          errors in your mobile device or mobile network, failing to complete the actions, failure
          to follow the stated directions, due to the inaccuracy or illegibility of the receipt
          submitted. Tyket cannot guarantee participating products will always be available for
          purchase at the published locations (e.g., a Sponsor may pull products from its shelves, a
          product may be out of stock, etc.), or that eligibility dates will not change without
          prior warning, and thus Tyket disclaims all responsibility for any failure to award Tyks.
          We are not liable for any failure of a Sponsor to award Tyks. By participating in the
          Program, you agree Tyket is under no obligation to notify you if there are no longer
          available Tyks.
        </Typography>
        <Typography>
          To keep your Member Account active, Members must earn Tyks within the previous twelve (12)
          months. Tyks earned through the Program will automatically expire twelve (12) months from
          the last day of Tyks-earning Qualifying Activity on the Member Account if not redeemed
          before the expiration of such twelve (12) month period. If no new Tyks are earned for a
          consecutive twelve (12) month period, Tyket shall automatically expire all of your
          accrued, but unused redeemable Tyks. Tyket may, in its discretion, send you periodic
          notifications to remind you that your Tyks will expire upon the 12th month of inactivity.
          However, it is your responsibility to monitor your Program account and Tyks status.
          Additionally, if you fail to log-in to your Member Account for any consecutive twelve (12)
          month period, your Tyks will automatically expire. Please note that we reserve the right
          to institute additional expiration dates for Tyks, as determined in our sole discretion.
          Certain actions taken prior to Program sign-up may be eligible to earn Tyks, in Tyket’s
          sole and absolute discretion, and will be automatically credited by Tyket to your Member
          Account. Tyks can be tracked in your Member Account. Tyks are always subject to
          verification and eligibility determined by us, in our sole and absolute discretion
        </Typography>
        <Typography>
          We reserve the right, in our sole discretion, at any time during the duration of this
          Program, all as set forth in these Terms, to: (i) change the number of Tyks awarded, or to
          award no Tyks, for any particular Qualifying Activity, (ii) offer additional or new
          Qualifying Activities for a limited time or permanently, (iii) delete any or all means to
          earn Tyks, (iv) limit the number of times or frequency a Member may earn Tyks for engaging
          in a Qualifying Activity or engaging in the activity during a specific time period, and
          (v) offer Tyks earning opportunities to select groups of Members. Also, Tyket may offer
          Tyks for engaging in a Qualifying Activity under one set of rules for one promotion, and
          may opt not to offer Tyks for engaging in that same activity under a subsequent promotion.
          Once a Qualifying Activity has been successfully completed and verified, the corresponding
          number of Tyks will appear in your Member Account.
        </Typography>
        <Typography>
          Each Member will be responsible for ensuring the accuracy of his/her Program Tyks totals.
          If a Member believes his/her Program Tyks total is not accurate, or that Tyks earned were
          not credited, please call us at the following phone number: [Insert Number] within six (6)
          days of the end of the applicable activity. Tyks subsequently determined, in the sole
          discretion of Tyket, to be invalid, will be removed from a Member’s Tyks accumulation
          total.
        </Typography>
        <Typography>
          Any Tyks you earn by completing a Qualifying Activity are considered a limited,
          non-exclusive, revocable, non-assignable, personal, and non-transferable right solely for
          use towards the earning of rewards. Accordingly, you have no property, proprietary,
          intellectual property, ownership, or monetary interest in your Tyks, which remain Tyket
          property at all times. Tyks have no “real world” or cash value and are not redeemable for
          cash, transferable or assignable for any reason or otherwise by operation of law. Tyks
          have no purpose or use except in exchange for rewards (if any) offered via the Program.
          Accordingly, you may not purchase, sell, barter, or trade any Tyks, or offer to purchase,
          sell, or trade any Tyks or other virtual items. Tyks will not be valid unless earned in
          strict compliance with the requirements as established and intended by us, and Member
          shall not attempt to earn Tyks by any means (including, without limitation, by using any
          script, bot or other automated means) that only simulates compliance with the applicable
          requirements. Tyket reserves the right to take any other or additional action it deems
          appropriate, in its sole discretion, in the event that Tyket believes, in its sole
          discretion, that a Member (or Members) have violated these Terms. You agree to abide by
          Tyket’s final and binding decisions regarding the Program and your participation in it.
        </Typography>
        <Typography>
          If you return an item on which you earned a certain number of Tyks, we may deduct up to an
          equivalent amount of Tyks from your Member Account. For example, if you earned 20 Tyks for
          purchasing a vase and then return that vase, we may deduct 20 Tyks from your Member
          Account. Continuing this example, if you have fewer than 20 Tyks in your Member Account,
          we may deduct your remaining balance. If you used Tyks for savings on a purchase and then
          return the item purchased, the number of Tyks that you redeemed will generally be credited
          to your Member Account. However in some instances you may receive a store credit instead,
          depending on the policies of that Sponsor. Whether or not an item that you have purchased
          can be returned is subject to the policies of each applicable Sponsor or merchant and
          applicable law. Tyket makes no representations and bears no responsibility for whether you
          can or cannot return any item you purchase.
        </Typography>
        <Link href="#linkable-table-of-contents">Return to Terms Table of Contents</Link>
        <Typography>
          <Typography component="span" fontWeight="bold" id="mrrw-section">
            4. Member-Required Disclosures and Representations and Warranties.{" "}
          </Typography>
          <Typography component="span">
            In connection with certain Qualifying Activities, from time to time, Members will
            receive access to Tyket content that is made available by us through the Program
            (“Official Content”), and, depending on your selected settings, will include a request
            for permission to post that Official Content on Member’s behalf at various third party
            social sharing sites, such as facebook.com, twitter.com, or other sites or services we
            make available from time to time during the Program (the “Approved Sites”), which
            Approved Site accounts are registered by Member with the Program (“Site Accounts”) or
            will provide Member with the option to disapprove Official Content if they have selected
            the “auto post” content option. Each participant enrolled in this Program (each, a
            “Member”) who submits or shares Official Content or User-Generated Content (defined in
            the Tyket Terms of Service) on an Approved Site in connection with this Program is
            required by these Terms and by law to disclose anything of value that is received by the
            Member from Tyket as encouragement for the Member’s sharing of such content.
          </Typography>
        </Typography>
        <Typography>
          You acknowledge and agree that if any such disclosures are included by Tyket within any
          messaging that you share from the Program, you will not edit, alter or otherwise remove
          these disclosures from the message. You acknowledge that any failure to abide by the
          requirements set forth in the previous sentence may result in the termination of your
          Member Account and forfeiture of all your Tyks. Additionally, if you choose to share
          Member Content or otherwise disclose your participation in the Program on your own or if
          the share functionality does not auto-generate the required disclosures, you agree to
          include the following statements as part of any message that mentions or otherwise
          references the Program, the User-Generated Content, the Official Content, or Tyket: (i)
          solely, for Tweets® on the Twitter® platform, you agree to include the hashtags
          “#TykChaseRewards” and “#sponsored”; and (ii) in any other message posted on any other
          social media platform, site or blog, you agree to include the following statement directly
          below the message: “In exchange for sharing content about Tyket, I may earn Tyks or
          entries into promotions as part of TykChaseRewards.” Members agree and represent and
          warrant to the following statements:
        </Typography>
        <Box>
          <Typography sx={{ ml: theme.spacing(4) }}>
            a. I will not post any information or content or conduct any activity that may violate,
            or which encourages the violation of, applicable local, state or federal laws or
            regulations and will promptly remove any such content upon request by Tyket.
          </Typography>
          <Typography sx={{ ml: theme.spacing(4) }}>
            b. I will not post any information or content that may infringe any intellectual
            property or other right of any third party and will promptly remove any such third party
            content upon request by Tyket.
          </Typography>
          <Typography sx={{ ml: theme.spacing(4) }}>
            c. I have read and will fully comply with the Federal Trade Commission’s (“FTC’s”)
            Endorsement and Testimonial Guides (“Guides”) (
            <Link
              target="_blank"
              href="http://ftc.gov/os/2009/10/091005revisedendorsementguides.pdf"
              rel="nofollow noreferrer"
            >
              http://ftc.gov/os/2009/10/091005revisedendorsementguides.pdf
            </Link>
            ) when talking or writing about Tyket. Further, and without limitation, I:
            <Typography sx={{ ml: theme.spacing(4) }}>
              i. Will clearly and conspicuously disclose my true identity and that I have received
              compensation and product and/or other incentive items from Tyket (as applicable)
              proximate to any mention by me of Tyket;
            </Typography>
            <Typography sx={{ ml: theme.spacing(4) }}>
              ii. Will not make any false, misleading or deceptive statement about Tyket’s products
              or services;
            </Typography>
            <Typography sx={{ ml: theme.spacing(4) }}>
              iii. Ensure that all my statements accurately reflect only my honest, current opinions
              and beliefs based on my personal experience;
            </Typography>
            <Typography sx={{ ml: theme.spacing(4) }}>
              iv. Will not purport to speak on behalf of Tyket; and
            </Typography>
            <Typography sx={{ ml: theme.spacing(4) }}>
              v. Maintain my social media activities related to this Program in a manner appropriate
              for a family audience and will not be rude or abusive.
            </Typography>
            <Typography>
              I have watched the FTC’s video guidance for bloggers on how to comply with the Guides:{" "}
              <Link
                target="_blank"
                href="http://business.ftc.gov/multimedia/videos/endorsement-guides"
              >
                http://business.ftc.gov/multimedia/videos/endorsement-guides
              </Link>{" "}
              and will follow the guidance at{" "}
              <Link
                target="_blank"
                href="http://business.ftc.gov/documents/bus71-ftcs-revised-endorsement-guideswhat-people-are-asking"
              >
                http://business.ftc.gov/documents/bus71-ftcs-revised-endorsement-guideswhat-people-are-asking
              </Link>
              .
            </Typography>
          </Typography>
          <Typography sx={{ ml: theme.spacing(4) }}>
            d. I will strictly comply with all applicable Approved Site terms of service, membership
            agreements, privacy policies, and other similar applicable governing documents of any
            third-party sites, services or venues that I use in performing Program activities,
            including, without limitation, Twitter’s Terms of Service and Facebook’s Statement of
            Rights and Responsibilities.
          </Typography>
        </Box>
        <Link href="#linkable-table-of-contents">Return to Terms Table of Contents</Link>
        <Box>
          <Typography fontWeight="bold" id="rewards-section">
            5. Rewards.
          </Typography>
          <RewardsSublist
            items={[
              {
                letter: "A",
                header: "Rewards Generally",
                text: "Once earned, Tyks will be deposited into your Member Account profile and can be used to acquire rewards such as merchandise, offers and other items of value from the “Rewards Section” of the Program website/app, while supplies last.  The rewards section of the Program website/app will list the corresponding Tyks value required to redeem each item.   Rewards, and the redemption thereof, and any other related information are subject to prior confirmation of eligibility, compliance with these Terms.  Rewards will not be awarded until a Member is confirmed and the verification process is complete, in Tyket’s absolute discretion.  The items listed as rewards on the website/app will fluctuate as available items are redeemed and additional items are added by Tyket.  Merchandise/items/prizes pictured as rewards on the website/app may not necessarily reflect exact colors, styles, or models of actual reward due to printing variations and/or manufacturer’s updates.  Tyket makes no representations, warranties, or guarantees that any particular Reward will be (or will continue to be) available or offered in the Program.  Reward availability is limited and is on a first-come, first-served basis.  Tyket reserves the absolute right to modify, change, delete or add rewards, or any element thereof at any time.  Tyket reserves the right to modify the Tyks value(s) for any reward, at any time and for any reason, during the Program Period.  All redemptions are subject to these Terms and all limitations or requirements on the Program website/app.  ",
              },
              {
                letter: "B",
                header: "Redemption of Tyks",
                text: "To spend/redeem your Tyks, navigate through the items listed on the rewards section of the Program website/app.  You can choose any item still available for which you have accumulated sufficient Tyks for redemption.  Click the image corresponding to the item you wish to redeem and follow the links and instructions to complete the redemption process.  As part of the redemption process, you will receive a confirmation email and when applicable, an email confirming that the order has been processed.  Emails will be sent to the email address associated with the Member’s Member Account.  The total number of Tyks a Member can use to redeem an item at any given time is the total number of Tyks available in their Member Account at the time of redemption.  Once a Member has ordered an item from the rewards section of the Program website/app, the order is final and the appropriate number of Tyks will be deducted immediately from their Member Account.",
              },
              {
                letter: "C",
                header: "Provide Accurate Information",
                text: "Each Member is responsible for ensuring the mailing address associated with his/her Member Account is accurate and up to date.  We are not responsible for non-receipt of an item shipped to the mailing address associated with a Member’s Member Account.  Changes to Member Accounts or the information in them should only be made by the Member to whom such accounts belong.",
              },
              {
                letter: "D",
                header: "Tyks Redemptions are Final",
                text: "All Tyks redemptions for items are final.  Tyks will not be refunded or placed back in a Member’s quantity of redeemable Tyks for any reason after an order has been placed and merchandise may not be returned for any reason except if item is damaged or defective, in which case item will be replaced with the same or like item.",
              },
              {
                letter: "E",
                header: "Rewards with Limited Availability",
                text: "Some items available in the Rewards section of the Program website/app may be available in limited quantities, or for a limited time, and will be noted as such.  Once the total available number of any such item have been claimed, or once the limited time to redeem such an item has expired, that item will be removed from the Rewards section of the Program website/app.  If there is a particular reward offered that you would like to receive, you should redeem your Tyks for that reward as soon as possible (once you have enough Tyks) because supplies or redemption time may be limited and the Program may be discontinued at any time in our sole discretion.  Many rewards are awarded on a first-come, first-served and while-supplies-last basis.  If you redeem your Tyks for a reward and we determine that the reward was unavailable, out of stock or for whatever reason cannot be provided to you, we will “refund” the Tyks that you exchanged for the reward.",
              },
              {
                letter: "F",
                header: "Forfeiture of Tyks",
                text: "Any Tyks remaining in a Member Account thirty (30) days after the Program End Date (defined below) will be forfeited without compensation.  The Tyks have no cash value and are only redeemable for items offered via the Program.",
              },
              {
                letter: "G",
                header: "Tyks Conversion",
                text: "From time to time, we may make items available in the rewards section of the Program website/app that will allow you to convert your accrued Tyks into rewards such as gift cards or similar items based on a pre-determined conversion ratio set by us.  You understand and agree that we may set the Tyks conversion ratio for any such rewards in our sole and absolute discretion and we may change such conversion ratio at any time throughout the Program Period.  The conversion ratio for Tyks into rewards may vary among retailers or rewards providers and all details regarding the specific conversion ratio applicable to a reward will be provided in the rewards section of the Program website/app.  We also reserve the right to charge you an administrative fee such as a processing or handling fee when converting your Tyks to a form of gift card (or similar reward item) and you agree to pay any such amounts if requested by us at the time of Tyks redemption.",
              },
              {
                letter: "H",
                header: "Rewards Sweepstakes, Contests and Promotions",
                text: "From time to time, as part of the Program activities, Tyket may elect to conduct sweepstakes, contests or other promotions, some of which may be open for entry only to Members and other which may be open to the general public (each, a “Promotion”).  Tyks may also be redeemable for entries in each Promotion that may be offered from time to time as part of the Program activities. In addition to these Terms, any Promotion we may offer as part of the Program or to Program Members, is and will also be subject to separate official rules (“Official Rules”) which will be posted in the Program during the applicable promotion period and which will govern Members’ participation, and our execution, of each such Promotion.  All Promotions are subject to all rules, terms and conditions of the Promotion, and described in the Official Rules that accompanies the Promotion.  Methods of entry into the Promotion will be detailed in the Official Rules. Members may enter the Promotion by redeeming the specified number of Tyks for the specified number of entries or as described in the Official Rules.",
              },
              {
                letter: "I",
                header: "Release and Taxes",
                text: "Members agree to sign an affidavit, release of liability, publicity release or similar agreement if requested by us prior to receiving your reward.  Members are responsible for all applicable taxes, including income tax liability, associated with any item acquired under the Program.  Tyket will issue an IRS Form 1099 to Members obtaining any item(s) with a combined total value equal to $600 or more in any single calendar year. Members obtaining any item(s) with a combined total value equal to $600 or more in any single calendar year will be required to provide his/her Social Security Number within the timeframe specified for tax reporting purposes prior to the shipment of the item(s). An IRS Form 1099-MISC will be issued to him/her for the total value of all items awarded for the tax year in which the item(s) are awarded.",
              },
              {
                letter: "J",
                header: "Other Terms Applicable to Rewards",
                text: "Rewards (which include any products or services that a Member can redeem Tyks for) are provided “as is” with no warranty or guarantee, either express or implied by us.  Tyks are not your personal property, and are not descendible, may not be inherited, bartered or sold to any third party.  You cannot transfer Tyks nor have rewards sent to any third party.  Tyks may not be redeemed with us for money or exchanged with any third party for money.  The only way to use Tyks is to redeem them for Program rewards to be sent to yourself.  Products made available as rewards may be refurbished products.  Some rewards may have age eligibility requirements.  We reserve the right to verify your eligibility qualifications prior to fulfilling a reward.",
              },
              {
                letter: "K",
                header: "Delivery of Rewards",
                text: "Rewards will be mailed to your U.S. postal address that you provided when you created, or last updated, your Member Account.  No rewards will be shipped to P.O. Boxes, APO (Army Post Office) or FPO (Fleet Post Office) addresses, or destinations outside the United States.  Rewards shipped via postal mail will be shipped within approximately eight (8) to twelve (12) weeks from the date your order is processed, unless the Program states a different schedule or the particular reward you ordered is limited in quantity, backordered, or out of stock.  We are not liable for any damages to, losses of or delays in any shipments.  Rewards that are undeliverable for whatever reason (including because your Program information is incorrect) will not be re-sent and are forfeited, and the Tyks will not be refunded.  After ordering a reward, you may receive a confirmation e-mail with an order number, which you should retain for your records. If you contact us in the future about your reward order, you may be required to provide your order number.",
              },
            ]}
          />
        </Box>
        <Link href="#linkable-table-of-contents">Return to Terms Table of Contents</Link>
        <Typography>
          <Typography component="span" fontWeight="bold" id="timing-and-termination-section">
            6. Timing and Termination.{" "}
          </Typography>
          <Typography component="span">
            The “Program Period” will begin on [Insert Date] and will end on a date to be determined
            in Tyket’s sole and absolute discretion, which date shall be posted on the Program
            website/app or sent to you via email (the “Program End Date”). Tyket makes no guarantee
            or representation as to the length of time during which the Program will be offered, and
            the Program could end at any time. Tyket reserves the right to cancel, modify, restrict,
            suspend or terminate this Program or any aspect or feature of the Program, including
            Tyks, and/or extend or shorten the current Program Period at any time without notice.
            After the Program End Date, Members will no longer be permitted to earn Tyks. Members
            will have thirty (30) days after the Program End Date (or the specific number of days
            noted in the Program ending announcement) during which to redeem any remaining Tyks in
            their Member Account. After such thirty (30) day period, all Tyks will expire and the
            Program will no longer be available, and no further liability will be owed by us to any
            Member. Any Tyks remaining in a Member Account thirty (30) days after the Program End
            Date will be forfeited without compensation.
          </Typography>
        </Typography>
        <Typography>
          These Terms will remain in full force and effect while you use the Program and/or are a
          Member. We may suspend or terminate your membership for any reason, at any time, including
          if you fail to remain in good standing in the Program. If we suspend or terminate you
          because you have breached these Terms, you may either be terminated from the Program or
          otherwise be restricted by us from redeeming Tyks or earning more Tyks until such time (if
          ever) when we reinstate your membership (at our discretion). In the event that you are
          terminated as a Member, we may elect to immediately void all of your accumulated Tyks and,
          in such instance, you shall not be entitled to redeem Tyks for any items. Even after your
          participation is terminated, these Terms will remain in effect. We reserve the right, at
          our sole discretion, to pursue all of its legal remedies, including, but not limited to,
          deletion of your Member Account, profile and submitted User-Generated Content, from the
          Program upon any breach by you of these Terms.
        </Typography>
        <Typography>
          If you wish to discontinue your participation in this Program, please contact us at
          [Insert Contact Details].
        </Typography>
        <Link href="#linkable-table-of-contents">Return to Terms Table of Contents</Link>
        <Typography>
          <Typography component="span" fontWeight="bold" id="disclaimers-section">
            7. Disclaimers.
          </Typography>
          <Typography component="span">
            YOUR ACCESS TO AND USE OF THE PROGRAM IS AT YOUR SOLE RISK
          </Typography>
        </Typography>
        <Typography>
          THE PROGRAM IS PROVIDED ON AN “AS IS”, “AS AVAILABLE”, AND “WITH ALL FAULTS” BASIS.
          Therefore, to the fullest extent permissible by law, Tyket, their parent companies and
          each of their respective subsidiaries and each of their respective employees, directors,
          members, managers, shareholders, agents, vendors, licensors, licensees, contractors,
          successors, and assigns (collectively, “Program Parties”) hereby disclaim and make no
          representations, warranties, endorsements, or promises, express or implied, as to:
        </Typography>
        <AlphabeticList
          items={[
            {
              letter: "a",
              text: "the Program and the website/app that makes the Program available;",
            },
            {
              letter: "b",
              text: "the functions, features, or any other elements on, or made accessible through, the Program and the Program website/app;",
            },
            {
              letter: "c",
              text: "any products, services, or instructions offered or referenced at or linked through the Program and Program website/app;",
            },
            {
              letter: "d",
              text: "security associated with the transmission of your content or materials (e.g., receipt) transmitted to Tyket or via the Program website/app;",
            },
            {
              letter: "e",
              text: "whether the Program website, app or the servers that make the Program website and app available are free from any harmful components (including viruses, Trojan horses, and other technologies that could adversely impact your Device);",
            },
            {
              letter: "f",
              text: "whether the information (including any instructions) on the Program website and app is accurate, complete, correct, adequate, useful, timely, or reliable;",
            },
            {
              letter: "g",
              text: "whether any defects to or errors on the Program website and app will be repaired or corrected; ",
            },
            {
              letter: "h",
              text: "whether your access to the Program website and/or app will be uninterrupted;",
            },
            {
              letter: "i",
              text: "whether the Program website/app will be available at any particular time or location; and",
            },
            {
              letter: "j",
              text: "whether your use of the Program is lawful in any particular jurisdiction.",
            },
          ]}
        />
        <Typography>
          EXCEPT FOR ANY SPECIFIC WARRANTIES PROVIDED HEREIN OR IN ADDITIONAL TERMS PROVIDED BY A
          PROGRAM PARTY, TYKET PARTIES HEREBY FURTHER DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED,
          INCLUDING THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
          NON-INFRINGEMENT OR MISAPPROPRIATION OF INTELLECTUAL PROPERTY RIGHTS OF THIRD PARTIES,
          TITLE, CUSTOM, TRADE, QUIET ENJOYMENT, SYSTEM INTEGRATION, AND FREEDOM FROM COMPUTER
          VIRUS.
        </Typography>
        <Typography>
          Some jurisdictions limit or do not allow the disclaimer of implied or other warranties so
          the above disclaimers may not apply to the extent such jurisdictions’ laws are applicable.
        </Typography>
        <Link href="#linkable-table-of-contents">Return to Terms Table of Contents</Link>
        <Typography>
          <Typography component="span" fontWeight="bold" id="limitation-of-liability-section">
            8. Limitation on Liability.{" "}
          </Typography>
          <Typography component="span">
            UNDER NO CIRCUMSTANCES WILL ANY PROGRAM PARTIES BE RESPONSIBLE OR LIABLE FOR ANY LOSS OR
            DAMAGES OF ANY KIND, including personal injury or death or for any direct, indirect,
            economic, exemplary, special, punitive, incidental, or consequential losses or damages
            that are directly or indirectly related to:
          </Typography>
        </Typography>
        <AlphabeticList
          items={[
            {
              letter: "a",
              text: "the Program and the website/app that makes the Program available;",
            },
            {
              letter: "b",
              text: "your activities in connection with the Program and Program website/app;",
            },
            {
              letter: "c",
              text: "your use of or inability to use the Program, or the performance of the website/app associated with the Program;",
            },
            {
              letter: "d",
              text: "any action taken in connection with an investigation by Program Parties or law enforcement authorities regarding your access to or use of the Program;",
            },
            {
              letter: "e",
              text: "any action taken in connection with copyright or other intellectual property owners or other rights owners;",
            },
            {
              letter: "f",
              text: "any errors or omissions in the Program’s and Program website’s/app’s technical operation; or",
            },
            {
              letter: "g",
              text: "any damage to any user’s computer, hardware, software, modem, or other equipment or technology, including damage from any security breach or from any virus, bugs, tampering, fraud, error, omission, interruption, defect, delay in operation or transmission, computer line, or network failure or any other technical or other malfunction, including losses or damages in the form of lost profits, loss of goodwill, loss of data, work stoppage, accuracy of results, or equipment failure or malfunction.",
            },
          ]}
        />
        <Typography>
          The foregoing limitations of liability will apply even if any of the foregoing events or
          circumstances were foreseeable and even if Program Parties were advised of or should have
          known of the possibility of such losses or damages, regardless of whether you bring an
          action based in contract, negligence, strict liability, or tort (including whether caused,
          in whole or in part, by negligence, acts of god, telecommunications failure, or
          destruction of the Program).
        </Typography>
        <Typography>
          Some jurisdictions do not allow the exclusion or limitation of incidental or consequential
          damages of the sort that are described above, so the above limitation or exclusion may not
          apply to you.
        </Typography>
        <Typography fontWeight="bold" textTransform="uppercase">
          EXCEPT AS MAY BE PROVIDED IN ANY ADDITIONAL TERMS, TO THE FULLEST EXTENT PERMITTED BY
          APPLICABLE LAW, IN NO EVENT WILL PROGRAM PARTIES’ TOTAL LIABILITY TO YOU, FOR ALL POSSIBLE
          DAMAGES, LOSSES, AND CAUSES OF ACTION IN CONNECTION WITH YOUR ACCESS TO AND USE OF THE
          SERVICE AND YOUR RIGHTS UNDER THESE TERMS, EXCEED AN AMOUNT EQUAL TO THE AMOUNT YOU HAVE
          PAID TYKET IN CONNECTION WITH THE TRANSACTION(S) THAT UNDERLIE THE CLAIM(S); PROVIDED,
          HOWEVER, THIS PROVISION WILL NOT APPLY IF A TRIBUNAL WITH APPLICABLE JURISDICTION FINDS
          SUCH TO BE UNCONSCIONABLE. FOR PURPOSES OF CLARITY, THE PRIOR SENTENCE DOES NOT EXPAND OR
          LIMIT ANY EXPRESS, WRITTEN PRODUCT WARRANTY THAT IS PROVIDED BY TYKET OR A MANUFACTURER OF
          A PHYSICAL PRODUCT.
        </Typography>
        <Link href="#linkable-table-of-contents">Return to Terms Table of Contents</Link>
        <Typography>
          <Typography component="span" fontWeight="bold" id="our-rights-section">
            9. Our Rights.{" "}
          </Typography>
          <Typography component="span">
            Tyket reserves the right to modify, suspend, cancel or discontinue the Program, in whole
            or in part, for any reason, at its sole discretion, with or without notice to Members.
            Tyket may, among other things, withdraw, limit, modify, suspend or cancel any item
            and/or modify or regulate the Tyks, items or benefits you may have accrued. Each Member
            agrees that Tyket will not be liable to the Member or any third party for any
            modification or discontinuance of the Program. Tyket reserves the right to interpret and
            apply the policies and procedures communicated in these Terms. All determinations by
            Tyket, including determinations of eligibility, and proper authorization of Tyks and
            item redemption, shall be final and conclusive in each case. Tyket reserves the right to
            terminate any Member’s participation in the Program, to deny award of any item and/or
            terminate service if, in Tyket’s sole judgment, such Member has in any way violated
            these Terms.
          </Typography>
        </Typography>
        <Link href="#linkable-table-of-contents">Return to Terms Table of Contents</Link>
        <Typography>
          <Typography component="span" fontWeight="bold" id="fraud-section">
            10. Fraud.{" "}
          </Typography>
          <Typography component="span">
            Fraud or abuse relating to the registration process, providing of personal information,
            or redemption of items is a violation of these. Users are solely responsible for any
            fraudulent use that may occur due to the theft of or sharing of a Member’s password.
            Members agree to immediately notify Tyket of any unauthorized use of their account or
            any other breach of security known to them. The Program is a service provided to an
            individual Member, not a company or other entity, and a company or other entity may not
            direct, encourage or allow individuals to participate for anything other than individual
            use. Tyket reserves the right to terminate or suspend a Member’s participation in the
            Program and/or void a Member’s Tyks if any Member engages in fraudulent activity or
            otherwise uses this Program other than in accordance with these Terms and applicable
            law.
          </Typography>
        </Typography>
        <Typography>
          ANY ATTEMPT BY ANY MEMBER OR ANY OTHER INDIVIDUAL OR ENTITY TO DAMAGE ANY WEB SITE OR
          UNDERMINE THE LEGITIMATE OPERATION OF THE PROGRAM IS A VIOLATION OF THESE TERMS. TYKET
          RESERVES THE RIGHT TO INVESTIGATE ANY SUSPICIOUS ACTIVITY AND TO SEEK DAMAGES FROM ANY
          SUCH PERSON TO THE FULLEST EXTENT PERMITTED BY LAW.
        </Typography>
        <Link href="#linkable-table-of-contents">Return to Terms Table of Contents</Link>
        <Typography>
          <Typography component="span" fontWeight="bold" id="indemnity-section">
            11. Indemnity.{" "}
          </Typography>
          <Typography component="span">
            In exchange for the right to participate in the Program, you agree to indemnify, defend
            (at our option) and hold us harmless from and against any and all damages, losses,
            liabilities, claims, costs, investigations, judgments, fines, penalties, settlements,
            interest, expenses or demands, including, but not limited to, personal injury, death, or
            damage to or loss of property, that directly or indirectly arise from or are related to:
            (i) your User-Generated Content; (ii) your use of the Program and your activities in
            connection with the Program; (iii) your breach or anticipatory breach of these Terms or
            any Additional Terms; (iv) your violation or anticipatory violation of any laws, rules,
            regulations, codes, statutes, ordinances, or orders of any governmental or
            quasi-governmental authorities in connection with your use of the Program or your
            activities in connection with the website, app and the Program; (v) information or
            material transmitted through your Device, even if not submitted by you, that infringes,
            violates, or misappropriates any copyright, trademark, trade secret, trade dress,
            patent, publicity, privacy, or other right of any person or entity; (vi) any
            misrepresentation made by you; and (vii) our use of the information that you submit to
            us (including your User-Generated Content) (all of the foregoing, “Claims and Losses”).
            You will cooperate as fully required by us in the defense of any Claim and Losses.
            Notwithstanding the foregoing, we retain the exclusive right to settle, compromise, and
            pay any and all Claims and Losses. We reserve the right to assume the exclusive defense
            and control of any Claims and Losses. You will not settle any Claims and Losses without,
            in each instance, the prior written consent of an officer of Tyket. We are not
            responsible for technical, hardware, network connections or incomplete or delayed
            computer transmissions, regardless of cause.
          </Typography>
        </Typography>
        <Link href="#linkable-table-of-contents">Return to Terms Table of Contents</Link>
        <Typography>
          <Typography component="span" fontWeight="bold" id="updates-to-terms-section">
            12. Updates to Terms.{" "}
          </Typography>
          <Typography component="span">
            These Terms (or if applicable Additional Terms), in the form posted at the time of your
            use of the applicable services to which they apply, shall govern such use (including
            transactions entered during such use). AS OUR SERVICE EVOLVES, THE TERMS AND CONDITIONS
            UNDER WHICH WE OFFER THE PROGRAM MAY BE MODIFIED AND WE MAY CEASE OFFERING THE PROGRAM
            UNDER THE TERMS OR ADDITIONAL TERMS FOR WHICH THEY WERE PREVIOUSLY OFFERED. ACCORDINGLY,
            EACH TIME YOU SIGN IN TO OR OTHERWISE USE THE PROGRAM YOU ARE ENTERING INTO A NEW
            AGREEMENT WITH US ON THE THEN APPLICABLE TERMS AND CONDITIONS AND YOU AGREE THAT WE MAY
            NOTIFY YOU OF OTHER TERMS BY POSTING THEM ON THE PROGRAM (OR IN ANY OTHER REASONABLE
            MANNER OF NOTICE WHICH WE ELECT), AND THAT YOUR USE OF THE PROGRAM AFTER SUCH NOTICE
            CONSTITUTES YOUR GOING FORWARD AGREEMENT TO THE REVISED TERMS. Therefore, you should
            review the posted terms of service and any applicable Additional Terms each time you use
            the Program (at least prior to each transaction or submission). The revised Terms will
            be effective as to new use and transactions as of the time that we post them, or such
            later date as may be specified in them or in other notice to you. However, the Terms
            (and any applicable Additional Terms) that applied when you previously used the Program
            will continue to apply to such prior use (i.e., changes and additions are prospective
            only) unless mutually agreed. In the event any notice to you of new, revised or
            additional terms is determined by a tribunal to be insufficient, the prior agreement
            shall continue until sufficient notice to establish a new agreement occurs. You should
            frequently check the home page, and the e-mail you associated with your Member Account
            for notices, all of which you agree are reasonable manners of providing you notice. You
            can reject any new, revised or Additional Terms by discontinuing use of the Program and
            related services.
          </Typography>
        </Typography>
        <Link href="#linkable-table-of-contents">Return to Terms Table of Contents</Link>
        <Typography>
          <Typography
            component="span"
            fontWeight="bold"
            id="communications-notices-customer-service-section"
          >
            13. Communications, Notices and Customer Service.
          </Typography>
          <Typography component="span">
            By signing-up for the Program, you agree that: (i) we may give you notices of important
            matters by prominently posting notice on the home page of the Program or in another
            reasonable manner we determine in our sole discretion; and (ii) we may contact you and
            send you communications by postal mail and e-mail at the addresses provided in your
            Member Account. These communications may include marketing communications about the
            Program as well as other promotional offers unrelated to the Program and you consent to
            receive these communications from us even if you previously indicated to us that you no
            longer wanted to receive communications from us. Your sign-up to the Program will serve
            as your express agreement to receive these marketing and similar communications from us.
            You may prospectively modify certain types of email communications that you receive from
            us relating to the Program by following the instructions contained within such emails.
            Such changes will only impact our email communications to the extent described in the
            modification process. You agree to promptly notify us if you change your e-mail or
            mailing address by updating your Member Account.
          </Typography>
          <Typography>
            If you have a question regarding using the Program, you may contact Tyket Customer
            Support by calling our toll-free number at [Insert Phone Number]. You acknowledge that
            the provision of customer support is at our sole discretion and that we have no
            obligation to provide you with customer support of any kind.
          </Typography>
        </Typography>
        <Link href="#linkable-table-of-contents">Return to Terms Table of Contents</Link>
        <Typography>
          <Typography component="span" fontWeight="bold" id="general-provisions-section">
            14. General Provisions.
          </Typography>
        </Typography>
        <RewardsSublist
          items={[
            {
              letter: "A",
              header: "Severability",
              text: "If any provision of these Terms, or any Additional Terms, is for any reason deemed invalid, unlawful, void, or unenforceable by a court or arbitrator of competent jurisdiction, then that provision will be deemed severable from these Terms or the Additional Terms, and the invalidity of the provision will not affect the validity or enforceability of the remainder of these Terms or the Additional Terms (which will remain in full force and effect).  To the extent permitted by applicable law, you agree to waive, and you hereby waive, any applicable statutory and common law that may permit a contract to be construed against its drafter.",
            },
            {
              letter: "B",
              header: "Investigations; Cooperation with Law Enforcement; Termination; Survival",
              text: "We reserve the right, without any limitation, to: (i) investigate any suspected breaches of its site’s security or its information technology or other systems or networks; (ii) investigate any suspected breaches of these Terms and any Additional Terms; (iii) investigate any information obtained by us in connection with reviewing law enforcement databases or complying with criminal laws; (iv) involve and cooperate with law enforcement authorities in investigating any of the foregoing matters; (v) prosecute violators of these Terms and any Additional Terms; and (vi) discontinue the Program, in whole or in part, or, except as may be expressly set forth in any Additional Terms, suspend or terminate your access to it, in whole or in part, including any user accounts or registrations, at any time, without notice, for any reason and without any obligation to you or any third party.  Any suspension or termination will not affect your obligations to us under these Terms or any Additional Terms.  Upon suspension or termination of your access to the Program, or upon notice from us, all rights granted to you under these Terms or any Additional Terms will cease immediately, and you agree that you will immediately discontinue use of the Program.  The provisions of these Terms and any Additional Terms, which by their nature should survive your suspension or termination will survive, including the rights and licenses you grant to us in these Terms, as well as the indemnities, releases, disclaimers, and limitations on liability and the provisions regarding jurisdiction, choice of law, no class action, and mandatory arbitration.",
            },
            {
              letter: "C",
              header: "Assignment",
              text: "We may assign its rights and obligations under these Terms and any Additional Terms, in whole or in part, to any party at any time without any notice.  These Terms and any Additional Terms may not be assigned by you, and you may not delegate your duties under them, without the prior written consent of an officer of Tyket.",
            },
            {
              letter: "D",
              header: "No Waiver",
              text: "Except as expressly set forth in these Terms or any Additional Terms, (i) no failure or delay by you or us in exercising any of rights, powers, or remedies under will operate as a waiver of that or any other right, power, or remedy, and (ii) no waiver or modification of any term of these Terms or any Additional Terms will be effective unless in writing and signed by the party against whom the waiver or modification is sought to be enforced.",
            },
            {
              letter: "E",
              header: "Connectivity",
              text: "You are responsible for obtaining and maintaining all Devices and other equipment and software, and all internet service provider, mobile service, and other services needed for your access to and use of the Program and you will be responsible for all charges related to them.",
            },
          ]}
        />
      </Stack>
    </Container>
  );
}
