import { graphql } from "../gql";
import { GetBusinessProfileInfoQuery } from "../gql/graphql";

export const getBusinessProfileInfoRequest = graphql(`
  query getBusinessProfileInfo($userId: String!) {
    businessProfileInfo(userId: $userId) {
      status
      message
      errorMessageId
      payload {
        id
        name
        type
        joinedDate
        renewalDate
        renewalPeriod
        email
        phonenumber
        status
        isStagewoodProfile
        statusUpdatedAt
        dueDate
        membershipCancellationDate
        accountId
        businessAddresses {
          id
          streetAddress1
          streetAddress2
          zipCode
          homeCity
          tykchaseLocation {
            id
            isPrimaryLocation
            createdAt
            updatedAt
            isEnabled
            tykchaseLocationReceiptVariants {
              id
              storeTitle
              storeAddressNormalized
              receiptUrl
              phoneNumber
              createdAt
              updatedAt
              deletedAt
            }
          }
        }
        membership {
          id
          internalId
          title
        }
        qrCode
        activeRules {
          id
          loneStarReward
          status {
            id
            title
          }
        }
        broker {
          id
          business {
            firstname
            lastname
            stageName
            username
            email
            publicEmail
            profilePicture
            phonenumber
            account {
              email
              firstname
              lastname
            }
          }
        }
      }
    }
  }
`);

export type BusinessProfileInfoPayload = NonNullable<GetBusinessProfileInfoQuery["businessProfileInfo"]>["payload"];
