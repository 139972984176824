import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import eyeIcon from "../../../../assets/icons/tykIcons/eye-icon.svg";
import clickIcon from "../../../../assets/icons/tykIcons/click-icon.svg";
import moneyBillIcon from "../../../../assets/icons/tykIcons/money-bill-icon.svg";
import { ExclamationTriangle } from "../../../../utils/icon";
import theme from "../../../../theme";
import AnalyticsCard from "../../AnalyticsCard";
import { Campaign } from "../../types";
import { campaigns } from "../../data";
import useStyles from "./styles";

const Marketing = () => {
  const { campaignItemWrap, emptyStateWrap } = useStyles();
  const [activeCampaign, setActiveCampaign] = useState<Campaign[]>([]);
  useEffect(() => {
    setActiveCampaign(campaigns);
  }, []);

  return (
    <AnalyticsCard
      headerTitle="analytics.marketing.title"
      headerSubtitle="analytics.marketing.sub_title"
      cardColor="#F4F6FA"
      dataLength={activeCampaign ? activeCampaign.length : 0}
    >
      {activeCampaign && activeCampaign.length ? (
        activeCampaign.slice(0, 2).map((campaign) => (
          <Box className={campaignItemWrap} key={campaign.id}>
            <Box className="campaignItemImageWrap">
              <img className="campaignImage" src={campaign.image} alt="" />
            </Box>

            <Box className="campaignItemContentWrap">
              <Typography
                color={theme.palette.primary.dark}
                fontFamily={theme.typography.fontFamily}
                fontSize="0.75rem"
              >
                {campaign.name}
              </Typography>

              <Box className="campaignItemContent">
                <Box className="campaignItemStatistic">
                  <Box>
                    <img src={eyeIcon} alt="Views" />
                  </Box>

                  <Typography
                    color={theme.palette.primary.dark}
                    fontFamily={theme.typography.fontFamily}
                    fontSize="0.625rem"
                    fontWeight="bold"
                  >
                    {campaign.views?.toLocaleString()}
                  </Typography>
                </Box>

                <Box className="campaignItemStatisticDivider" />

                <Box className="campaignItemStatistic">
                  <Box>
                    <img src={clickIcon} alt="Clicks" />
                  </Box>

                  <Typography
                    color={theme.palette.primary.dark}
                    fontFamily={theme.typography.fontFamily}
                    fontSize="0.625rem"
                    fontWeight="bold"
                  >
                    {campaign.clicks?.toLocaleString()}
                  </Typography>
                </Box>

                <Box className="campaignItemStatisticDivider" />

                <Box className="campaignItemStatistic">
                  <Box>
                    <img src={moneyBillIcon} alt="Spent" />
                  </Box>

                  <Typography
                    color={theme.palette.primary.dark}
                    fontFamily={theme.typography.fontFamily}
                    fontSize="0.625rem"
                    fontWeight="bold"
                  >
                    ${campaign.spent.toLocaleString()}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        ))
      ) : (
        <Box className={emptyStateWrap}>
          <ExclamationTriangle fill="#818FD4" />

          <Typography marginLeft="10px" color="#797882" fontFamily={theme.typography.fontFamily} fontSize="0.75rem">
            <FormattedMessage id="analytics.marketing.no_advertising" />
          </Typography>
        </Box>
      )}
    </AnalyticsCard>
  );
};

export default Marketing;
