import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    rewardsInfoContainer: {
      "@media(max-width: 900px)": {
        padding: "0 20px",
      },
      "@media(max-width: 600px)": {
        justifyContent: "center",
        padding: "10px 20px 35px",
      },
      "&.MuiGrid-root": {
        alignItems: "flex-end",
        marginTop: "3rem",
        marginLeft: "4rem",
        "@media(max-width: 600px)": {
          marginLeft: "0",
        },
        "& .rewartsInfo-spent-container": {
          textAlign: "center",
          marginLeft: "3rem",
          marginRight: "3rem",
          "& .MuiTypography-root": {
            "&.rewardsInfo-all": {
              fontFamily: theme?.typography?.fontFamily,
              fontWeight: "600",
              fontSize: "1.3rem",
              color: "#4B4B4B",
              marginLeft: "5px",
              marginBottom: "5px",
            },
            "& .rewardsInfo-spent": {
              fontFamily: theme?.typography?.fontFamily,
              fontWeight: "600",
              fontSize: "1.3rem",
              display: "inline",
              color: "#D3202F",
            },
          },
          "& .rewardsInfo-spent-label": {
            color: "#6E6E6E",
          },
        },

        "& .rewardsInfo-divider": {
          borderRightWidth: "2px",
          borderColor: "#8E8E8E",
          height: "30px",
        },

        "& .rewardsInfo-tyksTransfered-container": {
          textAlign: "center",
          marginRight: "3rem",

          "@media(max-width: 600px)": {
            marginLeft: "3rem",
          },

          "& .MuiGrid-root": {
            marginBottom: "5px",
          },

          "& .centors": {
            height: "30px",
          },

          "& .rewardsInfo-tyksTransfered-amount": {
            fontFamily: theme.typography.fontFamily,
            color: "#4B4B4B",
            fontWeight: "600",
            fontSize: "1.3rem",
          },
          "& .rewardsInfo-tyksTransfered-label": {
            color: "#6E6E6E",
            mt: "8px",
          },
        },

        "& .rewardsInfo-activeMembership-container": {
          fontSize: "1rem",
          color: "#4B4B4B",
          marginRight: "0rem",
          marginTop: "5px",
          "@media(max-width: 900px)": {
            marginTop: "10px",
          },
          "& .MuiTypography-root": {
            display: "inline",
            fontWeight: "600",
          },
        },
      },
      "& .padBoxTop2": {
        "@media (max-width: 600px)": {
          paddingTop: theme.spacing(2),
        },
        [theme.breakpoints.down("md")]: {
          paddingTop: 0,
        },
      },
    },
    buttonRules: {
      borderRadius: "2rem",
      height: "40px",
      width: "100px",
      textTransform: "none",
      padding: "0.3rem 1rem",
      "&:focus": {
        color: "#797882",
        backgroundColor: "#C6C6C666",
      },
    },
    viewButtons: {
      padding: ".8em",
      "& .MuiTypography-body1": {
        fontFamily: theme.typography.fontFamily,
      },
      "& :hover": {
        cursor: "pointer",
      },
    },
    dropDown: {
      maxWidth: "310px",
      flexBasis: "35% !important",
      "@media (max-width: 1200px)": {
        maxWidth: "100%",
        flexBasis: "100% !important",
      },
      "@media (max-width: 600px)": {
        margin: "0 !important",
      },
      "& .react-dropdown-select": {
        maxHeight: "32px",
        minHeight: "32px",
        marginTop: "5px",
        backgroundColor: "#FAFAFA",
        borderColor: "#CACACA",
        "& .react-dropdown-select-content": {
          fontSize: "0.9rem",
          fontStyle: "italic",
          color: "#4B4B4B",
          opacity: "1 !important",
          margin: "0 7px",
        },
      },
      "& .MuiGrid-root.MuiGrid-item": {
        paddingBottom: 0,
        paddingRight: 0,
        "@media (max-width: 600px)": {
          paddingLeft: 0,
        },

        "& .MuiTypography-root": {
          color: "#6E6E6E",
          fontSize: "0.75rem",
          fontFamily: theme?.typography?.fontFamily,
        },
      },
    },
    stateButtons: {
      maxWidth: "100% !important",
      flexBasis: "75% !important",

      "@media (max-width: 1200px)": {
        justifyContent: "space-evenly",
        marginLeft: "0 !important",
        flexBasis: "100% !important",
      },
      "& .MuiFormControlLabel-root": {
        cursor: "pointer",
        "@media(max-width: 1200px)": {
          margin: "0",

          "&:nth-child(2)": {
            margin: "0 10px",
          },
        },
      },
      "& .MuiCheckbox-root": {
        padding: "3px",
        height: "32px",

        "& .MuiSvgIcon-root": {
          fill: "#4DA17E",
        },
      },
      "& .MuiTypography-root": {
        color: "#4B4B4B",
        fontSize: "0.9rem",
        lineHeight: "1rem",
      },
    },
    membershipCard: {
      "& .MuiPaper-root": {
        background: "#E8E8E8",
        padding: "2em",
        "&:hover": {
          cursor: "pointer",
        },
      },
      "& .MuiPaper-rounded": {
        borderRadius: "4px 4px 0px 0px",
      },
      "& .css-eglki6-MuiLinearProgress-root": {
        background: "#C9D7F4",
        borderRadius: 2,
      },
      "& .css-5xe99f-MuiLinearProgress-bar1": {
        background: "#4262FF",
        borderRadius: 2,
      },
      "& .MuiTypography-h6": {
        color: "#000000",
      },
      opacity: "50%",
    },
    cardSelected: {
      "& .MuiPaper-root": {
        border: "2px solid #5972FE",
        borderBottom: 0,
      },
      opacity: 1,
    },
    switchBtn: {
      "& .MuiIconButton-root": {
        padding: 0,
      },
    },
    ruleControls: {
      color: "#4262FF",
      fontSize: "1rem",
      fontWeight: 600,
      transition: "all 300ms ease-in-out",
      position: "relative",
    },
    deactivateSelected: {
      "&.MuiButton-root": {
        color: "#FFFFFF",
        textTransform: "unset",
        background: "#CDCCD7",
        boxShadow: "none",
        borderRadius: "6px",
        "&:hover": {
          background: "#CDCCD7",
          boxShadow: "none",
        },

        "&.active": {
          background: "#FFBE40",
        },
      },
    },
    deactivateAll: {
      "&.MuiButton-root": {
        color: "#8986AD",
        marginLeft: "1rem",
        textTransform: "unset",
        borderColor: "#8986AD",
        borderRadius: "6px",
        opacity: "0.5",
        transition: "all 300ms ease-in-out",
        "&:hover": {
          borderColor: "#8986AD",
        },
        "&.active": {
          opacity: "1",
        },
      },
    },
    headCheckBox: {
      "&.MuiCheckbox-root": {
        color: "#797882",
        padding: "1rem 0",
        marginBottom: "2px",
      },
      "& .MuiCheckbox-colorSecondary.Mui-checked": {
        color: "#4DA17E",
      },
      "& .PrivateSwitchBase-input:checked + svg.MuiSvgIcon-root": {
        fill: "#4DA17E",
      },
    },
    checkBox: {
      "& .MuiCheckbox-colorSecondary.Mui-checked": {
        color: "#4DA17E",
      },
      "& .MuiCheckbox-root": {
        color: "#797882",
      },
      "& .PrivateSwitchBase-input:checked + svg.MuiSvgIcon-root": {
        fill: "#4DA17E",
      },
    },
    modal: {
      "& .MuiPaper-root": {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "35rem",
      },
      "& .css-sox5kk-MuiBackdrop-root": {
        backgroundColor: "rgba(0, 0, 0, 0.1)",
      },
      "& #file_larger": {
        color: "#8986AD",
        width: "2.75em !important",
        height: "3em !important",
      },
      "& #file_smaller": {
        color: "#8986AD",
        width: "1.25em !important",
        height: "1.5em !important",
        marginRight: "0.5em",
      },
    },
    footerBtn: {
      borderRadius: "20px !important",
      textTransform: "none",
      width: "6.5rem",
    },
    cancelBtn: {
      border: "2px solid #8986AD",
      color: "#8986AD",
      borderRadius: "20px",
      textTransform: "none",
      width: "6.5rem",
    },
    saveBtn: {
      background: "#203ED4",
      color: "#FAFAFA",
      borderRadius: "20px",
      textTransform: "none",
      width: "6.5rem",
    },
    rewardInput: {
      "&.MuiInputBase-root": {
        border: "2px solid #8986AD",
        borderRadius: 3,
        fontFamily: "AdobeClean",
        fontSize: "1em",
        height: "4rem",
        width: "10rem",
        "&::placeholder": {
          color: "#9C9BA3",
          textAlign: "center",
          fontSize: "1em",
          opacity: 1,
        },
        "& input": {
          paddingLeft: "23px",
          "&::-webkit-outer-spin-button,&::-webkit-inner-spin-button": {
            display: "none",
            margin: 0,
          },
        },
      },
    },
    сancelButton: {
      "&.MuiButton-root": {
        border: "2px solid #8986AD",
        color: "#8986AD",
        borderRadius: "20px",
        textTransform: "none",
        width: "6.5rem",
      },
    },
    modalContainer: {
      padding: "4em 3em 2em",
    },
    saveButton: {
      "&.MuiButton-root": {
        background: "#203ED4",
        color: "#FAFAFA",
        borderRadius: "20px",
        textTransform: "none",
        width: "6.5rem",
      },
    },
    fileUpload: {
      "&.MuiInputLabel-root": {
        border: "2px solid #8986AD",
        borderRadius: 3,
        textAlign: "center",
        height: "4rem",
        padding: "1em",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        width: "11rem",
        marginTop: 0,
        cursor: "pointer",
        "& p": {
          fontFamily: "AdobeClean !important",
          color: "#9C9BA3",
          fontSize: "1em",
        },
      },
    },
    imagePreview: {
      background: "#C9D7F4",
      padding: "2em",
      opacity: "0.3",
      borderRadius: 3,
    },
    caption: {
      "&.MuiTypography-body1": {
        fontFamily: "AdobeClean",
        color: "#9C9BA3",
        fontSize: "0.75rem",
        textAlign: "right",
      },
    },
  };
});

export default useStyles;
