import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    mainContainer: {
      display: "flex",
      height: "100vh",
      width: "100%",

      "@media all and (max-width: 960px)": {
        flexDirection: "column",
      },
    },
    insideContainer: {
      padding: "2rem",
      overflowY: "auto",
      [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
        padding: "2rem 1rem",
      },
    },
    choosePlanContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
      minWidth: "270px",
      justifyContent:"space-between",
      marginBottom:'1rem',
    },
    paymentTypeLabel: {
      fontSize: "0.75rem",
      color: "#747474",
      textAlign: "center",
    },
    priceDataContainer: {
      width: "100%",
      height: "10rem",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#E5E5EA",
      borderRadius: "5px",
      marginTop: "0.5rem",
      marginBottom: "3rem",
    },
    annualPriceContainer: {
      height: "100%",
      backgroundColor: "#CBCAD5",
      borderRadius: "5px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      padding: "0 8px",
      "& span": {
        color: "rgb(137, 134, 173, 0.8)",
      },
    },
    annualPrice: {
      fontWeight: "bold",
      color: "#4262FF",
      fontSize: "1.75rem",
    },
    monthlyPrice: {
      fontWeight: "bold",
      color: "#167B80",
      fontSize: "1.75rem",
    },
    descriptionStyle: {
      fontSize: "0.75rem",
      color: "#747474",
    },
    amountPerLocation: {
      fontWeight: "bold",
      paddingRight: "1rem",
    },
    divider: {
      width: "100%",
      margin: "0.75rem 0",
      backgroundColor: "#D4D4D4",
    },
    greenFontColor: {
      color: "#167B80",
    },
    leftPriceContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      justifyContent: "center",
      padding: "1rem 2rem",

      [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
        padding: "1rem",
      },
    },
    flexContainer: {
      display: "flex",
      alignItems: "center",
      gap: "1rem",
    },
    listContainer: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      maxHeight: "500px",
      padding: "1rem 0",
      marginBottom: "1rem",

      "& .MuiListItem-root": {
        padding: "4px",
      },

      [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
        maxHeight: "350px",
      },
    },
    listItems: {
      maxWidth: "300px",
      cursor: "default",

      [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
        maxWidth: "300px",
      },
    },
    listText: {
      color: "#707070",
      overflowWrap: "break-word",

      "& .MuiListItemText-primary ": {
        fontFamily: theme.typography.fontFamily,
        fontSize: "0.75rem",
      },
    },
  };
});

export default useStyles;
