import { FC } from "react";
import { Box } from "@mui/material";
import { FormattedMessage } from "react-intl";
import Modal from "../../../Modal";
import useStyles from "./styles";
import { ReactComponent as ContractIcon } from "../../../../assets/icons/paymentIcons/contractIcon.svg";

interface PaymentModalProps {
  modalActive: boolean;
  onClose: () => void;
}

const PaymentModal: FC<PaymentModalProps> = ({ modalActive, onClose: handleClose }) => {
  const { wrap, titleContainer, termsSection, termsContainer } = useStyles();

  return (
    <Modal isOpen={modalActive} onClose={handleClose}>
      <div className={wrap}>
        <div className={titleContainer}>
          <ContractIcon width={70} />
          <h4>
            <FormattedMessage id="modals.terms_of_service" />
          </h4>
        </div>

        <div className={termsSection}>
          <Box className={termsContainer}>
            <iframe src="/terms-of-service" title="Terms of Use iframe" width="560px" height="100%" seamless />
          </Box>
        </div>
      </div>
    </Modal>
  );
};

export default PaymentModal;
