import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    suggestedExperiencesContainer: {
      width: "100%",
      marginTop: "24px",

      "& .suggestedExperiencesTitle": {
        marginBottom: "10px",
        color: theme.palette.experiences.darkPurple,
        fontFamily: theme.typography.fontFamily,
        fontSize: "0.875rem",
        fontWeight: "600",
      },

      "& .carousel": {
        "& .switch-button": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "26px",
          margin: "10px 0",
          backgroundColor: "#E9E9E9",

          "& .MuiSvgIcon-root": {
            width: "28px",
            height: "28px",
            fill: theme.palette.experiences.blue,
          },
        },
      },
    },
  };
});

export default useStyles;
