import { FC } from "react";
import { Tabs, Tab } from "@mui/material";
import { Link, useRouteMatch } from "react-router-dom";
import { useIntl } from "react-intl";
import useStyles from "./styles";
import { Routes } from "../../../constants";

export const BillingPaymentsTabs: FC = () => {
  const intl = useIntl();
  const { tabsContainer } = useStyles();
  const routeMatch = useRouteMatch();
  const currentTab = routeMatch?.path;

  return (
    <Tabs value={currentTab} className={tabsContainer}>
      <Tab
        label={intl.formatMessage({ id: "billing_payments_tabs_billing" })}
        value={Routes.BILLING}
        to={Routes.BILLING}
        component={Link}
      />
      <Tab
        label={intl.formatMessage({ id: "billing_payments_tabs_payments" })}
        value={`${Routes.BILLING}${Routes.PAYMENTS}`}
        to={`${Routes.BILLING}${Routes.PAYMENTS}`}
        component={Link}
      />
    </Tabs>
  );
};
