import { Button, Grid } from "@mui/material";
import { ReactChild } from "react";
import { FormattedMessage } from "react-intl";
import useStyles from "./styles";

interface LayoutProps {
  title: ReactChild | string;
  subtitle: string;
  headerControls?: ReactChild;
  subHeader?: ReactChild;
  children: ReactChild | (() => ReactChild);
  footer: ReactChild | (() => ReactChild);
  handleSave?: () => void;
  handleCancel?: () => void;
}

const Layout = ({
  title,
  subtitle,
  headerControls,
  subHeader,
  children,
  footer,
  handleSave,
  handleCancel,
}: LayoutProps) => {
  const { modalHeader, modalBody, modalFooter, buttonsContainer } = useStyles();

  return (
    <Grid container direction="column">
      <div className={modalHeader}>
        <div className="left-side">
          <h1>{title}</h1>
          <p className="subtitle">{subtitle}</p>
        </div>

        <Grid>{headerControls}</Grid>
      </div>

      {subHeader}

      <div className={modalBody}>{children}</div>

      <div className={modalFooter}>{footer}</div>

      <div className={buttonsContainer}>
        <Button onClick={handleCancel}><FormattedMessage id="btn_text_cancel"/></Button>
        <Button onClick={handleSave}><FormattedMessage id="btn_text_save"/></Button>
      </div>
    </Grid>
  );
};

export default Layout;
