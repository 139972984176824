import { IEChartsOption, Series } from "../../../../../types/charts";

const colors = {
  one: "#2F444C",
  two: "#C74E72",
  three: "#276733",
  four: "#FFBF00",
};

const values = {
  one: [1, 2, 3],
  two: [1, 0, 2],
  three: [0, 4, 6],
  four: [2, 7, 10],
};

const getMultiLineSeries = () => {
  const baseSettings = {
    type: "line",
    symbolSize: false,
  };
  const series: Series[] = [];

  Object.entries(values).map((entry) => {
    series.push({
      ...baseSettings,
      name: entry[0],
      data: entry[1],
      color: colors[entry[0] as keyof typeof colors],
    });
    return entry;
  });
  return { series };
};

export const getOptions = (): IEChartsOption => {
  const { series } = getMultiLineSeries();

  return {
    grid: {
      left: 10,
      top: 20,
      right: 20,
      bottom: 0,
      height: "240px",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      axisTick: { alignWithLabel: true },
      boundaryGap: [0, "10%"],
      data: ["1th", "2th", "3th"],
    },
    yAxis: {
      type: "value",
      min: 0,
    },
    series,
    tooltip: false,
  };
};
