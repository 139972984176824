import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    chatContainer: {
      width: "375px",

      "& .title-container": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "20px",

        "& .avatar-image": {
          width: "42px",
          height: "42px",
          marginRight: "12px",
          backgroundColor: "#8986AD",
          borderRadius: "50%",
        },

        "& h2": {
          color: "#34305D",
          fontSize: "0.875rem",
        },
      },

      "& .chat-container": {
        display: "flex",
        flexDirection: "column",
        maxHeight: "250px",
        padding: "10px",
        overflowY: "auto",

        "& .message-container": {
          display: "flex",
          flexDirection: "column",
          padding: "10px",

          "& .message-date": {
            marginBottom: "4px",
            fontSize: "0.75rem",
          },

          "& .message-text": {
            maxWidth: "250px",
            padding: "16px 20px",
            borderRadius: "6px",
            fontSize: "0.75rem",
            color: "#797882",
          },
        },

        "& .message-container.self": {
          paddingLeft: "40px",
          alignItems: "end",

          "& .message-date": {
            alignSelf: "end",
          },

          "& .message-text": {
            backgroundColor: "#E9E9E9",
          },
        },

        "& .message-container.winner": {
          paddingRight: "40px",

          "& .message-text": {
            backgroundColor: "#C9D7F4",
          },
        },

        "& .empty-message": {
          textAlign: "center",
        },
      },

      "& .new-message-container": {
        display: "flex",
        flexDirection: "column",
        alignItems: "end",
        padding: "20px",

        "& .MuiFormControl-root": {
          width: "100%",

          "& .MuiOutlinedInput-root": {
            "&:hover fieldset": {
              border: "1px solid #CDCCD7",
            },

            "&.Mui-focused fieldset": {
              border: "1px solid #CDCCD7",
            },

            "& .MuiInputBase-input": {
              fontFamily: theme.typography.fontFamily,
              fontSize: "0.6875rem",
              color: "#797882",
            },
          },
        },

        "& .MuiButtonBase-root": {
          width: "85px",
          height: "32px",
          marginTop: "14px",
          padding: "0 26px",
          fontSize: "0.875rem",
          borderRadius: "16px",
          boxShadow: "none",
          textTransform: "none",
          backgroundColor: "#1473E6",
          color: theme.palette.white,
          "&:hover": {
            backgroundColor: "#203ED4",
          },
        },
      },
    },
  };
});

export default useStyles;
