import { defineMessages, IntlShape } from "react-intl";

const messages = defineMessages({
  modalTitle: {
    id: "menu_memberships_enroll_cancel_modal_title",
    defaultMessage: "Are you sure you want to cancel your membership?",
  },
  modalDescr: {
    id: "menu_memberships_enroll_cancel_modal_descr",
    defaultMessage:
      "You will be able to use TykChase until the end of your billing period, but you won't be able to undo the cancellation. Are you sure you want to proceed?",
  },
  confirmButton: {
    id: "btn_text_yes",
    defaultMessage: "Yes",
  },
  cancelButton: {
    id: "btn_text_cancel",
    defaultMessage: "Cancel",
  },
});

const CancellationModalText = (intl: IntlShape) => {
  return {
    TITLE: intl.formatMessage(messages.modalTitle),
    DESCR: intl.formatMessage(messages.modalDescr),
    CONFIRM: intl.formatMessage(messages.confirmButton),
    CANCEL: intl.formatMessage(messages.cancelButton),
  };
};

interface ICancellationModal {
  open: boolean;
  onClose: () => void;
  userId: string;
}

export { CancellationModalText };
export type { ICancellationModal };
