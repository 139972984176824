import { gql } from "@apollo/client";

export const savePaymentMethodMutation = gql`
  mutation SavePaymentMethodMutation($input: SaveUserPaymentMethodInput!) {
    savePaymentMethod(input: $input) {
      id
      brand
      expMonth
      expYear
      lastFour
      token
      isDefault
      owner
      billingAddress {
        addressLine1
        addressLine2
        city
        state
        zipCode
        countryCode
      }
    }
  }
`;
