import { FC } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import sadTykCharacter from "../../assets/icons/tykIcons/sadTykCharacter.svg";
import theme from "../../theme";
import useStyles from "./styles";

interface ErrorPageProps {
  isShowButtons?: boolean;
}

const ErrorPage: FC<ErrorPageProps> = ({ isShowButtons = false }) => {
  const { container, buttonsWrapper } = useStyles();
  const history = useHistory();

  return (
    <Box display="flex" flexDirection="column" className={container}>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Box display="flex" justifyContent="center" alignItems="center" className="tykIconWrapper">
          <img src={sadTykCharacter} alt="" className="tykIcon" />
        </Box>

        <Typography fontFamily={theme.typography.fontFamily} fontSize="1.125rem" fontWeight="600" color="#505050">
          <FormattedMessage id="error.oops" />
        </Typography>

        <Typography fontFamily={theme.typography.fontFamily} fontSize="1rem" color="#505050" align="center">
          <FormattedMessage id="error.we_are_having_problems" />
        </Typography>
      </Box>

      {isShowButtons && (
        <Box className={buttonsWrapper}>
          <Button onClick={() => history.goBack()} variant="contained" className="secondary-button">
            <FormattedMessage id="btn_go_back" />
          </Button>

          <Button variant="contained" className="primary-button">
            <FormattedMessage id="btn_try_again" />
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default ErrorPage;
