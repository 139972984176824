import { ComponentType } from "react";
import AnalyticsTabs from "../AnalyticsTabs";

const withAnalyticsTabsLayout = (Component: ComponentType) => {
  const wrappedWithAnalyticsTabsLayout = () => (
    <>
      <AnalyticsTabs />
      <Component />
    </>
  );

  wrappedWithAnalyticsTabsLayout.displayName = "wrappedWithAnalyticsTabsLayout";

  return wrappedWithAnalyticsTabsLayout;
};

export default withAnalyticsTabsLayout;
