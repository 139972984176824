import { FC, RefObject, useImperativeHandle } from "react";
import { FormikProps } from "formik";
import { useTheme } from "@mui/styles";
import { Alert, Box, FormControl, Grid, MenuItem, Select, TextareaAutosize, Typography } from "@mui/material";

import { useIntl } from "react-intl";

import useStyles from "../styles";

import Icon from "../../../../../../utils/icon";
import AnimatedLoader from "../../../../../AnimatedLoader";

import { Holiday, TykchaseCampaignTypes } from "../../../../../../generated/types";
import { StepsFormikValues } from "../../../../../../types/campaigns";

type Props = {
  holidays?: Holiday[];
  isLoadingHolidaysData?: boolean;
  campaignType?: string;
  informationRef: RefObject<FormikProps<StepsFormikValues>>;
  formik: FormikProps<StepsFormikValues>;
};

const InformationStep: FC<Props> = ({
  holidays,
  isLoadingHolidaysData,
  campaignType,
  informationRef,
  formik,
}: Props) => {
  const theme = useTheme();
  const { wrap, fieldWrapper } = useStyles();
  const { formatMessage } = useIntl();
  const isHolidaysCampaign = campaignType === TykchaseCampaignTypes.Holidays;
  const isHolidaysEmpty = isHolidaysCampaign && holidays?.length === 0;

  useImperativeHandle(informationRef, () => ({
    ...formik,
  }));

  const customCoverHandler = (e) => {
    const reader = new FileReader();
    reader.onloadend = function onloadend() {
      formik.setFieldValue("customCoverBase64", reader.result);
    };
    reader.readAsDataURL(e.target.files[0]);
    formik.handleChange(e);
  };

  return (
    <Grid className={wrap}>
      <form>
        <Grid container flexDirection={{ xs: "column", md: "row" }} columnSpacing={3} className="generalInformation">
          <Grid item md={6}>
            {isHolidaysCampaign && (
              <Grid mb="20px" width="100%" className={fieldWrapper}>
                <Grid
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="flex-end"
                  mb={!formik.errors.holidayId ? "64px" : "0px"}
                >
                  <Typography
                    mb={!formik.errors.holidayId ? "-66px" : "-2px"}
                    fontSize="0.75rem"
                    color={theme.palette.label?.grey}
                  >
                    {formatMessage({ id: "modals.information_step.holiday" })}
                  </Typography>

                  {!isHolidaysEmpty && formik.touched.holidayId && formik.errors.holidayId && (
                    <Grid className={!formik.errors.holidayId ? "hiddenMessage" : ""} mb="16px">
                      <Alert severity="error">{formik.errors.holidayId}</Alert>
                    </Grid>
                  )}
                </Grid>

                <FormControl style={{ width: "100%" }}>
                  <Select
                    displayEmpty
                    style={{ width: "100%" }}
                    onChange={formik.handleChange}
                    value={formik.values.holidayId}
                    className="selectedHoliday"
                    name="holidayId"
                  >
                    <MenuItem disabled value="">
                      <em> {formatMessage({ id: "modals.information_step.select_your_holiday" })}</em>
                    </MenuItem>

                    {holidays &&
                      holidays.map((holiday: Holiday) => (
                        <MenuItem key={holiday.id} value={holiday.id}>
                          {holiday.title}
                        </MenuItem>
                      ))}
                  </Select>
                  {isHolidaysEmpty && !isLoadingHolidaysData && (
                    <Alert severity="error">
                      {formatMessage({ id: "modals.information_step.doesnt_have_any_holidays" })}
                    </Alert>
                  )}
                </FormControl>
              </Grid>
            )}

            {!isHolidaysEmpty && (
              <Grid
                mt={campaignType !== TykchaseCampaignTypes.Holidays ? "50px" : "0px"}
                mb={{ md: "15px" }}
                className={fieldWrapper}
              >
                <Typography mb="5px" fontSize="0.75rem" color={theme.palette.label?.grey}>
                  {formatMessage({ id: "modals.information_step.title" })}
                </Typography>

                <TextareaAutosize
                  style={{ width: "100%" }}
                  minRows={5}
                  value={formik.values.title}
                  placeholder={formatMessage({ id: "modals.information_step.write_your_campaign_title" })}
                  onChange={formik.handleChange}
                  name="title"
                />

                {formik.touched.title && formik.errors.title && (
                  <Box className="errorWrapper right">
                    <Alert severity="error">{formik.errors.title}</Alert>
                  </Box>
                )}
              </Grid>
            )}

            {!isHolidaysEmpty && (
              <Grid className={fieldWrapper}>
                <Typography mb="5px" fontSize="0.75rem" color={theme.palette.label?.grey}>
                  {formatMessage({ id: "modals.information_step.description" })}
                </Typography>

                <TextareaAutosize
                  style={{ width: "100%" }}
                  minRows={5}
                  value={formik.values.description}
                  placeholder={formatMessage({ id: "modals.information_step.write_your_campaign_title_big" })}
                  onChange={formik.handleChange}
                  name="description"
                />

                {formik.touched.description && formik.errors.description && (
                  <Box className="errorWrapper right">
                    <Alert severity="error">{formik.errors.description}</Alert>
                  </Box>
                )}
              </Grid>
            )}
          </Grid>

          {!isHolidaysEmpty && (
            <Grid item xs={12} md={6} className={fieldWrapper}>
              <Grid
                height="50px"
                container
                justifyContent={{ xs: "center", md: "start" }}
                className={!formik.errors.startDate && !formik.errors.endDate ? "hiddenMessage" : ""}
              >
                {((formik.touched.startDate && formik.errors.startDate) ||
                  (formik.touched.endDate && formik.errors.endDate)) && (
                  <Grid item maxWidth="270px">
                    <Alert severity="error">{formik.errors.startDate || formik.errors.endDate}</Alert>
                  </Grid>
                )}
              </Grid>
              <Grid
                container
                mb="15px"
                width="100%"
                justifyContent={{ xs: "center", md: "start" }}
                className={fieldWrapper}
              >
                <Grid item>
                  <Typography mb="5px" fontSize="0.75rem" color={theme.palette.label?.grey}>
                    {formatMessage({ id: "modals.information_step.start" })}
                  </Typography>

                  <input value={formik.values.startDate} type="date" onChange={formik.handleChange} name="startDate" />
                </Grid>

                <Grid item ml={{ xs: "10px" }}>
                  <Typography mb="5px" fontSize="0.75rem" color={theme.palette.label?.grey}>
                    {formatMessage({ id: "modals.information_step.end" })}
                  </Typography>

                  <input value={formik.values.endDate} type="date" onChange={formik.handleChange} name="endDate" />
                </Grid>
              </Grid>

              <Grid container justifyContent={{ xs: "center", md: "start" }}>
                <Typography mb="4px" fontSize="0.75rem" color={theme.palette.label?.grey}>
                  {formatMessage({ id: "modals.information_step.select_a_cover" })}
                </Typography>
              </Grid>

              <Grid
                container
                flexWrap="nowrap"
                mb="20px"
                justifyContent={{ xs: "center", md: "start" }}
                className={fieldWrapper}
              >
                <label className="labelCoverTemplate" htmlFor="inputCoverTemplate">
                  <Grid>
                    <Icon.MetroFilePicture />
                  </Grid>

                  <Typography fontSize="0.6rem" color={theme.palette.purple}>
                    {formatMessage({ id: "modals.information_step.cover_template" })}
                  </Typography>

                  <input id="inputCoverTemplate" type="file" />
                </label>

                <label className="labelCoverTemplate" htmlFor="inputCoverTemplate">
                  <Grid>
                    <Icon.MetroFilePicture />
                  </Grid>

                  <Typography fontSize="0.6rem" color={theme.palette.purple}>
                    {formatMessage({ id: "modals.information_step.cover_template" })}
                  </Typography>

                  <input id="inputCoverTemplate" type="file" />
                </label>

                <label className="labelCoverTemplate" htmlFor="inputCoverTemplate">
                  <Grid>
                    <Icon.MetroFilePicture />
                  </Grid>

                  <Typography fontSize="0.6rem" color={theme.palette.purple}>
                    {formatMessage({ id: "modals.information_step.cover_template" })}
                  </Typography>

                  <input id="inputCoverTemplate" type="file" />
                </label>

                {formik.touched.customCoverBase64 && formik.errors.customCoverBase64 && (
                  <Grid width="90px">
                    <Alert
                      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
                      style={{ height: "110px", padding: "3px" }}
                      severity="error"
                    >
                      {formik.errors.customCoverBase64}
                    </Alert>
                  </Grid>
                )}
              </Grid>

              <Grid container justifyContent={{ xs: "center", md: "end" }} maxWidth={{ xs: "100%", md: "250px" }}>
                <label htmlFor="customFileInputId">
                  <Typography className="details" fontSize="0.625rem">
                    {formatMessage({ id: "modals.information_step.upload_custom" })}
                  </Typography>

                  <input
                    id="customFileInputId"
                    type="file"
                    className="customFileInput"
                    accept="image/*"
                    onChange={customCoverHandler}
                    name="customCoverBase64"
                  />
                </label>
              </Grid>
            </Grid>
          )}
        </Grid>
        {isLoadingHolidaysData && <AnimatedLoader width={80} height={80} />}
      </form>
    </Grid>
  );
};

export default InformationStep;
