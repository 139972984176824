import { FC } from "react";
import { Box, ButtonBase, Grid } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import manWalking from "../../assets/images/manWalking.png";
import FallBackPages from "../FallBackPages";
import MarketingTable from "./MarketingTable";
import { useFeatureFlagValue } from "../../hooks";
import theme from "../../theme";
import { buttonStyle, useStyles } from "./styles";
import { FeatureFlagEnum } from "../../types/contexts";

const Marketing: FC = () => {
  const { createNewPromotionButton, contentWrapper } = useStyles();
  const location = useLocation();
  const history = useHistory();
  const isMarketingV2Enabled = useFeatureFlagValue(FeatureFlagEnum.MARKETING_V2_ENABLED);
  const { formatMessage } = useIntl();
  const handleButtonClick = () => {
    history.push({
      pathname: `${location.pathname}/Promotions`,
    });
  };

  return (
    <Grid marginTop="0">
      {isMarketingV2Enabled ? (
        <>
          <ButtonBase className={createNewPromotionButton} sx={{ ...buttonStyle(theme) }} onClick={handleButtonClick}>
            {formatMessage({ id: "marketing.create_new_promotion" })}
          </ButtonBase>

          <Box className={contentWrapper}>
            <MarketingTable />
          </Box>
        </>
      ) : (
        <FallBackPages
          image={manWalking}
          title={formatMessage({ id: "marketing.not_available" })}
          description={<span>{formatMessage({ id: "not_available.description" })}</span>}
        />
      )}
    </Grid>
  );
};

export default Marketing;
