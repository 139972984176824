import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Typography, CircularProgress } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import { useLazyQuery } from "@apollo/client";
import { useAccount, useUser, useUnifiedLogin } from "@stagewood/unified-login-library";
import { FormattedMessage } from "react-intl";
import { useStyles } from "./styles";
import { ReactComponent as StoreIcon } from "../../assets/icons/enrollIcons/addStoreIcon.svg";
import { ReactComponent as BusinessIcon } from "../../assets/icons/enrollIcons/business.svg";
import { ReactComponent as Logo } from "../../assets/icons/menuIcons/logoTykchase.svg";
import defaultProfilePlaceholder from "../../assets/icons/business/default-profile-placeholder.svg";
import Header from "../Layout/HeaderAlternative";
import { getBusinessProfileInfoRequest } from "../../graphql/GetBusinessProfileInfo.query";

const ChooseBusiness = () => {
  const { parent, business, noBusinessProfileFound, createProfileButton, continueWithSelectedProfile } = useStyles();
  const user = useUser();
  const account = useAccount();
  const { switchUser } = useUnifiedLogin();
  const history = useHistory();
  const [isBusy, setBusy] = useState<boolean>(false);
  const [businessProfiles, setBusinessProfiles] = useState<any[]>([]);
  const [isBusinessProfileFound, setIsBusinessProfileFound] = useState(false);
  const businessProfileMembershipInfoRef = useRef<any>(null);
  const [selectedProfile, setSelectedProfile] = useState(undefined);

  const [getBusinessInfo, { loading: isGetBusinessProfileQueryLoading }] = useLazyQuery(getBusinessProfileInfoRequest, {
    returnPartialData: true,
    onCompleted: (data) => {
      businessProfileMembershipInfoRef.current = data;
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (account && account?.users?.length > 0) {
      const businesses = account?.users?.filter((userProfile) => {
        return userProfile.userType === "Business";
      });
      if (businesses.length > 0) {
        setBusinessProfiles(businesses);
        setIsBusinessProfileFound(true);
      } else {
        setIsBusinessProfileFound(false);
      }
    }
  }, [account]);

  function toCreateProfile() {
    history.push("/how-to-create-business-profile");
  }

  const onProfileSelect = (businessParam) => {
    setSelectedProfile(businessParam.id);
  };

  useEffect(() => {
    if (!selectedProfile) {
      return;
    }
    setBusy(true);
    getBusinessInfo({
      variables: {
        userId: selectedProfile,
      },
    })
      .then(() => {})
      .catch(() => {})
      .finally(() => setBusy(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProfile]);

  function onContinueWithSelectedProfile() {
    const businessProfileMembershipInfo = businessProfileMembershipInfoRef.current;
    if (!businessProfileMembershipInfo?.businessProfileInfo || !selectedProfile || isBusy) return;

    if (businessProfileMembershipInfo.businessProfileInfo.status === "SUCCESS") {
      const businessProfileInfo = businessProfileMembershipInfo.businessProfileInfo?.payload;
      if (businessProfileInfo?.membership) {
        switchUser(businessProfileInfo.id).then(() => {
          setBusy(false);
          history.push("/Dashboard");
        });
        return;
      }
    }
    switchUser(selectedProfile).then(() => history.push("/Memberships"));
  }

  return (
    <Grid container direction="column" alignItems="center" justifyContent="flex-start" className={parent} pt="4rem">
      <Grid>
        <Header />
      </Grid>

      <Grid item display="flex" direction="column" alignItems="center" style={{ textAlign: "center" }} spacing={2}>
        <Grid style={{ marginTop: "40px" }}>
          <Logo width={300} />
        </Grid>

        <Grid item style={{ margin: "2rem 0" }}>
          <Typography style={{ fontWeight: 600 }}>{`Hi ${user?.firstName || ""} ${user?.lastName || ""}!`}</Typography>
          <Typography style={{ fontWeight: 500 }}>
            <FormattedMessage id="enroll.welcome" />
          </Typography>
          {isBusinessProfileFound && (
            <Typography variant="body2" mt={{ sm: 1.5 }}>
              <FormattedMessage id="enroll.complete_the_process" />
            </Typography>
          )}
        </Grid>
        {isBusinessProfileFound && (
          <Typography
            style={{
              color: "#4262FF",
              fontWeight: 500,
              margin: ".5rem 0 2rem",
              fontSize: "1.2rem",
            }}
          >
            <FormattedMessage id="enroll.select_profile" />
          </Typography>
        )}

        <div style={{ position: "relative" }}>
          {isBusy && (
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(255, 255, 255, 0.2)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          )}

          <Grid container item justifyContent="space-around">
            {isBusinessProfileFound ? (
              businessProfiles.map((businessEl) => {
                return (
                  <Grid
                    onClick={() => {
                      onProfileSelect(businessEl);
                    }}
                    key={businessEl.id}
                    item
                    className={`${business} ${businessEl.id === selectedProfile ? "selected" : "unselected"} ${
                      isBusy ? "disabled" : null
                    }`}
                  >
                    <div className="imageWrapper">
                      <img src={businessEl.profilePicture || defaultProfilePlaceholder} alt={businessEl.displayName} />
                    </div>
                    <div className="textWrapper">
                      <BusinessIcon width={24} height={24} style={{ marginRight: 8 }} />
                      {businessEl.displayName}
                    </div>
                  </Grid>
                );
              })
            ) : (
              <Grid
                className={noBusinessProfileFound}
                container
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <SentimentDissatisfiedIcon fontSize="inherit" />
                <Typography textAlign="left">
                  <FormattedMessage id="enroll.we_couldnt_find" />
                  <Typography fontWeight="600">
                    <FormattedMessage id="enroll.any_profile" />
                  </Typography>
                  <br />
                  <FormattedMessage id="enroll.associated_with_your_account" />
                </Typography>
              </Grid>
            )}
          </Grid>
        </div>

        <Grid
          style={{
            marginTop: "4rem",
          }}
        >
          {/* eslint-disable-next-line no-nested-ternary */}
          {!selectedProfile ? (
            <button type="button" className={createProfileButton} onClick={toCreateProfile}>
              <StoreIcon />
              <FormattedMessage id="enroll.create_profile" />
            </button>
          ) : isGetBusinessProfileQueryLoading ? (
            <LoadingButton className={continueWithSelectedProfile} size="large" loading variant="contained">
              ...
            </LoadingButton>
          ) : (
            <button
              type="button"
              className={continueWithSelectedProfile}
              onClick={onContinueWithSelectedProfile}
              disabled={isBusy}
            >
              <FormattedMessage id="enroll.continue" />
            </button>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ChooseBusiness;
