import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Box, Typography, Button, Stack, TextField, FormLabel } from "@mui/material";
import { useIntl } from "react-intl";
import theme from "../../../../theme";
import { useStyles } from "./styles";
import FormHelperTextBox from "../../FormHelperTextBox";
import Triggers from "../Triggers";
import AnimatedLoader from "../../../AnimatedLoader";
import { QrType, CategoryName, ITtykchaseCampaign, ITab } from "../../types";
import { useCategory } from "./hooks";

interface IQrForm {
  onSubmit: (val: boolean) => void;
  isDirty: boolean;
  isLoading: boolean;
  name: string;
  setName: (val: string) => void;
  trigger: ITtykchaseCampaign | null;
  setTrigger: (val: ITtykchaseCampaign) => void;
  setQrType: (val: QrType) => void;
}

const QrForm = ({ name, setName, trigger, setTrigger, setQrType, onSubmit, isDirty, isLoading }: IQrForm) => {
  const { qrForm } = useStyles();
  const { formatMessage } = useIntl();

  const history = useHistory();

  const [category, setCategory] = useCategory();

  useEffect(() => {
    if (!category) return;
    setQrType(category.type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  const handleNameChange = (e) => setName(e.target.value);

  const handleCancel = () => history.goBack();

  const handleSubmit = (e) => {
    e.preventDefault();
    const experienceIsSelected = Boolean(category?.triggers.find((elem) => elem.id === trigger?.id));
    onSubmit(Boolean(experienceIsSelected && name));
  };

  const tabs: ITab[] = Object.keys(CategoryName).map((key) => ({
    title: CategoryName[key],
    type: QrType[key.toUpperCase()],
  }));

  return (
    <form onSubmit={handleSubmit} className={qrForm}>
      <FormLabel
        sx={{
          display: "block",
          maxWidth: "310px",
          marginBottom: theme.spacing(6),
        }}
      >
        <Typography component="span" fontSize="0.875rem" mb="6px">
          {formatMessage({ id: "technology.qr_codes.qr_code_name" })}
        </Typography>
        <TextField
          placeholder={formatMessage({ id: "technology.qr_codes.name_here" })}
          value={name}
          onChange={handleNameChange}
          sx={{
            width: "100%",
          }}
          InputProps={{
            classes: {
              input: "input",
            },
          }}
        />

        <FormHelperTextBox
          isError={isDirty && !name}
          errorText={formatMessage({ id: "technology.qr_codes.please_prove_a_name" })}
        />
      </FormLabel>
      {!category ? (
        <AnimatedLoader width={80} height={80} />
      ) : (
        <Box width="100%" maxWidth="851px">
          <Box mb={3}>
            <Typography component="span" color={theme.palette.technology.grey3}>
              {formatMessage({ id: "technology.qr_codes.select_a_trigger" })}
            </Typography>
          </Box>
          <Box mb="92px">
            <Triggers
              tabs={tabs}
              category={category}
              onCategoryChange={setCategory}
              trigger={trigger}
              onTriggerChange={setTrigger}
              formIsDirty={isDirty}
            />
          </Box>
          <Stack direction="row" justifyContent="end" spacing={1}>
            <Button onClick={handleCancel} variant="outlined" className="cancle-btn btn">
              {formatMessage({ id: "btn_text_cancel" })}
            </Button>
            <Button type="submit" variant="contained" disabled={isLoading} className="next-btn btn">
              <Typography component="span"> {formatMessage({ id: "btn_text_next" })}</Typography>
            </Button>
          </Stack>
        </Box>
      )}
    </form>
  );
};

export default QrForm;
