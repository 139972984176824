import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { useUpdatePaymentDetailsLink } from "./useUpdatePaymentDetailsLink.hook";
import { useRefetchBusinessProfile } from "./useRefetchBusinessProfile.hook";
import { formatDate } from "../../../../utils/formatDate";

export interface TUnableToProcessPaymentNotificationProps {
  userId: string;
  dueDate: string;
}

export const UnableToProcessPaymentNotification: FC<TUnableToProcessPaymentNotificationProps> = ({
  userId,
  dueDate,
}) => {
  const updatePaymentDetailsPath = useUpdatePaymentDetailsLink();

  useRefetchBusinessProfile(userId);

  return (
    <FormattedMessage
      id="tykchase_membership_notifications.unable_to_process_payment.notification"
      values={{
        updatePaymentDetailsLink: (
          <Link to={updatePaymentDetailsPath} data-testid="update-payment-details-link">
            <FormattedMessage id="tykchase_membership_notifications.unable_to_process_payment.link_text" />
          </Link>
        ),
        dueDate: <strong data-testid="due-date">{formatDate(dueDate)}</strong>,
      }}
    />
  );
};
