import { defineMessages, IntlShape } from "react-intl";
import { HashRoutes } from "../constants";

enum YourRewardsTabsIds {
  RULES = "nav-tab-rules",
  CAMPAIGNS = "nav-tab-campaigns",
  EXPERIENCES = "nav-tab-experiences",
  JACKPOT = "nav-tab-jackpot",
  PROMOTERS = "nav-tab-promoters",
}

interface IYourRewardsItem {
  label: string;
  renderScene: () => JSX.Element;
  hash: HashRoutes;
  id: YourRewardsTabsIds;
}

const messages = defineMessages({
  rewardRulesTab: {
    id: "your_rewards_reward_rules_tab",
    defaultMessage: "Reward Rules",
  },
  campaignsTab: {
    id: "your_rewards_campaigns_tab",
    defaultMessage: "Campaigns",
  },
  experiencesTab: {
    id: "your_rewards_experiences_tab",
    defaultMessage: "Experiences",
  },
  jackpotTab: {
    id: "your_rewards_jackpot_tab",
    defaultMessage: "Jackpot",
  },
  promotersTab: {
    id: "your_rewards_promoters_tab",
    defaultMessage: "Promoters",
  },
  rewardDistribution: {
    id: "reward_rules_reward_distribution",
    defaultMessage: "Reward Distribution",
  },
});

const YourRewardsItems = (intl: IntlShape) => {
  return {
    REWARD_RULES: intl.formatMessage(messages.rewardRulesTab),
    CAMPAIGNS: intl.formatMessage(messages.campaignsTab),
    EXPERIENCES: intl.formatMessage(messages.experiencesTab),
    JACKPOT: intl.formatMessage(messages.jackpotTab),
    PROMOTERS: intl.formatMessage(messages.promotersTab),
    REWARD_DISTRIBUTION: intl.formatMessage(messages.rewardDistribution),
  };
};

export { YourRewardsItems, YourRewardsTabsIds };
export type { IYourRewardsItem };
