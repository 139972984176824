export enum Routes {
  ANALYTICS = "/Analytics",
  DASHBOARD = "/Dashboard",
  MEMBERSHIPS = "/Memberships",
  YOUR_REWARDS = "/Your_Rewards",
  MARKETING = "/Marketing",
  TECHNOLOGY = "/Technology",
  BILLING = "/billing",
  DEMOGRAPHICS = "/Demographics",
  PSYCHOGRAPHICS = "/Psychographics",
  ENROLL = "/enroll",
  CHOOSE_BUSINESS = "/choose-business",
  HOW_TO_CREATE_BUSINESS_PROFILE = "/how-to-create-business-profile",
  COOKIES = "/cookies",
  TERMS_OF_SERVICE = "/terms-of-service",
  LOCATIONS = "/locations",
  PAYMENTS = "/payments",
}

export enum HashRoutes {
  RULES = "rules",
  CAMPAIGNS = "campaigns",
  EXPERIENCES = "experiences",
  JACKPOT = "jackpot",
  PROMOTERS = "promoters",
}
