import { FC } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import receiptIcon from "../../../../../assets/icons/campaign/receipt-icon.svg";
import revenueIcon from "../../../../../assets/icons/campaign/revenue-icon.svg";
import moneyBillIcon from "../../../../../assets/icons/campaign/money-bill-alt.svg";
import theme from "../../../../../theme";
import useStyles from "../styles";

const ModalFooter: FC = () => {
  const { modalFooter } = useStyles();

  return (
    <Box className={modalFooter}>
      <Grid container spacing={2}>
        <Grid item sm={4} className="gridItem">
          <Box className="gridItemImageWrap">
            <img src={receiptIcon} alt="" />
          </Box>

          <Box display="flex" flexDirection="column">
            <Typography
              variant="h3"
              color={theme.palette.primary.dark}
              fontFamily={theme.typography.fontFamily}
              fontSize="1.125rem"
              fontWeight="bold"
            >
              150
            </Typography>

            <Typography color="#797882" fontFamily={theme.typography.fontFamily} fontSize="0.75rem">
              <FormattedMessage id="modals.total_scanned_receipts" />
            </Typography>
          </Box>
        </Grid>

        <Grid item sm={4} className="gridItem">
          <Box className="gridItemImageWrap">
            <img src={revenueIcon} alt="" />
          </Box>

          <Box display="flex" flexDirection="column">
            <Typography
              variant="h3"
              color={theme.palette.primary.dark}
              fontFamily={theme.typography.fontFamily}
              fontSize="1.125rem"
              fontWeight="bold"
            >
              ${"2000".toLocaleString()}
            </Typography>

            <Typography color="#797882" fontFamily={theme.typography.fontFamily} fontSize="0.75rem">
              <FormattedMessage id="modals.revenue" />
            </Typography>
          </Box>
        </Grid>

        <Grid item sm={4} className="gridItem">
          <Box className="gridItemImageWrap">
            <img src={moneyBillIcon} alt="" />
          </Box>

          <Box display="flex" flexDirection="column">
            <Typography
              variant="h3"
              color={theme.palette.primary.dark}
              fontFamily={theme.typography.fontFamily}
              fontSize="1.125rem"
              fontWeight="bold"
            >
              ${"60".toLocaleString()}
            </Typography>

            <Typography color="#797882" fontFamily={theme.typography.fontFamily} fontSize="0.75rem">
              <FormattedMessage id="modals.invested" />
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ModalFooter;
