export type Message = {
  id: string;
  senderId: string;
  text: string;
  date: string;
};

export type Winner = {
  id: string;
  name: string;
  date: string;
  amount: {
    green: string;
    yellow: string;
  };
  attemps: number;
  messages?: Message[];
};

export enum Views {
  CHAT = 'chat',
  DETAILS = 'details',
  MESSAGES = 'messages',
}
