import { FC } from "react";
import { Link } from "react-router-dom";
import { Grid, ListItem, ListItemIcon, ListItemText, Tooltip } from "@mui/material";

import useStyles from "./styles";

import { FeatureTooltip, featureTooltipStyleProps } from "../../Tooltips";
import ComingSoonLabel from "../../Utils/ComingSoonLabel";

import { IMenuItem } from "../../../types/menu";
import { Routes } from "../../../constants";
import { TooltipType } from "../../../types/tooltips";

interface ItemNavigationBarProps {
  navigationItem: IMenuItem;
}

const ItemNavigationBar: FC<ItemNavigationBarProps> = ({ navigationItem }) => {
  const { listItem, text, button } = useStyles();

  const disabledListItems = (item: IMenuItem) => {
    return item.route === Routes.ANALYTICS || item.route === Routes.MARKETING || item.route === Routes.TECHNOLOGY;
  };

  return (
    <Tooltip
      title={<FeatureTooltip type={TooltipType.COMING_SOON} />}
      placement="right"
      arrow
      disableHoverListener={!disabledListItems(navigationItem)}
      componentsProps={featureTooltipStyleProps}
    >
      <span>
        <ListItem
          button
          className={button}
          component={Link}
          to={navigationItem.route}
          key={navigationItem.text}
          disabled={disabledListItems(navigationItem)}
        >
          <Grid className={listItem}>
            <ListItemIcon>{navigationItem.icon}</ListItemIcon>
            <ListItemText primary={navigationItem.text} className={text} />
          </Grid>
          {disabledListItems(navigationItem) && <ComingSoonLabel />}
        </ListItem>
      </span>
    </Tooltip>
  );
};

export default ItemNavigationBar;
