import { FormattedMessage } from 'react-intl';
import useStyles from './styles';

const Footer = () => {
  const { footerWrap } = useStyles();

  return (
    <div className={footerWrap}>
      <div className="row">
        <div className="decor" />
        <span>
          <FormattedMessage id="modals.reward_distribution.maximum_for_one_experience" />
        </span>
        <FormattedMessage id="modals.reward_distribution.more_than" />
      </div>
      <div className="row">
        <div className="decor" />
        <span>
          <FormattedMessage id="modals.reward_distribution.maximum_lonestars_that_can_be_given" />
        </span>
        <FormattedMessage id="modals.reward_distribution.procent_of_lonestars" />
      </div>
      <div className="row">
        <div className="decor" />
        <span>
          <FormattedMessage id="modals.reward_distribution.centors_cost" />
        </span>
        $0.01
      </div>
      <div className="row">
        <div className="decor" />
        <span>
          <FormattedMessage id="modals.reward_distribution.loneStars_cost" />
        </span>
        <FormattedMessage id="modals.reward_distribution.loneStars_cost" />
      </div>
    </div>
  );
};

export default Footer;
