import { FC } from "react";
import { Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import useStyles from "./styles";
import { Message, Views, Winner } from "../types";

interface ChatsTableProps {
  winners: Winner[];
  onChangeView: (winner, view) => void;
}

const ChatsTable: FC<ChatsTableProps> = ({ winners, onChangeView }) => {
  const { header, tableContainer } = useStyles();

  const getChats = (): { id: string; name: string; message: Message }[] =>
    winners.reduce((acc: { id: string; name: string; message: Message }[], currentWinner) => {
      const lastMessage = currentWinner.messages && currentWinner.messages[currentWinner.messages.length - 1];
      if (lastMessage) {
        acc.push({
          id: currentWinner.id,
          name: currentWinner.name,
          message: lastMessage,
        });
      }
      return acc;
    }, []);

  return (
    <>
      <h1 className={header}>Messages</h1>

      <TableContainer className={tableContainer}>
        <Table>
          <TableBody>
            {getChats().length ? (
              getChats().map((chat) => (
                <TableRow
                  key={chat.id}
                  onClick={() =>
                    onChangeView(
                      winners.find((winner) => chat.id === winner.id),
                      Views.CHAT,
                    )
                  }
                >
                  <TableCell>
                    <div className="avatar-image" />
                  </TableCell>

                  <TableCell>
                    <div className="chat-content">
                      <h2>{chat.name}</h2>
                      <p>{chat.message.text}</p>
                    </div>
                  </TableCell>

                  <TableCell align="right" className="date-column">
                    {chat.message.date}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <p className="empty-message">No messages yet</p>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ChatsTable;
