import { FC, StrictMode } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { ThemeProvider } from "@mui/styles";
import { Elements } from "@stripe/react-stripe-js";
import { ApolloProvider } from "@apollo/client";
import { UnifiedLoginProvider } from "@stagewood/unified-login-library";
import { SnackbarProvider } from "notistack";
import { useApolloClient } from "./apollo";
import App from "./components/App/App";
import reportWebVitals from "./reportWebVitals";
import theme from "./theme";
import stripe from "./constants/stripe";
import { FeatureFlagsProvider, LanguageProvider } from "./contexts";
import { reportAppVersion } from "./reportAppVersion";
import { MAX_SNACK } from "./constants";

const unifiedLoginConfig = {
  baseUrl: process.env.REACT_APP_UNIFIED_LOGIN_API_URL,
  loginRoute: process.env.REACT_APP_UNIFIED_LOGIN_BASE_URL,
};

const ApolloClientProvider: FC = ({ children }) => {
  const client = useApolloClient();

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

ReactDOM.render(
  <StrictMode>
    <UnifiedLoginProvider {...unifiedLoginConfig}>
      <LanguageProvider>
        <ThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={MAX_SNACK}>
            <ApolloClientProvider>
              <Elements stripe={stripe}>
                <FeatureFlagsProvider>
                  <App />
                </FeatureFlagsProvider>
              </Elements>
            </ApolloClientProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </LanguageProvider>
    </UnifiedLoginProvider>
  </StrictMode>,
  document.getElementById("root"),
);

reportWebVitals();
reportAppVersion();
