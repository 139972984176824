import { makeStyles } from "@mui/styles";

import { MembershipAvatarProps } from "./types";
import { Memberships } from "../../../types/membershipTypes";

const avatarStyles = (props: MembershipAvatarProps) => ({
  avatarStyle: {
    backgroundColor: () => {
      switch (props.membership) {
        case Memberships.Business:
          return "#1B959A";
        default:
          return "#1B959A";
      }
    },
    color: () => {
      switch (props.membership) {
        case Memberships.Business:
          return "#ffffff";
        default:
          return "#ffffff";
      }
    },
    height: () => {
      return props.avatarWidthAndHeight;
    },
    width: () => {
      return props.avatarWidthAndHeight;
    },
    zIndex: 1,
    marginRight: "0.5rem",
  },
});

const useStyles = makeStyles((props: MembershipAvatarProps) => {
  return { ...avatarStyles(props) };
});

export { avatarStyles, useStyles };
