import { useIntl } from "react-intl";
import { Typography, Grid, AppBar, Toolbar, Box, IconButton } from "@mui/material";
import { useUnifiedLogin, WaffleButton } from "@stagewood/unified-login-library";
import { useLocation, useHistory } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ChangeHeader } from "../../../utils/menuitems";
import MembershipAvatar from "../../Utils/Avatar/MembershipAvatar";
import { HeaderEnrollButton } from "../../Buttons";
import ProfileButton from "../../ProfileButton";
import { useStyles, headerStyle } from "./styles";
import { useBusinessProfile } from "../../../hooks/useBusinessProfile.hook";

const Header = (props) => {
  const { appBar, toolbarHeight, leftBox, rightBox, buttonBox, planLabel } = useStyles(props);
  const { isAuthenticated } = useUnifiedLogin();

  const location = useLocation();
  const history = useHistory();
  const intl = useIntl();
  const [businessProfile, , isSubscribed] = useBusinessProfile();
  const headerEnrollButtonText = isSubscribed ? "btn_upgrade_membership" : "btn_enroll_your_business";

  const toMembership = () => {
    history.push("/Memberships");
  };

  return (
    <AppBar position="fixed" sx={{ ...headerStyle.appBar }} className={appBar}>
      <Toolbar sx={{ ...headerStyle.toolbarHeight }} className={toolbarHeight}>
        <Box component="div" sx={{ ...headerStyle.leftBox }} className={leftBox}>
          <IconButton
            onClick={() => {
              history.goBack();
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          {ChangeHeader(location.pathname, intl)}
        </Box>

        <Box component="div" sx={{ ...headerStyle.rightBox }} className={rightBox}>
          <Grid item sx={{ ...headerStyle.buttonBox }} className={buttonBox}>
            {isSubscribed && businessProfile?.membership && (
              <Grid
                container
                alignItems="center"
                style={{
                  width: "max-content",
                }}
              >
                <MembershipAvatar
                  membership={businessProfile.membership.title ?? undefined}
                  avatarWidthAndHeight="34px"
                />

                <Typography sx={{ ...headerStyle.planLabel }} className={planLabel}>
                  {businessProfile.membership.title || ""}
                </Typography>
              </Grid>
            )}

            <HeaderEnrollButton
              buttonText={isSubscribed ? "btn_upgrade_membership" : "btn_enroll_your_business"}
              alternativeText="btn_text_enroll"
              btnOnClick={toMembership}
            />
          </Grid>

          <div>
            <WaffleButton />

            {isAuthenticated && <ProfileButton />}
          </div>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
