import { makeStyles } from "@mui/styles";

const buttonStyle = (theme) => ({
  margin: "20px 40px 40px",
  padding: "8px 30px",
  backgroundColor: theme.palette.marketing.blue,
  borderRadius: "20px",
  color: theme.palette.marketing.light,
  fontSize: "0.875rem",
  fontWeight: 600,
});

const useStyles = makeStyles((theme) => {
  return {
    createNewPromotionButton: { ...buttonStyle(theme) },

    contentWrapper: {
      margin: "0 40px",
      padding: "40px",
      background: theme.palette.white,

      [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
        margin: "0 20px",
        padding: "20px",
      },
    },
  };
});

export { buttonStyle, useStyles };
