import { RuleName } from "../../../../generated/types";
import { TooltipType } from "../../../../types/tooltips";

export const CHILD_RULE_TOOLTIP_TYPE = {
  [RuleName.FrequentCustomer]: TooltipType.FREQUENT_CUSTOMER_REWARDS,
};

export const DEACTIVATION_MODAL_TITLE_TYPE = {
  PARENT_RULE: "reward_rules_deactivation_modal_title_parent_rule" as const,
  DEFAULT: "reward_rules_deactivation_modal_title_default" as const,
};

export const DEACTIVATION_MODAL_MESSAGE_TYPE = {
  PARENT_RULE: "reward_rules_deactivation_modal_message_parent_rule" as const,
  DEFAULT: "reward_rules_deactivation_modal_message_default" as const,
};
