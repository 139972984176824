import { FC, useState } from "react";
import {
  Box,
  ButtonBase,
  FormControl,
  FormHelperText,
  Grid,
  InputBase,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { FormattedMessage, useIntl } from "react-intl";
import PromotionDetailsModal from "../PromotionDetailsModal";
import { DEFAULT_ROWS_PER_PAGE, ROWS_PER_PAGE_OPTIONS } from "../../../constants";
import { Pagination, selectDropdownStyles } from "../../shared/Pagination";
import { rows } from "../data";
import theme from "../../../theme";
import useStyles from "./styles";

const MarketingTable: FC = () => {
  const { tableContainer, searchContainer, filterContainer, paginationContainer } = useStyles();
  const { selectDropdown } = selectDropdownStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [searchValue, setSearchValue] = useState("");
  const [filterValue, setFilterValue] = useState("All");
  const [currentPromotion, setCurrentPromotion] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { formatMessage } = useIntl();
  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleFilterChange = (event) => {
    setFilterValue(event.target.value);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenModal = (event, currentRow) => {
    event.preventDefault();
    setCurrentPromotion(currentRow);
    setIsModalOpen(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <Grid container>
        <Grid item className={searchContainer} lg={8} xs={12} marginBottom="20px">
          <FormControl className="inputWrapper">
            <SearchIcon className="searchIcon" />

            <InputBase
              placeholder={formatMessage({ id: "marketing.marketing_table.find_a_suite" })}
              value={searchValue}
              onChange={handleSearchChange}
            />
          </FormControl>

          <ButtonBase className="searchButton">
            <FormattedMessage id="btn_text_next" />
          </ButtonBase>
        </Grid>

        <Grid item xs={12} className={filterContainer} marginBottom="20px">
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <FormHelperText>
              <FormattedMessage id="marketing.marketing_table.filter" />
            </FormHelperText>

            <Select value={filterValue} onChange={handleFilterChange} inputProps={{ "aria-label": "Without label" }}>
              <MenuItem value="All">
                <Typography
                  fontFamily={theme.typography.fontFamily}
                  fontSize="0.75rem"
                  color={theme.palette.primary.dark}
                >
                  <FormattedMessage id="marketing.marketing_table.all" />
                </Typography>
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <TableContainer className={tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left" width="88px">
                <FormattedMessage id="marketing.marketing_table.preview" />
              </TableCell>

              <TableCell align="left">
                <FormattedMessage id="marketing.marketing_table.promotion_name" />
              </TableCell>

              <TableCell align="left">
                <FormattedMessage id="marketing.marketing_table.start_end" />
              </TableCell>

              <TableCell align="right">
                <FormattedMessage id="marketing.marketing_table.spent_budget" />
              </TableCell>

              <TableCell align="right">
                <FormattedMessage id="marketing.marketing_table.ads" />
              </TableCell>

              <TableCell align="right">
                <FormattedMessage id="marketing.marketing_table.views" />
              </TableCell>

              <TableCell align="right">
                <FormattedMessage id="marketing.marketing_table.clicks" />
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id} onClick={(event) => handleOpenModal(event, row)}>
                <TableCell align="left" width="88px">
                  <Box className="promotionImageWrapper">
                    <img src={row?.promotionImage} alt="" />
                  </Box>
                </TableCell>

                <TableCell align="left">{row?.promotionName}</TableCell>

                <TableCell align="left">{`${row?.startDate} / ${row?.endDate}`}</TableCell>

                <TableCell align="right">{`$${row?.spent} / ${row?.budget}`}</TableCell>

                <TableCell align="right">{row?.ads}</TableCell>

                <TableCell align="right">{row?.views?.toLocaleString()}</TableCell>

                <TableCell align="right">{row?.clicks?.toLocaleString()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid className={paginationContainer}>
        <Table>
          <TableBody>
            <TableRow>
              <Pagination
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                count={rows.length}
                labelRowsPerPage={<FormattedMessage id="items_per_page" />}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                showFirstButton
                showLastButton
                SelectProps={{
                  MenuProps: { classes: { paper: selectDropdown } },
                }}
                sx={{ border: "none" }}
              />
            </TableRow>
          </TableBody>
        </Table>

        <Typography>
          <FormattedMessage id="btn_text_next" />
        </Typography>
      </Grid>

      <PromotionDetailsModal isOpen={isModalOpen} onClose={handleCloseModal} promotion={currentPromotion} />
    </>
  );
};

export default MarketingTable;
