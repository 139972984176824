import { FC } from "react";
import { Alert, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { FormattedMessage } from "react-intl";
import useStyles from "./styles";
import { PurchaseRewardRate } from "../../../../../generated/types";
import { PatternInput } from "../../../../shared/PatternInput";
import { TWO_DIGITS_AFTER_COMMA_DECIMAL_PATTERN } from "../../../regexp";
import { RewardRuleModalSubHeaderProps } from "../RewardRuleModalSubHeaders.interfaces";

export const EarmTyksForPurchasesSubHeader: FC<RewardRuleModalSubHeaderProps> = ({ formik }) => {
  const { wrap } = useStyles();

  const purchaseRewardRateFieldProps = formik.getFieldProps("purchaseRewardRate");
  const minPurchaseForRewardFieldProps = formik.getFieldProps("minPurchaseForReward");
  const minPurchaseForRewardError = formik.touched.minPurchaseForReward ? formik.errors.minPurchaseForReward : "";
  const { isSubmitting } = formik;

  return (
    <div className={wrap}>
      <div className="left">
        <p>
          <FormattedMessage id="modals.earn_tyks_header.title" />
        </p>
        <FormControl disabled={isSubmitting}>
          <InputLabel id="demo-simple-select-label">
            <FormattedMessage id="modals.earn_tyks_header.fixed_amount" />
          </InputLabel>
          <Select {...purchaseRewardRateFieldProps}>
            <MenuItem value={PurchaseRewardRate.Fixed}>
              <FormattedMessage id="modals.earn_tyks_header.fixed_amount" />
            </MenuItem>
            <MenuItem value={PurchaseRewardRate.ByPercentage}>
              <FormattedMessage id="modals.earn_tyks_header.purchase_percentage" />
            </MenuItem>
          </Select>
        </FormControl>
      </div>

      <div className="right">
        <p>
          <FormattedMessage id="modals.earn_tyks_header.minimum_purchase" />
        </p>
        <div className="purchaseInput">
          <PatternInput
            {...minPurchaseForRewardFieldProps}
            pattern={TWO_DIGITS_AFTER_COMMA_DECIMAL_PATTERN}
            className="input"
            placeholder="$0.00"
            disabled={isSubmitting}
          />
          <p>
            <FormattedMessage id="modals.earn_tyks_header.receipt" />
          </p>
        </div>
        {minPurchaseForRewardError && (
          <Alert severity="error" sx={{ position: "absolute", width: "100%" }}>
            {minPurchaseForRewardError}
          </Alert>
        )}
      </div>
    </div>
  );
};
