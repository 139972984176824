import { parse } from "qs";
import { useLocation, useRouteMatch } from "react-router-dom";
import { Checkout } from "../Checkout/Checkout";

import { useStyles } from "./styles";

interface CheckoutRouteParams {
  cartId: string;
}

export const CheckoutPage = () => {
  const { params } = useRouteMatch<CheckoutRouteParams>();
  const { search } = useLocation();
  const { container } = useStyles();
  const { successPageUrl } = parse(search, { ignoreQueryPrefix: true });

  return (
    <div className={container}>
      <Checkout cartId={params.cartId} successPageUrl={successPageUrl as string} />
    </div>
  );
};
