import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  paymentMethodSelect: {
    marginBottom: "42px",
    width: "350px",
  },
  cardForm: {
    containerType: "inline-size",
    flexWrap: "wrap",
    gap: "40px",
  },
  subForm: {
    minWidth: "350px",
  },
}));
