import { FC } from "react";
import {
  Button,
  Divider,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import SponsorIcon from "../../../../assets/icons/tykIcons/sponsor.svg";
import FanatyksIconSmall from "../../../../assets/icons/tykIcons/fanatyks-icon-small.svg";
import Modal from "../../../Modal";
import useStyles from "./styles";

const rows = [
  {
    id: 1,
    closeDate: "Oct 9th, 2021",
    goal: "5,000 LS",
    awardsAvailable: 1,
    availableAndDistributedLS: "100 / 200",
    followingEvent: "3,000",
    participatingBusiness: 20,
    sponsoredBy: "None",
  },
];

const experiencePerks = [
  {
    perk: "34",
    name: "Suite tickets",
  },
  {
    perk: "28",
    name: "tickets for sale",
  },
  {
    perk: "02",
    name: "tickets for staff",
  },
  {
    perk: "28",
    name: " tickets for celebrity",
  },
  {
    perk: "02",
    name: "tickets for sponsor",
  },
  {
    perk: "16",
    name: "Extra tickets $75.00 each",
  },
  {
    perk: "06",
    name: "Free Parking Passes",
  },
  {
    perk: "RD$15.00",
    name: "Parking pass unit price",
  },
  {
    perk: "*Included",
    name: "Food and Drink",
  },
  {
    perk: "*Included",
    name: "Private Bathroom",
  },
];

interface DetailsModalProps {
  isOpen: boolean;
  onClose: VoidFunction;
}

const DetailsModal: FC<DetailsModalProps> = ({ isOpen, onClose }) => {
  const { detailsContainer } = useStyles();

  return (
    <Modal onClose={onClose} isOpen={isOpen} isClosable={false}>
      <Grid container direction="column" className={detailsContainer}>
        <Grid className="modal-header">
          <div className="avatar-circle" />

          <Grid className="modal-header__title__container">
            <h1 className="modal-header__title">Marlins Vs. Pittsburg</h1>

            <Typography className="extra">LoanDepot Park, Miami</Typography>
          </Grid>
        </Grid>

        <Grid className="modal-body">
          <Grid>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      <FormattedMessage id="rewards.experiences.swepstake" />
                    </TableCell>

                    <TableCell align="center">
                      <FormattedMessage id="rewards.experiences.goal" />
                    </TableCell>

                    <TableCell align="center">
                      <FormattedMessage id="rewards.experiences.awards_available" />
                    </TableCell>

                    <TableCell align="center">
                      <FormattedMessage id="rewards.experiences.available_ls" />
                    </TableCell>

                    <TableCell align="center">
                      <FormattedMessage id="rewards.experiences.following_event" />
                    </TableCell>

                    <TableCell align="center">
                      <FormattedMessage id="rewards.experiences.participating_business" />
                    </TableCell>

                    <TableCell align="center">
                      <div className="sponsor-column-title">
                        <img src={FanatyksIconSmall} alt="fanatyks-icon-small" />
                        Sponsored by
                      </div>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell align="center">{row.closeDate}</TableCell>

                      <TableCell align="center">{row.goal}</TableCell>

                      <TableCell align="center">{row.awardsAvailable}</TableCell>

                      <TableCell align="center">{row.availableAndDistributedLS}</TableCell>

                      <TableCell align="center">{row.followingEvent}</TableCell>

                      <TableCell align="center">{row.participatingBusiness}</TableCell>

                      <TableCell align="center">{row.sponsoredBy}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid className="experience-perks-container">
            <Grid className="experience-perks-title__wrapper">
              <Typography className="experience-perks-title">Experience perks</Typography>

              <img src={SponsorIcon} alt="sponsor-icon" />
            </Grid>

            <Divider />

            <Grid className="perks-container-wrapper">
              <Grid item md={5} xs={6} className="perks-container">
                {experiencePerks.map((item) => (
                  <div className="perks-item" key={item.name}>
                    <Typography className="perks-item-value">
                      • (<strong>{item.perk}</strong>)
                    </Typography>

                    <Typography className="perks-item-name">{item.name}</Typography>
                  </div>
                ))}
              </Grid>

              <Grid item md={7} xs={6} className="statistics-container">
                <div className="statistics-item">
                  <div className="circle">
                    <Typography className="statistics-item-value">20 / 34</Typography>
                  </div>

                  <Typography className="statistics-item-title">Seats Available</Typography>
                </div>

                <div className="statistics-item">
                  <div className="circle">
                    <Typography className="statistics-item-value">$5.3K</Typography>
                  </div>

                  <Typography className="statistics-item-title">Total Income</Typography>
                </div>

                <div className="statistics-item">
                  <div className="circle">
                    <Typography className="statistics-item-value">$420</Typography>
                  </div>

                  <Typography className="statistics-item-title">Non Founding Price</Typography>
                </div>

                <div className="statistics-item">
                  <div className="circle">
                    <div className="logo-circle" />
                  </div>

                  <Typography className="statistics-item-title">Talent:</Typography>

                  <Typography className="statistics-item-subtitle"> Alex Rodriguez</Typography>
                </div>

                <div className="statistics-item">
                  <div className="circle disabled">
                    <div className="logo-circle" />
                  </div>

                  <Typography className="statistics-item-title">Sponsor:</Typography>

                  <Typography className="statistics-item-subtitle">None</Typography>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid className="modal-footer">
          <Button onClick={onClose}>
            <FormattedMessage id="btn_text_ok" />
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default DetailsModal;
