import { ComponentPropsWithRef } from "react";

export interface BillingProps extends ComponentPropsWithRef<any> {
  isSubscribed: boolean;
}

export enum BillingOrderEnum {
  ASC = "asc",
  DESC = "desc",
}

export enum BillingOrderByFieldEnum {
  CREATED_AT = "createdAt",
  IS_ENABLED = "isEnabled",
}
