import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      width: "110px",
      margin: "10px",
      boxShadow: "2px 2px 10px -1px rgba(0, 0, 0, 0.3) !important",

      "& .cardImage": {
        height: "75px",
      },

      "& .MuiCardContent-root": {
        padding: "6px",

        "& .cardDescription": {
          textAlign: "left",
          color: theme.palette.black,
          fontFamily: theme.typography.fontFamily,
          fontSize: "0.625rem",
          fontWeight: 600,
        },
      },

      "& .MuiCardActions-root": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "6px",

        "& .MuiTypography-root": {
          color: theme.palette.experiences.blue,
          fontFamily: theme.typography.fontFamily,
          fontSize: "0.625rem",
          textDecoration: "underline",
          cursor: "pointer",
        },

        "& .MuiSvgIcon-root": {
          width: "18px",
          height: "18px",
          fill: theme.palette.experiences.blue,
        },
      },
    },
  };
});

export default useStyles;
