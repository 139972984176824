import { useFormik } from "formik";
import * as yup from "yup";
import { AccountAddressFields } from "./types";

export const useAddressCreationForm = (data: AccountAddressFields, isBilling?: boolean) => {
  const formik = useFormik<AccountAddressFields>({
    initialValues: data,
    validationSchema: isBilling
      ? yup.object({
          addressStreet1: yup.string().required(),
          addressStreet2: yup.string().nullable(),
          addressCity: yup.string().required(),
          zipCode: yup.number().required(),
        })
      : yup.object({
          label: yup.string().required(),
          addressStreet1: yup.string().required(),
          addressStreet2: yup.string().nullable(),
          addressCity: yup.string().required(),
          zipCode: yup.number().required(),
          isDefault: yup.boolean(),
        }),
    onSubmit: () => {
      formik.validateForm();
    },
    validateOnMount: true,
    validateOnChange: true,
  });

  return [
    {
      values: formik.values,
      isValid: formik.isValid,
      addressCreationProps: {
        errors: formik.errors,
        touched: formik.touched,
        addressFieldsProps: {
          regionName: {
            setFieldValue: (value) => {
              formik.setFieldValue("regionName", value);
            },
          },
          shortCountryCode: {
            setFieldValue: (value) => {
              formik.setFieldValue("shortCountryCode", value);
            },
          },
          addressStreet1: {
            ...formik.getFieldProps("addressStreet1"),
            onChange: (e) => {
              formik.setFieldValue("addressStreet1", e.target.value).then(() => {
                formik.validateField("addressStreet1");
              });
              formik.setFieldTouched("addressStreet1");
            },
          },
          addressStreet2: { ...formik.getFieldProps("addressStreet2") },
          label: {
            ...formik.getFieldProps("label"),
            onChange: (e) => {
              formik.setFieldValue("label", e.target.value).then(() => {
                formik.validateField("label");
              });
              formik.setFieldTouched("label");
            },
          },
          zipCode: {
            ...formik.getFieldProps("zipCode"),
            onChange: (e) => {
              formik.setFieldValue("zipCode", e.target.value).then(() => {
                formik.validateField("zipCode");
              });
              formik.setFieldTouched("zipCode");
            },
          },
          isDefault: { ...formik.getFieldProps("isDefault") },
          addressCity: {
            value: formik.values.addressCity,
            onChange: (value: string) => {
              formik.setFieldValue("addressCity", value).then(() => {
                formik.validateField("addressCity");
              });
              formik.setFieldTouched("addressCity");
            },
          },
        },
      },
    },
    formik.submitForm,
    formik.resetForm,
  ] as const;
};
