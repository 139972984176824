import { makeStyles } from "@mui/styles";

const modalStyles = {
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  wrapContent: {
    display: "flex",
    flexDirection: "column" as "column",
    maxHeight: "90vh",
    maxWidth: "90vw",
    width: "max-content",
    padding: "70px 70px 50px",
    borderRadius: "5px",
    background: "#FFFFFF",
    position: "relative" as "relative",
    overflow: "auto",

    "@media(max-width: 1200px)": {
      padding: "40px 20px 20px",
    },

    "@media(max-width: 600px)": {
      width: "100%",
      overflow: "auto",
    },

    "& .close-button": {
      position: "absolute" as "absolute",
      top: "10px",
      right: "10px",
      height: "25px",
      width: "25px",
      cursor: "pointer",

      "&::before, &::after": {
        content: '""',
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%) rotateZ(45deg)",
        height: "20px",
        width: "2px",
        background: "#797882",
      },

      "&::before": {
        transform: "translate(-50%, -50%) rotateZ(-45deg)",
      },
    },
  },
};

const useStyles = makeStyles((theme) => {
  return {
    ...modalStyles,
  };
});

export { useStyles, modalStyles };
