import { useEffect, useState } from "react";
import { Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import Centor from "../../../../assets/icons/tykIcons/Centor-icon@2x.png";
import LoneStar from "../../../../assets/icons/tykIcons/LoneStar-icon@2x.png";
import HolidayCampaign from "../../../../assets/icons/campaign/holiday-campaign.svg";
import DoubleTripleCampaign from "../../../../assets/icons/campaign/double-triple-campaign.svg";
import LowTrafficCampaign from "../../../../assets/icons/campaign/low-traffic-campaign.svg";
import SurpriseCampaign from "../../../../assets/icons/campaign/surprise-campaign.svg";
import { ExclamationTriangle } from "../../../../utils/icon";
import AnalyticsCard from "../../AnalyticsCard";
import ViewDetailsButton from "../ViewDetailsButton";
import { campaigns as data, CampaignTypes, Campaign } from "./data";
import theme from "../../../../theme";
import useStyles from "./styles";

const PAGE_SIZE = 4;

const Campaigns = () => {
  const { tableContainer, emptyStateWrap } = useStyles(false)();
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);

  useEffect(() => {
    setCampaigns(data.rows);
  }, []);

  const getCampaignTypeIcon = (type: string) => {
    switch (type) {
      case CampaignTypes.Holiday:
        return HolidayCampaign;
      case CampaignTypes.DoubleTriple:
        return DoubleTripleCampaign;
      case CampaignTypes.LowTraffic:
        return LowTrafficCampaign;
      case CampaignTypes.Surprise:
        return SurpriseCampaign;
      default:
        return "";
    }
  };

  return (
    <AnalyticsCard
      headerTitle="analytics.campaigns.title"
      headerSubtitle="analytics.campaigns.sub_title"
      cardColor={campaigns?.length ? theme.palette.white : "#F8F8F8"}
      dataLength={data.count}
      withDivider={false}
    >
      <TableContainer className={tableContainer}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead className="tableHeadRow">
            <TableRow>
              <TableCell>
                <FormattedMessage id="analytics.campaigns.table_names.type" />
              </TableCell>

              <TableCell>
                <FormattedMessage id="analytics.campaigns.table_names.preview" />
              </TableCell>

              <TableCell>
                <FormattedMessage id="analytics.campaigns.table_names.name" />
              </TableCell>

              <TableCell align="right">
                <FormattedMessage id="analytics.campaigns.table_names.spent" />
              </TableCell>

              <TableCell>
                <FormattedMessage id="analytics.campaigns.table_names.distributed" />
              </TableCell>

              <TableCell>
                <FormattedMessage id="analytics.campaigns.table_names.behavior" />
              </TableCell>

              <TableCell>
                <FormattedMessage id="analytics.campaigns.table_names.details" />
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {campaigns &&
              campaigns.length > 0 &&
              campaigns.slice(0, PAGE_SIZE).map((campaign) => (
                <TableRow key={campaign.id} className="tableBodyRow">
                  <TableCell>
                    <Box className="campaignItemImageWrap">
                      <img className="campaignImage" src={getCampaignTypeIcon(campaign?.type || "")} alt="Campaign" />
                    </Box>
                  </TableCell>

                  <TableCell>
                    <Box className="campaignItemImageWrap">
                      <img className="campaignImage" src={campaign.previewImage} alt="" />
                    </Box>
                  </TableCell>

                  <TableCell>{campaign.name}</TableCell>

                  <TableCell align="right">{`$${campaign.spent?.toLocaleString()}`}</TableCell>

                  <TableCell>
                    <Box display="flex" flexDirection="row" marginRight="10px">
                      <Box display="flex" flexDirection="row" alignItems="flex-end" marginRight="16px">
                        <Typography
                          fontFamily={theme.typography.fontFamily}
                          fontSize="0.75rem"
                          fontWeight="bold"
                          marginRight="6px"
                          minWidth="24px"
                        >
                          {campaign.distributed?.tyks.toLocaleString()}
                        </Typography>

                        <img src={Centor} alt="Tyk" height={20} />
                      </Box>

                      <Box display="flex" flexDirection="row" alignItems="flex-end">
                        <Typography
                          fontFamily={theme.typography.fontFamily}
                          fontSize="0.75rem"
                          fontWeight="bold"
                          marginRight="6px"
                          minWidth="20px"
                        >
                          {campaign.distributed?.loneStars.toLocaleString()}
                        </Typography>

                        <img src={LoneStar} alt="Tyk" height={20} />
                      </Box>
                    </Box>
                  </TableCell>

                  <TableCell>
                    <img src={campaign.behavior} alt="" height={20} />
                  </TableCell>

                  <TableCell>
                    <ViewDetailsButton detailsData={campaign} />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {!campaigns?.length && (
        <Box className={emptyStateWrap}>
          <ExclamationTriangle fill="#818FD4" />

          <Typography marginLeft="10px" color="#797882" fontFamily={theme.typography.fontFamily} fontSize="0.75rem">
            <FormattedMessage id="analytics.campaigns.no_campaigns" />
          </Typography>
        </Box>
      )}
    </AnalyticsCard>
  );
};

export default Campaigns;
