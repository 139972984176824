import { gql } from "@apollo/client";

export const removePaymentMethodMutation = gql`
  mutation RemovePaymentMethodMutation($userPaymentMethodId: String!, $businessId: String!) {
    removePaymentMethod(userPaymentMethodId: $userPaymentMethodId, businessId: $businessId) {
      message
      status
      payload
    }
  }
`;
