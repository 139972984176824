import { FC } from "react";
import { useQuery } from "@apollo/client";

import Welcome from "./Welcome";
import { Query, ScannedReceiptsPayload } from "../../generated/types";
import { Memberships, MembershipsInternalId } from "../../types/membershipTypes";
import Layout from "./Layout";
import { useBusinessProfile } from "../../hooks/useBusinessProfile.hook";
import { GET_USER_MONTHLY_SCANNED_RECEIPTS, GET_USER_MONTHLY_TOTAL_CENTOR_AMOUNT } from "../../apollo/queries";
import { GetRewardRulesDashboardInfoQuery } from "./graphql/GetRewardRulesDashboardInfo.query";

interface MembershipDashboardProps {
  userMembership?: string;
  internalId:number | undefined
}

const DEFAULT_SCANNED_RECEIPTS_VALUE: ScannedReceiptsPayload = {
  scannedReceipts: 0,
  totalScannedReceipts: 0,
};

const MembershipDashboard: FC<MembershipDashboardProps> = ({ userMembership, internalId }) => {
  const [user] = useBusinessProfile();
const isMembershipEnabled = internalId === MembershipsInternalId.Business;

  const { data: monthlyTotalCentorAmountQuery, loading: isLoadingMonthlyTotalCentorAmountQuery } = useQuery<
    Pick<Query, "getMonthlyTotalCentorAmount">
  >(GET_USER_MONTHLY_TOTAL_CENTOR_AMOUNT, {
    variables: {
      userId: user?.id,
    },
  });

  const { data: monthlyScannedReceiptsQuery, loading: isLoadingMonthlyScannedReceiptsQuery } = useQuery<
    Pick<Query, "getMonthlyScannedReceipts">
  >(GET_USER_MONTHLY_SCANNED_RECEIPTS, {
    variables: {
      userId: user?.id,
    },
  });

  const { data: monthlyRewardData, loading: isLoadingMonthlyRewardData } = useQuery(GetRewardRulesDashboardInfoQuery, {
    variables: {
      userId: user?.id || "",
    },
  });

  const totalCentorAmount = monthlyTotalCentorAmountQuery?.getMonthlyTotalCentorAmount?.payload ?? 0;
  const receipts: ScannedReceiptsPayload =
    monthlyScannedReceiptsQuery?.getMonthlyScannedReceipts?.payload ?? DEFAULT_SCANNED_RECEIPTS_VALUE;

  const rewardsData = monthlyRewardData?.getRewardRulesDashboardInfo?.payload ?? null;
  const isLoading =
    isLoadingMonthlyRewardData || isLoadingMonthlyScannedReceiptsQuery || isLoadingMonthlyTotalCentorAmountQuery;

  if (!userMembership) {
     return <Welcome />;
  }

  return (
    <Layout
      isYourRewardsEnabled={isMembershipEnabled}
      totalCentorAmount={totalCentorAmount}
      receipts={receipts}
      rewardsData={rewardsData}
      isLoading={isLoading}
      userMembership={userMembership}
    />
  );
};

export default MembershipDashboard;
