import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    qrSuccessWrap: {
      flex: 1,
      padding: `${theme.spacing(11)} ${theme.spacing(6)}`,
      background: theme.palette.technology.white,
      [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
        padding: `${theme.spacing(11)} ${theme.spacing(2)}`,
      },
      "& .MuiButton-root": {
        height: "32px",
        width: "159px",
        backgroundColor: theme.palette.technology.blue,
        color: theme.palette.technology.white,
        borderRadius: "20px",
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none",
        },
      },
      "& .MuiTypography-root": {
        fontFamily: `${theme.typography.fontFamily}`,
      },
    },
  };
});

export default useStyles;
