import { FC, useRef } from "react";
import { FormikProps } from "formik";
import { useTheme } from "@mui/styles";

import { useIntl } from "react-intl";
import Icon from "../../../../../../utils/icon";

import { ITykchaseGlobalConfig } from "../../../../../../types/tykchaseGlobalConfig";

import { TykchaseCampaignTypes, TykchaseCampaignTriggers } from "../../../../../../generated/types";
import { CampaignSteps, StepsFormikValues } from "../../../../../../types/campaigns";
import CampaingLayout from "../Layout/CampaingLayout";

type Props = {
  active: boolean;
  handlerModal: () => void;
  tykchaseGlobalConfig?: ITykchaseGlobalConfig;
};

const Surprise: FC<Props> = ({ active, handlerModal, tykchaseGlobalConfig }: Props) => {
  const theme = useTheme();
  const informationRef = useRef<FormikProps<StepsFormikValues>>(null);
  const triggerRef = useRef<FormikProps<StepsFormikValues>>(null);
  const rewardRef = useRef<FormikProps<StepsFormikValues>>(null);
  const { formatMessage } = useIntl();

  return (
    <CampaingLayout
      active={active}
      handlerModal={handlerModal}
      informationRef={informationRef}
      triggerRef={triggerRef}
      rewardRef={rewardRef}
      title={formatMessage({ id: "rewards.campaigns.surprise.title" })}
      subTitle={formatMessage({ id: "rewards.campaigns.surprise.sub_title" })}
      mainBgColor={theme.palette.campaigns.blue}
      secondaryBgColor={theme.palette.campaigns.darkBlue}
      Icon={Icon.Surprise}
      paginationTitles={[
        formatMessage({ id: "rewards.campaigns.pagination_titles.information" }),
        formatMessage({ id: "rewards.campaigns.pagination_titles.trigger" }),
        formatMessage({ id: "rewards.campaigns.pagination_titles.reward_settings" }),
      ]}
      informationStepKey={`${TykchaseCampaignTypes.Surprise}-${CampaignSteps.Information}`}
      triggerType={TykchaseCampaignTriggers.Surprise}
      campaignType={TykchaseCampaignTypes.Surprise}
      rewardStepKey={`${TykchaseCampaignTypes.Surprise}-${CampaignSteps.Reward}`}
      triggerStepKey={`${TykchaseCampaignTypes.Surprise}-${CampaignSteps.Trigger}`}
      summaryStepKey={`${TykchaseCampaignTypes.Surprise}-${CampaignSteps.Summary}`}
      tykchaseGlobalConfig={tykchaseGlobalConfig}
    />
  );
};

export default Surprise;
