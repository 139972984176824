import { IntlShape } from "react-intl";
import { Grid } from "@mui/material";

import RewardRules from "../components/Rewards/RewardRules";
import Campaigns from "../components/Rewards/Campaigns";
import Experiences from "../components/Rewards/Experiences";
import Jackpot from "../components/Rewards/Jackpot";
import Promoters from "../components/Rewards/Promoters";

import { IYourRewardsItem, YourRewardsItems, YourRewardsTabsIds } from "../types/yourRewards";
import { HashRoutes } from "../constants";

export const tabsItems = (intl: IntlShape): IYourRewardsItem[] => {
  return [
    {
      label: YourRewardsItems(intl).REWARD_RULES,
      renderScene: () => (
        <Grid role="tabpanel" px={{ lg: 6, md: 3 }}>
          <RewardRules />
        </Grid>
      ),
      hash: HashRoutes.RULES,
      id: YourRewardsTabsIds.RULES,
    },
    {
      label: YourRewardsItems(intl).CAMPAIGNS,
      renderScene: () => (
        <Grid width="100%" role="tabpanel" px={{ lg: 6, md: 3 }}>
          <Campaigns />
        </Grid>
      ),
      hash: HashRoutes.CAMPAIGNS,
      id: YourRewardsTabsIds.CAMPAIGNS,
    },
    {
      label: YourRewardsItems(intl).EXPERIENCES,
      renderScene: () => (
        <Grid role="tabpanel" width="100vw">
          <Experiences />
        </Grid>
      ),
      hash: HashRoutes.EXPERIENCES,
      id: YourRewardsTabsIds.EXPERIENCES,
    },
    {
      label: YourRewardsItems(intl).JACKPOT,
      renderScene: () => (
        <Grid width="100%" role="tabpanel" px={{ lg: 6, md: 3 }}>
          <Jackpot />
        </Grid>
      ),
      hash: HashRoutes.JACKPOT,
      id: YourRewardsTabsIds.JACKPOT,
    },
    {
      label: YourRewardsItems(intl).PROMOTERS,
      renderScene: () => (
        <Grid width="100%" role="tabpanel" px={{ lg: 6, md: 3 }}>
          <Promoters />
        </Grid>
      ),
      hash: HashRoutes.PROMOTERS,
      id: YourRewardsTabsIds.PROMOTERS,
    },
  ];
};
