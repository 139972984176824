import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      padding: "1rem",
    },
    cardPadding: {
      padding: "20px",
    },
    uploadDate: {
      fontSize: "10px",
      fontFamily: theme.typography.fontFamily,
    },
    dateButtonContainer: {
      alignContent: "center",
      alignItem: "center",
    },

    cardAddress: {
      fontSize: "15px",
      fontFamily: theme.typography.fontFamily,
    },
    receiptImg: {
      height: "5rem",
      width: "5rem",
    },
    deleteButton: {
      textTransform: "none",
      height: "25px",
      fontFamily: theme.typography.fontFamily,
    },
    successButton: {
      textTransform: "none",
      marginRight: "10px",
      height: "25px",
      fontFamily: theme.typography.fontFamily,
    },
  };
});

export default useStyles;
