import { FC, useState, useMemo, RefObject, Key } from "react";
import { FormikProps, useFormik } from "formik";
import Modal from "../../../../../../Modal";
import Layout from "..";
import {
  Holiday,
  TykchaseCampaign,
  TykchaseCampaignTriggers,
  TykchaseCampaignTypes,
} from "../../../../../../../generated/types";

import { StepsFormikValues } from "../../../../../../../types/campaigns";
import { informationSchema, rewardSchema, triggerSchema } from "../../ValidationSchema";
import { ITykchaseGlobalConfig } from "../../../../../../../types/tykchaseGlobalConfig";
import InformationStep from "../../ModalSteps/InformationStep";
import TriggerStep from "../../ModalSteps/TriggerStep";
import SummaryStep from "../../ModalSteps/SummaryStep";
import RewardStep from "../../ModalSteps/RewardStep";

type Props = {
  active: boolean;
  handlerModal: () => void;
  informationRef: RefObject<FormikProps<StepsFormikValues>>;
  triggerRef: RefObject<FormikProps<StepsFormikValues>>;
  Icon: (props: any) => JSX.Element;
  title: string;
  subTitle: string;
  informationStepKey: Key | null | undefined;
  triggerStepKey: Key | null | undefined;
  rewardStepKey: Key | null | undefined;
  summaryStepKey: Key | null | undefined;
  triggerType: TykchaseCampaignTriggers | boolean;
  campaignType: TykchaseCampaignTypes;
  paginationTitles: string[];
  mainBgColor: string;
  secondaryBgColor: string;
  isLoadingHolidaysData?: boolean;
  tykchaseGlobalConfig?: ITykchaseGlobalConfig | undefined;
  isLoadingActiveCampaignsData?: boolean;
  holidays?: Holiday[];
  rewardRef?: RefObject<FormikProps<StepsFormikValues>>;
  activeCampaigns?: TykchaseCampaign[] | undefined;
};

const CampaingLayout: FC<Props> = ({
  active,
  handlerModal,
  holidays,
  informationRef,
  triggerRef,
  rewardRef,
  Icon,
  title,
  subTitle,
  triggerType,
  campaignType,
  isLoadingHolidaysData,
  tykchaseGlobalConfig,
  informationStepKey,
  triggerStepKey,
  rewardStepKey,
  summaryStepKey,
  paginationTitles,
  mainBgColor,
  secondaryBgColor,
  activeCampaigns,
  isLoadingActiveCampaignsData,
}: Props) => {
  const [currentStep, setCurrentStep] = useState(0);
  const handleChangeStep = () => {
    setCurrentStep(currentStep + 1);
  };
  const handlerBackCurrentStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleSchema = useMemo(() => {
    if (currentStep === 0) {
      return informationSchema;
    }
    if (currentStep === 1) {
      return triggerSchema;
    }
    return rewardSchema;
  }, [currentStep]);

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      holidayId: "",
      startDate: "",
      endDate: "",
      customCoverBase64: "",
      receiptTotalPriceTrigger: 0,
      surpriseNumber: 0,
      dollarReward: "",
      purchaseRewardRate: "",
      multiplierNumber: 0,
      boostedTykchaseCampaign: "",
      boostedTykchaseCampaignId: "",
      dayTrigger: "",
      name: "",
      code: "",
      price: 0,
      triggerType,
      campaignType,
    },
    validationSchema: handleSchema,

    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(false);
    },
  });

  const modalSteps = [
    <InformationStep
      campaignType={formik.values.campaignType}
      holidays={holidays}
      isLoadingHolidaysData={isLoadingHolidaysData}
      informationRef={informationRef}
      key={informationStepKey}
      formik={formik}
    />,
    <TriggerStep
      activeCampaigns={activeCampaigns}
      isLoadingActiveCampaignsData={isLoadingActiveCampaignsData}
      triggerType={formik.values.triggerType}
      triggerRef={triggerRef}
      key={triggerStepKey}
      formik={formik}
    />,
    ...(triggerType
      ? [
          <RewardStep
            rewardRef={rewardRef as RefObject<FormikProps<StepsFormikValues>>}
            tykchaseGlobalConfig={tykchaseGlobalConfig}
            key={rewardStepKey}
            formik={formik}
          />,
        ]
      : []),

    <SummaryStep formik={formik} key={summaryStepKey} />,
  ];

  return (
    <div>
      <Modal
        isOpen={active}
        onClose={handlerModal}
        isClosable={false}
        p="0"
        width={{ xs: "calc(100% - 30px)", sm: "100%", lg: "900px" }}
      >
        <Layout
          title={title}
          subtitle={subTitle}
          mainBgColor={mainBgColor}
          secondaryBgColor={secondaryBgColor}
          icon={<Icon />}
          paginationTitles={paginationTitles}
          handlerModal={handlerModal}
          informationRef={informationRef}
          triggerRef={triggerRef}
          rewardRef={rewardRef as RefObject<FormikProps<StepsFormikValues>>}
          formik={formik}
          currentStep={currentStep}
          handlerBackCurrentStep={handlerBackCurrentStep}
          handleChangeStep={handleChangeStep}
        >
          {modalSteps}
        </Layout>
      </Modal>
    </div>
  );
};
export default CampaingLayout;
