import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "12px",
  },
  headerContainer: {
    display: "grid",
    gridTemplateColumns: "3fr 2fr",
    paddingLeft:'24px',
    paddingRight:'49px',
    fontSize: "15px",
    color: "#82858B",
    marginBottom: "12px",
  },
  quantityColumn: {
    textAlign: "right",
  },
  priceColumn: {
    textAlign: "right",
  },
  lineItemsContainer: {
    padding: "24px 0",
    backgroundColor: "#f3f3f7",
  },
}));
