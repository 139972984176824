import { FC, useState, useEffect } from "react";
import { Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { Event } from "../types";
import useStyles from "./styles";

interface EventsTableProps {
  events: Event[];
  onEventsSelected: (events) => void;
}

const EventsTable: FC<EventsTableProps> = ({ events, onEventsSelected }) => {
  const { tableContainer } = useStyles();
  const [selectedEvents, setSelectedEvents] = useState<Event[]>([]);
  const getIndex = (id: string) => selectedEvents.findIndex((event) => event.id === id);

  const handleEventsSelected = (element, isEventSelected) => {
    if (!isEventSelected) {
      setSelectedEvents([...selectedEvents, element]);
      return;
    }
    setSelectedEvents((prev) => prev && prev.filter((el) => el.id !== element.id));
  };

  useEffect(() => {
    onEventsSelected(selectedEvents);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEvents]);

  return (
    <TableContainer className={tableContainer}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />

            <TableCell />

            <TableCell>
              <FormattedMessage id="marketing.events_table.type" />
            </TableCell>

            <TableCell>
              <FormattedMessage id="marketing.events_table.event_name" />
            </TableCell>

            <TableCell align="right">
              <FormattedMessage id="marketing.events_table.end_date" />
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {events.length ? (
            events.map((element) => {
              const isElementSelected = getIndex(element.id) !== -1;

              return (
                <TableRow
                  role="checkbox"
                  key={element.id}
                  selected={isElementSelected}
                  onClick={() => handleEventsSelected(element, isElementSelected)}
                >
                  <TableCell padding="checkbox">
                    <Checkbox checked={isElementSelected} />
                  </TableCell>

                  <TableCell className="event-image-cell">
                    <div className="event-image" />
                  </TableCell>

                  <TableCell>
                    <div className="type-wrapper">
                      <p className="type-text">{element.type}</p>
                    </div>
                  </TableCell>

                  <TableCell>
                    <p>{element.name}</p>
                  </TableCell>

                  <TableCell align="right">
                    <p>{element.endDate}</p>
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <p className="empty-message">
              <FormattedMessage id="marketing.events_table.no_events" />
            </p>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EventsTable;
