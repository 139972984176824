import { Grid, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import useStyles from "./styles";

export const BillingAddress = ({ paymentMethod, chosenCardPaymentMethodId }) => {
  const { infoContLetterStyle, titleText } = useStyles();
  const { formatMessage } = useIntl();

  return paymentMethod.userPaymentMethodId === chosenCardPaymentMethodId ? (
    <Grid container item lg={6} direction="column" spacing={2}>
      <Grid item>
        <Typography className={titleText}>
          {formatMessage({ id: "payment_method_form.billing_address_section.title" })}
        </Typography>
      </Grid>
      <Grid item className={infoContLetterStyle}>
        <Typography>{formatMessage({ id: "payment_method_form.billing_address_section.address_line_1" })}</Typography>
        <Typography>{paymentMethod.addressLine || formatMessage({ id: "label_none" })}</Typography>
      </Grid>
      <Grid item className={infoContLetterStyle}>
        <Typography>{formatMessage({ id: "payment_method_form.billing_address_section.address_line_2" })}</Typography>
        <Typography>{paymentMethod.addressLineSecond || formatMessage({ id: "label_none" })}</Typography>
      </Grid>
      <Grid item className={infoContLetterStyle}>
        <Typography>{formatMessage({ id: "payment_method_form.billing_address_section.country" })}</Typography>
        <Typography>{paymentMethod.countryCode || formatMessage({ id: "label_none" })}</Typography>
      </Grid>
      <Grid item className={infoContLetterStyle}>
        <Typography>{formatMessage({ id: "payment_method_form.billing_address_section.state" })}</Typography>
        <Typography>{paymentMethod.state || formatMessage({ id: "label_none" })}</Typography>
      </Grid>

      <Grid item className={infoContLetterStyle}>
        <Typography>{formatMessage({ id: "payment_method_form.billing_address_section.zip" })}</Typography>
        <Typography>{paymentMethod.zipCode || formatMessage({ id: "label_none" })}</Typography>
      </Grid>
    </Grid>
  ) : null;
};
