import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => {
  return {
    emptyStateWrap: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: "24px 8px",
      margin: "16px 0",
      backgroundColor: "#FFDDC2",
      borderRadius: "6px",
    },
  };
});

export default useStyles;
