import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { formatDate } from "../../../../utils/formatDate";

interface MembershipCancellationNotificationProps {
  cancellationDate: Date;
}

export const MembershipCancellationNotification: FC<MembershipCancellationNotificationProps> = ({
  cancellationDate,
}) => (
  <FormattedMessage
    id="tykchase_membership_notifications.cancellation_notification.notification"
    values={{ cancellationDate: <strong data-testid="cancellation-date">{formatDate(cancellationDate)}</strong> }}
  />
);
