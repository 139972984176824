import { FC } from "react";
import { Button, Grid } from "@mui/material";

import { FormattedMessage } from "react-intl";
import useStyles from "./styles";

import { TykchaseCampaignTypes } from "../../../../../../../generated/types";

import { CAMPAIGN_MODAL_STEPS } from "../../../../../../../constants";

type Props = {
  currentStep: number;
  isDoubleTripleCampaign: boolean;
  handleBack?: () => void;
  handleCancel?: () => void;
  handleNext?: () => void;
};

const ButtonsContainer: FC<Props> = ({
  currentStep,
  isDoubleTripleCampaign,
  handleBack,
  handleCancel,
  handleNext,
}: Props) => {
  const { buttonsContainer } = useStyles();

  return (
    <Grid
      className={buttonsContainer}
      container
      justifyContent="space-between"
      p={{ xs: "10px 15px 20px", sm: "20px 40px 30px", md: "0 62px 72px" }}
    >
      <Grid container flexWrap="nowrap" width="max-content">
        {!!currentStep && (
          <Button onClick={handleBack}>
            <FormattedMessage id="btn_text_back" />
          </Button>
        )}
        <Button onClick={handleCancel}>
          <FormattedMessage id="btn_text_cancel" />
        </Button>
      </Grid>
      <Button onClick={handleNext}>
        {(isDoubleTripleCampaign && currentStep === CAMPAIGN_MODAL_STEPS.REWARD_STEP) ||
        currentStep === CAMPAIGN_MODAL_STEPS.SUMMARY_STEP ? (
          <FormattedMessage id="btn_text_finish" />
        ) : (
          <FormattedMessage id="btn_text_next" />
        )}
      </Button>
    </Grid>
  );
};

export default ButtonsContainer;
