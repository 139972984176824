import { Button, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useState } from "react";
import Sidebar from "./Sidebar";
import Table from "./Table";
import useStyles from "./styles";
import GeneralAutomateSettings from "./modals/GeneralAutomateSettings";

const Promoters = () => {
  const theme = useTheme();
  const { wrap } = useStyles();
  const [modalActive, setModalActive] = useState(false);
  const handlerModal = () => {
    setModalActive(!modalActive);
  };
  return (
    <Grid
      className={wrap}
      mt="45px"
      p={{ xs: "30px 20px 40px", md: "30px 20px 20px 40px" }}
      bgcolor={theme.palette.white}
    >
      <Typography mb="28px" color={theme.palette.black}>
        Your promoters
      </Typography>

      <Grid container flexWrap="nowrap" flexDirection={{ xs: "column-reverse", md: "row" }}>
        <Grid
          width={{ xs: "100%", md: "calc(60% - 20px)", lg: "calc(75% - 35px)" }}
          mr={{ xs: "0", md: "35px" }}
        >
          <Table />
          <Grid container alignItems="center" flexWrap="nowrap" justifyContent="end">
            <Typography
              onClick={() => {
                handlerModal();
              }}
              className="underline"
              mr="15px"
              fontSize="0.875rem"
              color={theme.palette.dashBoard?.blue}
            >
              General automate settings
            </Typography>
            <Button className="distrubute">Distrubute acumulated</Button>
            <Button>Reward</Button>
          </Grid>
        </Grid>
        <Grid width={{ xs: "100%", md: "40%", lg: "25%" }} mb={{ xs: "10px", md: "0" }}>
          <Sidebar />
        </Grid>
      </Grid>

      <GeneralAutomateSettings modalActive={modalActive} handlerModal={handlerModal} />
    </Grid>
  );
};

export default Promoters;
