import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  container: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridTemplateAreas: `
      "title title"
      "cardholderName cardholderName"
      "number number"
      "expiry cvv"
    `,
    gridAutoRows: "max-content",
    gridGap: "0px 74px",
  },
  cardholderName: {
    gridArea: "cardholderName",
    "& input": {
      width: "100%",
      background: "none",
      fontSize: "12px",
      fontWeight: "300",

      "&:focus": {
        outline: "0.05rem solid blue",
      },

      "&::placeholder": {
        color: "#767676",
        fontWeight: "300",
      },
    },
  },
  number: {
    gridArea: "number",
  },
  expiry: {
    paddingTop: "0px !important",
    marginTop: "0px !important",
    gridArea: "expiry",
  },
  cvv: {
    paddingTop: "0px !important",
    marginTop: "0px !important",
    gridArea: "cvv",
  },
  label: {
    fontSize: "12px",
    color: "#6E6E6E",
  },
  input: {
    border: "1px solid #CACACA",
    borderRadius: "4px",
    padding: "8px",
    lineHeight: "1.2em",

    "&.StripeElement--focus": {
      outline: "0.05rem solid blue",
    },
  },
  errorText: {
    color: "#FF0000",
  },
  message: {
    "&.MuiAlert-root": {
      backgroundColor: "#FFF2E2",
      color: "#5C2122",
      fontSize: "0.75rem",
      padding: "2px 4px",
      marginTop: "2px",

      "& .MuiAlert-icon": {
        color: "#FF983B",
        margin: 0,
        padding: 0,
        alignSelf: "center",

        "& MuiSvgIcon-root": {
          width: "10px",
          height: "10px",
        },
      },

      "& .MuiAlert-message": {
        margin: "0 0 0 6px",
        padding: 0,
        overflow: "hidden",
        alignSelf: "center",
      },
    },
  },
}));
