import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: "100%",
      padding: "2rem 1rem",
      display: "flex",
      justifyContent: "center",
    },
    summary: {
      color: "#12111A",
      fontSize: "1.2rem",
      marginBottom: "2rem",
    },
    totalContainer: {
      display: "flex",
      justifyContent: "flex-end",
      "& .MuiTableBody-root": {
        width: "auto",
        "& tr": {
          "& td": {
            padding: "2px 16px",
            fontFamily: theme.typography.fontFamily,
            fontSize: "0.875rem",
            color: theme.palette.black,
            border: "none",
          },
          "& td:first-child": {
            fontSize: "0.75rem",
            color: "#82858B",
          },
        },
      },
    },
    formGroup: {
      margin: `${theme.spacing(2)} 0 ${theme.spacing(12)}`,

      "& .MuiFormControlLabel-root": {
        height: "20px",
        width: "100%",
        marginTop: "0.5rem",
      },
    },
    termsLabel: {
      fontSize: "0.75rem",
      color: "#737373",
    },
    modalBtn: {
      textTransform: "none",
      textDecoration: "underline",
      color: "#0364FF",
    },
    tableContainer: {
      marginBottom: "8px",

      "& .MuiTableBody-root": {
        backgroundColor: "#8986AD19",
        borderRadius: "3px",

        "& tr.tableBodyRow": {
          "& td": {
            fontFamily: theme.typography.fontFamily,
            fontSize: "0.75rem",
            fontWeight: 600,
            color: "#797882",
            border: "none",
          },
        },
      },
      "& .tableHeadRow": {
        "& th": {
          padding: "10px",
          fontFamily: theme.typography.fontFamily,
          fontSize: "0.75rem",
          color: "#797882",
          border: "none",
        },

        "& th:first-child": {
          fontSize: "0.875rem",
          fontWeight: "bold",
          color: theme.palette.black,
        },
      },
    },

    termsAndConditionsLink: {
      backgroundColor: "transparent",
      border: "none",
      padding: "0",
      color: "#4262FF",
      textDecoration: "underline",
      cursor: "pointer",
    },

    cancellationModal: {
      "& .modal": {
        padding: `${theme.spacing(6)} ${theme.spacing(6)} ${theme.spacing(4)} ${theme.spacing(6)}`,
      },

      "& .modal-title": {
        display: "flex",
        justifyContent: "space-between",
        paddingBottom: theme.spacing(3),
        "& .modal-title-text": {
          fontSize: "1.25rem",
          fontWeight: 700,
        },
        "& .warning-icon": {
          color: "#FFBE40",
          width: "51px",
          height: "45px",
          flexShrink: 0,
        },
      },

      "& .modal-content": {
        padding: `${theme.spacing(2)} 0 ${theme.spacing(3)}`,
      },

      "& .btn": {
        width: "150px",
        fontWeight: 700,
        borderRadius: "2rem",
        textTransform: "none",
      },

      "& .confirm-btn": {
        backgroundColor: "#C8000000",
        border: "2px solid #747474",
        color: "#747474",
        "&:hover": {
          backgroundColor: "#47474712",
        },
      },

      "& .cancel-btn": {
        backgroundColor: "#1473E6",
        color: theme.palette.white,
        "&:hover": {
          backgroundColor: "#203ED4",
        },
      },
    },
  };
});

export default useStyles;
