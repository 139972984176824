import React, { FC, useMemo, useState, useCallback, Fragment, useEffect } from "react";
import {
  AlertColor,
  Box,
  Grid,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import { FormattedMessage, MessageDescriptor, useIntl } from "react-intl";
import { useUser } from "@stagewood/unified-login-library";
import { ReactComponent as DownloadIcon } from "../../assets/icons/technology/feather-download-Icon.svg";
import useStyles from "./styles";
import { BusinessCardReceipt } from "./BusinessCard";
import { getTykchaseLocationsQuery } from "./graphql/GetTykchaseLocations.query";
import { useBusinessProfile } from "../../hooks/useBusinessProfile.hook";
import { PageLoader } from "../PageLoader";
import { formatDate } from "../../utils/formatDate";
import { IBusinessReceiptsCards } from "./BusinessCard/types";
import { getUploadUrlMutation } from "./graphql/GetUploadUrl.mutation";
import { getFileExtension } from "../../utils/getFileExtension";
import { HttpMethod } from "../../types/httpMethod";
import { addHashToFileName } from "../../utils/addHashToFileName";
import { readFileAsync } from "../../utils/readFileAsync";
import { getOptions } from "../Utils/Notifications";
import { ReceiptUploadModal } from "./ReceiptUploadModal";
import { useBooleanState } from "../../hooks/useBooleanState.hook";
import { uploadReceiptMutation } from "./graphql/UploadReceipt.mutation";
import { Status } from "../../gql/graphql";
import { removeAddressVariantMutation } from "./graphql/RemoveAddressVariant.mutation";
import { ReceiptRemoveModal } from "./ReceiptRemoveModal";
import { downloadFile } from "../../utils/downloadFile";
import { PAGINATION_OPTIONS } from "../BillingPayments/Billing/Billing.consts";
import { BillingOrderByFieldEnum, BillingOrderEnum } from "../BillingPayments/Billing/Billing.interfaces";
import { getComparator } from "../BillingPayments/Billing/Billing.helpers";
import Icon from "../../utils/icon";
import { EnrolledModal } from "./EnrolledModal";
import { useModalRouteState } from "../../hooks/useModalRouteState.hook";
import { SuccessModal } from "./SuccessModal";

enum MessagesMap {
  SUCCESS = "receipt_successfully_uploaded",
  ERROR_GENERAL = "error_could_not_upload_receipt",
  BUSINESS_NOT_FOUND = "error_business_not_found",
  BUSINESS_ADDRESSES_NOT_FOUND = "error_business_addresses_not_found",
  BUSINESS_ADDRESS_DID_NOT_MATCH = "error_business_addresse_not_match",
  LOW_LEVEL_OF_CONFIDENCE_FOR_REQUIRED_DATA = "error_low_level_of_confidence_for_required_data",
  DELETE_SUCCESS = "receipt_successfully_deleted",
  DELETE_FAIL = "receipt_failed_to_delete",
}

const BusinessReceiptsCards: FC<IBusinessReceiptsCards> = ({ locationReceiptVariants, onDelete, onDownload }) => {
  const filteredVariants = useMemo(() => {
    return locationReceiptVariants.filter((variant) => !variant.deletedAt);
  }, [locationReceiptVariants]);

  return (
    <>
      {filteredVariants.map((locationReceiptVariant) => (
        <BusinessCardReceipt
          id={locationReceiptVariant.id}
          storeTitle={locationReceiptVariant.storeTitle}
          storeAddress={locationReceiptVariant.storeAddressNormalized}
          uploadedDate={formatDate(locationReceiptVariant.updatedAt)}
          receiptUrl={locationReceiptVariant.receiptUrl}
          phoneNumber={locationReceiptVariant.phoneNumber ?? ""}
          onDelete={onDelete}
          onDownload={onDownload}
          key={locationReceiptVariant.id}
        />
      ))}
    </>
  );
};

function stableSort(array, comparator: (a, b) => number) {
  const stabilizedThis = array.map((el, index) => {
    return [el, index];
  });

  stabilizedThis.sort((a, b) => {
    const filtered = a[0];
    const filtered1 = b[0];
    const order = comparator(filtered?.tykchaseLocation ?? false, filtered1?.tykchaseLocation ?? false);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export const Locations: FC = () => {
  const LOCATION_ENROLL_SUCCESS = "locationEnrollSuccess";
  const { mainContainer, border, color, enrolledState } = useStyles();
  const [businessProfile, businessProfileLoading, , refetch] = useBusinessProfile();
  const user = useUser();
  const { isVisible: isSuccessModalVisible, closeModal: closeSuccessVisible } =
    useModalRouteState(LOCATION_ENROLL_SUCCESS);
  const closeModal = useCallback(
    (id) => {
      refetch();
      closeSuccessVisible();
    },
    [closeSuccessVisible, refetch],
  );

  const [locationId, setLocationId] = useState<string | null>(null);
  const [addressVariantId, setAddressVariantId] = useState<string | null>(null);
  const [location, setLocation] = useState<string | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [order, setOrder] = useState<BillingOrderEnum>(BillingOrderEnum.DESC);
  const [orderBy, setOrderBy] = useState<BillingOrderByFieldEnum>(BillingOrderByFieldEnum.IS_ENABLED);
  const [isVisibleArray, setIsVisibleArray] = useState<String[]>([]);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const [open, setOpen] = useState(false);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const locations = businessProfile?.businessAddresses ?? [];
  const handleClose = (id: string) => {
    setIsVisibleArray(isVisibleArray.filter((item) => item !== id));
    setOpen(false);
  };
  const visibleRows = React.useMemo(
    () =>
      stableSort(locations, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [locations, order, orderBy, page, rowsPerPage],
  );
  const setVisible = (id: string) => {
    setIsVisibleArray([id, ...isVisibleArray]);
    setOpen(true);
  };

  const handleRequestSort = useCallback(
    (property: BillingOrderByFieldEnum) => {
      const isAsc = orderBy === property && order === BillingOrderEnum.ASC;
      setOrder(isAsc ? BillingOrderEnum.DESC : BillingOrderEnum.ASC);
      setOrderBy(property);
    },
    [order, orderBy],
  );

  const createSortHandler = useCallback(
    (property: BillingOrderByFieldEnum) => () => {
      handleRequestSort(property);
    },
    [handleRequestSort],
  );

  const showUploadReceiptModal = useBooleanState(false);
  const showEnrolledtModal = useBooleanState(false);
  const showDeleteReceiptModal = useBooleanState(false);
  const uploading = useBooleanState(false);
  const deleting = useBooleanState(false);
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  const showNotification = useCallback(
    (variant: AlertColor, messageId: MessageDescriptor["id"]) => {
      const DELAY = 250;
      setTimeout(() => {
        enqueueSnackbar(intl.formatMessage({ id: messageId }), getOptions(variant));
      }, DELAY);
    },
    [intl, enqueueSnackbar],
  );

  const { data: tykchaseLocations, loading: tykchaseLocationsLoading } = useQuery(getTykchaseLocationsQuery, {
    variables: {
      userId: businessProfile?.id as string,
    },
  });

  const [getUploadUrl] = useMutation(getUploadUrlMutation);
  const [createReceiptAddressVariant] = useMutation(uploadReceiptMutation, {
    onCompleted: ({ uploadReceipt }) => {
      if (uploadReceipt?.status === Status.Error) {
        const message = uploadReceipt?.message as string;
        const errorMessage = MessagesMap[message] || MessagesMap.ERROR_GENERAL;
        showNotification("error", errorMessage);
      } else {
        showNotification("success", MessagesMap.SUCCESS);
      }
    },
    refetchQueries: [getTykchaseLocationsQuery],
    awaitRefetchQueries: true,
  });

  const [deleteReceiptAddressVariant] = useMutation(removeAddressVariantMutation, {
    onCompleted: ({ removeTykchaseLocationReceiptVariant }) => {
      if (!removeTykchaseLocationReceiptVariant?.payload) {
        showNotification("error", MessagesMap.DELETE_FAIL);
        return;
      }
      showNotification("success", MessagesMap.DELETE_SUCCESS);
    },
    refetchQueries: [getTykchaseLocationsQuery],
    awaitRefetchQueries: true,
  });

  const handleBusinessAddressClick = useCallback(
    (id: string) => {
      if (businessProfile?.businessAddresses?.some((address) => address?.streetAddress1)) {
        setLocationId(id);
        showUploadReceiptModal.setTrue();
      } else {
        showNotification("warning", MessagesMap.BUSINESS_ADDRESSES_NOT_FOUND);
      }
    },
    [businessProfile?.businessAddresses, showNotification, showUploadReceiptModal],
  );

  const uploadFileToS3 = async (fileToUpload: File, uploadUrl: string): Promise<Response> => {
    const buffer = await readFileAsync(fileToUpload);

    return fetch(uploadUrl, {
      method: HttpMethod.PUT,
      body: buffer,
      headers: {
        "Content-Type": fileToUpload.type,
      },
    });
  };

  const handleFileSubmit = async (): Promise<void> => {
    if (!file) {
      return;
    }

    try {
      uploading.setTrue();

      const { data } = await getUploadUrl({
        variables: {
          extension: getFileExtension(file?.name || ""),
          filename: addHashToFileName(file?.name || ""),
        },
      });

      const urls = data?.getUploadUrl?.payload;

      const response = await uploadFileToS3(file, urls?.uploadUrl as string);

      if (!response.ok) {
        showNotification("error", MessagesMap.ERROR_GENERAL);
        return;
      }

      await createReceiptAddressVariant({
        variables: {
          url: urls?.downloadUrl as string,
          tykchaseLocationId: locationId as string,
        },
      });
    } catch (err) {
      showNotification("error", MessagesMap.ERROR_GENERAL);
    } finally {
      uploading.setFalse();
      showUploadReceiptModal.setFalse();
      setFile(null);
    }
  };

  const handleDelete = async () => {
    try {
      deleting.setTrue();

      await deleteReceiptAddressVariant({
        variables: {
          id: addressVariantId as string,
        },
      });
    } catch (err) {
      showNotification("error", MessagesMap.DELETE_FAIL);
    } finally {
      deleting.setFalse();
      showDeleteReceiptModal.setFalse();
    }
  };
  const invokeEnrolledModal = (id: string) => {
    setLocation(id);
    if (id) {
      showEnrolledtModal.setTrue();
    }
  };
  const invokeCloseModal = (id: string) => {
    setLocation(null);
    showEnrolledtModal.setFalse();
  };
  const invokeDeleteAddressConfirmationModal = (id: string) => {
    setAddressVariantId(id);
    showDeleteReceiptModal.setTrue();
  };

  if (tykchaseLocationsLoading && businessProfileLoading) {
    return <PageLoader />;
  }
  return (
    <>
      <Grid container direction="column" spacing={2} className={mainContainer}>
        <TableContainer component={Paper}>
          <Table className={border} sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead className={color}>
              <TableRow>
                <TableCell align="left">
                  <FormattedMessage id="locations.country_city" />
                </TableCell>
                <TableCell align="left">
                  <FormattedMessage id="locations.street_address" />
                </TableCell>
                <TableCell sortDirection="asc" align="right">
                  <TableSortLabel
                    active={orderBy === BillingOrderByFieldEnum.IS_ENABLED}
                    direction={orderBy === BillingOrderByFieldEnum.IS_ENABLED ? order : BillingOrderEnum.ASC}
                    onClick={createSortHandler(BillingOrderByFieldEnum.IS_ENABLED)}
                  >
                    <FormattedMessage id="locations.state" />
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right">
                  <FormattedMessage id="locations.actions" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {visibleRows.map((address) => {
                return (
                  <>
                    <TableRow key={address.homeCity}>
                      <TableCell component="th" scope="row">
                        {address.homeCity}
                      </TableCell>
                      <TableCell align="left">
                        {address.streetAddress1.concat(" ", address.streetAddress2, ", ", address.zipCode)}
                      </TableCell>
                      <TableCell align="right">
                        <Typography
                          onClick={() => invokeEnrolledModal(address?.id)}
                          className={!address?.tykchaseLocation?.isEnabled ? enrolledState : undefined}
                          color={address?.tykchaseLocation?.isEnabled ? "green" : "orange"}
                        >
                          <FormattedMessage
                            id={address?.tykchaseLocation?.isEnabled ? "locations.enrolled" : "locations.not_enrolled"}
                          />
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        {!!address?.tykchaseLocation?.tykchaseLocationReceiptVariants?.length && (
                          <IconButton onClick={() => setVisible(address.id)} aria-label="Look">
                            <Icon.EyeIcon />
                          </IconButton>
                        )}
                        <IconButton
                          onClick={() => {
                            handleBusinessAddressClick(address?.id as string);
                          }}
                          aria-label="download"
                        >
                          <DownloadIcon width={16} height={15} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    <Grid lg={12}>
                      {!!address?.tykchaseLocation?.tykchaseLocationReceiptVariants?.length &&
                        isVisibleArray.includes(address.id) && (
                          <Modal
                            open={open}
                            onClose={() => handleClose(address.id)}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <BusinessReceiptsCards
                              locationReceiptVariants={address?.tykchaseLocation?.tykchaseLocationReceiptVariants}
                              onDelete={invokeDeleteAddressConfirmationModal}
                              onDownload={downloadFile}
                            />
                          </Modal>
                        )}
                    </Grid>
                  </>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={PAGINATION_OPTIONS}
                  count={visibleRows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={<FormattedMessage id="items_per_page" />}
                  showFirstButton
                  showLastButton
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Grid>
      <EnrolledModal refetch={refetch} location={location} open={showEnrolledtModal.value} onClose={invokeCloseModal} />
      <ReceiptUploadModal
        open={showUploadReceiptModal.value}
        onClose={showUploadReceiptModal.setFalse}
        onChange={setFile}
        onSubmit={handleFileSubmit}
        isLoading={uploading.value}
        fileName={file?.name}
      />
      <ReceiptRemoveModal
        open={showDeleteReceiptModal.value}
        onClose={showDeleteReceiptModal.setFalse}
        isLoading={deleting.value}
        onSubmit={handleDelete}
      />
      <SuccessModal id={user?.id ?? ""} isActive={isSuccessModalVisible} onClose={closeModal} />
    </>
  );
};
