import { FC, HTMLAttributes } from "react";
import clsx from "clsx";
import { useIntl } from "react-intl";
import { useStyles } from "./styles";
import { AppTooltips } from "../../types/tooltips";

export interface NoMembershipPlanNotificationProps extends HTMLAttributes<HTMLDivElement> {}

export const NoMembershipPlanNotification: FC<NoMembershipPlanNotificationProps> = () => {
  const styles = useStyles();
  const intl = useIntl();

  return <div className={clsx(styles.container)}>{AppTooltips(intl).NO_PLAN}</div>;
};
