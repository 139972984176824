import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Box, ButtonBase, CssBaseline, Grid, List, Toolbar, Avatar } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

import useStyles from "./styles";
import { menuItems } from "../../../utils/menuitems";
import { ReactComponent as Logo } from "../../../assets/icons/menuIcons/logoTykchaseYellow.svg";
import ModalContact from "./ModalContact";
import ItemNavigationBar from "./ItemNavigationBar";

import { useBusinessProfile } from "../../../hooks/useBusinessProfile.hook";

const drawerWidth = 300;

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const NavigationBar = (props: Props) => {
  const { appBar, drawerHeader, drawerHeaderDesktop, closeIcon, menu, contact } = useStyles(props);
  const { window } = props;
  const intl = useIntl();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [contactModalActive, setContactModalActive] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [businessProfile] = useBusinessProfile();
  const broker = businessProfile?.broker?.business;

  const container = window !== undefined ? () => window().document.body : undefined;

  const handleContactModal = () => {
    setContactModalActive(!contactModalActive);
  };

  const getBrokerName = (): string => {
     if (broker?.stageName){
      return broker.stageName;
    }
    if (broker?.firstname && broker?.lastname) {
      return `${broker.firstname} ${broker.lastname}`;
    }

    if (broker?.account?.firstname && broker?.account?.lastname) {
      return `${broker.account.firstname} ${broker.account.lastname}`;
    }

    return broker?.username as string;
  };

  const brokerInfo = broker && (
    <div className="wrap">
      <div className="left">
        <Avatar src={broker.profilePicture || undefined} alt="broker-avatar" className="avatar" />
        <h4>{getBrokerName()}</h4>
        <p>
          <FormattedMessage id="modals.contact_tykbroker.your_tykbroker" />
        </p>
      </div>
      {broker?.publicEmail || broker?.account?.email && (
        <ButtonBase onClick={handleContactModal}>
          <FormattedMessage id="btn_text_contact" />
        </ButtonBase>
      )}
    </div>
  );

  return (
    <>
      <ModalContact
        isOpen={contactModalActive}
        handleClose={handleContactModal}
        businessData={{
          email: businessProfile?.email,
          phoneNumber: businessProfile?.phonenumber,
        }}
        brokerEmail={broker?.publicEmail || broker?.account?.email}
      />

      <Box sx={{ display: "flex", zIndex: "2" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: { sm: 0 },
            ml: { sm: 0 },
          }}
          className={appBar}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
            }}
          >
            <Grid container className={drawerHeader}>
              <Logo height={140} viewBox="-5 0 220 80" />
              <IconButton
                color="inherit"
                aria-label="close drawer"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, width: "20px", height: "20px" }}
              >
                <CloseIcon className={closeIcon} />
              </IconButton>
            </Grid>
            <List className={menu}>
              {menuItems(intl).map((item) => (
                <ItemNavigationBar key={item.text} navigationItem={item} />
              ))}
            </List>
            <div className={contact}>
              {brokerInfo}

              <p className="privacy">
                <FormattedMessage id="company.name" />
              </p>
            </div>
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
            }}
            open
          >
            <Grid container className={drawerHeaderDesktop}>
              <Logo height={140} viewBox="0 0 220 80" />
            </Grid>
            <List className={menu}>
              {menuItems(intl).map((item) => (
                <ItemNavigationBar key={item.text} navigationItem={item} />
              ))}
            </List>
            <div className={contact}>
              {brokerInfo}

              <p className="privacy">
                <FormattedMessage id="company.name" />
              </p>
            </div>
          </Drawer>
        </Box>
      </Box>
    </>
  );
};

export default NavigationBar;
