import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    detailsContainer: {
      width: "760px",

      [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
        maxWidth: "100%",
      },

      "& .modalBody": {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        marginBottom: "20px",

        "& .selectWrap": {
          marginLeft: "24px",
          "& .MuiFormControl-root": {
            "& .MuiFormHelperText-root": {
              fontFamily: theme.typography.fontFamily,
            },

            "& .MuiInputBase-root": {
              height: "32px",
              width: "120px",

              "& .MuiSelect-select": {
                fontFamily: theme.typography.fontFamily,
                fontSize: "0.75rem",
              },
            },
          },
        },

        "& .chartWrap": {
          maxWidth: "560px",
          height: "280px",
          [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
            overflowX: "auto",
            whiteSpace: "nowrap",
          },
        },
      },
    },

    datePickerWrapper: {
      display: "flex",
      flexDirection: "column",

      "& .MuiInputLabel-root": {
        paddingBottom: "4px",
        fontSize: "0.75rem",
        color: "#747474",
      },

      "& .MuiInputBase-root": {
        width: "120px",
        height: "32px",
        border: "1px solid #D3D3D3",
        fontFamily: theme.typography.fontFamily,
        fontSize: "0.875rem",
        color: "#AAA9A9",

        "&:hover": {
          border: `1px solid ${theme.palette.primary.dark}`,
        },
      },

      "& .MuiInputBase-input": {
        padding: "4px 8px 4px 10px",
        color: theme.palette.analytics?.grey,
      },

      "& .Mui-focused": {
        border: `1px solid ${theme.palette.primary.dark}`,
      },

      "& fieldset": {
        padding: 0,
        border: "none",
      },
    },

    modalHeader: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-end",
      justifyContent: "space-between",
      width: "100%",
      marginBottom: "20px",

      [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
        flexDirection: "column",
        alignItems: "flex-start",
      },

      "& .modalHeaderWrap": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",

        [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
          alignItems: "flex-start",
        },

        "& .previewWrap": {
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          width: "44px",
          height: "44px",
          marginRight: "10px",
          backgroundColor: "#F1F4F9",
          borderRadius: "50%",
          "& img": {
            width: "100%",
            height: "100%",
            borderRadius: "50%",
          },
        },
        "& .titleWrap": {
          display: "flex",
          flexDirection: "column",
          "& .subtitleWrap": {
            display: "flex",
            flexDirection: "row",

            [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
              flexDirection: "column",
            },
          },
        },
      },

      "& .exportButton": {
        fontSize: "0.75rem",
        color: "#4262FF",
        textTransform: "capitalize",
        textDecoration: "underline",
      },
    },

    totalsStatisticWrap: {
      "& .statisticWrap": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: "120px",
        height: "56px",
        padding: "8px",
        margin: "6px",
        backgroundColor: "#F0F0F0",
        borderRadius: "6px",

        "& .statistic": {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        },

        "& .smallImage": {
          height: "12px",
        },
      },

      "& .statisticDivider": {
        height: "16px",
        width: "1px",
        backgroundColor: "#707070",
        alignSelf: "center",
        margin: "0 16px",
      },
    },

    modalFooter: {
      display: "flex",
      flexDirection: "row",
      width: "80%",

      "& .gridItem": {
        display: "flex",
        flexDirection: "row",
        width: "100%",

        "& .gridItemImageWrap": {
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          width: "40px",
          height: "40px",
          marginRight: "10px",
          backgroundColor: "#F1F4F9",
          borderRadius: "50%",
        },
      },
    },
  };
});

export default useStyles;
