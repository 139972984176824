import { FC } from "react";
import { FormikProps } from "formik";
import { Alert, Box, Grid, OutlinedInput, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import useStyles from "../../styles";

import { StepsFormikValues } from "../../../../../../../types/campaigns";

type Props = {
  formik: FormikProps<StepsFormikValues>;
};

const SurpriseTrigger: FC<Props> = ({ formik }: Props) => {
  const theme = useTheme();
  const { wrap, fieldWrapper } = useStyles();

  return (
    <Grid
      className={wrap}
      container
      pl={{ md: "80px" }}
      pt={{ md: "80px" }}
      justifyContent={{ xs: "center", md: "start" }}
    >
      <Grid item mr="20px">
        <Typography
          fontSize="0.75rem"
          color={theme.palette.label?.grey}
          mb="5px"
          textAlign={{ xs: "center", md: "start" }}
        >
          <FormattedMessage id="modals.triggers.surprise_number" />
        </Typography>

        <OutlinedInput
          value={formik.values.surpriseNumber}
          onChange={formik.handleChange}
          placeholder="0"
          type="number"
          className="surpriseTrigger"
          name="surpriseNumber"
        />
      </Grid>

      <Grid item maxWidth="200px" mt="30px">
        <Typography color={theme.palette.diagram?.grey} fontSize="0.75rem">
          <FormattedMessage id="modals.triggers.select_the_minimum_amount" />
        </Typography>
      </Grid>

      <Grid item className={fieldWrapper} xs={12}>
        {formik.touched.surpriseNumber && formik.errors.surpriseNumber && (
          <Box width="50%" marginTop="6px">
            <Alert severity="error">{formik.errors.surpriseNumber}</Alert>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default SurpriseTrigger;
