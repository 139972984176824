import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    sponsorContainer: {
      width: "430px",
      [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
        maxWidth: "100%",
      },

      "& .modal-header": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "end",

        "& .modal-header__title": {
          fontSize: "1.1875rem",
          color: "#323232",
        },
      },
      "& .modal-divider": {
        height: "2px",
        marginTop: "16px",
        color: "#EAEAEA",
      },
      "& .modal-body": {
        padding: "16px 0 24px 0",

        "& .MuiTypography-root": {
          fontSize: "0.9375rem",
          color: "#505050",
        },

        "& .extra": {
          paddingTop: "16px",
          fontWeight: 600,
        },
      },
      "& .modal-footer": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "end",

        "& .MuiButtonBase-root": {
          height: "40px",
          paddingLeft: "26px",
          paddingRight: "26px",
          fontSize: "1rem",
          fontWeight: 600,
          borderRadius: "20px",
          boxShadow: "none",
          textTransform: "none",
        },

        "& .MuiButtonBase-root.cancel-button": {
          marginRight: "16px",
          backgroundColor: "#C8000000",
          border: "2px solid #747474",
          color: "#747474",
          "&:hover": {
            backgroundColor: "#47474712",
          },
        },

        "& .MuiButtonBase-root.primary-button": {
          backgroundColor: "#1473E6",
          color: theme.palette.white,
          "&:hover": {
            backgroundColor: "#203ED4",
          },
        },
      },
    },
    addToLoneStarsSectionContainer: {
      width: "430px",
      [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
        maxWidth: "100%",
      },

      "& .modal-header": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "end",

        "& .modal-header__title": {
          fontSize: "1.1875rem",
          color: "#323232",
        },
      },

      "& .modal-divider": {
        height: "2px",
        marginTop: "16px",
        color: "#EAEAEA",
      },

      "& .modal-body": {
        padding: "16px 0 24px 0",

        "& .MuiTypography-root": {
          fontSize: "0.9375rem",
          color: "#505050",
        },
      },

      "& .modal-footer": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "end",

        "& .MuiButtonBase-root": {
          height: "40px",
          paddingLeft: "26px",
          paddingRight: "26px",
          fontSize: "1rem",
          fontWeight: 600,
          borderRadius: "20px",
          boxShadow: "none",
          textTransform: "none",
        },

        "& .MuiButtonBase-root.cancel-button": {
          marginRight: "16px",
          backgroundColor: "#C8000000",
          border: "2px solid #747474",
          color: "#747474",
          "&:hover": {
            backgroundColor: "#47474712",
          },
        },
        "& .MuiButtonBase-root.primary-button": {
          backgroundColor: "#1473E6",
          color: theme.palette.white,
          "&:hover": {
            backgroundColor: "#203ED4",
          },
        },
      },
    },
    detailsContainer: {
      width: "560px",
      [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
        maxWidth: "100%",
      },

      "& .modal-header": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "start",

        "& .avatar-circle": {
          width: "44px",
          height: "44px",
          marginRight: "18px",
          borderRadius: "50%",
          backgroundColor: theme.palette.experiences.lightPurple,
        },

        "& .modal-header__title__container": {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",

          "& .modal-header__title": {
            fontSize: "1.125rem",
            color: theme.palette.experiences.darkPurple,
          },

          "& .extra": {
            fontSize: "0.75rem",
            color: "#AAA9A9",
          },
        },
      },

      "& .modal-divider": {
        height: "1px",
        margin: "16px 0",
        color: theme.palette.experiences.lightGrey,
      },

      "& .modal-body": {
        width: "100%",
        padding: "16px 0 24px 0",

        "& .MuiTableCell-head": {
          fontFamily: theme.typography.fontFamily,
          fontSize: "0.75rem",
          padding: "10px",
          backgroundColor: "#E9E9E9",
          color: theme.palette.experiences.grey,
          lineHeight: "normal",

          "& .sponsor-column-title": {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",

            "& img": {
              height: "4px",
            },
          },
        },
        "& .MuiTableCell-body": {
          padding: "10px",
          border: "none",
          fontFamily: theme.typography.fontFamily,
          fontSize: "0.75rem",
          color: theme.palette.experiences.dark,
          lineHeight: "normal",
        },

        "& .experience-perks-container": {
          display: "flex",
          flexDirection: "column",
          marginTop: "20px",

          "& .experience-perks-title__wrapper": {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",

            "& .experience-perks-title": {
              fontSize: "0.875rem",
              color: theme.palette.experiences.darkPurple,
            },
          },

          "& .MuiDivider-root": {
            width: "100%",
            margin: "10px 0 18px",
            backgroundColor: theme.palette.experiences.lightGrey,
          },

          "& .perks-container-wrapper": {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",

            "& .perks-container": {
              marginRight: "20px",

              "& .perks-item": {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingBottom: "6px",

                "& .perks-item-value": {
                  fontSize: "0.75rem",
                  color: theme.palette.experiences.grey,
                  "& strong": {
                    padding: "0 3px",
                    fontWeight: 600,
                    color: theme.palette.experiences.darkPurple,
                  },
                },

                "& .perks-item-name": {
                  padding: "0 4px",
                  fontSize: "0.75rem",
                  color: theme.palette.experiences.grey,
                },
              },
            },

            "& .statistics-container": {
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              alignItems: "center",

              "& .statistics-item": {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                margin: "0 10px 10px",

                "& .circle": {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "80px",
                  height: "80px",
                  borderRadius: "50%",
                  border: "3px solid #34305D",

                  "& .statistics-item-value": {
                    fontSize: "1.125rem",
                    color: "#0364FF",
                  },

                  "& .logo-circle": {
                    width: "54px",
                    height: "54px",
                    borderRadius: "50%",
                    backgroundColor: theme.palette.experiences.lightGrey,
                  },
                },
                "& .circle.disabled": {
                  border: "3px solid #797882",
                },

                "& .statistics-item-title": {
                  marginTop: "9px",
                  fontSize: "0.6875rem",
                  color: theme.palette.experiences.grey,
                },

                "& .statistics-item-subtitle": {
                  fontSize: "0.6875rem",
                  color: theme.palette.experiences.darkPurple,
                },
              },
            },
          },
        },
      },
      "& .modal-footer": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "end",

        "& .MuiButtonBase-root": {
          height: "40px",
          padding: "0 32px",
          fontSize: "0.875rem",
          borderRadius: "20px",
          boxShadow: "none",
          textTransform: "none",
          backgroundColor: "#1473E6",
          color: theme.palette.white,
          "&:hover": {
            backgroundColor: "#203ED4",
          },
        },
      },
    },

    detailsButton: {
      color: theme.palette.experiences.blue,
      fontFamily: theme.typography.fontFamily,
      fontSize: "0.625rem",
      textDecoration: "underline",
      cursor: "pointer",
    },
  };
});

export default useStyles;
