import { AppBar, Toolbar, Box } from "@mui/material";
import { useUnifiedLogin, WaffleButton } from "@stagewood/unified-login-library";
import useStyles from "./styles";
import ProfileButton from "../../ProfileButton";
import { ReactComponent as Logo } from "../../../assets/icons/menuIcons/logoTykchaseYellow-BlackLetters.svg";

const HeaderAlternative = (props) => {
  const { leftBox, rightBox, appBar, toolbarHeight, logoStyle } = useStyles(props);
  return (
    <AppBar position="fixed" sx={{ backgroundColor: "white" }} className={appBar}>
      <Toolbar className={toolbarHeight}>
        <Box component="div" className={leftBox}>
          <Logo className={logoStyle} />
        </Box>
        <Box component="div" className={rightBox}>
          <WaffleButton />
          <ProfileButton />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default HeaderAlternative;
