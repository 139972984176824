import { useQuery } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import clsx from "clsx";
import { FC, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { CartStatus } from "../../gql/graphql";
import { PageLoader } from "../PageLoader";
import { CheckoutContent } from "./CheckoutContent";
import { getCartQuery } from "./graphql/GetCart.query";
import { useStyles } from "./styles";

interface CheckoutProps {
  cartId: string;
  successPageUrl?: string;
}

export const Checkout: FC<CheckoutProps> = ({ cartId, successPageUrl = "/" }) => {
  const styles = useStyles();
  const { data, loading, error, refetch } = useQuery(getCartQuery, {
    variables: { cartId },
    notifyOnNetworkStatusChange: true,
    onCompleted: (responseData) => {
      if (responseData?.getCart?.status === CartStatus.Draft) {
        refetch();
      }
    },
  });
  const cart = data?.getCart;

  if (loading || cart?.status === CartStatus.Draft) {
    return <PageLoader />;
  }

  if (cart?.status !== CartStatus.Created) {
    return <Redirect to={successPageUrl} />;
  }

  if (error) {
    return null;
  }

  return (
    <div className={styles.container}>
      <CheckoutContent cart={cart} successPageUrl={successPageUrl} />
    </div>
  );
};
