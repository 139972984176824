import { makeStyles } from "@mui/styles";
import theme from "../../../theme";
import { Memberships } from "../../../types/membershipTypes";

type Props = {
  internalId: string | number | undefined;
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    width: 290,
    flexDirection: "column",
  },
  padding: {
    dispay: "flex",
    minHeight: 520,
    paddingLeft: 10,
    paddingRight: 10,
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "10px",
    alignContent: "center",
    gap: 40,
  },
  contentContainer: {
    paddingTop: "50px",
    flexDirection: "column",
    alignItems: "center",
    alignContent: "center",
  },
  innerContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    position: "relative",
    "& .MuiGrid-root": {
      flexWrap: "nowrap",
    },
  },
  backgroundSmall: {
    position: "absolute",
    width: "90%",
    height: "70%",
    background: "#F0F0F0",
    opacity: 0.1,
    borderRadius: 5,
  },
  marginBrands:{
    marginTop:'10px',
  },
  backgroundSmallCentral: {
    position: "absolute",
    width: "80%",
    height: "74.2%",
    background: "#F0F0F0",
    opacity: 0.1,
    borderRadius: 5,
  },
  backgroundNormal: {
    position: "absolute",
    width: "110%",
    height: "100%",
    background: "#F0F0F0",
    opacity: 0.1,
    borderRadius: 5,
  },
  locationContainer: {
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    alignContent: "center",
  },
  priceContainer: {
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    alignContent: "center",
  },
  buttonContainer: {
    marginTop: "auto",
    backgroundColor: theme.palette.white,
    minHeight: 100,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  background: {
    background: (props: Props) => {
      switch (props.internalId) {
        case 1:
          return theme.palette?.membership?.business;
        case 2:
          return theme.palette?.membership?.brands;
        case 3:
          return theme.palette?.membership?.enterprise;
        default:
          return "#000";
      }
    },
  },
  btnArea: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },

  main: {
    fontSize: 28,
    color: theme.palette.white,
  },
  mainBold: {
    fontSize: 28,
    color: theme.palette.white,
    fontWeight: "bold",
  },
  secondary: {
    fontSize: 14,
    color: theme.palette.white,
  },
  secondaryBold: {
    fontSize: 14,
    color: theme.palette.white,
    fontWeight: "bold",
  },
});

export { useStyles };
