import { Font } from "@react-pdf/renderer";

import AdobeCleanFont from "../../../../assets/fonts/AdobeClean/_ttf/AdobeClean-Regular.ttf";
import AdobeCleanBoldFont from "../../../../assets/fonts/AdobeClean/_ttf/AdobeClean-Bold.ttf";
import AdobeCleanItalicFont from "../../../../assets/fonts/AdobeClean/_ttf/AdobeClean-It.ttf";
import AdobeCleanBoldItalicFont from "../../../../assets/fonts/AdobeClean/_ttf/AdobeClean-BoldIt.ttf";

export function registerReceiptDocumentFonts() {
  Font.register({
    family: "AdobeClean",
    fonts: [
      {
        src: AdobeCleanFont,
      },
      {
        src: AdobeCleanBoldFont,
        fontWeight: "bold",
      },
      {
        src: AdobeCleanItalicFont,
        fontWeight: "normal",
        fontStyle: "italic",
      },
      {
        src: AdobeCleanBoldItalicFont,
        fontWeight: "bold",
        fontStyle: "italic",
      },
    ],
  });
}
