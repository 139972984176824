import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import clsx from "clsx";
import Icon from "../../../utils/icon";
import useStyles from "./styles";
import WinnersHistory from "../WinnersHistory";

const Sidebar = ({ active }: { active: boolean }) => {
  const theme = useTheme();
  const { hideContent } = useStyles();
  return (
    <Grid borderRadius="6px" overflow="hidden" bgcolor={theme.palette.ruleToggle.lightBlue}>
      <Grid p="20px 32px" borderRadius="6px" bgcolor={theme.palette.ruleToggle.blue}>
        <Grid container flexWrap="nowrap" justifyContent="space-between" mb="32px">
          <Grid
            container
            flexDirection="column"
            alignItems="center"
            maxWidth="max-content"
            mr="14px"
          >
            <Icon.Jackpot maxWidth="73px" minWidth="41px" />

            <Typography
              alignSelf="start"
              mt="10px"
              fontSize="0.875rem"
              color={active ? theme.palette.analytics?.lightGreen : theme.palette.analytics?.red}
            >
              {active ? "Active" : "Inactive"}
            </Typography>
          </Grid>
          <Grid maxWidth="132px" width="100%">
            <Grid container flexWrap="nowrap" alignItems="end">
              <Icon.CentorsFull />
              <Typography
                height="max-content"
                ml="15px"
                fontSize="0.875rem"
                color={theme.palette.primary.dark}
                fontWeight="bold"
              >
                {active ? "2,000" : 0}
              </Typography>
            </Grid>
            <Grid container flexWrap="nowrap" alignItems="end" mb="10px">
              <Icon.LoneStars />
              <Typography
                height="max-content"
                ml="15px"
                fontSize="0.875rem"
                color={theme.palette.primary.dark}
                fontWeight="bold"
              >
                {active ? 550 : 0}
              </Typography>
            </Grid>
            <Typography fontSize="0.875rem" color={theme.palette.diagram?.purple}>
              {active ? "accumulated" : "No tyks accumulated"}
            </Typography>
          </Grid>
        </Grid>

        <WinnersHistory isActive={active} />
      </Grid>

      <Grid
        className={clsx(hideContent, {
          active,
        })}
        maxHeight={active ? "500px" : "0px"}
      >
        <Grid p="20px 32px" borderBottom={`1px solid ${theme.palette.analytics?.lightBlue}`}>
          <Typography
            mb="20px"
            fontWeight="bold"
            fontSize="0.875rem"
            color={theme.palette.primary.dark}
          >
            Jackpot settings
          </Typography>

          <Typography mb="4px" fontSize="0.875rem" color={theme.palette.analytics?.grey}>
            Audience
          </Typography>

          <Typography
            mb="16px"
            fontSize="0.875rem"
            fontWeight="bold"
            color={theme.palette.primary.dark}
          >
            General
          </Typography>

          <Typography mb="4px" fontSize="0.875rem" color={theme.palette.analytics?.grey}>
            Chances
          </Typography>

          <Typography
            mb="14px"
            fontSize="0.875rem"
            fontWeight="bold"
            color={theme.palette.primary.dark}
          >
            2
          </Typography>

          <Typography mb="4px" fontSize="0.875rem" color={theme.palette.analytics?.grey}>
            Release
          </Typography>

          <Grid container>
            <Typography
              mr="4px"
              fontSize="0.875rem"
              fontWeight="bold"
              color={theme.palette.primary.dark}
            >
              Amount =&gt;
            </Typography>
            <Typography
              fontSize="0.875rem"
              fontWeight="bold"
              color={theme.palette.analytics?.lightGreen}
            >
              395
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid p="20px 32px">
        <Typography mb="4px" fontSize="0.875rem" color={theme.palette.analytics?.grey}>
          Last Release:
        </Typography>

        <Typography
          mb="16px"
          fontSize="0.875rem"
          fontWeight="bold"
          color={theme.palette.primary.dark}
        >
          {active ? "11.36.36 - 250" : "never released"}
        </Typography>

        <Typography mb="4px" fontSize="0.875rem" color={theme.palette.analytics?.grey}>
          Total Jackpot Released:
        </Typography>

        <Typography
          mb="16px"
          fontSize="0.875rem"
          fontWeight="bold"
          color={theme.palette.primary.dark}
        >
          {active ? "1,540" : "0"}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Sidebar;
