import { ComponentType, ComponentPropsWithRef } from "react";
import { BillingPaymentsTabs } from "../BillingPaymentsTabs";
import { useBusinessProfile } from "../../../hooks/useBusinessProfile.hook";
import { NoMembershipPlan } from "../../Layout/NoMembershipPlan";

const WithTabsBillingPaymentsLayout = <T extends {}>(Component: ComponentType<T>) => {
  const [, , isSubscribed] = useBusinessProfile();

  return function wrappedWithTabsBillingPaymentsLayout(props: ComponentPropsWithRef<any>) {
    return (
      <>
        <NoMembershipPlan isSubscribed={isSubscribed}>
          <BillingPaymentsTabs />
          <Component isSubscribed={isSubscribed} {...props} />
        </NoMembershipPlan>
      </>
    );
  };
};

export default WithTabsBillingPaymentsLayout;
