import { graphql } from "../../../gql";

export const GetWeeklyAverageScannedReceiptsInfoQuery = graphql(`
  query GetWeeklyAverageScannedReceiptsInfo($userId: String!) {
    getWeeklyAvgScannedReceiptInfo(userId: $userId) {
      status
      message
      errorMessageId
      payload {
        total
        weekly
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
      }
    }
  }
`);
