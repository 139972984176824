import { SVGProps } from "react";

export function IconAwesomeRobot(props: Pick<SVGProps<SVGSVGElement>, "width" | "height" | "fill">): JSX.Element {
  const defaultIconWidth = 34.35;
  const defaultIconHeight = 27.48;
  const defaultIconFill = "#74b369";
  const iconWidth = props.width ?? defaultIconWidth;
  const iconHeight = props.height ?? defaultIconHeight;
  const iconFill = props.fill ?? defaultIconFill;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={iconWidth}
      height={iconHeight}
      viewBox={`0 0 ${defaultIconWidth} ${defaultIconHeight}`}
    >
      <path
        data-name="icon-awesome-robot"
        d="M0,13.74v6.87a1.716,1.716,0,0,0,1.717,1.717H3.435v-10.3H1.717A1.716,1.716,0,0,0,0,13.74ZM24.9,5.152H18.892V1.717a1.717,1.717,0,0,0-3.435,0V5.152H9.446A4.293,4.293,0,0,0,5.152,9.446v14.6A3.438,3.438,0,0,0,8.587,27.48H25.762A3.438,3.438,0,0,0,29.2,24.045V9.446A4.293,4.293,0,0,0,24.9,5.152ZM13.74,22.327H10.3V20.61H13.74Zm-1.717-6.441a2.147,2.147,0,1,1,2.147-2.147A2.146,2.146,0,0,1,12.022,15.887Zm6.87,6.441H15.457V20.61h3.435Zm5.152,0H20.61V20.61h3.435Zm-1.717-6.441a2.147,2.147,0,1,1,2.147-2.147A2.146,2.146,0,0,1,22.327,15.887Zm10.3-3.864H30.915v10.3h1.717A1.716,1.716,0,0,0,34.35,20.61V13.74A1.716,1.716,0,0,0,32.632,12.022Z"
        fill={iconFill}
      />
    </svg>
  );
}
