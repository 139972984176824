import { FC, useImperativeHandle, RefObject } from "react";

import { FormikProps } from "formik";
import { useTheme } from "@mui/styles";
import { FormControl, Grid, MenuItem, Select, Typography } from "@mui/material";

import { FormattedMessage } from "react-intl";
import { Diagram } from "../../../../Modals/Diagram";

import useStyles from "../styles";
import { PurchaseRewardRate } from "../../../../../../generated/types";
import { StepsFormikValues } from "../../../../../../types/campaigns";
import { ITykchaseGlobalConfig } from "../../../../../../types/tykchaseGlobalConfig";

type Props = {
  rewardRef: RefObject<FormikProps<StepsFormikValues>>;
  tykchaseGlobalConfig?: ITykchaseGlobalConfig;
  formik: FormikProps<StepsFormikValues>;
  setPurchaseRewardState?: any;
};

const RewardStep: FC<Props> = ({ rewardRef, tykchaseGlobalConfig, formik, setPurchaseRewardState }: Props) => {
  const { wrap } = useStyles();
  const theme = useTheme();
  const { Fixed, ByPercentage } = PurchaseRewardRate;

  const isPercentage = formik.values.purchaseRewardRate === ByPercentage;

  useImperativeHandle(rewardRef, () => ({
    ...formik,
  }));

  const handlerPercentage = (e) => {
    const purchaseRewardRate = e.target.value;
    formik.setFieldValue("purchaseRewardRate", purchaseRewardRate);
  };

  return (
    <Grid container className={wrap} mt="50px">
      <Grid container className="rewardsSettings">
        <FormControl>
          <Typography mb="-4px" fontSize="0.75rem" color={theme.palette.label?.grey}>
            <FormattedMessage id="modals.reward_step.distribute_rewards" />
          </Typography>

          <Select
            displayEmpty
            className="selectedRewards"
            defaultValue=""
            onChange={handlerPercentage}
            value={formik.values.purchaseRewardRate || ""}
          >
            <MenuItem disabled value="">
              <em>
                <FormattedMessage id="modals.reward_step.select_type" />
              </em>
            </MenuItem>

            <MenuItem value={Fixed}>
              <FormattedMessage id="modals.reward_step.fixed_amount" />
            </MenuItem>

            <MenuItem value={ByPercentage}>
              <FormattedMessage id="modals.reward_step.purchase_percentage" />
            </MenuItem>
          </Select>
        </FormControl>

        {formik.values.purchaseRewardRate && (
          <Grid container justifyContent={{ xs: "center", md: "start" }}>
            <Diagram
              formik={formik}
              isPercentage={isPercentage}
              setInputData={() => {}}
              margin={{ xs: "40px 20px 0 20px", sm: "180px auto 0" }}
              width="max-content"
              tykchaseGlobalConfig={tykchaseGlobalConfig}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default RewardStep;
