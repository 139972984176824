import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      width: "100%",
      marginTop: "77px",
      marginBottom: "45px",

      "& input:focus": {
        "&::placeholder": {
          color: "transparent !important",
        },
      },
      "& .MuiInputBase-root": {
        "& .MuiInputBase-input": {
          color: theme.palette.diagram?.purple,
        },
      },
      "& .subContainer": {
        display: "flex",
        justifyContent: "center",
        alignItems: "end",
        width: "max-content",
        margin: "12px auto 0",

        "&.notAvailable": {
          color: `${theme.palette.ruleToggle.lightRed} !important`,
          "& p, & #information_icon, & .MuiInputBase-root, & .MuiInputBase-input::placeholder": {
            color: `${theme.palette.ruleToggle.lightRed} !important`,
            fill: `${theme.palette.ruleToggle.lightRed} !important`,
            borderColor: `${theme.palette.ruleToggle.lightRed} !important`,
          },
        },

        "& img": {
          height: "33px",
          width: "33px",
          margin: "0 9px",

          [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
            height: "30px",
            width: "30px",
          },
        },

        "& .MuiInputBase-root": {
          height: "30px",
          width: "59px",
          marginLeft: "10px",
          padding: "3px 5px",
          fontSize: "18px",
          border: `1px solid ${theme.palette.diagram?.grey}`,
          borderRadius: "6px",

          [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
            height: "24px",
            width: "45px",
            fontSize: "14px",
          },
          "& .MuiInputBase-input": {
            textAlign: "end",
            "&::placeholder": {
              textAlign: "end",
              opacity: "1",
              fontWeight: "bold",
              color: theme.palette.diagram?.dark,
            },

            "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
              WebkitAppearance: "none",
              margin: "0",
            },

            "&[type=number]": {
              MozAppearance: "textfield",
            },
          },
        },
      },
    },
    diagramWrap: {
      display: "flex",
      alignItems: "center",
      width: "100%",

      [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
        flexDirection: "column",
        margin: "30px 0",
      },
    },

    item: {
      position: "relative",
      height: "97px",
      width: "132px",
      padding: "8px 15px",
      marginRight: "14px",
      borderRadius: "3px",
      border: `1px solid ${theme.palette.diagram?.grey}`,

      [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
        height: "77px",
        width: "100px",
        padding: "6px 8px",
        marginRight: "8px",
      },

      [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
        height: "70px",
        width: "86px",
      },

      [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
        height: "100px",
        width: "152px",
        marginRight: "0",
        marginBottom: "15px",
      },

      "& input:focus": {
        "&::placeholder": {
          color: "transparent !important",
        },
      },

      "&.notAvailable": {
        borderColor: `${theme.palette.ruleToggle.lightRed} !important`,

        "& p, & #information_icon, & .MuiInputBase-root, & .MuiInputBase-input::placeholder": {
          color: `${theme.palette.ruleToggle.lightRed} !important`,
          fill: `${theme.palette.ruleToggle.lightRed} !important`,
          borderBottomColor: `${theme.palette.ruleToggle.lightRed} !important`,
        },

        "& .MuiSwitch-thumb": {
          background: `${theme.palette.ruleToggle.lightRed} !important`,
        },
      },

      "&.first, &.splitItem.last, &.last": {
        marginRight: "0",
        marginBottom: "0",
      },

      "& div": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      },

      "& .itemTop": {
        height: "17px",

        "& .MuiSwitch-root": {
          width: "47px",
          right: "-12px",
          "& .MuiSwitch-switchBase": {
            top: "3px",
            "& .MuiSwitch-thumb": {
              height: "11px",
              width: "11px",
              backgroundColor: theme.palette.diagram?.purple,
            },
          },

          "& .MuiSwitch-track": {
            height: "11px",
            width: "22px",
            backgroundColor: theme.palette.diagram?.grey,
          },

          "& .Mui-checked": {
            transform: "translateX(14px)",
          },
        },
      },

      "& .itemMiddle": {
        height: "32px",
        marginTop: "8px",
        marginBottom: "5px",
        [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
          height: "28px",
          margin: "0 0 2px",
        },
        [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
          height: "28px",
        },
        [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
          height: "34px",
          marginTop: "6px",
        },
        "& .MuiInputBase-root": {
          height: "32px",
          width: "50px",
          marginRight: "4px",
          fontSize: "1",
          borderBottom: `1px solid ${theme.palette.diagram?.purple} `,
          "&::after": {
            content: "'%'",
            color: theme.palette.diagram?.purple,
            fontWeight: "bold",
            fontFamily: theme.typography.fontFamily,
            fontSize: "1.125rem",
          },
          [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
            height: "28px",
          },
          [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
            height: "25px",
          },
          [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
            height: "34px",
          },
          "& .MuiInputBase-input": {
            fontSize: "18px",
            fontFamily: theme.typography.fontFamily,
            textAlign: "center",
            color: theme.palette.diagram?.purple,
            fontWeight: "bold",
            paddingRight: "5px",
            [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
              fontSize: "15px",
            },
            [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
              fontSize: "14px",
            },

            [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
              fontSize: "18px",
            },

            "&::placeholder": {
              color: theme.palette.diagram?.purple,
              fontFamily: theme.typography.fontFamily,
              fontWeight: "bold",
              opacity: "1",
              textAlign: "center",
            },

            "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
              WebkitAppearance: "none",
              margin: "0",
            },

            "&[type=number]": {
              MozAppearance: "textfield",
            },
          },
        },

        "& .rewardsIcon": {
          [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
            height: "30px",
            width: "26px",
          },
          [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
            height: "46px",
            width: "39px",
          },
        },

        "& img": {
          height: "33px",
          width: "33px",
          marginBottom: "5px",

          [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
            height: "22px",
            width: "24px",
          },
          [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
            height: "33px",
            width: "33px",
          },
        },
      },

      "& .itemBottom": {
        "& p": {
          maxWidth: "41px",
          fontSize: "9px",
          color: theme.palette.diagram?.purple,
          [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
            lineHeight: "11px",
          },
          [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
            fontSize: "11px",
            lineHeight: "normal",
          },
        },
      },

      "&.defaultItem.first": {
        background: theme.palette.diagram?.white,
        border: "0",
        opacity: "1",
        "& .MuiInputBase-root": {
          "&::after": {
            color: theme.palette.diagram?.blue,
          },
          "& .MuiInputBase-input": {
            color: theme.palette.diagram?.blue,
          },
        },

        "& .itemTop": {
          [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
            height: "12px",
          },
        },
        "& .itemMiddle": {
          marginTop: "0",
          "& .MuiInputBase-root": {
            "& .MuiInputBase-input": {
              "&::placeholder": {
                color: `${theme.palette.diagram?.blue}`,
                textAlign: "start",
              },
            },
          },
          "& .rewardsIcon": {
            [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
              height: "30px",
              width: "22px",
            },

            [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
              height: "46px",
              width: "35px",
            },
          },
        },
      },

      "&.isPercentage:not(.first)": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",

        "& .MuiInputBase-root": {
          display: "none",
        },
        "& img": {
          marginBottom: "10px",
        },
      },

      "&.isFlipProcentageItem": {
        display: "flex",
        flexDirection: "column",

        "& .itemMiddle": {
          justifyContent: "center",
          marginTop: "8px !important",
        },

        "& .MuiInputBase-root": {
          display: "none",
        },
      },
    },

    equivalent: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "12px",
      width: "13px",
      margin: "0 18px",
      [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
        margin: "0 14px",
      },
      [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
        margin: "0 10px",
      },
      [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
        margin: "15px 0",
      },
      "& span": {
        height: "2px",
        width: "100%",
        background: theme.palette.primary.dark,
      },
    },

    split: {
      position: "relative",
      marginRight: "14px",
      [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
        marginRight: "0",
        marginBottom: "15px",
      },
      "& .splitTip": {
        display: "flex",
        alignItems: "center",
        position: "absolute",
        top: "-44%",
        left: "50%",
        transform: "translateX(-50%)",
        color: theme.palette.analytics?.grey,
        fontSize: "12px",
        [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
          top: "0",
          left: "131%",
        },
        "& p": {
          [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
            whiteSpace: "nowrap",
          },
          [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
            fontSize: "11px",
          },
          [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
            fontSize: "9px",
          },
        },
      },

      "& .splitTop": {
        position: "absolute",
        top: "-22%",
        left: "50%",
        transform: "translateX(-50%)",
        display: "flex",
        justifyContent: "space-between",
        height: "23%",
        width: "70%",
        opacity: "0.8",

        [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
          top: "50%",
          left: "124%",
          transform: "translate(-50%, -50%)",
          flexDirection: "column",
          height: "84%",
          width: "50%",
        },
        "& div": {
          display: "flex",
          justifyContent: "center",
          paddingTop: "11px",
          color: theme.palette.diagram?.purple,
          border: `0.5px solid ${theme.palette.diagram?.grey}`,
          borderBottom: "0",
          borderRadius: "3px",
          fontSize: "10px",
          [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
            paddingTop: "7px",
          },
          [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
            paddingTop: "5px",
          },
          [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
            padding: "0",
            border: `0.5px solid ${theme.palette.diagram?.purple}`,
            borderLeft: "none",
          },
        },
        "& .splitTopParent": {
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          height: "100%",
          width: "80%",
          fontSize: "12px",
          color: theme.palette.diagram?.blue,
          [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
            width: "86%",
          },
          [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            top: "50%",
            left: "25%",
            transform: "translate(-50%, -50%)",
            height: "91%",
            width: "36px",
          },

          "&.inside": {
            width: "30%",
            borderRadius: "0",
            [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              top: "50%",
              transform: "translate(-50%, -50%)",
              height: "31%",
              width: "36px",
            },
          },
        },
      },

      "& .splitMidle": {
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        zIndex: "10",
        [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
          flexDirection: "column",
        },
        "& .splitItem.first": {
          marginRight: "14px",
          background: "none",
          border: `1px solid ${theme.palette.diagram?.grey}`,
          [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
            marginRight: "8px",
          },
          [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
            marginRight: "0",
            marginBottom: "10px",
          },
          "& .itemMiddle": {
            marginTop: "10px",
          },
        },
      },
    },
  };
});

export default useStyles;
