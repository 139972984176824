import { ApplicationFeatures } from "../../../types/applicationFeaturesEnum";

const editFormulaFeatures = [
  ApplicationFeatures.TotalDistributionCashier,
  ApplicationFeatures.TotalDistributionCentors,
  ApplicationFeatures.TotalDistributionJackpot,
  ApplicationFeatures.TotalDistributionLoneStars,
  ApplicationFeatures.TotalDistributionMinLoneStars,
];

export const membershipFeatures = [
  {
    title: "Basic",
    features: [],
  },
  {
    title: "Gold",
    features: [],
  },
  {
    title: "Platinum",
    features: editFormulaFeatures,
  },
  {
    title: "Diamond",
    features: editFormulaFeatures,
  },
  {
    title: "Diamond Elite",
    features: editFormulaFeatures,
  },
  {
    title: "Brand Basic",
    features: [],
  },
  {
    title: "Brand Diamond",
    features: editFormulaFeatures,
  },
];
