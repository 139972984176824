import { Winner } from './types';

export const winners: Winner[] = [
  {
    id: '1',
    name: 'Ronald54863',
    date: 'Oct 9th, 2021',
    amount: {
      green: '2,000',
      yellow: '550',
    },
    attemps: 2,
    messages: [
      {
        id: '1',
        senderId: '1',
        text: 'Lorem ipsum dolor sit amet, consectetur ' +
          'adipiscing elit. Nunc ut mi sit amet nunc volutpat pulvinar. ' +
          'Aliquam vitae eros purus. Cras commodo quam sit amet odio.',
        date: 'Dec 1st, 2021',
      },
      {
        id: '2',
        senderId: 'self',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ' +
          'Nunc ut mi sit amet nunc volutpat pulvinar.',
        date: 'Dec. 25th, 2021',
      },
      {
        id: '3',
        senderId: '1',
        text: 'Thanks for the prize !!',
        date: 'Dec. 25th, 2021',
      },
    ],
  },
  {
    id: '2',
    name: 'Migue_ascanio987',
    date: 'Oct 9th, 2021',
    amount: {
      green: '5000',
      yellow: '150',
    },
    attemps: 1,
    messages: [
      {
        id: '1',
        senderId: '2',
        text: 'Thanks for the prize !!',
        date: 'Dec 1st, 2021',
      },
    ],
  },
  {
    id: '3',
    name: 'Santi_figueroa',
    date: 'Oct 9th, 2021',
    amount: {
      green: '300',
      yellow: '50',
    },
    attemps: 1,
  },
  {
    id: '4',
    name: 'Damian87846',
    date: 'Oct 9th, 2021',
    amount: {
      green: '5,000',
      yellow: '700',
    },
    attemps: 1,
  },
];
