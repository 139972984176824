import { defineMessages, IntlShape } from "react-intl";
import { PurchaseRewardRate } from "../../gql/graphql";

export enum RewardsFilterEnum {
  ALL_RULES = "All Rules",
  SOCIAL_INTERACTION_RULES = "Social Interaction Rules",
  IN_STORE_RULES = "In-Store Rules",
}
export enum RewardsFilterEnumSpanish {
  ALL_RULES = "Todas las reglas",
  SOCIAL_INTERACTION_RULES = "Reglas de interacción social",
  IN_STORE_RULES = "Reglas en la tienda",
}

export enum RewardInputsEnum {
  DOLLAR_REWARD = "dollarReward",
  REWARD_ON_PURCHASE_COUNT = "rewardOnPurchaseCount",
  MULTIPLE_REWARD_COUNT = "multipleRewardCount",
  MIN_PURCHASE_FOR_REWARD = "minPurchaseForReward",
  ALLOW_MULTIPLE_REWARD = "allowMultipleReward",
  PURCHASE_REWARD_RATE = "purchaseRewardRate",
}

export interface RewardInputs {
  dollarReward?: number | null;
  rewardOnPurchaseCount?: number | null;
  multipleRewardCount?: number | null;
  minPurchaseForReward?: number | null;
  allowMultipleReward?: boolean;
  purchaseRewardRate?: PurchaseRewardRate;
}

const messages = defineMessages({
  minValue: {
    id: "validation_error_min_value",
    defaultMessage: "Minimum value is",
  },
  maxValue: {
    id: "validation_error_max_value",
    defaultMessage: "Maximum value is",
  },
  onlyWholeNumbers: {
    id: "validation_error_only_whole_numbers",
    defaultMessage: "Only whole numbers are allowed",
  },
});

export const ValidationError = (intl: IntlShape) => {
  return {
    MIN_VALUE: intl.formatMessage(messages.minValue),
    MAX_VALUE: intl.formatMessage(messages.maxValue),
    ONLY_WHOLE_NUMBERS: intl.formatMessage(messages.onlyWholeNumbers),
  };
};
