import { Grid, Typography, Button } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Hands from "../../assets/images/dashboard/Hands - Pinches.png";
import useStyles from "./styles";

const Welcome = () => {
  const theme = useTheme();
  const { btn } = useStyles();
  const history = useHistory();

  const pushToMemberships = () => {
    history.push("/Memberships");
  };

  return (
    <Grid container width="75%" p="2rem 1rem">
      <Grid width={{ xs: "100%", md: "50%" }}>
        <img width="100%" src={Hands} alt="hands" />
      </Grid>

      <Grid width={{ xs: "100%", md: "50%" }} color={theme.palette.diagram?.purple}>
        <Typography mb="1rem" color={theme.palette.primary.dark} fontWeight="bold" fontSize="1.375rem">
          <FormattedMessage id="dashboard.welcome.title" />
        </Typography>

        <Typography mb="1.3rem">
          <FormattedMessage id="dashboard.welcome.you_are_only_one_step" />
        </Typography>

        <Typography mb="1.6rem">
          <FormattedMessage id="dashboard.welcome.it_easier_than_you_think" />
        </Typography>

        <Button className={btn} onClick={pushToMemberships}>
          <FormattedMessage id="dashboard.welcome.enroll" />
        </Button>
      </Grid>
    </Grid>
  );
};

export default Welcome;
