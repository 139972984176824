import { FC, useState } from "react";
import { FormattedMessage } from "react-intl";
import { GetCartQuery } from "../../../gql/graphql";
import { ButtonsStyle } from "../../Buttons/styles";
import { Cart } from "../../Cart";
import { LoadingButton } from "../../LoadingButton/loading-button.component";
import { PaymentMethods } from "../PaymentMethods";
import { useStyles } from "./styles";
import { useCheckoutHandlers } from "./useCheckoutHandler.hook";
import { useLineItems } from "./useLineItems.hook";
import { NEW_CARD_TOKEN } from "../PaymentMethods/PaymentMethods.consts";
import { useCardFormValidation } from "../PaymentMethods/CardForm/hooks/card-form-validation.hook";
import { useAddressCreationForm } from "../../AddressSelectionModal/AddressCreationForm/useAddressCreationForm.hook";

  const defaultValues = {
    addressCity: "",
    addressStreet1: "",
    addressStreet2: "",
    label: "",
    zipCode: "",
    isDefault: false,
    shortCountryCode:"",
    regionName:'',
    };

interface CheckoutContentProps {
  cart: NonNullable<GetCartQuery["getCart"]>;
  successPageUrl?: string;
}

export const CheckoutContent: FC<CheckoutContentProps> = ({ cart, successPageUrl }) => {
  const styles = useStyles();
  const [cardToken, setCardToken] = useState<string | null>(null);
  const [cardholderName, setCardholderName] = useState<string | null>(null);
  const [{ values, isValid, addressCreationProps }, submit] = useAddressCreationForm(defaultValues, true);
  const { handleCheckout, loading } = useCheckoutHandlers(
    cart,
    cardToken,
    cardholderName,
    {
      city: values.addressCity.split(",")[0],
      state: values.regionName ? values.regionName : null,
      country: values.shortCountryCode ? values.shortCountryCode?.toUpperCase() : null,
      line1: values.addressStreet1,
      line2: values.addressStreet2 || null,
      postal_code: values.zipCode,
    },
    successPageUrl,
  );
  const lineItems = useLineItems(cart);

  const { cardInputsMessageStatus, setCardInputsMessageStatus, allFieldsComplete, emptyNonNullValues } =
    useCardFormValidation();

  const handleCheckoutClick =  async () => {
    if (cardToken === NEW_CARD_TOKEN) {
      await submit();
      if (allFieldsComplete && isValid) {
        handleCheckout();

        return;
      }

      emptyNonNullValues();
    } else {
      handleCheckout();
    }
  };

  return (
    <>
      <section className={styles.paymentMethodsSection}>
        <PaymentMethods
          userId={cart.ownerId}
          cardToken={cardToken}
          onCardTokenChange={setCardToken}
          cardholderName={cardholderName}
          onCardholderNameChange={setCardholderName}
          setCardInputsMessageStatus={setCardInputsMessageStatus}
          cardInputsMessageStatus={cardInputsMessageStatus}
          addressCreationProps={addressCreationProps}
        />
      </section>
      <section className={styles.summarySection}>
        {lineItems && <Cart lineItems={lineItems} taxInCents={cart.taxInCents} />}
        <LoadingButton
          loadingPosition="start"
          className={styles.checkoutButton}
          sx={ButtonsStyle({}).root}
          variant="contained"
          onClick={handleCheckoutClick}
          loading={loading}
        >
          <FormattedMessage id="btn_text_checkout" />
        </LoadingButton>
      </section>
    </>
  );
};
