export const isValueNullOrUndefined = (value) => {
  return value === null || value === undefined;
};

export const capitalizeFirstLetter = (string: string) => {
  if (!string) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const CloneProps = (props) => {
  const { children, ...other } = props;
  return children(other);
};
