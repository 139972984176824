import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      cursor: "pointer",
      boxShadow: `0px 4px 6px 0px ${theme.palette.campaigns.shadowGrey}`,
    },
    alert: {
      width: "100%",
    },
  };
});

export default useStyles;
