import { makeStyles } from "@mui/styles";
import { useIntl } from "react-intl";
import { ButtonText } from "../Membership/PaymentSummary/types";

type Props = {
  buttonText: string;
};

const ButtonsStyle = (props) => {
  const intl = useIntl();
  return {
    root: {
      backgroundColor: "#1473E6",
      color: "white",
      fontFamily: '"AdobeClean", "AdobeCleanSerif", "Poppins", "sans-serif"',
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#F6C40E",
      },
    },
    mainBtn: {
      padding: "0.6rem 1.5rem",
      minWidth: "200px",
      maxWidth: "300px",
      width: "80%",
      borderRadius: "0.4rem",
      "& .MuiButton-label": {
        color: "#fff",
      },
      color: "#fff",
    },
    headerBtn: {
      borderRadius: "2rem",
      padding: "0.3rem 1.5rem",
      minWidth: "max-content",
      "@media all and (max-width: 700px)": {
        display: "none",
      },
    },
    headerResponsiveBtn: {
      borderRadius: "2rem",
      padding: "0.3rem 1.5rem",
      minWidth: "max-content",
      "@media all and (min-width: 701px)": {
        display: "none",
      },
    },
    membershipBtn: {
      borderRadius: "2rem",
      padding: "0.3rem 1.5rem",
      width: "12rem",
      height: "2.2rem",
      fontWeight: 600,
      "& .MuiButton-label": {
        color: "#fff",
      },
      color: "#fff",
      opacity: () => {
        if (props.buttonText !== ButtonText(intl).COMING_SOON) {
          return 1;
        }
        return 0.5;
      },
    },
    footerBtn: {
      borderRadius: "20px",
      fontSize: "0.8rem",
      padding: "0",
      height: "2rem",
      width: "80%",
    },
    removePaymentBtn: {
      backgroundColor: "#BF0010",
      borderRadius: "20px",
      fontSize: "12px",
      height: "36px",
      width: "130px",
      justifyContent: "center",
      color: "white",
    },
    cancelPaymentBtn: {
      backgroundColor: "white",
      boderWidth: "10px",
      borderColor: "#8986AD",
      borderRadius: "20px",
      fontSize: "12px",
      fontWeight: "600",
      height: "36px",
      width: "100px",
      "&:hover": {
        backgroundColor: "#8986ADs",
        borderColor: "#8986ADs",
        boxShadow: "none",
      },
    },
  };
};

const useStyles = makeStyles((theme) => {
  const intl = useIntl();
  return {
    root: {
      backgroundColor: "#1473E6",
      color: theme.palette.white,
      fontFamily: theme.typography.fontFamily,
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#F6C40E",
      },
    },
    mainBtn: {
      padding: "0.6rem 1.5rem",
      minWidth: "200px",
      maxWidth: "300px",
      width: "80%",
      borderRadius: "0.4rem",
    },
    headerBtn: {
      borderRadius: "2rem",
      padding: "0.3rem 1.5rem",
      minWidth: "max-content",
      "@media all and (max-width: 700px)": {
        display: "none",
      },
    },
    headerResponsiveBtn: {
      borderRadius: "2rem",
      padding: "0.3rem 1.5rem",
      minWidth: "max-content",
      "@media all and (min-width: 701px)": {
        display: "none",
      },
    },
    membershipBtn: {
      borderRadius: "2rem",
      padding: "0.3rem 1.5rem",
      width: "12rem",
      height: "2.2rem",
      fontWeight: 600,
      "& .MuiButton-label": {
        color: "#fff",
      },
      opacity: (props: Props) => {
        if (props.buttonText !== ButtonText(intl).COMING_SOON) {
          return 1;
        }
        return 0.5;
      },
    },
    footerBtn: {
      borderRadius: "20px",
      fontSize: "0.8rem",
      padding: "0",
      width: "80%",
      height: "2rem",
      minWidth: "max-content",
    },
  };
});

export { useStyles, ButtonsStyle };
