import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    wrap: {
      opacity: ".7",
      transition: "300ms ease-in-out",
      color: theme.palette.ruleToggle.lightRed,
      "& .lockedIcon": {
        position: "absolute",
        top: "-12px",
        left: "-5px",
        transition: "300ms ease-in-out",
      },

      "& .MuiTypography-root": {
        lineHeight: "1",
      },

      "& .MuiSwitch-root": {
        height: "45px",
        width: "65px",

        "& .MuiSwitch-switchBase": {
          top: "3px",
          "&.Mui-checked": {
            transform: "translateX(27px)",
            [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
              transform: "translateX(16px)",
            },
            "& .MuiSwitch-thumb": {
              background: theme.palette.blue,
            },

            "& + .MuiSwitch-track": {
              background: theme.palette.ruleToggle.blue,
            },
          },

          "& .MuiSwitch-thumb": {
            background: theme.palette.ruleToggle.lightRed,
            [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
              height: "16px",
              width: "16px",
            },
          },
        },

        "& .MuiSwitch-track": {
          background: "#D38D93",
          borderRadius: "10px",
        },

        [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
          height: "40px",
          width: "50px",
        },
      },

      "&.active": {
        opacity: "1",
        color: theme.palette.analytics?.grey,
        "& .lockedIcon": {
          opacity: "0",
          visibility: "hidden",
        },
        "& .MuiSwitch-thumb": {
          background: theme.palette.diagram?.purple,
        },
      },
    },
  };
});

export default useStyles;
