import { useCallback, useState } from "react";
import { Portal } from "@mui/material";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import CookieConsentContext from "./context";
import { ConsentWrapper, TextContainer, ButtonContainer, CookiesSettingButton, AcceptAllCookiesButton } from "./styles";
import type { CookieConsentProviderProps, CookieSettings } from "./types";

const COOKIE_CONSENT_SETTINGS_STORAGE_KEY = ".cookie-settings";

const { Provider } = CookieConsentContext;

export function CookieConsentProvider({ children }: CookieConsentProviderProps) {
  const [showConsentBanner, setShowConsentBanner] = useState<boolean>(true);
  const history = useHistory();
  const [cookieSettings, setCookieSettings] = useState<CookieSettings>(() => {
    const settings = {
      essentialCookies: false,
      analyticalCookies: false,
      marketingCookies: false,
    };

    try {
      const storedCookieSettings = localStorage.getItem(COOKIE_CONSENT_SETTINGS_STORAGE_KEY);
      if (storedCookieSettings) {
        const deserializeStoredCookieSettings = JSON.parse(storedCookieSettings);
        if (
          typeof deserializeStoredCookieSettings === "object" &&
          "essentialCookies" in deserializeStoredCookieSettings &&
          "analyticalCookies" in deserializeStoredCookieSettings &&
          "marketingCookies" in deserializeStoredCookieSettings
        ) {
          setShowConsentBanner(false);
          settings.essentialCookies = deserializeStoredCookieSettings.essentialCookies === true;
          settings.analyticalCookies = deserializeStoredCookieSettings.analyticalCookies === true;
          settings.marketingCookies = deserializeStoredCookieSettings.marketingCookies === true;
        }
      }
    } catch (e: unknown) {
      // ignore
    }

    return { ...settings };
  });

  // Callback used to accept all the cookies
  const onAcceptAllCookiesClick = useCallback(() => {
    const newCookieSettings: CookieSettings = {
      essentialCookies: true,
      analyticalCookies: true,
      marketingCookies: true,
    };

    setShowConsentBanner(false);
    setCookieSettings(newCookieSettings);
    localStorage.setItem(COOKIE_CONSENT_SETTINGS_STORAGE_KEY, JSON.stringify(newCookieSettings));
  }, []);

  // Render the provider
  return (
    <Provider value={cookieSettings}>
      {children}

      {showConsentBanner && (
        <Portal>
          <ConsentWrapper>
            <TextContainer>
              <FormattedMessage id="cookies.by_clicking" /> &quot;
              <strong>
                <FormattedMessage id="cookies.accept_cookies" />
              </strong>
              &quot; <FormattedMessage id="cookies.you_agree" />
            </TextContainer>

            <ButtonContainer>
              <CookiesSettingButton role="button" onClick={() => history.push("/cookies")}>
                <FormattedMessage id="cookies.more_information" />
              </CookiesSettingButton>

              <AcceptAllCookiesButton role="button" onClick={onAcceptAllCookiesClick}>
                <FormattedMessage id="cookies.accept_cookies" />
              </AcceptAllCookiesButton>
            </ButtonContainer>
          </ConsentWrapper>
        </Portal>
      )}
    </Provider>
  );
}
