import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    wrap: {
      marginTop: "-100px",

      "@media(max-width: 600px)": {
        marginTop: "0px",
      },

      "& .row": {
        display: "flex",
        alignItems: "center",
        transition: "all 300ms ease-in-out",

        "& .MuiSwitch-root": {
          height: "44px",
          width: "65px",
          "& .MuiSwitch-switchBase": {
            top: "3px",
            "& .MuiSwitch-thumb": {
              height: "21px",
              width: "21px",
              backgroundColor: theme.palette.diagram?.purple,
            },
          },

          "& .MuiSwitch-track": {
            height: "21px",
            width: "41px",
            backgroundColor: theme.palette.diagram?.grey,
            borderRadius: "14px",
            opacity: "1",
          },

          "& .Mui-checked": {
            transform: "translateX(24px)",

            "& .MuiSwitch-thumb": {
              backgroundColor: `${theme.palette.diagram?.blue} !important`,
            },

            "& + .MuiSwitch-track": {
              backgroundColor: `${theme.palette.diagram?.purple} !important`,
            },
          },
        },

        "&:nth-child(2)": {
          opacity: "0",
          visibility: "hidden",
          justifyContent: "end",
        },

        "&.active": {
          opacity: "1",
          visibility: "visible",
        },

        "& .MuiInputBase-root": {
          height: "32px",
          maxWidth: "54px",
          padding: "0 5px",
          margin: "0 10px",
          border: "1px solid #CACACA",
          borderRadius: "4px",
          " & input, input::-webkit-input-placeholder ": {
            textAlign: "center",
          },
          "& .MuiInputBase-input": {
            "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
              WebkitAppearance: "none",
              margin: "0",
            },

            "&[type=number]": {
              MozAppearance: "textfield",
            },
          },
        },
      },
    },
  };
});

export default useStyles;
