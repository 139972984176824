import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    alert: {
      padding: "6px 10px",
      alignItems: "center",
    },
    greyOut: {
      backgroundColor: "#F4F6FC!important",
      background: "#F4F6FC!important",
    },
    hiddenDiagramMessage: {
      visibility: "hidden",
      height: "90px",
    },
    diagramWrap: {
      display: "flex",

      flexWrap: "nowrap",
      width: "100%",
      // marginTop: "260px",
      marginTop: "150px",
      marginBottom: "200px",
      [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
        flexDirection: "column",
        margin: "30px 0",
      },
    },
    item: {
      position: "relative",
      height: "97px",
      width: "132px",
      padding: "8px 15px",
      marginRight: "14px",
      borderRadius: "3px",
      border: `1px solid ${theme.palette.diagram?.grey}`,

      "& input:focus": {
        "&::placeholder": {
          color: "transparent !important",
        },
      },

      [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
        height: "77px",
        width: "100px",
        padding: "8px 8px",
        marginRight: "8px",
      },

      [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
        height: "70px",
        width: "86px",
      },

      [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
        height: "100px",
        width: "152px",
        marginRight: "0",
        padding: "2px 8px",
        marginBottom: "15px",
      },

      "&.first": {
        background: theme.palette.diagram?.white,
        border: "0",
        opacity: "1",
        "& .itemMiddle": {
          "& .MuiInputBase-root": {
            "& .MuiInputBase-input": {
              color: theme.palette.diagram?.blue,
              "&::placeholder": {
                color: "#4262FF",
                textAlign: "start",
              },
            },
          },
        },
      },

      "&:first-child, &:last-child": {
        marginRight: "0",
        marginBottom: "0",
      },

      "& > div": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      },

      "& .itemTop": {
        height: "17px",
        alignSelf: "start",

        "& .MuiSwitch-root": {
          width: "47px",
          right: "-12px",
          "& .MuiSwitch-switchBase": {
            top: "3px",
            "& .MuiSwitch-thumb": {
              height: "11px",
              width: "11px",
              backgroundColor: theme.palette.diagram?.purple,
            },
          },

          "& .MuiSwitch-track": {
            height: "11px",
            width: "22px",
            backgroundColor: theme.palette.diagram?.grey,
          },

          "& .Mui-checked": {
            transform: "translateX(14px)",
          },
        },
      },

      "& .itemMiddle": {
        height: "32px",
        marginBottom: "5px",

        [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
          height: "28px",
        },

        [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
          height: "34px",
        },

        "& .MuiInputBase-root": {
          height: "32px",
          width: "50px",
          marginRight: "4px",
          fontSize: "1",
          borderBottom: `1px solid ${theme.palette.diagram?.purple}`,

          [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
            height: "28px",
          },
          [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
            height: "34px",
          },
          "& .MuiInputBase-input": {
            fontSize: "18px",
            fontFamily: theme.typography.fontFamily,

            [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
              fontSize: "15px",
            },
            [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
              fontSize: "14px",
            },

            [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
              fontSize: "18px",
            },

            "&::placeholder": {
              color: theme.palette.diagram?.grey,
              fontFamily: theme.typography.fontFamily,
              opacity: "1",
              textAlign: "center",
            },

            "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
              WebkitAppearance: "none",
              margin: "0",
            },

            "&[type=number]": {
              MozAppearance: "textfield",
            },
          },
        },
        "& img": {
          height: "33px",
          width: "33px",
          marginTop: "10px",
        },
      },

      "& .itemBottom": {
        "& p": {
          maxWidth: "41px",
          fontSize: "9px",
          whiteSpace: "nowrap",
          color: theme.palette.diagram?.purple,
          [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
            fontSize: "14px",
          },
        },
      },

      "&.isPercentage:not(.first)": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& .MuiInputBase-root": {
          display: "none",
        },
        "& img": {
          marginBottom: "10px",
        },
      },

      "&.isFlipProcentageItem": {
        display: "flex",
        flexDirection: "column",

        "& .itemMiddle": {
          marginTop: "8px",
        },

        "& .MuiInputBase-root": {
          display: "none",
        },
      },
    },
    equivalent: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "12px",
      width: "13px",
      margin: "50px 18px",

      [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
        margin: "40px 14px",
      },
      [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
        margin: "30px 10px",
      },
      [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
        margin: "20px 60px",
      },
      "& span": {
        height: "2px",
        width: "100%",
        background: theme.palette.primary.dark,
      },
    },

    split: {
      position: "relative",
      marginRight: "14px",
      [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
        marginRight: "0",
        marginBottom: "15px",
      },
      "& .splitTip": {
        display: "flex",
        alignItems: "center",
        position: "absolute",
        top: "-85%",
        left: "53%",
        transform: "translateX(-50%)",
        color: theme.palette.diagram?.purple,
        fontSize: "12px",
        [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
          top: "0",
          left: "131%",
        },
        "& p": {
          marginLeft: "5px",
          [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
            fontSize: "11px",
            whiteSpace: "nowrap",
          },
          [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
            fontSize: "9px",
          },
        },
      },

      "& .splitTop": {
        position: "absolute",
        top: "-59%",
        left: "50%",
        transform: "translateX(-50%)",
        display: "flex",
        justifyContent: "space-between",
        height: "61%",
        width: "85%",
        opacity: "0.8",

        [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
          top: "45%",
          left: "124%",
          transform: "translate(-50%, -50%)",
          flexDirection: "column",
          height: "84%",
          width: "50%",
        },
        "& div": {
          display: "flex",
          justifyContent: "center",
          paddingTop: "11px",
          color: theme.palette.diagram?.purple,
          border: `0.5px solid ${theme.palette.diagram?.purple}`,
          borderBottom: "0",
          borderRadius: "3px",
          fontSize: "10px",
          [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
            paddingTop: "7px",
          },
          [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
            paddingTop: "5px",
          },
          [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
            padding: "0",
            borderBottom: `0.5px solid ${theme.palette.diagram?.purple}`,
            borderLeft: "none",
          },
        },
        "& .splitTopChild": {
          alignSelf: "end",
          height: "37px",
          width: "49px",
          background: theme.palette.primary.light,
          zIndex: "10",

          [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
            height: "30px",
          },

          [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
            height: "25px",
          },

          [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
            height: "30px",
            width: "25px",
            alignSelf: "start",

            "& p": {
              display: "flex",
              alignItems: "center",
            },
          },
        },

        "& .splitTopParent": {
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          height: "100%",
          width: "91%",
          fontSize: "12px",
          color: theme.palette.diagram?.blue,
          [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
            width: "86%",
          },
          [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            top: "50%",
            transform: "translate(-50%, -50%)",
            height: "91%",
            width: "36px",
          },

          "&.inside": {
            width: "30%",
            borderRadius: "0",
            [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              top: "50%",
              transform: "translate(-50%, -50%)",
              height: "31%",
              width: "36px",
            },
          },
        },
      },

      "& .splitMidle": {
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        zIndex: "10",
        [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
          flexDirection: "column",
        },
        "& .splitItem:first-child": {
          marginRight: "14px",
          background: "none",
          border: `1px solid ${theme.palette.diagram?.grey}`,
          [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
            marginRight: "8px",
          },
          [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
            marginRight: "0",
            marginBottom: "10px",
          },

          "& .MuiInputBase-input": {
            color: theme.palette.diagram?.black,
          },
        },
      },
    },
  };
});

export default useStyles;
