import { FC } from "react";
import { Grid } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { CardExpiryElement } from "@stripe/react-stripe-js";
import { useStyles } from "./styles";
import { CardErrorMessage } from "../CardErrorMessage";
import { CardMessageStatus, CustomInputStyleOptions, EventStripe } from "./CardForm.types";

interface CardExpiryInputProps {
  cardMessageStatus: CardMessageStatus | null;
  customInputStyleOptions: (formatMessageId: string) => CustomInputStyleOptions;
  onChangeErrorHandler: (event: EventStripe) => void;
}

export const CardExpiryInput: FC<CardExpiryInputProps> = ({
  cardMessageStatus,
  customInputStyleOptions,
  onChangeErrorHandler,
}) => {
  const styles = useStyles();

  return (
    <Grid className={styles.expiry}>
      <label htmlFor="card-expiry" className={styles.label}>
        <FormattedMessage id="card_expiry" />
      </label>
      <CardExpiryElement
        id="card-expiry"
        className={styles.input}
        options={customInputStyleOptions("card_expiry_placeholder")}
        onChange={onChangeErrorHandler}
      />
      <CardErrorMessage
        cardMessageStatus={cardMessageStatus}
        emptyErrorMessageId="card_expiry_error_empty"
        invalidErrorMessageId="card_expiry_error_invalid"
      />
    </Grid>
  );
};
