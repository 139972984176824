import { useCallback, useState } from "react";

export const useBooleanState = (defaultValue = false) => {
  const [booleanState, setBooleanState] = useState(defaultValue);

  return {
    value: booleanState,
    setTrue: useCallback(() => setBooleanState(true), [setBooleanState]),
    setFalse: useCallback(() => setBooleanState(false), [setBooleanState]),
    toggle: useCallback(() => setBooleanState((currentValue) => !currentValue), [setBooleanState]),
  };
};
