import { FC, ReactNode } from "react";
import { ClickBlocker } from "../../ClickBlocker";
import { NoMembershipPlanNotification } from "../../NoMembershipPlanNotification";

interface NoMembershipPlanProps {
  children?: ReactNode;
  isSubscribed: boolean;
}

export const NoMembershipPlan: FC<NoMembershipPlanProps> = ({ children, isSubscribed }) => {
  if (isSubscribed) {
    return <>{children}</>;
  }

  return (
    <>
      <NoMembershipPlanNotification />
      <ClickBlocker>{children}</ClickBlocker>
    </>
  );
};
