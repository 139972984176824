import { OptionsObject } from "notistack";
import { AlertColor } from "@mui/material";
import Notification from "../../Notification";
import { ANCHOR_ORIGIN_HORIZONTAL, ANCHOR_ORIGIN_VERTICAL, AUTO_HIDE_DURATION } from "../../../constants";

export const getOptions = (variant: AlertColor): OptionsObject => ({
  autoHideDuration: AUTO_HIDE_DURATION,
  anchorOrigin: { vertical: ANCHOR_ORIGIN_VERTICAL, horizontal: ANCHOR_ORIGIN_HORIZONTAL },
  content: (key, message) => <Notification id={key} message={message} variant={variant} />,
});
