import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    mainWidget: {
      backgroundColor: theme.palette.dashBoard?.lightBlue,
      borderRadius: "0.375rem",
      height: "9.25rem",

      "& .widgetItem": {
        padding: "0 2.8125rem 0 0",
        margin: "0 2.8125rem 0 0",
        borderRight: `0.031rem solid ${theme.palette.diagram?.purple}`,

        "@media(max-width: 990px)": {
          margin: "0 1.5rem 0 0",
          padding: "0 1.5rem 0 0",
        },

        "@media(max-width: 925px)": {
          margin: "0 0.8rem 0 0",
          padding: "0 0.8rem 0 0",
        },

        "@media(max-width: 640px)": {
          margin: "0 0.45rem 0 0",
          padding: "0 0.45rem 0 0",
        },

        "@media(max-width: 600px)": {
          margin: "0 0.8rem 0 0",
          padding: "0 0.8rem 0 0",
        },

        "&:last-child": {
          padding: "0",
          margin: "0",
          borderRight: "none",
        },

        "& .widgetItemIcon": {
          height: "2.688rem",
          width: "2.688rem",
          borderRadius: "6.25rem",
          backgroundColor: theme.palette.primary.dark,
          border: `0.25rem solid ${theme.palette.diagram?.purple}`,
        },

        "& .widgetItemValue": {
          fontSize: "1.125rem",
          fontWeight: "bold",
          color: theme.palette.primary.dark,
        },

        "& .widgetItemTitle": {
          fontSize: "0.625rem",
          color: theme.palette.dashBoard?.grey,
        },
      },
    },
  };
});

export default useStyles;
