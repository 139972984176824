import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    btn: {
      height: "32px",
      padding: "0 27px",
      marginRight: "10px",
      color: theme.palette.analytics?.white,
      background: theme.palette.dashBoard?.blue,
      borderRadius: "16px",
      textTransform: "unset",
      fontWeight: "bold",
      "&:hover": {
        textDecoration: "none",
        backgroundColor: "#F6C40E",
      },
    },
    mainContainer: {
      display: "flex",
      height: "100vh",
      backgroundColor: "#FFFFFF",

      "@media all and (max-width: 900px)": {
        display: "block",
      },

      "& .RewardIconYellow": {
        height: "100%",
        width: "100%",
      },

      "& .more": {
        textDecoration: "underline",
        cursor: "pointer",
      },
    },
    mainDiv: {
      display: "grid",
      width: "75%",
      padding: "2rem 1rem",
      backgroundColor: "#FAFAFA",
      overflowY: "auto",
      gridTemplateColumns: "repeat(6, 1fr)",
      gridTemplateRows: "repeat(5, minmax(min-content, max-content))",
      gridTemplateAreas: `
        'thisMonth thisMonth thisMonth thisMonth thisMonth thisMonth'
        'secondaryWidget secondaryWidget secondaryWidget secondaryWidget secondaryWidget technology'
        'analytics analytics analytics analytics analytics technology'
        'yourRewards yourRewards yourRewards experiences experiences experiences'
        'campaigns campaigns campaigns marketing marketing marketing'`,
      gap: "10px",

      [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
        gridTemplateAreas: `
        'thisMonth thisMonth thisMonth thisMonth thisMonth thisMonth'
        'secondaryWidget secondaryWidget secondaryWidget secondaryWidget secondaryWidget secondaryWidget'
        'technology technology technology technology technology technology'
        'analytics analytics analytics analytics analytics analytics'
        'yourRewards yourRewards yourRewards experiences experiences experiences'
        'campaigns campaigns campaigns marketing marketing marketing'`,
      },

      [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
        gridTemplateAreas: `
        'thisMonth thisMonth thisMonth thisMonth thisMonth thisMonth'
        'secondaryWidget secondaryWidget secondaryWidget secondaryWidget secondaryWidget secondaryWidget'
        'technology technology technology technology technology technology'
        'analytics analytics analytics analytics analytics analytics'
        'yourRewards yourRewards yourRewards yourRewards yourRewards yourRewards'
        'experiences experiences experiences experiences experiences experiences'
        'campaigns campaigns campaigns campaigns campaigns campaigns'
        'marketing marketing marketing marketing marketing marketing'`,
      },

      [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
        width: "100%",
      },

      "@media all and (max-width: 600px)": {
        overflow: "hidden",
      },
    },
  };
});

export default useStyles;
