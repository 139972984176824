import { FC, createContext, useState, useMemo, useCallback, useEffect } from "react";
import { IntlProvider } from "react-intl";
import { Language } from "../types/contexts";
import { locales, LocalStorageKey, messages } from "../locales";

export const LanguageContext = createContext<Language>({
  language: locales.EN.value,
  selectLanguage: () => {},
});

export const LanguageProvider: FC = ({ children }) => {
  const [language, setLanguage] = useState(localStorage.getItem(LocalStorageKey.SELECTED_LOCALE) || locales.EN.value);

  const selectLanguage = useCallback((selectedLanguage: string) => {
    setLanguage(selectedLanguage);
    localStorage.setItem(LocalStorageKey.SELECTED_LOCALE, selectedLanguage);
  }, []);

  const contextValue = useMemo(
    (): Language => ({
      language,
      selectLanguage,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [language],
  );
  return (
    <LanguageContext.Provider value={contextValue}>
      <IntlProvider locale={language} messages={messages[language]}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};
