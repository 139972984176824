import { isPlainObject } from "./isPlainObject.util";

export const flatten = (object: Record<string, unknown>, parentKey?: string) => {
  return Object.entries(object).reduce((acc, [key, value]) => {
    const currentKey = [parentKey, key].filter(Boolean).join(".");

    return {
      ...acc,
      ...(isPlainObject(value) ? flatten(value, currentKey) : { [currentKey]: value }),
    };
  }, {});
};
