import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import { Grid } from "@mui/material";

import { useIntl } from "react-intl";
import useStyles from "./styles";
import QrCodes from "./QrCodes";
import QrCreate from "./QrCodes/QrCreate";
import { withTabsTechnologyLayout } from "./HOCs";
import QrSuccess from "./QrCodes/QrSuccess";
import QrEdit from "./QrCodes/QrEdit";
import { useFeatureFlagValue } from "../../hooks";
import { FeatureFlagEnum } from "../../types/contexts";
import FallBackPages from "../FallBackPages";
import manWalking from "../../assets/images/manWalking.png";

const Technology = () => {
  const { formatMessage } = useIntl();
  const { rootContainer } = useStyles();
  const { path } = useRouteMatch();
  const isTechnologyV2Enabled = useFeatureFlagValue(FeatureFlagEnum.TECHNOLOGY_V2_ENABLED);
  return (
    <Grid>
      <Grid container item direction="column" className={rootContainer}>
        {isTechnologyV2Enabled ? (
          <Switch>
            <Route path={`${path}/Edit/:id`} component={withTabsTechnologyLayout(QrEdit)} />
            <Route path={`${path}/Create-Qr/Success`} component={QrSuccess} />
            <Route path={`${path}/Create-Qr`} component={withTabsTechnologyLayout(QrCreate)} />
            <Route exact path="/Technology" component={withTabsTechnologyLayout(QrCodes)} />
            <Route>
              <Redirect to="/Technology" />
            </Route>
          </Switch>
        ) : (
          <FallBackPages
            image={manWalking}
            title={formatMessage({ id: "technology.not_available" })}
            description={<span>{formatMessage({ id: "not_available.description" })}</span>}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default Technology;
