import { FC } from "react";
import { Button, Divider, Grid, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import AdManagerLogo from "../../../assets/icons/tykIcons/Ad_Manager_logo.svg";
import Modal from "../../Modal";
import useStyles from "./styles";

interface AdManagerRedirectionModalProps {
  isOpen: boolean;
  onClose: VoidFunction;
}

const AdManagerRedirectionModal: FC<AdManagerRedirectionModalProps> = ({ isOpen, onClose }) => {
  const { modalContainer } = useStyles();
  return (
    <Modal onClose={onClose} isOpen={isOpen} isClosable={false}>
      <Grid container direction="column" className={modalContainer}>
        <Grid className="modal-header">
          <h1 className="modal-header__title">
            <FormattedMessage id="modals.ad_manager.title" />
          </h1>

          <img src={AdManagerLogo} alt="ad-manager-logo" />
        </Grid>

        <Divider className="modal-divider" />

        <Grid className="modal-body">
          <Typography>
            <FormattedMessage id="modals.ad_manager.description" />
          </Typography>

          <Typography className="extra">
            <FormattedMessage id="modals.ad_manager.try_it" />
          </Typography>
        </Grid>

        <Grid className="modal-footer">
          <Button onClick={onClose} variant="contained" className="cancel-button">
            <FormattedMessage id="btn_text_cancel" />
          </Button>
          <Button onClick={onClose} variant="contained" className="primary-button">
            <FormattedMessage id="btn_text_continue" />
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default AdManagerRedirectionModal;
