import { Dispatch, SetStateAction } from "react";
import { Box, Grid, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { FormattedMessage } from "react-intl";
import useStyles from "./styles";
import MembershipRuleRow from "./MembershipRuleRow";
import { IMembershipRule } from "../../../types/membershipTypes";
import { ITykchaseGlobalConfig } from "../../../types/tykchaseGlobalConfig";
import RuleConfigurationInterface from "../RewardRules/RuleConfigurationInterface";
import { Rewards } from "../Modals/Diagram/types";
import { isItemDisabled } from "../../Utils/helpers";

interface MembershipRulesTableProps {
  isSubscribed: boolean;
  membershipRules: IMembershipRule[];
  isAllRulesSelected: boolean;
  tykchaseGlobalConfig?: ITykchaseGlobalConfig;
  handlePublishModal: (ruleConfiguration: RuleConfigurationInterface) => Promise<void>;
  handleSaveModal: (ruleConfiguration: RuleConfigurationInterface) => Promise<void>;
  handleSelectRuleToggle: (ruleId: any) => void;
  handleActiveRuleToggle: (ruleId: string) => void;
  handleSelectAllRulesToggle: () => void;
  setRewardSplit: Dispatch<SetStateAction<Rewards>>;
  disabledChildrenRules: string[] | null;
  membershipType?: string | null;
}

export const MembershipRulesTable = ({
  isSubscribed,
  membershipRules,
  handlePublishModal,
  handleSaveModal,
  handleSelectRuleToggle,
  handleActiveRuleToggle,
  handleSelectAllRulesToggle,
  isAllRulesSelected,
  tykchaseGlobalConfig,
  setRewardSplit,
  disabledChildrenRules,
  membershipType,
}: MembershipRulesTableProps) => {
  const { rulesTable, headCheckBox } = useStyles();

  return (
    <Grid container item direction="column" className={rulesTable}>
      <Grid item>
        <Box className="tableHeadRow" display="grid" gridTemplateColumns="50px 1fr" alignItems="end">
          <Checkbox
            disabled={!isSubscribed}
            checked={isAllRulesSelected}
            className={headCheckBox}
            onClick={handleSelectAllRulesToggle}
          />
          <Box
            className="tableHeadRowContent"
            display="grid"
            columnGap={1}
            gridTemplateColumns="minmax(100px, 5fr) minmax(40px ,1fr) minmax(60px ,1fr)"
          >
            <Box display="flex" alignItems="center">
              <Typography>
                <FormattedMessage id="reward_rules_general_rewards" />
              </Typography>
            </Box>

            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography>
                <FormattedMessage id="label_published" />
              </Typography>
            </Box>
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <Typography>
                <FormattedMessage id="label_actions" />
              </Typography>
            </Box>
          </Box>
        </Box>
        {membershipRules?.map(
          ({
            id,
            title,
            isSelected,
            isActive,
            reward,
            ruleName,
            backendData,
            multipleTimeReward,
            pickedRewardInputs,
            rewardRuleModalHeaderControls,
            rewardRuleModalFooter,
            rewardRuleModalSubHeader,
          }) => {
            return (
              <MembershipRuleRow
                key={id}
                id={id}
                title={title}
                isSelected={isSelected}
                isActive={isActive}
                reward={reward}
                ruleName={ruleName}
                backendData={backendData}
                handlePublishModal={handlePublishModal}
                handleSaveModal={handleSaveModal}
                handleSelectToggle={handleSelectRuleToggle}
                handleActiveToggle={handleActiveRuleToggle}
                tykchaseGlobalConfig={tykchaseGlobalConfig}
                setRewardSplit={setRewardSplit}
                disabledChildrenRules={disabledChildrenRules}
                membershipRules={membershipRules}
                isSubscribed={isSubscribed}
                multipleTimeReward={multipleTimeReward}
                pickedRewardInputs={pickedRewardInputs}
                rewardRuleModalHeaderControls={rewardRuleModalHeaderControls}
                rewardRuleModalFooter={rewardRuleModalFooter}
                rewardRuleModalSubHeader={rewardRuleModalSubHeader}
              />
            );
          },
        )}
      </Grid>
    </Grid>
  );
};
