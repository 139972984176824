import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const AbsoluteRedirect = (props) => {
  const history = useHistory();
  const { to } = props;
  // window.location.href = to;
  useEffect(() => {
    history.replace(to);
  }, [history, to]);

  return null;
};

export default AbsoluteRedirect;
