import { boolean } from "yup";
import { ITykAwardsDistribution } from "../../../../types/tykchaseGlobalConfig";

export interface IRewardDistributionModal {
  reward: string;
  isOpen: boolean;
  customTykAwardsDistribution?: ITykAwardsDistribution;
  handleOnRewardDistributionSave?: (customRewards: ITykAwardsDistribution) => void;
  onClose: VoidFunction;
}

export interface DistributionRateType {
  centorsRate?: string | number;
  jackpotAwardRate?: string | number;
  loneStarsRate?: string | number;
  minimumLoneStarAward?: string | number;
  promoterAwardRate?: string | number;
}

export interface IRewardDistributionData {
  values: DistributionRateType;
  ids: string[];
}

export interface IRewardDistributionDataWithPermission {
  value: string;
  id: string;
}

export const keysPermission: IRewardDistributionDataWithPermission[] = [
  { id: "TotalDistributionCentors", value: "centorsRate" },
  { id: "TotalDistributionLoneStars", value: "loneStarsRate" },
  { id: "TotalDistributionJackpot", value: "jackpotAwardRate" },
  { id: "TotalDistributionCashier", value: "promoterAwardRate" },
  { id: "TotalDistributionMinLoneStars", value: "minimumLoneStarAward" },
];
