import { Dispatch, SetStateAction } from "react";
import { PurchaseRewardRate } from "../../../../generated/types";
import { ITykchaseGlobalConfig } from "../../../../types/tykchaseGlobalConfig";

export enum RatesPropertyNames {
  CENTORS_RATE = "centorsRate",
  PROMOTER_ARWARD_RATE = "promoterAwardRate",
  LONE_STARS_RATE = "loneStarsRate",
  JACKPOT_AWARD_RATE = "jackpotAwardRate",
}

export interface Rewards {
  centorsRewards?: string;
  loneStarsRewards?: string;
  jackpotRewards?: string;
  promoterRewards?: string;
}

export interface DiagramData {
  rewardUsd?: string | number;
  isPercentage?: boolean;
  backendData?: any;
  setInputData?: any;
  setRewardSplit?: Dispatch<SetStateAction<Rewards>>;
  margin?: string | {};
  width?: string;
  formik?: any;
  tykchaseGlobalConfig?: ITykchaseGlobalConfig;
  purchaseRewardRate?: PurchaseRewardRate;
  isSubmitting?: boolean;
}
