import theme from "../../../../theme";
import { Memberships, MembershipsSpanish } from "../../../../types/membershipTypes";
import { PlanOption } from "./PlansList.interfaces";

export const plansListOptions: PlanOption[] = [
  {
    title: Memberships.Business,
    titleSpanish: MembershipsSpanish.Negocios,
    letter: "B",
    color: theme.palette.membership?.business as string,
  },
  {
    title: Memberships.Brands,
    titleSpanish: MembershipsSpanish.Marcas,
    letter: "Br",
    color: theme.palette.membership?.brands as string,
    comingSoon: true,
  },
  {
    title: Memberships.Enterprise,
    titleSpanish: MembershipsSpanish.Empresa,
    letter: "E",
    color: theme.palette.membership?.enterprise as string,
    comingSoon: true,
  },
];
