import { Box, IconButton, Typography } from "@mui/material";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import { OpenIcon, ExclamationTriangle } from "../../../../../utils/icon";
import JackpotIcon from "../../../../../assets/icons/tykIcons/Jackpot-Icon(1)@2x.png";
import Centor from "../../../../../assets/icons/tykIcons/Centor-icon@2x.png";
import LoneStar from "../../../../../assets/icons/tykIcons/LoneStar-icon@2x.png";
import theme from "../../../../../theme";
import useStyles from "./styles";

const Jackpot = () => {
  const { wrap, emptyStateWrap } = useStyles();
  const isActive = true;
  const isWinner = false;

  return (
    <Box className={wrap} display="flex" flexDirection="column">
      <Box className="jackpotHeader" display="flex" flexDirection="row" alignItems="flex-start">
        <img src={JackpotIcon} alt="" height="32px" className={clsx({ inactive: !isActive })} />

        <Box display="flex" flexDirection="column" width="100%" marginLeft="10px">
          <Typography
            variant="h3"
            color={isActive ? theme.palette.black : "#818FD4"}
            fontFamily={theme.typography.fontFamily}
            fontSize="1rem"
            fontWeight="bold"
          >
            <FormattedMessage id="your_rewards_jackpot_tab" />
          </Typography>

          <Typography color="#797882" fontFamily={theme.typography.fontFamily} fontSize="0.75rem">
            <FormattedMessage id={isActive ? "active" : "inactive"} />
          </Typography>
        </Box>
      </Box>

      <Box className="jackpotBody">
        {isActive ? (
          <Box className="jackpotItemWrap">
            <IconButton>
              <OpenIcon fill="#0364FF" />
            </IconButton>

            <Box display="flex" flexDirection="column" marginTop={isWinner ? "0" : "10px"}>
              {isWinner && (
                <Typography
                  color="#D3202F"
                  fontFamily={theme.typography.fontFamily}
                  fontSize="0.625rem"
                  fontWeight="bold"
                  marginLeft="6px"
                >
                  Won on Jan. 27th 2022
                </Typography>
              )}

              <Box className="jackpotItemContent">
                <Box className="jackpotItemStatisticWrap">
                  <Box className="jackpotItemStatistic">
                    <Typography
                      color={theme.palette.black}
                      fontFamily={theme.typography.fontFamily}
                      fontSize="0.875rem"
                      fontWeight="bold"
                    >
                      {"200".toLocaleString()}
                    </Typography>
                  </Box>

                  <Typography color="#797882" fontFamily={theme.typography.fontFamily} fontSize="0.625rem">
                    <FormattedMessage id="analytics.jackpot.attempts" />
                  </Typography>
                </Box>

                <Box className="jackpotItemStatisticDivider" />

                <Box className="jackpotItemStatisticWrap">
                  <Box className="jackpotItemStatistic">
                    <Typography
                      color={theme.palette.black}
                      fontFamily={theme.typography.fontFamily}
                      fontSize="0.8125rem"
                      fontWeight={500}
                      marginRight="6px"
                    >
                      {"200".toLocaleString()}
                    </Typography>

                    <img src={Centor} alt="Tyk" height={20} />
                  </Box>

                  <Typography color="#797882" fontFamily={theme.typography.fontFamily} fontSize="0.625rem">
                    <FormattedMessage id="analytics.jackpot.accumulated" />
                  </Typography>
                </Box>

                <Box className="jackpotItemStatisticWrap">
                  <Box className="jackpotItemStatistic">
                    <Typography
                      color={theme.palette.black}
                      fontFamily={theme.typography.fontFamily}
                      fontSize="0.8125rem"
                      fontWeight={500}
                      marginRight="6px"
                    >
                      {"50".toLocaleString()}
                    </Typography>

                    <img src={LoneStar} alt="LoneStars" height={26} />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box className={emptyStateWrap}>
            <ExclamationTriangle fill="#818FD4" />

            <Typography marginLeft="10px" color="#797882" fontFamily={theme.typography.fontFamily} fontSize="0.75rem">
              <FormattedMessage id="analytics.jackpot.jackpot_is_inactive" />
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Jackpot;
