import { FC, useState } from "react";
import { useTheme } from "@mui/styles";
import { Grid, Typography } from "@mui/material";

import { useIntl } from "react-intl";
import CreateCampaignWidget from "./CreateCampaignWidget";
import Modals from "./Modals";

import Icon from "../../../../utils/icon";
import { useFeatureFlagValue } from "../../../../hooks";
import { ICreateCampaign } from "../types";
import { FeatureFlagEnum } from "../../../../types/contexts";

const CreateCampaign = ({ tykchaseGlobalConfig }: ICreateCampaign) => {
  const theme = useTheme();
  const isSpecialCampaignV2Enabled = useFeatureFlagValue(FeatureFlagEnum.SPECIAL_CAMPAIGN_ENABLED);
  const { formatMessage } = useIntl();
  const [selectedModalName, setSelectedModalName] = useState("");
  const SelectedModalComponent = Modals[selectedModalName];

  const handleToggleModal = (modalName) => {
    setSelectedModalName(modalName);
  };

  return (
    <Grid p={{ xs: "0 10px", lg: "0 66px" }} mb="23px">
      <Typography mb="23px" fontSize="0.875rem" color={theme.palette.analytics?.grey}>
        {formatMessage({ id: "rewards.title" })}
      </Typography>

      <Grid container justifyContent="center">
        <CreateCampaignWidget
          handleToggleModal={() => handleToggleModal("Holidays")}
          title={formatMessage({ id: "rewards.titles.holidays" })}
          mainBgColor={theme.palette.campaigns.lightGreen}
          secondaryBgColor={theme.palette.campaigns.green}
          icon={<Icon.Holiday />}
        />

        <CreateCampaignWidget
          handleToggleModal={() => handleToggleModal("DoubleTriple")}
          title={formatMessage({ id: "rewards.titles.double_triple" })}
          mainBgColor={theme.palette.campaigns.yellow}
          secondaryBgColor={theme.palette.campaigns.darkYellow}
          icon={<Icon.DoubleTriple />}
        />

        <CreateCampaignWidget
          handleToggleModal={() => handleToggleModal("LowTraffic")}
          title={formatMessage({ id: "rewards.titles.low_traffic_days" })}
          mainBgColor={theme.palette.campaigns.purple}
          secondaryBgColor={theme.palette.campaigns.darkPurple}
          icon={<Icon.LowTrafficDays />}
        />

        <CreateCampaignWidget
          handleToggleModal={() => handleToggleModal("Surprise")}
          title={formatMessage({ id: "rewards.titles.surprise" })}
          mainBgColor={theme.palette.campaigns.blue}
          secondaryBgColor={theme.palette.campaigns.darkBlue}
          icon={<Icon.Surprise />}
        />

        <CreateCampaignWidget
          handleToggleModal={() => handleToggleModal("Product")}
          title={formatMessage({ id: "rewards.titles.product_service" })}
          mainBgColor={theme.palette.campaigns.red}
          secondaryBgColor={theme.palette.campaigns.darkRed}
          icon={<Icon.Product />}
        />

        {isSpecialCampaignV2Enabled && (
          <CreateCampaignWidget
            handleToggleModal={() => handleToggleModal("SpecialEvent")}
            title={formatMessage({ id: "rewards.titles.special_event" })}
            mainBgColor={theme.palette.campaigns.pink}
            secondaryBgColor={theme.palette.campaigns.darkPink}
            icon={<Icon.SpecialEvent />}
          />
        )}
      </Grid>

      {SelectedModalComponent && (
        <SelectedModalComponent active handlerModal={handleToggleModal} tykchaseGlobalConfig={tykchaseGlobalConfig} />
      )}
    </Grid>
  );
};

export default CreateCampaign;
