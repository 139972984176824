import { graphql } from "../../../../gql";

export const getPaymentMethodsQuery = graphql(`
  query GetPaymentMethods($userId: ID!) {
    getActivePaymentMethods(input: { userId: $userId }) {
      id
      brand
      owner
      expMonth
      expYear
      lastFour
      token
      isDefault
      userPaymentMethodId
      billingAddress {
        addressLine1
        addressLine2
        city
        state
        zipCode
        countryCode
      }
    }
  }
`);
