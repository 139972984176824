import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    qrCodesWrap: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      padding: `0 ${theme.spacing(6)} ${theme.spacing(5)}`,
      [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
        padding: 0,
      },
      "& .technologyContent": {
        flex: 1,
        padding: `${theme.spacing(3)} ${theme.spacing(6)}`,
        backgroundColor: theme.palette.technology.white,
        [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
          justifyContent: "center",
        },
        [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
          justifyContent: "center",
          padding: `${theme.spacing(3)} ${theme.spacing(3)}`,
        },
      },
      "& .devider": {
        margin: "0 65px 0 85px",
      },
      "& .total": {
        "@media(max-width: 1370px)": {
          display: "none",
        },
      },
      "& .MuiButton-root": {
        height: "40px",
        backgroundColor: theme.palette.technology.blue,
        borderRadius: "20px",
        boxShadow: "none",
        fontSize: "0.875rem",
        fontWeight: 700,
        textTransform: "none",
      },
      "& .MuiTypography-root": {
        fontFamily: theme.typography.fontFamily,
      },
      "& .MuiPaginationItem-root": {
        color: theme.palette.technology.grey2,
      },
    },
  };
});

export default useStyles;
