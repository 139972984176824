import { StyleSheet, Text, View } from "@react-pdf/renderer";

import { Style } from "@react-pdf/types";

const styles = StyleSheet.create({
  font: {
    fontFamily: "AdobeClean",
    fontSize: 13,
  },
  table: {
    width: "100%",
    flexDirection: "column",
    borderLeft: 0.5,
    borderRight: 0.5,
    borderColor: "#E9E9E9",
  },
  sectionWrapper: {
    width: "100%",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  tableHeadingBox: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#E9E9E9",
    height: 28,
  },
  headingLeftSection: {
    width: "46%",
    paddingLeft: 20,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  headingRightSection: {
    width: "56%",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  columnHeadingBox: {
    width: "25%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  dataSection: {
    width: "100%",
    backgroundColor: "#FFFFFF",
  },
  dataSectionRow: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dataSectionRowLeftSection: {
    width: "44%",
    padding: 10,
    paddingLeft: 20,
    paddingTop: 5,
    paddingBottom: 5,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  dataSectionRowRightSection: {
    width: "56%",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  dataSectionCell: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "25%",
    borderRight: 0.5,
    borderColor: "#E2E2E4",
    height: 35,
  },
  taxSection: {
    width: "100%",
    height: 40,
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    borderTop: 1,
    borderColor: "#E2E2E4",
  },
  taxSectionTaxCell: {
    width: "14%",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    height: "100%",
    paddingRight: 5,
    borderRight: 0.5,
    borderColor: "#E2E2E4",
  },
  taxSectionTaxAmountCell: {
    width: "14%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-end",
    height: "100%",
    borderLeft: 0.5,
    borderRight: 0.5,
    borderColor: "#E2E2E4",
  },
  headingTitleStyle: {
    color: "#878787",
    fontSize: 11,
  },
  tableContentBox: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "",
    height: 75,
  },
  tableEndBox: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    backgroundColor: "#E9E9E9",
    height: 28,
  },
  tableEndBoxTotal: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "14%",
    height: "100%",
    border: 1,
    borderColor: "#E2E2E4",
  },
  tableEndBoxTotalAmount: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "14%",
    height: "100%",
    border: 1,
    borderColor: "#E2E2E4",
  },
  totalText: {
    fontSize: 12,
    padding: 2,
    fontWeight: "bold",
  },
  columnTitleStyle: {
    color: "#878787",
    fontSize: 11,
  },
  rowTitleStyle: {
    color: "#161C2E",
    fontSize: 12,
  },
  cellTitleStyle: {
    color: "#333333",
    fontSize: 11,
  },
});

// NOTE!!!
// This is an untraditional file - this is NOT! a React component,
// although the syntax is incredibly similar to React Native
// This is a react-pdf components file
// The functions in this file reuse/modularize building a react-pdf component

export const createTable = (argsObj: TableArgsObject) => {
  const { sectionObjects: sections, tax, total } = argsObj;
  return (
    // THE ENTIRE TABLE
    <View style={[styles.table, styles.font]}>
      {sections.map((tableSection) => {
        return (
          // TABLE SECTIONS
          <View style={styles.sectionWrapper} key={tableSection.title}>
            {/* HEADING BOX */}
            <View style={styles.tableHeadingBox}>
              <View style={styles.headingLeftSection}>
                <Text style={tableSection.titleStyle || styles.headingTitleStyle}>{tableSection.title}</Text>
              </View>
              <View style={styles.headingRightSection}>
                {tableSection.columnTitles.map((columnTitle) => {
                  return (
                    // max of 4 columns, no matter how many, they're always the same size
                    <View style={styles.columnHeadingBox} key={columnTitle}>
                      <Text style={tableSection.columnTitleStyle || styles.columnTitleStyle}>{columnTitle}</Text>
                    </View>
                  );
                })}
              </View>
            </View>
            {/* DATA SECTION */}
            <View style={styles.dataSection}>
              {tableSection.rows?.map((row) => {
                return (
                  <View style={styles.dataSectionRow} key={row.title}>
                    <View style={styles.dataSectionRowLeftSection}>
                      <Text style={row.titleStyle || styles.rowTitleStyle}>{row.title}</Text>
                    </View>
                    <View style={styles.dataSectionRowRightSection}>
                      {row.data.map((cell) => {
                        return (
                          <View
                            key={`${row.title}-${cell.title}`}
                            style={[
                              styles.dataSectionCell,
                              {
                                borderLeft: row.data.length > 1 ? 0.5 : 0,
                              },
                            ]}
                          >
                            <Text style={cell.titleStyle || styles.cellTitleStyle}>{cell.title}</Text>
                          </View>
                        );
                      })}
                    </View>
                  </View>
                );
              })}
            </View>
          </View>
        );
      })}
      {/* TAX SECTION */}
      <View style={styles.taxSection}>
        <View style={styles.taxSectionTaxCell}>
          <Text>Tax</Text>
        </View>
        <View style={styles.taxSectionTaxAmountCell}>
          <Text>{tax}</Text>
        </View>
      </View>
      {/* END OF TABLE (TOTAL) */}
      <View style={styles.tableEndBox}>
        <View style={styles.tableEndBoxTotal}>
          <Text style={styles.totalText}>Total</Text>
        </View>
        <View style={styles.tableEndBoxTotalAmount}>
          <Text style={styles.totalText}>{total}</Text>
        </View>
      </View>
    </View>
  );
};

type TableArgsObject = {
  sectionObjects: Array<TableSectionObject>;
  tax: string;
  total: string;
};

type TableSectionObject = {
  title: string;
  titleStyle?: Style;

  // "table" section. Specify column titles and row data
  // data in rows must match # of columns
  columnTitles: Array<string>;
  columnTitleStyle?: Style;
  rows?: Array<TableRowObject>;
};

type TableRowObject = {
  title: string;
  titleStyle?: Style;
  data: Array<DataEntry>;
};

type DataEntry = {
  title: string;
  titleStyle?: Style;
};
