import { Event } from './types';

export const events: Event[] = [
  {
    id: '1',
    type: 'C',
    name: 'Standup Comedy Night',
    endDate: '15.07.2022',
  },
  {
    id: '2',
    type: 'C',
    name: 'Standup Comedy Night',
    endDate: '15.07.2022',
  },
  {
    id: '3',
    type: 'C',
    name: 'Local Band Concert',
    endDate: '10.08.2022',
  },
];
