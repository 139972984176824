import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    tooltipIcon: {
      width: "50px",
      height: "50px",
      "& path": {
        fill: "#FFF",
      },
    },
    tooltipText: {
      fontSize: "0.75rem",
      paddingLeft: theme.spacing(1),
    },
  };
});

export default useStyles;
