import { FC } from "react";
import { Button } from "@mui/material";
import { FormattedMessage } from "react-intl";
import FileExportIcon from "../../../assets/icons/menuIcons/file-export.svg";
import theme from "../../../theme";

const ExportButton: FC = () => {
  return (
    <Button
      sx={{
        fontSize: "0.875rem",
        color: theme.palette.analytics?.lightPurple,
        textTransform: "capitalize",
        textDecoration: "underline",
      }}
      startIcon={<img src={FileExportIcon} alt="" />}
    >
      <FormattedMessage id="btn_text_export" />
    </Button>
  );
};

export default ExportButton;
