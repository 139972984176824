import { Grid, InputBase } from '@mui/material';
import Modal from '../../../Modal';
import RuleToggle from '../../RuleToggle';
import Layout from './Layout';

const GeneralAutomateSettings = ({
  modalActive,
  handlerModal,
}: {
  modalActive: boolean;
  handlerModal: () => void;
}) => {
  return (
    <Modal isOpen={modalActive} onClose={handlerModal}>
      <Layout title="General Automate Settings">
        <Grid>
          <Grid mb="20px">
            <RuleToggle title="Release automatically every month on">
              <input type="date" defaultValue="2021-06-12" />
            </RuleToggle>
          </Grid>
          <Grid>
            <RuleToggle title="Release automatically when amount hits">
              <Grid minWidth="70px">
                <InputBase placeholder="000" type="number" />
              </Grid>
            </RuleToggle>
          </Grid>
        </Grid>
      </Layout>
    </Modal>
  );
};

export default GeneralAutomateSettings;
