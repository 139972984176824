import { FC, ReactNode } from "react";
import { Box, Divider, IconButton } from "@mui/material";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import { OpenIcon } from "../../../utils/icon";
import useStyles from "./styles";
import { MessageIds } from "../../../i18n";

interface AnalyticsCardProps {
  headerTitle: MessageIds;
  headerSubtitle: MessageIds;
  headerImage?: string;
  cardColor: string;
  dataLength: number;
  children: ReactNode;
  isExperiences?: boolean;
  withDivider?: boolean;
}

const AnalyticsCard: FC<AnalyticsCardProps> = ({
  headerTitle,
  headerSubtitle,
  headerImage,
  cardColor,
  dataLength,
  children,
  isExperiences = false,
  withDivider = true,
}) => {
  const { wrap } = useStyles();

  const getOpenIconColor = () => {
    if (!dataLength && !isExperiences) {
      return "#8986AD";
    }
    if (!dataLength && isExperiences) {
      return "#827164";
    }
    return "#0364FF";
  };

  return (
    <Box className={wrap} bgcolor={cardColor}>
      <Box className="cardHeader">
        <Box
          maxHeight="10px"
          height="10px"
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          {headerImage && <img src={headerImage} alt="" width="24" />}
        </Box>

        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="start" width="100%">
          <Box>
            <h3 className="cardHeaderTitle">
              <FormattedMessage id={headerTitle} />
            </h3>

            <span className="cardHeaderSubtitle">
              <FormattedMessage id={headerSubtitle} />
            </span>

            <span
              className={clsx("cardHeaderSubtitleCount", {
                active: dataLength,
                experiences: isExperiences,
              })}
            >
              {dataLength}
            </span>
          </Box>

          <IconButton disabled={!dataLength}>
            <OpenIcon fill={getOpenIconColor()} />
          </IconButton>
        </Box>
      </Box>

      {withDivider && <Divider className="cardDivider" />}

      <Box className="cardBody">{children}</Box>
    </Box>
  );
};

export default AnalyticsCard;
