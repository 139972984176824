import { Alert, Switch } from "@mui/material";
import clsx from "clsx";
import { useState } from "react";
import { Box } from "@mui/system";
import { FormattedMessage } from "react-intl";
import useStyles from "./styles";
import FooterTypes from "../Footer/types";
import { NUMBERS_ONLY_PATTERN } from "../../regexp";
import { PatternInput } from "../../../shared/PatternInput";

const Switchs = ({
  multipleRewardCountFieldProps,
  multipleRewardCountError,
  allowMultipleRewardProps,
  isSubmitting,
}: FooterTypes) => {
  const { wrap } = useStyles();

  const [active, setActive] = useState(true);
  return (
    <div className={wrap}>
      <div className="row">
        <Switch {...allowMultipleRewardProps} checked={allowMultipleRewardProps?.value} disabled={!!isSubmitting} />
        <FormattedMessage id="modals.allow_users_to_receive_rewards" />
      </div>
      {allowMultipleRewardProps?.value && (
        <Box sx={{ position: "relative" }}>
          <div
            className={clsx("row", {
              active,
            })}
          >
            <>
              <PatternInput
                {...multipleRewardCountFieldProps}
                pattern={NUMBERS_ONLY_PATTERN}
                placeholder="0"
                disabled={!!isSubmitting}
              />
              <FormattedMessage id="modals.rewards_to_same_client" />
            </>
          </div>
          {multipleRewardCountError && (
            <Alert severity="error" sx={{ position: "absolute", width: "100%", marginLeft: "10px" }}>
              {multipleRewardCountError}
            </Alert>
          )}
        </Box>
      )}
    </div>
  );
};

export default Switchs;
