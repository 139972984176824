import {
  FormControl,
  Grid,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import Icon from '../../../../../utils/icon';
import Modal from '../../../../Modal';
import Layout from '../Layout';
import useStyles from './styles';

const PromoterInfo = ({
  modalActive,
  handlerModal,
}: {
  modalActive: boolean;
  handlerModal: () => void;
}) => {
  const { wrap, wrapSelect } = useStyles();
  return (
    <Modal isOpen={modalActive} onClose={handlerModal}>
      <Layout removeBtn title={<FormattedMessage id="modals.promoter.promoter_info"/>}>
        <Grid
          className={wrap}
          flexWrap="nowrap"
          flexDirection={{ xs: 'column', md: 'row' }}
          container
          p={{ xs: '0', sm: '0 50px', md: '0' }}
        >
          <Grid
            height="100px"
            width="100px"
            m={{ xs: '0 auto 20px', md: '0 30px 0 0' }}
            overflow="hidden"
            borderRadius="100px"
          >
            <Icon.HappyGirls />
          </Grid>

          <Grid
            width="100%"
            maxWidth={{ xs: '210px', md: '301px' }}
            m={{ xs: '0 auto 20px', md: '0 30px 0 0' }}
          >
            <Grid mb="30px">
              <Typography>Tyket user account</Typography>
              <InputBase placeholder="steve@tyket.com" />
            </Grid>

            <Grid mb="30px">
              <Typography>Employee ID</Typography>
              <InputBase placeholder="0957849" />
            </Grid>

            <Grid className={wrapSelect}>
              <FormControl>
                <InputLabel id="demo-simple-select-label">
                  Select a franchise
                </InputLabel>
                <Select defaultValue="">
                  <MenuItem value="Weekly">Weekly</MenuItem>
                  <MenuItem value="Monthly">Monthly</MenuItem>
                  <MenuItem value="Every 3 months">Every 3 months</MenuItem>
                  <MenuItem value="Yearly">Yearly</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid
            width="100%"
            maxWidth={{ xs: '210px', md: '301px' }}
            m={{ xs: '0 auto', md: '0' }}
          >
            <Typography>Promoter name</Typography>
            <InputBase placeholder="Steve Balmer" />
          </Grid>
        </Grid>
      </Layout>
    </Modal>
  );
};

export default PromoterInfo;
