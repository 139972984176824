import { FC } from "react";
import { MenuItem, Select, FormControl, InputLabel, Alert } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { PurchaseRewardRate } from "../../../../../generated/types";
import { PatternInput } from "../../../../shared/PatternInput";
import { NUMBERS_ONLY_PATTERN } from "../../../regexp";
import useStyles from "./styles";
import { RewardRuleModalSubHeaderProps } from "../RewardRuleModalSubHeaders.interfaces";

export const FrequentCustomerRewardsSubHeader: FC<RewardRuleModalSubHeaderProps> = ({ formik }) => {
  const purchaseRewardRateFieldProps = formik.getFieldProps("purchaseRewardRate");
  const rewardOnPurchaseCountFieldProps = formik.getFieldProps("rewardOnPurchaseCount");
  const rewardOnPurchaseCountError = formik.touched.rewardOnPurchaseCount ? formik.errors.rewardOnPurchaseCount : "";
  const { isSubmitting } = formik;

  const { wrap } = useStyles();
  return (
    <div className={wrap}>
      <div className="left">
        <p>
          <FormattedMessage id="modals.earn_tyks_header.title" />
        </p>
        <FormControl disabled={isSubmitting}>
          <InputLabel id="demo-simple-select-label">
            <FormattedMessage id="modals.earn_tyks_header.title" />
          </InputLabel>
          <Select {...purchaseRewardRateFieldProps}>
            <MenuItem value={PurchaseRewardRate.Fixed}>
              <FormattedMessage id="modals.earn_tyks_header.fixed_amount" />
            </MenuItem>
            <MenuItem value={PurchaseRewardRate.ByPercentage}>
              <FormattedMessage id="modals.earn_tyks_header.purchase_percentage" />
            </MenuItem>
          </Select>
        </FormControl>
      </div>

      <div className="right">
        <div className="purchaseInput">
          <p>
            <FormattedMessage id="modals.earn_tyks_header.reward" />
          </p>
          <PatternInput
            {...rewardOnPurchaseCountFieldProps}
            pattern={NUMBERS_ONLY_PATTERN}
            className="input"
            placeholder="0"
            disabled={isSubmitting}
          />
        </div>
        {rewardOnPurchaseCountError && (
          <Alert severity="error" sx={{ position: "absolute", width: "100%" }}>
            {rewardOnPurchaseCountError}
          </Alert>
        )}
      </div>
    </div>
  );
};
