import { InputBase } from "@mui/material";
import { PatternInputProps } from "./types";

export const PatternInput = (props: PatternInputProps) => {
  return (
    <InputBase
      {...props}
      value={props.value ?? ""}
      onChange={(e) => {
        if (props.pattern.test(e.target.value) || !e.target.value) {
          props?.onChange?.(e);
        } else {
          e.preventDefault();
        }
      }}
    />
  );
};
