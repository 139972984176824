import { InputBase, Switch } from "@mui/material";
import clsx from "clsx";
import { useState } from "react";
import useStyles from "./styles";
import { ReactComponent as RewardsIcon } from "../../../../../assets/icons/tykIcons/rewardsIcon.svg";
import { ReactComponent as Exclamation } from "../../../../../assets/icons/tykIcons/exclamation.svg";
import { ReactComponent as Usd } from "../../../../../assets/icons/tykIcons/usd.svg";
import RewardsIconYelow from "../../../../../assets/icons/tykIcons/rewardsIconYelow.png";
import Jackpot from "../../../../../assets/icons/tykIcons/Jackpot-Icon(1)@2x.png";
import Cashier from "../../../../../assets/icons/tykIcons/cashier-icon@2x.png";
import DiagramItem from "./DiagramItem";
import {
  TotalDistributionAwardComponent,
  TotalDistributionCashierComponent,
  TotalDistributionCentorsComponent,
  TotalDistributionJackpotComponent,
  TotalDistributionLoneStarsComponent,
} from "../../Diagram/functionalityComponents";
import { ApplicationFeatures } from "../../../../../types/applicationFeaturesEnum";

interface DiagramData {
  isPercentage?: boolean;
  setRewardDistributionData: (arg) => void;
  getInputsValue: any;
}

const Diagram = ({ isPercentage, setRewardDistributionData, getInputsValue }: DiagramData) => {
  const { container, diagramWrap, equivalent, split } = useStyles();
  const [isFlipProcentageItem, setIsFlipProcentageItem] = useState(false);
  const handlerProcentage = () => {
    setIsFlipProcentageItem(!isFlipProcentageItem);
  };
  const [valuesState, setValuesState] = useState({
    centorsRate: getInputsValue("centorsRate"),
    jackpotAwardRate: getInputsValue("jackpotAwardRate"),
    loneStarsRate: getInputsValue("loneStarsRate"),
    minimumLoneStarAward: getInputsValue("minimumLoneStarAward"),
    promoterAwardRate: getInputsValue("promoterAwardRate"),
  });

  const [switchesState, setSwitchesState] = useState({
    centorsRate: false,
    jackpotAwardRate: false,
    loneStarsRate: false,
    minimumLoneStarAward: false,
    promoterAwardRate: false,
  });

  const handleChangeCreator = (feautureId, inputName) => (e) => {
    setRewardDistributionData((prevState) => {
      const isFeatureExist = prevState.ids.includes(feautureId);
      return {
        ...prevState,
        values: {
          ...prevState.values,
          [inputName]: e.target.value,
        },
        ...(isFeatureExist || {
          ids: [...prevState.ids, feautureId],
        }),
      };
    });
    setValuesState((prevState) => {
      return {
        ...prevState,
        [inputName]: e.target.value,
      };
    });
  };

  const handleToogle = (feautureId, inputName) => async (event) => {
    setSwitchesState((prevState) => {
      return {
        ...prevState,
        [inputName]: event.target.checked,
      };
    });
    if (!event.target.checked) {
      const newEvent = { target: { value: "" } };
      handleChangeCreator(feautureId, inputName)(newEvent);
    }
  };

  return (
    <div className={container}>
      <div className={clsx(diagramWrap)}>
        <DiagramItem
          secondaryClassName="defaultItem first"
          objectClassNames={{
            isFlipProcentageItem,
          }}
          isPercentage={isPercentage}
          isFlipProcentageItem={isFlipProcentageItem}
          onClick={handlerProcentage}
          onKeyPress={() => {}}
          role="button"
          tabIndex={0}
        >
          <>
            <div className="itemTop">{isFlipProcentageItem && <Exclamation />}</div>

            <div className="itemMiddle">
              <InputBase
                placeholder="50"
                type="number"
                inputProps={{ min: "0" }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
              <Usd className="rewardsIcon" />
            </div>

            <div className="itemBottom">{isFlipProcentageItem || <p>Purchase Percentage</p>}</div>
          </>
        </DiagramItem>
        <div className={equivalent}>
          <span />
          <span />
          <span />
        </div>

        <div className={split}>
          <div className="splitTip">
            <p>Customer reward split</p>
          </div>

          <div className="splitTop">
            <div className="splitTopParent" />
          </div>

          <div className="splitMidle">
            <TotalDistributionCentorsComponent
              secondaryClassName="splitItem first"
              objectClassNames={{
                isPercentage,
              }}
            >
              <>
                <div className="itemTop">
                  <Exclamation />

                  <Switch onChange={handleToogle(ApplicationFeatures.TotalDistributionCentors, "centorsRate")} />
                </div>

                <div className="itemMiddle">
                  <RewardsIcon className="rewardsIcon" />
                  <InputBase
                    placeholder={valuesState.centorsRate}
                    type="number"
                    inputProps={{ min: "0" }}
                    value={valuesState.centorsRate}
                    disabled={!switchesState.centorsRate}
                    onChange={handleChangeCreator(ApplicationFeatures.TotalDistributionCentors, "centorsRate")}
                  />
                </div>

                <div className="itemBottom">
                  <p>Centors</p>
                </div>
              </>
            </TotalDistributionCentorsComponent>

            <TotalDistributionLoneStarsComponent
              secondaryClassName="splitItem last"
              objectClassNames={{
                isPercentage,
              }}
            >
              <>
                <div className="itemTop">
                  <Exclamation />

                  <Switch onChange={handleToogle(ApplicationFeatures.TotalDistributionLoneStars, "loneStarsRate")} />
                </div>

                <div className="itemMiddle">
                  <img src={RewardsIconYelow} alt="icon" />
                  <InputBase
                    placeholder={valuesState.loneStarsRate}
                    type="number"
                    inputProps={{ min: "0" }}
                    disabled={!switchesState.loneStarsRate}
                    value={valuesState.loneStarsRate}
                    onChange={handleChangeCreator(ApplicationFeatures.TotalDistributionLoneStars, "loneStarsRate")}
                  />
                </div>

                <div className="itemBottom">
                  <p>Lone Stars</p>
                </div>
              </>
            </TotalDistributionLoneStarsComponent>
          </div>
        </div>
        <TotalDistributionJackpotComponent
          objectClassNames={{
            isPercentage,
          }}
        >
          <>
            <div className="itemTop">
              <Exclamation />

              <Switch onChange={handleToogle(ApplicationFeatures.TotalDistributionJackpot, "jackpotAwardRate")} />
            </div>

            <div className="itemMiddle">
              <img src={Jackpot} alt="icon" />
              <InputBase
                placeholder={valuesState.jackpotAwardRate}
                type="number"
                inputProps={{ min: "0" }}
                value={valuesState.jackpotAwardRate}
                disabled={!switchesState.jackpotAwardRate}
                onChange={handleChangeCreator(ApplicationFeatures.TotalDistributionJackpot, "jackpotAwardRate")}
              />
            </div>

            <div className="itemBottom">
              <p>Jackpot</p>
            </div>
          </>
        </TotalDistributionJackpotComponent>

        <TotalDistributionCashierComponent
          secondaryClassName="last"
          objectClassNames={{
            isPercentage,
          }}
        >
          <>
            <div className="itemTop">
              <Exclamation />

              <Switch onChange={handleToogle(ApplicationFeatures.TotalDistributionCashier, "promoterAwardRate")} />
            </div>

            <div className="itemMiddle">
              <img src={Cashier} alt="icon" />
              <InputBase
                placeholder={valuesState.promoterAwardRate}
                type="number"
                inputProps={{ min: "0" }}
                value={valuesState.promoterAwardRate}
                disabled={!switchesState.promoterAwardRate}
                onChange={handleChangeCreator(ApplicationFeatures.TotalDistributionCashier, "promoterAwardRate")}
              />
            </div>

            <div className="itemBottom">
              <p>Cashier</p>
            </div>
          </>
        </TotalDistributionCashierComponent>
      </div>

      <TotalDistributionAwardComponent
        onChange={handleChangeCreator(ApplicationFeatures.TotalDistributionMinLoneStars, "minimumLoneStarAward")}
        getInputsValue={valuesState.minimumLoneStarAward}
      />
    </div>
  );
};

export default Diagram;
