import { MessageIds } from "../../i18n";

export type Campaign = {
  id: string;
  name: string;
  image: string;
  views?: number;
  clicks?: number;
  spent: number;
  reward?: number;
};

export enum TimeFrame {
  ThisMonth = "This month",
  LastThreeMonths = "Last 3 months",
  LastYear = "Last year to date",
  CustomRange = "Custom range",
}

export interface TimeFrameSelectItem {
  value: TimeFrame;
  label: MessageIds;
}

export type Values = Record<string, string | number>;
