import { graphql } from "../../../../gql";

export const getRewardRulesConfigurationQuery = graphql(`
  query getRewardRulesConfigurationQuery($userId: String!) {
    getRewardRulesConfiguration(userId: $userId) {
      payload {
        monthlyRewardLimit
        customTykAwardsDistribution {
          jackpotAwardRate
          promoterAwardRate
          centorsRate
          loneStarsRate
          minimumLoneStarAward
          maximumLoneStarPerEvent
          maximumLoneStarRateOnDemand
        }
        tykchaseRuleStatus {
          id
          title
        }
        activeRules {
          id
          loneStarReward
          centorReward
          dollarReward
          period
          purchaseRewardRate
          minPurchaseForReward
          rewardOnceHitLimit
          rewardOnPurchaseCount
          limitFriendsInvitation
          friendsInvitationsLimit
          allowMultipleReward
          multipleRewardCount
          rule {
            id
            title
            internalId
            isStagewoodPerk
            childRule {
              id
              title
              ruleName
            }
            category {
              id
              title
            }
          }
          status {
            id
            title
          }
        }
        membershipRules {
          id
          internalId
          title
          ruleName
          isStagewoodPerk
          parentRule {
            id
            title
            ruleName
          }
          category {
            id
            title
          }
        }
        tykchaseGlobalConfig {
          id
          dollarPerTykcoinRate
          centorPerDollarRate
          loneStarPerDollarRate
          loneStarOnDemandPerDollarRate
          centorPurchaseByTykcoinRate
          tykAwardsDistribution {
            jackpotAwardRate
            promoterAwardRate
            centorsRate
            loneStarsRate
            minimumLoneStarAward
            maximumLoneStarPerEvent
            maximumLoneStarRateOnDemand
            isPrincipal
          }
        }
      }
      status
      message
    }
  }
`);
