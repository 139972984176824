import { FC, MouseEvent, ChangeEvent, useState, useEffect, useCallback } from "react";
import { Box, Grid, TablePagination, TableSortLabel } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { useQuery } from "@apollo/client";
import { useUser } from "@stagewood/unified-login-library";
import { ReactComponent as EmptyDataIcon } from "../../../assets/icons/tykIcons/emptyData.svg";

import { TransactionsHistory, QueryFetchUserTransactionsHistoryArgs } from "../../../generated/types";

import { useStyles } from "./styles";
import Payment from "../Payment";
import { getUserTransactionsHistoryQuery } from "../graphql/GetUserTransactionsHistory.query";
import { DEFAULT_ITEMS_LIMIT, PAGINATION_OPTIONS } from "./Billing.consts";
import { BillingEntry } from "../billing-entry/billing-entry.component";
import { FeatureFlagEnum } from "../../../types/contexts";
import { useFeatureFlagValue } from "../../../hooks";
import { usePaginationQueryParams } from "../../../hooks/usePaginationQueryParams.hook";
import { PageLoader } from "../../PageLoader";
import { BillingOrderByFieldEnum, BillingOrderEnum, BillingProps } from "./Billing.interfaces";
import { getComparator } from "./Billing.helpers";

export const Billing: FC<BillingProps> = ({ isSubscribed }) => {
  const user = useUser();
  const styles = useStyles();
  const isYourPaymentMethodAtBillingPageEnabled = useFeatureFlagValue(
    FeatureFlagEnum.YOUR_PAYMENT_METHOD_AT_BILLING_ENABLED,
  );
  const { limit, offset, setOffset, setLimitAndOffset } = usePaginationQueryParams({
    defaultLimit: DEFAULT_ITEMS_LIMIT,
  });
  const [order, setOrder] = useState<BillingOrderEnum>(BillingOrderEnum.DESC);
  const [orderBy, setOrderBy] = useState<BillingOrderByFieldEnum>(BillingOrderByFieldEnum.CREATED_AT);
  const [transactions, setTransactions] = useState<TransactionsHistory[]>([]);

  const transactionsQuery = useQuery(getUserTransactionsHistoryQuery, {
    variables: {
      ownerId: user?.id,
      limit,
      offset,
      order,
      orderBy,
    } as QueryFetchUserTransactionsHistoryArgs,
  });

  useEffect(() => {
    if (transactionsQuery.data?.fetchUserTransactionsHistory?.payload) {
      setTransactions(transactionsQuery.data?.fetchUserTransactionsHistory?.payload as TransactionsHistory[]);
    }
  }, [transactionsQuery.data?.fetchUserTransactionsHistory?.payload]);

  const handlePageChange = useCallback(
    (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setOffset((limit || DEFAULT_ITEMS_LIMIT) * newPage);
    },
    [limit, setOffset],
  );
  const handleRowsPerPageChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setLimitAndOffset(Number(event.target.value), 0);
    },
    [setLimitAndOffset],
  );
  const createSortHandler = useCallback(
    (property: BillingOrderByFieldEnum) => {
      const isAsc = orderBy === property && order === BillingOrderEnum.ASC;

      if (transactions.length > 0) {
        setTransactions(
          [...transactions].sort(getComparator(isAsc ? BillingOrderEnum.DESC : BillingOrderEnum.ASC, property)),
        );
      }
      setOrderBy(property);
      setOrder(isAsc ? BillingOrderEnum.DESC : BillingOrderEnum.ASC);
    },
    [order, orderBy, transactions],
  );
  const handleSortByCreatedAt = useCallback(
    () => createSortHandler(BillingOrderByFieldEnum.CREATED_AT),
    [createSortHandler],
  );

  const isVisibleTable = transactions.length > 0 && !transactionsQuery.loading && !transactionsQuery.error;

  return (
    <>
      <Grid container direction="column" className={styles.mainWrap} item>
        <Grid container item direction="row" spacing={{ xl: 5, md: 4 }} className={styles.topWrap}>
          {isYourPaymentMethodAtBillingPageEnabled && <Payment />}
        </Grid>
        <Grid item mt={{ xs: 4 }} className={styles.bottomWrap}>
          <Grid container item direction="column" alignItems="flex-end">
            <Grid item className={styles.table}>
              <Box
                className="tableHead"
                display="grid"
                gridTemplateColumns="minmax(150px, 3fr) minmax(90px, 2fr) minmax(60px ,1fr) minmax(40px ,1fr)"
              >
                <Box>
                  <FormattedMessage id="billing.invoices" />
                </Box>
                <Box>
                  <TableSortLabel
                    active={orderBy === BillingOrderByFieldEnum.CREATED_AT}
                    direction={orderBy === BillingOrderByFieldEnum.CREATED_AT ? order : BillingOrderEnum.ASC}
                    onClick={handleSortByCreatedAt}
                    style={{ cursor: isSubscribed ? "pointer" : "default" }}
                    disabled={transactions.length === 0}
                  >
                    <FormattedMessage id="billing.date" />
                  </TableSortLabel>
                </Box>
                <Box>
                  <FormattedMessage id="billing.amount" />
                </Box>
                <Box>
                  <FormattedMessage id="billing.actions" />
                </Box>
              </Box>
              {isVisibleTable && (
                <Box className="tableBody">
                  {transactions.map((item) => (
                    <BillingEntry key={item.cartHistoryId} item={item} />
                  ))}
                </Box>
              )}
              {transactions.length === 0 && !transactionsQuery.loading && !transactionsQuery.error && (
                <Box className="tableBody">
                  <Box className={styles.emptyData}>
                    <Grid>
                      <EmptyDataIcon />
                    </Grid>
                    <Grid>
                      <FormattedMessage id="billing_transactions_empty" />
                    </Grid>
                  </Box>
                </Box>
              )}
            </Grid>
            {isVisibleTable && (
              <Grid item className={styles.pagination} mt={{ xs: 3 }}>
                <table>
                  <tfoot>
                    <tr>
                      <TablePagination
                        SelectProps={{
                          disabled: !isSubscribed,
                        }}
                        page={offset && limit ? Math.ceil(offset / limit) : 0}
                        count={
                          transactionsQuery.data?.fetchUserTransactionsHistory?.pagination.total ??
                          limit ??
                          DEFAULT_ITEMS_LIMIT
                        }
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleRowsPerPageChange}
                        rowsPerPage={limit ?? DEFAULT_ITEMS_LIMIT}
                        rowsPerPageOptions={PAGINATION_OPTIONS}
                        labelRowsPerPage={<FormattedMessage id="items_per_page" />}
                        showFirstButton
                        showLastButton
                      />
                    </tr>
                  </tfoot>
                </table>
              </Grid>
            )}
          </Grid>
        </Grid>
        {transactionsQuery.loading && <PageLoader />}
      </Grid>
    </>
  );
};
