import { graphql } from "../../../gql";

export const getUserTransactionsHistoryQuery = graphql(`
  query fetchUserTransactionsHistory($ownerId: String!, $limit: Int, $offset: Int, $orderBy: String, $order: String) {
    fetchUserTransactionsHistory(ownerId: $ownerId, limit: $limit, offset: $offset, orderBy: $orderBy, order: $order) {
      payload {
        id
        cartHistoryId
        cartHistory {
          id
          ownerId
          userPaymentMethodId
          title
          metadata
          createdAt
          taxInCents
          cartLineItemsHistory {
            id
            priceInCents
            taxInCents
            title
            metadata
          }
        }
      }
      status
      message
      errorMessageId
      pagination {
        count
        total
      }
    }
  }
`);
