import { graphql } from "../../../gql";

export const completeCartMutation = graphql(`
  mutation CompleteCart($cartId: String!) {
    completeCart(input: { id: $cartId }) {
      status
      errorMessageId
      payload {
        id
        status
      }
    }
  }
`);
