import { graphql } from "../../../gql";

export const getAccountAddressesQuery = graphql(`
  query GetAccountAddresses {
    getAccount {
      payload {
        accountAddresses {
          id
          addressCity
          addressStreet1
          zipCode
          label
        }
      }
    }
  }
`);
