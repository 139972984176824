import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  pageRoot: {
    minHeight: "100vh",
    backgroundColor: "#2F3E4D",
    color: "#FAFAFA",
  },
  container: {
    maxWidth: "1210px",
    margin: "99px auto 0",
  },
  title: {
    fontSize: "28px",
    fontWeight: "600",
    letterSpacing: "0px",
  },
  description: {
    marginTop: "21px",
    fontSize: "18px",
    letterSpacing: "0px",
    fontWeight: "300",
    maxWidth: "515px",
  },
  businessIcon: {
    marginLeft: "60px",
    maxHeight: "170px",
    [theme.breakpoints.down("md")]: {
      marginLeft: "0px",
    },
  },
  step: {
    "& .step-icon_container": {
      justifyContent: "flex-start",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
      },
    },
    "& .step-icon": {
      [theme.breakpoints.down("sm")]: {
        height: "60px",
        width: "50px",
      },
    },
    "& .index": {
      height: "84px",
      width: "84px",
      borderRadius: "50%",
      color: "#2B2B2B",
      backgroundColor: "#FDD400",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontWeight: 600,
      fontSize: "2rem",
      marginRight: "25px",
      [theme.breakpoints.down("sm")]: {
        height: "60px",
        width: "60px",
      },
    },
  },
  buttonProfileSelection: {
    "&.MuiButton-root": {
      textTransform: "none",
      width: "208px",
      height: "40px",
      fontSize: "14px",
      color: "#2F3E4D",
      borderRadius: "20px",
      backgroundColor: "#C9D7F4",
      fontWeight: "600",
      letterSpacing: "0px",
    },
  },
  stepName: {
    color: "#D3D6D9",
    fontSize: "18px",
  },
  buttonContainer: {
    marginTop: "30px",
  },
  westIcon: {
    width: "14px",
    height: "14px",
    marginRight: "7px",
    fontWeight: "600",
  },
  businessSection: {
    marginTop: "83px",
    [theme.breakpoints.down("sm")]: {
      "& svg": {
        width: "30%",
      },
    },
    [theme.breakpoints.down("xs")]: {
      position: "relative",
      zIndex: "1",
      "& .MuiGrid-root": {
        zIndex: "1",
      },
      "& svg": {
        position: "absolute",
        zIndex: "-1",
        bottom: "-4rem",
        right: "-1rem",
        height: "120%",
        width: "120px",
      },
    },
  },
}));

export default useStyles;
