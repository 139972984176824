import { useMutation } from "@apollo/client";
import { parse, stringify } from "qs";
import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useBusinessProfile } from "../../hooks/useBusinessProfile.hook";
import { createSubscriptionPaymentMethodUpdateCartMutation } from "./graphql/CreateSubscriptionPaymentMethodUpdateCart.mutation";

export const useCartCheckoutUrl = (): [
  checkoutUrl: string,
  isLoading: boolean,
  isLoaded: boolean,
  fetchCart: (addressId: string) => void,
] => {
  const [businessProfile] = useBusinessProfile();
  const businessId = businessProfile?.id;
  const [createCart, { loading, data }] = useMutation(createSubscriptionPaymentMethodUpdateCartMutation);
  const { search } = useLocation();
  const { redirect } = parse(search, { ignoreQueryPrefix: true });
  const checkoutUrl = `/checkout/${data?.createSubscriptionPaymentMethodUpdateCart?.payload?.id}${stringify(
    {
      successPageUrl: redirect,
    },
    { addQueryPrefix: true },
  )}`;

  const fetchCart = useCallback(
    (addressId: string) => {
      if (businessId) {
        createCart({
          variables: {
            businessId,
            addressId,
          },
        });
      }
    },
    [businessId, createCart],
  );

  return [checkoutUrl, loading, !!data, fetchCart];
};
