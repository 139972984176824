import { isPlainObject } from "./isPlainObject.util";

type OmitNull<T> = T extends infer V | null ? ([V] extends [null] ? never : V) : T;

type OmitEmptyValues<T extends object> = {
  [K in keyof T]?: T[K] extends object ? OmitEmptyValues<T[K]> : OmitNull<T[K]>;
};

export const omitEmptyValues = <T extends object>(object: T): OmitEmptyValues<T> => {
  return Object.entries(object).reduce((filteredObject, [key, value]) => {
    if (value) {
      return {
        ...filteredObject,
        [key]: isPlainObject(value) ? omitEmptyValues(value) : value,
      };
    }

    return value;
  }, {});
};
