import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    rootContainer: {
      backgroundColor: theme.palette.technology.light,
      minHeight: "100vh",
      paddingTop: 0,
    },
  };
});

export default useStyles;
