import { Grid, Typography } from "@mui/material";
import clsx from "clsx";
import { useState, useEffect } from "react";
import { useTheme } from "@mui/styles";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import useStyles from "./styles";
import Icon from "../../../utils/icon";
import { membershipFeatures } from "./types";
import { useBusinessProfile } from "../../../hooks/useBusinessProfile.hook";

const withMembershipFunctionality = function wrappedWithMembershipFunctionality(WrappedComponent, functionalityId) {
  return function WithMembershipFunctionality(props) {
    const theme = useTheme();
    const history = useHistory();
    const { wrap } = useStyles();
    const [activePopup, setActivePopup] = useState(false);

    const [businessProfile] = useBusinessProfile();

    const handlePopup = () => {
      setActivePopup(true);
      setTimeout(() => {
        setActivePopup(false);
      }, 3000);
    };

    const isFeatureAvailable = () => {
      return membershipFeatures.some((membership) => {
        return membership.title === businessProfile?.membership?.title && membership.features.includes(functionalityId);
      });
    };

    useEffect(() => {
      return () => setActivePopup(false);
    }, []);

    return (
      <Grid
        className={clsx(wrap, {
          notAvailable: !isFeatureAvailable(),
        })}
        onMouseEnter={handlePopup}
        onKeyPress={() => {}}
        role="button"
        tabIndex={0}
      >
        {isFeatureAvailable() || (
          <Grid>
            <Icon.Locked
              className={clsx("lockedIcon", {
                notAvailable: !isFeatureAvailable(),
              })}
            />
            <Grid
              className={clsx("popup", {
                active: activePopup,
              })}
              height={{ xs: "100px", lg: "60px" }}
              width={{ xs: "220px", lg: "320px" }}
            >
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                height="30px"
                width="30px"
                mr="10px"
                borderRadius="100px"
                bgcolor={theme.palette.analytics?.darkYellow}
              >
                <PriorityHighIcon />
              </Grid>
              <Typography width="80%" fontSize="0.75rem">
                <FormattedMessage id="hocs.feature_not_available" />
                <span
                  className="more"
                  onClick={() => history.push("/Memberships")}
                  onKeyPress={() => {}}
                  role="button"
                  tabIndex={0}
                >
                  <FormattedMessage id="hocs.upgrade" />
                </span>
              </Typography>
            </Grid>
          </Grid>
        )}

        <Grid
          className={clsx("wrapWrappedComponent", {
            notAvailable: !isFeatureAvailable(),
          })}
        >
          <WrappedComponent isFeatureAvailable={isFeatureAvailable()} {...props} />
        </Grid>
      </Grid>
    );
  };
};

export default withMembershipFunctionality;
