import { useLazyQuery } from "@apollo/client";
import { parse } from "qs";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getBusinessProfileInfoRequest } from "../../../../graphql/GetBusinessProfileInfo.query";
import { SUCCESS_PAYMENT_METHOD_UPDATE_PARAM } from "../../TykChaseMembershipNotification.consts";

export const useRefetchBusinessProfile = (userId: string) => {
  const { pathname, search } = useLocation();
  const { replace } = useHistory();
  const { [SUCCESS_PAYMENT_METHOD_UPDATE_PARAM]: shouldRefetchBusinessProfile } =
    parse(search, {
      ignoreQueryPrefix: true,
    }) ?? {};

  const [refetchBusinessProfile, { loading: businessProfileLoading }] = useLazyQuery(getBusinessProfileInfoRequest, {
    variables: {
      userId,
    },
    fetchPolicy: "network-only",
    onCompleted: () => {
      replace(pathname);
    },
  });

  useEffect(() => {
    if (shouldRefetchBusinessProfile && !businessProfileLoading) {
      refetchBusinessProfile();
    }
  }, [refetchBusinessProfile, businessProfileLoading, shouldRefetchBusinessProfile]);
};
