import { Grid, Typography, Switch } from "@mui/material";
import { ReactChild, useState } from "react";
import { useTheme } from "@mui/styles";
import clsx from "clsx";
import useStyles from "./styles";

const RuleToggle = ({
  title,
  children,
  w,
  mb,
}: {
  title?: string;
  children?: ReactChild;
  w?: string;
  mb?: string;
}) => {
  const theme = useTheme();
  const { wrap } = useStyles();
  const [active, setActive] = useState(false);
  return (
    <Grid
      className={clsx(wrap, {
        active,
      })}
      container
      alignItems="center"
      justifyContent="space-between"
      flexWrap="nowrap"
      maxWidth={w}
      width="100%"
      p={{ xs: "10px", lg: "15px 35px" }}
      mb={mb}
      borderRadius="6px"
      bgcolor={theme.palette.analytics?.lightGrey}
      border={`1px solid${theme.palette.diagram?.purple}`}
    >
      <Typography mr="15px" color={theme.palette.analytics?.grey}>
        {title}
      </Typography>
      <Grid container flexWrap="nowrap" alignItems="center" width="max-content">
        <Grid mr="25px">{children}</Grid>

        <Switch onChange={() => setActive(!active)} />
      </Grid>
    </Grid>
  );
};

export default RuleToggle;
