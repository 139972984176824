import { Grid, Button, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useUser } from "@stagewood/unified-login-library";
import { FormattedMessage } from "react-intl";
import { useStyles } from "./styles";
import success from "../../../assets/images/HandsPhone.png";
import { Routes } from "../../../constants";
import { getBusinessProfileInfoRequest } from "../../../graphql/GetBusinessProfileInfo.query";

const PaymentSuccess = () => {
  const { container, setupRewardsBtn } = useStyles();
  const history = useHistory();
  const user = useUser();

  useQuery(getBusinessProfileInfoRequest, {
    variables: { userId: user?.id ?? "" },
    skip: !user?.id,
    fetchPolicy: "network-only",
  });

  const toYourRewards = () => {
    history.push(Routes.YOUR_REWARDS);
  };

  return (
    <Grid mt={{ sm: 24 }} mx={{ sm: 5, md: 9 }} container className={container}>
      <Grid item sm={2}>
        <img
          style={{
            height: "100%",
            width: "100%",
            maxWidth: "220px",
            objectFit: "contain",
          }}
          src={success}
          alt="payment success"
        />
      </Grid>
      <Grid
        item
        md={7}
        flexDirection="column"
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        ml={{ sm: 5 }}
      >
        <Typography color="#34305D" fontWeight={600} fontSize="1.5rem">
          <FormattedMessage id="membership.congratulations_first_tykchase_payment" />
        </Typography>
        <Typography color="#615F7A" fontSize="1.2rem" mt={{ sm: 1 }} mb={{ sm: 2 }}>
          <FormattedMessage id="membership.continue_boosting_your_business" />
        </Typography>
        <Button className={setupRewardsBtn} variant="contained" onClick={toYourRewards}>
          <FormattedMessage id="membership.setup_rewards" />
        </Button>
      </Grid>
    </Grid>
  );
};

export default PaymentSuccess;
