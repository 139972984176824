import { FC } from "react";
import { FormikProps } from "formik";
import { Alert, Grid, Input, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import useStyles from "../../styles";

import Icon from "../../../../../../../utils/icon";

import { TriggerStepFormikValues, StepsFormikValues } from "../../../../../../../types/campaigns";

type Props = {
  formik: FormikProps<StepsFormikValues>;
};

const ReceiptTotalTrigger: FC<Props> = ({ formik }: Props) => {
  const theme = useTheme();
  const { wrap, fieldWrapper } = useStyles();

  return (
    <Grid
      className={wrap}
      container
      justifyContent={{ xs: "center", md: "start" }}
      alignItems="center"
      flexWrap="nowrap"
      mt={{ sm: "70px" }}
      pl={{ md: "100px" }}
      flexDirection={{ xs: "column", sm: "row" }}
    >
      <Grid
        container
        flexWrap="nowrap"
        width="max-content"
        alignItems={{ xs: "center", sm: "end" }}
        pb={{ sm: "55px" }}
        flexDirection={{ xs: "column", sm: "row" }}
      >
        <Icon.ReceiptTrigger mr="8px" />

        <Typography
          mr={{ sm: "20px" }}
          mt={{ sm: "70px" }}
          mb={{ sm: "8px" }}
          fontSize="1.063rem"
          color={theme.palette.primary.dark}
        >
          <FormattedMessage id="modals.triggers.receipt_trigger" />
        </Typography>
      </Grid>

      <Grid maxWidth="200px" mt={{ xs: "10px", sm: "0" }} className={fieldWrapper}>
        <Grid height="70px" className={!formik.errors.receiptTotalPriceTrigger ? "hiddenMessage" : ""}>
          {formik.touched.receiptTotalPriceTrigger && formik.errors.receiptTotalPriceTrigger && (
            <Grid marginBottom="10px" width="209px">
              <Alert severity="error">{formik.errors.receiptTotalPriceTrigger}</Alert>
            </Grid>
          )}
        </Grid>

        <Grid mb="12px">
          <Input
            type="number"
            placeholder="> $015"
            className="holidaysTrigger"
            value={formik.values.receiptTotalPriceTrigger}
            onChange={formik.handleChange}
            name="receiptTotalPriceTrigger"
          />
        </Grid>

        <Typography color={theme.palette.diagram?.grey} fontSize="0.75rem" textAlign={{ xs: "center", sm: "start" }}>
          <FormattedMessage id="modals.triggers.select_the_minimum_amount" />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ReceiptTotalTrigger;
