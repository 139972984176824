import { Dispatch, FC, HTMLAttributes, SetStateAction } from "react";
import { CardForm } from "./CardForm";
import { NEW_CARD_TOKEN } from "./PaymentMethods.consts";
import { PaymentMethodsSelect } from "./PaymentMethodsSelect";
import { useStyles } from "./styles";
import { usePaymentMethodOptions } from "./usePaymentMethodOptions.hook";
import { CardInputsMessageStatus } from "./CardForm/CardForm.types";
import { BillingAddressForm } from "./BillingAddressForm";
import { BillingAddress } from "../../../types/billingAddress.type";
import { useAddressCreationForm } from "../../AddressSelectionModal/AddressCreationForm/useAddressCreationForm.hook";
import { AddressCreationForm } from "../../AddressSelectionModal/AddressCreationForm";
import { AddressCreationFormProps } from "../../AddressSelectionModal/AddressCreationForm/types";

interface PaymentMethodsProps extends HTMLAttributes<HTMLDivElement> {
  userId: string;
  cardToken?: string | null;
  onCardTokenChange: (token: string | null) => void;
  cardholderName: string | null;
  onCardholderNameChange: Dispatch<SetStateAction<string | null>>;
  cardInputsMessageStatus: CardInputsMessageStatus;
  setCardInputsMessageStatus: Dispatch<SetStateAction<CardInputsMessageStatus>>;
  addressCreationProps: AddressCreationFormProps;
}

export const PaymentMethods: FC<PaymentMethodsProps> = ({
  userId,
  cardToken = null,
  onCardTokenChange: handleCardTokenChange,
  cardholderName,
  onCardholderNameChange: handleCardholderNameChange,
  cardInputsMessageStatus,
  setCardInputsMessageStatus,
  className,
  addressCreationProps,
  ...divAttributes
}) => {
  const styles = useStyles();
  const showCardForm = cardToken === NEW_CARD_TOKEN;

  const options = usePaymentMethodOptions(userId);

  return (
    <div className={className} {...divAttributes}>
      <PaymentMethodsSelect
        className={styles.paymentMethodSelect}
        options={options}
        value={cardToken}
        onChange={handleCardTokenChange}
      />
      {showCardForm && (
        <div className={styles.cardForm}>
          <CardForm
            className={styles.subForm}
            cardholderName={cardholderName}
            onCardholderNameChange={handleCardholderNameChange}
            cardInputsMessageStatus={cardInputsMessageStatus}
            setCardInputsMessageStatus={setCardInputsMessageStatus}
          />
          <AddressCreationForm  isBilling {...addressCreationProps} />
        </div>
      )}
    </div>
  );
};
