import { SVGProps } from "react";

export function IconFeatherCheckCircle(
  props: Pick<SVGProps<SVGSVGElement>, "width" | "height" | "stroke">,
): JSX.Element {
  const defaultIconWidth = 33;
  const defaultIconHeight = 33;
  const defaultIconStroke = "#74b369";
  const iconWidth = props.width ?? defaultIconWidth;
  const iconHeight = props.height ?? defaultIconHeight;
  const iconStroke = props.stroke ?? defaultIconStroke;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={iconWidth}
      height={iconHeight}
      viewBox={`0 0 ${defaultIconWidth} ${defaultIconHeight}`}
    >
      <g data-name="icon-feather-check-circle" transform="translate(1.026 1.05)">
        <path
          d="M33,16.62V18A15,15,0,1,1,24.105,4.29"
          transform="translate(-2.523 -2.523)"
          fill="none"
          stroke={iconStroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <path
          id="Path_94759"
          data-name="Path 94759"
          d="M33,6,18,21.015l-4.5-4.5"
          transform="translate(-5.045)"
          fill="none"
          stroke={iconStroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
      </g>
    </svg>
  );
}
