import { FC } from "react";
import { ButtonBase, Checkbox, FormControl, FormControlLabel, Grid, InputBase, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useIntl } from "react-intl";
import useStyles from "./styles";
import SuggestedExperiences from "./SuggestedExperiences";
import ExperiencesTable from "./ExperiencesTable";

const Experiences: FC = () => {
  const { experiencesContainer, eventExperiencesSelectionContainer, experiencesSearchContainer, experiencesFilter } =
    useStyles();
  const { formatMessage } = useIntl();
  return (
    <Grid container className={experiencesContainer}>
      <Grid className={eventExperiencesSelectionContainer} item xs={12} sm={8}>
        <Typography className="experiencesTitle">
          {formatMessage({ id: "rewards.experiences.event_experiences" })}
        </Typography>

        <Typography className="experiencesDescription">
          {formatMessage({ id: "rewards.experiences.your_customers_will_be_allowed" })}
        </Typography>

        <Grid className={experiencesSearchContainer}>
          <FormControl className="inputWrapper">
            <SearchIcon className="searchIcon" />
            <InputBase placeholder={formatMessage({ id: "rewards.experiences.find_experience" })} />
          </FormControl>

          <ButtonBase className="searchButton">{formatMessage({ id: "rewards.experiences.search" })}</ButtonBase>
        </Grid>

        <Grid className={experiencesFilter}>
          <Typography className="experiencesFilterTitle">
            {formatMessage({ id: "rewards.experiences.show" })}
          </Typography>

          <FormControlLabel control={<Checkbox />} label={formatMessage({ id: "rewards.experiences.random_local" })} />

          <FormControlLabel
            control={<Checkbox />}
            label={formatMessage({ id: "rewards.experiences.random_national" })}
          />
          <FormControlLabel
            control={<Checkbox />}
            label={formatMessage({ id: "rewards.experiences.random_premium" })}
          />

          <FormControlLabel
            control={<Checkbox />}
            label={formatMessage({ id: "rewards.experiences.sponsored_event" })}
          />
        </Grid>

        <SuggestedExperiences />

        <ExperiencesTable />
      </Grid>

      <Grid item xs={12} sm={4} />
    </Grid>
  );
};

export default Experiences;
