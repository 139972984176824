import { makeStyles } from "@mui/styles";
import theme from "../../../../theme";

const useStyles = makeStyles(() => ({
  qrEditWrap: {
    padding: `0 ${theme.spacing(6)} ${theme.spacing(5)}`,
    [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
      padding: 0,
    },
    "& .mainContent": {
      backgroundColor: theme.palette.technology.white,
      width: "100%",
      padding: `${theme.spacing(3)} ${theme.spacing(8)}`,
      [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
        padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
      },
    },
    "& .buttonArea": {
      display: "flex",
      justifyContent: "flex-end",
      maxWidth: "915px",
      marginBottom: "20px",
    },
    "& .remove-btn": {
      height: "40px",
      width: "156px",
      backgroundColor: theme.palette.technology.red,
      color: theme.palette.technology.white,
      borderRadius: "20px",
      boxShadow: "none",
      "&:hover": {
        backgroundColor: theme.palette.technology.lightRed,
      },
    },
    "& .remove-btn .MuiTypography-root": {
      fontSize: "0.75rem",
      fontWeight: 700,
      textTransform: "none",
    },
    "& .MuiTypography-root": {
      fontFamily: `${theme.typography.fontFamily}`,
    },
  },
}));

export { useStyles };
