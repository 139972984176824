import { graphql } from "../../../../gql";

export const createSubscriptionCartMutation = graphql(`
  mutation CreateSubscriptionCart($input: CreateSubscriptionCartInput!) {
    createSubscriptionCart(input: $input) {
      message
      errorMessageId
      payload {
        id
      }
    }
  }
`);
