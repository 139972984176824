import { FC, useContext, useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Avatar, Box, Typography, Select, MenuItem, SelectChangeEvent, Grid } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { stringify } from "qs";
import { useStyles } from "./styles";
import theme from "../../../../theme";
import MembershipAvatar from "../../../Utils/Avatar/MembershipAvatar";
import ComingSoonLabel from "../../../Utils/ComingSoonLabel";
import { plansListOptions } from "./PlansList.helpers";
import { RouteMatchProps, PlanOption, PlansListProps } from "./PlansList.interfaces";
import { RenewalPeriod } from "../../../../generated/types";
import { Memberships, MembershipsSpanish } from "../../../../types/membershipTypes";
import AnimatedLoader from "../../../AnimatedLoader";
import { LanguageContext } from "../../../../contexts";

const handleInternalId = (title) => {
  switch (title) {
    case Memberships.Business:
    case MembershipsSpanish.Negocios:
      return 1;
    case Memberships.Brands:
    case MembershipsSpanish.Marcas:
      return 2;
    case Memberships.Enterprise:
    case MembershipsSpanish.Empresa:
      return 3;
    default:
      return 1;
  }
};
const handlePlanEnglish = (id) => {
  switch (id) {
    case 1:
      return Memberships.Business;
    case 2:
      return Memberships.Brands;
    case 3:
      return Memberships.Enterprise;
    default:
      return Memberships.Business;
  }
};
const handlePlanSpanish = (id) => {
  switch (id) {
    case 1:
      return MembershipsSpanish.Negocios;
    case 2:
      return MembershipsSpanish.Marcas;
    case 3:
      return MembershipsSpanish.Empresa;
    default:
      return MembershipsSpanish.Negocios;
  }
};

export const PlansList: FC<PlansListProps> = ({
  handleChangePlan,
  isLoading,
  readOnly,
  locationsCountLimit,
  mandatoryDepositAmount,
}) => {
  const { language } = useContext(LanguageContext);
  const history = useHistory();
  const {
    url,
    params: { membership },
  }: RouteMatchProps = useRouteMatch();
  const [selectedPlan, setSelectedPlan] = useState<any>(
    language === "en"
      ? handlePlanEnglish(handleInternalId(membership))
      : handlePlanSpanish(handleInternalId(membership)),
  );
  const styles = useStyles();
  const navigateToMembershipSummary = (newSelectedPlan: Memberships): void => {
    const path = url.replace(selectedPlan, newSelectedPlan);
    history.push({
      pathname: `${path}`,
      search: stringify(
        {
          renewalPeriod: RenewalPeriod.Monthly,
        },
        { addQueryPrefix: true },
      ),
    });
  };

  useEffect(() => {
    handleChangePlan(selectedPlan, handleInternalId(selectedPlan));
  }, [handleChangePlan, language, selectedPlan]);

  if (isLoading) {
    return <AnimatedLoader width={80} height={80} />;
  }
  const inputProps = readOnly
    ? {
        IconComponent: null,
      }
    : {};

  return (
    <Grid container className={styles.container}>
      <Box boxSizing="border-box">
        <Typography className={styles.membershipDetails}>
          <FormattedMessage
            id={
              readOnly
                ? "membership.membership_summary.your_actual_membership"
                : "membership.membership_summary.choose_your_membership"
            }
          />
        </Typography>

        <Box display="flex">
          <MembershipAvatar membership={selectedPlan} avatarWidthAndHeight="53px" />
          <Select
            className={styles.selectComp}
            value={selectedPlan}
            readOnly={readOnly}
            inputProps={inputProps}
            onChange={(event: SelectChangeEvent) => {
              navigateToMembershipSummary(event.target.value as Memberships);
              setSelectedPlan(event.target.value as Memberships);
            }}
          >
            {plansListOptions.map((option: PlanOption) => (
              <MenuItem
                key={option.title}
                disabled={!!option.comingSoon}
                value={language === "es" ? option.titleSpanish : option.title}
              >
                <Box className={styles.avatarBox}>
                  <Box display="flex">
                    <Avatar
                      style={{
                        backgroundColor: `${option.color}`,
                        color: `${option.letterColor}` || "#ffffff",
                        height: "20px",
                        width: "20px",
                      }}
                    >
                      <Typography fontFamily={theme.typography.fontFamily} fontSize="0.625rem">
                        {option.letter}
                      </Typography>
                    </Avatar>

                    <Typography className={styles.planTitle}>
                      {language === "es" ? option.titleSpanish : option.title}
                    </Typography>
                  </Box>
                  {option.comingSoon && (
                    <Box display="flex">
                      <ComingSoonLabel />
                    </Box>
                  )}
                </Box>
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
      <Grid item spacing={3}>
        <Typography className={styles.location}>
          <FormattedMessage
            id={locationsCountLimit ? "membership.location_count" : "membership.not_attached_to_locations"}
            values={{ count: locationsCountLimit }}
          />
        </Typography>
        <Typography className={styles.priceTitle}>
          <FormattedMessage id="membership.membership_summary.credit_limit" />
        </Typography>
        <Typography className={styles.price}>{`$${mandatoryDepositAmount}`}</Typography>
      </Grid>
    </Grid>
  );
};
