import { Memberships, MembershipsSpanish } from "../../types/membershipTypes";
import { MembershipRule } from "../Membership/MembershipSummary/types";

export const membershipWeights: Record<Memberships | MembershipsSpanish, number> = {
  [Memberships.Business]: 1,
  [MembershipsSpanish.Negocios]: 1,
  [Memberships.Brands]: 2,
  [MembershipsSpanish.Empresa]: 2,
  [Memberships.Enterprise]: 3,
  [MembershipsSpanish.Marcas]: 3,
};

export const isItemDisabled = (membershipType, minimalMembershipLevel) => {
  return !membershipType || membershipWeights[membershipType] < membershipWeights[minimalMembershipLevel];
};

export const isPossibleUpgradePlan = (membershipType, minimalMembershipLevel) => {
  return membershipWeights[membershipType] < membershipWeights[minimalMembershipLevel];
};
export const filteredRules = (rulesData, isStageWood) =>
  rulesData?.getAllCategoriesWithRules?.reduce(
    (
      acc: {
        [key: string]: MembershipRule[];
      },
      category,
    ) => {
      const filtered = category.tykchaseRule.filter((item) => {
        return isStageWood || (!item.isStagewoodPerk && !isStageWood);
      });
      const key = category.title;
      acc[key] = [...filtered];

      return acc;
    },
    {},
  ) ?? {};
