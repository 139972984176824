import { IEChartsOption } from "../../../../types/charts";
import { Values } from "../../types";
import theme from "../../../../theme";

export const getOption = (values: Values): IEChartsOption => {
  const seriesNames = Object.keys(values).map((name: string) => name);
  const seriesData = Object.values(values).map((value: string | number) => value);

  const data = seriesNames.map((value: string, index: number) => {
    return {
      name: value,
      itemStyle: {
        color: theme.palette.analytics?.chart,
        borderRadius: [0, 5, 5, 0],
      },
      value: seriesData[index],
      cursor: "default",
    };
  });

  return {
    grid: {
      left: 10,
      top: 20,
      right: 20,
      bottom: 0,
      height: "140px",
      containLabel: true,
    },
    yAxis: {
      type: "category",
      data: seriesNames,
      axisTick: false,
    },
    xAxis: {
      type: "value",
    },
    series: [
      {
        data,
        type: "bar",
        name: "Total",
        cursor: "auto",
        barWidth: 20,
      },
    ],
    tooltip: false,
  };
};
