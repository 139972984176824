import { graphql } from "../../../gql";

export const getAllMembershipsQuery = graphql(`
  query GetAllMemberships {
    getAllMemberships {
      locationsCountLimit
      locationSetupFee
      internalId
      setupFee
      id
      title
      mandatoryDepositAmount
    }
  }
`);
