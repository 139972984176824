import { FC } from "react";
import Lottie from "react-lottie";
import { Box, Typography } from "@mui/material";
import animationData from "../../assets/animations/loading.json";
import theme from "../../theme";

interface AnimatedLoaderProps {
  height?: number;
  width?: number;
}

const AnimatedLoader: FC<AnimatedLoaderProps> = ({ height = 40, width = 40 }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    renderer: "svg",
  };

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <Lottie options={defaultOptions} height={height} width={width} />

      <Typography color="#505050" fontFamily={theme.typography.fontFamily} fontSize="1rem">
        Loading results
      </Typography>
    </Box>
  );
};
export default AnimatedLoader;
