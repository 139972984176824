import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { FormikProps, useFormik } from "formik";
import { RewardRuleModalProps } from "./RewardRuleModal.interfaces";
import Modal from "../../Modal";
import Layout from "../Modals/Layout";
import { Diagram } from "../Modals/Diagram";
import { useBooleanState } from "../../../hooks/useBooleanState.hook";
import { RewardInputs } from "../types";
import { modalValidateSchema, pickInitialValues } from "./RewardRuleModal.helpers";
import { PurchaseRewardRate, Periods } from "../../../generated/types";

export const RewardRuleModal: FC<RewardRuleModalProps> = ({
  title,
  reward,
  isOpen,
  tykchaseGlobalConfig,
  onClose,
  handleSave,
  handleCancel,
  handlePublish,
  setRewardSplit,
  backendData,
  setIsModalOpen,
  multipleTimeReward,
  pickedRewardInputs,
  rewardRuleModalFooter,
  rewardRuleModalSubHeader,
  rewardRuleModalHeaderControls,
}) => {
  const intl = useIntl();
  const { value: isPublishing, setTrue: setPublishing, setFalse: setSaving } = useBooleanState();
  const [period, setPeriod] = useState<Periods | undefined>(backendData?.period ?? undefined);
  const [rewardOnPurchaseCount, setRewardOnPurchaseCount] = useState(backendData?.rewardOnPurchaseCount);
  const initialValues = pickInitialValues(pickedRewardInputs, backendData, reward);
  const [isPercentage, setIsPercentage] = useState(false);

  const formik: FormikProps<RewardInputs> = useFormik<RewardInputs>({
    initialValues,
    validationSchema: modalValidateSchema(intl).pick(pickedRewardInputs),
    onSubmit: async () => {
      if (isPublishing) {
        await handlePublish({
          dollarReward: formik.values.dollarReward,
          allowMultipleReward: formik.values.allowMultipleReward,
          multipleRewardCount: Number(formik.values.multipleRewardCount),
          period,
          purchaseRewardRate: formik.values.purchaseRewardRate,
          minPurchaseForReward: parseFloat(String(formik.values.minPurchaseForReward)),
          rewardOnPurchaseCount: Number(formik.values.rewardOnPurchaseCount?.toString()),
        });
      } else {
        await handleSave({
          dollarReward: formik.values.dollarReward,
          allowMultipleReward: formik.values.allowMultipleReward,
          multipleRewardCount: Number(formik.values.multipleRewardCount),
          period,
          purchaseRewardRate: formik.values.purchaseRewardRate,
          minPurchaseForReward: parseFloat(String(formik.values.minPurchaseForReward)),
          rewardOnPurchaseCount: Number(formik.values.rewardOnPurchaseCount?.toString()),
        });
      }

      setIsModalOpen(false);
    },
  });

  useEffect(() => {
    if (formik.values.purchaseRewardRate) {
      setIsPercentage(formik.values.purchaseRewardRate === PurchaseRewardRate.ByPercentage);
    }
  }, [formik.values.purchaseRewardRate]);

  const onPublish = async () => {
    setPublishing();
    await formik.submitForm();
  };

  const onSave = async () => {
    setSaving();
    await formik.submitForm();
  };

  const onCloseHandler = () => {
    if (!formik.isSubmitting) {
      handleCancel();
      onClose();
    }
  };

  return (
    <Modal onClose={onCloseHandler} isOpen={isOpen}>
      <Layout
        title={title}
        subtitle={intl.formatMessage({
          id: multipleTimeReward
            ? "reward_rules_modal_subtitle_multiple_time_reward"
            : "reward_rules_modal_subtitle_one_time_reward",
        })}
        handleSave={onSave}
        handleCancel={onCloseHandler}
        handlePublish={onPublish}
        isSaveLoading={!isPublishing && formik.isSubmitting}
        isPublishLoading={isPublishing && formik.isSubmitting}
        headerControls={
          rewardRuleModalHeaderControls
            ? rewardRuleModalHeaderControls({
                period,
                setPeriod,
                formik,
                rewardOnPurchaseCount,
                setRewardOnPurchaseCount,
              })
            : undefined
        }
        subHeader={rewardRuleModalSubHeader ? rewardRuleModalSubHeader(formik) : undefined}
        footer={rewardRuleModalFooter ? rewardRuleModalFooter(formik) : undefined}
      >
        <Diagram
          isPercentage={isPercentage}
          rewardUsd={reward}
          tykchaseGlobalConfig={tykchaseGlobalConfig}
          setRewardSplit={setRewardSplit}
          backendData={backendData}
          purchaseRewardRate={formik.values.purchaseRewardRate}
          formik={formik}
          isSubmitting={formik.isSubmitting}
        />
      </Layout>
    </Modal>
  );
};
