import qs from "qs";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

/**
 * Returns an object with query parameters
 */
export const useQueryParams = (): qs.ParsedQs => {
  const location = useLocation();

  return useMemo(() => qs.parse(location.search, { ignoreQueryPrefix: true }), [location.search]);
};
