import { Button, Grid } from "@mui/material";
import { ReactChild, ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import useStyles from "./styles";
import { LoadingButton } from "../../../LoadingButton/loading-button.component";

interface LayoutProps {
  title: ReactChild | string;
  subtitle: string;
  headerControls?: ReactNode | ReactChild | (() => ReactChild) | null;
  subHeader?: ReactNode | ReactChild | (() => ReactChild) | null;
  children: ReactChild | (() => ReactChild);
  footer?: ReactNode | ReactChild | (() => ReactChild) | null;
  handleSave?: () => void;
  handleCancel?: () => void;
  handlePublish?: () => void;
  isSaveLoading?: boolean;
  isPublishLoading?: boolean;
}

const Layout = ({
  title,
  subtitle,
  headerControls,
  subHeader,
  children,
  footer,
  handleSave,
  handleCancel,
  handlePublish,
  isSaveLoading,
  isPublishLoading,
}: LayoutProps) => {
  const { modalHeader, modalBody, modalFooter, buttonsContainer } = useStyles();

  return (
    <Grid container direction="column">
      <div className={modalHeader}>
        <div className="left-side">
          <h1>{title}</h1>
          <p className="subtitle">{subtitle}</p>
        </div>

        <Grid>{headerControls}</Grid>
      </div>

      {subHeader}

      <div className={modalBody}>{children}</div>

      {footer && <div className={modalFooter}>{footer}</div>}

      <div className={buttonsContainer}>
        <Button disabled={isSaveLoading || isPublishLoading} onClick={handleCancel}>
          <FormattedMessage id="btn_text_cancel" />
        </Button>
        <LoadingButton disabled={isPublishLoading} loading={isSaveLoading} loadingPosition="start" onClick={handleSave}>
          <FormattedMessage id="btn_text_save" />
        </LoadingButton>
        <LoadingButton
          disabled={isSaveLoading}
          loading={isPublishLoading}
          classes={{ loadingIndicator: "loading-indicator" }}
          loadingPosition="start"
          onClick={handlePublish}
          variant="contained"
        >
          <FormattedMessage id="btn_text_publish" />
        </LoadingButton>
      </div>
    </Grid>
  );
};

export default Layout;
