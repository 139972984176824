import { Grid, CardActions, Typography, Paper } from "@mui/material";
import clsx from "clsx";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import businessIcon from "../../../assets/icons/membership/businessIcon.svg";
import shopIcon from "../../../assets/icons/membership/shopIcon.svg";

import { MembershipCardButton } from "../../Buttons";
import { useStyles } from "./styles";
import { MembershipDetails } from "../types";
import { buttonText } from "../helpers";

export interface CardMembershipProps extends MembershipDetails {
  onSelectPlan: (title: string) => void;
  membershipTitle: string | null | undefined;
}
const locationText = (id) => {
  switch (id) {
    case 1:
      return "membership.location_count";
    case 3:
      return "membership.unlimited_location";
    case 2:
      return "membership.brands_membership";
    default:
      return "membership.membership";
  }
};

const priceText = (id, secondary, locationSetupFee, mandatoryDepositAmount) => {
  switch (id) {
    case 1:
      return (
        <Typography className={secondary}>
          <FormattedMessage id="membership.deposit_for_credit" values={{ deposit: mandatoryDepositAmount }} />
        </Typography>
      );
    case 3:
      return (
        <>
          <Typography className={secondary}>
            <FormattedMessage id="membership.credit" values={{ deposit: mandatoryDepositAmount }} />
          </Typography>
          <Typography className={secondary}>
            <FormattedMessage id="membership.addition_locations" values={{ price: locationSetupFee }} />
          </Typography>
        </>
      );
    default:
      return "membership.membership";
  }
};

const CardMembership: FC<CardMembershipProps> = (props) => {
  const {
    title,
    onSelectPlan,
    membershipTitle,
    locationsCountLimit,
    internalId,
    setupFee,
    locationSetupFee,
    mandatoryDepositAmount,
  } = props;
  const {
    root,
    background,
    btnArea,
    buttonContainer,
    main,
    secondary,
    mainBold,
    secondaryBold,
    contentContainer,
    innerContainer,
    locationContainer,
    priceContainer,
    backgroundNormal,
    backgroundSmall,
    backgroundSmallCentral,
    padding,
    marginBrands,
  } = useStyles({ internalId });
  const selectPlan = () => {
    onSelectPlan(title);
  };

  return (
    <Paper className={clsx(root, background)} elevation={4}>
      <Grid className={padding} container>
        <Grid className={contentContainer} item>
          <Grid flexDirection="row" className={innerContainer} flexWrap="nowrap" container item>
            {internalId === 3 ? (
              <Grid spacing={3} container alignItems="center" alignContent="center" item flexDirection="row">
                <Grid
                  className={innerContainer}
                  container
                  alignItems="center"
                  alignContent="center"
                  item
                  flexDirection="row"
                >
                  <img width={40} src={businessIcon} alt="Business" />
                  <Grid className={backgroundSmall} item />
                </Grid>
                <Grid
                  className={innerContainer}
                  container
                  alignItems="center"
                  alignContent="center"
                  item
                  flexDirection="row"
                >
                  <img src={businessIcon} alt="Business" />
                  <Grid className={backgroundSmallCentral} item />
                </Grid>
                <Grid
                  className={innerContainer}
                  container
                  alignItems="center"
                  alignContent="center"
                  item
                  flexDirection="row"
                >
                  <img width={40} src={businessIcon} alt="Business" />
                  <Grid className={backgroundSmall} item />
                </Grid>
              </Grid>
            ) : (
              <Grid
                className={innerContainer}
                container
                alignItems="center"
                alignContent="center"
                item
                flexDirection="row"
              >
                <img src={internalId === 2 ? shopIcon : businessIcon} alt="Business" />
                <Grid className={backgroundNormal} item />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item>
          <Typography className={clsx(mainBold)}>{title}</Typography>
        </Grid>
        <Grid container item className={locationContainer}>
          <Typography className={secondaryBold}>
            <FormattedMessage id={locationText(internalId)} values={{ count: locationsCountLimit }} />
          </Typography>
        </Grid>
        {internalId === 2 ? (
          <Typography className={clsx(secondaryBold, internalId === 2 && marginBrands)}>
            <FormattedMessage id="membership.contact_for_special" />
          </Typography>
        ) : (
          <Grid container item className={priceContainer}>
            <Grid marginBottom={2} flexDirection="column" container justifyContent="center">
              <Typography className={main}>${setupFee}</Typography>
              <Typography className={secondary}>
                <FormattedMessage id="membership.one_time_payment" />
              </Typography>
              {internalId === 3 && (
                <Typography className={secondary}>
                  <FormattedMessage id="membership.addition_locations" values={{ price: locationSetupFee }} />
                </Typography>
              )}
            </Grid>
            <Grid columnGap={2} flexDirection="column" container justifyContent="center">
              {priceText(internalId, secondary, locationSetupFee, mandatoryDepositAmount)}
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid container item className={buttonContainer}>
        <CardActions className={btnArea}>
          <MembershipCardButton buttonText={buttonText(membershipTitle, title)} btnOnClick={selectPlan} />
        </CardActions>
      </Grid>
    </Paper>
  );
};

export default CardMembership;
