import { useLazyQuery, useMutation } from "@apollo/client";
import { StripeCardNumberElement, CreatePaymentMethodCardData } from "@stripe/stripe-js";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { GetCartQuery } from "../../../generated/types";
import {
  CartProcessingStatus,
  GetPaymentMethodsDocument,
  FetchUserTransactionsHistoryDocument,
  Status,
} from "../../../gql/graphql";
import { getOptions } from "../../Utils/Notifications";
import { completeCartMutation } from "../graphql/CompleteCart.mutation";
import { NEW_CARD_TOKEN } from "../PaymentMethods/PaymentMethods.consts";
import { getCartProcessingInfoQuery } from "../graphql/GetCartProcessingInfo.query";
import { BillingAddress } from "../../../types/billingAddress.type";
import { omitEmptyValues } from "../../../utils/omitEmptyValues.util";
import { MessageIds } from "../../../i18n";

const getPaymentMethod = (
  cardToken: string | null,
  cardholderName: string | null,
  billingAddress: BillingAddress,
  card?: StripeCardNumberElement | null,
): string | Omit<CreatePaymentMethodCardData, "type"> => {
  if (cardToken === NEW_CARD_TOKEN) {
    return {
      card: card!,
      billing_details: {
        name: cardholderName?.toUpperCase(),
        address: omitEmptyValues(billingAddress),
      },
    };
  }

  return cardToken ?? "";
};

export const useErrorNotification = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { formatMessage } = useIntl();

  return useCallback(
    (id: MessageIds = "checkout_error") => {
      enqueueSnackbar(formatMessage({ id }), getOptions("error"));
    },
    [enqueueSnackbar, formatMessage],
  );
};

export const useSuccessNotification = (successMessageId: MessageIds) => {
  const { enqueueSnackbar } = useSnackbar();
  const { formatMessage } = useIntl();

  return useCallback(() => {
    enqueueSnackbar(formatMessage({ id: successMessageId }), getOptions("success"));
  }, [enqueueSnackbar, formatMessage, successMessageId]);
};

export const useCheckoutHandler = (
  clientSecret: string,
  onSuccessCallback: VoidFunction,
  cardToken: string | null,
  cardholderName: string | null,
  billingAddress: BillingAddress,
) => {
  const [isCheckoutLoading, setLoading] = useState(false);
  const [isCheckoutError, setError] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const showErrorNotification = useErrorNotification();

  const handleCheckout = useCallback(() => {
    setLoading(true);
    const card = elements?.getElement("cardNumber");

    if (!cardToken || (cardToken === NEW_CARD_TOKEN && !card)) {
      showErrorNotification("checkout_error_no_card_selected");
      setError(true);
      setLoading(false);

      return;
    }

    const paymentMethod = getPaymentMethod(cardToken, cardholderName, billingAddress, card);

    stripe
      ?.confirmCardPayment(clientSecret, {
        payment_method: paymentMethod,
        save_payment_method: true,
      })
      .then((result) => {
        if (result.error) {
          setError(true);
          showErrorNotification();
        } else if (result.paymentIntent?.status === "succeeded") {
          onSuccessCallback();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    cardholderName,
    showErrorNotification,
    clientSecret,
    stripe,
    elements,
    onSuccessCallback,
    cardToken,
    billingAddress,
  ]);

  return {
    isCheckoutLoading,
    isCheckoutError,
    handleCheckout,
  };
};
export const useWaitForCartProcessingCompletion = (cartId: string, onSuccessCallback: VoidFunction) => {
  const [getCartProcessingInfo, { data: getCartProcessingInfoData }] = useLazyQuery(getCartProcessingInfoQuery, {
    variables: { cartId },
    fetchPolicy: "no-cache",
  });

  const waitForCartProcessingCompletion = async () => {
    const { data } = await getCartProcessingInfo();

    if (data?.getCartPaymentProcessingInfo?.payload?.status === CartProcessingStatus.InProgress) {
      waitForCartProcessingCompletion();
    } else {
      onSuccessCallback();
    }
  };

  const processingStatus = getCartProcessingInfoData?.getCartPaymentProcessingInfo?.payload?.status;
  const isProcessingCompleted =
    processingStatus === CartProcessingStatus.Success || processingStatus === CartProcessingStatus.Failure;

  return [waitForCartProcessingCompletion, isProcessingCompleted] as const;
};

export const useCartCompletionHandler = (id: string, successPageUrl = "/") => {
  const { push } = useHistory();
  const showErrorNotification = useErrorNotification();
  const [waitForCartProcessingCompletion, isProcessingCompleted] = useWaitForCartProcessingCompletion(id, () =>
    push(successPageUrl),
  );

  const [completeCart, { loading, error, data }] = useMutation(completeCartMutation, {
    variables: {
      cartId: id,
    },
    onCompleted: (mutationResponse) => {
      if (mutationResponse.completeCart?.status === Status.Pending) {
        waitForCartProcessingCompletion();
      } else {
        push(successPageUrl);
      }
    },
    onError: () => {
      showErrorNotification();
    },
    refetchQueries: [GetPaymentMethodsDocument, FetchUserTransactionsHistoryDocument],
  });
  const isCartCompletionLoading = loading || (data?.completeCart?.status === Status.Pending && !isProcessingCompleted);

  return {
    isCartCompletionLoading,
    isCartCompletionError: !!error,
    handleCartCompletion: completeCart,
  };
};

export const useCheckoutHandlers = (
  { clientSecret, id }: NonNullable<GetCartQuery["getCart"]>,
  cardToken: string | null,
  cardholderName: string | null,
  billingAddress: BillingAddress,
  successPageUrl?: string,
) => {
  const { isCartCompletionLoading, isCartCompletionError, handleCartCompletion } = useCartCompletionHandler(
    id ?? "",
    successPageUrl,
  );

  const { isCheckoutLoading, isCheckoutError, handleCheckout } = useCheckoutHandler(
    clientSecret ?? "",
    handleCartCompletion,
    cardToken,
    cardholderName,
    billingAddress,
  );

  return {
    loading: isCheckoutLoading || isCartCompletionLoading,
    error: isCheckoutError || isCartCompletionError,
    handleCheckout,
  };
};
