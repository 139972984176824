import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    wrap: {
      position: "relative",
      width: "max-content",
      margin: "0 auto",

      "&.notAvailable::before": {
        content: '""',
        position: "absolute",
        top: "0",
        left: "0",
        height: "100%",
        width: "100%",
        zIndex: "999",
      },

      "& .wrapWrappedComponent": {
        "&.notAvailable": {
          opacity: ".5",
        },
      },

      "& .lockedIcon": {
        position: "absolute",
        top: "-10px",
        left: "-3px",
        "&.notAvailable": {
          opacity: ".5",
        },
      },
      "& .popup": {
        position: "absolute",
        bottom: "calc(100% + 13px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
        borderRadius: "6px",
        background: theme.palette.diagram?.grey,
        opacity: "0",
        visibility: "hidden",
        transition: "300ms ease-in-out",
        zIndex: "999",
        "&::after": {
          content: '""',
          position: "absolute",
          bottom: "-9px",
          left: "20%",
          transform: "rotate(45deg)",
          height: "20px",
          width: "20px",
          background: theme.palette.diagram?.grey,
          zIndex: "1",
        },
        "&:hover": {
          opacity: "1",
          visibility: "visible",
        },
        "&.active": {
          opacity: "1",
          visibility: "visible",
        },

        "& .more": {
          textDecoration: "underline",
          color: theme.palette.blue,
          marginLeft: "5px",
          cursor: "pointer",
        },
      },
    },
  };
});

export default useStyles;
