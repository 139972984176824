import { FC, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useUser } from "@stagewood/unified-login-library";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import { useStyles } from "./styles";
import { PaymentMethod } from "../PaymentMethod";
import { useActivePaymentMethods } from "../hooks/useActivePaymentMethods.hook";
import { IPaymentMethod } from "../PaymentMethod/PaymentMethod.interfaces";
import { PageLoader } from "../../PageLoader";
import { AddPaymentMethodButton } from "../add-payment-method/add-payment-method-button.component";
import { useBooleanState } from "../../../hooks/useBooleanState.hook";
import { AddPaymentMethodModal } from "../add-payment-method/add-payment-method-modal.component";
import { RemovePaymentMethodModal } from "../remove-payment-method/remove-payment-method-modal.component";
import { getOptions } from "../../Utils/Notifications";

export const Payments: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const user = useUser();
  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>([]);
  const [chosenCardPaymentMethodId, setChosenCardPaymentMethodId] = useState("");
  const styles = useStyles();
  const intl = useIntl();
  const {
    value: addPaymentMethodModalOpen,
    setTrue: openAddPaymentMethodModal,
    setFalse: closeAddPaymentMethodModal,
  } = useBooleanState();

  const {
    value: removePaymentMethodModalOpen,
    setTrue: openRemovePaymentMethodModal,
    setFalse: closeRemovePaymentMethodModal,
  } = useBooleanState();

  const [activePaymentMethods, isActivePaymentMethodsLoading] = useActivePaymentMethods(user?.id as string);

  const onCardClick = (paymentMethodId: string) => {
    if (chosenCardPaymentMethodId && chosenCardPaymentMethodId === paymentMethodId) {
      setChosenCardPaymentMethodId("");
    } else if (!chosenCardPaymentMethodId) {
      setChosenCardPaymentMethodId(paymentMethodId);
    }
  };

  const onCardRemoveClick = (paymentMethodId: string, isDefault: boolean) => {
    if (isDefault || paymentMethods.length === 1) {
      enqueueSnackbar(intl.formatMessage({ id: "error.this_is_your_default_payment_method" }), getOptions("error"));
    } else {
      setChosenCardPaymentMethodId(paymentMethodId);
      openRemovePaymentMethodModal();
    }
  };

  useEffect(() => {
    if (user?.id) {
      setPaymentMethods(activePaymentMethods as IPaymentMethod[]);
    }
  }, [activePaymentMethods, user]);

  return (
    <Grid className={styles.paymentsWrap} container>
      <Grid container item xs={12} lg={12}>
        {!isActivePaymentMethodsLoading ? (
          <>
            <Box mb={4} width="100%">
              <AddPaymentMethodButton onClick={openAddPaymentMethodModal} />
              <AddPaymentMethodModal open={addPaymentMethodModalOpen} handleClose={closeAddPaymentMethodModal} />
              <RemovePaymentMethodModal
                open={removePaymentMethodModalOpen}
                handleClose={closeRemovePaymentMethodModal}
                paymentItem={paymentMethods.find(
                  (element) => element.userPaymentMethodId === chosenCardPaymentMethodId,
                )}
              />
            </Box>
            <Grid container item>
              {paymentMethods.map((paymentMethod: IPaymentMethod) => (
                <PaymentMethod
                  onCardClick={onCardClick}
                  onCardRemoveClick={onCardRemoveClick}
                  chosenCardPaymentMethodId={chosenCardPaymentMethodId}
                  key={paymentMethod.id}
                  paymentMethod={paymentMethod}
                />
              ))}
            </Grid>
          </>
        ) : (
          <PageLoader />
        )}
      </Grid>
    </Grid>
  );
};
