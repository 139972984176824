import { FC, useEffect, useState } from "react";
import {
  Grid,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";

import { useIntl } from "react-intl";
import { TimeFrame, TimeFrameSelectItem } from "../types";
import theme from "../../../theme";
import useStyles from "./styles";
import { getStartAndEndDates } from "./TimeFrameFilter.helpers";

const options: TimeFrameSelectItem[] = [
  {
    value: TimeFrame.ThisMonth,
    label: "analytics.rules.time_frame.label.this_month",
  },
  {
    value: TimeFrame.LastThreeMonths,
    label: "analytics.rules.time_frame.label.last_three_months",
  },
  {
    value: TimeFrame.LastYear,
    label: "analytics.rules.time_frame.label.last_year",
  },
  {
    value: TimeFrame.CustomRange,
    label: "analytics.rules.time_frame.label.custom_range",
  },
];

const TimeFrameFilter: FC = () => {
  const { timeFrameSelect, datePickerWrapper } = useStyles();
  const { formatMessage } = useIntl();
  const [selectedTimeFrame, setSelectedTimeFrame] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedTimeFrame(event.target.value);
  };

  useEffect(() => {
    if (selectedTimeFrame !== TimeFrame.CustomRange) {
      const { start, end } = getStartAndEndDates(selectedTimeFrame);
      setStartDate(start);
      setEndDate(end);
    } else {
      setStartDate("");
      setEndDate("");
    }
  }, [selectedTimeFrame]);

  return (
    <Grid container display="flex" flexDirection="row" alignItems="center">
      <Grid item marginRight="20px">
        <FormControl sx={{ m: 1, minWidth: "120px", width: "190px" }} className={timeFrameSelect}>
          <Typography
            fontFamily={theme.typography.fontFamily}
            color={theme.palette.label?.grey}
            variant="caption"
            paddingBottom="2px"
          >
            {formatMessage({ id: "analytics.rules.time_frame.title" })}
          </Typography>

          <Select
            placeholder={formatMessage({ id: "analytics.rules.time_frame.select_your_period" })}
            value={selectedTimeFrame}
            onChange={handleChange}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value="">
              <Typography fontFamily={theme.typography.fontFamily} fontSize="0.75rem">
                <em>{formatMessage({ id: "analytics.rules.time_frame.select_your_period" })}</em>
              </Typography>
            </MenuItem>

            {options.map((option) => (
              <MenuItem value={option.value} key={option.value}>
                <Typography fontFamily={theme.typography.fontFamily} fontSize="0.75rem">
                  {formatMessage({ id: option.label })}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      {!!selectedTimeFrame && (
        <Grid item m={1} display="flex" flexDirection="row">
          <Box className={datePickerWrapper} marginRight="20px">
            <InputLabel htmlFor="startDate">{formatMessage({ id: "inputs.start_date" })}</InputLabel>

            <TextField
              id="startDate"
              type="date"
              className="datePicker"
              value={startDate}
              onChange={(event) => setStartDate(event.target.value || "")}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                max: endDate || new Date().toISOString().slice(0, 10),
              }}
              disabled={selectedTimeFrame !== TimeFrame.CustomRange}
            />
          </Box>

          <Box className={datePickerWrapper}>
            <InputLabel htmlFor="endDate">{formatMessage({ id: "inputs.end_date" })}</InputLabel>

            <TextField
              id="endDate"
              type="date"
              className="datePicker"
              value={endDate}
              onChange={(event) => setEndDate(event.target.value || "")}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: startDate,
                max: new Date().toISOString().slice(0, 10),
              }}
              disabled={selectedTimeFrame !== TimeFrame.CustomRange}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default TimeFrameFilter;
