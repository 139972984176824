import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    modalContainer: {
      width: "400px",

      [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
        maxWidth: "100%",
      },

      "& .modal-header": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "end",

        "& .modal-header__title": {
          fontSize: "1.1875rem",
          color: "#323232",
        },
      },

      "& .modal-divider": {
        height: "2px",
        marginTop: "16px",
        color: "#EAEAEA",
      },

      "& .modal-body": {
        padding: "16px 0 24px 0",

        "& .MuiTypography-root": {
          fontSize: "0.9375rem",
          color: "#505050",
        },
      },

      "& .modal-footer": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "end",

        "& .MuiButtonBase-root": {
          height: "40px",
          paddingLeft: "26px",
          paddingRight: "26px",
          fontSize: "1rem",
          fontWeight: 600,
          borderRadius: "20px",
          boxShadow: "none",
          textTransform: "none",
        },

        "& .MuiButtonBase-root.secondary-button": {
          marginRight: "16px",
          backgroundColor: "#C8000000",
          border: "2px solid #747474",
          color: "#747474",
          "&:hover": {
            backgroundColor: "#47474712",
          },
        },

        "& .MuiButtonBase-root.primary-button": {
          backgroundColor: "#1473E6",
          color: theme.palette.white,
          "&:hover": {
            backgroundColor: "#203ED4",
          },
        },
      },
    },
  };
});

export default useStyles;
