import { Box, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { FC } from "react";
import { useQuery } from "@apollo/client";
import { DepositBox } from "../DepositBox";
import { useStyles } from "./styles";
import { ReactComponent as ExclamationIcon } from "../../../assets/icons/tykIcons/exclamation.svg";
import { DepositRechargeModal } from "./DepositRechargeModal";
import { getMembershipDepositSettingsQuery } from "./graphql/GetMembershipDepositSettings.query";
import { useModalRouteState } from "../../../hooks/useModalRouteState.hook";
import { DepositRechargeSuccessModal } from "./DepositRechargeSuccessModal";
import {
  AMOUNT_PARAM_KEY,
  DEFAULT_DEPOSIT_MINIMAL_AMOUNT,
  DEPOSIT_RECHARGE_MODAL_PARAM_KEY,
  DEPOSIT_SETTINGS_MODAL_PARAM_KEY,
} from "./DepositSettings.consts";

interface DepositSettingsProps {
  userId: string;
  isSubscribed: boolean;
}

export const DepositSettings: FC<DepositSettingsProps> = ({ userId, isSubscribed }) => {
  const styles = useStyles();
  const {
    isVisible: isDepositSettingsModalVisible,
    openModal: openDepositSettingsModal,
    closeModal: closeDepositSettingsModal,
  } = useModalRouteState(DEPOSIT_SETTINGS_MODAL_PARAM_KEY);
  const { isVisible: isDepositRechargeSuccessVisible, closeModal: closeDepositRechargeSuccess } = useModalRouteState(
    DEPOSIT_RECHARGE_MODAL_PARAM_KEY,
    [AMOUNT_PARAM_KEY],
  );
  const { data: membershipRepositData, loading: isMembershipDepositLoading } = useQuery(
    getMembershipDepositSettingsQuery,
    { variables: { userId } },
  );
  const amount =
    membershipRepositData?.user?.tykchaseConfig?.membership.mandatoryDepositAmount ?? DEFAULT_DEPOSIT_MINIMAL_AMOUNT;

  return (
    <>
      <DepositBox
        title={<FormattedMessage id="tyk_deposit_settings" />}
        amount={amount}
        editButton={
          <button
            className={styles.editButton}
            type="button"
            onClick={openDepositSettingsModal}
            disabled={isMembershipDepositLoading}
            style={{ cursor: isSubscribed ? "pointer" : "default" }}
          >
            <FormattedMessage id="btn_text_edit" />
          </button>
        }
        tooltipContent={
          <Box display="flex" alignItems="center" p={1}>
            <ExclamationIcon className={styles.tooltipIcon} />
            <Typography className={styles.tooltipText}>
              <FormattedMessage id="tyk_deposit_settings_tooltip" />
            </Typography>
          </Box>
        }
      />
      {!isMembershipDepositLoading && (
        <DepositRechargeModal
          businessId={userId}
          isActive={isDepositSettingsModalVisible}
          onClose={closeDepositSettingsModal}
          minDeposit={amount}
        />
      )}
      <DepositRechargeSuccessModal isActive={isDepositRechargeSuccessVisible} onClose={closeDepositRechargeSuccess} />
    </>
  );
};
