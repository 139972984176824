import { ReactElement } from "react";
import { defineMessages, IntlShape } from "react-intl";
import { Routes } from "../constants";

interface IMenuItem {
  icon: ReactElement;
  text: string;
  route: Routes;
}

const messages = defineMessages({
  dashboard: {
    id: "menu_dashboard",
    defaultMessage: "Dashboard",
  },
  memberships: {
    id: "menu_memberships",
    defaultMessage: "Memberships",
  },
  yourRewards: {
    id: "menu_your_rewards",
    defaultMessage: "Your Rewards",
  },
  analytics: {
    id: "menu_analytics",
    defaultMessage: "Analytics",
  },
  marketing: {
    id: "menu_marketing",
    defaultMessage: "Marketing",
  },
  technology: {
    id: "menu_technology",
    defaultMessage: "Technology",
  },
  billing: {
    id: "menu_billing_payments",
    defaultMessage: "Billing & Payments",
  },
  locations: {
    id: "menu_locations",
    defaultMessage: "Locations",
  },
});

const MenuItems = (intl: IntlShape) => {
  return {
    DASHBOARD: intl.formatMessage(messages.dashboard),
    MEMBERSHIPS: intl.formatMessage(messages.memberships),
    YOUR_REWARDS: intl.formatMessage(messages.yourRewards),
    ANALYTICS: intl.formatMessage(messages.analytics),
    MARKETING: intl.formatMessage(messages.marketing),
    TECHNOLOGY: intl.formatMessage(messages.technology),
    BILLING: intl.formatMessage(messages.billing),
    LOCATIONS: intl.formatMessage(messages.locations),
  };
};

export { MenuItems };
export type { IMenuItem };
