import { Button, Grid, Typography } from "@mui/material";
import { ReactChild } from "react";
import { useTheme } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import useStyles from "./styles";

interface LayoutProps {
  title?: ReactChild | string;
  children?: ReactChild | (() => ReactChild);
  mbTitle?: string;
  removeBtn?: boolean;
  handleRemove?: () => void;
  handleCancel?: () => void;
  handleAccept?: () => void;
}

const Layout = ({
  title,
  children,
  mbTitle,
  removeBtn,
  handleRemove,
  handleCancel,
  handleAccept,
}: LayoutProps) => {
  const { modalBody, buttonsContainer } = useStyles();
  const theme = useTheme();

  return (
    <Grid container direction="column" width={{ xs: "auto", md: "791px" }}>
      {typeof title === "string" ? (
        <Typography fontWeight="bold" color={theme.palette.black} mb={mbTitle || { xs: "70px", md: "135px" }}>
          {title}
        </Typography>
      ) : (
        <>{title}</>
      )}
      <div className={modalBody}>{children}</div>

      <Grid mt="138px" className={buttonsContainer}>
        <Button onClick={handleCancel}>
          <FormattedMessage id="btn_text_cancel" />
        </Button>
        {removeBtn && (
          <Button className="removeBtn" onClick={handleRemove}>
            <FormattedMessage id="btn_text_remove" />
          </Button>
        )}
        <Button onClick={handleAccept}>
          <FormattedMessage id="btn_text_accept" />
        </Button>
      </Grid>
    </Grid>
  );
};

export default Layout;
