import { FC, HTMLAttributes } from "react";
import clsx from "clsx";
import { useStyles } from "./styles";
import { BusinessProfileInfoPayload } from "../../graphql/GetBusinessProfileInfo.query";
import { TykchaseConfigStatus } from "../../gql/graphql";
import { UnableToProcessPaymentNotification } from "./notifications/UnableToProcessPaymentNotification";
import { MembershipCancellationNotification } from "./notifications/MembershipCancellationNotification";

export interface TykChaseMembershipNotificationProps extends HTMLAttributes<HTMLDivElement> {
  businessProfileInfo?: BusinessProfileInfoPayload;
}

export const TykChaseMembershipNotification: FC<TykChaseMembershipNotificationProps> = ({
  className,
  businessProfileInfo,
  ...divAttributes
}) => {
  const styles = useStyles();
  const userId = businessProfileInfo?.id ?? "";

  if (businessProfileInfo?.status === TykchaseConfigStatus.PastDue && !!businessProfileInfo?.dueDate) {
    return (
      <div className={clsx(styles.container, className)} {...divAttributes} data-testid="unable-to-pay-notification">
        <UnableToProcessPaymentNotification userId={userId} dueDate={businessProfileInfo.dueDate} />
      </div>
    );
  }

  if (
    businessProfileInfo?.status === TykchaseConfigStatus.CancellationScheduled &&
    !!businessProfileInfo?.membershipCancellationDate
  ) {
    return (
      <div className={clsx(styles.container, className)} {...divAttributes} data-testid="cancellation-notification">
        <MembershipCancellationNotification cancellationDate={businessProfileInfo.membershipCancellationDate} />
      </div>
    );
  }

  return null;
};
