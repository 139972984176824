import { FC } from "react";
import { Box, Grid } from "@mui/material";
import TimeFrameFilter from "../TimeFrameFilter";
import ExportButton from "../ExportButton";
import PsychographicsInsights from "./PsychographicsInsights";
import AgeRange from "./AgeRange";
import Genre from "./Genre";
import AudienceInterest from "./AudienceInterest";
import useStyles from "../General/styles";

const Psychographics: FC = () => {
  const { wrap, contentWrap } = useStyles();
  return (
    <Box className={wrap}>
      <Box className={contentWrap}>
        <Grid container spacing={2} width="100%">
          <Grid item sm={12} display="flex" justifyContent="space-between" marginBottom="20px">
            <TimeFrameFilter />

            <ExportButton />
          </Grid>

          <Grid item container spacing={2} lg={8} md={12} width="100%">
            <Grid item md={6} width="100%">
              <AgeRange />
            </Grid>

            <Grid item md={6} width="100%">
              <Genre />
            </Grid>

            <Grid item sm={12} width="100%">
              <AudienceInterest />
            </Grid>
          </Grid>

          <Grid item lg={4} md={12} width="100%">
            <PsychographicsInsights />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Psychographics;
