export const downloadFile = async (fileUrl: string): Promise<void> => {
  const response = await fetch(fileUrl);
  const blob = await response.blob();
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  const fileName = fileUrl.split("/").pop();
  link.download = fileName as string;
  document.body.appendChild(link);
  link.click();
  URL.revokeObjectURL(url);
  link.remove();
};
