import { FC } from "react";
import { useIntl } from "react-intl";
import { Select } from "../../../Select";

export interface SelectItem {
  value: string;
  label: string;
}

export interface PaymentMethodsSelectProps {
  className?: string;
  options: SelectItem[];
  value: string | null;
  onChange: (value: string | null) => void;
}

export const PaymentMethodsSelect: FC<PaymentMethodsSelectProps> = ({
  className,
  options,
  value,
  onChange: handleChange,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Select
      backspaceDelete={false}
      clearable={false}
      searchable={false}
      options={options}
      value={value}
      onChange={handleChange}
      placeholder={formatMessage({ id: "select_payment_method_placeholder" })}
      label={formatMessage({ id: "select_payment_method_label" })}
      className={className}
    />
  );
};
