import { useState } from "react";
import { Box, Button, TextareaAutosize, Radio, Alert, Link } from "@mui/material";
import clsx from "clsx";
import { useIntl } from "react-intl";
import MailOpen from "../../../../assets/icons/contact/Icon-mail-open.svg";
import { MailIcon, PhoneIcon } from "../../../../utils/icon";
import Modal from "../../../Modal";
import useStyles from "./styles";

interface BusinessData {
  email?: string | null;
  phoneNumber?: string | null;
}
interface ModalContactTypes {
  isOpen: boolean;
  handleClose: () => void;
  businessData: BusinessData;
  brokerEmail?: string | null;
}

const ModalContact = ({ isOpen, handleClose, businessData, brokerEmail }: ModalContactTypes) => {
  const { formatMessage } = useIntl();
  const { wrap, header, messageWrapper, contact, buttonsContainer, warningAlert } = useStyles();
  const [contacted, setContacted] = useState(false);
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [isShowWarning, setIsShowWarning] = useState(false);

  const handleChange = (event) => {
    if (isError) {
      setIsError(false);
    }
    setMessage(event.target.value);
  };

  const handleCloseModal = () => {
    setMessage("");
    setIsError(false);
    handleClose();
  };

  const handleCancel = () => {
    if (!message) {
      handleCloseModal();
      return;
    }

    setIsShowWarning(true);
  };

  const handleClickYes = () => {
    setIsShowWarning(false);
    handleCloseModal();
  };

  const handleClickNo = () => {
    setIsShowWarning(false);
  };

  const handleSend = () => {
    if (!message) {
      setIsError(true);
      return;
    }
    handleCloseModal();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleCancel}>
      <Box className={wrap}>
        <Box className={header}>
          <Box className="topContainer">
            <h2>{formatMessage({ id: "modals.contact_tykbroker.title" })}</h2>

            <img src={MailOpen} alt="Mail" />
          </Box>

          <p className="subTitle">{formatMessage({ id: "modals.contact_tykbroker.sub_title" })}</p>
        </Box>

        <Box className={messageWrapper}>
          <p>{formatMessage({ id: "modals.contact_tykbroker.message" })}</p>

          <TextareaAutosize
            minRows={6}
            placeholder={formatMessage({ id: "inputs.type_your_inquiry" })}
            onChange={handleChange}
            value={message}
            maxLength={65}
          />

          <Box className="errorContainer">
            {isError && <Alert severity="error">{formatMessage({ id: "error.message_cannot_be_empty" })}</Alert>}
          </Box>
        </Box>

        <Box className={contact}>
          <h3>{formatMessage({ id: "modals.contact_tykbroker.want_to_be_contacted" })}</h3>

          <Box className="content">
            {businessData.email && (
              <Box
                className={clsx("item", {
                  active: !contacted,
                })}
                onClick={() => setContacted(false)}
                role="button"
                tabIndex={0}
              >
                <Box>
                  <Radio checked={!contacted} />

                  <Box padding="0 10px">
                    <MailIcon stroke={!contacted ? "#4262FF" : "#8986ad"} />
                  </Box>

                  <p className="contactMethod">{formatMessage({ id: "modals.contact_tykbroker.mail" })}</p>
                </Box>

                <p className="contactData">{businessData.email}</p>
              </Box>
            )}

            {businessData.phoneNumber && (
              <Box
                className={clsx("item", {
                  active: contacted,
                })}
                onClick={() => setContacted(true)}
                role="button"
                tabIndex={0}
              >
                <Box>
                  <Radio checked={contacted} />

                  <Box padding="0 10px">
                    <PhoneIcon stroke={contacted ? "#4262FF" : "#8986ad"} />
                  </Box>

                  <p className="contactMethod">{formatMessage({ id: "modals.contact_tykbroker.mobile" })}</p>
                </Box>

                <p className="contactData">{businessData.phoneNumber}</p>
              </Box>
            )}
          </Box>
        </Box>

        {!isShowWarning ? (
          <Box className={buttonsContainer}>
            <Button onClick={handleCancel} className="secondary-button">
              {formatMessage({ id: "btn_text_cancel" })}
            </Button>

            <Button onClick={handleSend} className="primary-button">
              {message.length > 0 && (
                <Link
                  href={`mailto:${brokerEmail}?body=${encodeURIComponent(message)}`}
                  target="_blank"
                  rel="noreferrer"
                  className="link"
                />
              )}
              {formatMessage({ id: "btn_text_send" })}
            </Button>
          </Box>
        ) : (
          <Alert
            severity="warning"
            className={warningAlert}
            action={
              <>
                <Button onClick={handleClickYes}> {formatMessage({ id: "btn_text_yes" })}</Button>

                <Button onClick={handleClickNo}> {formatMessage({ id: "btn_text_no" })}</Button>
              </>
            }
          >
            {formatMessage({ id: "modals.contact_tykbroker.are_you_sure" })}
            <br />
            <b>{formatMessage({ id: "modals.contact_tykbroker.leave_without_sending" })}</b>
          </Alert>
        )}
      </Box>
    </Modal>
  );
};

export default ModalContact;
