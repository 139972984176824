import clsx from "clsx";
import { FC, HTMLAttributes } from "react";
import { FormattedMessage } from "react-intl";
import tykWoodIcon from "../../assets/icons/paymentIcons/tykwoodIcon.svg";
import { useStyles } from "./styles";

export const TykWoodLogo: FC<HTMLAttributes<HTMLDivElement>> = ({ className, ...attributes }) => {
  const styles = useStyles();

  return (
    <div className={clsx(styles.container, className)} {...attributes}>
      <span className={styles.label}>
        <FormattedMessage id="secure_transactions_by" />
      </span>
      <img className={styles.logo} src={tykWoodIcon} alt="tyk_wood_logo" />
    </div>
  );
};
