import behavior from "../../../../assets/icons/campaign/behavior.svg";

export type Rule = {
  id: string;
  name: string;
  type?: string;
  spent: number;
  distributed?: {
    tyks: number;
    loneStars: number;
  };
  behavior?: string;
  end?: string;
};

export const rules: {
  count: number;
  rows: Rule[];
} = {
  rows: [
    {
      id: "0",
      name: "Buy a Large Pizza",
      type: "One time rule",
      spent: 200,
      distributed: {
        tyks: 200,
        loneStars: 50,
      },
      behavior,
    },
    {
      id: "1",
      name: "General Scan Receipt",
      spent: 150,
      distributed: {
        tyks: 120,
        loneStars: 10,
      },
      behavior,
    },
    {
      id: "2",
      name: "Like in Biz posts",
      spent: 150,
      distributed: {
        tyks: 98,
        loneStars: 15,
      },
      behavior,
    },
    {
      id: "3",
      name: "Following Biz Profile",
      spent: 100,
      distributed: {
        tyks: 200,
        loneStars: 50,
      },
      behavior,
    },
    {
      id: "4",
      name: "Like in Biz posts",
      spent: 100,
      distributed: {
        tyks: 200,
        loneStars: 50,
      },
      behavior,
    },
  ],
  count: 14,
};
