import { Box, Stack, Typography, useTheme } from "@mui/material";
import { RewardsSublistProps } from "./interfaces/rewards-sublist.interface";

export function RewardsSublist(props: RewardsSublistProps): JSX.Element {
  const theme = useTheme();

  return (
    <Stack spacing={4} my={theme.spacing(4)}>
      {props.items.map((item) => (
        <Box sx={{ ml: `${theme.spacing(8)} !important` }} key={item.header}>
          <Typography component="span" fontWeight="bold">
            {item.letter}.
          </Typography>
          <Typography component="span" fontWeight="bold" sx={{ textDecoration: "underline", ml: theme.spacing(4) }}>
            {item.header}
          </Typography>
          <Typography component="span" sx={{ ml: theme.spacing(4) }}>
            {item.text}
          </Typography>
        </Box>
      ))}
    </Stack>
  );
}
