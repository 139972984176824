import { debounce } from "lodash";
import MapboxGeocoding from "@mapbox/mapbox-sdk/services/geocoding";

import { useState, FC } from "react";
import { Stack, Autocomplete, TextField, Checkbox, Box, Typography, Alert, InputLabel } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { useStyles } from "./styles";
import { AddressContext, AddressCreationFormProps, CityOption, InitialPlace } from "./types";
import { AddressContextId } from "../constants";

export const AddressCreationForm: FC<AddressCreationFormProps> = ({
  addressFieldsProps,
  errors,
  isBilling,
  touched,
}) => {
  const { addressCity, addressStreet1, addressStreet2, label, zipCode, isDefault, shortCountryCode, regionName } =
    addressFieldsProps;
  const styles = useStyles();
  const { formatMessage } = useIntl();

  const [places, setPlaces] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState({ place_name: addressCity.value } as InitialPlace);

  const geocodingClient = MapboxGeocoding({
    accessToken: process.env.REACT_APP_MAPBOX_API_TOKEN as string,
  });

  const handleChange = async (value: string, addressContext?: AddressContext[]) => {
    if (isBilling && addressContext && shortCountryCode && regionName) {
      const regionContext =
        addressContext.find((item) => {
          return item.id.startsWith(AddressContextId.Region);
        }) ?? "";
      const countryContext =
        addressContext.find((item) => {
          return item.id.startsWith(AddressContextId.Country);
        }) ?? "";
      shortCountryCode.setFieldValue(countryContext ? countryContext.short_code : "");
      regionName.setFieldValue(regionContext ? regionContext.text : "");
    }
    addressCity.onChange(value);
    setSelectedPlace({ place_name: value });
  };

  const handleInputChange = debounce(async (value: string) => {
    const response = await geocodingClient
      .forwardGeocode({
        query: value,
        limit: 20,
        types: ["place"],
        mode: "mapbox.places",
      })
      .send();

    setPlaces(response.body.features);
  }, 200);

  const formatOptionLabel = (option: CityOption) => {
    return option?.place_name;
  };

  return (
    <Stack spacing={1}>
      <Box>
        <Typography className={styles.label}>
          <FormattedMessage id="create_account_address_form.selectcity" />
        </Typography>
        <Autocomplete
          options={places}
          getOptionLabel={formatOptionLabel}
          value={selectedPlace}
          renderInput={(params) => (
            <TextField
              {...params}
              title={String(params.inputProps.value)}
              placeholder={formatMessage({ id: "create_account_address_form.enter_your_city" })}
            />
          )}
          onChange={(e, value) => {
            handleChange(value?.place_name || "", value?.context);
          }}
          onInputChange={(e, newValue) => {
            handleInputChange(newValue);
          }}
          classes={{ noOptions: styles.onOptions }}
          className={styles.autocomplete}
        />

        {errors.addressCity && touched.addressCity && (
          <Alert severity="error" className={styles.alert}>
            <FormattedMessage id="create_account_address_form.required_field" />
          </Alert>
        )}
      </Box>
      <Box>
        <Typography className={styles.label}>
          <FormattedMessage id="create_account_address_form.street_address" />
        </Typography>
        <TextField
          {...addressStreet1}
          placeholder={formatMessage({ id: "create_account_address_form.street_first_address_placeholder" })}
          className={styles.input}
        />
        {errors.addressStreet1 && touched.addressStreet1 && (
          <Alert severity="error" className={styles.alert}>
            <FormattedMessage id="create_account_address_form.required_field" />
          </Alert>
        )}
      </Box>
      <Box>
        <TextField
          {...addressStreet2}
          placeholder={formatMessage({ id: "create_account_address_form.street_second_address_placeholder" })}
          className={styles.input}
        />
      </Box>
      {!isBilling && (
        <Box>
          <Typography className={styles.label}>
            <FormattedMessage id="create_account_address_form.label_or_alias_for_this_address" />
          </Typography>
          <TextField
            {...label}
            placeholder={formatMessage({ id: "create_account_address_form.address_name" })}
            className={styles.input}
          />
          {errors.label && touched.label && (
            <Alert severity="error" className={styles.alert}>
              <FormattedMessage id="create_account_address_form.required_field" />
            </Alert>
          )}
        </Box>
      )}
      <Box>
        <Typography className={styles.label}>
          <FormattedMessage id="create_account_address_form.postal_zip_code" />
        </Typography>
        <TextField
          {...zipCode}
          placeholder={formatMessage({ id: "create_account_address_form.enter_the_zip_code_for_this_ad" })}
          className={styles.input}
        />
        {errors.zipCode && touched.zipCode && (
          <Alert severity="error" className={styles.alert}>
            <FormattedMessage id="create_account_address_form.required_field" />
          </Alert>
        )}
      </Box>
      {!isBilling && (
        <InputLabel>
          <Stack direction="row" alignItems="center">
            <Typography className={styles.label}>
              <FormattedMessage id="create_account_address_form.save_as_default_address" />
            </Typography>
            <Checkbox color="primary" {...isDefault} checked={isDefault.value} />
          </Stack>
        </InputLabel>
      )}
    </Stack>
  );
};
