import { useCallback } from "react";
import { useQueryParams } from "./useQueryParams.hook";
import { useSetQueryParams } from "./useSetQueryParams.hook";

export const useQueryParameterState = <T extends string>(key: string, defaultValue: T): [T, (value: T) => void] => {
  const { [key]: parameterValue } = useQueryParams();
  const setQueryParams = useSetQueryParams();

  const setParameterValue = useCallback(
    (value: string) => {
      setQueryParams({ [key]: value });
    },
    [setQueryParams, key],
  );

  return [(parameterValue as T) ?? defaultValue, setParameterValue];
};
