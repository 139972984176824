import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    wrap: {
      maxWidth: "650px",
      minWidth: "200px",
      "& h4": {
        marginBottom: "25px",
        color: "#34305D",
        fontSize: "18px",
      },
      "& .MuiFormGroup-root": {
        padding: "10px",
        "& .MuiCheckbox-root": {
          "& .MuiSvgIcon-root": {
            width: "18px",
            height: "18px",
          },
        },
        "& .MuiCheckbox-root.Mui-checked": {
          "& .MuiSvgIcon-root": {
            fill: "#4262FF",
          },
        },
      },
    },

    titleContainer: {
      display: "flex",
      alignItems: "center",
      gap: "2rem",

      "& img": {
        width: "30px",
      },
    },
    termsSection: {
      width: "100%",
      border: "1px solid #D3D3D3",
      borderRadius: "10px",
      padding: "2rem",
      height: "450px",
    },

    termsContainer: {
      height: "100%",
      overflow: "hidden",
    },
  };
});

export default useStyles;
