import { useState, useLayoutEffect } from "react";

const usePagination = <T>({ list, rowsPerPage }: { list: T[]; rowsPerPage: number }) => {
  const [page, setPage] = useState(0);

  const [rows, setRows] = useState<T[]>([]);
  const [emptyRows, setEmptyRows] = useState(0);

  useLayoutEffect(() => {
    const fromRow = page * rowsPerPage;
    const toRow = fromRow + rowsPerPage;
    const rowsData = rowsPerPage > 0 ? list.slice(fromRow, toRow) : list;
    setRows(rowsData);

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRowsData = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - list.length) : 0;
    setEmptyRows(emptyRowsData);
  }, [page, list, rowsPerPage]);

  return { rows, emptyRows, page, setPage };
};

export default usePagination;
