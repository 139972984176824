import { ReactNode } from "react";
import { FormikProps } from "formik";
import { RuleName } from "../generated/types";
import { MessageIds } from "../i18n";
import { RewardInputs, RewardInputsEnum } from "../components/Rewards/types";
import { RewardRuleModalHeaderControlsProps } from "../components/Rewards/RewardRuleModal/RewardRuleModalHeaderControls/RewardRuleModalHeaderControls.interfaces";

export interface IMembership {
  id: number;
  name: string;
  description: string;
  type: string;
  price: number;
  perks: Perks;
  pricePerMonth: number;
  title: string;
}

export interface MembershipDataDetails {
  title: MessageIds;
  features: MessageIds[];
}

export interface IMembershipData {
  title: string;
  internalId?:number | string ;
}

export interface IMembershipRule {
  id: string;
  title: string;
  isActive: boolean;
  isSelected: boolean;
  category: string;
  categorySpanish?:string;
  reward?: number;
  internalId: number;
  ruleName: RuleName;
  backendData?: any;
  multipleTimeReward?: boolean;
  headerControls?: boolean;
  pickedRewardInputs: RewardInputsEnum[];
  rewardRuleModalHeaderControls?: (headerControls: RewardRuleModalHeaderControlsProps) => ReactNode;
  rewardRuleModalSubHeader?: (formik: FormikProps<RewardInputs>) => ReactNode;
  rewardRuleModalFooter?: (formik: FormikProps<RewardInputs>) => ReactNode;
}

export type Perks = {
  analytics?: string[];
  rules?: string[];
  tyksPrice?: string[];
  support?: string[];
};
export enum Memberships {
  Business = "Business",
  Enterprise = "Enterprise",
  Brands = "Brands",
}
export enum MembershipsInternalId {
  Business = 1,
  Enterprise = 2,
  Brands = 3,
}
export enum MembershipsSpanish {
  Negocios = "Negocios",
  Empresa = "Empresa",
  Marcas = "Marcas",
}
