import { FC, useState } from "react";
import {
  ButtonBase,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { Info, Message } from "@mui/icons-material";
import RewardsIconGreen from "../../../../assets/icons/tykIcons/rewardsIcon.svg";
import RewardsIconYellow from "../../../../assets/icons/tykIcons/rewardsIconYelow.svg";
import useStyles from "./styles";
import { Views, Winner } from "../types";

interface WinnersTableProps {
  winners: Winner[];
  onChangeView: (winner: any, view) => void;
}

const WinnersTable: FC<WinnersTableProps> = ({ winners, onChangeView }) => {
  const { tableContainer, paginationContainer } = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(4);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <TableContainer className={tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Winner</TableCell>

              <TableCell>Date</TableCell>

              <TableCell>Amount</TableCell>

              <TableCell>Attemps</TableCell>

              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {winners.map((winner) => (
              <TableRow key={winner.id}>
                <TableCell>{winner.name}</TableCell>

                <TableCell>{winner.date}</TableCell>

                <TableCell>
                  <div className="amount-container">
                    <div className="amount-item">
                      <img src={RewardsIconGreen} alt="reward-icon-green" />

                      <p>{winner.amount.green}</p>
                    </div>

                    <div className="amount-item">
                      <div className="lonestart-container">
                        <img src={RewardsIconYellow} alt="reward-icon-yellow" />
                      </div>

                      <p>{winner.amount.yellow}</p>
                    </div>
                  </div>
                </TableCell>

                <TableCell align="right">{winner.attemps}</TableCell>

                <TableCell align="right">
                  <div className="actions-container">
                    <ButtonBase className="actions-item">
                      <Message onClick={() => onChangeView(winner, Views.CHAT)} />
                    </ButtonBase>

                    <ButtonBase className="actions-item">
                      <Info onClick={() => onChangeView(winner, Views.DETAILS)} />
                    </ButtonBase>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid className={paginationContainer}>
        <TablePagination
          rowsPerPageOptions={[4, 6]}
          component="div"
          count={winners.length}
          labelRowsPerPage="Items per page"
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton
          showLastButton
        />

        <Typography>Next</Typography>
      </Grid>
    </>
  );
};

export default WinnersTable;
