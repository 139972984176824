import { useHistory, useRouteMatch } from "react-router-dom";
import dateFormat from "dateformat";
import { Card, CardContent, CardActions, CardHeader, Box, Typography, Stack, IconButton } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import toImg from "react-svg-to-image";
import { FormattedMessage } from "react-intl";
import { ReactComponent as DownloadIcon } from "../../../../assets/icons/technology/feather-download-Icon.svg";
import { ReactComponent as EditIcon } from "../../../../assets/icons/technology/noun-edit-icon.svg";
import { ReactComponent as LinkIcon } from "../../../../assets/icons/technology/ionic-ios-link.svg";
import { ReactComponent as NoImgIcon } from "../../../../assets/icons/technology/metro-file-picture.svg";
import useStyles from "./styles";
import theme from "../../../../theme";

interface IQrCodeCard {
  id: string;
  qrName: string;
  title: string;
  url: string;
  endDate: string;
  imgSrc?: string;
}

const ImageBox = ({ src }: { src?: string }) => {
  if (src) {
    return <img src={src} alt="img" className="eventImg" />;
  }

  return (
    <Box className="eventImg empty">
      <NoImgIcon />
    </Box>
  );
};

const QrCodeCard = ({ id, qrName, title, url, endDate, imgSrc }: IQrCodeCard) => {
  const { card } = useStyles();
  const { path } = useRouteMatch();
  const history = useHistory();

  const handleEditCLick = () => {
    history.push(`${path}/Edit/${id}`);
  };

  const qrDownload = (fileName: string, qrId: string) => {
    const SCALE = 5;
    toImg(`#${qrId}`, fileName, {
      scale: SCALE,
      format: "jpeg",
      download: true,
    });
  };

  return (
    <Card className={card}>
      <CardActions className="cardActions">
        <IconButton
          onClick={() => {
            qrDownload(qrName, id);
          }}
          className="icon-button"
        >
          <DownloadIcon />
        </IconButton>
        <IconButton onClick={handleEditCLick} className="icon-button">
          <EditIcon />
        </IconButton>
      </CardActions>
      <CardHeader title={qrName} className="cardHeader" />
      <CardContent className="cardContent">
        <Stack direction={{ xs: "column", md: "row" }} spacing={1} alignItems="center">
          <Box flexShrink={0}>
            <QRCodeSVG size={96} value={url} id={id} includeMargin />
          </Box>
          <Stack direction={{ xs: "column", md: "row" }} spacing={2} alignItems="center">
            <Stack direction={{ xs: "column", md: "row" }} spacing={2} alignItems="center">
              <Box className="linkedTo">
                <LinkIcon />
                <Typography className="linkedToText">
                  <FormattedMessage id="technology.qr_codes.linked_to" />
                </Typography>
              </Box>
              <ImageBox src={imgSrc} />
            </Stack>
            <Box maxWidth="215px">
              <Typography component="span" display="block">
                {title}
              </Typography>
              <Typography component="span" display="block" color={theme.palette.purple} fontSize="0.75rem">{`${(
                <FormattedMessage id="technology.qr_codes.ends" />
              )} > ${dateFormat(endDate, "mmmm, dS yyyy")}`}</Typography>
            </Box>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default QrCodeCard;
