import { FC, useCallback, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Grid, Typography } from "@mui/material";
import { ImageOutlined } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import ImportantIcon from "../../../assets/icons/tykIcons/important-icon.svg";
import EventsTable from "./EventsTable";
import useStyles from "./styles";
import { events } from "./data";
import Stepper from "./Stepper";

const SimpleAdvertising: FC = () => {
  const { simpleAdvertisingContainer, buttonsWrapper } = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [selectedEvents, setSelectedEvents] = useState<Event[]>([]);
  const [campaignSettings, setCampaignSettings] = useState({});

  const handleEventsSelected = useCallback((updatedEvents) => {
    setSelectedEvents(updatedEvents);
  }, []);

  const handleUpdateCampaignSettings = useCallback((settings) => {
    setCampaignSettings(settings);
  }, []);

  const handleNextClick = () => {
    history.push({
      pathname: `${location.pathname}/Summary`,
      state: {
        events: selectedEvents,
        campaignSettings,
      },
    });
  };

  return (
    <Grid className={simpleAdvertisingContainer}>
      <Typography className="simple-advertising-title">
        <FormattedMessage id="marketing.simple_advertising.title" />
      </Typography>

      <Grid className="simple-advertising-container">
        <Grid className="simple-advertising-content">
          <Grid className="steps-container">
            <Stepper onUpdateCampaignSettings={handleUpdateCampaignSettings} />

            <div className="info-wrapper">
              <img src={ImportantIcon} alt="important-icon" />

              <p>
                <FormattedMessage id="marketing.simple_advertising.title" />
              </p>
            </div>
          </Grid>

          <Grid className="events-container">
            <EventsTable events={events} onEventsSelected={handleEventsSelected} />

            <div className="preview-wrapper">
              <p>
                <FormattedMessage id="marketing.simple_advertising.preview" />
              </p>

              <div className="preview-image-wrapper">
                <ImageOutlined />
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid className="simple-advertising-footer">
          <div className={buttonsWrapper}>
            <Button onClick={() => history.goBack()} variant="contained" className="secondary-button">
              <FormattedMessage id="btn_text_back" />
            </Button>

            <Button
              onClick={handleNextClick}
              variant="contained"
              className="primary-button"
              disabled={!selectedEvents.length || Object.keys(campaignSettings).length === 0}
            >
              <FormattedMessage id="btn_text_next" />
            </Button>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SimpleAdvertising;
