import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  container: {
    "& *.MuiTypography-body1, & *.MuiButton-root": {
      fontFamily: '"AdobeClean", "AdobeCleanSerif", "Poppins", "sans-serif"',
    },
  },
  setupRewardsBtn: {
    "&.MuiButton-root": {
      padding: ".5rem 2rem",
      marginTop: "1rem",
      borderRadius: "16px",
      height: "32px",
      minHeight: "32px",
      backgroundColor: "#0364FF",
      fontWeight: 600,
      fontSize: ".9rem",
      textTransform: "unset",
    },
  },
}));
