import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    mainDiv: {
      display: "grid",
      width: "90%",
      padding: "2rem 1rem",
      backgroundColor: "#FAFAF",
      overflowY: "auto",
      gridTemplateColumns: "repeat(6, 1fr)",
      gridTemplateRows: "repeat(5, minmax(min-content, max-content))",
      gridTemplateAreas: `
        'thisMonth thisMonth thisMonth thisMonth thisMonth thisMonth'
        'info info info info info info'
        'yourRewards yourRewards  yourRewards yourRewards yourRewards yourRewards'`,
      gap: "10px",

      [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
        width: "100%",
      },

      "@media all and (max-width: 600px)": {
        overflow: "hidden",
      },

      "& .totalCentorAmount": {
        fontWeight: "bold",
        color: theme.palette.primary.dark,
        fontSize: "16px",
      },
    },

    mainDivGold: {
      display: "grid",
      width: "90%",
      padding: "2rem 1rem",
      backgroundColor: "#FAFAF",
      overflowY: "auto",
      gridTemplateColumns: "repeat(6, 1fr)",
      gridTemplateRows: "repeat(5, minmax(min-content, max-content))",
      gridTemplateAreas: `
        'thisMonth thisMonth thisMonth thisMonth thisMonth thisMonth'
        'info info info info info info'
        'yourRewards yourRewards  yourRewards yourCampaigns yourCampaigns yourCampaigns'
        'analytics analytics analytics analytics analytics analytics'`,
      gap: "10px",

      [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
        width: "100%",
      },

      "@media all and (max-width: 600px)": {
        overflow: "hidden",
      },

      "& .totalCentorAmount": {
        fontWeight: "bold",
        color: theme.palette.primary.dark,
        fontSize: "16px",
      },
    },
  };
});

export default useStyles;
