import { LoadingButton } from "@mui/lab";
import { Dialog, Typography, Divider, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import { Box } from "@mui/system";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { IReceiptRemoveModal } from "./types";
import useStyles from "./styles";
import { ReactComponent as WarningIcon } from "../../../assets/icons/tykIcons/exclamation-triangle.svg";

export const ReceiptRemoveModal: FC<IReceiptRemoveModal> = ({ open, onClose, onSubmit, isLoading }) => {
  const { receiptRemoveModal } = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="receipt-delete-dialog"
      aria-describedby="receipt-delete-dialog"
      className={receiptRemoveModal}
    >
      <Box className="modal">
        <Box className="modal-title">
          <Typography className="modal-title-text">
            <FormattedMessage id="locations_remove_address_modal_title" />
          </Typography>
          <WarningIcon className="warning-icon" />
        </Box>
        <Divider />
        <DialogContent className="modal-content">
          <DialogContentText>
            <FormattedMessage id="locations_remove_address_modal_descr" />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="outlined"
            disabled={isLoading}
            loading={isLoading}
            onClick={onSubmit}
            className="submit-btn btn"
            classes={{ loadingIndicator: "loading-indicator" }}
          >
            <FormattedMessage id="btn_text_yes" />
          </LoadingButton>
          <Button variant="contained" disabled={isLoading} onClick={onClose} className="cancel-btn btn">
            <FormattedMessage id="btn_text_cancel" />
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
