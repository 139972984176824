import { FC, ElementType } from "react";
import { useUnifiedLogin } from "@stagewood/unified-login-library";
import { Route } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { Grid } from "@mui/material";
import AbsoluteRedirect from "./AbsoluteRedirect";

interface PrivateRouteProps {
  component: ElementType;
  path: string;
  exact?: boolean;
}

const PrivateRoute: FC<PrivateRouteProps> = ({ component: RouteComponent, ...rest }) => {
  const { user, isLoading, isAuthenticated } = useUnifiedLogin();

  if (isLoading) {
    return (
      <Grid container direction="column" justifyContent="center" alignItems="center" style={{ minHeight: "60vh" }}>
        <Grid item>
          <BeatLoader color="#EE2970" />
        </Grid>
      </Grid>
    );
  }

  return (
    <Route
      {...rest}
      render={(props) => (isAuthenticated ? <RouteComponent {...props} /> : <AbsoluteRedirect to="/qr" />)}
    />
  );
};
export default PrivateRoute;
