import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => {
  return {
    cardWrap: {
      padding: "20px",
      maxWidth: "500px",
      border: "1px solid transparent",
    },
    cardMargin: {
      marginBottom: "10px",
      borderRadius: "20px",
    },
    cardBrand: {
      fontSize: "12px",
      marginLeft: "10px",
      alignSelf: "center",
      textTransform: "uppercase",
      fontWeight: "bold",
    },

    defaultButton: {
      fontSize: "12px",
      height: "25px",
      lineHeight: "10px",
      border: "1px solid",
      fontWeight: "bold",
    },

    cardNumber: {
      fontSize: "18px",
      fontWeight: "bold",
      marginBottom: "10px",
    },

    cardExpDate: {
      fontSize: "14px",
      marginBottom: "10px",
      fontWeight: "100",
      paddingTop: "5px",
      paddingBottom: "5px",
    },
    chosenCard: { border: "1px solid #203ED4" },

    cardOwner: {
      fontSize: "16px",
      marginBottom: "10px",
    },

    input: {
      height: "0.8rem",
      lineHeight: "0.8rem",
      padding: 0,
    },

    cardExpDateInput: {
      width: "80px",
    },
  };
});
