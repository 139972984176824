import { Button } from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import Modal from "../../../Modal";
import { TykWoodLogo } from "../../../TykWoodLogo";
import { useStyles } from "./styles";
import { useFormattedRechargeAmount } from "./useFormattedRechargeAmount.hook";

interface DepositRechargeSuccessModalProps {
  isActive: boolean;
  onClose: VoidFunction;
}

export const DepositRechargeSuccessModal: FC<DepositRechargeSuccessModalProps> = ({
  isActive,
  onClose: handleClose,
}) => {
  const styles = useStyles();
  const formattedAmount = useFormattedRechargeAmount();

  return (
    <Modal isOpen={isActive} onClose={handleClose}>
      <div className={styles.modalContent}>
        <h1 className={styles.modalHeader}>
          <FormattedMessage id="tyk_deposit_settings" />
        </h1>
        <TykWoodLogo className={styles.tykWoodLogo} />
        <div className={styles.successMessage}>
          <TaskAltIcon className={styles.successIcon} />
          <div>
            <FormattedMessage id="your_payment_for" />
            <strong>{formattedAmount}</strong>
            <br />
            <FormattedMessage id="was_processed_successfully" />
          </div>
        </div>
        <Button
          className={styles.okButton}
          type="button"
          onClick={handleClose}
          classes={{ root: "root" }}
        >
          <FormattedMessage id="btn_text_ok" />
        </Button>
      </div>
    </Modal>
  );
};
