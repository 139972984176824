import { makeStyles } from "@mui/styles";

const TABLE_BORDER = "1px solid #D3D3D3";

export const useStyles = makeStyles((theme) => {
  return {
    mainWrap: {
      marginTop: "0",
      padding: "20px 48px",

      "@media(max-width:1170px)": {
        padding: "20px 20px",
      },
    },

    topWrap: {
      "&.MuiGrid-root": {
        alignItems: "end",
        justifyContent: "flex-start",
        gap: "30px",
        flexWrap: "nowrap",
        maxWidth: "100% !important",
        marginLeft: "0 ",
        "@media(max-width:900px)": {
          flexWrap: "wrap",
          justifyContent: "end",
        },
      },
    },

    topItemsWrap: {
      "&:first-child": {
        paddingLeft: "0 !important",
      },

      "@media(max-width: 1200px)": {
        "&:last-child": {
          maxWidth: "30%",
        },
      },
      "@media(max-width:900px)": {
        width: "49%",
      },
    },

    searchBar: {
      "& > .MuiGrid-root": {
        paddingBottom: "0px",
        paddingTop: "4px!important",
        "& .MuiInputBase-root": {
          height: "32px",
          paddingRight: "5px",
        },
      },
      "& .MuiInputBase-root": {
        width: "100%",
        border: "1px solid #D3D3D3",
        borderRadius: "5px",
        background: "#FFFFFF",
      },
      "& .MuiInputBase-input": {
        paddingLeft: "5px",
        fontFamily: '"AdobeClean", "AdobeCleanSerif", "Poppins", "sans-serif"',
        "&::placeholder, &::-webkit-input-placeholder": {
          fontSize: "0.9rem",
          fontStyle: "italic",
          color: "#959595",
          opacity: "1 !important",
        },
      },
    },
    dropDown: {
      "& > .MuiGrid-root": {
        paddingBottom: "0px",
        paddingTop: "4px!important",
      },
      "& .react-dropdown-select": {
        minHeight: "32px",
        maxHeight: "32px",
      },
      "& .css-wmw4vi-ReactDropdownSelect": {
        borderRadius: 5,
        border: "1px solid #D3D3D3",
        background: "#FFFFFF",
        "& input": {
          fontStyle: "italic",
          "&::placeholder": {
            fontSize: "0.9rem",
            fontStyle: "italic",
            color: "#959595",
          },
        },
      },
    },
    table: {
      width: "100%",
      "& .tableBody": {
        borderRadius: "10px",
        border: TABLE_BORDER,
        overflow: "hidden",
      },
      "& .tableHead": {
        height: "40px",
        "@media (max-width: 900px)": {
          height: "30px",
        },
        "& .MuiBox-root": {
          color: "#959595",
          fontSize: "0.9rem",
          "& svg": {
            marginLeft: "5px",
            width: "0.6em",
            height: "0.6em",
          },
        },
      },
      "& .tableHead, & .tableRow": {
        "@media (max-width: 900px)": {
          gridTemplateColumns: "minmax(91px, 3fr) minmax(61px, 2fr) minmax(25px ,1fr) minmax(19px ,1fr)",
        },
        "& .MuiBox-root": {
          display: "flex",
          alignItems: "center",
          padding: "0 18px",
          "@media (max-width: 900px)": {
            padding: "0 12px",
          },
          "&:nth-child(2)": {
            paddingLeft: "50px",
            "@media (max-width: 900px)": {
              paddingLeft: "10px",
            },
          },
          "&:nth-child(3)": {
            justifyContent: "center",
          },
          "&:last-child": {
            justifyContent: "end",
            paddingLeft: "0",
            paddingRight: "2px",
          },
        },
      },
      "& .tableRow": {
        backgroundColor: "#ffffff",
        height: "50px",
        transition: "300ms ease-in-out",
        "&:hover": {
          backgroundColor: "#efefef",
        },
        "& + .tableRow": {
          borderTop: TABLE_BORDER,
        },
        "& > .MuiBox-root": {
          color: "#454545",
          "&:first-child": {
            borderRight: TABLE_BORDER,
          },
        },
      },
    },
    emptyData: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#ffffff",
      padding: "20px 0px",
      flexDirection: "column",
    },
    pagination: {
      "& .MuiTableCell-root": {
        borderBottom: "none",
      },

      "& .MuiTypography-root": {
        marginTop: "-3px",
        marginRight: "15px",
        fontFamily: '"AdobeClean", "AdobeCleanSerif", "Poppins", "sans-serif"',
      },

      "& .MuiTablePagination-input": {
        borderRadius: "5px",
        border: "2px solid #4262FF",
      },
    },

    bottomWrap: {
      maxWidth: "100% !important",
    },
    actionsMenu: {
      "& .MuiPaper-root": {
        border: "1px solid #CDCCD7",
        boxShadow: "1px 1px 4px 0px #45454519",

        "& .MuiMenuItem-root": {
          fontFamily: theme.typography.fontFamily,
          fontSize: "0.875rem",
          color: "#454545",
          "&:hover": {
            backgroundColor: "#efefef",
          },
        },
      },
    },
  };
});
