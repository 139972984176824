import * as yup from "yup";
import { PurchaseRewardRate, TykchaseCampaignTriggers, TykchaseCampaignTypes } from "../../../../../../gql/graphql";

const { ReceiptTotal, Day, Surprise, ProductOrService } = TykchaseCampaignTriggers;
const { Fixed, ByPercentage } = PurchaseRewardRate;
export const informationSchema = yup.object().shape({
  title: yup.string().max(65).required("You must type a title"),
  description: yup.string().max(160).required("You must type a description"),
  startDate: yup.string().required("You must select a start / end date"),
  holidayId: yup.string().when("campaignType", {
    is: TykchaseCampaignTypes.Holidays,
    then: yup.string().required("You must select a holiday"),
    otherwise: yup.string(),
  }),

  endDate: yup.string().required("You must select a start / end date"),
  customCoverBase64: yup.string().required("You must select or upload a cover"),
});

export const triggerSchema = yup.object().shape({
  receiptTotalPriceTrigger: yup.number().when("triggerType", {
    is: ReceiptTotal,
    then: yup.number().min(15, "Minimum allowed is $15").required("You must provide an amount"),
    otherwise: yup.number(),
  }),

  surpriseNumber: yup.number().when("triggerType", {
    is: Surprise,
    then: yup.number().min(1).required("Define a number of surprises"),
    otherwise: yup.number(),
  }),
  name: yup.string().when("triggerType", {
    is: ProductOrService,
    then: yup.string().required("You must type a product name"),
    otherwise: yup.string(),
  }),
  price: yup.number().when("triggerType", {
    is: ProductOrService,
    then: yup.number().required("You must type a product price"),
    otherwise: yup.number(),
  }),
  code: yup.string().when("triggerType", {
    is: ProductOrService,
    then: yup.string().required("You must type a product code"),
    otherwise: yup.string(),
  }),
  dayTrigger: yup.string().when("triggerType", {
    is: Day,
    then: yup.string().required("You must select at least one day"),
    otherwise: yup.string(),
  }),
  boostedTykchaseCampaignId: yup.string().when("triggerType", {
    is: false,
    then: yup.string().required("You must select an active campaign"),
    otherwise: yup.string(),
  }),
  multiplierNumber: yup.number().when("triggerType", {
    is: false,
    then: yup.number().min(2).required("You must select a multiplier"),
    otherwise: yup.number(),
  }),
});
export const rewardSchema = yup.object().shape({
  purchaseRewardRate: yup.string().required("Please Select"),
  dollarReward: yup.number().when("purchaseRewardRate", {
    is: ByPercentage,
    then: yup.number().required("You must provide a percentage"),
    otherwise: yup.number().min(20, "Amount must be higher than $20").required("You must provide an amount"),
  }),
});
