import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    wrap: {
      "& input": {
        flexDirection: "row-reverse",
        padding: "16px 10px",
        fontSize: "1rem",
        color: theme.palette.diagram?.purple,
        background: "transparent",
        border: `2px solid ${theme.palette.diagram?.purple}`,
        borderRadius: "3px",
        textAlign: "center",
        "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
          WebkitAppearance: "none",
          margin: "0",
        },

        [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
          maxWidth: "60px",
          padding: "6px",
        },
      },
    },
  };
});

export default useStyles;
