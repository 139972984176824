import { FC, useState, useEffect } from "react";
import { Grid, Typography, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useQuery } from "@apollo/client";
import { useUser } from "@stagewood/unified-login-library";
import { FormattedMessage } from "react-intl";
import CreateCampaign from "./CreateCampaign";
import useStyles from "./styles";
import CardCampaign from "./CardCampaign";
import { TykchaseCampaign } from "../../../generated/types";

import { GET_ALL_CAMPAIGNS, GET_TYKCHASE_CONFIGURATION_QUERY } from "../../../apollo/queries";
import happyGirls from "../../../assets/images/happyGirls.png";
import { ITykchaseGlobalConfig } from "../../../types/tykchaseGlobalConfig";

const Campaigns: FC = () => {
  const theme = useTheme();
  const { wrap } = useStyles();
  const [allCampaigns, setAllCampaigns] = useState<TykchaseCampaign[]>([]);

  const user = useUser();

  const { data: campaignsData } = useQuery(GET_ALL_CAMPAIGNS, {
    fetchPolicy: "no-cache",
  });

  const {
    loading: isTykchaseConfigurationQueryLoading,
    error: tykchaseConfigurationQueryError,
    data: tykchaseConfigurationData,
  } = useQuery(GET_TYKCHASE_CONFIGURATION_QUERY, {
    variables: {
      userId: user?.id,
    },
  });

  useEffect(() => {
    if (campaignsData) {
      setAllCampaigns(campaignsData.getAllCampaigns);
    }
  }, [campaignsData]);

  return (
    <Grid
      mt="40px"
      p="40px 0"
      bgcolor={theme.palette.white}
      border={`0.5px solid ${theme.palette.purple}`}
      className={wrap}
    >
      <CreateCampaign
        tykchaseGlobalConfig={
          tykchaseConfigurationData?.getTykchaseConfiguration?.payload && {
            ...tykchaseConfigurationData?.getTykchaseConfiguration?.payload?.tykchaseGlobalConfig,
            customTykAwardsDistribution: {
              ...tykchaseConfigurationData?.getTykchaseConfiguration?.payload?.customTykAwardsDistribution,
            },
          }
        }
      />

      <Grid container alignItems="center" p="12px 66px" bgcolor={theme.palette.campaigns.lightGrey}>
        <Typography fontStyle="italic" fontSize="0.875rem" mr="10px" color={theme.palette.secondary.main}>
          Sort by
        </Typography>
        <FormControl>
          <InputLabel id="demo-simple-select-label">
            <FormattedMessage id="rewards.campaigns.card_campaign.campaign_status" />
          </InputLabel>
          <Select defaultValue="">
            <MenuItem value="Weekly">
              <FormattedMessage id="rewards.campaigns.card_campaign.weekly" />
            </MenuItem>
            <MenuItem value="Monthly">
              <FormattedMessage id="rewards.campaigns.card_campaign.monthly" />
            </MenuItem>
            <MenuItem value="Every 3 months">
              <FormattedMessage id="rewards.campaigns.card_campaign.every_three" />
            </MenuItem>
            <MenuItem value="Yearly">
              <FormattedMessage id="rewards.campaigns.card_campaign.yearly" />
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid
        container
        flexDirection={{ xs: "column", md: "row" }}
        flexWrap="nowrap"
        p={{ xs: "20px 10px", lg: "20px 66px" }}
      >
        <Grid
          width={{ xs: "100%", md: "70%" }}
          mr="35px"
          borderRight={{ xs: "none", md: `1px solid ${theme.palette.campaigns.borderGrey}` }}
        >
          {allCampaigns.map((campaign) => (
            <Grid
              key={campaign.id}
              container
              flexWrap="nowrap"
              width={{ xs: "95%", lg: "80%" }}
              m="0 auto"
              p="15px 11px"
              boxShadow={`0px 3px 6px ${theme.palette.campaigns.shadowGrey}`}
              borderRadius="6px"
            >
              <CardCampaign campaignData={campaign} />
            </Grid>
          ))}
        </Grid>
        <Grid width={{ xs: "100%", md: "30%" }} pt="20px">
          <Typography mb="16px" fontSize="0.875rem" fontWeight="bold" color={theme.palette.purple}>
            Campaigns
          </Typography>
          <Typography color={theme.palette.black} fontSize="1.75rem" fontWeight="bold">
            02
          </Typography>
          <Typography fontSize="0.75rem" mb="18px" color={theme.palette.purple}>
            Active campaigns
          </Typography>
          <Typography color={theme.palette.black} fontSize="1.75rem" fontWeight="bold">
            02
          </Typography>
          <Typography fontSize="0.75rem" mb="18px" color={theme.palette.purple}>
            Cancelled for lack of tyks
          </Typography>
          <Typography mb="23px" fontSize="0.875rem" color={theme.palette.purple} fontWeight="bold">
            Experiences
          </Typography>
          <Grid container flexWrap="nowrap">
            <Grid container width="35%" borderRadius="6px" overflow="hidden" mr="11px">
              <img height="100%" width="100%" src={happyGirls} alt="img" />
            </Grid>
            <Grid container flexWrap="nowrap">
              <Grid width="100%" mr="10px">
                <Typography color={theme.palette.black} fontSize="0.813rem">
                  Marlins Vs. Pittsburgh
                </Typography>
                <Typography color={theme.palette.analytics?.grey} fontSize="0.625rem" mb="10px">
                  LoanDepot Park, Miami
                </Typography>
                <Typography className="details" color={theme.palette.blue} fontSize="0.625rem">
                  Details
                </Typography>
              </Grid>
              <Grid
                alignSelf="end"
                height="23px"
                width="23px"
                bgcolor={theme.palette.analytics?.lightGreen}
                borderRadius="100px"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Campaigns;
