import { FC } from "react";
import { Grid } from "@mui/material";
import { useAccount, useUser } from "@stagewood/unified-login-library";
import useStyles from "./styles";
import BusinessInfo from "./BusinessInfo";
import AbsoluteRedirect from "../Routing/AbsoluteRedirect";
import MembershipDashboard from "./MembershipDashboard";
import { useBusinessProfile } from "../../hooks/useBusinessProfile.hook";
import { PageLoader } from "../PageLoader";

const Dashboard: FC = () => {
  const { mainContainer } = useStyles();
  const user = useUser();
  const account = useAccount();
  const [businessProfile, isLoadingBusinessProfile, isSubscribed] = useBusinessProfile();
  const userMembership = isSubscribed ? businessProfile?.membership?.title : undefined;
  const internalId = isSubscribed ? businessProfile?.membership?.internalId : undefined;
  if (user == null || user.userType !== "Business") {
    const businessesForAccount = (account?.users || []).filter((u) => u.userType === "Business");
    if (businessesForAccount.length === 0) {
      return <AbsoluteRedirect to="/enroll" />;
    }

    return <AbsoluteRedirect to="/choose-business" />;
  }
  if (isLoadingBusinessProfile) {
    return <PageLoader />;
  }

  return (
    <Grid className={mainContainer}>
      <MembershipDashboard userMembership={userMembership ?? undefined} internalId={internalId ?? undefined} />
      <Grid item xs={false} md={4} lg={3}>
        <BusinessInfo />
      </Grid>
    </Grid>
  );
};

export default Dashboard;
