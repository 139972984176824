import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    promotionContainer: {
      paddingTop: 0,

      "& .promotion-title": {
        padding: "50px 90px 30px",
        fontFamily: theme.typography.fontFamily,
        fontSize: "0.875rem",
        fontWeight: "600",
        color: theme.palette.marketing.darkPurple,

        [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
          padding: "40px 30px 20px",
        },
      },

      "& .promotion-container": {
        display: "flex",
        flexDirection: "row",
        margin: "0 30px",
        backgroundColor: theme.palette.white,
        border: "0.5px solid #8986AD",

        [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
          flexDirection: "column",
          alignItems: "center",
          margin: "0 10px 10px 10px",
        },

        "& .promotion-item-container": {
          maxWidth: "228px",
          minHeight: "380px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: "64px",
          padding: "18px 14px",
          cursor: "pointer",

          [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
            margin: "32px",
          },

          [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
            minHeight: "100%",
            margin: "8px",
          },

          "&:hover": {
            backgroundColor: "#F3F3F3",
            borderRadius: "6px",

            "& .promotion-item-header": {
              "& .title-wrapper": {
                "& h4": {
                  fontSize: "1rem",
                  fontWeight: 600,
                  color: theme.palette.marketing.darkPurple,
                },
              },
            },
          },

          "& .promotion-item-header": {
            width: "100%",
            height: "170px",
            padding: "2px",
            border: "1px solid #C7C7C7",
            borderRadius: "6px",

            "& img": {
              width: "100%",
              height: "50%",
              backgroundColor: theme.palette.marketing.lightBlue,
              border: "1px solid #E9E9E9",
              borderRadius: "6px",
            },

            "& .title-wrapper": {
              padding: "18px",

              "& h4": {
                fontSize: "1rem",
                fontWeight: 400,
                color: theme.palette.marketing.darkPurple,
              },
            },
          },

          "& .promotion-item-description": {
            marginTop: "12px",
            color: theme.palette.marketing.darkGrey,
          },
        },
      },
    },
  };
});

export default useStyles;
