import { Typography, Avatar } from "@mui/material";
import theme from "../../../theme";
import { MembershipAvatarProps } from "./types";
import { avatarStyles, useStyles } from "./styles";
import { Memberships } from "../../../types/membershipTypes";

const MembershipAvatar = (props: MembershipAvatarProps) => {
  const { membership } = props;
  const { avatarStyle } = useStyles(props);

  const getInitials = (title?: string) => {
    switch (title) {
      case Memberships.Business:
        return "B";
      default:
        return "B";
    }
  };

  return (
    <>
      <Avatar className={avatarStyle} sx={{ ...avatarStyles(props).avatarStyle }}>
        <Typography fontSize="1rem" fontFamily={theme.typography.fontFamily}>
          {getInitials(membership)}
        </Typography>
      </Avatar>
    </>
  );
};

export default MembershipAvatar;
