import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    timeFrameSelect: {
      "& .MuiInputLabel-root": {
        fontFamily: theme.typography.fontFamily,
        paddingBottom: "4px",
        fontSize: "0.75rem",
        color: "#747474",
      },

      "& .MuiInputBase-root": {
        height: "32px",
        width: "190px",
        minWidth: "120px",
        backgroundColor: theme.palette.white,
        border: `1px solid ${theme.palette.diagram?.grey}`,
        borderRadius: "4px",

        "& .MuiSelect-select": {
          fontFamily: theme.typography.fontFamily,
          fontSize: "0.75rem",
        },

        "&:hover": {
          border: `1px solid ${theme.palette.primary.dark}`,
        },
      },

      "& .Mui-focused": {
        border: `1px solid ${theme.palette.primary.dark}`,
      },

      "& fieldset": {
        padding: 0,
        border: "none",
      },
    },

    datePickerWrapper: {
      display: "flex",
      flexDirection: "column",

      "& .MuiInputLabel-root": {
        paddingBottom: "4px",
        fontSize: "0.75rem",
        color: "#747474",
      },

      "& .MuiInputBase-root": {
        width: "120px",
        height: "32px",
        border: "1px solid #D3D3D3",
        fontFamily: theme.typography.fontFamily,
        fontSize: "0.875rem",
        color: "#AAA9A9",

        "&:hover": {
          border: `1px solid ${theme.palette.primary.dark}`,
        },
      },

      "& .MuiInputBase-input": {
        padding: "4px 8px 4px 10px",
        color: theme.palette.analytics?.grey,
      },

      "& .Mui-focused": {
        border: `1px solid ${theme.palette.primary.dark}`,
      },

      "& fieldset": {
        padding: 0,
        border: "none",
      },
    },
  };
});

export default useStyles;
