import { useContext, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { useAccount } from "@stagewood/unified-login-library";
import { LanguageContext } from "../contexts";
import { getAccountLanguageQuery } from "../graphql/GetAccountLanguage.query";
import { locales } from "../locales";

export const useQueryLanguage = () => {
  const { selectLanguage } = useContext(LanguageContext);
  const account = useAccount();

  const { loading: isGetAccountLanguageLoading, data: queryResult } = useQuery(getAccountLanguageQuery, {
    variables: { id: account?.id ?? "" },
    skip: !account?.id,
    onCompleted: (data) => {
      if (data.fetchAccountLanguage) {
        selectLanguage(data?.fetchAccountLanguage.payload ?? locales.EN.value);
      }
    },
  });

  return [isGetAccountLanguageLoading];
};
