import { Grid, Radio, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import clsx from "clsx";
import { useState } from "react";
import Icon from "../../../../utils/icon";
import RewardsAccumulated from "../modals/RewardsAccumulated";
import AutomateRewards from "../modals/AutomateRewards";
import PromoterInfo from "../modals/PromoterInfo";

const TableRow = () => {
  const theme = useTheme();
  const [promoterState, setPromoterState] = useState({
    selected: false,
    actionOn: false,
  });
  const [radioCheked, setRadioCheked] = useState(false);
  const [moreMenuActive, setMoreMenuActive] = useState(false);

  const handlerPromoterState = () => {
    setPromoterState((prevState) => {
      const newState = {
        ...prevState,
        [prevState.selected ? "actionOn" : "selected"]: true,
      };

      return newState;
    });

    setRadioCheked(true);
  };

  const [selectedModalName, setSelectedModalName] = useState<string>("");

  const modals = {
    RewardsAccumulated: (
      <RewardsAccumulated
        modalActive
        handlerModal={() => {
          setSelectedModalName("");
        }}
      />
    ),
    AutomateRewards: (
      <AutomateRewards
        modalActive
        handlerModal={() => {
          setSelectedModalName("");
        }}
      />
    ),
    PromoterInfo: (
      <PromoterInfo
        modalActive
        handlerModal={() => {
          setSelectedModalName("");
        }}
      />
    ),
  };
  const SelectedModalComponent = modals[selectedModalName];

  return (
    <>
      <Grid
        className={clsx({
          selected: promoterState.selected,
          actionOn: promoterState.actionOn,
        })}
        container
        flexWrap="nowrap"
        onClick={handlerPromoterState}
      >
        <Grid className="rowWrap">
          <Radio checked={radioCheked} />
          <Typography fontSize="0.75rem" color={theme.palette.analytics?.grey}>
            0587569
          </Typography>
          <Grid container alignItems="center">
            <Grid height="30px" width="30px" overflow="hidden" borderRadius="100px" mr="10px">
              <Icon.HappyGirls />
            </Grid>
            <Typography fontSize="0.75rem" color={theme.palette.analytics?.grey}>
              Steve Balmer
            </Typography>
          </Grid>

          <Typography
            textOverflow="ellipsis"
            fontSize="0.75rem"
            color={theme.palette.analytics?.grey}
            whiteSpace="nowrap"
            overflow="hidden"
          >
            6 Woodsman Avenue 6 Woodsman Avenue 6 Woodsman Avenue
          </Typography>

          <Grid container flexWrap="nowrap">
            <Grid container alignItems="center" flexWrap="nowrap" mr="5px">
              <Icon.CentorsFull mb="9px" />
              <Typography ml="5px">2,000</Typography>
            </Grid>
            <Grid container alignItems="center" flexWrap="nowrap">
              <Icon.LoneStars mb="9px" />
              <Typography ml="5px">550</Typography>
            </Grid>
          </Grid>

          <Grid>
            <Grid
              className="power"
              justifySelf="center"
              container
              justifyContent="center"
              alignItems="center"
              height="22px"
              width="22px"
              bgcolor={theme.palette.diagram?.grey}
              borderRadius="100px"
              padding="5px"
            >
              <Icon.Power />
            </Grid>
          </Grid>
          <Grid
            onClick={(event) => {
              event.stopPropagation();
              setMoreMenuActive(!moreMenuActive);
            }}
            className="more"
          >
            <Grid
              className={clsx("content", {
                active: moreMenuActive,
              })}
              width="120px"
              height="112px"
              whiteSpace="nowrap"
            >
              <Typography
                p="10px 9px"
                fontSize="0.75rem"
                onClick={() => {
                  setSelectedModalName("RewardsAccumulated");
                }}
              >
                Custom rewards
              </Typography>
              <Typography
                p="10px 9px"
                fontSize="0.75rem"
                onClick={() => {
                  setSelectedModalName("AutomateRewards");
                }}
              >
                Automate rewards
              </Typography>
              <Typography
                p="10px 9px"
                fontSize="0.75rem"
                onClick={() => {
                  setSelectedModalName("PromoterInfo");
                }}
              >
                Promoter info
              </Typography>
            </Grid>

            <Grid className="moreDecor">
              <Grid className="moreItem" top="40%" />
              <Grid className="moreItem" top="50%" />
              <Grid className="moreItem" top="60%" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {SelectedModalComponent && SelectedModalComponent}
    </>
  );
};

export default TableRow;
