export enum FeatureFlagEnum {
  ANALYTICS_V2_ENABLED = "isAnalyticsV2Enabled",
  MARKETING_V2_ENABLED = "isMarketingV2Enabled",
  TECHNOLOGY_V2_ENABLED = "isTechnologyV2Enabled",
  SPECIAL_CAMPAIGN_ENABLED = "isSpecialCampaignV2Enabled",
  EXPERIENCES_V2_ENABLED = "isExperiencesV2Enabled",
  JACKPOT_V2_ENABLED = "isJackpotV2Enabled",
  PROMOTERS_V2_ENABLED = "isPromotersV2Enabled",
  REWARD_DISTRIBUTION_V2_ENABLED = "isRewardDistributionV2Enabled",
  EXPERIENCES_TAB_OF_QR_CODE_V2_ENABLED = "isExperiencesTabOfQRCodeV2Enabled",
  NO_MEMBERSHIP_TYPE = "isNoMembershipType",
  YOUR_PAYMENT_METHOD_AT_BILLING_ENABLED = "isYourPaymentMethodAtBillingPageEnabled",
  ENROLL_CONTROL_ENABLED = "isEnrollControlEnabled",
  CATEGORIES_FILTER_FOR_REWARDS_ENABLED = "isCategoriesFilterForRewardsEnabled",
}

export type FeatureFlags = Record<FeatureFlagEnum, boolean>;

export interface Language {
  language: string;
  selectLanguage: (language: string) => void;
}
