import { LoadingButton } from "@mui/lab";
import { Dialog, Typography, Divider, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import { Box } from "@mui/system";
import { ChangeEvent, FC } from "react";
import { FormattedMessage } from "react-intl";
import useStyles from "./styles";
import { IReceiptUploadModal } from "./types";

export const ReceiptUploadModal: FC<IReceiptUploadModal> = ({
  open,
  onClose,
  onChange,
  onSubmit,
  isLoading,
  fileName,
}) => {
  const { receiptUploadModal } = useStyles();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target?.files?.[0] || null);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="receipt-upload-dialog"
      aria-describedby="receipt-upload-dialog"
      className={receiptUploadModal}
    >
      <Box className="modal">
        <Box className="modal-title">
          <Typography className="modal-title-text">
            <FormattedMessage id="locations_upload_your_receipt" />
          </Typography>
        </Box>
        <Divider />
        <DialogContent className="modal-content">
          <Button className="choose-file" variant="contained" size="large" disabled={isLoading}>
            <FormattedMessage id="btn_text_choose_file_to_upload" />
            <label htmlFor="file-upload" className="label">
              <input id="file-upload" hidden type="file" accept="image/*" onChange={handleChange} />
            </label>
          </Button>
          <DialogContentText className="file-name">{fileName}</DialogContentText>
        </DialogContent>
        <DialogActions className="modal-actions">
          <LoadingButton
            variant="outlined"
            disabled={isLoading || !fileName}
            loading={isLoading}
            onClick={onSubmit}
            className="submit-btn btn"
            classes={{ loadingIndicator: "loading-indicator" }}
          >
            <FormattedMessage id="btn_text_submit" />
          </LoadingButton>
          <Button variant="contained" disabled={isLoading} onClick={onClose} className="cancel-btn btn">
            <FormattedMessage id="btn_text_cancel" />
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
