import { defineMessages, IntlShape } from "react-intl";

const messages = defineMessages({
  weeklyTitle: {
    id: "weekly_scanned_receipt_avg",
    defaultMessage: "Weekly average scanned receipts",
  },
});
const WeeklyReceiptWidgetText = (intl: IntlShape) => {
  return {
    WeeklyTitle: intl.formatMessage(messages.weeklyTitle),
  };
};

export { WeeklyReceiptWidgetText };
