import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  tooltipIcon: {
    width: "50px",
    height: "50px",
    "& path": {
      fill: "#FFFFFF",
    },
  },
  tooltipText: {
    fontSize: "0.75rem",
    paddingLeft: theme.spacing(1),
  },
  editButton: {
    backgroundColor: "transparent",
    border: "none",
    color: "#4262FF",
    textDecoration: "underline",
    cursor: "pointer",
  },
}));
