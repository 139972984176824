import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    wrap: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      padding: "26px 24px",
      backgroundColor: "#DBEAFE5C",
      borderRadius: "6px",
      border: "1px solid #CDCCD7",

      [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
        padding: "18px 12px 8px",
      },
    },
  };
});

export default useStyles;
