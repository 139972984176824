import { useState } from "react";

const usePagination = <T>({ data, itemsPerPage }: { data: Array<T>; itemsPerPage: number }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const maxPage = Math.ceil(data.length / itemsPerPage);

  const getCurrentPageData = () => {
    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;
    return data.slice(begin, end);
  };

  const next = () => {
    setCurrentPage((page) => Math.min(page + 1, maxPage));
  };

  const prev = () => {
    setCurrentPage((page) => Math.max(page - 1, 1));
  };

  const jump = (page) => {
    const pageNumber = Math.max(1, page);
    setCurrentPage(() => Math.min(pageNumber, maxPage));
  };

  return { next, prev, jump, getCurrentPageData, currentPage, maxPage };
};

export default usePagination;
