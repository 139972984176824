import { makeStyles } from "@mui/styles";

const useStyles = (props)=> makeStyles((theme) => ({
    wrap: {
      display: "flex",
      flexDirection: "column",
      alignItems: "end",
      [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
        alignSelf: "end",
      },

      "& .title": {
        alignSelf: "start",
        [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
          alignSelf: "end",
        },
      },

      "& .MuiInputLabel-root": {
        transform: "translate(14px, 13px) scale(1)",
        fontSize: "12px",
        "&.MuiFormLabel-filled": {
          color: "transparent !important",
        },

        "&.Mui-focused": {
          color: theme.palette.diagram?.grey,
        },
      },

      "& .MuiOutlinedInput-root": {
        height: "33px",
        width: props.language === 'en' ? "180px" : "250px",
        margin: "5px 0",

        "& .MuiOutlinedInput-notchedOutline": {
          border: `1px solid ${theme.palette.diagram?.grey} !important`,
        },
      },

      "& .purchaseInput": {
        marginTop: "20px",
        "& .input": {
          height: "32px",
          maxWidth: "54px",
          padding: "0 5px",
          margin: "0 10px",
          marginRight: "0",
          border: `1px solid ${theme.palette.diagram?.grey}`,
          borderRadius: "4px",
          " & input, input::-webkit-input-placeholder ": {
            textAlign: "center",
            color: theme.palette.diagram?.blue,
          },
        },
      },
    },
}));

export default useStyles;
