import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import clsx from "clsx";
import LockIcon from "@mui/icons-material/Lock";
import { FormattedMessage, MessageDescriptor, useIntl } from "react-intl";
import { useStyles, ButtonsStyle } from "./styles";

type IButtonProps = {
  buttonText: MessageDescriptor["id"];
  alternativeText?:MessageDescriptor["id"];
  btnOnClick: any;
  disabled?: boolean;
  isLoading?: boolean;
};

export const MainButton = ({ buttonText, btnOnClick, disabled = false, isLoading = false }: IButtonProps) => {
    const { formatMessage } = useIntl();
    const formattedButttonText = formatMessage({ id: buttonText });
  const { root, mainBtn } = useStyles({ buttonText: formattedButttonText });

  if (isLoading) {
    return (
      <LoadingButton
        sx={{ ...ButtonsStyle({ formattedButttonText }).root, ...ButtonsStyle({ formattedButttonText }).mainBtn }}
        className={clsx(root, mainBtn)}
        size="large"
        loading
        // loadingPosition="end"
        variant="contained"
      >
        <FormattedMessage id={buttonText} />
      </LoadingButton>
    );
  }
  return (
    <Button
      sx={{ ...ButtonsStyle({ formattedButttonText }).root, ...ButtonsStyle({ formattedButttonText }).mainBtn }}
      className={clsx(root, mainBtn)}
      onClick={btnOnClick}
      disabled={disabled}
    >
      <FormattedMessage id={buttonText} />
    </Button>
  );
};

export const HeaderEnrollButton = ({ buttonText, alternativeText, btnOnClick }: IButtonProps) => {
    const { formatMessage } = useIntl();
    const formattedButttonText = formatMessage({ id: buttonText });
  const { root, headerBtn, headerResponsiveBtn } = useStyles({ buttonText: formattedButttonText });

  return (
    <>
      <Button
        sx={{ ...ButtonsStyle({ formattedButttonText }).root, ...ButtonsStyle({ formattedButttonText }).headerBtn }}
        className={clsx(root, headerBtn)}
        onClick={btnOnClick}
      >
        <FormattedMessage id={buttonText} />
      </Button>
      <Button
        sx={{
          ...ButtonsStyle({ formattedButttonText }).root,
          ...ButtonsStyle({ formattedButttonText }).headerResponsiveBtn,
        }}
        className={clsx(root, headerResponsiveBtn)}
        onClick={btnOnClick}
      >
        <FormattedMessage id={alternativeText} />
      </Button>
    </>
  );
};

export const FooterButtons = ({ buttonText, btnOnClick }: IButtonProps) => {
  const { formatMessage } = useIntl();
    const formattedButttonText = formatMessage({ id: buttonText });
  const { root, footerBtn } = useStyles({ buttonText: formattedButttonText });

  return (
    <Button
      sx={{
        ...ButtonsStyle({ formattedButttonText }).root,
        ...ButtonsStyle({ formattedButttonText }).footerBtn,
      }}
      className={clsx(root, footerBtn)}
      onClick={btnOnClick}
    >
      <FormattedMessage id={buttonText} />
    </Button>
  );
};

export const MembershipCardButton = ({ buttonText, btnOnClick }: IButtonProps) => {
  const { formatMessage } = useIntl();
  const formattedButttonText = formatMessage({ id: buttonText });
  const { root, membershipBtn } = useStyles({ buttonText:formattedButttonText });

  const renderButton = (status: string | undefined) => {
    if (status !== "btn_text_coming_soon") {
      return (
        <Button
          sx={{
            ...ButtonsStyle({ formattedButttonText }).root,
            ...ButtonsStyle({ formattedButttonText }).membershipBtn,
          }}
          className={clsx(root, membershipBtn)}
          onClick={btnOnClick}
        >
          <FormattedMessage id={buttonText} />
        </Button>
      );
    }
    return (
      <Button
        sx={{
          ...ButtonsStyle({ formattedButttonText }).root,
          ...ButtonsStyle({ formattedButttonText }).membershipBtn,
          ...{ color: "#A5AFE2" },
        }}
        className={clsx(root, membershipBtn)}
        onClick={btnOnClick}
        disabled
      >
        <LockIcon style={{ fontSize: "1rem", marginRight: "5px", color: "#A5AFE2" }} />
        <FormattedMessage id={buttonText} />
      </Button>
    );
  };

  return <>{renderButton(buttonText)}</>;
};
