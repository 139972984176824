import dummyImage from "../../../../assets/images/NoPath.png";
import behavior from "../../../../assets/icons/campaign/behavior.svg";

export enum CampaignTypes {
  Holiday = "Holiday Campaign",
  DoubleTriple = "Double Triple Campaign",
  LowTraffic = "Low Traffic Campaign",
  Surprise = "Surprise Campaign",
}

export type Campaign = {
  id: string;
  type?: string;
  previewImage?: string;
  name: string;
  spent: number;
  distributed?: {
    tyks: number;
    loneStars: number;
  };
  behavior?: string;
  reward?: number;
  end?: string;
};

export const campaigns: {
  count: number;
  rows: Campaign[];
} = {
  rows: [
    {
      id: "0",
      type: CampaignTypes.Holiday,
      previewImage: dummyImage,
      name: "Buy a Large Pizza",
      spent: 200,
      distributed: {
        tyks: 200,
        loneStars: 50,
      },
      behavior,
      end: "Ends Jul. 27th, 2022",
    },
    {
      id: "1",
      type: CampaignTypes.DoubleTriple,
      previewImage: dummyImage,
      name: "General Scan Receipt",
      spent: 150,
      distributed: {
        tyks: 120,
        loneStars: 10,
      },
      behavior,
      end: "Ends Jul. 27th, 2022",
    },
    {
      id: "2",
      type: CampaignTypes.LowTraffic,
      previewImage: dummyImage,
      name: "Like in Biz posts",
      spent: 150,
      distributed: {
        tyks: 98,
        loneStars: 15,
      },
      behavior,
      end: "Ends Jul. 27th, 2022",
    },
    {
      id: "3",
      type: CampaignTypes.Surprise,
      previewImage: dummyImage,
      name: "Following Biz Profile",
      spent: 100,
      distributed: {
        tyks: 200,
        loneStars: 50,
      },
      behavior,
      end: "Ends Jul. 27th, 2022",
    },
    {
      id: "4",
      type: CampaignTypes.Surprise,
      previewImage: dummyImage,
      name: "Like in Biz posts",
      spent: 100,
      distributed: {
        tyks: 200,
        loneStars: 50,
      },
      behavior,
      end: "Ends Jul. 27th, 2022",
    },
  ],
  count: 5,
};
