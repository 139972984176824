import { useMutation } from "@apollo/client";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { green } from "@mui/material/colors";
import { useUser } from "@stagewood/unified-login-library";
import { useElements } from "@stripe/react-stripe-js";
import { useCallback, useEffect, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import { useBooleanState } from "../../../hooks/useBooleanState.hook";
import { ButtonsStyle } from "../../Buttons/styles";
import { CardForm } from "../../Checkout/PaymentMethods/CardForm";
import { useCardFormValidation } from "../../Checkout/PaymentMethods/CardForm/hooks/card-form-validation.hook";
import { addPaymentMethodMutation as AddPaymentMethodMutation } from "./graphql/add-payment-method.mutation";
import { AddPaymentMethodModalProps } from "./interfaces/add-payment-method-modal.interface";
import { useSavePaymentMethod } from "./hooks/use-save-payment-method.hook";

import { useAddressCreationForm } from "../../AddressSelectionModal/AddressCreationForm/useAddressCreationForm.hook";
import { AddressCreationForm } from "../../AddressSelectionModal/AddressCreationForm";
import { BillingAddressFormik } from "../../../types/billingAddress.type";
import { CardInputsMessageStatus } from "../../Checkout/PaymentMethods/CardForm/CardForm.types";

const defaultValues: BillingAddressFormik = {
  addressCity: "",
  addressStreet1: "",
  addressStreet2: "",
  label: "",
  zipCode: "",
  isDefault: false,
  shortCountryCode: "",
  regionName: "",
};

const defaultCardInputsMessageStatus: CardInputsMessageStatus = {
  cardCardholderMessageStatus: null,
  cardNumberMessageStatus: null,
  cardExpiryMessageStatus: null,
  cardCvcMessageStatus: null,
};

const SubmitButton = styled(Button)({
  "&.success": {
    backgroundColor: green[400],
  },
});

export function AddPaymentMethodModal(props: AddPaymentMethodModalProps): JSX.Element {
  const user = useUser();
  const elements = useElements();
  const { cardInputsMessageStatus, setCardInputsMessageStatus, allFieldsComplete, emptyNonNullValues } =
    useCardFormValidation();
  const savePaymentMethod = useSavePaymentMethod();
  const [{ values, isValid, addressCreationProps }, submit, resetForm] = useAddressCreationForm(defaultValues, true);
  const {
    value: isSavingPaymentMethod,
    setTrue: setPaymentMethodSaving,
    setFalse: setPaymentMethodNotSaving,
  } = useBooleanState();
  const {
    value: paymentMethodAddedSuccessfully,
    setTrue: setPaymentMethodAddedSuccessfully,
    setFalse: setPaymentMethodAdditionFailed,
  } = useBooleanState();
  const [cardholderName, setCardholderName] = useState<string | null>(null);

  const [setupIntent, { loading: setupIntentLoading, data: setupIntentData }] = useMutation(AddPaymentMethodMutation, {
    variables: {
      input: {
        userId: user?.id,
      },
    },
  });
  const { handleClose } = props;

  useEffect(() => {
    if (props.open) {
      setupIntent();
    } else {
      setCardholderName(null);
      setCardInputsMessageStatus(defaultCardInputsMessageStatus);
      resetForm();
    }
  }, [props.open, resetForm, setupIntent, setCardInputsMessageStatus]);
  const handlePaymentMethodSaved = useCallback(() => {
    setPaymentMethodAddedSuccessfully();
    setTimeout(() => {
      handleClose!({}, "escapeKeyDown");
      setPaymentMethodNotSaving();
      setPaymentMethodAdditionFailed();
      setCardholderName("");
    }, 2000);
  }, [handleClose, setPaymentMethodAddedSuccessfully, setPaymentMethodAdditionFailed, setPaymentMethodNotSaving]);
  const addPaymentMethod = useCallback(async () => {
    await submit();
    if (isValid && allFieldsComplete) {
      if (!paymentMethodAddedSuccessfully) {
        const card = elements?.getElement("cardNumber");

        setPaymentMethodSaving();

        savePaymentMethod(
          card!,
          setupIntentData.addPaymentMethod.clientSecret,
          cardholderName,
          {
            city: values.addressCity.split(",")[0],
            state: values.regionName ? values.regionName : null,
            country: values.shortCountryCode ? values.shortCountryCode?.toUpperCase() : null,
            line1: values.addressStreet1,
            line2: values.addressStreet2 || null,
            postal_code: values.zipCode,
          },
          {
            onCardConfirmationFailed: setPaymentMethodAdditionFailed,
            onPaymentMethodSaved: handlePaymentMethodSaved,
            onPaymentMethodSavingFailed: setPaymentMethodAdditionFailed,
            onPaymentMethodSavingSettled: setPaymentMethodNotSaving,
          },
        );
      }
    }

    emptyNonNullValues();
  }, [
    emptyNonNullValues,
    submit,
    isValid,
    allFieldsComplete,
    paymentMethodAddedSuccessfully,
    elements,
    setPaymentMethodSaving,
    savePaymentMethod,
    setupIntentData?.addPaymentMethod.clientSecret,
    cardholderName,
    values.addressCity,
    values.regionName,
    values.shortCountryCode,
    values.addressStreet1,
    values.addressStreet2,
    values.zipCode,
    setPaymentMethodAdditionFailed,
    handlePaymentMethodSaved,
    setPaymentMethodNotSaving,
  ]);

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="add-new-payment-method-title"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="add-new-payment-method-title">
        <FormattedMessage id="add-payment-method.modal.title" />
      </DialogTitle>
      <DialogContent>
        {setupIntentLoading && (
          <Box display="flex" justifyContent="center" alignItems="center" sx={{ width: "100%" }} py={4}>
            <CircularProgress />
          </Box>
        )}
        {!setupIntentLoading && setupIntentData && (
          <>
            <CardForm
              cardholderName={cardholderName}
              onCardholderNameChange={setCardholderName}
              setCardInputsMessageStatus={setCardInputsMessageStatus}
              cardInputsMessageStatus={cardInputsMessageStatus}
            />
            <AddressCreationForm isBilling {...addressCreationProps} />
            <Box width="100%" mt={10}>
              <SubmitButton
                sx={{ ...ButtonsStyle(null).mainBtn, maxWidth: "100%", width: "inherit" }}
                variant="contained"
                onClick={addPaymentMethod}
                disabled={isSavingPaymentMethod}
                className={clsx({ success: paymentMethodAddedSuccessfully })}
              >
                {isSavingPaymentMethod && <CircularProgress size={24} />}
                {!isSavingPaymentMethod && paymentMethodAddedSuccessfully && (
                  <Stack spacing={2} direction="row">
                    <CheckIcon />
                    <Typography textTransform="initial">
                      <FormattedMessage id="add-payment-method.modal.submit-button.success-text" />
                    </Typography>
                  </Stack>
                )}
                {!isSavingPaymentMethod && !paymentMethodAddedSuccessfully && (
                  <Typography>
                    <FormattedMessage id="add-payment-method.modal.submit-button.regular-text" />
                  </Typography>
                )}
              </SubmitButton>
            </Box>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
