import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { IntlShape, FormattedMessage } from "react-intl";

import { ReactComponent as Dashboard } from "../assets/icons/menuIcons/dashboard.svg";
import { ReactComponent as Membership } from "../assets/icons/menuIcons/membership.svg";
import { ReactComponent as Reward } from "../assets/icons/menuIcons/yourRewards.svg";
import { ReactComponent as Analytics } from "../assets/icons/menuIcons/analytics.svg";
import { ReactComponent as Marketing } from "../assets/icons/menuIcons/marketing.svg";
import { ReactComponent as Billing } from "../assets/icons/menuIcons/billing.svg";
import { ReactComponent as Location } from "../assets/icons/menuIcons/locationSection.svg";
import { ReactComponent as Technology } from "../assets/icons/menuIcons/technologyIcon.svg";
import { ReactComponent as MembershipHeader } from "../assets/icons/headerIcons/membershipHeaderIcon.svg";
import { ReactComponent as RewardsHeader } from "../assets/icons/headerIcons/yourRewardsHeaderIcon.svg";
import { ReactComponent as AnalyticsHeader } from "../assets/icons/headerIcons/analyticsHeaderIcon.svg";
import { ReactComponent as MarketingHeader } from "../assets/icons/headerIcons/marketingHeaderIcon.svg";
import { ReactComponent as TechnologyHeader } from "../assets/icons/headerIcons/technologyHeaderIcon.svg";
import { ReactComponent as BillingHeader } from "../assets/icons/headerIcons/billingHeaderIcon.svg";

import { IMenuItem, MenuItems } from "../types/menu";
import { Routes } from "../constants";

const useStyles = makeStyles(() => {
  return {
    menuText: {
      fontWeight: 600,
      "@media (max-width: 800px)": {
        display: "none",
      },
    },
  };
});

export const menuItems = (intl: IntlShape): IMenuItem[] => {
  return [
    {
      icon: <Dashboard />,
      text: MenuItems(intl).DASHBOARD,
      route: Routes.DASHBOARD,
    },
    {
      icon: <Membership />,
      text: MenuItems(intl).MEMBERSHIPS,
      route: Routes.MEMBERSHIPS,
    },
    {
      icon: <Location />,
      text: MenuItems(intl).LOCATIONS,
      route: Routes.LOCATIONS,
    },
    {
      icon: <Reward />,
      text: MenuItems(intl).YOUR_REWARDS,
      route: Routes.YOUR_REWARDS,
    },
    {
      icon: <Analytics />,
      text: MenuItems(intl).ANALYTICS,
      route: Routes.ANALYTICS,
    },
    {
      icon: <Marketing />,
      text: MenuItems(intl).MARKETING,
      route: Routes.MARKETING,
    },
    {
      icon: <Technology />,
      text: MenuItems(intl).TECHNOLOGY,
      route: Routes.TECHNOLOGY,
    },
    {
      icon: <Billing />,
      text: MenuItems(intl).BILLING,
      route: Routes.BILLING,
    },
  ];
};

export const ChangeHeader = (path: string, intl: IntlShape) => {
  const { menuText } = useStyles();
  const parsedPath = path.split("/").filter((el) => el !== "");
  let cleanPath = parsedPath[0]?.toLocaleLowerCase() || "";
  if (cleanPath.includes("memberships")) cleanPath = "memberships";
  if (cleanPath.includes("marketing")) cleanPath = "marketing";

  switch (cleanPath) {
    case "dashboard":
      return (
        <Grid container alignItems="center">
          <Dashboard className="appbar" />
          <Typography className={menuText}>{MenuItems(intl).DASHBOARD}</Typography>
        </Grid>
      );
    case "memberships":
      return (
        <Grid container alignItems="center">
          <MembershipHeader className="appbar" />
          <Typography className={menuText}>{MenuItems(intl).MEMBERSHIPS}</Typography>
        </Grid>
      );
    case "your_rewards":
      return (
        <Grid container alignItems="center">
          <RewardsHeader className="appbar" />
          <Typography className={menuText}>{MenuItems(intl).YOUR_REWARDS}</Typography>
        </Grid>
      );
    case "analytics":
      return (
        <Grid container alignItems="center">
          <AnalyticsHeader className="appbar" />
          <Typography className={menuText}>{MenuItems(intl).ANALYTICS}</Typography>
        </Grid>
      );
    case "marketing":
      return (
        <Grid container alignItems="center">
          <MarketingHeader className="appbar" />
          <Typography className={menuText}>{MenuItems(intl).MARKETING}</Typography>
        </Grid>
      );
    case "technology":
      return (
        <Grid container alignItems="center">
          <TechnologyHeader className="appbar" />
          <Typography className={menuText}>{MenuItems(intl).TECHNOLOGY}</Typography>
        </Grid>
      );
    case "billing":
      return (
        <Grid container alignItems="center">
          <BillingHeader className="appbar" />
          <Typography className={menuText}>{MenuItems(intl).BILLING}</Typography>
        </Grid>
      );
    case "locations":
      return (
        <Grid container alignItems="center">
          <BillingHeader className="appbar" />
          <Typography className={menuText}>{MenuItems(intl).LOCATIONS}</Typography>
        </Grid>
      );
    case "checkout":
      return (
        <Grid container alignItems="center">
          <BillingHeader className="appbar" />
          <Typography className={menuText}>
            <FormattedMessage id="breadcrumbs_checkout" />
          </Typography>
        </Grid>
      );
    default:
      return "";
  }
};
