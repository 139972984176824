import { graphql } from "../../../gql";

export const getCartProcessingInfoQuery = graphql(`
  query GetCartProcessingInfo($cartId: String!) {
    getCartPaymentProcessingInfo(input: { id: $cartId }) {
      message
      errorMessageId
      payload {
        status
      }
    }
  }
`);
