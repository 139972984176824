import { FC, useState } from "react";
import { Button, Grid, Typography, Divider, ButtonBase } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import loneStarsIcon from "../../../../assets/icons/tykIcons/loneStars.svg";
import Modal from "../../../Modal";
import theme from "../../../../theme";
import useStyles from "./styles";

const AddToLoneStarsSectionButton: FC = () => {
  const { addToLoneStarsSectionContainer } = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <ButtonBase onClick={handleOpenModal}>
        <AddCircle sx={{ color: theme.palette.experiences.blue }} />
      </ButtonBase>

      <Modal onClose={handleCloseModal} isOpen={isModalOpen} isClosable={false}>
        <Grid container direction="column" className={addToLoneStarsSectionContainer}>
          <Grid className="modal-header">
            <h1 className="modal-header__title">
              <FormattedMessage id="rewards.experiences.modals.add_to_lone_stars" />
            </h1>

            <img src={loneStarsIcon} alt="lone-stars-icon" />
          </Grid>

          <Divider className="modal-divider" />

          <Grid className="modal-body">
            <Typography>
              <FormattedMessage id="rewards.experiences.modals.adding_this_event_to_your_selection" />
            </Typography>
          </Grid>

          <Grid className="modal-footer">
            <Button onClick={handleCloseModal} variant="contained" className="cancel-button">
              <FormattedMessage id="btn_text_cancel" />
            </Button>

            <Button onClick={handleCloseModal} variant="contained" className="primary-button">
              <FormattedMessage id="btn_text_continue" />
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default AddToLoneStarsSectionButton;
