import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    width: "100%",
    padding: "60px 30px 0 60px",
    justifyContent: "center",
    alignItems: "center",
  },

  inlineContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px",
  },
}));
