import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    wrap: {
      "& .jackpotHeader": {
        "& .inactive": {
          opacity: 0.5,
        },
      },

      "& .jackpotBody": {
        "& .jackpotItemWrap": {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "109px",
          padding: "12px 12px 16px",
          margin: "8px 0 0",
          borderRadius: "6px",
          backgroundColor: "#CBDFFB",

          [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
            margin: "4px 0",
          },

          "& .MuiButtonBase-root": {
            alignSelf: "flex-end",
            padding: 0,
          },

          "& .jackpotItemContent": {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "flex-start",

            "& .jackpotItemStatisticWrap": {
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              margin: "0 6px",

              "& .jackpotItemStatistic": {
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                height: "30px",
              },
            },

            "& .jackpotItemStatisticDivider": {
              height: "32px",
              width: "1px",
              backgroundColor: "#34305D",
              alignSelf: "end",
              margin: "0 10px",
            },
          },
        },
      },
    },

    emptyStateWrap: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "109px",
      padding: "24px 8px",
      margin: "8px 0 0",
      backgroundColor: "#CBDFFB",
      borderRadius: "6px",
    },
  };
});

export default useStyles;
