import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    wrap: {
      display: "flex",
      marginTop: "20px",

      [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
        flexDirection: "column",
      },
      "& .left": {
        marginRight: "60px",

        "& .MuiFormControl-root": {
          marginTop: "2px",
        },

        "& .MuiInputLabel-root": {
          fontSize: "12px",
          "&.MuiFormLabel-filled": {
            color: "transparent !important",
          },

          "&.Mui-focused": {
            marginTop: "4px",
            color: theme.palette.text.primary,
          },
        },

        "& .MuiOutlinedInput-root": {
          height: "33px",
          width: "240px",
          margin: "6px 0",

          "& .MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${theme.palette.diagram?.grey} !important`,
          },
        },
      },

      "& .right": {
        position: "relative",
        color: theme.palette.diagram?.purple,
        [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
          alignSelf: "end",
        },
        "& .purchaseInput": {
          display: "flex",
          marginTop: "9px",

          "& .input": {
            height: "32px",
            maxWidth: "124px",
            padding: "0 5px",
            marginRight: "5px",
            border: `1px solid ${theme.palette.diagram?.grey}`,
            borderRadius: "4px",
            " & input, input::-webkit-input-placeholder ": {
              color: theme.palette.diagram?.blue,
            },
          },

          "& p": {
            display: "flex",
            alignItems: "center",
            fontSize: "0.625rem",
          },
        },
      },
    },
  };
});

export default useStyles;
