import clsx from "clsx";
import { FC, HTMLAttributes } from "react";
import { FormattedMessage } from "react-intl";
import { formatCentsToDollars } from "../../shared/PriceFormatters";
import { useStyles } from "./styles";

interface TotalsSectionProps extends HTMLAttributes<HTMLDivElement> {
  subtotal: number;
  tax: number;
  total: number;
  isDraft?: boolean;
}

export const TotalsSection: FC<TotalsSectionProps> = ({ subtotal, tax, total, isDraft, className, ...attributes }) => {
  const styles = useStyles();

  return (
    <section className={clsx(styles.container, className)} {...attributes}>
      <h2 className={styles.titleColumn}>
        <FormattedMessage id="cart_subtotal" />
      </h2>
      <h2 className={styles.valueColumn}>{formatCentsToDollars(subtotal)}</h2>
      {!isDraft && (
        <>
          <h2 className={styles.titleColumn}>
            <FormattedMessage id="cart_tax" />
          </h2>
          <h2 className={styles.valueColumn}>{formatCentsToDollars(tax)}</h2>
          <h2 className={styles.titleColumn}>
            <FormattedMessage id="cart_total" />
          </h2>
          <h2 className={clsx(styles.valueColumn, styles.total)}>{formatCentsToDollars(total)}</h2>
        </>
      )}
    </section>
  );
};
