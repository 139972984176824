import { FC, ChangeEvent } from "react";
import { Grid } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import clsx from "clsx";
import { useStyles } from "./styles";
import { MAX_CHARACTERS_CARDHOLDER_NAME } from "./CardForm.consts";
import { CardErrorMessage } from "../CardErrorMessage";
import { CardMessageStatus } from "./CardForm.types";

interface CardCardholderInputProps {
  hasCardholderNameErrors: boolean | null;
  cardholderName: string | null;
  onChangeCardholderNameHandler: (event: ChangeEvent<HTMLInputElement>) => void;
  onFocusOutCardholderName: (event: ChangeEvent<HTMLInputElement>) => void;
  cardMessageStatus: CardMessageStatus | null;
}

export const CardCardholderInput: FC<CardCardholderInputProps> = ({
  hasCardholderNameErrors,
  cardholderName,
  onChangeCardholderNameHandler,
  onFocusOutCardholderName,
  cardMessageStatus,
}) => {
  const styles = useStyles();
  const { formatMessage } = useIntl();

  return (
    <Grid className={styles.cardholderName} style={{ marginBottom: hasCardholderNameErrors ? "0px" : "28px" }}>
      <label className={styles.label} htmlFor="cardholder-name">
        <FormattedMessage id="card_cardholder_name" />
      </label>
      <input
        maxLength={MAX_CHARACTERS_CARDHOLDER_NAME}
        placeholder={formatMessage({ id: "card_cardholder_name_placeholder" })}
        id="cardholder-name"
        className={clsx(styles.input, hasCardholderNameErrors && styles.errorText)}
        value={cardholderName || ""}
        onChange={onChangeCardholderNameHandler}
        onBlur={onFocusOutCardholderName}
      />
      <CardErrorMessage
        cardMessageStatus={cardMessageStatus}
        emptyErrorMessageId="card_cardholder_error_empty"
        invalidErrorMessageId="card_cardholder_error_invalid"
      />
    </Grid>
  );
};
