import { RuleName } from "../../generated/types";
import { IMembershipRule } from "../../types/membershipTypes";
import { RewardInputsEnum } from "./types";
import {
  EarnTyksForVisitingLocationHeaderControls,
  FrequentCustomerRewardsHeaderControls,
} from "./RewardRuleModal/RewardRuleModalHeaderControls";
import {
  EarmTyksForPurchasesSubHeader,
  FrequentCustomerRewardsSubHeader,
} from "./RewardRuleModal/RewardRuleModalSubHeaders";
import {
  EarnTyksForVisitingLocationFooter,
  FrequentCustomerRewardsFooter,
} from "./RewardRuleModal/RewardRuleModalFooters";

export const dummyRules: IMembershipRule[] = [
  {
    id: "EarnTyksForSignUp",
    internalId: 1,
    title: "Earn Tyks for Enrollment in business program",
    isSelected: false,
    isActive: false,
    category: "In-Store Rules",
    categorySpanish: "Reglas en la tienda",
    ruleName: RuleName.EnrollmentInBusinessProgram,
    pickedRewardInputs: [RewardInputsEnum.DOLLAR_REWARD, RewardInputsEnum.PURCHASE_REWARD_RATE],
  },
  {
    id: "EarnTyksForVisitingLocation",
    internalId: 2,
    title: "Earn Tyks for Visiting Location",
    isSelected: false,
    isActive: false,
    category: "In-Store Rules",
    categorySpanish: "Reglas en la tienda",
    ruleName: RuleName.VisitingLocation,
    multipleTimeReward: true,
    pickedRewardInputs: [
      RewardInputsEnum.DOLLAR_REWARD,
      RewardInputsEnum.PURCHASE_REWARD_RATE,
      RewardInputsEnum.ALLOW_MULTIPLE_REWARD,
      RewardInputsEnum.MULTIPLE_REWARD_COUNT,
    ],
    rewardRuleModalHeaderControls: (headerControls) => (
      <EarnTyksForVisitingLocationHeaderControls
        formik={headerControls.formik}
        period={headerControls.period}
        setPeriod={headerControls.setPeriod}
      />
    ),
    rewardRuleModalFooter: (formik) => <EarnTyksForVisitingLocationFooter formik={formik} />,
  },
  {
    id: "PremiumRewardBonus",
    internalId: 3,
    title: "Premium Reward Bonus with First Purchase",
    isSelected: false,
    isActive: false,
    category: "In-Store Rules",
    categorySpanish: "Reglas en la tienda",
    ruleName: RuleName.PremiumRewardFirstPurchase,
    pickedRewardInputs: [RewardInputsEnum.DOLLAR_REWARD, RewardInputsEnum.PURCHASE_REWARD_RATE],
  },
  {
    id: "EarnTyksForPurchases",
    internalId: 4,
    title: "Earn Tyks for Purchases",
    isSelected: false,
    isActive: false,
    category: "In-Store Rules",
    categorySpanish: "Reglas en la tienda",
    ruleName: RuleName.Purchases,
    multipleTimeReward: true,
    pickedRewardInputs: [
      RewardInputsEnum.DOLLAR_REWARD,
      RewardInputsEnum.PURCHASE_REWARD_RATE,
      RewardInputsEnum.MIN_PURCHASE_FOR_REWARD,
    ],
    rewardRuleModalSubHeader: (formik) => <EarmTyksForPurchasesSubHeader formik={formik} />,
  },
  {
    id: "FrequentCustomerRewards",
    internalId: 5,
    title: "Frequent Customer Rewards",
    isSelected: false,
    isActive: false,
    category: "In-Store Rules",
    categorySpanish: "Reglas en la tienda",
    ruleName: RuleName.FrequentCustomer,
    multipleTimeReward: true,
    pickedRewardInputs: [
      RewardInputsEnum.DOLLAR_REWARD,
      RewardInputsEnum.PURCHASE_REWARD_RATE,
      RewardInputsEnum.REWARD_ON_PURCHASE_COUNT,
      RewardInputsEnum.ALLOW_MULTIPLE_REWARD,
      RewardInputsEnum.MULTIPLE_REWARD_COUNT,
    ],
    rewardRuleModalHeaderControls: (headerControls) => (
      <FrequentCustomerRewardsHeaderControls
        formik={headerControls.formik}
        period={headerControls.period}
        setPeriod={headerControls.setPeriod}
      />
    ),
    rewardRuleModalSubHeader: (formik) => <FrequentCustomerRewardsSubHeader formik={formik} />,
    rewardRuleModalFooter: (formik) => <FrequentCustomerRewardsFooter formik={formik} />,
  },
  {
    id: "AnniversaryOfFirstPurchaseReward",
    internalId: 6,
    title: "Anniversary of their First Purchase Reward",
    isSelected: false,
    isActive: false,
    category: "In-Store Rules",
    categorySpanish: "Reglas en la tienda",
    ruleName: RuleName.AnniversaryOfFirstPurchase,
    pickedRewardInputs: [RewardInputsEnum.DOLLAR_REWARD, RewardInputsEnum.PURCHASE_REWARD_RATE],
  },
  {
    id: "UserBirthdayReward",
    internalId: 7,
    title: "Birthday Reward",
    isSelected: false,
    isActive: false,
    category: "In-Store Rules",
    categorySpanish: "Reglas en la tienda",
    ruleName: RuleName.Birthday,
    multipleTimeReward: true,
    pickedRewardInputs: [RewardInputsEnum.DOLLAR_REWARD, RewardInputsEnum.PURCHASE_REWARD_RATE],
  },
  {
    id: "MostEngagedReward",
    internalId: 8,
    title: "Most Engaged Reward",
    isSelected: false,
    isActive: false,
    category: "In-Store Rules",
    categorySpanish: "Reglas en la tienda",
    ruleName: RuleName.MostEngaged,
    multipleTimeReward: true,
    pickedRewardInputs: [
      RewardInputsEnum.DOLLAR_REWARD,
      RewardInputsEnum.PURCHASE_REWARD_RATE,
      RewardInputsEnum.ALLOW_MULTIPLE_REWARD,
      RewardInputsEnum.MULTIPLE_REWARD_COUNT,
    ],
    rewardRuleModalHeaderControls: (headerControls) => (
      <EarnTyksForVisitingLocationHeaderControls
        formik={headerControls.formik}
        period={headerControls.period}
        setPeriod={headerControls.setPeriod}
      />
    ),
    rewardRuleModalFooter: (formik) => <EarnTyksForVisitingLocationFooter formik={formik} />,
  },
  {
    id: "BonusForTheHighestTyksRewards",
    internalId: 9,
    title: "Bonus for the Highest Tyks Reward",
    isSelected: false,
    isActive: false,
    category: "In-Store Rules",
    categorySpanish: "Reglas en la tienda",
    ruleName: RuleName.HighestTyks,
    pickedRewardInputs: [RewardInputsEnum.DOLLAR_REWARD, RewardInputsEnum.PURCHASE_REWARD_RATE],
    rewardRuleModalHeaderControls: (headerControls) => (
      <EarnTyksForVisitingLocationHeaderControls
        formik={headerControls.formik}
        period={headerControls.period}
        setPeriod={headerControls.setPeriod}
      />
    ),
  },
  {
    id: "RewardsForInviting",
    internalId: 10,
    title: "Rewards for Inviting Friends to TykBiz",
    isSelected: false,
    isActive: false,
    category: "Social Interaction Rules",
    categorySpanish: "Reglas de interacción social",
    ruleName: RuleName.InvitingFriendsToTykbiz,
    pickedRewardInputs: [RewardInputsEnum.DOLLAR_REWARD, RewardInputsEnum.PURCHASE_REWARD_RATE],
  },
  {
    id: "RewardsForInvitingToTyket",
    internalId: 74,
    title: "Invite Friends to Tyket",
    isSelected: false,
    isActive: false,
    category: "Social Interaction Rules",
    categorySpanish: "Reglas de interacción social",
    ruleName: RuleName.InvitingFriendsToTyket,
    pickedRewardInputs: [RewardInputsEnum.DOLLAR_REWARD, RewardInputsEnum.PURCHASE_REWARD_RATE],
  },
  {
    id: "RewardsForDownloadingTyket",
    internalId: 75,
    title: "Download Tyket App",
    isSelected: false,
    isActive: false,
    category: "Social Interaction Rules",
    ruleName: RuleName.DownloadTyketApp,
    pickedRewardInputs: [RewardInputsEnum.DOLLAR_REWARD, RewardInputsEnum.PURCHASE_REWARD_RATE],
  },
];
