import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    wrap: {
      display: "flex",
      flexDirection: "column",
      alignItems: "end",
      position: "relative",

      "& .row": {
        display: "flex",
        alignItems: "center",
        color: "#34305D",

        "& svg": {
          marginRight: "10px",
        },
      },

      "& .MuiTypography-root": {
        color: "#4262FF",
        textDecoration: "underline",
        cursor: "pointer",
      },
    },
  };
});

export default useStyles;
