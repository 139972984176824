/* eslint-disable react/jsx-no-duplicate-props */
import { FC, useMemo, ChangeEvent } from "react";
import { Alert, Button, InputAdornment, InputLabel, TextField, Grid } from "@mui/material";
import { FormattedMessage } from "react-intl";
import * as yup from "yup";
import { useFormik } from "formik";

import Modal from "../../../Modal";

import { useStyles } from "./styles";
import moneyBillAlt from "../../../../assets/icons/paymentIcons/moneyBillAlt.svg";

import { useSubmitHandler } from "./useSubmitHandler.hook";
import { LoadingButton } from "../../../LoadingButton/loading-button.component";
import { AddressSelectionModal } from "../../../AddressSelectionModal";
import { useAddressSelectionModal } from "../../../AddressSelectionModal/useAddressSelectionModal.hook";

interface DepositRechargeModalProps {
  isActive: boolean;
  businessId: string;
  minDeposit: number;
  onClose: VoidFunction;
}

const InputLabelProps = { shrink: true };
const InputProps = {
  startAdornment: <InputAdornment position="start">$</InputAdornment>,
};

const fractionDigitsPattern = /\d+[.]+\d+\d+\d/;
const regExpForFractionDigits = new RegExp(fractionDigitsPattern);

export const DepositRechargeModal: FC<DepositRechargeModalProps> = ({
  isActive,
  businessId,
  minDeposit,
  onClose: handleClose,
}) => {
  const styles = useStyles();
  const [handleSubmit, createDepositCartLoading] = useSubmitHandler(businessId);
  const inputProps = useMemo(() => ({ min: minDeposit }), [minDeposit]);
  const [addressSelectionModalProps, openAddressSelectionModal] = useAddressSelectionModal();

  const formik = useFormik({
    initialValues: {
      rechargeAmount: minDeposit,
    },
    validationSchema: yup.object().shape({
      rechargeAmount: yup.number().min(minDeposit).required(),
    }),
    onSubmit: (values) => {
      openAddressSelectionModal((addressId: string) => {
        handleSubmit(values.rechargeAmount, addressId);
      });
    },
  });

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const checkFractionDigits = regExpForFractionDigits.test(e.target.value);

    if (checkFractionDigits) {
      e.preventDefault();
    } else {
      formik.handleChange(e);
    }
  };

  return (
    <>
      <Modal isOpen={isActive} onClose={handleClose}>
        <div className={styles.modalContent}>
          <div className={styles.iconSection}>
            <img className={styles.billIcon} src={moneyBillAlt} alt="Money bill icon" />
          </div>
          <form noValidate onSubmit={formik.handleSubmit}>
            <header className={styles.modalHeader}>
              <h1>
                <FormattedMessage id="tyk_deposit_settings" />
              </h1>
            </header>
            <Grid mb={!formik.errors.rechargeAmount ? "77px" : "22px"}>
              <InputLabel htmlFor="rechargeAmount" className={styles.inputLabel}>
                <FormattedMessage id="amount_to_recharge_label" />
              </InputLabel>

              <TextField
                id="rechargeAmount"
                type="number"
                value={formik.values.rechargeAmount}
                onChange={onChangeHandler}
                InputLabelProps={InputLabelProps}
                InputProps={InputProps}
                inputProps={inputProps}
                size="small"
                className={styles.textField}
              />

              {formik.errors.rechargeAmount && (
                <Grid mt="7px">
                  <Alert className={styles.alert} severity="error">
                    {formik.errors.rechargeAmount && (
                      <span>
                        <FormattedMessage
                          id="tyk_deposit_settings_amount_minimum"
                          defaultMessage="Your minimum recharge amount is {minDeposit}"
                          values={{ minDeposit: <b>${minDeposit}</b> }}
                        />
                      </span>
                    )}
                  </Alert>
                </Grid>
              )}
            </Grid>
            <footer className={styles.modalFooter}>
              <Button type="button" onClick={handleClose} classes={{ root: "settings-button" }}>
                <FormattedMessage id="btn_text_cancel" />
              </Button>
              <LoadingButton
                className="recharge-button"
                type="submit"
                classes={{ root: "settings-button", loadingIndicator: "loading-indicator" }}
                loadingPosition="start"
                loading={createDepositCartLoading}
                variant="contained"
              >
                <FormattedMessage id="btn_text_recharge_immediately" />
              </LoadingButton>
            </footer>
          </form>
        </div>
      </Modal>
      <AddressSelectionModal {...addressSelectionModalProps} />
    </>
  );
};
