import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    wrap: {
      "& .MuiTypography-root": {
        whiteSpace: "nowrap",
      },
    },
  };
});

export default useStyles;
