import { Document, Page, Text, View, Image } from "@react-pdf/renderer";

import { capitalize } from "@mui/material";
import TykChaseLogoPNG from "../../../../assets/icons/menuIcons/png/logoTykchase.png";

import { createTable } from "./Table";
import { ReceiptDocumentProps } from "./interfaces/receipt-document.interface";
import { receiptDocumentStyles } from "./styles";
import { registerReceiptDocumentFonts } from "./font";
import { formatCentsToDollars } from "../../../shared/PriceFormatters";
import { formatDate } from "../../../../utils/formatDate";

registerReceiptDocumentFonts();

export function ReceiptDocument(props: ReceiptDocumentProps): JSX.Element {
  const taxInCents = props.details.cartHistory.taxInCents ?? 0;
  const taxesPercentage = ((taxInCents / (props.total - taxInCents)) * 100).toFixed(2);

  return (
    <>
      {!props.details && (
        <Document>
          <Page size="LETTER">
            <View>
              <Text>{props.pdfGenerationErrorLabel}</Text>
            </View>
          </Page>
        </Document>
      )}
      {props.details && (
        <Document>
          <Page size="LETTER" style={receiptDocumentStyles.font}>
            <View style={receiptDocumentStyles.topLevelWrapper}>
              <View style={receiptDocumentStyles.topGraySection}>
                <Image src={TykChaseLogoPNG} style={receiptDocumentStyles.tykChaseLogoLeft} />
                <View style={receiptDocumentStyles.documentHeadingSectionRight}>
                  <Text style={receiptDocumentStyles.topRightTransactionTitle}>
                    {`${formatDate(props.details.cartHistory.createdAt, { month: "long" })} Statement`}
                  </Text>
                  <View style={receiptDocumentStyles.topRightAccountEmailLine}>
                    <Text style={receiptDocumentStyles.textMediumLightGray}>{`${props.accountLabel} `}</Text>
                    <Text style={receiptDocumentStyles.textMediumBoldLightGray}>{props.account?.email}</Text>
                  </View>
                  <View style={receiptDocumentStyles.topRightInvoiceNumberLine}>
                    <Text style={receiptDocumentStyles.regularFont}>{`${props.invoiceNumberLabel} `}</Text>
                    <Text style={receiptDocumentStyles.transactionId}>{props.details.id}</Text>
                  </View>
                  <Text style={receiptDocumentStyles.transactionCreatedAt}>
                    {formatDate(props.details.cartHistory.createdAt, {
                      month: "long",
                      day: "numeric",
                      year: "numeric",
                    })}
                  </Text>
                </View>
              </View>
              <View style={receiptDocumentStyles.contentSection}>
                <View style={receiptDocumentStyles.orderDetailsAndTableSection}>
                  {createTable({
                    tax: `${taxesPercentage}%`,
                    total: formatCentsToDollars(props.total),
                    sectionObjects: [
                      {
                        title: props.productLabel,
                        columnTitles: [props.priceLabel],
                        rows: props.details.cartHistory.cartLineItemsHistory.map((cartLineItem) => ({
                          title: cartLineItem.title || capitalize(cartLineItem.metadata?.itemType.toLowerCase()),
                          data: [
                            {
                              title: formatCentsToDollars(cartLineItem.priceInCents),
                            },
                          ],
                        })),
                      },
                    ],
                  })}
                </View>
                <View style={receiptDocumentStyles.billingEndOfPageSection}>
                  <View style={receiptDocumentStyles.endOfPageBottomLineSection}>
                    <View style={receiptDocumentStyles.endOfPageBottomLine} />
                  </View>
                </View>
              </View>
            </View>
          </Page>
        </Document>
      )}
    </>
  );
}
