import { TransactionsHistory } from "../../../generated/types";
import { BillingOrderByFieldEnum, BillingOrderEnum } from "./Billing.interfaces";

export function descendingComparator(a: TransactionsHistory, b: TransactionsHistory, orderBy: BillingOrderByFieldEnum) {
  if (!!a[orderBy] || b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (!!b[orderBy] || b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order: BillingOrderEnum, orderBy: BillingOrderByFieldEnum) {
  return order === BillingOrderEnum.DESC
    ? (a: TransactionsHistory, b: TransactionsHistory) => descendingComparator(a, b, orderBy)
    : (a: TransactionsHistory, b: TransactionsHistory) => -descendingComparator(a, b, orderBy);
}
