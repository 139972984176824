import { FC } from "react";
import { RewardRuleModalFooterProps } from "./RewardRuleModalFooters.interfaces";
import Footer from "../../Modals/Footer";

export const EarnTyksForVisitingLocationFooter: FC<RewardRuleModalFooterProps> = ({ formik }) => {
  return (
    <Footer
      switches
      multipleRewardCountFieldProps={formik.getFieldProps("multipleRewardCount")}
      multipleRewardCountError={formik?.touched?.multipleRewardCount ? formik?.errors?.multipleRewardCount : ""}
      allowMultipleRewardProps={formik.getFieldProps("allowMultipleReward")}
      isSubmitting={formik.isSubmitting}
    />
  );
};
