export const CENTS_PER_DOLLAR = 100;
export const CURRENCY_DISPLAY_SYMBOL = "symbol";
export const CURRENCY_DISPLAY_NARROW_SYMBOL = "narrowSymbol";
const ZERO_FRACTION_DIGIT = 0;
const TWO_FRACTION_DIGITS = 2;

export const formatDollars = (cents: number, currencyDisplay = CURRENCY_DISPLAY_SYMBOL) => {
  const isIntegerCents = Number.isInteger(cents);
  const checkZeroCentsDigits = String(cents).slice(-2) === "00";
  const isZeroFractionDigit = isIntegerCents || (!isIntegerCents && checkZeroCentsDigits) || !cents;

  const maximumFractionDigits = isZeroFractionDigit ? ZERO_FRACTION_DIGIT : TWO_FRACTION_DIGITS;

  return Intl.NumberFormat(undefined, {
    currency: "USD",
    style: "currency",
    currencyDisplay,
    maximumFractionDigits,
  }).format(cents);
};

export const formatCentsToDollars = (cents: number) => {
  return formatDollars(cents / CENTS_PER_DOLLAR);
};
