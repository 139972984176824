import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import { Grid } from "@mui/material";
import { useIntl } from "react-intl";
import manWalking from "../../assets/images/manWalking.png";
import FallBackPages from "../FallBackPages";
import withAnalyticsTabsLayout from "./HOCs/withAnalyticsTabsLayout";
import General from "./General";
import Demographics from "./Demographics";
import Psychographics from "./Psychographics";
import { useFeatureFlagValue } from "../../hooks";
import { Routes } from "../../constants";
import useStyles from "./styles";
import { FeatureFlagEnum } from "../../types/contexts";

const Analytics = () => {
  const { rootContainer } = useStyles();
  const { path } = useRouteMatch();
  const isAnalyticsV2Enabled = useFeatureFlagValue(FeatureFlagEnum.ANALYTICS_V2_ENABLED);

  const { formatMessage } = useIntl();
  return (
    <Grid>
      <Grid container item direction="column" className={rootContainer}>
        {isAnalyticsV2Enabled ? (
          <Switch>
            <Route path={`${path}${Routes.DEMOGRAPHICS}`} component={withAnalyticsTabsLayout(Demographics)} />
            <Route path={`${path}${Routes.PSYCHOGRAPHICS}`} component={withAnalyticsTabsLayout(Psychographics)} />
            <Route exact path={Routes.ANALYTICS} component={withAnalyticsTabsLayout(General)} />
            <Route>
              <Redirect to={Routes.ANALYTICS} />
            </Route>
          </Switch>
        ) : (
          <FallBackPages
            image={manWalking}
            title={formatMessage({ id: "analytics.analytics_not_available.title" })}
            description={<span>{formatMessage({ id: "not_available.description" })}</span>}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default Analytics;
