import { ReactElement } from "react";
import { defineMessages, IntlShape } from "react-intl";

interface MainWidgetItem {
  icon: ReactElement;
  title: string;
}

const messages = defineMessages({
  scannedReceipt: {
    id: "dashboard_main_widget_scanned_receipt",
    defaultMessage: "Scanned Receipt",
  },
  totalScanned: {
    id: "dashboard_main_widget_total_scanned",
    defaultMessage: "Total Scanned",
  },
  averagePerReceipt: {
    id: "dashboard_main_widget_average_per_receipt",
    defaultMessage: "Average per Receipt",
  },
  mostPurchasedItem: {
    id: "dashboard_main_widget_most_purchase_item",
    defaultMessage: "Most Purchased Item",
  },
});

const MainWidgetTitles = (intl: IntlShape) => {
  return {
    SCANNED_RECEIPTS: intl.formatMessage(messages.scannedReceipt),
    TOTAL_SCANNED: intl.formatMessage(messages.totalScanned),
    AVERAGE_PER_RECEIPT: intl.formatMessage(messages.averagePerReceipt),
    MOST_PURCHASED_ITEM: intl.formatMessage(messages.mostPurchasedItem),
  };
};

export { MainWidgetTitles };
export type { MainWidgetItem };
