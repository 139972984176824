import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import JackpotSrc from "../assets/icons/tykIcons/Jackpot-Icon(1)@2x.png";
import CashierSrc from "../assets/icons/tykIcons/cashier-icon@2x.png";
import RewardsIconYellow from "../assets/icons/tykIcons/rewardsIconYelow.png";
import HappyGirlsSrc from "../assets/images/happyGirls.png";
import sadTykCharacter from "../assets/icons/tykIcons/sadTykCharacter.svg";

const Recycle = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 101.428 101.427">
    <g id="recycle" transform="translate(-176.628 -176.63)">
      <path
        id="Path_62055"
        data-name="Path 62055"
        d="M92.646,19.148A50.711,50.711,0,0,0,22.965,9.834L18.5,4.229a1.981,1.981,0,0,0-3.374.461L2.961,33.41a1.981,1.981,0,0,0,2.167,2.724l30.72-5.407a1.981,1.981,0,0,0,1.206-3.184l-4.4-5.532a35.146,35.146,0,0,1,34.261-3.577,1.981,1.981,0,1,0,1.567-3.638A39.1,39.1,0,0,0,28.6,20.1a1.981,1.981,0,0,0-.317,2.784l3.6,4.518L8.031,31.6,17.478,9.3l3.6,4.519a1.981,1.981,0,0,0,2.783.317A46.745,46.745,0,0,1,99.536,54.726L91.719,56.1a38.993,38.993,0,0,0-8.136-29.746,1.981,1.981,0,0,0-3.1,2.466,35.068,35.068,0,0,1,6.857,29.305,1.981,1.981,0,0,0,1.936,2.4c.114,0,12.422-2.158,12.422-2.158a1.981,1.981,0,0,0,1.623-1.719,50.349,50.349,0,0,0-10.676-37.5Z"
        transform="translate(174.379 176.626)"
        fill="#ffd02f"
      />
      <path
        id="Path_62056"
        data-name="Path 62056"
        d="M98.55,230.888l-30.72,5.4a1.981,1.981,0,0,0-1.207,3.184l4.4,5.537A34.969,34.969,0,0,1,52.7,251.4c-2.26.083-3.962,0-3.962,0a35.133,35.133,0,0,1-32.4-42.5,1.981,1.981,0,0,0-2.28-2.367L1.981,208.648a1.981,1.981,0,0,0-1.624,1.719,50.7,50.7,0,0,0,44.622,56.316,51.49,51.49,0,0,0,5.836.332,50.28,50.28,0,0,0,29.9-9.829l4.461,5.605a1.981,1.981,0,0,0,3.374-.461l12.168-28.72a1.981,1.981,0,0,0-2.167-2.724ZM86.2,257.719,82.6,253.2a1.981,1.981,0,0,0-2.783-.317A46.745,46.745,0,0,1,4.141,212.291l7.818-1.374a39.142,39.142,0,0,0,63.117,36,1.981,1.981,0,0,0,.317-2.783l-3.6-4.524,23.852-4.194Z"
        transform="translate(176.628 11.041)"
        fill="#ffd02f"
      />
    </g>
  </svg>
);

Recycle.defaultProps = {
  width: "101.428",
  height: "101.427",
};

const CreditCardIcon = ({ fill = "currentColor" }: { fill?: string }) => (
  <svg
    aria-hidden="true"
    fill={fill}
    focusable="false"
    data-prefix="fas"
    data-icon="credit-card"
    className="svg-inline--fa fa-credit-card fa-w-18"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 576 512"
  >
    <path d="M0 432c0 26.5 21.5 48 48 48h480c26.5 0 48-21.5 48-48V256H0v176zm192-68c0-6.6 5.4-12 12-12h136c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H204c-6.6 0-12-5.4-12-12v-40zm-128 0c0-6.6 5.4-12 12-12h72c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM576 80v48H0V80c0-26.5 21.5-48 48-48h480c26.5 0 48 21.5 48 48z" />
  </svg>
);

const Jackpot = (props) => (
  <Grid {...props} container>
    <img height="100%" width="100%" src={JackpotSrc} alt="jackpot icon" />
  </Grid>
);

Jackpot.defaultProps = {
  width: "100%",
};

const Centors = () => (
  <Grid container height="40px" width="40px">
    <svg id="Centors" xmlns="http://www.w3.org/2000/svg" width="63.744" height="47.609" viewBox="0 0 63.744 47.609">
      <defs>
        <clipPath id="clip-path">
          <path
            id="Path_62204"
            data-name="Path 62204"
            d="M997.126,446.277a3.354,3.354,0,0,1,.678.2l-3.9,10.573a9.836,9.836,0,0,1-.929-7.9c1.045-3.031,3.091-3.08,4.154-2.87"
            transform="translate(-992.503 -446.204)"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-2">
          <path
            id="Path_62207"
            data-name="Path 62207"
            d="M1068.542,446.277c1.064-.21,3.109-.16,4.155,2.87a9.84,9.84,0,0,1-.929,7.9l-3.9-10.573a3.376,3.376,0,0,1,.678-.2"
            transform="translate(-1067.864 -446.204)"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-3">
          <path
            id="Path_62210"
            data-name="Path 62210"
            d="M1024.368,448.565h.458c11.556.584,22.333-6.382,22.527-9.91.479-8.677-1.641-15.574-6.3-20.5a24.518,24.518,0,0,0-16.375-7.3h-.188a24.517,24.517,0,0,0-16.375,7.3c-4.661,4.924-6.781,11.822-6.3,20.5C1002.008,442.192,1012.8,449.152,1024.368,448.565Z"
            transform="translate(-1001.746 -410.862)"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-4">
          <path
            id="Path_62214"
            data-name="Path 62214"
            d="M1027.524,438.784c-.193,0-.326.267-.253.51.38,1.266,1.649,4.454,4.8,4.454s4.445-3.21,4.837-4.487c.075-.245-.059-.515-.254-.514Z"
            transform="translate(-1027.25 -438.748)"
            fill="#558b57"
          />
        </clipPath>
        <clipPath id="clip-path-5">
          <rect
            id="Rectangle_148597"
            data-name="Rectangle 148597"
            width="4.793"
            height="8.286"
            rx="2.396"
            fill="none"
          />
        </clipPath>
      </defs>
      <g id="Group_144591" data-name="Group 144591" transform="translate(0 0)">
        <g id="Group_144590" data-name="Group 144590">
          <path
            id="Path_2589"
            data-name="Path 2589"
            d="M1021.252,457.8c17.359,0,31.417,2.616,31.417,5.847s-14.042,5.843-31.417,5.843-31.418-2.616-31.418-5.843S1003.91,457.8,1021.252,457.8Z"
            transform="translate(-989.38 -421.886)"
            fill="#707070"
            opacity="0.421"
          />
          <path
            id="Path_62202"
            data-name="Path 62202"
            d="M996.177,445.328a3.363,3.363,0,0,1,.678.2l-3.9,10.573a9.838,9.838,0,0,1-.929-7.9c1.045-3.031,3.091-3.08,4.154-2.87m.457-2.315c-2.408-.475-5.469.436-6.841,4.416a12.248,12.248,0,0,0,1.176,9.951l2.559,3.972,1.637-4.432,3.9-10.572.759-2.056-1.994-.908a5.713,5.713,0,0,0-1.2-.37Z"
            transform="translate(-989.197 -417.607)"
            fill="#276733"
          />
          <path
            id="Path_62203"
            data-name="Path 62203"
            d="M997.126,446.277a3.354,3.354,0,0,1,.678.2l-3.9,10.573a9.836,9.836,0,0,1-.929-7.9c1.045-3.031,3.091-3.08,4.154-2.87"
            transform="translate(-990.146 -418.555)"
            fill="#558b57"
          />
          <g id="Group_144570" data-name="Group 144570" transform="translate(2.357 27.648)">
            <g id="Group_144569" data-name="Group 144569" clipPath="url(#clip-path)">
              <ellipse
                id="Ellipse_775"
                data-name="Ellipse 775"
                cx="7.699"
                cy="9.39"
                rx="7.699"
                ry="9.39"
                transform="translate(-4.912 -15.981)"
                fill="#fff"
              />
            </g>
          </g>
          <path
            id="Path_62205"
            data-name="Path 62205"
            d="M1067.345,445.328c1.064-.21,3.109-.16,4.155,2.87a9.84,9.84,0,0,1-.929,7.9l-3.9-10.573a3.39,3.39,0,0,1,.678-.2m-.456-2.315a5.714,5.714,0,0,0-1.2.37l-1.994.908.759,2.056,3.9,10.572,1.637,4.432,2.56-3.972a12.25,12.25,0,0,0,1.176-9.951c-1.373-3.98-4.435-4.891-6.842-4.416Z"
            transform="translate(-1010.582 -417.607)"
            fill="#276733"
          />
          <path
            id="Path_62206"
            data-name="Path 62206"
            d="M1068.542,446.277c1.064-.21,3.109-.16,4.155,2.87a9.84,9.84,0,0,1-.929,7.9l-3.9-10.573a3.376,3.376,0,0,1,.678-.2"
            transform="translate(-1011.779 -418.555)"
            fill="#558b57"
          />
          <g id="Group_144572" data-name="Group 144572" transform="translate(56.085 27.648)">
            <g id="Group_144571" data-name="Group 144571" clipPath="url(#clip-path-2)">
              <ellipse
                id="Ellipse_776"
                data-name="Ellipse 776"
                cx="6.226"
                cy="7.594"
                rx="6.226"
                ry="7.594"
                transform="translate(0.301 -11.145)"
                fill="#fff"
              />
            </g>
          </g>
          <path
            id="Path_62208"
            data-name="Path 62208"
            d="M1024.368,448.565h.458c11.556.584,22.333-6.382,22.527-9.91.479-8.677-1.641-15.574-6.3-20.5a24.518,24.518,0,0,0-16.375-7.3h-.188a24.517,24.517,0,0,0-16.375,7.3c-4.661,4.924-6.781,11.822-6.3,20.5C1002.008,442.192,1012.8,449.152,1024.368,448.565Z"
            transform="translate(-992.799 -408.41)"
            fill="#558b57"
          />
          <g id="Group_144574" data-name="Group 144574" transform="translate(8.947 2.452)">
            <g id="Group_144573" data-name="Group 144573" clipPath="url(#clip-path-3)">
              <ellipse
                id="Ellipse_777"
                data-name="Ellipse 777"
                cx="20.543"
                cy="23.382"
                rx="20.543"
                ry="23.382"
                transform="matrix(0.976, -0.217, 0.217, 0.976, -3.807, 1.266)"
                fill="#b1d3a4"
              />
              <path
                id="Path_62209"
                data-name="Path 62209"
                d="M1010.839,360.811c12.689,7,28.889,6.408,22.377,18.216-6.955,12.612-34.727,30.738-45.959,24.544-12.689-7-17.7-22.242-11.184-34.05S998.151,353.813,1010.839,360.811Z"
                transform="translate(-993.557 -395.496)"
                fill="#f2f2f2"
              />
            </g>
          </g>
          <path
            id="Path_62211"
            data-name="Path 62211"
            d="M1046.814,433.161c.107-.23-2.25-19.383-21.594-19.383-20.141,0-21.509,19.251-21.486,19.3,1.266,2.669,6.1,10.3,21.423,10.739C1040.767,444.27,1045.783,435.379,1046.814,433.161Z"
            transform="translate(-993.37 -409.247)"
            fill="#19441f"
          />
          <path
            id="Path_62212"
            data-name="Path 62212"
            d="M1021.987,450.065c-11.58,0-23.312-6.857-23.609-12.26-.518-9.377,1.827-16.886,6.968-22.319a26.745,26.745,0,0,1,18.157-8.063h.188a26.745,26.745,0,0,1,18.156,8.063c5.142,5.433,7.487,12.943,6.968,22.319-.309,5.613-12.988,12.813-25.069,12.225h-.308C1022.956,450.052,1022.471,450.065,1021.987,450.065Zm1.394-2.487h.458c11.556.584,22.333-6.382,22.527-9.91.479-8.676-1.641-15.574-6.3-20.5a24.517,24.517,0,0,0-16.375-7.3h-.188a24.518,24.518,0,0,0-16.376,7.3c-4.661,4.924-6.781,11.822-6.3,20.5C1001.021,441.2,1011.815,448.165,1023.381,447.578Z"
            transform="translate(-991.812 -407.423)"
            fill="#276733"
          />
          <g id="Group_144578" data-name="Group 144578" transform="translate(27.13 22.333)">
            <g id="Group_144577" data-name="Group 144577">
              <path
                id="Path_62213"
                data-name="Path 62213"
                d="M1027.524,438.784c-.193,0-.326.267-.253.51.38,1.266,1.649,4.454,4.8,4.454s4.445-3.21,4.837-4.487c.075-.245-.059-.515-.254-.514Z"
                transform="translate(-1027.25 -438.748)"
                fill="#558b57"
              />
              <g id="Group_144576" data-name="Group 144576" clipPath="url(#clip-path-4)">
                <g id="Group_144575" data-name="Group 144575" transform="translate(1.341 2.47)">
                  <ellipse
                    id="Ellipse_778"
                    data-name="Ellipse 778"
                    cx="3.427"
                    cy="2.537"
                    rx="3.427"
                    ry="2.537"
                    fill="#dff7ca"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="Group_144589" data-name="Group 144589" transform="translate(18.346 8.162)">
            <g id="Group_144581" data-name="Group 144581">
              <g id="Group_144579" data-name="Group 144579">
                <path
                  id="Path_62215"
                  data-name="Path 62215"
                  d="M1014.933,424.634a6.183,6.183,0,0,1,.822-3.224,4.99,4.99,0,0,1,2.673-2.35,4.561,4.561,0,0,1,.922-.175,4.34,4.34,0,0,1,.94.026,3.412,3.412,0,0,1,1.753.816,4.241,4.241,0,0,1,1.319,3.156,8.029,8.029,0,0,0-2.212-1.746,2.592,2.592,0,0,0-1.032-.2,3.369,3.369,0,0,0-.548.054,3.092,3.092,0,0,0-.54.119,5.912,5.912,0,0,0-2.012,1.346C1016.332,423.085,1015.692,423.833,1014.933,424.634Z"
                  transform="translate(-1014.93 -418.871)"
                  fill="#8dfc81"
                />
              </g>
              <g id="Group_144580" data-name="Group 144580" transform="translate(19.389 0.002)">
                <path
                  id="Path_62216"
                  data-name="Path 62216"
                  d="M1049.96,425.077c-.719-.849-1.312-1.658-1.945-2.365a6.067,6.067,0,0,0-1.871-1.574,1.892,1.892,0,0,0-.207-.077c-.091-.019-.187-.051-.272-.061a2.466,2.466,0,0,0-.5-.059,1.954,1.954,0,0,0-.945.245,10.063,10.063,0,0,0-2.088,1.976,4.6,4.6,0,0,1,1.054-3.277,3.111,3.111,0,0,1,1.773-.97,3.733,3.733,0,0,1,.994-.015,4.358,4.358,0,0,1,.465.085,3.625,3.625,0,0,1,.5.165,5.072,5.072,0,0,1,2.446,2.643A6.749,6.749,0,0,1,1049.96,425.077Z"
                  transform="translate(-1042.127 -418.874)"
                  fill="#8dfc81"
                />
              </g>
            </g>
            <g id="Group_144588" data-name="Group 144588" transform="translate(2.094 6.339)">
              <g id="Group_144584" data-name="Group 144584">
                <rect
                  id="Rectangle_148595"
                  data-name="Rectangle 148595"
                  width="4.793"
                  height="9.452"
                  rx="2.396"
                  fill="#8dfc81"
                />
                <rect
                  id="Rectangle_148596"
                  data-name="Rectangle 148596"
                  width="2.45"
                  height="4.831"
                  rx="1.225"
                  transform="translate(1.171 3.023)"
                  fill="#276733"
                />
                <g id="Group_144583" data-name="Group 144583">
                  <g id="Group_144582" data-name="Group 144582" clipPath="url(#clip-path-5)">
                    <circle
                      id="Ellipse_779"
                      data-name="Ellipse 779"
                      cx="2.317"
                      cy="2.317"
                      r="2.317"
                      transform="translate(-0.548 -0.229)"
                      fill="#fff"
                    />
                  </g>
                </g>
              </g>
              <g id="Group_144587" data-name="Group 144587" transform="translate(18.263)">
                <rect
                  id="Rectangle_148598"
                  data-name="Rectangle 148598"
                  width="4.793"
                  height="9.452"
                  rx="2.396"
                  fill="#8dfc81"
                />
                <rect
                  id="Rectangle_148599"
                  data-name="Rectangle 148599"
                  width="2.45"
                  height="4.831"
                  rx="1.225"
                  transform="translate(1.172 3.023)"
                  fill="#276733"
                />
                <g id="Group_144586" data-name="Group 144586">
                  <g id="Group_144585" data-name="Group 144585" clipPath="url(#clip-path-5)">
                    <circle
                      id="Ellipse_780"
                      data-name="Ellipse 780"
                      cx="2.317"
                      cy="2.317"
                      r="2.317"
                      transform="translate(-0.548 -0.229)"
                      fill="#fff"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </Grid>
);

const CentorsFull = (props) => {
  const { iconContainer } = makeStyles(() => ({
    iconContainer: {
      position: "relative",

      "& svg": {
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
      },
    },
  }))();

  return (
    <Grid className={iconContainer} container {...props}>
      <svg id="Centor-character" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.937 64.567">
        <defs>
          <linearGradient
            id="linear-gradient"
            x1="0.5"
            y1="0.996"
            x2="0.5"
            y2="0.116"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#b1d3a4" />
            <stop offset="0.071" stopColor="#add0a1" stopOpacity="0.922" />
            <stop offset="0.531" stopColor="#96be8e" stopOpacity="0.431" />
            <stop offset="0.85" stopColor="#88b383" stopOpacity="0.122" />
            <stop offset="1" stopColor="#83af7f" stopOpacity="0" />
          </linearGradient>
          <clipPath id="clip-path">
            <path
              id="SVGID"
              d="M730.365,618.469a1.982,1.982,0,0,1,.4.119l-2.306,6.246a5.812,5.812,0,0,1-.549-4.67c.618-1.791,1.826-1.82,2.454-1.7"
              transform="translate(-727.634 -618.425)"
              fill="none"
            />
          </clipPath>
          <clipPath id="clip-path-2">
            <path
              id="SVGID-2"
              data-name="SVGID"
              d="M878.729,618.469c.628-.124,1.837-.095,2.454,1.7a5.813,5.813,0,0,1-.549,4.67l-2.306-6.246a1.982,1.982,0,0,1,.4-.119"
              transform="translate(-878.328 -618.425)"
              fill="none"
            />
          </clipPath>
          <clipPath id="clip-path-3">
            <path
              id="SVGID-3"
              data-name="SVGID"
              d="M759.482,570.031h.271c6.827.345,13.194-3.77,13.309-5.855.283-5.127-.97-9.2-3.723-12.111a14.485,14.485,0,0,0-9.674-4.31h-.111a14.484,14.484,0,0,0-9.674,4.31c-2.753,2.91-4.006,6.984-3.723,12.111C746.272,566.266,752.648,570.378,759.482,570.031Z"
              transform="translate(-746.117 -547.756)"
              fill="none"
            />
          </clipPath>
          <clipPath id="clip-path-4">
            <path
              id="SVGID-4"
              data-name="SVGID"
              d="M797.277,603.538c-.114,0-.193.158-.149.3.224.748.974,2.631,2.834,2.631s2.626-1.9,2.857-2.651c.044-.144-.035-.3-.15-.3Z"
              transform="translate(-797.116 -603.517)"
              fill="#558b57"
            />
          </clipPath>
          <clipPath id="clip-path-5">
            <rect id="SVGID-5" data-name="SVGID" width="2.831" height="4.895" rx="1.416" fill="none" />
          </clipPath>
        </defs>
        <g id="Group_160361" data-name="Group 160361" transform="translate(6.071 0)">
          <path
            id="Path_94271"
            data-name="Path 94271"
            d="M762.823,384.943H726.546v56.972h0c.086,2.1,8.17,3.789,18.134,3.789s18.047-1.693,18.133-3.789h.006Z"
            transform="translate(-725.315 -384.943)"
            opacity="0.3"
            fill="url(#linear-gradient)"
            style={{ mixBlendMode: "multiply", isolation: "isolate" }}
          />
          <g id="Group_160360" data-name="Group 160360" transform="translate(0 11.981)">
            <rect
              id="Rectangle_148724"
              data-name="Rectangle 148724"
              width="1.545"
              height="1.545"
              transform="translate(1.863 11.01) rotate(-45)"
              fill="#8edd83"
            />
            <rect
              id="Rectangle_148725"
              data-name="Rectangle 148725"
              width="1.923"
              height="1.923"
              transform="translate(0 27.004) rotate(-45)"
              fill="#8edd83"
            />
            <rect
              id="Rectangle_148726"
              data-name="Rectangle 148726"
              width="1.545"
              height="1.545"
              transform="translate(36.474 18.075) rotate(-45)"
              fill="#8edd83"
            />
            <rect
              id="Rectangle_148727"
              data-name="Rectangle 148727"
              width="0.914"
              height="0.914"
              transform="translate(31.881 0.646) rotate(-45)"
              fill="#8edd83"
            />
          </g>
        </g>
        <g id="Group_160362" data-name="Group 160362" transform="translate(0 50.679)">
          <path
            id="Path_94272"
            data-name="Path 94272"
            d="M716.847,639.422c-14,0-24.969-3.05-24.969-6.944s10.968-6.944,24.969-6.944,24.969,3.05,24.969,6.944S730.848,639.422,716.847,639.422Zm0-13.294c-13.229,0-24.4,2.908-24.4,6.35s11.176,6.35,24.4,6.35,24.405-2.908,24.405-6.35S730.075,626.128,716.847,626.128Z"
            transform="translate(-691.878 -625.535)"
            fill="#b5e5a1"
          />
        </g>
        <g id="Group_160386" data-name="Group 160386" transform="translate(7.07 32.634)">
          <g id="Group_160385" data-name="Group 160385" transform="translate(0 0)">
            <g id="Group_160384" data-name="Group 160384">
              <path
                id="Path_2589"
                data-name="Path 2589"
                d="M740.859,641.623c10.255,0,18.561,1.546,18.561,3.454s-8.3,3.452-18.561,3.452-18.561-1.546-18.561-3.452S730.613,641.623,740.859,641.623Z"
                transform="translate(-722.029 -620.402)"
                fill="#707070"
                opacity="0.421"
                style={{ mixBlendMode: "multiply", isolation: "isolate" }}
              />
              <path
                id="Path_94273"
                data-name="Path 94273"
                d="M725.147,613.252a1.977,1.977,0,0,1,.4.119l-2.306,6.246a5.812,5.812,0,0,1-.549-4.67c.618-1.791,1.826-1.82,2.454-1.7m.27-1.368a3.466,3.466,0,0,0-4.042,2.609,7.236,7.236,0,0,0,.695,5.879l1.512,2.347.967-2.619,2.306-6.246.448-1.214-1.178-.537a3.339,3.339,0,0,0-.709-.218Z"
                transform="translate(-721.022 -596.874)"
                fill="#276733"
              />
              <path
                id="Path_94274"
                data-name="Path 94274"
                d="M730.365,618.469a1.982,1.982,0,0,1,.4.119l-2.306,6.246a5.812,5.812,0,0,1-.549-4.67c.618-1.791,1.826-1.82,2.454-1.7"
                transform="translate(-726.241 -602.091)"
                fill="#558b57"
              />
              <g id="Group_160364" data-name="Group 160364" transform="translate(1.393 16.334)">
                <g id="Group_160363" data-name="Group 160363" clipPath="url(#clip-path)">
                  <ellipse
                    id="Ellipse_735"
                    data-name="Ellipse 735"
                    cx="4.548"
                    cy="5.548"
                    rx="4.548"
                    ry="5.548"
                    transform="translate(-2.902 -9.442)"
                    fill="#fff"
                  />
                </g>
              </g>
              <path
                id="Path_94275"
                data-name="Path 94275"
                d="M872.149,613.252c.628-.124,1.837-.095,2.455,1.7a5.812,5.812,0,0,1-.549,4.67l-2.306-6.246a1.983,1.983,0,0,1,.4-.119m-.27-1.368a3.329,3.329,0,0,0-.709.218l-1.178.537.449,1.214,2.306,6.246.967,2.619,1.512-2.347a7.236,7.236,0,0,0,.695-5.879,3.466,3.466,0,0,0-4.042-2.609Z"
                transform="translate(-838.613 -596.874)"
                fill="#276733"
              />
              <path
                id="Path_94276"
                data-name="Path 94276"
                d="M878.729,618.469c.628-.124,1.837-.095,2.454,1.7a5.813,5.813,0,0,1-.549,4.67l-2.306-6.246a1.982,1.982,0,0,1,.4-.119"
                transform="translate(-845.194 -602.091)"
                fill="#558b57"
              />
              <g id="Group_160366" data-name="Group 160366" transform="translate(33.135 16.334)">
                <g id="Group_160365" data-name="Group 160365" clipPath="url(#clip-path-2)">
                  <ellipse
                    id="Ellipse_736"
                    data-name="Ellipse 736"
                    cx="3.678"
                    cy="4.487"
                    rx="3.678"
                    ry="4.487"
                    transform="translate(0.178 -6.584)"
                    fill="#fff"
                  />
                </g>
              </g>
              <path
                id="Path_94277"
                data-name="Path 94277"
                d="M759.482,570.031h.271c6.827.345,13.194-3.77,13.309-5.855.283-5.127-.97-9.2-3.723-12.111a14.485,14.485,0,0,0-9.674-4.31h-.111a14.484,14.484,0,0,0-9.674,4.31c-2.753,2.91-4.006,6.984-3.723,12.111C746.272,566.266,752.648,570.378,759.482,570.031Z"
                transform="translate(-740.831 -546.308)"
                fill="#558b57"
              />
              <g id="Group_160368" data-name="Group 160368" transform="translate(5.286 1.448)">
                <g id="Group_160367" data-name="Group 160367" clipPath="url(#clip-path-3)">
                  <ellipse
                    id="Ellipse_737"
                    data-name="Ellipse 737"
                    cx="12.136"
                    cy="13.814"
                    rx="12.136"
                    ry="13.814"
                    transform="matrix(0.976, -0.217, 0.217, 0.976, -2.249, 0.748)"
                    fill="#b1d3a4"
                  />
                  <path
                    id="Path_94278"
                    data-name="Path 94278"
                    d="M711.3,442.775c7.5,4.134,17.067,3.786,13.22,10.762-4.109,7.451-20.516,18.16-27.152,14.5-7.5-4.134-10.455-13.141-6.608-20.117S703.8,438.641,711.3,442.775Z"
                    transform="translate(-701.09 -463.268)"
                    fill="#f2f2f2"
                  />
                </g>
              </g>
              <path
                id="Path_94279"
                data-name="Path 94279"
                d="M775.544,565.038c.063-.135-1.329-11.452-12.758-11.452-11.9,0-12.708,11.374-12.693,11.4.748,1.577,3.6,6.083,12.656,6.345C771.971,571.6,774.934,566.348,775.544,565.038Z"
                transform="translate(-743.969 -550.91)"
                fill="#19441f"
              />
              <path
                id="Path_94280"
                data-name="Path 94280"
                d="M753.23,566.073c-6.841,0-13.772-4.051-13.949-7.243-.306-5.54,1.079-9.976,4.117-13.187a15.8,15.8,0,0,1,10.727-4.763h.111a15.8,15.8,0,0,1,10.726,4.763c3.037,3.21,4.423,7.647,4.117,13.187-.183,3.316-7.674,7.57-14.811,7.222h-.182C753.8,566.066,753.516,566.073,753.23,566.073Zm.824-1.469h.271c6.827.345,13.194-3.77,13.309-5.855.283-5.126-.97-9.2-3.723-12.11a14.483,14.483,0,0,0-9.674-4.311h-.111a14.484,14.484,0,0,0-9.674,4.311c-2.753,2.909-4.006,6.984-3.723,12.11C740.843,560.838,747.22,564.95,754.054,564.6Z"
                transform="translate(-735.403 -540.88)"
                fill="#276733"
              />
              <g id="Group_160372" data-name="Group 160372" transform="translate(16.028 13.194)">
                <g id="Group_160371" data-name="Group 160371">
                  <path
                    id="SVGID-7"
                    data-name="SVGID"
                    d="M797.277,603.538c-.114,0-.193.158-.149.3.224.748.974,2.631,2.834,2.631s2.626-1.9,2.857-2.651c.044-.144-.035-.3-.15-.3Z"
                    transform="translate(-797.116 -603.517)"
                    fill="#558b57"
                  />
                  <g id="Group_160370" data-name="Group 160370" clipPath="url(#clip-path-4)">
                    <g id="Group_160369" data-name="Group 160369" transform="translate(0.792 1.459)">
                      <ellipse
                        id="Ellipse_738"
                        data-name="Ellipse 738"
                        cx="2.024"
                        cy="1.499"
                        rx="2.024"
                        ry="1.499"
                        fill="#dff7ca"
                      />
                    </g>
                  </g>
                </g>
              </g>
              <g id="Group_160383" data-name="Group 160383" transform="translate(10.839 4.822)">
                <g id="Group_160375" data-name="Group 160375">
                  <g id="Group_160373" data-name="Group 160373">
                    <path
                      id="Path_94281"
                      data-name="Path 94281"
                      d="M772.482,567.175a3.65,3.65,0,0,1,.486-1.9,2.948,2.948,0,0,1,1.579-1.389,2.719,2.719,0,0,1,.545-.1,2.574,2.574,0,0,1,.555.015,2.013,2.013,0,0,1,1.036.482,2.508,2.508,0,0,1,.779,1.864,4.742,4.742,0,0,0-1.307-1.032,1.529,1.529,0,0,0-.609-.115,2.021,2.021,0,0,0-.324.032,1.856,1.856,0,0,0-.319.07,3.5,3.5,0,0,0-1.188.8C773.308,566.26,772.93,566.7,772.482,567.175Z"
                      transform="translate(-772.48 -563.77)"
                      fill="#8dfc81"
                    />
                  </g>
                  <g id="Group_160374" data-name="Group 160374" transform="translate(11.455 0.001)">
                    <path
                      id="Path_94282"
                      data-name="Path 94282"
                      d="M831.491,567.441c-.425-.5-.775-.98-1.149-1.4a3.583,3.583,0,0,0-1.1-.93,1.14,1.14,0,0,0-.123-.046c-.054-.011-.11-.03-.161-.036a1.455,1.455,0,0,0-.3-.035,1.152,1.152,0,0,0-.559.145,5.946,5.946,0,0,0-1.233,1.167,2.714,2.714,0,0,1,.623-1.936,1.839,1.839,0,0,1,1.047-.573,2.2,2.2,0,0,1,.588-.009,2.566,2.566,0,0,1,.274.05,2.113,2.113,0,0,1,.3.1,3,3,0,0,1,1.445,1.561A3.988,3.988,0,0,1,831.491,567.441Z"
                      transform="translate(-826.863 -563.776)"
                      fill="#8dfc81"
                    />
                  </g>
                </g>
                <g id="Group_160382" data-name="Group 160382" transform="translate(1.237 3.745)">
                  <g id="Group_160378" data-name="Group 160378">
                    <rect
                      id="Rectangle_148728"
                      data-name="Rectangle 148728"
                      width="2.831"
                      height="5.584"
                      rx="1.416"
                      fill="#8dfc81"
                    />
                    <rect
                      id="Rectangle_148729"
                      data-name="Rectangle 148729"
                      width="1.447"
                      height="2.854"
                      rx="0.724"
                      transform="translate(0.692 1.786)"
                      fill="#276733"
                    />
                    <g id="Group_160377" data-name="Group 160377">
                      <g id="Group_160376" data-name="Group 160376" clipPath="url(#clip-path-5)">
                        <ellipse
                          id="Ellipse_739"
                          data-name="Ellipse 739"
                          cx="1.369"
                          cy="1.369"
                          rx="1.369"
                          ry="1.369"
                          transform="translate(-0.324 -0.135)"
                          fill="#fff"
                        />
                      </g>
                    </g>
                  </g>
                  <g id="Group_160381" data-name="Group 160381" transform="translate(10.789)">
                    <rect
                      id="Rectangle_148730"
                      data-name="Rectangle 148730"
                      width="2.831"
                      height="5.584"
                      rx="1.416"
                      fill="#8dfc81"
                    />
                    <rect
                      id="Rectangle_148731"
                      data-name="Rectangle 148731"
                      width="1.447"
                      height="2.854"
                      rx="0.724"
                      transform="translate(0.692 1.786)"
                      fill="#276733"
                    />
                    <g id="Group_160380" data-name="Group 160380">
                      <g id="Group_160379" data-name="Group 160379" clipPath="url(#clip-path-5)">
                        <ellipse
                          id="Ellipse_740"
                          data-name="Ellipse 740"
                          cx="1.369"
                          cy="1.369"
                          rx="1.369"
                          ry="1.369"
                          transform="translate(-0.324 -0.135)"
                          fill="#fff"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </Grid>
  );
};

CentorsFull.defaultProps = {
  height: "40px",
  width: "40px",
};

const LoneStars = (props) => (
  <Grid {...props}>
    <img height="100%" width="100%" src={RewardsIconYellow} alt="" />
  </Grid>
);

LoneStars.defaultProps = {
  height: "40apx",
  width: "38px",
};

const Usd = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="18" viewBox="0 0 30 18">
    <path
      id="Icon_awesome-money-bill-alt"
      data-name="Icon awesome-money-bill-alt"
      d="M16.5,15h-.75V10.875a.375.375,0,0,0-.375-.375h-.637a1.123,1.123,0,0,0-.624.189l-.719.479a.375.375,0,0,0-.1.52l.416.624a.375.375,0,0,0,.52.1l.022-.015V15H13.5a.375.375,0,0,0-.375.375v.75a.375.375,0,0,0,.375.375h3a.375.375,0,0,0,.375-.375v-.75A.375.375,0,0,0,16.5,15Zm12-10.5H1.5A1.5,1.5,0,0,0,0,6V21a1.5,1.5,0,0,0,1.5,1.5h27A1.5,1.5,0,0,0,30,21V6A1.5,1.5,0,0,0,28.5,4.5ZM2.25,20.25v-3a3,3,0,0,1,3,3Zm0-10.5v-3h3A3,3,0,0,1,2.25,9.75Zm12.75,9c-2.485,0-4.5-2.351-4.5-5.25S12.515,8.25,15,8.25s4.5,2.35,4.5,5.25S17.484,18.75,15,18.75Zm12.75,1.5h-3a3,3,0,0,1,3-3Zm0-10.5a3,3,0,0,1-3-3h3Z"
      transform="translate(0 -4.5)"
      fill="#4da17e"
    />
  </svg>
);

const PurchasedThrougCampaign = ({ mb }: { mb?: string }) => (
  <Grid container mb={mb}>
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="21" viewBox="0 0 11.732 13.165">
      <g id="purchased_throug_campaign" data-name="purchased throug campaign" transform="translate(-92.65 -13.44)">
        <path
          id="Path_94656"
          data-name="Path 94656"
          d="M120.677,19.061l-.415-2.935a.705.705,0,0,0-.706-.623h-.429a2.119,2.119,0,0,0-4.236,0h-.443a.705.705,0,0,0-.706.623l-.858,6.05a.722.722,0,0,0,.706.817h3.433a4.237,4.237,0,0,1,3.655-3.932Zm-3.682-4.748a1.273,1.273,0,0,1,1.26,1.2h-2.506a1.26,1.26,0,0,1,1.246-1.2Zm0,4.818a1.95,1.95,0,0,1-1.952-1.952.429.429,0,0,1,.858,0,1.094,1.094,0,0,0,2.187,0,.429.429,0,0,1,.858,0,1.941,1.941,0,0,1-1.952,1.952Z"
          transform="translate(-20.227)"
          fill="#0364ff"
        />
        <path
          id="Path_94657"
          data-name="Path 94657"
          d="M318.644,273.84a3.364,3.364,0,1,0,3.364,3.364A3.368,3.368,0,0,0,318.644,273.84Zm1.509,2.866-1.509,1.592a.445.445,0,0,1-.3.138.374.374,0,0,1-.3-.138l-.9-.955a.406.406,0,0,1,0-.6.435.435,0,0,1,.609.014l.6.623,1.2-1.274a.426.426,0,1,1,.609.6Z"
          transform="translate(-217.626 -253.963)"
          fill="#0364ff"
        />
      </g>
    </svg>
  </Grid>
);

const ClickIcons = ({ mt, ml }: { mt?: string; ml?: string }) => (
  <Grid container mt={mt} ml={ml}>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 12.51 16.567">
      <g id="Click_icons" data-name="Click icons" transform="translate(-2574.357 -16484.277)">
        <path
          id="Icon_awesome-mouse-pointer"
          data-name="Icon awesome-mouse-pointer"
          d="M5.81,6.328H3.77L4.844,8.942a.357.357,0,0,1-.182.461l-.945.412a.34.34,0,0,1-.449-.187L2.248,7.146.582,8.86A.34.34,0,0,1,0,8.613V.352A.34.34,0,0,1,.582.1L6.05,5.729A.352.352,0,0,1,5.81,6.328Z"
          transform="translate(2580.715 16491)"
          fill="#0364ff"
        />
        <path
          id="Path_94626"
          data-name="Path 94626"
          d="M1.094,2.094,5,0"
          transform="translate(2573.5 16491)"
          fill="none"
          stroke="#0364ff"
          strokeWidth="1"
        />
        <line
          id="Line_632"
          data-name="Line 632"
          y1="2"
          x2="4"
          transform="translate(2582.5 16487.5)"
          fill="none"
          stroke="#0364ff"
          strokeWidth="1"
        />
        <line
          id="Line_631"
          data-name="Line 631"
          x2="2"
          y2="4"
          transform="translate(2577.5 16484.5)"
          fill="none"
          stroke="#0364ff"
          strokeWidth="1"
        />
      </g>
    </svg>
  </Grid>
);

const EyeIcon = ({ mt, ml }: { mt?: string; ml?: string }) => (
  <Grid container mt={mt} ml={ml}>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 10.152 4.844">
      <g id="Eye_icon" data-name="Eye icon" transform="translate(-2532.95 -16494)">
        <path
          id="Icon_ionic-md-eye"
          data-name="Icon ionic-md-eye"
          d="M7.326,7.383A5.459,5.459,0,0,0,2.25,10.8a5.476,5.476,0,0,0,10.152,0A5.459,5.459,0,0,0,7.326,7.383Zm0,5.7A2.282,2.282,0,1,1,9.633,10.8,2.3,2.3,0,0,1,7.326,13.087Zm0-3.651A1.369,1.369,0,1,0,8.711,10.8,1.381,1.381,0,0,0,7.326,9.436Z"
          transform="translate(2530.7 16486.617)"
          fill="#0364ff"
        />
      </g>
    </svg>
  </Grid>
);

const MaterialEventSeat = ({ mt }: { mt?: string }) => (
  <Grid container mt={mt}>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 13.333 12">
      <path
        id="Icon_material-event-seat"
        data-name="Icon material-event-seat"
        d="M4.333,14.5v2h2v-2H13v2h2v-4H4.333Zm10-5.333h2v2h-2ZM3,9.167H5v2H3Zm10,2H6.333V5.833A1.337,1.337,0,0,1,7.667,4.5h4A1.337,1.337,0,0,1,13,5.833Z"
        transform="translate(-3 -4.5)"
        fill="#0364ff"
      />
    </svg>
  </Grid>
);

const Locked = (props) => (
  <Grid {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="10.071" height="11.75" viewBox="0 0 10.071 11.75">
      <path
        id="Locked_icon_"
        data-name="Locked icon "
        d="M5.036,0A3.367,3.367,0,0,0,1.679,3.357V5.036H0V11.75H10.071V5.036H8.393V3.357A3.367,3.367,0,0,0,5.036,0Zm0,1.679A1.662,1.662,0,0,1,6.714,3.357V5.036H3.357V3.357A1.662,1.662,0,0,1,5.036,1.679Z"
        fill="#d3202f"
      />
    </svg>
  </Grid>
);

const LowTrafficDays = (props) => (
  <Grid {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="35.537" height="29.857" viewBox="0 0 35.537 29.857">
      <g id="low-traffic-day-icon" transform="translate(0)">
        <path
          id="Path_94552"
          data-name="Path 94552"
          d="M169.573,304.369l-2.777-2.777-2.494,2.494-1.071-1.071,3.565-3.565,1.767,1.767,1.1-6.591-6.591,1.1,1.767,1.767L162.03,300.3l-1.071-1.071,1.736-1.736-2.777-2.777,11.585-1.931Z"
          transform="translate(-135.967 -292.783)"
          fill="#e9e9e9"
        />
        <path
          id="Ellipse_964"
          data-name="Ellipse 964"
          d="M9.6-1a10.6,10.6,0,0,1,10.6,10.6,10.6,10.6,0,0,1-10.6,10.6A10.6,10.6,0,0,1-1,9.6,10.6,10.6,0,0,1,9.6-1Zm0,19.691A9.088,9.088,0,1,0,.515,9.6,9.1,9.1,0,0,0,9.6,18.691Z"
          transform="translate(7.594 5.708)"
          fill="#e9e9e9"
        />
        <path
          id="Line_519"
          data-name="Line 519"
          d="M6.488,7.559-.707.364.364-.707,7.559,6.488Z"
          transform="translate(24.184 22.298)"
          fill="#e9e9e9"
        />
        <path
          id="Path_94553"
          data-name="Path 94553"
          d="M148.13,312.331a1.5,1.5,0,0,1-1.071-.443l-4.766-4.766,1.071-1.071,4.766,4.766,5.523-5.523,1.071,1.071-5.523,5.523A1.5,1.5,0,0,1,148.13,312.331Z"
          transform="translate(-131.69 -295.819)"
          fill="#e9e9e9"
        />
        <path
          id="Path_94554"
          data-name="Path 94554"
          d="M147.644,318.974a3.019,3.019,0,0,1-2.142-.886l-5.21-5.21,1.071-1.071,5.21,5.21a1.516,1.516,0,0,0,2.142,0l6.724-6.724,1.071,1.071-6.724,6.724A3.02,3.02,0,0,1,147.644,318.974Z"
          transform="translate(-131.205 -297.032)"
          fill="#e9e9e9"
        />
        <path
          id="Path_94555"
          data-name="Path 94555"
          d="M135.866,302.381l-2.18-2.18a1.516,1.516,0,0,0-2.142,0l-2.18,2.18-1.071-1.071,2.18-2.18a3.032,3.032,0,0,1,4.284,0l2.18,2.18Z"
          transform="translate(-128.293 -294.109)"
          fill="#e9e9e9"
        />
        <path
          id="Path_94556"
          data-name="Path 94556"
          d="M129.364,310.18l-1.071-1.071,3.251-3.251a1.515,1.515,0,0,1,2.142,0l.979.979-1.071,1.071-.979-.979Z"
          transform="translate(-128.293 -295.849)"
          fill="#e9e9e9"
        />
      </g>
    </svg>
  </Grid>
);

const NounAnalytics = (props) => (
  <Grid {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="16.576" height="16.577" viewBox="0 0 16.576 16.577">
      <g id="noun-analytics-icon" transform="translate(-0.15 0)">
        <path
          id="Path_94587"
          data-name="Path 94587"
          d="M87.688,31.243H84.847V25.992h2.841v5.251ZM98.89,17.7a1.61,1.61,0,1,0-.719-.746l-2.236,2.081a1.606,1.606,0,0,0-2.011.405l-1.6-.586c0-.013,0-.026,0-.038a1.608,1.608,0,1,0-3.057.7l-2.283,2.245a1.608,1.608,0,1,0,.727.739L90,20.255a1.6,1.6,0,0,0,1.972-.425l1.6.586c0,.013,0,.026,0,.038A1.608,1.608,0,1,0,96.64,19.8ZM86.267,23.77a.572.572,0,1,1,.572-.572A.573.573,0,0,1,86.267,23.77Zm4.453-4.379a.572.572,0,1,1,.572-.572A.573.573,0,0,1,90.72,19.391ZM99.627,15.7a.572.572,0,1,1-.573.572A.573.573,0,0,1,99.627,15.7Zm-4.454,5.324a.572.572,0,1,1,.573-.572A.573.573,0,0,1,95.173,21.027Zm1.421,10.216H93.753V23.249h2.841Zm-4.453,0H89.3v-9.13h2.841Zm8.906,0H98.206V19.538h2.841V31.243Z"
          transform="translate(-84.509 -14.666)"
          fill="#4262ff"
          fillRule="evenodd"
        />
      </g>
    </svg>
  </Grid>
);

const NounEdit = (props) => (
  <Grid {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="16.867" height="16.456" viewBox="0 0 16.867 16.456">
      <g id="noun-edit-icon" transform="translate(0 -0.001)">
        <path
          id="Path_94552"
          data-name="Path 94552"
          d="M134.843,56a1.241,1.241,0,0,0-.881.36l-7.1,7.1-.019.019a1.217,1.217,0,0,0-.315.514,4.96,4.96,0,0,0-.315,2.552.634.634,0,0,0,.5.5,4.607,4.607,0,0,0,1.851-.09,4.485,4.485,0,0,0,.694-.225,1.24,1.24,0,0,0,.514-.321l.019-.019,7.109-7.1a1.42,1.42,0,0,0-.116-2l-.823-.823a1.609,1.609,0,0,0-.983-.469c-.045,0-.09,0-.135,0Zm-.006,1.234s.1-.041.251.109c.274.277.552.55.829.823.15.15.1.255.109.251l-.411.411-1.183-1.189Zm-1.279,1.279,1.183,1.189-5.81,5.81c.013-.02-.062.045-.154.084a3.34,3.34,0,0,1-.5.161,3.956,3.956,0,0,1-.874.1,3.94,3.94,0,0,1,.1-.868,3.377,3.377,0,0,1,.167-.508c.03-.069.056-.112.058-.122Zm-11.107.366a2.066,2.066,0,0,0-2.057,2.057V70.4a2.066,2.066,0,0,0,2.057,2.057h9.461a2.066,2.066,0,0,0,2.057-2.057V64.435a.617.617,0,1,0-1.234,0V70.4a.808.808,0,0,1-.823.823h-9.461a.808.808,0,0,1-.823-.823V60.938a.808.808,0,0,1,.823-.823h6.787a.617.617,0,1,0,0-1.234Z"
          transform="translate(-120.394 -56)"
          fill="#4262ff"
        />
      </g>
    </svg>
  </Grid>
);

const Product = (props) => (
  <Grid {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="33.15" height="33.15" viewBox="0 0 33.15 33.15">
      <g id="product-icon" transform="translate(-85.098 -17.506)">
        <path
          id="Path_94605"
          data-name="Path 94605"
          d="M99.1,126.932a4.4,4.4,0,0,0-1.247-3.07,3.307,3.307,0,0,1,3.567.7l.773-.789h0a4.4,4.4,0,0,0-5.3-.663,4.412,4.412,0,0,0-6.629,3.82v2.21h-2.21a.553.553,0,0,0-.553.552v18.785a.552.552,0,0,0,.553.553h11.6v-1.1h-6.63v-17.68h1.658v2.21h1.1v-2.21h3.868v-1.1H99.1Zm-8.38,17.926a.574.574,0,0,0-.92,0l-1.2,1.8V131.517l1.2,1.8a.552.552,0,0,0,.92,0l1.2-1.8v15.139Zm.718,3.069H89.084l1.178-1.768Zm-1.178-15.912-1.178-1.768H91.44Zm1.1-2.873v-2.21a3.311,3.311,0,0,1,4.562-3.07,4.4,4.4,0,0,0-1.247,3.07v2.21Zm4.42-2.21a3.3,3.3,0,0,1,1.105-2.464,3.3,3.3,0,0,1,1.1,2.464v2.21h-2.21Z"
          transform="translate(-2.401 -98.376)"
          fill="#e9e9e9"
        />
        <path
          id="Path_94606"
          data-name="Path 94606"
          d="M301.86,24.935a.552.552,0,0,0,.055-.247V20.82h2.21v3.868a.552.552,0,0,0,.055.247l3.315,6.63.989-.494-3.254-6.513v-6.5a.552.552,0,0,0-.552-.552h-3.315a.553.553,0,0,0-.552.552v6.5l-3.257,6.514a.553.553,0,0,0-.058.247V50.1a.552.552,0,0,0,.553.553h3.868v-1.1H298.6v-18.1Zm2.265-6.324v1.105h-2.21V18.61Z"
          transform="translate(-199.137)"
          fill="#e9e9e9"
        />
        <path
          id="Path_94607"
          data-name="Path 94607"
          d="M399.291,260.1l-2.132-3.731V254.3a.552.552,0,0,0-.552-.552h-8.84a.553.553,0,0,0-.552.552v2.064l-2.137,3.738a.548.548,0,0,0-.073.276v11.05a.552.552,0,0,0,.553.553h13.26a.552.552,0,0,0,.552-.553V260.38a.547.547,0,0,0-.078-.283Zm-1.427-.27h-2.516l1.258-2.2Zm-9.545-4.973h7.735v1.1h-7.735Zm-.232,2.21h7.57l-1.58,2.763h-7.57Zm5.757,13.813h-7.735v-9.945h7.735Zm4.42,0h-3.315v-9.945h3.315Z"
          transform="translate(-281.12 -221.327)"
          fill="#e9e9e9"
        />
      </g>
    </svg>
  </Grid>
);

const SpecialEvent = (props) => (
  <Grid {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="26.979" height="25.928" viewBox="0 0 26.979 25.928">
      <g id="special-event-icon" transform="translate(-84 -24.642)">
        <path
          id="Path_94682"
          data-name="Path 94682"
          d="M107.94,26.715h-3.947V25.579a.937.937,0,1,0-1.874,0v1.136H92.889V25.579a.937.937,0,0,0-1.874,0v1.136H87.039A3.042,3.042,0,0,0,84,29.754V47.531a3.042,3.042,0,0,0,3.039,3.039h20.9a3.042,3.042,0,0,0,3.039-3.039V29.725a3.036,3.036,0,0,0-3.039-3.01ZM109.1,47.5a1.146,1.146,0,0,1-1.164,1.136h-20.9A1.164,1.164,0,0,1,85.874,47.5V35.462h23.2V47.5Zm0-13.944H85.874V29.725a1.145,1.145,0,0,1,1.164-1.136h3.947v1.136a.937.937,0,0,0,1.874,0V28.589h9.229v1.136a.937.937,0,0,0,1.874,0V28.589h3.947a1.164,1.164,0,0,1,1.164,1.136v3.834Z"
          fill="#f1f4f9"
        />
        <path
          id="Path_94683"
          data-name="Path 94683"
          d="M270.332,294.246a.372.372,0,0,1,.114.369l-.341,1.931a.564.564,0,0,0,.823.6l1.7-.88a.485.485,0,0,1,.4,0l1.732.909a.564.564,0,0,0,.823-.6l-.312-1.9a.42.42,0,0,1,.113-.369l1.42-1.363a.565.565,0,0,0-.312-.965l-1.9-.284a.439.439,0,0,1-.312-.227l-.88-1.761a.569.569,0,0,0-1.022,0l-.852,1.732a.377.377,0,0,1-.312.227l-1.96.284a.565.565,0,0,0-.312.966Z"
          transform="translate(-175.398 -251.316)"
          fill="#f1f4f9"
        />
      </g>
    </svg>
  </Grid>
);

const Surprise = (props) => (
  <Grid {...props}>
    <svg
      id="Surprise-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="38.446"
      height="33.613"
      viewBox="0 0 38.446 33.613"
    >
      <g id="Group_160587" data-name="Group 160587" transform="translate(9.004 11.345)">
        <path
          id="Rectangle_148935"
          data-name="Rectangle 148935"
          d="M-5.605-6.076h19.5a.471.471,0,0,1,.471.471v4.04a.471.471,0,0,1-.471.471h-19.5a.471.471,0,0,1-.471-.471v-4.04A.471.471,0,0,1-5.605-6.076Zm19.026.942H-5.134v3.1H13.42Z"
          transform="translate(6.076 6.076)"
          fill="#e9e9e9"
        />
      </g>
      <g id="Group_160588" data-name="Group 160588" transform="translate(10.294 15.855)">
        <path
          id="Path_94552"
          data-name="Path 94552"
          d="M755.6,1766.663H738.687a.471.471,0,0,1-.471-.471v-10.651h.942v10.18h15.975v-10.18h.942v10.651A.471.471,0,0,1,755.6,1766.663Z"
          transform="translate(-738.216 -1755.541)"
          fill="#e9e9e9"
        />
      </g>
      <g id="Group_160589" data-name="Group 160589" transform="translate(18.765 6.113)">
        <path
          id="Path_94553"
          data-name="Path 94553"
          d="M848,1635.952v-.575l-.458-.11c1.055-4.379,3.614-5.68,5.657-5.438a2.5,2.5,0,0,1,2.467,2.112C855.67,1634.19,852.3,1635.952,848,1635.952Zm4.735-5.209c-1.5,0-3.254,1.116-4.149,4.256,3.7-.145,6.139-1.649,6.139-3.058,0-.473-.625-1.057-1.636-1.177A3,3,0,0,0,852.738,1630.743Z"
          transform="translate(-847.546 -1629.801)"
          fill="#e9e9e9"
        />
      </g>
      <g id="Group_160590" data-name="Group 160590" transform="translate(12.48 6.781)">
        <path
          id="Path_94554"
          data-name="Path 94554"
          d="M773.176,1643.913c-3.781,0-6.742-1.562-6.742-3.556a2.241,2.241,0,0,1,2.2-1.9c1.807-.215,4.068.93,5,4.786l-.458.11Zm-4.127-4.54a2.606,2.606,0,0,0-.3.018c-.821.1-1.373.578-1.373.966,0,1.164,2.11,2.453,5.209,2.6C771.807,1640.316,770.321,1639.373,769.049,1639.373Z"
          transform="translate(-766.434 -1638.431)"
          fill="#e9e9e9"
        />
      </g>
      <g id="Group_160591" data-name="Group 160591" transform="translate(18.752 0)">
        <path
          id="Line_519"
          data-name="Line 519"
          d="M-5.605-3.82a.471.471,0,0,1-.471-.471V-5.605a.471.471,0,0,1,.471-.471.471.471,0,0,1,.471.471v1.314A.471.471,0,0,1-5.605-3.82Z"
          transform="translate(6.076 6.076)"
          fill="#e9e9e9"
        />
      </g>
      <g id="Group_160592" data-name="Group 160592" transform="translate(4.653 8.168)">
        <path
          id="Line_520"
          data-name="Line 520"
          d="M-4.472-4.478a.469.469,0,0,1-.236-.063L-5.842-5.2a.471.471,0,0,1-.171-.643.471.471,0,0,1,.643-.171l1.134.657a.471.471,0,0,1,.171.643A.471.471,0,0,1-4.472-4.478Z"
          transform="translate(6.077 6.077)"
          fill="#e9e9e9"
        />
      </g>
      <g id="Group_160593" data-name="Group 160593" transform="translate(4.653 23.847)">
        <path
          id="Line_521"
          data-name="Line 521"
          d="M-5.606-4.478a.471.471,0,0,1-.408-.235.471.471,0,0,1,.171-.643l1.134-.657a.471.471,0,0,1,.643.171.471.471,0,0,1-.171.643l-1.134.657A.469.469,0,0,1-5.606-4.478Z"
          transform="translate(6.077 6.077)"
          fill="#e9e9e9"
        />
      </g>
      <g id="Group_160594" data-name="Group 160594" transform="translate(18.752 31.357)">
        <path
          id="Line_522"
          data-name="Line 522"
          d="M-5.605-3.82a.471.471,0,0,1-.471-.471V-5.605a.471.471,0,0,1,.471-.471.471.471,0,0,1,.471.471v1.314A.471.471,0,0,1-5.605-3.82Z"
          transform="translate(6.076 6.076)"
          fill="#e9e9e9"
        />
      </g>
      <g id="Group_160595" data-name="Group 160595" transform="translate(31.717 23.847)">
        <path
          id="Line_523"
          data-name="Line 523"
          d="M-4.472-4.478a.469.469,0,0,1-.236-.063L-5.842-5.2a.471.471,0,0,1-.171-.643.471.471,0,0,1,.643-.171l1.134.657a.471.471,0,0,1,.171.643A.471.471,0,0,1-4.472-4.478Z"
          transform="translate(6.077 6.077)"
          fill="#e9e9e9"
        />
      </g>
      <g id="Group_160596" data-name="Group 160596" transform="translate(31.717 8.168)">
        <path
          id="Line_524"
          data-name="Line 524"
          d="M-5.606-4.478a.471.471,0,0,1-.408-.235.471.471,0,0,1,.171-.643l1.134-.657a.471.471,0,0,1,.643.171.471.471,0,0,1-.171.643l-1.134.657A.469.469,0,0,1-5.606-4.478Z"
          transform="translate(6.077 6.077)"
          fill="#e9e9e9"
        />
      </g>
      <g id="Group_160597" data-name="Group 160597" transform="translate(9.376 0.086)">
        <path
          id="Line_525"
          data-name="Line 525"
          d="M-3.736-1.895a.471.471,0,0,1-.408-.236l-1.87-3.24a.471.471,0,0,1,.172-.643.471.471,0,0,1,.643.172l1.87,3.24a.471.471,0,0,1-.172.643A.469.469,0,0,1-3.736-1.895Z"
          transform="translate(6.077 6.077)"
          fill="#e9e9e9"
        />
      </g>
      <g id="Group_160598" data-name="Group 160598" transform="translate(0 16.336)">
        <path
          id="Line_526"
          data-name="Line 526"
          d="M-1.866-5.134H-5.605a.471.471,0,0,1-.471-.471.471.471,0,0,1,.471-.471h3.739a.471.471,0,0,1,.471.471A.471.471,0,0,1-1.866-5.134Z"
          transform="translate(6.076 6.076)"
          fill="#e9e9e9"
        />
      </g>
      <g id="Group_160599" data-name="Group 160599" transform="translate(9.376 29.345)">
        <path
          id="Line_527"
          data-name="Line 527"
          d="M-5.607-1.895a.469.469,0,0,1-.235-.063A.471.471,0,0,1-6.014-2.6l1.87-3.24A.471.471,0,0,1-3.5-6.014a.471.471,0,0,1,.172.643L-5.2-2.13A.471.471,0,0,1-5.607-1.895Z"
          transform="translate(6.077 6.077)"
          fill="#e9e9e9"
        />
      </g>
      <g id="Group_160600" data-name="Group 160600" transform="translate(26.258 29.345)">
        <path
          id="Line_528"
          data-name="Line 528"
          d="M-3.736-1.895a.471.471,0,0,1-.408-.236l-1.87-3.24a.471.471,0,0,1,.172-.643.471.471,0,0,1,.643.172l1.87,3.24a.471.471,0,0,1-.172.643A.469.469,0,0,1-3.736-1.895Z"
          transform="translate(6.077 6.077)"
          fill="#e9e9e9"
        />
      </g>
      <g id="Group_160601" data-name="Group 160601" transform="translate(33.765 16.336)">
        <path
          id="Line_529"
          data-name="Line 529"
          d="M-1.866-5.134H-5.605a.471.471,0,0,1-.471-.471.471.471,0,0,1,.471-.471h3.739a.471.471,0,0,1,.471.471A.471.471,0,0,1-1.866-5.134Z"
          transform="translate(6.076 6.076)"
          fill="#e9e9e9"
        />
      </g>
      <g id="Group_160602" data-name="Group 160602" transform="translate(26.258 0.086)">
        <path
          id="Line_530"
          data-name="Line 530"
          d="M-5.607-1.895a.469.469,0,0,1-.235-.063A.471.471,0,0,1-6.014-2.6l1.87-3.24A.471.471,0,0,1-3.5-6.014a.471.471,0,0,1,.172.643L-5.2-2.13A.471.471,0,0,1-5.607-1.895Z"
          transform="translate(6.077 6.077)"
          fill="#e9e9e9"
        />
      </g>
    </svg>
  </Grid>
);

const DoubleTriple = (props) => (
  <Grid {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="32.815" height="33.153" viewBox="0 0 32.815 33.153">
      <g id="double-tripple-icon" transform="translate(0 0)">
        <path
          id="Rectangle_148935"
          data-name="Rectangle 148935"
          d="M-5.581-6.076H14.773a.5.5,0,0,1,.495.495v3.7a.5.5,0,0,1-.495.495H-5.581a.5.5,0,0,1-.495-.495v-3.7A.5.5,0,0,1-5.581-6.076Zm19.859.99H-5.086V-2.38H14.278Z"
          transform="translate(6.076 25.13)"
          fill="#e9e9e9"
        />
        <path
          id="Path_94552"
          data-name="Path 94552"
          d="M147.522,1872.954h-17.8a.5.5,0,0,1-.495-.495v-9.414h.99v8.919h16.812v-7.283h.99v7.778A.5.5,0,0,1,147.522,1872.954Z"
          transform="translate(-127.95 -1839.801)"
          fill="#e9e9e9"
        />
        <g id="Group_160607" data-name="Group 160607" transform="translate(10.207 13.85)">
          <path
            id="Path_94553"
            data-name="Path 94553"
            d="M239.492,1753.817H238.9l.1-.58c.9-5.152,3.245-5.552,3.943-5.552a2.2,2.2,0,0,1,2.222,2.113C245.169,1752.014,242.622,1753.817,239.492,1753.817Zm3.455-5.142c-.974,0-2.2,1.1-2.851,4.128,2.3-.192,4.083-1.466,4.083-3A1.2,1.2,0,0,0,242.947,1748.675Z"
            transform="translate(-238.903 -1747.685)"
            fill="#e9e9e9"
          />
        </g>
        <g id="Group_160608" data-name="Group 160608" transform="translate(5.784 14.51)">
          <path
            id="Path_94554"
            data-name="Path 94554"
            d="M190.184,1761.263H189.6c-2.764,0-5.012-1.6-5.012-3.567a1.98,1.98,0,0,1,2-1.9c.621,0,2.708.353,3.5,4.892Zm-3.6-4.482a.992.992,0,0,0-1.011.915c0,1.265,1.51,2.352,3.415,2.546C188.426,1757.7,187.4,1756.781,186.583,1756.781Z"
            transform="translate(-184.583 -1755.792)"
            fill="#e9e9e9"
          />
        </g>
        <g id="Group_160609" data-name="Group 160609" transform="translate(24.193 0)">
          <path
            id="Path_94555"
            data-name="Path 94555"
            d="M411.227,1583.744h-.588l.1-.58c.9-5.152,3.245-5.552,3.943-5.552a2.2,2.2,0,0,1,2.222,2.112C416.9,1581.941,414.357,1583.744,411.227,1583.744Zm3.456-5.142c-.974,0-2.2,1.1-2.851,4.127,2.3-.192,4.083-1.466,4.083-3A1.2,1.2,0,0,0,414.682,1578.6Z"
            transform="translate(-410.638 -1577.612)"
            fill="#e9e9e9"
          />
        </g>
        <g id="Group_160610" data-name="Group 160610" transform="translate(19.769 0.66)">
          <path
            id="Path_94556"
            data-name="Path 94556"
            d="M361.919,1591.19h-.588c-2.764,0-5.012-1.6-5.012-3.567a1.981,1.981,0,0,1,2-1.905c.621,0,2.708.353,3.5,4.892Zm-3.6-4.482a.992.992,0,0,0-1.011.916c0,1.265,1.51,2.352,3.415,2.546C360.162,1587.631,359.134,1586.708,358.319,1586.708Z"
            transform="translate(-356.319 -1585.718)"
            fill="#e9e9e9"
          />
        </g>
        <path
          id="Path_94557"
          data-name="Path 94557"
          d="M239.515,1822.013h-.99v-14.425h.99Z"
          transform="translate(-228.348 -1788.86)"
          fill="#e9e9e9"
        />
        <path
          id="Rectangle_148936"
          data-name="Rectangle 148936"
          d="M-5.581-6.076H9.441a.5.5,0,0,1,.495.495V-.826a.5.5,0,0,1-.495.495H-5.581a.5.5,0,0,1-.495-.495V-5.581A.5.5,0,0,1-5.581-6.076Zm14.528.99H-5.086v3.765H8.946Z"
          transform="translate(22.879 11.233)"
          fill="#e9e9e9"
        />
        <path
          id="Line_519"
          data-name="Line 519"
          d="M-5.086,7.505h-.99V0h.99Z"
          transform="translate(24.156 10.407)"
          fill="#e9e9e9"
        />
        <path
          id="Path_94558"
          data-name="Path 94558"
          d="M354.121,1728.151h-12.47v-.99h11.975v-21.756h.99v22.251A.5.5,0,0,1,354.121,1728.151Z"
          transform="translate(-323.077 -1694.998)"
          fill="#e9e9e9"
        />
        <path
          id="Line_520"
          data-name="Line 520"
          d="M-5.086,21.843h-.99V-6.076h.99Z"
          transform="translate(30.391 11.31)"
          fill="#e9e9e9"
        />
      </g>
    </svg>
  </Grid>
);

const Holiday = (props) => (
  <Grid {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="35.667" height="32.497" viewBox="0 0 35.667 32.497">
      <g id="Holiday-icon" transform="translate(0.704 0.5)">
        <g id="g1725" transform="translate(0 7.736)">
          <path
            id="path1707"
            d="M1398,1410v9.394l3.868-4.421,3.868,4.421V1410"
            transform="translate(-1384.738 -1405.027)"
            fill="none"
            stroke="#e9e9e9"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="path1709"
            d="M1254,1356s6.078,4.973,17.13,4.973,17.13-4.973,17.13-4.973"
            transform="translate(-1254 -1356)"
            fill="none"
            stroke="#e9e9e9"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="path1717"
            d="M1256.886,1378.933l-2.886,8.939,5.039-3.018,2.323,5.4,2.886-8.939"
            transform="translate(-1254 -1376.821)"
            fill="none"
            stroke="#e9e9e9"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="path1719"
            d="M1522.087,1378.933l2.886,8.939-5.039-3.018-2.323,5.4-2.886-8.939"
            transform="translate(-1490.713 -1376.821)"
            fill="none"
            stroke="#e9e9e9"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
        <g id="g2183-5-3" transform="translate(26.523 23.208)">
          <path
            id="path2175-3-9"
            d="M1578,1524v1.658"
            transform="translate(-1574.685 -1524)"
            fill="none"
            stroke="#e9e9e9"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="path2177-9-8"
            d="M1578,1578v1.657"
            transform="translate(-1574.685 -1573.027)"
            fill="none"
            stroke="#e9e9e9"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="path2179-6-4"
            d="M1597.658,1560H1596"
            transform="translate(-1591.027 -1556.685)"
            fill="none"
            stroke="#e9e9e9"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="path2181-1-9"
            d="M1543.658,1560H1542"
            transform="translate(-1542 -1556.685)"
            fill="none"
            stroke="#e9e9e9"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
        <g id="g2066-9-8" transform="translate(14.367 27.076)">
          <path
            id="path2054-6-2"
            d="M1434,1566v1.105"
            transform="translate(-1431.79 -1566)"
            fill="none"
            stroke="#e9e9e9"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="path2056-0-3"
            d="M1434,1602v1.105"
            transform="translate(-1431.79 -1598.684)"
            fill="none"
            stroke="#e9e9e9"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="path2058-0-4"
            d="M1447.105,1590H1446"
            transform="translate(-1442.684 -1587.79)"
            fill="none"
            stroke="#e9e9e9"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="path2060-7-2"
            d="M1411.1,1590H1410"
            transform="translate(-1410 -1587.79)"
            fill="none"
            stroke="#e9e9e9"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
        <g id="g1787" transform="translate(13.814 2.21)">
          <path
            id="path1779"
            d="M1440,1296v1.658"
            transform="translate(-1436.685 -1296)"
            fill="none"
            stroke="#e9e9e9"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="path1781"
            d="M1440,1350v1.658"
            transform="translate(-1436.685 -1345.027)"
            fill="none"
            stroke="#e9e9e9"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="path1783"
            d="M1459.658,1332H1458"
            transform="translate(-1453.027 -1328.685)"
            fill="none"
            stroke="#e9e9e9"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="path1785"
            d="M1405.658,1332H1404"
            transform="translate(-1404 -1328.685)"
            fill="none"
            stroke="#e9e9e9"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
        <g id="g1798" transform="translate(27.076 0.552)">
          <path
            id="path1789"
            d="M1572,1278v1.105"
            transform="translate(-1569.79 -1278)"
            fill="none"
            stroke="#e9e9e9"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="path1791"
            d="M1572,1314v1.105"
            transform="translate(-1569.79 -1310.684)"
            fill="none"
            stroke="#e9e9e9"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="path1794"
            d="M1585.105,1302H1584"
            transform="translate(-1580.685 -1299.79)"
            fill="none"
            stroke="#e9e9e9"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="path1796"
            d="M1549.105,1302H1548"
            transform="translate(-1548 -1299.79)"
            fill="none"
            stroke="#e9e9e9"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
        <g id="g1808" transform="translate(3.868)">
          <path
            id="path1800"
            d="M1320,1272v1.105"
            transform="translate(-1317.79 -1272)"
            fill="none"
            stroke="#e9e9e9"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="path1802"
            d="M1320,1308v1.105"
            transform="translate(-1317.79 -1304.685)"
            fill="none"
            stroke="#e9e9e9"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="path1804"
            d="M1333.105,1296H1332"
            transform="translate(-1328.685 -1293.79)"
            fill="none"
            stroke="#e9e9e9"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="path1806"
            d="M1297.105,1296H1296"
            transform="translate(-1296 -1293.79)"
            fill="none"
            stroke="#e9e9e9"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
        <g id="g1818" transform="translate(0.552 22.103)">
          <path
            id="path1810"
            d="M1296,1512v1.658"
            transform="translate(-1292.685 -1512)"
            fill="none"
            stroke="#e9e9e9"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="path1812"
            d="M1296,1566v1.658"
            transform="translate(-1292.685 -1561.027)"
            fill="none"
            stroke="#e9e9e9"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="path1814"
            d="M1315.657,1548H1314"
            transform="translate(-1309.027 -1544.685)"
            fill="none"
            stroke="#e9e9e9"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="path1816"
            d="M1261.658,1548H1260"
            transform="translate(-1260 -1544.685)"
            fill="none"
            stroke="#e9e9e9"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  </Grid>
);

const MetroFilePicture = (props) => (
  <Grid {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="17.349" height="19.827" viewBox="0 0 17.349 19.827">
      <path
        id="Icon_metro-file-picture"
        data-name="Icon metro-file-picture"
        d="M19.369,19.277H6.977V16.8l3.718-6.2,5.092,6.2,3.582-2.478Zm0-8.055A1.859,1.859,0,1,1,17.51,9.363,1.859,1.859,0,0,1,19.369,11.222ZM21.03,6.363a17.557,17.557,0,0,0-1.688-1.931,17.561,17.561,0,0,0-1.931-1.688,3.088,3.088,0,0,0-1.761-.817h-9.6A1.551,1.551,0,0,0,4.5,3.477V20.206a1.551,1.551,0,0,0,1.549,1.549H20.3a1.551,1.551,0,0,0,1.549-1.549V8.124a3.088,3.088,0,0,0-.817-1.761ZM18.466,5.309a16.9,16.9,0,0,1,1.405,1.576H16.891V3.9a16.883,16.883,0,0,1,1.576,1.405Zm2.142,14.9a.314.314,0,0,1-.31.31H6.048a.314.314,0,0,1-.31-.31V3.477a.314.314,0,0,1,.31-.31h9.6V7.5a.62.62,0,0,0,.62.62h4.337Z"
        transform="translate(-4.499 -1.928)"
        fill="#8986ad"
        opacity="0.5"
      />
    </svg>
  </Grid>
);

const ReceiptTrigger = (props) => (
  <Grid {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="31.729" height="42.311" viewBox="0 0 31.729 42.311">
      <path
        id="Receipt-trigger-icon"
        d="M171.181.222a1.3,1.3,0,0,0-1.19-.18l-7.472,2.491L156.358.068a1.311,1.311,0,0,0-.981,0l-6.161,2.464L141.742.042a1.3,1.3,0,0,0-1.19.18A1.326,1.326,0,0,0,140,1.3V40.957a1.311,1.311,0,0,0,.55,1.071,1.339,1.339,0,0,0,1.193.182l7.472-2.491,6.163,2.464a1.287,1.287,0,0,0,.981,0l6.161-2.464,7.472,2.491a1.322,1.322,0,0,0,1.737-1.253V1.3a1.319,1.319,0,0,0-.55-1.074ZM146.612,7.905h10.576a1.322,1.322,0,1,1,0,2.644H146.612a1.322,1.322,0,0,1,0-2.644Zm7.932,21.153h-7.932a1.322,1.322,0,0,1,0-2.644h7.932a1.322,1.322,0,0,1,0,2.644Zm0-7.932h-7.932a1.322,1.322,0,0,1,0-2.644h7.932a1.322,1.322,0,0,1,0,2.644ZM163.8,31.5v1.526a1.322,1.322,0,1,1-2.644,0v-1.5a4.259,4.259,0,0,1-1.185-.542,1.323,1.323,0,0,1,1.47-2.2,1.874,1.874,0,0,0,1.036.28c.809,0,1.322-.434,1.322-.661s-.513-.661-1.322-.661c-2.187,0-3.966-1.484-3.966-3.305a3.4,3.4,0,0,1,2.644-3.1V19.8a1.322,1.322,0,1,1,2.644,0v1.5a4.228,4.228,0,0,1,1.185.542,1.322,1.322,0,1,1-1.47,2.2,1.86,1.86,0,0,0-1.036-.277c-.809,0-1.322.434-1.322.661s.513.661,1.322.661c2.187,0,3.966,1.484,3.966,3.305a3.4,3.4,0,0,1-2.644,3.1Zm1.322-15.661H146.612a1.322,1.322,0,0,1,0-2.644h18.509a1.322,1.322,0,0,1,0,2.644Z"
        transform="translate(-140.002 0.029)"
        fill="#34305d"
      />
    </svg>
  </Grid>
);

const Cashier = (props) => (
  <Grid {...props} container>
    <img height="100%" width="100%" src={CashierSrc} alt="jackpot icon" />
  </Grid>
);

Cashier.defaultProps = {
  width: "100%",
};

const HappyGirls = () => (
  <Grid>
    <img height="100px" width="100%" src={HappyGirlsSrc} alt="happy girls" />
  </Grid>
);

const Power = () => (
  <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="PowerSettingsNewIcon">
    <path d="M13 3h-2v10h2V3zm4.83 2.17-1.42 1.42C17.99 7.86 19 9.81 19 12c0 3.87-3.13 7-7 7s-7-3.13-7-7c0-2.19 1.01-4.14 2.58-5.42L6.17 5.17C4.23 6.82 3 9.26 3 12c0 4.97 4.03 9 9 9s9-4.03 9-9c0-2.74-1.23-5.18-3.17-6.83z" />
  </svg>
);

export const OpenIcon = ({ fill = "currentColor" }: { fill?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14.628" height="14.628" viewBox="0 0 14.628 14.628">
    <path
      id="Icon_ionic-md-open"
      data-name="Icon ionic-md-open"
      d="M17.5,17.5H6.125V6.125H11.2V4.5H6.125A1.63,1.63,0,0,0,4.5,6.125V17.5a1.63,1.63,0,0,0,1.625,1.625H17.5A1.63,1.63,0,0,0,19.128,17.5V12.423H17.5Zm-4.47-13V6.125h3.332L8.157,14.333l1.138,1.138L17.5,7.263v3.332h1.625V4.5Z"
      transform="translate(-4.5 -4.5)"
      fill={fill}
    />
  </svg>
);

export const ExclamationTriangle = ({ fill = "currentColor" }: { fill?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30.5" height="27.111" viewBox="0 0 30.5 27.111">
    <path
      id="Icon_awesome-exclamation-triangle"
      data-name="Icon awesome-exclamation-triangle"
      d="M30.157,23.3a2.543,2.543,0,0,1-2.2,3.812H2.545A2.543,2.543,0,0,1,.343,23.3L13.048,1.27a2.543,2.543,0,0,1,4.4,0ZM15.25,18.745a2.436,2.436,0,1,0,2.436,2.436A2.436,2.436,0,0,0,15.25,18.745ZM12.937,9.99l.393,7.2a.635.635,0,0,0,.634.6h2.571a.635.635,0,0,0,.634-.6l.393-7.2a.635.635,0,0,0-.634-.67H13.572a.635.635,0,0,0-.634.67Z"
      transform="translate(0)"
      fill={fill}
    />
  </svg>
);

export const MailIcon = ({ stroke = "#8986ad" }: { stroke?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="26.535" height="21" viewBox="0 0 26.535 21">
    <g id="Icon_feather-mail" data-name="Icon feather-mail" transform="translate(-1.607 -5)">
      <path
        id="Path_94550"
        data-name="Path 94550"
        d="M5.375,6h19A2.382,2.382,0,0,1,26.75,8.375v14.25A2.382,2.382,0,0,1,24.375,25h-19A2.382,2.382,0,0,1,3,22.625V8.375A2.382,2.382,0,0,1,5.375,6Z"
        fill="none"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        id="Path_94551"
        data-name="Path 94551"
        d="M26.75,9,14.875,17.313,3,9"
        transform="translate(0 -0.625)"
        fill="none"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </svg>
);

export const PhoneIcon = ({ stroke = "#8986ad" }: { stroke?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20.919" height="20.956" viewBox="0 0 20.919 20.956">
    <path
      id="Icon_feather-phone-call"
      data-name="Icon feather-phone-call"
      d="M14.821,5.1A4.5,4.5,0,0,1,18.379,8.66M14.821,1.5a8.106,8.106,0,0,1,7.16,7.151m-.9,7.187v2.7a1.8,1.8,0,0,1-1.963,1.8,17.824,17.824,0,0,1-7.773-2.765,17.563,17.563,0,0,1-5.4-5.4A17.824,17.824,0,0,1,3.175,4.364,1.8,1.8,0,0,1,4.967,2.4h2.7a1.8,1.8,0,0,1,1.8,1.549,11.565,11.565,0,0,0,.63,2.531,1.8,1.8,0,0,1-.405,1.9L8.552,9.525a14.411,14.411,0,0,0,5.4,5.4L15.1,13.785A1.8,1.8,0,0,1,17,13.38a11.565,11.565,0,0,0,2.531.63A1.8,1.8,0,0,1,21.081,15.839Z"
      transform="translate(-2.167 -0.396)"
      fill="none"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

const CentorsSad = (props) => (
  <Grid {...props}>
    <img width="100%" src={sadTykCharacter} alt="sad centors" />
  </Grid>
);

export default {
  Recycle,
  CreditCardIcon,
  Jackpot,
  Centors,
  CentorsFull,
  LoneStars,
  Usd,
  PurchasedThrougCampaign,
  ClickIcons,
  EyeIcon,
  MaterialEventSeat,
  Locked,
  LowTrafficDays,
  NounAnalytics,
  NounEdit,
  Product,
  SpecialEvent,
  Surprise,
  DoubleTriple,
  Holiday,
  MetroFilePicture,
  ReceiptTrigger,
  Cashier,
  HappyGirls,
  Power,
  OpenIcon,
  ExclamationTriangle,
  MailIcon,
  PhoneIcon,
  CentorsSad,
};
