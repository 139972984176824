import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    footerWrap: {
      display: "flex",
      flexDirection: "column",
      alignSelf: "start",
      width: "100%",
      fontSize: "12px",

      [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
        fontSize: "9px",
      },

      [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
        fontSize: "7px",
      },
      "& .row": {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        marginTop: "10px",

        "& .decor": {
          position: "relative",
          height: "8px",
          width: "8px",
          marginRight: "5px",
          border: `1px solid ${theme.palette.diagram?.grey}`,
          borderRadius: "50px",
          "&::before": {
            content: '""',
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "3px",
            width: "3px",
            background: theme.palette.diagram?.grey,
            borderRadius: "50px",
          },
        },

        "& span": {
          fontWeight: "bold",
          marginRight: "5px",
        },
      },
    },
  };
});

export default useStyles;
