import { FC, useState, useEffect, useRef } from "react";
import { FormikProps } from "formik";
import { useTheme } from "@mui/styles";
import { useQuery } from "@apollo/client";
import { useIntl } from "react-intl";
import Icon from "../../../../../../utils/icon";
import { ITykchaseGlobalConfig } from "../../../../../../types/tykchaseGlobalConfig";

import { GET_ACTIVE_CAMPAIGNS } from "../../../../../../apollo/queries";
import { TykchaseCampaign, TykchaseCampaignTypes } from "../../../../../../generated/types";
import { CampaignSteps, StepsFormikValues } from "../../../../../../types/campaigns";
import CampaingLayout from "../Layout/CampaingLayout";

type Props = {
  active: boolean;
  handlerModal: () => void;
  tykchaseGlobalConfig?: ITykchaseGlobalConfig;
};

const DoubleTriple: FC<Props> = ({ active, handlerModal }: Props) => {
  const theme = useTheme();
  const [activeCampaigns, setActiveCampaigns] = useState<TykchaseCampaign[]>([]);
  const informationRef = useRef<FormikProps<StepsFormikValues>>(null);
  const triggerRef = useRef<FormikProps<StepsFormikValues>>(null);
  const { formatMessage } = useIntl();
  const { data: activeCampaignsData, loading: isLoadingActiveCampaignsData } = useQuery(GET_ACTIVE_CAMPAIGNS, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (activeCampaignsData) {
      setActiveCampaigns(activeCampaignsData.getActiveCampaigns);
    }
  }, [activeCampaignsData]);

  return (
    <CampaingLayout
      active={active}
      handlerModal={handlerModal}
      informationRef={informationRef}
      triggerRef={triggerRef}
      title={formatMessage({ id: "rewards.campaigns.double_triple.title" })}
      subTitle={formatMessage({ id: "rewards.campaigns.double_triple.sub_title" })}
      mainBgColor={theme.palette.campaigns.yellow}
      secondaryBgColor={theme.palette.campaigns.darkYellow}
      Icon={Icon.DoubleTriple}
      paginationTitles={[
        formatMessage({ id: "rewards.campaigns.pagination_titles.information" }),
        formatMessage({ id: "rewards.campaigns.pagination_titles.campaign_selections" }),
      ]}
      informationStepKey={`${TykchaseCampaignTypes.DoubleTriple}-${CampaignSteps.Information}`}
      triggerType={false}
      campaignType={TykchaseCampaignTypes.DoubleTriple}
      rewardStepKey={`${TykchaseCampaignTypes.DoubleTriple}-${CampaignSteps.Reward}`}
      triggerStepKey={`${TykchaseCampaignTypes.DoubleTriple}-${CampaignSteps.Trigger}`}
      summaryStepKey={`${TykchaseCampaignTypes.DoubleTriple}-${CampaignSteps.Summary}`}
      activeCampaigns={activeCampaigns}
      isLoadingActiveCampaignsData={isLoadingActiveCampaignsData}
    />
  );
};

export default DoubleTriple;
