import { FC, useEffect, useState } from "react";
import { FormikProps } from "formik";
import { useLazyQuery } from "@apollo/client";
import { useTheme } from "@mui/styles";
import {
  Alert,
  Box,
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import useStyles from "../../styles";
import AnimatedLoader from "../../../../../../AnimatedLoader";
import CardCampaign from "../../../../CardCampaign";
import { GET_CAMPAIGN } from "../../../../../../../apollo/queries";
import { TykchaseCampaign } from "../../../../../../../generated/types";
import { StepsFormikValues } from "../../../../../../../types/campaigns";

type Props = {
  formik: FormikProps<StepsFormikValues>;
  activeCampaigns?: TykchaseCampaign[];
};

const MultiplierTrigger: FC<Props> = ({ formik, activeCampaigns }: Props) => {
  const theme = useTheme();
  const { wrap, fieldWrapper } = useStyles();
  const isActiveCampaignsEmpty = activeCampaigns?.length === 0;

  const [selectedCampaign, setSelectedCampaign] = useState<TykchaseCampaign>(
    formik.values.boostedTykchaseCampaign as unknown as TykchaseCampaign,
  );
  const [loadSelectedCampaign, { loading: isLoadingSelectedCampaign }] = useLazyQuery(GET_CAMPAIGN, {
    onCompleted: (dataQuery) => {
      setSelectedCampaign(dataQuery.getCampaign.tykchaseCampaignPayload);
    },
    fetchPolicy: "no-cache",
  });
  const selectedActiveCampaignHandler = async ({ target: { value } }: SelectChangeEvent) => {
    await loadSelectedCampaign({
      variables: {
        id: value,
      },
    });
    await formik.setFieldValue("boostedTykchaseCampaignId", value);
  };

  useEffect(() => {
    formik.setFieldValue("boostedTykchaseCampaign", selectedCampaign);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCampaign]);

  return (
    <Grid className={wrap} container flexDirection={{ xs: "column", md: "row" }}>
      <Grid item width={{ xs: "100%", md: "60%" }}>
        <Grid container className={fieldWrapper}>
          <Typography fontSize="0.75rem" color={theme.palette.label?.grey} mb="-5px">
            <FormattedMessage id="modals.triggers.active_campaign" />
          </Typography>

          <FormControl style={{ width: "100%" }}>
            <Select
              displayEmpty
              style={{ width: "100%" }}
              value={formik.values.boostedTykchaseCampaignId}
              onChange={selectedActiveCampaignHandler}
              className="selectedActiveCampaign"
              name="boostedTykchaseCampaignId"
            >
              <MenuItem disabled value="">
                <em>
                  <FormattedMessage id="modals.triggers.select_active_campaign" />
                </em>
              </MenuItem>

              {activeCampaigns &&
                activeCampaigns.map((campaign) => (
                  <MenuItem key={campaign.id} value={campaign.id}>
                    {campaign.title}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          {!isActiveCampaignsEmpty &&
            formik.touched.boostedTykchaseCampaignId &&
            formik.errors.boostedTykchaseCampaignId && (
              <Box width="100%" marginTop="6px">
                <Alert severity="error">{formik.errors.boostedTykchaseCampaignId}</Alert>
              </Box>
            )}

          {isActiveCampaignsEmpty && (
            <Box width="100%" marginTop="6px">
              <Alert severity="error">
                <FormattedMessage id="modals.triggers.you_dont_have_any_campaigns" />
              </Alert>
            </Box>
          )}
        </Grid>

        {formik.values.boostedTykchaseCampaign && (
          <Grid>
            {isLoadingSelectedCampaign ? (
              <AnimatedLoader width={80} height={80} />
            ) : (
              <>
                <Typography fontSize="0.75rem" color={theme.palette.label?.grey} mt="40px" mb="5px">
                  <FormattedMessage id="modals.triggers.selected_campaign" />
                </Typography>

                <Grid
                  container
                  flexWrap="nowrap"
                  p="15px 11px"
                  boxShadow={`0px 3px 6px ${theme.palette.campaigns.shadowGrey}`}
                  borderRadius="6px"
                >
                  <CardCampaign
                    cardPreview
                    campaignData={formik.values.boostedTykchaseCampaign as unknown as TykchaseCampaign}
                  />
                </Grid>
              </>
            )}
          </Grid>
        )}
      </Grid>

      {!isActiveCampaignsEmpty && (
        <Grid item textAlign={{ xs: "center", md: "start" }} pl="30px" className={fieldWrapper}>
          <Typography mt={{ xs: "10px", md: "0px" }} fontSize="0.75rem" color={theme.palette.label?.grey} mb="5px">
            <FormattedMessage id="modals.triggers.multiplier" />
          </Typography>

          <Box display="flex" flexDirection="row">
            <OutlinedInput
              onChange={formik.handleChange}
              value={formik.values.multiplierNumber}
              type="number"
              inputProps={{ min: 0 }}
              className="doubleTrigger"
              name="multiplierNumber"
            />

            {formik.touched.multiplierNumber && formik.errors.multiplierNumber && (
              <Box width="120px" marginLeft="6px">
                <Alert sx={{ height: "100%" }} severity="error">
                  {formik.errors.multiplierNumber}
                </Alert>
              </Box>
            )}
          </Box>

          <Grid container item justifyContent={{ xs: "center", md: "start" }}>
            <Typography
              maxWidth={{ xs: "150px" }}
              color={theme.palette.diagram?.grey}
              fontSize="0.75rem"
              textAlign={{ xs: "center", md: "start" }}
            >
              <FormattedMessage id="modals.triggers.multiply_by_this" />
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default MultiplierTrigger;
