import { ComponentType, ComponentPropsWithRef } from "react";
import TabsTechnology from "../TabsTechnology";

const withTabsTechnologyLayout = <T extends {}>(Component: ComponentType<T>) => {
  const wrappedWithTabsTechnologyLayout = (props: ComponentPropsWithRef<any>) => (
    <>
      <TabsTechnology />
      <Component {...props} />
    </>
  );

  wrappedWithTabsTechnologyLayout.displayName = "wrappedWithTabsTechnologyLayout";

  return wrappedWithTabsTechnologyLayout;
};

export default withTabsTechnologyLayout;
