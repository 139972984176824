import { graphql } from "../../../gql";

export const getUploadUrlMutation = graphql(`
  mutation GetUploadUrl($filename: String!, $extension: String!) {
    getUploadUrl(filename: $filename, extension: $extension) {
      payload {
        filename
        pathname
        uploadUrl
        downloadUrl
      }
    }
  }
`);
