import { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { useQuery } from "@apollo/client";
import { FormattedMessage } from "react-intl";
import { ReactComponent as ExclamationIcon } from "../../../assets/icons/tykIcons/exclamation.svg";
import { GET_IROHA_ACCOUNT_ASSETS } from "../../../apollo/queries";
import useStyles from "./styles";
import { DepositBox } from "../DepositBox";
import { ASSET_ID } from "./constants";
import { Query } from "../../../generated/types";
import { useBusinessProfile } from "../../../hooks/useBusinessProfile.hook";

export const CurrentBalance = () => {
  const { tooltipIcon, tooltipText } = useStyles();

  const [businessProfile] = useBusinessProfile();

  const getIrohaAccountAssets = useQuery<Pick<Query, "getIrohaAccountAssets">>(GET_IROHA_ACCOUNT_ASSETS, {
    variables: { userId: businessProfile?.id },
    fetchPolicy: "no-cache",
  });

  const amount = useMemo(() => {
    const irohaAccountAsset = getIrohaAccountAssets?.data?.getIrohaAccountAssets?.payload?.find(
      (asset) => asset?.assetId === ASSET_ID,
    );

    return irohaAccountAsset?.balance ? parseFloat(irohaAccountAsset.balance) : 0;
  }, [getIrohaAccountAssets]);

  return (
    <DepositBox
      title={<FormattedMessage id="your_rewards_current_balance" defaultMessage="Current balance" />}
      amount={amount}
      isDataLoading={getIrohaAccountAssets.loading}
      tooltipContent={
        <Box display="flex" alignItems="center" p={1}>
          <ExclamationIcon className={tooltipIcon} />
          <Typography className={tooltipText}>
            <FormattedMessage
              id="your_rewards_current_balance_tooltip"
              defaultMessage="Current balance refers to the amount available of your Tyk Deposit."
            />
          </Typography>
        </Box>
      }
    />
  );
};
