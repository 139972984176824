import { useMemo } from "react";
import { GetCartQuery } from "../../../gql/graphql";
import { CartProps } from "../../Cart";

enum TykchaseFee {
  TykchaseSetUpFee = "TykChase Setup Fee",
  TykRewardDepositFee = "Minimum Tyk Reward Deposit Fee",
}
type Props = {
  cart: GetCartQuery["getCart"];
  enrolledModal?: boolean;
};

export const useLineItems = (cart: GetCartQuery["getCart"]): CartProps["lineItems"] | null =>
  useMemo(() => {
    if (!cart) {
      return null;
    }

    return cart.lineItems.map((lineItem) => ({
      title: lineItem?.label ?? "",
      priceInCents: lineItem?.priceInCents ?? 0,
      taxInCents: 0,
    }));
  }, [cart]);
