import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Box, Typography, Button } from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import { FormattedMessage } from "react-intl";
import { useStyles } from "./styles";
import { ReactComponent as TrashIcon } from "../../../../assets/icons/technology/trash.svg";
import { DELETE_QR_CODE, UPDATE_QR_CODE } from "../../../../apollo/mutations";
import { GET_QR_CODE } from "../../../../apollo/queries";
import QrForm from "../QrForm";
import { ITtykchaseCampaign, QrType } from "../../types";

const QrEdit = () => {
  const { qrEditWrap } = useStyles();
  const params = useParams<{ id: string }>();

  const history = useHistory();

  const [name, setName] = useState("");
  const [trigger, setTrigger] = useState<ITtykchaseCampaign | null>(null);
  const [qrType, setQrType] = useState<QrType | null>(null);

  const [updateQrCode, { loading: qrUpdateLoading }] = useMutation(UPDATE_QR_CODE);
  const [deleteQrCode, { loading: qrDeleteLoading }] = useMutation(DELETE_QR_CODE);

  const [formIsDirty, setFormIsDirty] = useState(false);

  useQuery(GET_QR_CODE, {
    variables: { id: params.id },
    onCompleted: (qrData) => {
      const qr = qrData.getQrCode.qrCodePayload;
      setName(qr.name);
      setTrigger(qr.tykchaseCampaign);
    },
    fetchPolicy: "no-cache",
  });

  const handleSubmit = (isValid: boolean) => {
    if (!isValid) {
      setFormIsDirty(true);
      return;
    }

    updateQrCode({
      variables: {
        id: params.id,
        name,
        qrType,
        tykchaseCampaignId: (trigger as ITtykchaseCampaign).id,
      },
      onCompleted: () => {
        history.push("/Technology");
      },
    });
  };

  const removeQrCode = (id: string) => {
    deleteQrCode({
      variables: {
        id,
      },
      onCompleted: () => {
        history.push("/Technology");
      },
    });
  };

  return (
    <Box className={qrEditWrap}>
      <Box className="buttonArea">
        <Button
          onClick={() => removeQrCode(params.id)}
          variant="contained"
          startIcon={<TrashIcon />}
          disabled={qrUpdateLoading || qrDeleteLoading}
          className="remove-btn"
        >
          <Typography component="span">
            <FormattedMessage id="technology.qr_codes.remove" />
          </Typography>
        </Button>
      </Box>

      <Box className="mainContent">
        <QrForm
          name={name}
          setName={setName}
          trigger={trigger}
          setTrigger={setTrigger}
          setQrType={setQrType}
          onSubmit={handleSubmit}
          isDirty={formIsDirty}
          isLoading={qrUpdateLoading || qrDeleteLoading}
        />
      </Box>
    </Box>
  );
};

export default QrEdit;
