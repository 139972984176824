import { FC } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { Tabs, Tab } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { Routes } from "../../../constants";
import useStyles from "./styles";

const AnalyticsTabs: FC = () => {
  const { tabsContainer } = useStyles();
  const routeMatch = useRouteMatch();
  const currentTab = routeMatch?.path;
  return (
    <Tabs value={currentTab} className={tabsContainer}>
      <Tab
        label={<FormattedMessage id="analytics.analytics_tabs.general" />}
        value={Routes.ANALYTICS}
        to={Routes.ANALYTICS}
        component={Link}
      />
      <Tab
        label={<FormattedMessage id="analytics.analytics_tabs.psychographics" />}
        value={`${Routes.ANALYTICS}${Routes.PSYCHOGRAPHICS}`}
        to={`${Routes.ANALYTICS}${Routes.PSYCHOGRAPHICS}`}
        component={Link}
      />
      <Tab
        label={<FormattedMessage id="analytics.analytics_tabs.demographics" />}
        value={`${Routes.ANALYTICS}${Routes.DEMOGRAPHICS}`}
        to={`${Routes.ANALYTICS}${Routes.DEMOGRAPHICS}`}
        component={Link}
      />
    </Tabs>
  );
};

export default AnalyticsTabs;
