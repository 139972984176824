import { Box, Typography, FormHelperText } from "@mui/material";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import theme from "../../../theme";

const FormHelperTextBox = ({ isError, errorText }: { isError: boolean; errorText: string }) => (
  <Box height="24px">
    {isError && (
      <FormHelperText
        error
        sx={{
          display: "flex",
          alignItems: "center",
          marginTop: 0,
          paddingTop: "3px",
        }}
      >
        <WarningRoundedIcon
          sx={{
            height: "11px",
            width: "12px",
            marginRight: "4px",
          }}
        />
        <Typography
          component="span"
          fontSize="0.75rem"
          pt="2px"
          sx={{
            "&.MuiTypography-root": {
              color: theme.palette.technology.redError,
            },
          }}
        >
          {errorText}
        </Typography>
      </FormHelperText>
    )}
  </Box>
);

export default FormHelperTextBox;
