import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import useStyles from "./styles";
import TableRow from "./TableRow";

const Table = () => {
  const { wrap } = useStyles();
  const theme = useTheme();

  return (
    <Grid className={wrap} minHeight="301px" width="100%">
      <Grid className="rowWrap head">
        <Typography
          borderBottom={{
            xs: `1px solid ${theme.palette.analytics?.grey} !important`,
            md: "0 !important",
          }}
          width="30px"
        />
        <Typography>Id</Typography>
        <Typography>Promoter</Typography>
        <Typography>Location</Typography>
        <Typography>Accumulated in location</Typography>
        <Typography>Automated actions</Typography>
        <Typography>Actions</Typography>
      </Grid>
      <TableRow />
    </Grid>
  );
};

export default Table;
