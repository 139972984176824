import { useEffect, MouseEvent } from "react";
import {
  Box,
  Typography,
  Stack,
  Chip,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableFooter,
  TablePagination,
} from "@mui/material";
import RadioButtonCheckedRoundedIcon from "@mui/icons-material/RadioButtonCheckedRounded";
import dateFormat from "dateformat";
import theme from "../../../../theme";
import { ReactComponent as WarningIcon } from "../../../../assets/icons/technology/exclamation-triangle.svg";
import { ReactComponent as NoImgIcon } from "../../../../assets/icons/technology/metro-file-picture.svg";
import FormHelperTextBox from "../../FormHelperTextBox";
import useStyles from "./styles";
import { usePagination } from "./hooks";
import { useFeatureFlagValue } from "../../../../hooks";
import { ICategory, ITab, ITtykchaseCampaign, QrType, CategoryName } from "../../types";
import { FeatureFlagEnum } from "../../../../types/contexts";

interface ICategoryTabs {
  list: ITab[];
  selected: QrType;
  onSelect: (val: QrType) => void;
}

interface ITriggersTable {
  title?: string;
  list: ITtykchaseCampaign[];
  rowsPerPage?: number;
  trigger: ITtykchaseCampaign | null;
  onTriggerChange: (val: ITtykchaseCampaign) => void;
}

interface ITriggers {
  category: ICategory;
  onCategoryChange: (val: QrType) => void;
  tabs: ITab[];
  trigger: ITtykchaseCampaign | null;
  onTriggerChange: (val: ITtykchaseCampaign) => void;
  formIsDirty: boolean;
}

const CategoryTabs = ({ list, selected, onSelect }: ICategoryTabs) => {
  const isExperiencesTabOfQRCodeV2Enabled = useFeatureFlagValue(FeatureFlagEnum.EXPERIENCES_TAB_OF_QR_CODE_V2_ENABLED);

  const handleClick = (elem) => {
    if (elem.type === selected) return;
    onSelect(elem.type);
  };

  return (
    <Stack direction="row" spacing="12px" overflow="auto">
      {list.map((elem) => {
        const isSelected = selected === elem.type;
        return (
          <Chip
            onClick={() => handleClick(elem)}
            className={isSelected ? "selected" : ""}
            label={elem.title}
            key={elem.title}
            disabled={elem.title === CategoryName.Experiences && !isExperiencesTabOfQRCodeV2Enabled}
          />
        );
      })}
    </Stack>
  );
};

const NoActiveExperiences = ({ title = "" }: { title?: string }) => (
  <Stack
    direction="row"
    spacing="12px"
    sx={{
      display: "flex",
      alignItems: "end",
      justifyContent: "center",
      maxWidth: "300px",
      margin: "auto",
      backgroundColor: theme.palette.technology.lightOrange,
      padding: `${theme.spacing(4)} ${theme.spacing(5)}`,
    }}
  >
    <WarningIcon />
    <Typography component="span" fontSize="0.75rem" color={theme.palette.technology.grey}>
      {`No active ${title} were found`}
    </Typography>
  </Stack>
);

const TriggersTable = ({ title, list = [], rowsPerPage = 4, trigger, onTriggerChange }: ITriggersTable) => {
  const ROW_HEIGHT = 45;

  const { rows, emptyRows, page, setPage } = usePagination({ list, rowsPerPage });

  useEffect(() => {
    if (!trigger) {
      setPage(0);
      return;
    }

    const rowIndex = list.findIndex((val) => val.id === trigger.id);
    if (rowIndex === -1) {
      setPage(0);
      return;
    }
    const startFromPage = Math.floor(rowIndex / rowsPerPage);
    setPage(startFromPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, trigger, rowsPerPage]);

  const handleChangePage = (_event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };
  const THead = () => (
    <TableHead>
      <TableRow>
        <TableCell align="left" colSpan={2}>
          <Typography component="span">{title}</Typography>
        </TableCell>
        <TableCell align="right" colSpan={2}>
          <Typography component="span">End date</Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );

  const TBody = () => (
    <TableBody>
      {rows.map((row) => (
        <TableRow
          onClick={() => {
            onTriggerChange(row);
          }}
          hover
          selected={row.id === trigger?.id}
          key={row.id}
        >
          <TableCell padding="checkbox">
            <Checkbox
              checked={row.id === trigger?.id}
              icon={
                <RadioButtonCheckedRoundedIcon className="checkbox" htmlColor={theme.palette.technology.lightGrey4} />
              }
              checkedIcon={
                <RadioButtonCheckedRoundedIcon className="checkbox" htmlColor={theme.palette.technology.lightBlue} />
              }
            />
          </TableCell>
          <TableCell>
            <Typography component="span">{row.title}</Typography>
          </TableCell>
          <TableCell align="right">
            <Typography component="span">{dateFormat(row.endDate, "dd.mm.yyyy")}</Typography>
          </TableCell>
        </TableRow>
      ))}
      {rows.length > 0 && emptyRows > 0 && (
        <TableRow
          sx={{
            "&.MuiTableRow-root": {
              height: ROW_HEIGHT * emptyRows,
              border: "none",
            },
          }}
        >
          <TableCell colSpan={6} sx={{ border: "none" }} />
        </TableRow>
      )}
      {rows.length === 0 && (
        <TableRow>
          <TableCell
            align="center"
            colSpan={3}
            sx={{
              "&.MuiTableCell-root": {
                paddingTop: "50px",
                border: "none",
              },
            }}
          >
            <NoActiveExperiences title={title} />
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );

  const TFooter = () => (
    <TableFooter sx={{ height: ROW_HEIGHT }}>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={[]}
          colSpan={3}
          count={list.length}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage={null}
          onPageChange={handleChangePage}
          sx={{
            "&.MuiTableCell-root": {
              height: ROW_HEIGHT,
              border: "none",
            },
            "& .MuiTablePagination-root": {
              height: ROW_HEIGHT,
            },
            "& .MuiTablePagination-toolbar": {
              minHeight: ROW_HEIGHT - 1,
              color: theme.palette.technology.grey2,
            },
          }}
        />
      </TableRow>
    </TableFooter>
  );

  return (
    <Table>
      <THead />
      <TBody />
      {list.length > rowsPerPage && <TFooter />}
    </Table>
  );
};

const Preview = ({ src, visibilityHidden }: { src?: string; visibilityHidden: boolean }) => {
  const image = src ? <img src={src} alt="experience-img" className="experienceImg" /> : <NoImgIcon />;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        visibility: `${visibilityHidden ? "hidden" : "unset"}`,
      }}
    >
      <Typography component="span" mb="7px">
        Preview
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="157px"
        height="151px"
        border={`1px solid ${theme.palette.technology.lightGrey4}`}
      >
        {image}
      </Box>
    </Box>
  );
};

const Triggers = ({ category, onCategoryChange, tabs, trigger, onTriggerChange, formIsDirty }: ITriggers) => {
  const { experiencesWrap } = useStyles();

  const getCategoryTitle = () => {
    const currentCategory = tabs.find((elem) => elem.type === category?.type);
    return currentCategory?.title;
  };

  return (
    <Box className={experiencesWrap}>
      <Box mb={1}>
        <CategoryTabs list={tabs} selected={category?.type} onSelect={onCategoryChange} />
        <FormHelperTextBox
          isError={formIsDirty && !category?.triggers.find((elem) => elem.id === trigger?.id)}
          errorText="Please select a trigger for your QR code"
        />
      </Box>
      <Stack direction="row" spacing="27px" color={theme.palette.technology.grey2}>
        <Box width="100%">
          <TriggersTable
            title={getCategoryTitle()}
            list={category?.triggers}
            trigger={trigger}
            onTriggerChange={onTriggerChange}
          />
        </Box>
        <Box pt={1} display={{ xs: "none", md: "block" }}>
          <Preview src={trigger?.defaultCoverPhoto} visibilityHidden={category.triggers?.length === 0} />
        </Box>
      </Stack>
    </Box>
  );
};

export default Triggers;
