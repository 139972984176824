import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    wrap: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "0.75rem",
    },

    wrapIcon: {
      justifyContent: "center",
      alignItems: "center",
      height: "1.56rem",
      width: "1.56rem",
      marginRight: "0.625rem",
      color: "#FF983B",
    },

    tooltipInfoIcon: {
      width: "50px",
      height: "50px",
      "& path": {
        fill: "#FFFFFF",
      },
    },

    tooltipInfoText: {
      fontSize: "0.75rem",
      paddingLeft: theme.spacing(1),
    },
  };
});

export default useStyles;
