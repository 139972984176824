import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useState } from "react";
import Icon from "../../../utils/icon";
import AddNewPromoter from "./modals/AddNewPromoter";

const Sidebar = () => {
  const theme = useTheme();
  const [modalActive, setModalActive] = useState(false);
  const handlerModal = () => {
    setModalActive(!modalActive);
  };
  return (
    <Grid borderRadius="6px" overflow="hidden" bgcolor={theme.palette.ruleToggle.lightBlue}>
      <Grid p="20px 32px" borderRadius="6px" bgcolor={theme.palette.ruleToggle.blue}>
        <Grid container flexWrap="nowrap" justifyContent="space-between" mb="32px">
          <Grid maxWidth="max-content" mr="14px">
            <Icon.Cashier maxWidth="73px" minWidth="41px" />
          </Grid>
          <Grid maxWidth="132px" width="100%">
            <Grid container flexWrap="nowrap" alignItems="end">
              <Icon.CentorsFull />
              <Typography
                height="max-content"
                ml="15px"
                fontSize="0.875rem"
                color={theme.palette.primary.dark}
                fontWeight="bold"
              >
                2,000
              </Typography>
            </Grid>
            <Grid container flexWrap="nowrap" alignItems="end" mb="10px">
              <Icon.LoneStars />
              <Typography
                height="max-content"
                ml="15px"
                fontSize="0.875rem"
                color={theme.palette.primary.dark}
                fontWeight="bold"
              >
                550
              </Typography>
            </Grid>
            <Typography fontSize="0.875rem" color={theme.palette.diagram?.purple}>
              accumulated
            </Typography>
          </Grid>
        </Grid>
        <Typography mb="17px" fontSize="0.625rem" color={theme.palette.analytics?.grey}>
          * Promoters must be registered with Tyket account user
        </Typography>
        <Typography
          onClick={() => {
            handlerModal();
          }}
          className="underline"
          textAlign="end"
          fontSize="0.875rem"
          color={theme.palette.blue}
        >
          Add promoter
        </Typography>
      </Grid>
      <AddNewPromoter modalActive={modalActive} handlerModal={handlerModal} />
    </Grid>
  );
};

export default Sidebar;
