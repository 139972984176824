import { graphql } from "../../../gql";

export const uploadReceiptMutation = graphql(`
  mutation UploadReceipt($url: String!, $tykchaseLocationId: String!) {
    uploadReceipt(url: $url, tykchaseLocationId: $tykchaseLocationId) {
      status
      message
      errorMessageId
      payload {
        id
        tykchaseLocationId
        storeTitle
        storeAddressNormalized
        receiptUrl
        createdAt
      }
    }
  }
`);
