import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  experiencesWrap: {
    "& .MuiTableHead-root": {
      height: "45px",
    },
    "& .MuiTableRow-root": {
      height: "45px",
    },
    "& .MuiTableCell-root": {
      padding: `0 ${theme.spacing(1)}`,
    },
    "& .MuiTypography-root": {
      color: theme.palette.technology.grey2,
    },
    "& .selected": {
      backgroundColor: theme.palette.technology.darkPurple,
      color: theme.palette.technology.white,
      "&:hover": {
        backgroundColor: theme.palette.technology.darkPurple,
        color: theme.palette.technology.white,
      },
    },
    "& .checkbox": {
      width: "14px",
      height: "14px",
    },
    "& .experienceImg": {
      maxWidth: "100%",
      height: "inherit",
      objectFit: "cover",
    },
  },
}));

export default useStyles;
