import { StyleSheet } from "@react-pdf/renderer";

export const receiptDocumentStyles = StyleSheet.create({
  font: { fontFamily: "AdobeClean" },
  transactionId: {
    fontSize: 13,
    color: "#505050",
    fontWeight: "bold",
  },
  transactionCreatedAt: {
    fontSize: 13,
    color: "#797882",
    alignSelf: "flex-end",
  },
  billingInformation: {
    fontSize: 11,
    color: "#3E3D43",
    fontWeight: "bold",
    paddingBottom: 7,
  },
  billingAddress: {
    fontSize: 9,
    color: "#878787",
    paddingBottom: 3,
  },
  regularFont: {
    fontFamily: "AdobeClean",
    fontSize: 13,
    color: "#505050",
  },
  billingText: {
    fontSize: 9,
    color: "#161C2E",
  },
  topLevelWrapper: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  topGraySection: {
    backgroundColor: "#E9E9E9",
    width: "100vw",
    height: "18vh",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tykChaseLogoLeft: {
    height: 46,
    paddingLeft: 32,
  },
  documentHeadingSectionRight: {
    flexDirection: "column",
    justifyContent: "space-between",
    paddingRight: 50,
  },
  topRightTransactionTitle: {
    fontSize: 18,
    color: "#505050",
    alignSelf: "flex-end",
  },

  topRightAccountEmailLine: {
    flexDirection: "row",
    alignSelf: "flex-end",
  },
  topRightInvoiceNumberLine: {
    flexDirection: "row",
    alignSelf: "flex-end",
  },
  contentSection: {
    width: "100%",
    height: "82vh",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  orderDetailsAndTableSection: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    paddingTop: 55,
    paddingBottom: 15,
    paddingLeft: 60,
    paddingRight: 60,
  },
  orderDetailsHeadingSection: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 5,
  },
  billingEndOfPageSection: {
    width: "100%",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingBottom: 40,
  },
  billingSection: {
    width: "54%",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    paddingBottom: 25,
  },
  billingLeftAndRightSideWrapper: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  billingLeftSideAddressSection: {
    width: "34%",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  billingRightSidePaymentSection: {
    width: "34%",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-end",
  },
  billingRightSideCardSection: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  endOfPageBottomLineSection: {
    width: "100%",
    paddingLeft: 60,
    paddingRight: 60,
  },
  endOfPageBottomLine: {
    height: 0.5,
    backgroundColor: "#797882",
    width: "100%",
  },
  centeredRowLayout: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  textMediumLightGray: {
    fontSize: 11,
    color: "#797882",
  },
  textMediumBoldLightGray: {
    fontSize: 11,
    fontWeight: "bold",
    color: "#797882",
  },
  textLargeBoldDarkGray: {
    color: "#161C2E",
    fontSize: 13,
    fontWeight: "bold",
  },
});
