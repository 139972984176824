import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    wrap: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      padding: "16px 24px 8px",
      borderRadius: "6px",
      border: "1px solid #CDCCD7",

      [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
        padding: "8px 12px 4px",
      },

      "& .cardHeader": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "start",
        marginBottom: "16px",
        "& .cardHeaderTitle": {
          fontSize: "1rem",
          color: theme.palette.primary.dark,
        },
        "& .cardHeaderSubtitle": {
          fontSize: "0.75rem",
          color: "#797882",
        },
        "& .cardHeaderSubtitleCount": {
          marginLeft: "4px",
          fontSize: "1rem",
          fontWeight: 600,
          color: "#8986AD",
        },

        "& .experiences": {
          color: "#827164",
        },

        "& .active": {
          color: "#0364FF",
        },

        "& .MuiButtonBase-root": {
          padding: 0,
        },
      },

      "& .cardDivider": {
        backgroundColor: "#CDCCD7",
      },

      "& .cardBody": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        margin: "8px 0",

        [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
          margin: "4px 0",
        },
      },
    },
  };
});

export default useStyles;
