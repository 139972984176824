import { makeStyles } from "@mui/styles";

const drawerWidth = 300;

const headerStyle = {
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    backgroundColor: "#FAFAFA",
    boxShadow:
      " 0px 2px 1px -1px rgb(238 238 238 / 60%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);",
    "@media (max-width: 600px)": {
      width: "100%",
      paddingLeft: "30px",
    },
  },
  toolbarHeight: {
    minHeight: 99,
    width: "100%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    paddingBottom: "1rem",

    "@media all and (max-width: 600px)": {
      alignItems: "center",
    },
  },
  leftBox: {
    display: "flex",
    alignItems: "center",
    color: "#34305D",
    minWidth: "140px",
    width: "20%",
    height: "40px",

    "& .MuiIconButton-label": {
      color: "#000",
    },
    "@media all and (max-width: 800px)": {
      minWidth: "80px",
    },
  },
  rightBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    color: "#8986AD",
    width: "80%",
    height: "40px",
    gap: "2rem",
    "@media all and (min-width: 800px)": {
      gap: "4rem",
    },
    "@media all and (max-width: 350px)": {
      gap: 0,
    },
  },
  buttonBox: {
    display: "flex",
    alignItems: "center",
    color: "#8986AD",
    justifyContent: "flex-end",
    gap: "2rem",
    "@media all and (max-width: 600px)": {
      gap: 1,
    },
  },
  planLabel: {
    fontSize: "0.875rem",
    fontWeight: 600,
    color: "#4B4B4B",
    "@media all and (max-width: 800px)": {
      display: "none",
    },
  },
};

const useStyles = makeStyles((theme) => {
  return {
    ...headerStyle,
  };
});

export { useStyles, headerStyle };
