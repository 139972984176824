import { FC } from "react";
import { ButtonBase, FormControl, Grid, TextField } from "@mui/material";
import useStyles from "./styles";
import { Winner } from "../types";

interface ChatsTableProps {
  chatsData: Winner;
}

const Chat: FC<ChatsTableProps> = ({ chatsData }) => {
  const { chatContainer } = useStyles();

  return (
    <Grid className={chatContainer}>
      <div className="title-container">
        <div className="avatar-image" />
        <h2>{chatsData.name}</h2>
      </div>

      <div className="chat-container">
        {chatsData.messages?.length ? (
          chatsData.messages.map((chat) => {
            return (
              <div key={chat.id} className={`message-container ${chat.senderId === "self" ? "self" : "winner"}`}>
                <div className="message-date">{chat.date}</div>
                <div className="message-text">
                  <p>{chat.text}</p>
                </div>
              </div>
            );
          })
        ) : (
          <p className="empty-message">No messages yet</p>
        )}
      </div>

      <div className="new-message-container">
        <FormControl>
          <TextField
            variant="outlined"
            placeholder="Type your message here, 80 characters maximun"
            inputProps={{ maxLength: 80 }}
          />
        </FormControl>

        <ButtonBase>Send</ButtonBase>
      </div>
    </Grid>
  );
};

export default Chat;
