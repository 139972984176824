import { Grid, Typography } from "@mui/material";
import { ReactChild } from "react";
import { useTheme } from "@mui/styles";

const Section = ({
  title,
  subtitle,
  children,
}: {
  title?: string;
  subtitle?: string;
  children?: ReactChild;
}) => {
  const theme = useTheme();

  return (
    <Grid marginBottom="50px">
      <Typography mb="16px" color={theme.palette.analytics?.black}>
        {title}
      </Typography>

      {subtitle && (
        <Typography mb="38px" color={theme.palette.experiences.grey} fontSize="0.875rem">
          {subtitle}
        </Typography>
      )}

      {children}
    </Grid>
  );
};

export default Section;
