import { makeStyles } from "@mui/styles";

const membershipStyle = {
  headingContainer: {
    "&": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      color: "#6E6E6E",
      textAlign: "center" as "center",
      padding: "2rem 0",
    },

    "& .title-area": {

      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "90%",

    },
  },
  fontDisplay: {
    display: "inline-block",
    fontWeight: 600,
  },
  fontTitle:{
    fontWeight:18,
  },
    myMembershipContainer: {
    "&": {
      position: "absolute",
      right: 0,
      top: "-8px",
      display: "flex",
      alignItems: "center",
      color: "#0863FB",
      "&:hover": {
        background: "transparent",
        textDecoration: "underline",
      },
      "@media(max-width: 1200px)": {
        position: "static",
      },

      "& .MuiTypography-root": {
        fontSize: "0.875rem",
      },
    },

    "& .icon": {
      width: "13px",
      height: "13px",
      marginRight: "6px",
      "& > *": {
        fill: "#0863FB",
      },
    },
  },
  buttonContainer: {
    borderBottom: "1px solid #EAEAEA",
    margin: "0 1rem 4rem 1rem",
    width: "60%",
    display: "flex",
    justifyContent: "space-around",

    ".MuiGrid-spacing-xs-2 > &.MuiGrid-item": {
      padding: 0,
    },
    "& button": {
      height: "100%",
      borderRadius: 0,
      textTransform: "none",
    },
  },
  isActiveButton: {
    color: "#4262FF",
    borderBottom: "2px solid #4262FF",
  },
  isInactiveButton: {
    borderBottom: "2px solid #FAFAFA",
  },
  selectedPlan: {
    padding: "0.2rem",
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: "#1B959A",
    },
  },
  disabledCard: {
    padding: "0.2rem",
    borderRadius: "5px",
  },
};

const useStyles = makeStyles(() => {
  return { ...membershipStyle };
});

export { useStyles, membershipStyle };
