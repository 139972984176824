import { parse, stringify } from "qs";
import { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";

const TRUE_STRING = "true";

export const useModalRouteState = (paramName: string, additionalParams: string[] = []) => {
  const { pathname, search } = useLocation();
  const history = useHistory();
  const queryParams = parse(search, { ignoreQueryPrefix: true });

  const isVisible = queryParams[paramName] === TRUE_STRING;

  const openModal = useCallback(() => {
    history.replace(
      `${pathname}${stringify(
        {
          ...queryParams,
          [paramName]: true,
        },
        { addQueryPrefix: true },
      )}`,
    );
  }, [pathname, queryParams, paramName, history]);

  const closeModal = useCallback(() => {
    const newParams = { ...queryParams };

    [...additionalParams, paramName].forEach((param) => {
      delete newParams[param];
    });

    history.replace(`${pathname}${stringify(newParams, { addQueryPrefix: true })}`);
  }, [pathname, queryParams, paramName, history, additionalParams]);

  return { isVisible, openModal, closeModal };
};
