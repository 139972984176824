import { useCallback, useState } from "react";
import { CardInputsMessageStatus, CardMessageStatus } from "../CardForm.types";
import { CardFormValidationHookReturnType } from "../interfaces/card-form-validation.interface";

export function useCardFormValidation(statuses?: CardInputsMessageStatus): CardFormValidationHookReturnType {
  const [cardInputsMessageStatus, setCardInputsMessageStatus] = useState<CardInputsMessageStatus>(
    statuses ?? {
      cardCardholderMessageStatus: null,
      cardNumberMessageStatus: null,
      cardExpiryMessageStatus: null,
      cardCvcMessageStatus: null,
    },
  );
  const allFieldsComplete = Object.values(cardInputsMessageStatus).every(Boolean);

  const emptyNonNullValues = useCallback(() => {
    setCardInputsMessageStatus({
      cardCardholderMessageStatus: cardInputsMessageStatus.cardCardholderMessageStatus || CardMessageStatus.Empty,
      cardNumberMessageStatus: cardInputsMessageStatus.cardNumberMessageStatus || CardMessageStatus.Empty,
      cardExpiryMessageStatus: cardInputsMessageStatus.cardExpiryMessageStatus || CardMessageStatus.Empty,
      cardCvcMessageStatus: cardInputsMessageStatus.cardCvcMessageStatus || CardMessageStatus.Empty,
    });
  }, [
    cardInputsMessageStatus.cardCardholderMessageStatus,
    cardInputsMessageStatus.cardNumberMessageStatus,
    cardInputsMessageStatus.cardExpiryMessageStatus,
    cardInputsMessageStatus.cardCvcMessageStatus,
  ]);

  return {
    allFieldsComplete,
    emptyNonNullValues,
    cardInputsMessageStatus,
    setCardInputsMessageStatus,
  };
}
