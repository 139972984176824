import { FC, Fragment } from "react";
import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import clsx from "clsx";

type Props = {
  titles: Array<string>;
  currentStep: number;
};

const Pagination: FC<Props> = ({ titles, currentStep }: Props) => {
  const theme = useTheme();

  return (
    <Grid
      container
      flexWrap="nowrap"
      justifyContent={{ xs: "center", md: "start" }}
      pl={{ xs: "0", md: "138px" }}
      pb="10px"
    >
      {titles.map((title, index) => (
        <Fragment key={`step number ${index + 1}`}>
          <Grid
            container
            flexDirection="column"
            alignItems="center"
            width="60px"
            className={clsx("paginationItem", {
              active: index === currentStep,
              past: index < currentStep,
            })}
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              height="35px"
              width="35px"
              mb="4px"
              border={`1px solid ${theme.palette.campaigns.dark}`}
              borderRadius="100px"
            >
              <Grid
                className="innerCircle"
                container
                justifyContent="center"
                alignItems="center"
                height="25px"
                width="25px"
                borderRadius="100px"
              >
                <Typography color={theme.palette.purple} fontSize="0.75rem">
                  {index + 1}
                </Typography>
              </Grid>
            </Grid>

            <Typography className="title" fontSize="0.75rem" textAlign="center" color={theme.palette.purple}>
              {title}
            </Typography>
          </Grid>
          {index + 1 === titles.length || (
            <Grid
              className={clsx("paginationDecor", {
                past: index < currentStep,
              })}
              height="0"
              width={{ xs: "50px", md: "123px" }}
              mt="15px"
              border={`1px solid ${theme.palette.diagram?.grey}`}
            />
          )}
        </Fragment>
      ))}
    </Grid>
  );
};

export default Pagination;
