import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    wrap: {
      padding: "16px 24px",

      [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
        padding: "8px 12px",
      },

      "& .sectionHeaderWrapper": {
        width: "100%",
        marginBottom: "20px",
        paddingBottom: "16px",
        borderBottom: "1px solid #CDCCD7",

        [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
          paddingBottom: "10px",
          marginBottom: "10px",
        },
      },

      "& .sectionItemWrapper": {
        marginBottom: "18px",

        "&:last-child": {
          marginBottom: "0",
        },

        "& .title": {
          marginBottom: "2px",
          fontFamily: theme.typography.fontFamily,
          fontSize: "1rem",
          fontWeight: 600,
          color: theme.palette.primary.dark,
          lineHeight: "1",
        },

        "& .title.inactive": {
          color: theme.palette.analytics?.purple,
        },

        "& .text": {
          fontFamily: theme.typography.fontFamily,
          fontSize: "0.75rem",
          color: theme.palette.analytics?.grey,
        },
      },
    },
  };
});

export default useStyles;
