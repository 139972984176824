import { graphql } from "../../../../gql";

export const getMembershipDepositSettingsQuery = graphql(`
  query GetMembershipDepositSettings($userId: String!) {
    user(where: { id: $userId }) {
      tykchaseConfig {
        membership {
          mandatoryDepositAmount
        }
      }
    }
  }
`);
