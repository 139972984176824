import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    wrap: {
      // Styles for general information for all campaigns
      "& .generalInformation": {
        "& .MuiFormControl-root": {
          [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
            width: "100%",
          },
          marginTop: "2px",
          "& .MuiInputLabel-root": {
            top: "0px !important",
          },
        },

        "& .MuiGrid-root .labelCoverTemplate": {
          height: "110px",
          width: "86px",
        },

        "& .MuiOutlinedInput-root": {
          height: "33px",
          width: "240px",
          margin: "6px 0",
          [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
            width: "100%",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${theme.palette.diagram?.grey} !important`,
          },
        },

        "& .Mui-focused": {
          border: `1px solid ${theme.palette.inputs.active}`,
        },
      },

      // Styles for Holidays Campaigns Trigger
      "& .holidaysTrigger": {
        "&::before, &::after": {
          content: "none",
        },

        "& .MuiInput-input": {
          width: "179px",
          fontSize: "2.5rem",
          textAlign: "end",
          paddingTop: "40px",
          paddingBottom: "20px",
          paddingRight: "18px",
          border: `1px solid ${theme.palette.diagram?.grey}`,
          fontWeight: "bold",
        },
      },

      // Styles for Low Traffic Day Campaigns Trigger
      "& .lowTrafficDayTrigger": {
        [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
          flexDirection: "column",
        },

        "& .MuiToggleButton-root": {
          marginRight: "14px",
          border: `1px solid ${theme.palette.diagram?.grey} !important`,
          width: "64px",
          height: "61px",
          color: theme.palette.text.primary,
          borderRadius: "5px !important",
          [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
            marginRight: "0px",
            marginBottom: "14px",
          },
        },
      },

      // Styles for Product Campaigns Trigger
      "& .productTrigger": {
        marginTop: "50px",

        [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
          marginTop: "0px",
        },

        "& .MuiInputBase-input": {
          padding: "10px 5px",
          border: "none",
          textAlign: "start",
          fontSize: "15px",
        },

        "& .MuiOutlinedInput-root": {
          height: "46px",
          margin: "6px 0",
          "& .MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${theme.palette.diagram?.grey} !important`,
          },
        },

        "& .Mui-focused": {
          border: `1px solid ${theme.palette.inputs.active}`,
        },

        "& .productName": {
          maxWidth: "410px",
          marginRight: "18px",
          [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
            maxWidth: "100%",
            width: "100%",
            marginBottom: "10px",
            marginLeft: "0px",
            marginRight: "0px",
          },
        },

        "& .productPrice": {
          maxWidth: "160px",
          marginRight: "8px",
          [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
            maxWidth: "100%",
            width: "100%",
            marginBottom: "10px",
            marginLeft: "0px",
            marginRight: "0px",
          },
        },

        "& .productCode": {
          maxWidth: "195px",
          [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
            maxWidth: "100%",
            width: "100%",
            marginLeft: "0px",
            marginRight: "0px",
          },
        },
      },

      // Styles for rewards settings
      "& .rewardsSettings": {
        "& .MuiFormControl-root": {
          [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
            width: "100%",
          },
          marginTop: "2px",
          "& .MuiInputLabel-root": {
            top: "0px !important",
          },
        },

        "& .MuiInputBase-input": {
          height: "8px",
          padding: "10px 5px",
          border: "none",
        },

        "& .MuiInputLabel-root": {
          fontSize: "12px",
          "&.MuiFormLabel-filled": {
            color: "transparent !important",
          },

          "&.Mui-focused": {
            marginTop: "4px",
            color: theme.palette.text.primary,
          },
        },

        "& .MuiOutlinedInput-root": {
          height: "33px",
          width: "240px",
          margin: "6px 0",
          [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
            width: "100%",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${theme.palette.diagram?.grey} !important`,
          },
        },
      },

      // Styles for Surprise Campaigns Trigger
      "& .surpriseTrigger, & .doubleTrigger": {
        "& input[type=number]": {
          height: "75px",
          width: "95px",
          fontSize: "2.5rem",
          fontWeight: "bold",
          textAlign: "start",
          paddingLeft: "17px",
          "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
            WebkitAppearance: "auto",
            opacity: "1",
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            background: "#FFF",
          },
          "& .MuiSvgIcon-root": {
            color: "red",
          },
        },

        "& .Mui-focused": {
          border: `1px solid ${theme.palette.inputs.active}`,
        },
      },

      "& .selectedHoliday, & .selectedActiveCampaign, & .selectedRewards": {
        "& .MuiSelect-select": {
          fontSize: "15px",
          color: theme.palette.text.primary,
        },
      },

      "& .customFileInput": {
        display: "none",
      },
    },

    fieldWrapper: {
      position: "relative",

      "& .hiddenMessage": {
        visibility: "hidden",
      },

      "& .errorWrapper": {
        width: "70%",
        position: "absolute",
        bottom: "10px",
      },

      "& .right": {
        right: "4px",
      },

      "& .MuiAlert-root": {
        backgroundColor: "#FFF2E2",
        color: "#5C2122",
        fontSize: "0.75rem",

        "& .MuiAlert-icon": {
          color: "#FF983B",
        },

        "& .MuiAlert-message": {
          overflow: "hidden",
          textAlign: "center",
        },
      },
    },
  };
});

export default useStyles;
