import {
  Box,
  IconButton,
  PopperProps,
  Stack,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from "@mui/material";
import { useCallback, useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import { lightGreen, red } from "@mui/material/colors";
import { useAccount, useUser } from "@stagewood/unified-login-library";
import { useIntl } from "react-intl";
import { PDFProvider } from "../Receipt/pdf-provider/pdf-provider.component";
import { ReceiptDocument } from "../Receipt/receipt-document/receipt-document.component";
import { IconAwesomeRobot } from "../icons/icon-awesome-robot.component";
import { IconFeatherCheckCircle } from "../icons/icon-feather-check-circle.component";
import { IconMaterialErrorOutline } from "../icons/icon-material-error-outline.component";
import { BillingEntryProps } from "./interfaces/billing-entry";
import { useBilling, useItemTitle } from "../hooks/use-billing.hook";
import { formatCentsToDollars } from "../../shared/PriceFormatters";
import { BillingTranslations } from "../intl";
import { formatDate } from "../../../utils/formatDate";

const TooltipSharedPopperProps: Partial<PopperProps> = {
  modifiers: [
    {
      name: "offset",
      options: {
        offset: [20, -13],
      },
    },
  ],
};

const SuccessfullTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    PopperProps={{
      ...props.PopperProps,
      ...TooltipSharedPopperProps,
    }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    boxShadow: theme.shadows[3],
    backgroundColor: lightGreen[50],
    padding: theme.spacing(2),
    maxWidth: "initial",
  },
}));

const ErrorTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    PopperProps={{
      ...props.PopperProps,
      ...TooltipSharedPopperProps,
    }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    boxShadow: theme.shadows[3],
    backgroundColor: red[100],
    padding: theme.spacing(2),
    maxWidth: "initial",
  },
}));

const SNACKBAR_VISIBLE_TIME = 2500;

export function BillingEntry(props: BillingEntryProps): JSX.Element {
  const user = useUser();
  const intl = useIntl();
  const account = useAccount();
  const { getTotal } = useBilling();
  const { getItemTitle } = useItemTitle();

  const [receiptBlob, setReceiptBlob] = useState<Blob | null>();
  const [receiptDownloadRequested, setReceiptDownloadRequested] = useState(false);
  const [showPreparingReceipt, setShowPreparingReceipt] = useState(false);
  const [showDownloadingReceipt, setShowDownloadingReceipt] = useState(false);
  const [showReceiptError, setShowReceiptError] = useState(false);

  const itemTitle = getItemTitle(props.item.cartHistory);

  const downloadReceiptPdf = useCallback((blob: Blob, filename: string) => {
    const link = document.createElement("a");
    document.body.appendChild(link);
    link.style.position = "absolute";
    link.style.left = "-9999px";
    link.style.visibility = "hidden";
    link.href = URL.createObjectURL(blob as Blob);
    link.download = filename;
    link.click();
    URL.revokeObjectURL(link.href);
    link.remove();
  }, []);

  const handleDownload = useCallback(() => {
    if (!receiptBlob) {
      setShowPreparingReceipt(true);
      setReceiptDownloadRequested(true);
      setTimeout(() => setShowPreparingReceipt(false), SNACKBAR_VISIBLE_TIME);
    } else {
      setShowDownloadingReceipt(true);
      setTimeout(() => setShowDownloadingReceipt(false), SNACKBAR_VISIBLE_TIME);

      downloadReceiptPdf(receiptBlob, `${props.item.cartHistoryId}.pdf`);
    }
  }, [receiptBlob, downloadReceiptPdf, props.item.cartHistoryId]);

  const onReceiptBlobReady = useCallback(
    (blob: Blob) => {
      if (!receiptBlob) {
        setReceiptBlob(blob);

        if (receiptDownloadRequested) {
          downloadReceiptPdf(blob, `${props.item.cartHistoryId}.pdf`);
        }
      }
    },
    [receiptBlob, props.item.cartHistoryId, receiptDownloadRequested, downloadReceiptPdf],
  );

  const onReceiptError = useCallback(() => {
    setShowReceiptError(true);
    setTimeout(() => setShowReceiptError(false), SNACKBAR_VISIBLE_TIME);
  }, []);

  const total = getTotal(props.item.cartHistory);

  return (
    <>
      <Box
        key={props.item.id}
        className="tableRow"
        display="grid"
        gridTemplateColumns="minmax(150px, 3fr) minmax(90px, 2fr) minmax(60px ,1fr) minmax(40px ,1fr)"
      >
        <Box>{itemTitle}</Box>
        <Box>{formatDate(props.item.cartHistory.createdAt, { month: "short", day: "numeric", year: "numeric" })}</Box>
        <Box>{formatCentsToDollars(getTotal(props.item.cartHistory))}</Box>
        <Box>
          <SuccessfullTooltip
            open={showDownloadingReceipt}
            placement="bottom-end"
            title={
              <Stack spacing={2} direction="row" alignItems="center">
                <IconFeatherCheckCircle />
                <Stack>
                  <Typography sx={{ color: lightGreen[900] }}>
                    {BillingTranslations(intl).SNACKBAR_RECEIPT_GENERATED_TITLE}
                  </Typography>
                  <Typography fontWeight="bold" sx={{ color: lightGreen[900] }}>
                    {BillingTranslations(intl).SNACKBAR_RECEIPT_GENERATED_SUBTITLE}
                  </Typography>
                </Stack>
              </Stack>
            }
          >
            <Box>
              <SuccessfullTooltip
                open={showPreparingReceipt}
                placement="bottom-end"
                title={
                  <Stack spacing={2} direction="row" alignItems="center">
                    <IconAwesomeRobot />
                    <Stack>
                      <Typography fontWeight="bold" sx={{ color: lightGreen[900] }}>
                        {BillingTranslations(intl).SNACKBAR_RECEIPT_GENERATING_TITLE}
                      </Typography>
                      <Typography variant="body2" sx={{ color: lightGreen[900] }}>
                        {BillingTranslations(intl).SNACKBAR_RECEIPT_GENERATING_SUBTITLE}
                      </Typography>
                    </Stack>
                  </Stack>
                }
              >
                <Box>
                  <ErrorTooltip
                    open={showReceiptError}
                    placement="bottom-end"
                    title={
                      <Stack spacing={2} direction="row" alignItems="center">
                        <IconMaterialErrorOutline />
                        <Stack>
                          <Typography fontWeight="bold" sx={{ color: red[900] }}>
                            {BillingTranslations(intl).SNACKBAR_RECEIPT_GENERATION_FAILED_TITLE}
                          </Typography>
                          <Typography sx={{ color: red[900] }}>
                            {BillingTranslations(intl).SNACKBAR_RECEIPT_GENERATION_FAILED_SUBTITLE}
                          </Typography>
                        </Stack>
                      </Stack>
                    }
                  >
                    <IconButton id="menu-button" onClick={handleDownload}>
                      <DownloadIcon />
                    </IconButton>
                  </ErrorTooltip>
                </Box>
              </SuccessfullTooltip>
            </Box>
          </SuccessfullTooltip>
          {!receiptBlob && (
            <PDFProvider
              transactionId={props.item.cartHistoryId}
              onError={onReceiptError}
              onBlobReady={onReceiptBlobReady}
            >
              <ReceiptDocument
                details={props.item}
                total={total}
                account={account}
                user={user}
                productLabel={intl.formatMessage({ id: "receipt.product" })}
                priceLabel={intl.formatMessage({ id: "receipt.price" })}
                accountLabel={intl.formatMessage({ id: "receipt.account" })}
                invoiceNumberLabel={intl.formatMessage({ id: "receipt.invoice_number" })}
                pdfGenerationErrorLabel={intl.formatMessage({ id: "receipt.this_pdf_not_generated" })}
              />
            </PDFProvider>
          )}
        </Box>
      </Box>
    </>
  );
}
