import { FC, useEffect, useState } from "react";
import {
  Button,
  ButtonBase,
  Input,
  InputLabel,
  Step,
  StepContent,
  Stepper as MuiStepper,
  TextField,
} from "@mui/material";
import { useIntl } from "react-intl";
import useStyles from "../styles";

interface StepperProps {
  onUpdateCampaignSettings: (settings) => void;
}

const Stepper: FC<StepperProps> = ({ onUpdateCampaignSettings }) => {
  const { buttonsWrapper } = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [selectedElement, setSelectedElement] = useState(0);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [budget, setBudget] = useState<string>("");
  const [bid, setBid] = useState<string>("");
  const { formatMessage } = useIntl();
  const handleChangeSelectedElement = (value) => {
    setSelectedElement(value);
  };

  const activeElements = [
    {
      value: 0,
      label: formatMessage({ id: "marketing.stepper.all" }),
    },
    {
      value: 1,
      label: formatMessage({ id: "marketing.stepper.campaigns" }),
    },
    {
      value: 2,
      label: formatMessage({ id: "marketing.stepper.jackpot" }),
    },
    {
      value: 3,
      label: formatMessage({ id: "marketing.stepper.experiences" }),
    },
  ];

  const steps = [
    {
      id: 0,
      value: "1",
      label: formatMessage({ id: "marketing.stepper.promotion_period" }),
      getContent: (disabled) => (
        <>
          <div className="date-picker-wrapper">
            <InputLabel htmlFor="start-date">{formatMessage({ id: "marketing.stepper.start" })}</InputLabel>

            <TextField
              id="start-date"
              type="date"
              className="date-picker"
              value={startDate}
              onChange={(event) => setStartDate(event.target.value || "")}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={disabled}
            />
          </div>

          <div className="date-picker-wrapper">
            <InputLabel htmlFor="end-date">{formatMessage({ id: "marketing.stepper.end" })}</InputLabel>

            <TextField
              id="end-date"
              type="date"
              className="date-picker"
              placeholder="mm-dd-yyyy"
              value={endDate}
              onChange={(event) => setEndDate(event.target.value || "")}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={disabled}
            />
          </div>
        </>
      ),
    },
    {
      id: 1,
      value: "2",
      label: formatMessage({ id: "marketing.stepper.budget" }),
      getContent: (disabled) => (
        <>
          <div className="input-wrapper">
            <InputLabel htmlFor="budget-amount"> {formatMessage({ id: "marketing.stepper.budget" })}</InputLabel>

            <Input
              id="budget-amount"
              placeholder="$0000"
              value={budget}
              onChange={(event) => setBudget(event.target.value || "")}
              disabled={disabled}
              required
            />
          </div>

          <div className="input-wrapper">
            <InputLabel htmlFor="bid-amount"> {formatMessage({ id: "marketing.stepper.bid" })}</InputLabel>

            <Input
              id="bid-amount"
              placeholder="$0000"
              value={bid}
              onChange={(event) => setBid(event.target.value || "")}
              disabled={disabled}
              required
            />
          </div>
        </>
      ),
    },
    {
      id: 2,
      value: "3",
      label: formatMessage({ id: "marketing.stepper.active_elements" }),
      getContent: (disabled) => (
        <div className="buttons-wrapper">
          {activeElements.map((element) => (
            <ButtonBase
              key={element.value}
              className={`active-element-button ${selectedElement !== element.value ? "" : "active"}`}
              onClick={() => handleChangeSelectedElement(element.value)}
              disabled={disabled}
            >
              {element.label}
            </ButtonBase>
          ))}
        </div>
      ),
    },
  ];

  const handleNextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handlePrevStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getDisabledValue = () => {
    switch (activeStep) {
      case 0:
        return !startDate || !endDate;
      case 1:
        return !budget || !bid;
      default:
        return false;
    }
  };

  useEffect(() => {
    if (activeStep === steps.length) {
      onUpdateCampaignSettings({
        startDate,
        endDate,
        budget,
        bid,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  return (
    <MuiStepper orientation="vertical">
      {steps.map((step) => {
        return (
          <Step
            key={step.id}
            active={!!`${step.id}`}
            completed={step.id < activeStep}
            className={`${step.id !== activeStep && step.id > activeStep ? "disabled" : ""}`}
          >
            <div className="step-label-container">
              <div className="step-number__outer">
                <div className="step-number">{step.value}</div>
              </div>

              <div className="step-label">{step.label}</div>
            </div>

            <StepContent>
              <div className="step-content-wrapper">
                {step.getContent(step.id !== activeStep && step.id > activeStep)}
              </div>

              {step.id === activeStep && (
                <div className={buttonsWrapper}>
                  {step.id !== 0 && (
                    <Button onClick={handlePrevStep} variant="contained" className="secondary-button">
                      {formatMessage({ id: "btn_text_back" })}
                    </Button>
                  )}

                  <Button
                    onClick={handleNextStep}
                    variant="contained"
                    className="primary-button"
                    disabled={getDisabledValue()}
                  >
                    {formatMessage({ id: step.id !== 2 ? "btn_text_next" : "btn_text_finish" })}
                  </Button>
                </div>
              )}
            </StepContent>
          </Step>
        );
      })}
    </MuiStepper>
  );
};

export default Stepper;
