import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    wrap: {
      "& .MuiButton-root": {
        padding: "5px 20px",
        borderRadius: "16px",
        fontWeight: "bold",
        color: theme.palette.white,
        background: theme.palette.blue,
        "&.distrubute": {
          marginRight: "15px",
          color: theme.palette.analytics?.grey,
          border: `1px solid ${theme.palette.analytics?.grey}`,
          background: "none",
        },
      },

      "& .underline": {
        textDecoration: "underline",
        cursor: "pointer",
      },
    },
  };
});

export default useStyles;
