import { ReactChild } from "react";
import { Link } from "react-router-dom";
import { Grid, Typography } from "@mui/material";

import { useTheme } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import useStyles from "./styles";

import { ReactComponent as IconMdOpen } from "../../../../../assets/icons/dashboard/Icon ionic-md-open.svg";
import { Routes } from "../../../../../constants";
import { MessageIds } from "../../../../../i18n";

interface WidgetProps {
  title?: string;
  firstIcon?: ReactChild;
  secondIcon?: ReactChild;
  bg?: string;
  mr?: string | { xs: string; lg: string };
  mb?: string | { xs: string; lg: string };
  inverted?: boolean;
  totalCentorAmount?: number;
  info?: string
}

const ThisMonthSecondaryWidget = ({
  title,
  firstIcon,
  secondIcon,
  bg,
  mr,
  mb,
  inverted,
  totalCentorAmount,
  info,
}: WidgetProps) => {
  const theme = useTheme();
  const { secondaryWidget } = useStyles();

  return (
    <Grid mr={mr} mb={{ xs: "0.438rem", lg: "0" }} bgcolor={bg} container className={secondaryWidget}>
      <Grid container item>
        <Typography className="title">{title}</Typography>
      </Grid>

      {inverted ? (
        <Grid container justifyContent="center" flexWrap="nowrap">
          <Grid container flexDirection="column" justifyContent="center" alignItems="center" width="50%">
            <Grid container width="max-content">
              {firstIcon}
            </Grid>
            <Typography className="totalCentorAmount">{totalCentorAmount || "0"}</Typography>
            <Typography className="widgetItemTitle">
              <FormattedMessage
                id={info as MessageIds}
              />
            </Typography>
          </Grid>
          {secondIcon && (
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              width="50%"
              borderLeft={secondIcon && `0.031rem solid ${theme.palette.diagram?.purple}`}
            >
              <Grid container alignItems="center" flexDirection="column">
                <Grid mb="4px ">{secondIcon}</Grid>
                <Typography fontWeight="bold" fontSize="0.75rem" color={theme.palette.primary.dark}>
                  1,540
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      ) : (
        <>
          <Grid height="40%">
            <Grid>{firstIcon}</Grid>
            <Typography fontWeight="bold" fontSize="0.75rem" color={theme.palette.primary.dark}>
              1,540
            </Typography>
          </Grid>

          <Grid container width="100%" borderTop={secondIcon && `0.031rem solid ${theme.palette.diagram?.purple}`}>
            {secondIcon && (
              <Grid>
                <Grid>{secondIcon}</Grid>
                <Typography fontWeight="bold" fontSize="0.75rem" color={theme.palette.primary.dark}>
                  1,540
                </Typography>
              </Grid>
            )}
          </Grid>
        </>
      )}

      <Grid container alignItems="end" justifyContent="end">
        <Typography className="seeAllRules">
          <Link to={Routes.YOUR_REWARDS} className="link">
            <IconMdOpen />
            <span className="textLink"><FormattedMessage id="dashboard.see_all"/></span>
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ThisMonthSecondaryWidget;
