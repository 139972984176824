import { FC } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import LoneStars from "../../../../../assets/icons/tykIcons/loneStars.svg";
import Centors from "../../../../../assets/icons/tykIcons/centors.svg";
import SlotMachine from "../../../../../assets/icons/tykIcons/Jackpot-Icon(1)@2x.png";
import CentorsIcon from "../../../../../assets/icons/tykIcons/Centor-icon@2x.png";
import LoneStarsIcon from "../../../../../assets/icons/tykIcons/LoneStar-icon@2x.png";
import Cashier from "../../../../../assets/icons/tykIcons/cashier-icon@2x.png";
import eyeIcon from "../../../../../assets/icons/tykIcons/eye-icon.svg";
import clickIcon from "../../../../../assets/icons/tykIcons/click-icon.svg";
import useStyles from "../styles";
import theme from "../../../../../theme";

const TotalsStatistic: FC = () => {
  const { totalsStatisticWrap } = useStyles();

  return (
    <>
      <Typography
        variant="h3"
        color="#6E6E6E"
        fontFamily={theme.typography.fontFamily}
        fontSize="0.75rem"
        marginBottom="12px"
      >
        <FormattedMessage id="modals.totals" />
      </Typography>

      <Grid container spacing={1} className={totalsStatisticWrap}>
        <Grid item className="statisticWrap">
          <Box className="statistic">
            <Box>
              <img src={LoneStars} alt="LoneStars" height={16} />
            </Box>

            <Typography
              color={theme.palette.primary.dark}
              fontFamily={theme.typography.fontFamily}
              fontSize="0.625rem"
              fontWeight="bold"
            >
              ${"200".toLocaleString()}
            </Typography>
          </Box>

          <Box className="statisticDivider" />

          <Box className="statistic" width="40px">
            <Typography
              color={theme.palette.primary.dark}
              fontFamily={theme.typography.fontFamily}
              fontSize="0.625rem"
              fontWeight="bold"
            >
              {"55".toLocaleString()} LS
            </Typography>
          </Box>
        </Grid>

        <Grid item className="statisticWrap">
          <Box className="statistic">
            <Box>
              <img src={Centors} alt="Centors" height={14} />
            </Box>

            <Typography
              color={theme.palette.primary.dark}
              fontFamily={theme.typography.fontFamily}
              fontSize="0.625rem"
              fontWeight="bold"
            >
              ${"50".toLocaleString()}
            </Typography>
          </Box>

          <Box className="statisticDivider" />

          <Box className="statistic" width="40px">
            <Typography
              color={theme.palette.primary.dark}
              fontFamily={theme.typography.fontFamily}
              fontSize="0.625rem"
              fontWeight="bold"
            >
              {"200".toLocaleString()} C
            </Typography>
          </Box>
        </Grid>

        <Grid item className="statisticWrap">
          <Box className="statistic">
            <Box>
              <img src={SlotMachine} alt="Jackpot" height={18} />
            </Box>

            <Typography
              color={theme.palette.primary.dark}
              fontFamily={theme.typography.fontFamily}
              fontSize="0.625rem"
              fontWeight="bold"
            >
              ${"50".toLocaleString()}
            </Typography>
          </Box>

          <Box className="statisticDivider" />

          <Box className="statistic" width="40px">
            <Box display="flex" flexDirection="row" justifyContent="space-between" marginBottom="6px">
              <Typography
                color={theme.palette.primary.dark}
                fontFamily={theme.typography.fontFamily}
                fontSize="0.625rem"
                fontWeight="bold"
                marginRight="4px"
              >
                ${"200".toLocaleString()}
              </Typography>

              <img src={CentorsIcon} alt="Centors" className="smallImage" />
            </Box>

            <Box display="flex" flexDirection="row">
              <Typography
                color={theme.palette.primary.dark}
                fontFamily={theme.typography.fontFamily}
                fontSize="0.625rem"
                fontWeight="bold"
                marginRight="4px"
              >
                ${"50".toLocaleString()}
              </Typography>

              <img src={LoneStarsIcon} alt="LoneStars" className="smallImage" />
            </Box>
          </Box>
        </Grid>

        <Grid item className="statisticWrap" padding="8px">
          <Box className="statistic">
            <Box>
              <img src={Cashier} alt="Promoters" height={18} />
            </Box>

            <Typography
              color={theme.palette.primary.dark}
              fontFamily={theme.typography.fontFamily}
              fontSize="0.625rem"
              fontWeight="bold"
            >
              ${"50".toLocaleString()}
            </Typography>
          </Box>

          <Box className="statisticDivider" />

          <Box className="statistic" width="40px">
            <Box display="flex" flexDirection="row" marginBottom="6px">
              <Typography
                color={theme.palette.primary.dark}
                fontFamily={theme.typography.fontFamily}
                fontSize="0.625rem"
                fontWeight="bold"
                marginRight="4px"
              >
                ${"200".toLocaleString()}
              </Typography>

              <img src={CentorsIcon} alt="Centors" className="smallImage" />
            </Box>

            <Box display="flex" flexDirection="row">
              <Typography
                color={theme.palette.primary.dark}
                fontFamily={theme.typography.fontFamily}
                fontSize="0.625rem"
                fontWeight="bold"
                marginRight="4px"
              >
                ${"50".toLocaleString()}
              </Typography>

              <img src={LoneStarsIcon} alt="LoneStars" className="smallImage" />
            </Box>
          </Box>
        </Grid>

        <Grid item className="statisticWrap">
          <Box className="statistic">
            <Box>
              <img src={eyeIcon} alt="Views" />
            </Box>

            <Typography
              color={theme.palette.primary.dark}
              fontFamily={theme.typography.fontFamily}
              fontSize="0.625rem"
              fontWeight="bold"
            >
              {"1540".toLocaleString()}
            </Typography>
          </Box>

          <Box className="statisticDivider" />

          <Box className="statistic" width="40px">
            <Box>
              <img src={clickIcon} alt="Clicks" />
            </Box>

            <Typography
              color={theme.palette.primary.dark}
              fontFamily={theme.typography.fontFamily}
              fontSize="0.625rem"
              fontWeight="bold"
            >
              {"1540".toLocaleString()}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default TotalsStatistic;
