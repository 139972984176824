import { useTheme } from "@mui/styles";
import { Grid, InputBase, Typography } from "@mui/material";
import Icon from "../../../../utils/icon";
import Modal from "../../../Modal";
import RuleToggle from "../../RuleToggle";
import Layout from "./Layout";

const AutomateRewards = ({
  modalActive,
  handlerModal,
}: {
  modalActive: boolean;
  handlerModal: () => void;
}) => {
  const theme = useTheme();

  return (
    <Modal isOpen={modalActive} onClose={handlerModal}>
      <Layout
        title={
          <Grid
            container
            flexWrap="nowrap"
            width="100%"
            justifyContent="space-between"
            borderBottom={`1px solid ${theme.palette.diagram?.purple}`}
            height="54px"
            mb="80px"
          >
            <Typography
              fontWeight="bold"
              color={theme.palette.black}
              mb={{ xs: "70px", md: "135px" }}
            >
              Rewards accumulated
            </Typography>

            <Grid>
              <Grid container flexWrap="nowrap" alignItems="center">
                <Grid height="30px" width="30px" borderRadius="100px" overflow="hidden" mr="15px">
                  <Icon.HappyGirls />
                </Grid>
                <Typography fontSize="1rem">0587569 - Steve Balmer</Typography>
              </Grid>
              <Typography fontSize="0.75rem" textAlign="end">
                6 Woodsman Avenue ….
              </Typography>
            </Grid>
          </Grid>
        }
        mbTitle="25px"
      >
        <Grid>
          <Grid mb="20px">
            <RuleToggle title="Release automatically every month on">
              <input type="date" defaultValue="2021-06-12" />
            </RuleToggle>
          </Grid>
          <Grid>
            <RuleToggle title="Release automatically when amount hits">
              <Grid minWidth="70px">
                <InputBase placeholder="000" type="number" />
              </Grid>
            </RuleToggle>
          </Grid>
        </Grid>
      </Layout>
    </Modal>
  );
};

export default AutomateRewards;
