
import { FC } from "react";
import { Grid } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useIntl } from "react-intl";
import SectionLayout from "../components/SectionLayout";
import ThisMonthMainWidget, { defaultElements } from "../components/ThisMonth/ThisMonthMainWidget";
import YourRewardsWidget from "../components/YourRewardsWidget";
import ThisMonthSecondaryWidget from "../components/ThisMonth/ThisMonthSecondaryWidget";
import useStyles from "./styles";
import Icon from "../../../utils/icon";
import { GetRewardRulesDashboardInfoQuery, RewardsRulesDashboardInfoPayload, RuleName, ScannedReceiptsPayload } from "../../../generated/types";
import { PageLoader } from "../../PageLoader";
import WeeklyScannedAvgWidget from "../components/WeeklyScannedAvgWidget/WeeklyScannedAvgWidget";
import CampaignsWidget from "../components/CampaignsWidget";
import { membershipWeights } from "../../Utils/helpers";

interface LayoutPropsInterface {
  isYourRewardsEnabled: boolean;
  totalCentorAmount: number;
  isLoading: boolean;
  receipts: ScannedReceiptsPayload;
  rewardsData: any;
  userMembership: string | undefined;
}

const Layout: FC<LayoutPropsInterface> = ({
  isYourRewardsEnabled,
  totalCentorAmount,
  isLoading,
  receipts,
  rewardsData,
  userMembership,
}) => {
  const isCampaigns = false;
  const theme = useTheme();
  const { mainDiv, mainDivGold } = useStyles();
  const intl = useIntl();
  const isBusiness = membershipWeights[userMembership as string] === 1;

  const themeColor = totalCentorAmount ? theme.palette.dashBoard?.lightCyan : theme.palette.dashBoard?.light;
  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <Grid className={isBusiness ? mainDiv : mainDivGold}>
      <SectionLayout
        gridArea="thisMonth"
        title={intl.formatMessage({ id: "dashboard.this_month" })}
        child={
          <ThisMonthMainWidget
            receipts={receipts}
            items={isBusiness ? defaultElements(intl).slice(0, 2) : defaultElements(intl).slice(0, 3)}
          />
        }
      />
      <SectionLayout
        gridArea="info"
        child={
          <Grid container flexWrap="nowrap" flexDirection={{ xs: "column", lg: "row" }}>
            {!isBusiness ? (
              <>
                <ThisMonthSecondaryWidget
                  title={intl.formatMessage({ id: "dashboard.active_reward_rules" })}
                  firstIcon={totalCentorAmount ? <Icon.CentorsFull /> : <Icon.CentorsSad width={40} />}
                  bg={themeColor}
                  mr={{ xs: "0", lg: "0.638rem" }}
                  mb={{ xs: "0.438rem", lg: "0" }}
                  info={
                    totalCentorAmount
                      ? "dashboard.total_tyks_distributed_in_reward"
                      : "dashboard.no_tyks_distributed_in_reward"
                  }
                  totalCentorAmount={totalCentorAmount}
                  inverted
                />
                <ThisMonthSecondaryWidget
                  title={intl.formatMessage({ id: "dashboard.campaigns" })}
                  firstIcon={totalCentorAmount ? <Icon.CentorsFull /> : <Icon.CentorsSad width={40} />}
                  bg={themeColor}
                  mr={{ xs: "0", lg: "0" }}
                  mb={{ xs: "0.438rem", lg: "0" }}
                  info={
                    totalCentorAmount
                      ? "dashboard.total_tyks_distributed_in_campaigns"
                      : "dashboard.no_tyks_distributed_in_campaigns"
                  }
                  totalCentorAmount={totalCentorAmount}
                  inverted
                />
              </>
            ) : (
              <>
                <Grid container item xs={12} lg={4}>
                  <ThisMonthSecondaryWidget
                    title={intl.formatMessage({ id: "dashboard.total_tyks_distributed" })}
                    firstIcon={totalCentorAmount ? <Icon.CentorsFull /> : <Icon.CentorsSad width={40} />}
                    bg={themeColor}
                    mr={{ xs: "0", lg: "0.638rem" }}
                    mb={{ xs: "0.438rem", lg: "0" }}
                    info={
                      totalCentorAmount
                        ? "dashboard.total_tyks_distributed_in_reward"
                        : "dashboard.no_tyks_distributed_in_reward"
                    }
                    totalCentorAmount={totalCentorAmount}
                    inverted
                  />
                </Grid>
                <WeeklyScannedAvgWidget />
              </>
            )}
          </Grid>
        }
      />

      {isYourRewardsEnabled && rewardsData && rewardsData.length > 0 && (
        <SectionLayout
          gridArea="yourRewards"
          title={intl.formatMessage({ id: "dashboard.your_rewards" })}
          child={
            <Grid container flexWrap={isBusiness ? "wrap" : "nowrap"} flexDirection={{ xs: "column", lg: "row" }}>
              {rewardsData?.slice(0, !isBusiness ? 2 : undefined).map((data) => (
                <YourRewardsWidget
                  ml={{ xs: "0", lg: "0rem" }}
                  mr={{ xs: "0", lg: "1rem" }}
                  data={{
                    rule: data?.rule?.rule?.title || "N/A",
                    status: data?.rule?.status?.title || "N/A",
                    centorsDistributed: data?.centorsDistributed || 0,
                    ruleName: data?.rule?.rule?.ruleName || RuleName.Purchases,
                    deactivationDate: data?.rule?.deactivationDate,
                  }}
                  key={data?.rule?.rule?.ruleName}
                />
              ))}
            </Grid>
          }
        />
      )}
      {isCampaigns && !isBusiness && (
        <SectionLayout
          gridArea="yourCampaigns"
          title={intl.formatMessage({ id: "dashboard.your_campaigns" })}
          child={
            <Grid container flexWrap="nowrap" flexDirection={{ xs: "column", lg: "row" }}>
              {rewardsData?.slice(0, 2).map((data) => (
                <CampaignsWidget key={data?.rule?.rule?.ruleName} mr={{ xs: "0", lg: "1rem" }} />
              ))}
            </Grid>
          }
        />
      )}
      {!isBusiness && (
        <SectionLayout
          gridArea="analytics"
          title={intl.formatMessage({ id: "dashboard.analytics" })}
          child={
            <Grid container>
              <WeeklyScannedAvgWidget />
            </Grid>
          }
        />
      )}
    </Grid>
  );

};
export default Layout;
