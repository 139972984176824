import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    receiptRemoveModal: {
      "& .modal": {
        padding: `${theme.spacing(6)} ${theme.spacing(6)} ${theme.spacing(4)} ${theme.spacing(6)}`,
      },

      "& .modal-title": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingBottom: theme.spacing(3),
        "& .modal-title-text": {
          fontSize: "1.25rem",
          fontWeight: 700,
        },
        "& .warning-icon": {
          color: "#FFBE40",
          width: "51px",
          height: "45px",
          flexShrink: 0,
        },
      },

      "& .modal-content": {
        display: "flex",
        flexDirection: "column",
        padding: `${theme.spacing(2)} 0 ${theme.spacing(3)}`,
        alignItems: "center",
      },

      "& .btn": {
        width: "150px",
        fontWeight: 700,
        borderRadius: "2rem",
        textTransform: "none",
      },

      "& .submit-btn": {
        backgroundColor: "#1473E6",
        color: theme.palette.white,
        "&:hover": {
          backgroundColor: "#203ED4",
        },
        "& .loading-indicator": {
          color: "white",
        },
      },

      "& .cancel-btn": {
        backgroundColor: "#C8000000",
        border: "2px solid #747474",
        color: "#747474",
        "&:hover": {
          backgroundColor: "#47474712",
        },
      },

      "& .loading-indicator": {
        left: "18px",
        color: "#747474",
      },
    },
  };
});

export default useStyles;
