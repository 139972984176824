import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import { useUser } from "@stagewood/unified-login-library";
import { useIntl } from "react-intl";
import { UPDATE_REWARDS_DISTRIBUTION } from "../../../../apollo/mutations";
import Modal from "../../../Modal";
import Diagram from "./Diagram";
import Footer from "./Footer";
import Layout from "./Layout";
import { IRewardDistributionData, IRewardDistributionModal, keysPermission } from "./types";

const RewardDistributionModal = ({
  reward,
  isOpen,
  customTykAwardsDistribution,
  handleOnRewardDistributionSave,
  onClose,
}: IRewardDistributionModal) => {
  const initialData: IRewardDistributionData = { values: {}, ids: [] };
  const [rewardDistributionData, setRewardDistributionData] = useState(initialData);
  const { formatMessage } = useIntl();
  const user = useUser();

  const [updateRewards, mutationResult] = useMutation(UPDATE_REWARDS_DISTRIBUTION);

  const getInputsValue = (inputName) => {
    return mutationResult.data?.updateRewardDistribution?.[inputName] || customTykAwardsDistribution?.[inputName];
  };

  const onSave = () => {
    onClose();

    const rewardData = { ...rewardDistributionData };
    const keys = [...keysPermission];
    keys.forEach((key) => {
      if (rewardData?.ids?.includes(key.id)) {
        if (!rewardData?.values?.[key.value] || Number(rewardData?.values?.[key.value]) < 0) {
          // eslint-disable-next-line @typescript-eslint/dot-notation
          delete rewardData.values[key.value];
          const index = rewardData.ids.indexOf(key.id);
          if (index > -1) {
            rewardData.ids.splice(index, 1);
          }
        }
      }
    });

    updateRewards({
      variables: {
        ...rewardData.values,
        features: [...rewardData.ids],
        userId: user?.id,
      },
    }).then(({ data }) => {
      handleOnRewardDistributionSave?.({
        centorsRate: rewardData?.values?.centorsRate || null,
        loneStarsRate: rewardData?.values?.loneStarsRate || null,
        jackpotAwardRate: rewardData?.values?.jackpotAwardRate || null,
        promoterAwardRate: rewardData?.values?.promoterAwardRate || null,
        minimumLoneStarAward: rewardData?.values?.minimumLoneStarAward || null,
      });

      return data;
    });
  };

  const onCancel = () => {
    onClose();
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <Layout
        title={formatMessage({ id: "reward_rules_reward_distribution" })}
        subtitle={formatMessage({ id: "reward_rules_info" })}
        footer={<Footer />}
        handleSave={onSave}
        handleCancel={onCancel}
      >
        <Diagram setRewardDistributionData={setRewardDistributionData} getInputsValue={getInputsValue} />
      </Layout>
    </Modal>
  );
};

export default RewardDistributionModal;
