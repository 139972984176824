import { useCallback, useMemo, useRef } from "react";
import { useModalRouteState } from "../../hooks/useModalRouteState.hook";
import { AddressSelectionModalProps } from "./AddressSelectionModal";

const ADDRESS_SELECTION_MODAL_PARAM_KEY = "addressSelectionModal";
type SuccessCallback = (addressId: string) => void;

export const useAddressSelectionModal = (): [
  props: AddressSelectionModalProps,
  openModal: (successCallback: SuccessCallback) => void,
] => {
  const { isVisible, openModal, closeModal } = useModalRouteState(ADDRESS_SELECTION_MODAL_PARAM_KEY);
  const successCallbackRef = useRef<SuccessCallback | null>(null);

  const addressSelectionModalProps: AddressSelectionModalProps = useMemo(
    () => ({
      isActive: isVisible,
      onClose: closeModal,
      onSubmit: (addressId: string) => {
        closeModal();
        successCallbackRef.current?.(addressId);
      },
      allowAccountCreation: true,
    }),
    [isVisible, closeModal, successCallbackRef],
  );
  const openAddressSelectionModal = useCallback(
    (successCallback: SuccessCallback) => {
      successCallbackRef.current = successCallback;

      openModal();
    },
    [successCallbackRef, openModal],
  );

  return [addressSelectionModalProps, openAddressSelectionModal];
};
