import { Grid, InputBase, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useState } from "react";
import clsx from "clsx";
import Section from "./Section";
import Sidebar from "./Sidebar";
import RuleToggle from "../RuleToggle";
import Icon from "../../../utils/icon";
import RuleToggleJackpot from "./RuleToggleJackpot";
import useStyles from "./styles";

const Jackpot = () => {
  const theme = useTheme();
  const [jackpotActive, setJackpotActive] = useState(false);
  const { hideContent } = useStyles();
  const handlerJackpotState = () => {
    setJackpotActive(!jackpotActive);
  };
  return (
    <Grid
      container
      flexWrap="nowrap"
      flexDirection={{ xs: "column-reverse", md: "row" }}
      mt="40px"
      p={{ xs: "10px", lg: "70px 40px 124px" }}
      bgcolor={theme.palette.analytics?.white}
    >
      <Grid
        width="100%"
        maxWidth={{ xs: "100%", md: "60%", lg: "75%" }}
        mr={{ xs: "20px", lg: "60px" }}
      >
        <Section
          title="Sweepstake"
          subtitle="By activating the sweepstake your accumulated will be released randomly anytime, one winner will be selected among your customers scanned receipts."
        >
          <Grid container alignItems="end">
            <RuleToggleJackpot
              active={jackpotActive}
              setActive={handlerJackpotState}
              w="70%"
              title="Activate Sweepstake"
            />
            <Grid ml="17px">
              <Grid container flexWrap="nowrap" mb="6px">
                <Grid container flexWrap="nowrap" alignItems="end" mr="10px">
                  <Icon.CentorsFull />
                  <Typography
                    height="max-content"
                    ml="15px"
                    fontSize="0.875rem"
                    color={theme.palette.primary.dark}
                    fontWeight="bold"
                  >
                    2,000
                  </Typography>
                </Grid>
                <Grid container flexWrap="nowrap" alignItems="end">
                  <Icon.LoneStars />
                  <Typography
                    height="max-content"
                    ml="15px"
                    fontSize="0.875rem"
                    color={theme.palette.primary.dark}
                    fontWeight="bold"
                  >
                    550
                  </Typography>
                </Grid>
              </Grid>

              <Typography fontSize="0.75rem" color={theme.palette.analytics?.grey}>
                Minimun tyks needed to activate Jackpot
              </Typography>
            </Grid>
          </Grid>
        </Section>
        <Grid
          className={clsx(hideContent, {
            active: jackpotActive,
          })}
          maxHeight={jackpotActive ? "2500px" : "0px"}
        >
          <Section
            title="Define what part of your audience will participate in the sweepstake"
            subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed facilisis leo vel elit aliquam, sed commodo enim lacinia. Nunc vitae."
          >
            <>
              <RuleToggle title="Clients from" mb="20px">
                <Grid container flexWrap="nowrap" alignItems="center">
                  <input type="date" defaultValue="2021-06-12" />

                  <Typography m="0 10px" fontSize="0.875rem" color={theme.palette.primary.dark}>
                    to
                  </Typography>

                  <input type="date" defaultValue="2021-06-12" />
                </Grid>
              </RuleToggle>
              <RuleToggle title="Receipts => than">
                <InputBase placeholder="000" type="number" />
              </RuleToggle>
            </>
          </Section>

          <Section
            title="Decide when your jackpot will be released"
            subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed facilisis leo vel elit aliquam, sed commodo enim lacinia. Nunc vitae."
          >
            <RuleToggle title="When amount hits">
              <InputBase placeholder="000" type="number" />
            </RuleToggle>
          </Section>

          <Section title="Attemps allowed to win">
            <RuleToggle title="Chances to try per user">
              <Grid container flexWrap="nowrap" alignItems="center" width="max-content">
                <div className="btn minus" />
                <Grid m="0 6px">
                  <InputBase placeholder="000" type="number" />
                </Grid>
                <div className="btn plus" />
              </Grid>
            </RuleToggle>
          </Section>
        </Grid>
      </Grid>
      <Grid
        width="100%"
        maxWidth={{ xs: "100%", md: "40%", lg: "25%" }}
        mb={{ xs: "20px", md: "0" }}
      >
        <Sidebar active={jackpotActive} />
      </Grid>
    </Grid>
  );
};

export default Jackpot;
