import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    width: "100%",
    padding: "60px 30px 0 60px",
    gap: "30px",
  },
  summarySection: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  paymentMethodsSection: {
    width: "100%",
  },
}));
