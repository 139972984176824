import { Grid, Typography } from "@mui/material";
import { useUser } from "@stagewood/unified-login-library";
import { useIntl } from "react-intl";
import { useQuery } from "@apollo/client";
import { useTheme } from "@mui/styles";
import Icon from "../../../../utils/icon";
import { WeeklyReceiptWidgetText } from "./intl";
import DayBar from "./DayBar";
import { GetWeeklyAverageScannedReceiptsInfoQuery } from "../../graphql/GetWeeklyAverageScannedReceiptsInfo.query";
import { PageLoader } from "../../../PageLoader";

const daysArrays = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

export default function WeeklyScannedAvgWidget() {
  const theme = useTheme();
  const intl = useIntl();
  const user = useUser();

  const { data: weeklyAverageData, loading: isLoadingWeeklyAverageData } = useQuery(
    GetWeeklyAverageScannedReceiptsInfoQuery,
    {
      variables: {
        userId: user?.id || "",
      },
    },
  );

  const weeklyData = weeklyAverageData?.getWeeklyAvgScannedReceiptInfo?.payload;

  return isLoadingWeeklyAverageData ? (
    <PageLoader />
  ) : (
    <Grid
      container
      flexDirection={{ xs: "column", md: "row" }}
      flexWrap="wrap"
      width={{ xs: "100%" }}
      p="0.75rem"
      pl={{ xs: "0.75rem", md: "2rem" }}
      borderRadius="0.375rem"
      bgcolor={
        weeklyData?.weekly && weeklyData?.weekly > 0
          ? theme.palette.dashBoard?.lightCyan
          : theme.palette.dashBoard?.light
      }
    >
      <Grid>
        <Grid container item>
          <Typography color={theme.palette.primary.dark} fontSize={12} align="left" width={100} className="title">
            {WeeklyReceiptWidgetText(intl).WeeklyTitle}
          </Typography>
        </Grid>
        <Grid container justifyContent="start" flexWrap="nowrap">
          <Grid container flexDirection="column" justifyContent="center" alignItems="center" width="50%">
            <Grid container width="max-content">
              {(weeklyData?.weekly || 0) > 0 ? <Icon.CentorsFull /> : <Icon.CentorsSad width={40} />}
            </Grid>
            <Typography className="totalCentorAmount">{weeklyData?.weekly || "0"}</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        item
        width={{ xs: "93%", md: "70%" }}
        flexDirection="row"
        alignItems="end"
        alignContent="center"
        marginLeft="auto"
        marginRight="auto"
        justifyContent="space-around"
      >
        {daysArrays.map((day, index) => (
          <DayBar key={day} day={day} index={index} weeklyData={weeklyData} />
        ))}
      </Grid>
    </Grid>
  );
}
