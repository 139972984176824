import { graphql } from "../../../../gql";

export const updateUserPaymentMethodMutation = graphql(`
  mutation updateUserPaymentMethodMutation($input: UpdateUserPaymentMethodInput!) {
    updateUserPaymentMethod(input: $input) {
      id
      brand
      expMonth
      expYear
      lastFour
      token
      isDefault
      owner
      billingAddress {
        addressLine1
        addressLine2
        city
        state
        zipCode
        countryCode
      }
    }
  }
`);
