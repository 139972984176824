import { Dialog, Modal } from "@mui/material";
import { FC, useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import PaymentSummary from "../../Membership/PaymentSummary";
import { useQueryParameterState } from "../../../hooks/useQueryParameterState.hook";
import { RenewalPeriod } from "../../../generated/types";
import { getAllMembershipsQuery } from "../../Membership/graphql/GetAllMemberships.query";
import { GET_MEMBERSHIP } from "../../../apollo/queries";
import useStyles from "./styles";

export const EnrolledModal: any = ({ open, onClose, location, refetch }) => {
  const [currentPlan, setCurrentPlan] = useState("");
  const [internalId, setInternalId] = useState<number>(1);
  const [isCancellationMempershipModal, setIsCancellationMempershipModal] = useState(false);
  const [renewalPeriod, setRenewalPeriod] = useQueryParameterState<RenewalPeriod>(
    "renewalPeriod",
    RenewalPeriod.Monthly,
  );
  const { receiptUploadModal } = useStyles();
  const { loading: membershipsLoading, data: membershipsData } = useQuery(getAllMembershipsQuery);

  const memberships = membershipsData?.getAllMemberships || [];
  const currentPlanId = memberships?.find((membership) => membership?.internalId === internalId)?.id ?? "";
  const { loading: membershipLoading, data: membershipData } = useQuery(GET_MEMBERSHIP, {
    variables: {
      id: currentPlanId,
    },
    skip: !currentPlanId,
  });

  return (
    <Dialog open={open} onClose={onClose} className={receiptUploadModal}>
      <PaymentSummary
        enrolledModal
        locationId={location}
        name={currentPlan}
        isEditMode={false}
        membershipId={currentPlanId}
        renewalPeriod={RenewalPeriod.Monthly}
      />
    </Dialog>
  );
};
