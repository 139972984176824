import { SVGProps } from "react";

export function IconMaterialErrorOutline(
  props: Pick<SVGProps<SVGSVGElement>, "width" | "height" | "fill">,
): JSX.Element {
  const defaultIconWidth = 30;
  const defaultIconHeight = 30;
  const defaultIconFill = "#e16c65";
  const iconWidth = props.width ?? defaultIconWidth;
  const iconHeight = props.height ?? defaultIconHeight;
  const iconFill = props.fill ?? defaultIconFill;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={iconWidth}
      height={iconHeight}
      viewBox={`0 0 ${defaultIconWidth} ${defaultIconHeight}`}
    >
      <path
        d="M16.5,22.5h3v3h-3Zm0-12h3v9h-3ZM17.985,3A15,15,0,1,0,33,18,14.993,14.993,0,0,0,17.985,3ZM18,30A12,12,0,1,1,30,18,12,12,0,0,1,18,30Z"
        transform="translate(-3 -3)"
        fill={iconFill}
      />
    </svg>
  );
}
