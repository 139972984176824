import { FC } from "react";
import { Grid, Box, Typography } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useIntl } from "react-intl";
import { ReactComponent as ExclamationIcon } from "../../assets/icons/tykIcons/exclamation.svg";

import useStyles from "./styles";

import { AppTooltips, TooltipType } from "../../types/tooltips";

export const featureTooltipStyleProps = {
  tooltip: {
    sx: {
      bgcolor: "#FFF2E2",
      padding: "0.3125rem",
      borderRadius: "0.375rem",
      color: "#5C2122",
      "& .MuiTooltip-arrow": {
        color: "#FFF2E2",
      },
    },
  },
};

export const infoTooltipStyleProps = {
  tooltip: {
    sx: {
      bgcolor: "#161C2E",
      "& .MuiTooltip-arrow": {
        color: "#161C2E",
      },
    },
  },
};

export const FeatureTooltip: FC<{ type: TooltipType }> = ({ type }) => {
  const intl = useIntl();
  const { wrap, wrapIcon } = useStyles();

  return (
    <Grid className={wrap}>
      <Grid container className={wrapIcon}>
        <ErrorOutlineIcon />
      </Grid>
      <Grid>{AppTooltips(intl)[type]}</Grid>
    </Grid>
  );
};

export const InfoTooltip: FC<{ type: TooltipType }> = ({ type }) => {
  const intl = useIntl();
  const { tooltipInfoIcon, tooltipInfoText } = useStyles();

  return (
    <Box display="flex" alignItems="center" p={1}>
      <ExclamationIcon className={tooltipInfoIcon} />
      <Typography className={tooltipInfoText}>{AppTooltips(intl)[type]}</Typography>
    </Box>
  );
};
