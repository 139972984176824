import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  container: {
    display: "grid",
    gridTemplateColumns: "3fr 1fr 1fr",
    gridTemplateRows: "2fr 2fr 3fr",
    alignItems: "center",

    padding: "0 24px",
    marginBottom: "54px",
  },
  titleColumn: {
    gridColumn: "2 / 3",

    fontSize: "15px",
    fontWeight: 400,
    color: "#82858B",
    textAlign: "right",
  },
  valueColumn: {
    gridColumn: "3 / 4",

    fontSize: "18px",
    fontWeight: 400,
    color: "#1E1E1E",
    textAlign: "right",
  },
  total: {
    fontWeight: "700",
  },
}));
