import { FC, ChangeEvent, useLayoutEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Grid, Tab, Tabs, Tooltip } from "@mui/material";
import { useIntl } from "react-intl";

import useStyles from "./styles";

import { useFeatureFlagValue } from "../../hooks";
import { FeatureTooltip, featureTooltipStyleProps } from "../Tooltips";
import { tabsItems } from "../../utils/yourRewardsTabsItems";
import { CloneProps } from "../../utils/functions";
import ComingSoonLabel from "../Utils/ComingSoonLabel";

import { FeatureFlagEnum } from "../../types/contexts";
import { YourRewardsTabsIds } from "../../types/yourRewards";
import { TooltipType } from "../../types/tooltips";
import { useBusinessProfile } from "../../hooks/useBusinessProfile.hook";
import { NoMembershipPlan } from "../Layout/NoMembershipPlan";

import { isItemDisabled } from "../Utils/helpers";

const Rewards: FC = () => {
  const { rootContainer } = useStyles();
  const history = useHistory();
  const intl = useIntl();
  const [tabValue, setTabValue] = useState(0);
  const [currentScene, setCurrentScene] = useState<JSX.Element>(tabsItems(intl)[0].renderScene());
  const [user, , isSubscribed] = useBusinessProfile();
  const isExperiencesV2Enabled = useFeatureFlagValue(FeatureFlagEnum.EXPERIENCES_V2_ENABLED);
  const isJackpotV2Enabled = useFeatureFlagValue(FeatureFlagEnum.JACKPOT_V2_ENABLED);
  const isPromotersV2Enabled = useFeatureFlagValue(FeatureFlagEnum.PROMOTERS_V2_ENABLED);

  const isDisabledTab = (id: string): boolean => {
    return (
      (id === YourRewardsTabsIds.EXPERIENCES && !isExperiencesV2Enabled) ||
      (id === YourRewardsTabsIds.JACKPOT && !isJackpotV2Enabled) ||
      (id === YourRewardsTabsIds.PROMOTERS && !isPromotersV2Enabled) ||
      id === YourRewardsTabsIds.CAMPAIGNS
    );
  };

  const handleChange = (event: ChangeEvent<{ hash?: string }>, newValue: number) => {
    setCurrentScene(tabsItems(intl)[newValue].renderScene());
    setTabValue(newValue);
    if (!event.target.hash) {
      return;
    }
    history.push(`${history.location.pathname}${event.target.hash}`);
  };

  const renderIcon = (id: string) => {
    return isDisabledTab(id) ? <ComingSoonLabel /> : <Grid className="emptyTooltipIcon" />;
  };

  useLayoutEffect(() => {
    const currentIndex = tabsItems(intl).findIndex((tab) => tab.hash === history.location.hash.slice(1));
    if (currentIndex !== -1) {
      setTabValue(currentIndex);
      setCurrentScene(tabsItems(intl)[currentIndex].renderScene());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container item direction="column" pt={{ xs: isSubscribed ? "28px" : "0px" }} className={rootContainer}>
      <NoMembershipPlan isSubscribed={isSubscribed}>
        <Tabs value={tabValue} onChange={handleChange} aria-label="nav tabs" className="tabsContainer">
          {tabsItems(intl).map(({ label, hash, id }) =>
             isDisabledTab(id) ? (
              <CloneProps key={hash}>
                {(tabsProps) => (
                  <Tooltip
                    arrow
                    title={
                      <FeatureTooltip
                        type={ TooltipType.COMING_SOON}
                      />
                    }
                    placement="bottom"
                    componentsProps={featureTooltipStyleProps}
                  >
                    <div>
                      <Tab
                        {...tabsProps}
                        icon={renderIcon(id)}
                        label={label}
                        component={Link}
                        to={{ hash }}
                        id={id}
                        disabled
                      />
                    </div>
                  </Tooltip>
                )}
              </CloneProps>
            ) : (
              <Tab
                key={hash}
                icon={renderIcon(id)}
                label={label}
                component={Link}
                to={{ hash }}
                id={id}
                disableRipple={!isSubscribed}
              />
            ),
          )}
        </Tabs>

        {currentScene}
      </NoMembershipPlan>
    </Grid>
  );
};

export default Rewards;
