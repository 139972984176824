import { FC } from "react";
import { Box } from "@mui/material";
import PsychographicsCard from "../PsychographicsCard";
import AnalyticsChart from "../../AnalyticsChart";
import { getOption } from "./helpers";

const values = {
  Other: 160,
  Male: 580,
  Female: 230,
};

const Genre: FC = () => {
  return (
    <PsychographicsCard headerTitle="analytics.rules.psychographics_card_titles.genre">
      <Box minWidth="280px">
        <AnalyticsChart values={values} getOptions={getOption} />
      </Box>
    </PsychographicsCard>
  );
};

export default Genre;
