import React from "react";
import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import Icon from "../../../../utils/icon";

function calculateGraphPercentage(total: number | null | undefined, value: number | null | undefined) {
  if (value === 0 || value === total) {
    return 100;
  }

  if (total && value) {
    return Math.round((value * 100) / total);
  }

  return 0;
}

export default function DayBar({ day, weeklyData, index }) {
  const theme = useTheme();

  const getBGColor = (dayOfWeek, indexOfWeek) => {
    const count = weeklyData ? weeklyData[dayOfWeek] : 0;

    if (count <= 0) {
      return theme.palette.dashBoard?.lightYellow2;
    }
    return (indexOfWeek + 1) % 2 === 0 ? theme.palette.dashBoard?.darkPurple : theme.palette.dashBoard?.lightPurple;
  };

  return (
    <Grid item container key={day} flexDirection="column" alignContent="center" alignItems="center" width={35}>
      <Typography color={theme.palette.primary.dark} align="center" fontSize="12px">
        {weeklyData ? weeklyData[day] : 0}
      </Typography>
      <Grid
        container
        item
        height={calculateGraphPercentage(weeklyData?.total, weeklyData && weeklyData[day])}
        width={35}
        bgcolor={getBGColor(day, index)}
        borderRadius={1}
        marginBottom={1}
        marginTop={1}
        alignContent="center"
        justifyContent="center"
      >
        {(weeklyData ? weeklyData[day] : -1) <= 0 && (
          <Grid item>
            <Icon.CentorsSad width={20} />
          </Grid>
        )}
      </Grid>
      <Typography color={theme.palette.primary.dark} align="center" fontSize="10px">
        {day.substring(0, 3).toUpperCase()}
      </Typography>
    </Grid>
  );
}
