import { defineMessages, IntlShape } from "react-intl";

const messages = defineMessages({
  snackbarReceiptGeneratingTitle: {
    id: "billing_snackbar_receipt_generating_title",
    defaultMessage: "Generating your PDF",
  },
  snackbarReceiptGeneratingSubtitle: {
    id: "billing_snackbar_receipt_generating_subtitle",
    defaultMessage: "This process might take a few seconds",
  },
  snackbarReceiptGeneratedTitle: {
    id: "billing_snackbar_receipt_generated_title",
    defaultMessage: "Your PDF was",
  },
  snackbarReceiptGeneratedSubtitle: {
    id: "billing_snackbar_receipt_generated_subtitle",
    defaultMessage: "downloaded successfully",
  },
  snackbarReceiptGenerationFailedTitle: {
    id: "billing_snackbar_receipt_generation_failed_title",
    defaultMessage: "We had a problem generating your PDF",
  },
  snackbarReceiptGenerationFailedSubtitle: {
    id: "billing_snackbar_receipt_generation_failed_subtitle",
    defaultMessage: "You can try again or come back later",
  },
});

export const BillingTranslations = (intl: IntlShape) => {
  return {
    SNACKBAR_RECEIPT_GENERATING_TITLE: intl.formatMessage(messages.snackbarReceiptGeneratingTitle),
    SNACKBAR_RECEIPT_GENERATING_SUBTITLE: intl.formatMessage(messages.snackbarReceiptGeneratingSubtitle),
    SNACKBAR_RECEIPT_GENERATED_TITLE: intl.formatMessage(messages.snackbarReceiptGeneratedTitle),
    SNACKBAR_RECEIPT_GENERATED_SUBTITLE: intl.formatMessage(messages.snackbarReceiptGeneratedSubtitle),
    SNACKBAR_RECEIPT_GENERATION_FAILED_TITLE: intl.formatMessage(messages.snackbarReceiptGenerationFailedTitle),
    SNACKBAR_RECEIPT_GENERATION_FAILED_SUBTITLE: intl.formatMessage(messages.snackbarReceiptGenerationFailedSubtitle),
  };
};
