import { defineMessages, IntlShape } from "react-intl";

const messages = defineMessages({
  success: {
    id: "btn_text_success",
    defaultMessage: "Success",
  },
  pay: {
    id: "btn_text_pay",
    defaultMessage: "Pay",
  },
  enrolled: {
    id: "btn_text_enrolled",
    defaultMessage: "Enrolled",
  },
  enroll: {
    id: "btn_text_enroll",
    defaultMessage: "Enroll",
  },
  myMembership: {
    id: "btn_text_my_membership",
    defaultMessage: "My Membership",
  },
  comingSoon: {
    id: "btn_text_coming_soon",
    defaultMessage: "Coming Soon",
  },
  checkout: {
    id: "btn_text_checkout",
    defaultMessage: "Checkout",
  },
  upgradeMembership: {
    id: "btn_text_upgrade_membership",
    defaultMessage: "Upgrade membership",
  },
  saveChanges: {
    id: "btn_text_save_changes",
    defaultMessage: "Save changes",
  },
  cancelMembership: {
    id: "btn_text_cancel_membership",
    defaultMessage: "Cancel membership",
  },
  monthlyMembershipFee: {
    id: "menu_memberships_enroll_monthly_membership_fee",
    defaultMessage: "Monthly Membership Fee",
  },
  minimumTykRewardDeposit: {
    id: "menu_memberships_enroll_minimum_tyk_reward_deposit",
    defaultMessage: "Minimum Tyk Reward Deposit",
  },
});

const ButtonText = (intl: IntlShape) => {
  return {
    SUCCESS: intl.formatMessage(messages.success),
    PAY: intl.formatMessage(messages.pay),
    ENROLLED: intl.formatMessage(messages.enrolled),
    ENROLL: intl.formatMessage(messages.enroll),
    CHECKOUT: intl.formatMessage(messages.checkout),
    UPGRADE_MEMBERSHIP: intl.formatMessage(messages.upgradeMembership),
    SAVE_CHANGES: intl.formatMessage(messages.saveChanges),
    CANCEL_MEMBERSHIP: intl.formatMessage(messages.cancelMembership),
    MY_MEMBERSHIP: intl.formatMessage(messages.myMembership),
    COMING_SOON: intl.formatMessage(messages.comingSoon),
  };
};

const OrderSummaryDetailsText = (intl: IntlShape) => {
  return {
    MONTHLY_MEMBERSHIP_FEE: intl.formatMessage(messages.monthlyMembershipFee),
    MINIMUM_TYK_REWARD_DEPOSIT: intl.formatMessage(messages.minimumTykRewardDeposit),
  };
};

interface PaymentSummaryType {
  subTotal: number;
  taxesTotal: number;
  totalAmount: number;
}

export { ButtonText, OrderSummaryDetailsText };
export type { PaymentSummaryType };
