import { graphql } from "../../../gql";

export const setPaymentMethodAsDefaultMutation = graphql(`
  mutation setPaymentMethodAsDefaultMutation($userId: String!, $userPaymentMethodId: String!) {
    setPaymentMethodAsDefault(userId: $userId, userPaymentMethodId: $userPaymentMethodId) {
      success
      message
    }
  }
`);
