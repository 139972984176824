import { graphql } from "../../../../../gql";

export const createDepositCartMutation = graphql(`
  mutation CreateDepositCart($businessId: String!, $addressId: String!, $amountInCents: Int!) {
    createDepositCart(input: { businessId: $businessId, addressId: $addressId, amountInCents: $amountInCents }) {
      payload {
        id
      }
    }
  }
`);
