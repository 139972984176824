import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    wrap: {
      padding: "16px 24px",

      [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
        padding: "8px 12px",
      },

      "& .headerWrapper": {
        width: "100%",
        marginBottom: "20px",
        paddingBottom: "16px",
        borderBottom: "1px solid #CDCCD7",

        [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
          paddingBottom: "10px",
          marginBottom: "10px",
        },
      },
    },
  };
});

export default useStyles;
