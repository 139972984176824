import { Redirect, useHistory } from "react-router-dom";
import { Box, Stack, Typography, Button } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import { FormattedMessage } from "react-intl";
import theme from "../../../../theme";
import SuccessImg from "../../../../assets/images/technology/hands-phone.png";
import useStyles from "./styles";

interface IQrPayload {
  id: string;
  url: string;
  name: string;
}

const QrSuccess = () => {
  const { qrSuccessWrap } = useStyles();
  const history = useHistory();

  const qr = history.location.state as IQrPayload;

  const handleClick = () => {
    history.push({
      pathname: `/Technology`,
    });
  };

  if (!qr) {
    return <Redirect to="/Technology" />;
  }

  return (
    <Box className={qrSuccessWrap}>
      <Stack direction={{ xs: "column", lg: "row" }} alignItems={{ xs: "center", lg: "start" }} spacing={6}>
        <img src={SuccessImg} alt="qr-success" />
        <Stack
          alignItems={{ xs: "center", lg: "start" }}
          spacing={4}
          sx={{
            maxWidth: "310px",
          }}
        >
          <QRCodeSVG size={75} level="L" value={qr.url} />
          <Typography fontSize="1.375rem" fontWeight="700" color={theme.palette.primary.dark}>
            <FormattedMessage id="technology.qr_codes.congratulations" />
          </Typography>
          <Typography fontSize="1.125rem" color={theme.palette.primary.dark}>
            <FormattedMessage id="technology.qr_codes.continue_boosting_your_business" />
          </Typography>
          <Box width="159px">
            <Button variant="contained" onClick={handleClick}>
              <Typography component="span" fontSize="0.875rem" fontWeight="700" textTransform="none">
                <FormattedMessage id="technology.qr_codes.go_to_qr" />
              </Typography>
            </Button>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};

export default QrSuccess;
