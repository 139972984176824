import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    card: {
      boxShadow: `0px 4px 6px 0px ${theme.palette.campaigns.shadowGrey}`,
      "& .cardContent": {
        padding: `0 ${theme.spacing(3)} 36px ${theme.spacing(2)}`,
      },
      "& .cardActions": {
        justifyContent: "flex-end",
        padding: `${theme.spacing(1)} ${theme.spacing(3)} 0 ${theme.spacing(2)}`,
      },
      "& .icon-button": {
        padding: "4px",
      },
      "& .cardHeader": {
        padding: `0 ${theme.spacing(3)} ${theme.spacing(1)}`,
      },
      "& .MuiCardHeader-title": {
        fontSize: "0.875rem",
        color: theme.palette.technology.black,
        fontWeight: 600,
      },
      "& .MuiCardHeader-subheader": {
        fontSize: "0.75rem",
        color: `${theme.palette.purple}`,
      },
      "& .linkedTo": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      },
      "& .linkedToText": {
        fontSize: "0.675rem",
        color: `${theme.palette.purple}`,
      },
      "& .eventInfo": {
        display: "flex",
        alignItems: "center",
      },
      "& .eventImg": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexShrink: 0,
        height: "67px",
        width: "83px",
        objectFit: "cover",
        borderRadius: "10px",
      },
      "& .empty": {
        border: `1px solid ${theme.palette.technology.lightGrey2}`,
      },
      "& .MuiTypography-root": {
        fontFamily: `${theme.typography.fontFamily}`,
      },
    },
  };
});

export default useStyles;
