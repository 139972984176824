import { useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Box, Grid, Typography, Button, Stack, Divider, Pagination } from "@mui/material";
import { useQuery } from "@apollo/client";
import { FormattedMessage } from "react-intl";
import { GET_ALL_QR_CODES } from "../../../apollo/queries";
import QrCodeCard from "./QrCodeCard";
import useStyles from "./styles";
import theme from "../../../theme";
import { usePagination } from "./hooks";
import AnimatedLoader from "../../AnimatedLoader";
import { IQrCode, QrType } from "../types";

const QrCodeCounter = ({ count }: { count: number }) => {
  const countFormatted = String(count).length === 1 ? `0${count}` : count;

  return (
    <Box>
      <Typography mb={theme.spacing(2)} color={theme.palette.purple} fontWeight={theme.typography.fontWeightBold}>
        <FormattedMessage id="technology.qr_codes.title" />
      </Typography>
      <Typography fontWeight={theme.typography.fontWeightBold} fontSize="1.75rem">
        {countFormatted}
      </Typography>
      <Typography color={theme.palette.purple} fontWeight={theme.typography.fontWeightMedium} fontSize="1rem">
        <FormattedMessage id="technology.qr_codes.total_actives" />
      </Typography>
    </Box>
  );
};

const QrCodes = () => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { qrCodesWrap } = useStyles();

  const [qrCodes, setQrCodes] = useState<IQrCode[]>([]);

  const { loading: qrCodesLoading } = useQuery(GET_ALL_QR_CODES, {
    onCompleted: ({ getAllQrCodes }) => {
      setQrCodes(getAllQrCodes);
    },
    fetchPolicy: "no-cache",
  });

  const PER_PAGE = 4;
  const { getCurrentPageData, jump, currentPage, maxPage } = usePagination({
    data: qrCodes,
    itemsPerPage: PER_PAGE,
  });

  const handlePageChange = (_event: any, newPage: number) => {
    jump(newPage);
  };

  const handleClick = () => {
    history.push(`${path}/Create-Qr`);
  };

  return (
    <Box className={qrCodesWrap}>
      <Grid container item xs={9} justifyContent="flex-end" className="buttonArea">
        <Button variant="contained" sx={{ minWidth: "156px", marginBottom: "20px" }} onClick={handleClick}>
          <Typography
            component="span"
            sx={{
              fontSize: "0.875rem",
              fontWeight: 700,
            }}
          >
            <FormattedMessage id="technology.qr_codes.create" />
          </Typography>
        </Button>
      </Grid>

      <Box className="technologyContent" display="flex">
        {qrCodesLoading ? (
          <Box display="flex" justifyContent="center" flex={1}>
            <AnimatedLoader width={80} height={80} />
          </Box>
        ) : (
          <>
            <Box>
              <Stack spacing={3}>
                <Typography>
                  <FormattedMessage id="technology.qr_codes.your_qr_codes" />
                </Typography>
                {getCurrentPageData().map((qr) => (
                  <QrCodeCard
                    id={qr.id}
                    qrName={qr.name}
                    url={qr.url}
                    title={qr.tykchaseCampaign.title}
                    endDate={qr.tykchaseCampaign.endDate}
                    imgSrc={qr.tykchaseCampaign.defaultCoverPhoto}
                    key={qr.id}
                  />
                ))}
                {qrCodes.length > PER_PAGE && (
                  <Pagination count={maxPage} page={currentPage} onChange={handlePageChange} />
                )}
              </Stack>
            </Box>
            <Box display="flex" pt={theme.spacing(3)} className="total">
              <Box sx={{ display: "flex" }}>
                <Divider orientation="vertical" className="devider" />
                <Box pt={theme.spacing(3)}>
                  <Box position="fixed">
                    <QrCodeCounter count={qrCodes.length} />
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default QrCodes;
