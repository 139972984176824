import { defineMessages, IntlShape } from "react-intl";
import { TykBroker } from "../../../../generated/types";

const messages = defineMessages({
  modalTitle: {
    id: "menu_memberships_enroll_broker_modal_title",
    defaultMessage: "Select your TykBroker",
  },
  selectButton: {
    id: "btn_text_select",
    defaultMessage: "Select",
  },
  cancelButton: {
    id: "btn_text_cancel",
    defaultMessage: "Cancel",
  },
  noMatchesFound: {
    id: "no_matches_found",
    defaultMessage: "No matches found",
  },
  placeholder: {
    id: "broker_selection_placeholder",
    defaultMessage: "Broker name or email",
  },
});

const BrokerSelectionModalText = (intl: IntlShape) => {
  return {
    TITLE: intl.formatMessage(messages.modalTitle),
    SELECT: intl.formatMessage(messages.selectButton),
    CANCEL: intl.formatMessage(messages.cancelButton),
    NO_MATCHES: intl.formatMessage(messages.noMatchesFound),
    PLACEHOLDER: intl.formatMessage(messages.placeholder),
  };
};

interface IBrokerSelectionModal {
  open: boolean;
  onClose: () => void;
  handleChange: (input: string) => void;
  handleInputChange: (input: string) => void;
  onConfirm: () => void;
  bokers: Partial<TykBroker>[];
  isBrokerSelected: boolean;
  isLoading: boolean;
}

export { BrokerSelectionModalText };
export type { IBrokerSelectionModal };
