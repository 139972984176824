import dayjs from "dayjs";
import { TimeFrame } from "../types";

const FORMAT_DATE = "YYYY-MM-DD";

const subtractMonths = (date, months) => {
  date.setMonth(date.getMonth() - months);

  return date;
};

const getFirstDayCurrentMonth = () => {
  const date = new Date();

  return new Date(date.getUTCFullYear(), date.getUTCMonth(), 1).toLocaleDateString("en-CA");
};
export const getHowManyDaysAgo = (months: number): number => {
  const date = new Date();
  const newDate = subtractMonths(new Date(), months);

  const differenceInMs = date.getTime() - newDate.getTime();

  const differenceInDays = Math.ceil(differenceInMs / (1000 * 3600 * 24));

  return differenceInDays;
};

export const getStartAndEndDates = (timeFrame: string) => {
  let start;

  let end = new Date().toISOString().split("T")[0];

  switch (timeFrame) {
    case TimeFrame.ThisMonth:
      start = getFirstDayCurrentMonth();
      break;
    case TimeFrame.LastThreeMonths:
      start = dayjs().subtract(getHowManyDaysAgo(3), "day").startOf("day").format(FORMAT_DATE);
      break;
    case TimeFrame.LastYear:
      start = dayjs().subtract(getHowManyDaysAgo(12), "day").startOf("day").format(FORMAT_DATE);
      break;
    default:
      start = "";
      end = "";
      break;
  }

  return { start, end };
};
