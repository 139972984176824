import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => {
  return {
    paymentsWrap: {
      marginTop: "0",
      padding: "20px 48px",
    },
  };
});
