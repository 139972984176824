import { FC } from "react";
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { OpenIcon } from "../../../utils/icon";
import Modal from "../../Modal";
import { Promotions } from "../types";
import theme from "../../../theme";
import useStyles from "./styles";

interface PromotionDetailsModalProps {
  isOpen: boolean;
  onClose: VoidFunction;
  promotion: Promotions | null;
}

const PromotionDetailsModal: FC<PromotionDetailsModalProps> = ({ isOpen, onClose, promotion }) => {
  const { detailsContainer } = useStyles();

  return (
    <Modal onClose={onClose} isOpen={isOpen} isClosable>
      <Grid container direction="column" className={detailsContainer}>
        <Grid container className="modalHeader">
          <Typography
            alignSelf="end"
            marginBottom="10px"
            fontFamily={theme.typography.fontFamily}
            fontSize="0.75rem"
            color={theme.palette.primary.dark}
          >
            <FormattedMessage id="marketing.promotion_details_modal.title" />
          </Typography>

          <Grid container display="flex" flexDirection="row" justifyContent="space-between">
            <Grid item sm={5} display="flex" flexDirection="row" alignItems="center">
              <Box className="imageWrapper" sx={{ width: "48px", height: "46px" }}>
                <img src={promotion?.promotionImage} alt="" />
              </Box>

              <Box className="headerTitleContainer">
                <Typography
                  fontFamily={theme.typography.fontFamily}
                  fontSize="1.125rem"
                  color={theme.palette.primary.dark}
                  className="headerTitle"
                >
                  {promotion?.promotionName}
                </Typography>

                <Typography fontFamily={theme.typography.fontFamily} className="extra">
                  {promotion?.type}
                </Typography>
              </Box>
            </Grid>

            <Grid item sm={7}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="right">
                        <FormattedMessage id="marketing.marketing_table.start_end" />
                      </TableCell>

                      <TableCell align="right">
                        <FormattedMessage id="marketing.marketing_table.spent_budget" />
                      </TableCell>

                      <TableCell align="right">
                        <FormattedMessage id="marketing.marketing_table.ads" />
                      </TableCell>

                      <TableCell align="right">
                        <FormattedMessage id="marketing.marketing_table.views" />
                      </TableCell>

                      <TableCell align="right">
                        <FormattedMessage id="marketing.marketing_table.clicks" />
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    <TableRow key={promotion?.id}>
                      <TableCell align="right">{`${promotion?.startDate} / ${promotion?.endDate}`}</TableCell>

                      <TableCell align="right">{`$${promotion?.spent} / ${promotion?.budget}`}</TableCell>

                      <TableCell align="right">{promotion?.ads}</TableCell>

                      <TableCell align="right">{promotion?.views?.toLocaleString()}</TableCell>

                      <TableCell align="right">{promotion?.clicks?.toLocaleString()}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>

        <Grid className="modalBody">
          <Grid display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center">
            <Button className="analyticsButton" startIcon={<OpenIcon fill={theme.palette.marketing.blue} />}>
              <FormattedMessage id="marketing.promotion_details_modal.analytics" />
            </Button>
          </Grid>

          <Grid>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left" width="50px">
                      <FormattedMessage id="marketing.promotion_details_modal.type" />
                    </TableCell>

                    <TableCell align="left" width="68px">
                      <FormattedMessage id="marketing.marketing_table.preview" />
                    </TableCell>

                    <TableCell align="left">
                      <FormattedMessage id="marketing.marketing_table.promotion_name" />
                    </TableCell>

                    <TableCell align="right">
                      <FormattedMessage id="marketing.marketing_table.start_end" />
                    </TableCell>

                    <TableCell align="right">
                      <FormattedMessage id="marketing.marketing_table.spent_budget" />
                    </TableCell>

                    <TableCell align="right">
                      <FormattedMessage id="marketing.marketing_table.views" />
                    </TableCell>

                    <TableCell align="right">
                      <FormattedMessage id="marketing.marketing_table.clicks" />
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {promotion?.promotions?.length ? (
                    promotion.promotions.map((dataRow) => (
                      <TableRow key={dataRow?.id}>
                        <TableCell align="left" width="50px">
                          <Box className="typeWrapper">{dataRow?.type?.slice(0, 1)}</Box>
                        </TableCell>

                        <TableCell align="left" width="68px">
                          <Box className="previewWrapper">
                            <img src={dataRow?.promotionImage} alt="" className="previewImage" />
                          </Box>
                        </TableCell>

                        <TableCell align="left">{dataRow?.promotionName}</TableCell>

                        <TableCell align="right">{`${dataRow?.startDate} / ${dataRow?.endDate}`}</TableCell>

                        <TableCell align="right">{`$${dataRow?.spent} / ${dataRow?.budget}`}</TableCell>

                        <TableCell align="right">{dataRow?.views?.toLocaleString()}</TableCell>

                        <TableCell align="right">{dataRow?.clicks?.toLocaleString()}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <FormattedMessage id="no_data" />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default PromotionDetailsModal;
