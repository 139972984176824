import useStyles from "./styles";
import FooterSwitchs from "../Switchs";
import FooterTypes from "./types";

const Footer = ({
  switches = false,
  multipleRewardCountFieldProps,
  multipleRewardCountError,
  allowMultipleRewardProps,
  isSubmitting,
}: FooterTypes) => {
  const { footerWrap } = useStyles();

  return (
    <div className={footerWrap}>
      <div className="leftWrap">
        {switches && (
          <FooterSwitchs
            multipleRewardCountFieldProps={multipleRewardCountFieldProps}
            multipleRewardCountError={multipleRewardCountError}
            allowMultipleRewardProps={allowMultipleRewardProps}
            isSubmitting={isSubmitting}
          />
        )}
      </div>
    </div>
  );
};

export default Footer;
