import { graphql } from "../../../gql";

export const getCartQuery = graphql(`
  query GetCart($cartId: String!) {
    getCart(input: { id: $cartId }) {
      id
      ownerId
      clientSecret
      status
      taxInCents
      lineItems {
        label
        priceInCents
        renewalPeriod
        quantity
      }
    }
  }
`);
