import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Grid,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/DeleteForeverOutlined";
import { useCallback } from "react";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { FormattedMessage } from "react-intl";
import { useMutation } from "@apollo/client";
import { useUser } from "@stagewood/unified-login-library";
import { useBooleanState } from "../../../hooks/useBooleanState.hook";
import { ButtonsStyle } from "../../Buttons/styles";
import { RemovePaymentMethodModalProps } from "./interfaces/remove-payment-method-modal.interface";
import tykWoodIcon from "../../../assets/icons/paymentIcons/tykwoodIcon.svg";
import { removePaymentMethodMutation } from "./graphql/remove-payment-method.mutation";
import { getPaymentMethodsQuery } from "../../Checkout/PaymentMethods/graphql/GetPaymentMethods.query";

export function RemovePaymentMethodModal(props: RemovePaymentMethodModalProps): JSX.Element {
  const [removePaymentMethod] = useMutation(removePaymentMethodMutation);
  const user = useUser();

  const {
    value: isRemovingPaymentMethod,
    setTrue: setPaymentMethodRemoving,
    setFalse: setPaymentMethodNotRemoving,
  } = useBooleanState();

  const {
    value: paymentMethodRemovedSuccessfully,
    setTrue: setPaymentMethodRemovedSuccessfully,
    setFalse: setPaymentMethodRemovalFailed,
  } = useBooleanState();

  const { handleClose } = props;

  const handlePaymentMethodRemoved = useCallback(() => {
    setPaymentMethodRemovedSuccessfully();

    setTimeout(() => {
      handleClose!({}, "escapeKeyDown");
      setPaymentMethodNotRemoving();
      setPaymentMethodRemovalFailed();
    }, 2000);
  }, [handleClose, setPaymentMethodRemovedSuccessfully, setPaymentMethodRemovalFailed, setPaymentMethodNotRemoving]);

  const handleRemovePaymentMethod = useCallback(() => {
    if (!paymentMethodRemovedSuccessfully) {
      setPaymentMethodRemoving();

      removePaymentMethod({
        variables: {
          userPaymentMethodId: props.paymentItem?.userPaymentMethodId,
          businessId: user?.id,
        },
        refetchQueries: [getPaymentMethodsQuery],
        awaitRefetchQueries: true,
      })
        .then((paymentMethod) => {
          handlePaymentMethodRemoved();
        })
        .catch(() => {
          setPaymentMethodRemovalFailed();
        })
        .finally(() => {
          setPaymentMethodNotRemoving();
        });
    }
  }, [
    paymentMethodRemovedSuccessfully,
    setPaymentMethodRemoving,
    removePaymentMethod,
    props.paymentItem?.userPaymentMethodId,
    user?.id,
    handlePaymentMethodRemoved,
    setPaymentMethodRemovalFailed,
    setPaymentMethodNotRemoving,
  ]);

  const closeModal = () => {
    if (props.handleClose) {
      props.handleClose({}, "escapeKeyDown");
    }
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="remove-new-payment-method-title"
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle sx={{ marginTop: 3, marginLeft: 3 }} id="remove-new-payment-method-title">
        <CloseIcon
          onClick={closeModal}
          sx={{ position: "absolute", top: "6px", right: "6px", color: "#797882", fontSize: "20px" }}
        />
        <Grid sx={{ display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
          <Typography fontSize={14}>
            <FormattedMessage id="remove-payment-method.modal.title" />
          </Typography>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              color: "#34305D",
              fontSize: 8,
            }}
          >
            <FormattedMessage id="remove-payment-method.modal.secure_message" />
            <img src={tykWoodIcon} alt="tyk_wood_logo" />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <>
          <Typography sx={{ width: "100%", textAlign: "right", alignSelf: "flex-end" }}>
            <FormattedMessage id="remove-payment-method.modal.remove_prompt_1" />{" "}
            {props.paymentItem?.cardNumber.substring(
              props.paymentItem?.cardNumber.length - 5,
              props.paymentItem?.cardNumber.length,
            )}{" "}
            <FormattedMessage id="remove-payment-method.modal.remove_prompt_2" />
          </Typography>
        </>
      </DialogContent>
      <DialogActions sx={{ marginRight: 2, marginBottom: 2 }}>
        <Button
          sx={{
            ...ButtonsStyle(null).cancelPaymentBtn,
            border: "2px solid #8986AD",
            ":hover": { border: "2px solid #8986AD", fontWeight: "900" },
          }}
          onClick={closeModal}
        >
          <Typography sx={{ ":hover": { fontWeight: "900" } }} fontSize={12} color="#8986AD" textTransform="initial">
            <FormattedMessage id="remove-payment-method.modal.submit-button.close-text" />
          </Typography>
        </Button>
        <Button
          onClick={handleRemovePaymentMethod}
          sx={{ ...ButtonsStyle(null).removePaymentBtn, ":hover": { ...ButtonsStyle(null).removePaymentBtn } }}
          startIcon={
            !isRemovingPaymentMethod && paymentMethodRemovedSuccessfully ? (
              <CheckIcon sx={{ color: ButtonsStyle(null).removePaymentBtn.color }} />
            ) : (
              <DeleteIcon sx={{ color: ButtonsStyle(null).removePaymentBtn.color }} />
            )
          }
        >
          {isRemovingPaymentMethod && (
            <CircularProgress sx={{ color: ButtonsStyle(null).removePaymentBtn.color }} size={12} />
          )}
          {!isRemovingPaymentMethod && paymentMethodRemovedSuccessfully && (
            <Typography fontSize={12} color={ButtonsStyle(null).removePaymentBtn.color} textTransform="initial">
              <FormattedMessage id="remove-payment-method.modal.submit-button.success-text" />
            </Typography>
          )}
          {!isRemovingPaymentMethod && !paymentMethodRemovedSuccessfully && (
            <Typography fontSize={12} color={ButtonsStyle(null).removePaymentBtn.color}>
              <FormattedMessage id="remove-payment-method.modal.submit-button.regular-text" />
            </Typography>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
