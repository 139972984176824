import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { getPaymentMethodsQuery } from "./graphql/GetPaymentMethods.query";
import { NEW_CARD_TOKEN } from "./PaymentMethods.consts";
import { SelectItem } from "./PaymentMethodsSelect";

const CART_NUMBER_PREFIX = "**** **** ****";

export const usePaymentMethodOptions = (userId: string): SelectItem[] => {
  const { formatMessage } = useIntl();
  const { data } = useQuery(getPaymentMethodsQuery, {
    variables: { userId },
  });

  return useMemo(
    () => [
      { value: NEW_CARD_TOKEN, label: formatMessage({ id: "new_payment_method_label" }) },
      ...(data?.getActivePaymentMethods?.map((paymentMethod) => ({
        value: paymentMethod?.token ?? "",
        label: `${CART_NUMBER_PREFIX} ${paymentMethod?.lastFour ?? ""}`,
      })) ?? []),
    ],
    [formatMessage, data],
  );
};
