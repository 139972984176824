import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => {
  return {
    wrap: {
      display: "flex",
      flexDirection: "column",
      alignItems: "end",
      position: "relative",

      "& .row": {
        display: "flex",
        alignItems: "center",
        color: "#34305D",

        "& svg": {
          marginRight: "10px",
        },
      },

      "& .MuiTypography-root": {
        "&.enabled": {
          color: "#4262FF",
          textDecoration: "underline",
          cursor: "pointer",
        },

        "&.disabled": {
          opacity: "0.5",
          cursor: "default",
        },
      },
    },
  };
});

export default useStyles;
