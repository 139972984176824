import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    wrap: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "20px",

      [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
        flexDirection: "column",
      },

      "& .left": {
        marginRight: "60px",

        "& .MuiFormControl-root": {
          marginTop: "2px",
        },

        "& .MuiInputLabel-root": {
          fontSize: "12px",
          "&.MuiFormLabel-filled": {
            color: "transparent !important",
          },

          "&.Mui-focused": {
            marginTop: "4px",
            color: theme.palette.text.primary,
          },
        },

        "& .MuiOutlinedInput-root": {
          height: "33px",
          width: "240px",
          margin: "6px 0",

          "& .MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${theme.palette.diagram?.grey} !important`,
          },
        },
      },

      "& .right": {
        position: "relative",
        [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
          alignSelf: "end",
        },
        "& .purchaseInput": {
          display: "flex",
          marginTop: "22px",

          "& .input": {
            height: "32px",
            maxWidth: "54px",
            padding: "0 5px",
            marginLeft: "5px",
            border: "1px solid #CACACA",
            borderRadius: "4px",
            " & input, input::-webkit-input-placeholder ": {
              color: theme.palette.diagram?.blue,
              textAlign: "center",
            },
          },

          "& p": {
            display: "flex",
            alignItems: "center",
          },
        },
      },
    },
  };
});

export default useStyles;
