import { graphql } from "../../../gql";

export const GetRewardRulesDashboardInfoQuery = graphql(`
  query GetRewardRulesDashboardInfo($userId: String!) {
    getRewardRulesDashboardInfo(userId: $userId) {
      status
      message
      errorMessageId
      payload {
        rule {
          deactivationDate
          rule {
            id
            ruleName
            title
          }
          status {
            id
            title
          }
        }
        centorsDistributed
      }
    }
  }
`);
