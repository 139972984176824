import { FC } from "react";
import { Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { CheckCircleOutlined, HighlightOff } from "@mui/icons-material";
import theme from "../../../../theme";
import { RuleCategories } from "../../constants";
import { MembershipRule } from "../types";
import useStyles from "../styles";

interface RulesListProps {
  membershipRules: MembershipRule[];
  allRules: MembershipRule[];
}

const RulesList: FC<RulesListProps> = ({ membershipRules, allRules }) => {
  const { listContainer, listItems, listText } = useStyles();
  const specialActiveCampaignsInternalId = 17;
  const renderIcon = (rule) => {
    const ruleIndex = membershipRules.findIndex((currentRule) => currentRule.id === rule.id);
    return ruleIndex >= 0 ? (
      <CheckCircleOutlined sx={{ color: "#19C598" }} />
    ) : (
      <HighlightOff sx={{ color: "#D3202F" }} />
    );
  };

  return (
    <>
      {Object.entries(allRules)
        .sort((categoryRulesA, categoryRulesB) => RuleCategories[categoryRulesA[0]] - RuleCategories[categoryRulesB[0]])
        .map((categoryRules: any) => {
          if (!categoryRules.length) {
            return null;
          }
          return (
            <Grid item xs={12} key={categoryRules[0]} sx={{ marginTop: "1rem" }}>
              <Typography marginBottom="0.5rem" fontFamily={theme.typography.fontFamily}>
                {categoryRules[0]}
              </Typography>

              <Divider />

              <List className={listContainer} sx={{ padding: "0.5rem 0" }}>
                {categoryRules[1].map((rule: MembershipRule) => {
                  const monthlyAllowance = 1;
                  const title =
                    rule.internalId === specialActiveCampaignsInternalId
                      ? `${rule.title} (Monthly Allowance - ${monthlyAllowance})`
                      : rule.title;
                  return (
                    <ListItem className={listItems} key={rule.id}>
                      <ListItemIcon sx={{ minWidth: "2rem" }}>{renderIcon(rule)}</ListItemIcon>

                      <ListItemText
                        primary={title}
                        className={listText}
                        sx={{ fontFamily: theme.typography.fontFamily }}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          );
        })}
    </>
  );
};

export default RulesList;
