import { Dispatch, SetStateAction, useState, useEffect, ReactNode } from "react";
import { FormikProps } from "formik";
import { Box, Grid, IconButton, Tooltip } from "@mui/material";
import clsx from "clsx";

import Checkbox from "@mui/material/Checkbox";
import WarningIcon from "@mui/icons-material/Warning";
import DoneIcon from "@mui/icons-material/Done";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import useStyles from "./styles";
import DisableFeatureModal from "../../Modals/DisableFeatureModal";
import { FeatureTooltip, featureTooltipStyleProps } from "../../../Tooltips";

import { ReactComponent as EditSharpIcon } from "../../../../assets/icons/rewards/icon-awesome-edit.svg";

import RuleConfigurationInterface from "../../RewardRules/RuleConfigurationInterface";
import { ITykchaseGlobalConfig } from "../../../../types/tykchaseGlobalConfig";
import { Rewards } from "../../Modals/Diagram/types";
import { TooltipType } from "../../../../types/tooltips";
import { RuleName } from "../../../../generated/types";
import { IMembershipRule } from "../../../../types/membershipTypes";
import {
  CHILD_RULE_TOOLTIP_TYPE,
  DEACTIVATION_MODAL_MESSAGE_TYPE,
  DEACTIVATION_MODAL_TITLE_TYPE,
} from "./MembershipRuleRow.consts";
import { PageLoader } from "../../../PageLoader";
import { useBooleanState } from "../../../../hooks/useBooleanState.hook";
import { RewardRuleModal } from "../../RewardRuleModal";
import { RewardInputs, RewardInputsEnum } from "../../types";
import { RewardRuleModalHeaderControlsProps } from "../../RewardRuleModal/RewardRuleModalHeaderControls/RewardRuleModalHeaderControls.interfaces";

interface MembershipRuleRowProps {
  id: string;
  title: string;
  isActive?: boolean;
  isSelected?: boolean;
  reward?: number;
  ruleName: RuleName;
  backendData?: any;
  tykchaseGlobalConfig?: ITykchaseGlobalConfig;
  handlePublishModal: (ruleConfiguration: RuleConfigurationInterface) => Promise<void>;
  handleSaveModal: (ruleConfiguration: RuleConfigurationInterface) => Promise<void>;
  handleSelectToggle: (id: string) => void;
  handleActiveToggle: (id: string) => void;
  setRewardSplit: Dispatch<SetStateAction<Rewards>>;
  disabledChildrenRules: string[] | null;
  membershipRules: IMembershipRule[];
  isSubscribed: boolean;
  multipleTimeReward?: boolean;
  pickedRewardInputs: RewardInputsEnum[];
  rewardRuleModalHeaderControls?: (headerControls: RewardRuleModalHeaderControlsProps) => ReactNode;
  rewardRuleModalFooter?: (formik: FormikProps<RewardInputs>) => ReactNode;
  rewardRuleModalSubHeader?: (formik: FormikProps<RewardInputs>) => ReactNode;
}

const MembershipRuleRow = ({
  id,
  title,
  reward,
  backendData,
  isActive,
  isSelected,
  tykchaseGlobalConfig,
  ruleName,
  handlePublishModal,
  handleSaveModal,
  handleSelectToggle,
  handleActiveToggle,
  setRewardSplit,
  disabledChildrenRules,
  membershipRules,
  isSubscribed,
  multipleTimeReward,
  pickedRewardInputs,
  rewardRuleModalFooter,
  rewardRuleModalSubHeader,
  rewardRuleModalHeaderControls,
}: MembershipRuleRowProps) => {
  const { checkBox, membershipRuleRow } = useStyles();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isDisableFeatureModalOpen, setIsDisableFeatureModalOpen] = useState<boolean>(false);
  const [isChildRuleActivated, setIsChildRuleActivated] = useState<boolean>(false);
  const [childRuleTitle, setChildRuleTitle] = useState<string | null>(null);
  const { value: isRuleDisabling, setTrue: ruleDisabling, setFalse: ruleNotDisabling } = useBooleanState();

  const handleDisableFeature = async () => {
    ruleDisabling();
    setIsDisableFeatureModalOpen(false);
    await handleActiveToggle(id);
    ruleNotDisabling();
  };

  useEffect(() => {
    if (backendData && membershipRules) {
      const childRuleName = backendData.rule.childRule?.ruleName;

      if (childRuleName) {
        const childRule = membershipRules.find((rule) => rule.ruleName === childRuleName);
        if (childRule) {
          setIsChildRuleActivated(childRule.isActive);
          setChildRuleTitle(childRule.title);
        }
      }
    }
  }, [backendData, membershipRules]);

  const disabledChildRule = disabledChildrenRules?.includes(ruleName);

  const tooltipTitle = (disabledChildRule && <FeatureTooltip type={CHILD_RULE_TOOLTIP_TYPE[ruleName]} />) || "";

  const titleModal = isChildRuleActivated
    ? DEACTIVATION_MODAL_TITLE_TYPE.PARENT_RULE
    : DEACTIVATION_MODAL_TITLE_TYPE.DEFAULT;

  const messageModal = isChildRuleActivated
    ? DEACTIVATION_MODAL_MESSAGE_TYPE.PARENT_RULE
    : DEACTIVATION_MODAL_MESSAGE_TYPE.DEFAULT;

  return (
    <Tooltip title={tooltipTitle} componentsProps={featureTooltipStyleProps} arrow placement="bottom">
      <Box
        className={clsx(membershipRuleRow, {
          selected: isSelected,
          active: isActive,
          withInfo: reward,
        })}
        display="grid"
        gridTemplateColumns="50px 1fr"
      >
        <Checkbox
          onClick={() => {
            if (isSubscribed && !isRuleDisabling) handleSelectToggle(id);
          }}
          sx={{
            cursor: isSubscribed && !disabledChildRule  && !isRuleDisabling ? "pointer" : "default",
          }}
          disableRipple={!isSubscribed || isRuleDisabling}
          className={checkBox}
          checked={isSelected}
          disabled={disabledChildRule}
        />
        <Grid
          item
          display="grid"
          gridTemplateColumns="minmax(100px, 5fr) minmax(40px ,1fr) minmax(60px ,1fr)"
          className="tableBodyRow"
        >
          <Box
            className="ruleName"
            display="flex"
            alignItems="center"
            sx={{
              cursor: isSubscribed && !disabledChildRule  && !isRuleDisabling ? "pointer" : "default",
            }}
            onClick={() => {
              if (isSubscribed && !disabledChildRule  && !isRuleDisabling) {
                handleSelectToggle(id);
              }
            }}
          >
            {title}
          </Box>
          <Box
            data-testid="membershipRuleControl"
            className="ruleIcon"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <DoneIcon className="publishedDone" htmlColor="#4DA17E" />
            <WarningIcon className="publishedWarning" htmlColor="#CDCCD7" />
          </Box>
          <Box
            data-testid="membershipRuleControl"
            className="ruleActions"
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <div className={!isSubscribed  || disabledChildRule ? "actionBtn" : "actionBtn editBtn"}>
              <IconButton
                onClick={() => setIsModalOpen(true)}
                disabled={!isSubscribed  || disabledChildRule || isRuleDisabling}
              >
                <EditSharpIcon className="editIcon" />
              </IconButton>

              {isModalOpen && (
                <RewardRuleModal
                  title={title}
                  multipleTimeReward={multipleTimeReward}
                  isOpen={isModalOpen}
                  onClose={() => {
                    setIsModalOpen(false);
                  }}
                  setIsModalOpen={setIsModalOpen}
                  handlePublish={(ruleConfiguration) => {
                    return handlePublishModal({ ...ruleConfiguration, ruleId: id });
                  }}
                  handleSave={(ruleConfiguration) => {
                    return handleSaveModal({ ...ruleConfiguration, ruleId: id });
                  }}
                  handleCancel={() => null}
                  reward={reward}
                  backendData={backendData}
                  tykchaseGlobalConfig={tykchaseGlobalConfig}
                  setRewardSplit={setRewardSplit}
                  pickedRewardInputs={pickedRewardInputs}
                  rewardRuleModalFooter={rewardRuleModalFooter}
                  rewardRuleModalSubHeader={rewardRuleModalSubHeader}
                  rewardRuleModalHeaderControls={rewardRuleModalHeaderControls}
                />
              )}
            </div>
            <div aria-hidden="true" className="actionBtn deactivateRuleBtn">
              {!isRuleDisabling ? (
                <IconButton
                  onClick={() => {
                    setIsDisableFeatureModalOpen(true);
                  }}
                  disabled={!isSubscribed || !isActive}
                >
                  <PowerSettingsNewIcon />
                </IconButton>
              ) : (
                <PageLoader isInline />
              )}
            </div>

            <DisableFeatureModal
              isOpen={isDisableFeatureModalOpen}
              handleCloseModal={() => setIsDisableFeatureModalOpen(false)}
              handleContinueModal={handleDisableFeature}
              titleModal={titleModal}
              messageModal={messageModal}
              parentRule={title}
              childRule={childRuleTitle}
            />
          </Box>
        </Grid>
      </Box>
    </Tooltip>
  );
};

export default MembershipRuleRow;
