import { createTheme, Theme } from "@mui/material/styles";
import "./assets/fonts/font.css";

declare module "@mui/material/styles" {
  interface PaletteOptions {
    white: string;
    black: string;
    blue: string;
    purple: string;

    label?: {
      grey: string;
    };
    membership?: {
      business: string;
      enterprise: string;
      brands: string;
      enterpriseComingSoon: string;
      brandsComingSoon:string;
    };
    diagram?: {
      blue: string;
      purple: string;
      grey: string;
      black: string;
      white: string;
      dark: string;
    };
    analytics?: {
      white?: string;
      black?: string;
      lightGreen?: string;
      lightGreenAlt?: string;
      grey?: string;
      lightGrey?: string;
      darkPurple?: string;
      lightPurple?: string;
      purple?: string;
      darkYellow?: string;
      blue?: string;
      lightBlue?: string;
      green?: string;
      red?: string;
      lightRed?: string;
      pink?: string;
      chart?: string;
    };
    experiences: {
      dark: string;
      blue: string;
      darkPurple: string;
      lightPurple: string;
      grey: string;
      lightGrey: string;
      green: string;
    };
    marketing: {
      light: string;
      blue: string;
      lightBlue: string;
      darkPurple: string;
      lightPurple: string;
      grey: string;
      darkGrey: string;
      lightGrey: string;
      green: string;
    };
    ruleToggle: {
      blue: string;
      lightBlue: string;
      lightRed: string;
    };
    dashBoard?: {
      grey?: string;
      darkGrey?: string;
      borderGrey?: string;
      lightBlue?: string;
      blue?: string;
      darkBlue?: string;
      light?: string;
      white?: string;
      lightYellow?: string;
      lightYellow2?: string;
      lightPink?: string;
      purple?: string;
      lightCyan?: string;
      lightPurple?: string;
      darkPurple?: string;
    };
    campaigns: {
      dark: string;
      lightGreen: string;
      green: string;
      deepGreen: string;
      shadowGrey: string;
      lightGrey: string;
      borderGrey: string;
      darkYellow: string;
      yellow: string;
      darkPurple: string;
      purple: string;
      darkBlue: string;
      blue: string;
      darkRed: string;
      red: string;
      darkPink: string;
      pink: string;
    };
    technology: {
      black: string;
      blue: string;
      lightBlue: string;
      darkBlue: string;
      darkPurple: string;
      red: string;
      redError: string;
      lightRed: string;
      lightOrange: string;
      white: string;
      light: string;
      grey: string;
      grey2: string;
      grey3: string;
      lightGrey1: string;
      lightGrey2: string;
      lightGrey3: string;
      lightGrey4: string;
    };
    inputs: {
      border: string;
      active: string;
      error: string;
    };
  }

  interface Palette extends PaletteOptions {}
}

declare module "@mui/styles/defaultTheme" {
  interface DefaultTheme extends Theme {}
}

const theme = createTheme({
  palette: {
    white: "#FFFFFF",
    black: "#000000",
    blue: "#4262FF",
    purple: "#8986AD",
    primary: {
      light: "#F4F6FC",
      main: "#486EC2",
      dark: "#34305D",
    },
    secondary: {
      light: "#8E8E8E",
      main: "#4B4B4B",
      dark: "#fff",
    },
    text: {
      primary: "#6E6E6E",
      secondary: "#486EC2",
    },
    label: {
      grey: "#747474",
    },
    membership: {
      business: "#1B959A",
      enterprise: "#4E488C",
      brands: "#038C50",
      enterpriseComingSoon: "#6c6a8a",
      brandsComingSoon: "#628c7a",
    },
    diagram: {
      blue: "#4262FF",
      purple: "#8986AD",
      grey: "#CDCCD7",
      black: "#000",
      white: "#F2F2F2",
      dark: "#4B4B4B",
    },
    analytics: {
      white: "#FFFFFF",
      black: "#000000",
      lightGreen: "#4DA17E",
      lightGreenAlt: "#E2FCF1",
      grey: "#797882",
      lightGrey: "#F5F5F5",
      purple: "#8986AD",
      darkPurple: "#1E138E",
      lightPurple: "#4262FF",
      darkYellow: "#C68E07",
      blue: "#203ED4",
      lightBlue: "#C9D7F4",
      red: "#D3202F",
      green: "#4DA17E",
      pink: "#F4C9C9",
      chart: "#26C0C7",
      lightRed: "#EFD0D3",
    },
    experiences: {
      dark: "#12111A",
      blue: "#4262FF",
      darkPurple: "#34305D",
      lightPurple: "#8986AD",
      grey: "#797882",
      lightGrey: "#CDCCD7",
      green: "#4DA17E",
    },
    marketing: {
      light: "#FAFAFA",
      blue: "#0364FF",
      lightBlue: "#F1F4F9",
      darkPurple: "#34305D",
      lightPurple: "#8986AD",
      grey: "#CDCCD7",
      darkGrey: "#797882",
      lightGrey: "#E9E9E9",
      green: "#4DA17E",
    },
    ruleToggle: {
      blue: "#ACC5F3",
      lightBlue: "#DBEAFE",
      lightRed: "rgb(211 32 47 / 70%)",
    },

    dashBoard: {
      grey: "#4E4C5D",
      darkGrey: "#3A3A3A",
      borderGrey: "#9592B5",
      lightBlue: "#E1E4FA",
      blue: "#0364FF",
      darkBlue: "#A3BEFA",
      light: "#E9E9E9",
      white: "#EFFCEF",
      lightYellow: "#FFEFE2",
      lightYellow2: "#F8EBC5",
      lightPink: "#FADADA",
      purple: "#5D3057",
      lightCyan: "#E6F5F9",
      lightPurple: "#524AAD",
      darkPurple: "#34305D",
    },

    campaigns: {
      dark: "#3A3B4F",
      lightGreen: "#D6ECE4",
      green: "#9DC7B4",
      deepGreen: "#0EB771",
      shadowGrey: "#00000029",
      lightGrey: "#F6F6F6",
      borderGrey: "#D8D8D8",
      darkYellow: "#C7B39D",
      yellow: "#FFD6A5",
      darkPurple: "#8F87C7",
      purple: "#A5A5FF",
      darkBlue: "#8EB0B1",
      blue: "#9CF6FE",
      darkRed: "#B88A8A",
      red: "#FE9C9C",
      darkPink: "#6A4875",
      pink: "#E69CFE",
    },

    technology: {
      black: "#000000",
      blue: "#0364FF",
      lightBlue: "#0863FB",
      darkBlue: "#2546D4",
      darkPurple: "#34305D",
      red: "#D53F30",
      redError: "#D3202F",
      lightRed: "#FF0000",
      lightOrange: "#FFBE4030",
      white: "#FFFFFF",
      light: "#F2F2F2",
      grey: "#797882",
      grey2: "#747474",
      grey3: "#828282",
      lightGrey1: "#9592B5",
      lightGrey2: "#E9E9E9",
      lightGrey3: "#ADB5C0",
      lightGrey4: "#CDCCD7",
    },

    inputs: {
      border: "#D3D3D3",
      active: "#0364FF",
      error: "#D3202F",
    },
  },
  typography: {
    fontFamily: '"AdobeClean", "AdobeCleanSerif", "Poppins", "sans-serif"',
  },
});

export default theme;
