import { FC, useState, useEffect, ReactNode, MouseEvent, ChangeEvent, useMemo } from "react";
import { Grid, FormGroup, FormControlLabel, Checkbox, Alert } from "@mui/material";
import { useIntl } from "react-intl";
import { ApolloQueryResult, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";
import { useUser } from "@stagewood/unified-login-library";
import { useSnackbar } from "notistack";
import { stringify } from "qs";
import { debounce } from "lodash";
import { MainButton } from "../../Buttons";
import PaymentModal from "./PaymentModal";
import useStyles from "./styles";
import { createSubscriptionCartMutation } from "./graphql/CreateSubscriptionCart.mutation";
import { getOptions } from "../../Utils/Notifications";
import { BrokerSelectionModal } from "../MembershipSummary/BrokerSelectionModal";
import { GET_BROKERS_BY_MATCH } from "../../../apollo/queries";
import { MembershipCartPreview } from "./membership-cart-preview/membership-cart-preview.component";
import { RenewalPeriod } from "../../../gql/graphql";
import { AddressSelectionModal } from "../../AddressSelectionModal";
import { useAddressSelectionModal } from "../../AddressSelectionModal/useAddressSelectionModal.hook";
import { createLocationSetupCartMutation } from "../../Locations/graphql/CreateLocationSetupCart.mutation";
import { getDraftLocationSetupCartQuery } from "../../Locations/graphql/GetDraftLocationSetupCartQuery.query";
import { getDraftSubscriptionCartQuery } from "./graphql/getDraftSubscriptionCart.query";
import { GetBusinessProfileInfoQuery, GetCartQuery } from "../../../generated/types";

type IProps = {
  name: string;
  renewalPeriod: RenewalPeriod;
  membershipId: string;
  isEditMode?: boolean;
  enrolledModal?: boolean;
  locationId?: string;
};

const PaymentSummary: FC<IProps> = ({ renewalPeriod, membershipId, isEditMode, enrolledModal, locationId }) => {
  const { root, formGroup, termsLabel, termsAndConditionsLink } = useStyles();
  const [modalActive, setModalActive] = useState(false);
  const [brokerSelectionModalActive, setBrokerSelectionModalActive] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [brokerInput, setBrokerInput] = useState("");
  const [brokerId, setBrokerId] = useState("");
  const user = useUser();
  const [addressSelectionModalProps, showAddressSelectionModal] = useAddressSelectionModal();

  const [isTermsAndConditionsChecked, setIsTermsAndConditionsChecked] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const [getBrokers, { data: getBrokersData }] = useLazyQuery(GET_BROKERS_BY_MATCH);

  const debouncedGetBrokers = useMemo(() => debounce(getBrokers, 200), [getBrokers]);

  useEffect(() => {
    debouncedGetBrokers({
      variables: {
        inputText: brokerInput,
      },
    });
  }, [debouncedGetBrokers, brokerInput]);

  useEffect(() => {
    if (isTermsAndConditionsChecked && showAlert) {
      setShowAlert(false);
    }
  }, [isTermsAndConditionsChecked, showAlert]);

  const intl = useIntl();

  const getDraftSubscriptionCart = useQuery(getDraftSubscriptionCartQuery, {
    variables: {
      input: {
        businessId: user?.id as string,
        membershipId,
        addressId: "",
        brokerId: "",
      },
    },
    skip: !user || !membershipId,
  });

  const getDraftLocationSetupCart = useQuery(getDraftLocationSetupCartQuery, {
    variables: {
      input: {
        businessId: user?.id as string,
        businessAddressId: locationId as string,
        addressId: "",
        brokerId: "",
      },
    },
    skip: !user || !membershipId,
  });

  const [createSubscriptionCart, { loading: createSubscriptionCartMutationLoading }] = useMutation(
    createSubscriptionCartMutation,
    {
      onCompleted: (data) => {
        const cartId = data?.createSubscriptionCart?.payload?.id;

        if (cartId) {
          history.push(
            `/checkout/${cartId}${stringify(
              {
                successPageUrl: `${location.pathname}/payment-success`,
              },
              { addQueryPrefix: true },
            )}`,
          );
        } else {
          enqueueSnackbar(
            intl.formatMessage({ id: "error.something_wen_wrong_during_enrollment" }),
            getOptions("error"),
          );
        }
      },
    },
  );

  const [createLocationSetuoCart, { loading: createLocationSetupCartMutationLoading }] = useMutation(
    createLocationSetupCartMutation,
    {
      onCompleted: (data) => {
        const cartId = data?.createLocationSetupCart?.payload?.id;

        if (cartId) {
          history.push(
            `/checkout/${cartId}${stringify(
              {
                successPageUrl: `/locations?locationEnrollSuccess=true`,
              },
              { addQueryPrefix: true },
            )}`,
          );
        } else {
          enqueueSnackbar(
            intl.formatMessage({ id: "error.something_wen_wrong_during_enrollment" }),
            getOptions("error"),
          );
        }
      },
    },
  );

  const closeBrokerSelectionModal = () => {
    setBrokerSelectionModalActive(false);
    setBrokerId("");
  };

  const isEnrollButtonLoading = createSubscriptionCartMutationLoading || createLocationSetupCartMutationLoading;

  const handleCheckoutClick = () => {
    if (isTermsAndConditionsChecked && user?.id && membershipId) {
      setBrokerSelectionModalActive(true);
      setShowAlert(false);
    }
    if (!isTermsAndConditionsChecked) {
      setShowAlert(true);
    }
  };

  const handleBrokerSelectionConfirmation = async () => {
    showAddressSelectionModal((addressId: string) => {
      createSubscriptionCart({
        variables: {
          input: {
            membershipId,
            businessId: user?.id as string,
            addressId,
            brokerId,
          },
        },
      });
    });
  };

  const handleBrokerSelectionConfirmationLocation = async () => {
    showAddressSelectionModal((addressId: string) => {
      createLocationSetuoCart({
        variables: {
          input: {
            businessId: user?.id as string,
            businessAddressId: locationId as string,
            addressId,
            brokerId,
          },
        },
      });
    });
  };

  const showTermsAndConditionsModal = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();

    setModalActive(true);
  };

  const handleIsTermsAndConditionsCheckedChange = (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setIsTermsAndConditionsChecked(checked);
  };

  const closeTermsAndConditionsModal = () => {
    setModalActive(false);
  };

  return (
    <Grid container direction="column" className={root}>
      <MembershipCartPreview
        membershipId={membershipId}
        renewalPeriod={renewalPeriod}
        isEditMode={Boolean(isEditMode)}
        enrolledModal={enrolledModal}
        locationId={locationId}
        cart={
          enrolledModal
            ? (getDraftLocationSetupCart.data?.getDraftLocationSetupCart?.payload as GetCartQuery["getCart"])
            : (getDraftSubscriptionCart.data?.getDraftSubscriptionCart?.payload as GetCartQuery["getCart"])
        }
        loading={enrolledModal ? getDraftLocationSetupCart.loading : getDraftSubscriptionCart.loading}
      />
      <FormGroup className={formGroup}>
        {!isEditMode && (
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                value={isTermsAndConditionsChecked}
                onChange={handleIsTermsAndConditionsCheckedChange}
              />
            }
            label={
              <div>
                <span className={termsLabel}>
                  {intl.formatMessage({ id: "company.i_agree" })}
                  <button onClick={showTermsAndConditionsModal} type="button" className={termsAndConditionsLink}>
                    {intl.formatMessage({ id: "company.terms_and_conditions" })}
                  </button>
                </span>
              </div>
            }
          />
        )}
      </FormGroup>

      <Grid container justifyContent="center" alignItems="center" flexDirection="column">
        {!isEditMode && (
          <>
            {showAlert && (
              <Alert style={{ marginBottom: 10 }} severity="error">
                {intl.formatMessage({ id: "company.you_must_accept" })}
              </Alert>
            )}
            <MainButton
              buttonText="btn_text_enroll"
              isLoading={isEnrollButtonLoading}
              btnOnClick={handleCheckoutClick}
            />
          </>
        )}
      </Grid>

      <BrokerSelectionModal
        open={brokerSelectionModalActive}
        onClose={closeBrokerSelectionModal}
        handleChange={setBrokerId}
        handleInputChange={setBrokerInput}
        onConfirm={enrolledModal ? handleBrokerSelectionConfirmationLocation : handleBrokerSelectionConfirmation}
        bokers={getBrokersData?.findTykBrokersByNameOrEmail || []}
        isBrokerSelected={Boolean(brokerId)}
        isLoading={isEnrollButtonLoading}
      />
      <AddressSelectionModal {...addressSelectionModalProps} />
      <PaymentModal modalActive={modalActive} onClose={closeTermsAndConditionsModal} />
    </Grid>
  );
};

export default PaymentSummary;
