import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    advertisementSummaryContainer: {
      paddingTop: "120px",

      [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
        paddingTop: "80px",
      },

      "& .advertisement-summary-title": {
        padding: "50px 90px 30px",
        fontFamily: theme.typography.fontFamily,
        fontSize: "0.875rem",
        fontWeight: "600",
        color: theme.palette.marketing.darkPurple,

        [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
          padding: "40px 30px 20px",
        },
      },

      "& .advertisement-summary-content__wrapper": {
        display: "flex",
        flexDirection: "column",
        margin: "0 30px",
        padding: "60px",
        backgroundColor: theme.palette.white,
        border: "0.5px solid #8986AD",

        [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
          justifyContent: "center",
          alignItems: "center",
          margin: "0 10px 10px 10px",
          padding: "10px",
        },

        "& .advertisement-summary-content__title": {
          fontSize: "0.875rem",
          color: "#747474",
        },

        "& .advertisement-summary-content__body": {
          padding: "20px 0",

          "& .content-item": {
            width: "100%",
            display: "flex",
            flexDirection: "column",
            margin: "10px 20px 10px 0",
            padding: "20px 30px",
            backgroundColor: theme.palette.marketing.lightBlue,
            borderRadius: "6px",

            [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
              margin: "10px",
              padding: "10px 15px",
            },

            "& .content-item-title": {
              width: "124px",
              padding: "0 0 6px",
              borderBottom: "1px solid #8986AD",
              fontSize: "0.75rem",
              fontWeight: 400,
              color: "#747474",
            },

            "& .campaign-name-body": {
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "end",
              padding: "10px 0 0",

              [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "start",
              },

              "& .campaign-name-wrapper": {
                width: "50%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                alignItems: "center",
                height: "32px",
                padding: "4px 16px",
                backgroundColor: theme.palette.white,
                border: "1px solid #D3D3D3",
                borderRadius: "3px",

                "& .MuiInputBase-root": {
                  width: "100%",
                  fontFamily: theme.typography.fontFamily,
                  fontSize: "0.75rem",
                  fontWeight: 600,
                  color: theme.palette.marketing.darkPurple,
                  "&:hover": {
                    border: "none",
                  },
                },

                "& .MuiInput-root, .MuiInput-root:before, .MuiInput-root:after": {
                  border: "none",
                },

                [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
                  width: "100%",
                },
              },

              "& .preview-image-wrapper": {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",

                "& .preview-image": {
                  width: "102px",
                  height: "110px",
                  backgroundColor: "#C9D7F4",
                  borderRadius: "6px",
                },

                "& .preview-image-label": {
                  paddingTop: "10px",
                  fontSize: "0.625rem",
                  color: theme.palette.marketing.lightPurple,
                },

                [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
                  padding: "20px 0 0",
                },
              },
            },

            "& .budget-body": {
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "20px 0 0",

              "& .budget-item": {
                "& .budget-item-label": {
                  color: theme.palette.marketing.lightPurple,
                  fontSize: "0.75rem",
                  fontWeight: 400,
                },

                "& .budget-item-value": {
                  color: theme.palette.marketing.darkPurple,
                  fontSize: "0.75rem",
                  fontWeight: 600,
                },
              },
            },

            "& .promoted-elements-wrapper": {
              display: "flex",
              flexDirection: "column",
              padding: "10px 0 0",

              "& .MuiTableBody-root": {
                "& .MuiTableCell-body": {
                  padding: "8px",
                  color: theme.palette.marketing.darkPurple,
                  fontSize: "0.75rem",
                  lineHeight: "normal",
                  border: "none",

                  "& .type-wrapper": {
                    width: "27px",
                    height: "27px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: theme.palette.marketing.lightGrey,
                    borderRadius: "50%",

                    "& .type-text": {
                      fontSize: "0.75rem",
                      color: "#828282",
                    },
                  },
                },

                "& .MuiTableCell-body:first-child": {
                  paddingLeft: 0,
                  width: "40px",
                },

                "& .MuiTableCell-body.event-image-cell": {
                  width: "50px",

                  "& .event-image": {
                    width: "30px",
                    height: "30px",
                    marginRight: "6px",
                    borderRadius: "50%",
                    backgroundColor: theme.palette.marketing.lightPurple,
                  },
                },
              },

              "& .total-items": {
                display: "flex",
                flexDirection: "row",
                justifyContent: "end",
                alignItems: "end",
                padding: "30px 0 0",
                fontSize: "0.75rem",
                color: theme.palette.marketing.lightPurple,

                "& .total-items-count": {
                  paddingLeft: "4px",
                  fontSize: "0.75rem",
                  fontWeight: 600,
                  color: theme.palette.marketing.darkPurple,
                },
              },
            },
          },

          "& .content-item-checkout": {
            display: "flex",
            flexDirection: "row",
            alignItems: "end",

            "& .checkout-button": {
              height: "32px",
              margin: "10px",
              padding: "8px 30px",
              backgroundColor: "#1473E6",
              borderRadius: "20px",
              color: theme.palette.marketing.light,
              fontSize: "0.875rem",
              fontWeight: 400,
              "&:hover": {
                backgroundColor: "#203ED4",
              },
            },
          },
        },
      },
    },
  };
});

export default useStyles;
