import { Grid, Link } from "@mui/material";

import { useHistory } from "react-router-dom";

import { FormattedMessage } from "react-intl";
import Header from "../Layout/HeaderAlternative";
import {
  MainContainer,
  GoBackButton,
  GoBackButtonContainer,
  PolicyContainer,
  SectionTitle,
  SectionContent,
  SectionList,
} from "./styles";

import { ReactComponent as ArrowLeftSvg } from "../../assets/icons/arrow-left-solid.svg";
import { ReactComponent as CookieSvg } from "../../assets/images/cookie-bite-solid.svg";
import { ReactComponent as IconMdOpen } from "../../assets/icons/dashboard/Icon ionic-md-open.svg";

export default function CookiePolicies() {
  const history = useHistory();
  return (
    <Grid container>
      <Header />

      <MainContainer>
        {history.length > 0 && (
          <GoBackButtonContainer>
            <GoBackButton role="button" onClick={history.goBack}>
              <ArrowLeftSvg height={18} />
              <span>
                <FormattedMessage id="btn_go_back" />
              </span>
            </GoBackButton>
          </GoBackButtonContainer>
        )}

        <PolicyContainer>
          <CookieSvg width={200} />

          <Grid>
            <SectionTitle>
              <FormattedMessage id="cookies.title" />
            </SectionTitle>
            <SectionContent>
              <FormattedMessage id="cookies.you_agree" />
            </SectionContent>

            <SectionContent>
              <FormattedMessage id="cookies.description" />
            </SectionContent>

            <SectionContent>
              <FormattedMessage id="cookies.purpose_of_cookies.title" />
              <SectionList>
                <li>
                  <b>
                    <FormattedMessage id="cookies.purpose_of_cookies.necessity" />
                  </b>
                  <FormattedMessage id="cookies.purpose_of_cookies.necessity_desc" />
                </li>
                <li>
                  <b>
                    <FormattedMessage id="cookies.purpose_of_cookies.preference" />
                  </b>
                  <FormattedMessage id="cookies.purpose_of_cookies.preference_desc" />
                </li>
                <li>
                  <b>
                    <FormattedMessage id="cookies.purpose_of_cookies.analytics" />
                  </b>
                  <FormattedMessage id="cookies.purpose_of_cookies.analytics_desc" />
                </li>
                <li>
                  <b>
                    <FormattedMessage id="cookies.purpose_of_cookies.advertising" />
                  </b>
                  <FormattedMessage id="cookies.purpose_of_cookies.advertising_desc" />
                </li>
                <li>
                  <b>
                    <FormattedMessage id="cookies.purpose_of_cookies.social_media" />
                  </b>
                  <FormattedMessage id="cookies.purpose_of_cookies.social_media_desc" />
                </li>
              </SectionList>
            </SectionContent>

            <SectionContent>
              <FormattedMessage id="cookies.info" />
              <SectionList>
                <li>
                  <FormattedMessage id="browsers.chrome" />
                  <Link
                    component="a"
                    href="https://support.google.com/chrome/answer/95647?hl=en"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://support.google.com/chrome/answer/95647?hl=en
                  </Link>
                </li>
                <li>
                  <FormattedMessage id="browsers.explorer" />

                  <Link
                    component="a"
                    href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies
                  </Link>
                </li>
                <li>
                  <FormattedMessage id="browsers.safari" />
                  <Link
                    component="a"
                    href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac
                  </Link>
                </li>
                <li>
                  <FormattedMessage id="browsers.firefox" />
                  <Link
                    component="a"
                    href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer
                  </Link>
                </li>
                <li>
                  <FormattedMessage id="browsers.opera" />
                  <Link
                    component="a"
                    href="https://help.opera.com/en/latest/web-preferences/#cookies"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://help.opera.com/en/latest/web-preferences/#cookies
                  </Link>
                </li>
              </SectionList>
            </SectionContent>

            <SectionContent>
              <FormattedMessage id="cookies.if_you_wish" />
            </SectionContent>

            <SectionContent>
              <FormattedMessage id="cookies.in_addition" />
              <Link
                component="a"
                href="https://optout.networkadvertising.org/?c=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                <FormattedMessage id="cookies.network_advertising" /> <IconMdOpen />
              </Link>{" "}
              <FormattedMessage id="cookies.or" />
              <Link
                component="a"
                href="https://optout.aboutads.info/?c=2&lang=EN"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                <FormattedMessage id="cookies.digital_advertising" />
                <IconMdOpen />
              </Link>{" "}
              <FormattedMessage id="cookies.for_more_information" />
            </SectionContent>

            <SectionContent>
              <FormattedMessage id="cookies.opting_out" />
            </SectionContent>

            <SectionContent>
              <FormattedMessage id="cookies.we_do_not_track" />
            </SectionContent>
          </Grid>
        </PolicyContainer>
      </MainContainer>
    </Grid>
  );
}
