import { FC } from "react";
import { Grid } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { CardNumberElement } from "@stripe/react-stripe-js";
import { useStyles } from "./styles";
import { CardErrorMessage } from "../CardErrorMessage";
import { CardMessageStatus, CustomInputStyleOptions, EventStripe } from "./CardForm.types";

interface CardNumberInputProps {
  hasCardNumberErrors: boolean | null;
  cardMessageStatus: CardMessageStatus | null;
  customInputStyleOptions: (formatMessageId: string) => CustomInputStyleOptions;
  onChangeErrorHandler: (event: EventStripe) => void;
}

export const CardNumberInput: FC<CardNumberInputProps> = ({
  hasCardNumberErrors,
  cardMessageStatus,
  customInputStyleOptions,
  onChangeErrorHandler,
}) => {
  const styles = useStyles();

  return (
    <Grid className={styles.number} style={{ marginBottom: hasCardNumberErrors ? "0px" : "28px" }}>
      <label htmlFor="card-number" className={styles.label}>
        <FormattedMessage id="card_number" />
      </label>
      <CardNumberElement
        id="card-number"
        className={styles.input}
        options={customInputStyleOptions("card_number_placeholder")}
        onChange={onChangeErrorHandler}
      />
      <CardErrorMessage
        cardMessageStatus={cardMessageStatus}
        emptyErrorMessageId="card_number_error_empty"
        invalidErrorMessageId="card_number_error_invalid"
      />
    </Grid>
  );
};
