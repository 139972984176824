import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    hideContent: {
      maxHeight: "0",
      transition: "300ms ease-in-out 0ms",
      overflow: "hidden",
    },
  };
});

export default useStyles;
