import { ApolloError, useMutation } from "@apollo/client";
import { useUser } from "@stagewood/unified-login-library";
import { getPaymentMethodsQuery } from "../../Checkout/PaymentMethods/graphql/GetPaymentMethods.query";
import { setPaymentMethodAsDefaultMutation } from "../graphql/SetPaymentMethodAsDefault.mutation";

export const useDefaultPaymentMethod = (
  userPaymentMethodId: string,
): { setAsDefaultMethod: () => void; setAsDefaultMethodLoading: boolean; setAsDefaultMethodError?: ApolloError } => {
  const user = useUser();
  const userId = user?.id as string;

  const [setAsDefaultMethod, { loading, error }] = useMutation(setPaymentMethodAsDefaultMutation, {
    refetchQueries: [getPaymentMethodsQuery],
    awaitRefetchQueries: true,
    variables: { userId, userPaymentMethodId },
  });

  return {
    setAsDefaultMethod,
    setAsDefaultMethodLoading: loading,
    setAsDefaultMethodError: error,
  };
};
