import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    appBar: {
      backgroundColor: "#fff",
      marginBottom: "20px",
    },
    toolbarHeight: {
      minHeight: 99,
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    leftBox: {
      display: "flex",
      alignItems: "center",
      color: "#34305D",

      "& .MuiIconButton-label": {
        color: "#000",
      },
    },
    rightBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      color: "#8986AD",
      width: "max-content",
      // gap: "4rem",
      "@media all and (max-width: 701px)": {
        gap: 0,
        justifyContent: "flex-end",
      },
    },
    buttonBox: {
      display: "flex",
      alignItems: "center",
      color: "#8986AD",
      justifyContent: "flex-end",
      gap: "2rem",
    },
    upgradeButton: {
      backgroundColor: "#1473E6",
      color: "#fff",
      textTransform: "none",
      borderRadius: "2rem",
      padding: "0.3rem 1.5rem",
      minWidth: "max-content",
      "&:hover": {
        backgroundColor: "#F6C40E",
      },
      "@media all and (max-width: 700px)": {
        display: "none",
      },
    },
    upgradeButtonSmall: {
      backgroundColor: "#1473E6",
      color: "#fff",
      textTransform: "none",
      borderRadius: "2rem",
      padding: "0.3rem 1.5rem",
      minWidth: "max-content",
      "&:hover": {
        backgroundColor: "#F6C40E",
      },
      "@media all and (min-width: 701px)": {
        display: "none",
      },
    },
    logoStyle: {
      height: "60px",
      "@media all and (max-width: 600px)": {
        height: "40px",
        width: "200px",
      },
    },
  };
});

export default useStyles;
