import { Box, Typography, Stack, useTheme } from "@mui/material";
import { AlphabeticListProps } from "./interfaces/alphabetic-list.interface";

export function AlphabeticList(props: AlphabeticListProps): JSX.Element {
  const theme = useTheme();

  return (
    <Stack spacing={2}>
      {props.items.map((item) => (
        <Box key={item.letter}>
          <Typography component="span" ml={theme.spacing(4)}>
            ({item.letter.toLowerCase()})
          </Typography>
          <Typography component="span" ml={theme.spacing(4)}>
            {item.text}
          </Typography>
        </Box>
      ))}
    </Stack>
  );
}
