import { FC, useState } from "react";
import { Box, Grid, InputLabel, TextField } from "@mui/material";
import { useIntl } from "react-intl";
import Modal from "../../../Modal";
import ModalHeader from "./ModalHeader";
import DetailsChart from "./DetailsChart";
import TotalsStatistic from "./TotalsStatistic";
import ModalFooter from "./ModalFooter";
import { Campaign } from "../Campaigns/data";
import { Rule } from "../Rules/data";
import useStyles from "./styles";

interface DetailsModalProps {
  isOpen: boolean;
  onClose: VoidFunction;
  detailsData: Campaign | Rule;
  isShowFooter?: boolean;
}

const DetailsModal: FC<DetailsModalProps> = ({ isOpen, onClose, detailsData, isShowFooter = true }) => {
  const { formatMessage } = useIntl();
  const { detailsContainer, datePickerWrapper } = useStyles();

  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");

  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <Box className={detailsContainer}>
        <ModalHeader title={detailsData?.name} type={detailsData?.type} ends={detailsData?.end} />

        <Box className="modalBody" width="760px">
          <Grid container spacing={2}>
            <Grid item md={9} sm={12} width="100%">
              <Box display="flex" flexDirection="row" margin={3}>
                <Box className={datePickerWrapper} marginRight="20px">
                  <InputLabel htmlFor="startDate">{formatMessage({ id: "inputs.start_date" })}</InputLabel>

                  <TextField
                    id="startDate"
                    type="date"
                    className="datePicker"
                    value={startDate}
                    onChange={(event) => setStartDate(event.target.value || "")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      max: endDate || new Date().toISOString().slice(0, 10),
                    }}
                  />
                </Box>

                <Box className={datePickerWrapper}>
                  <InputLabel htmlFor="endDate">{formatMessage({ id: "inputs.end_date" })}</InputLabel>

                  <TextField
                    id="endDate"
                    type="date"
                    className="datePicker"
                    value={endDate}
                    onChange={(event) => setEndDate(event.target.value || "")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      min: startDate,
                      max: new Date().toISOString().slice(0, 10),
                    }}
                  />
                </Box>
              </Box>

              <Box className="chartWrap">
                <DetailsChart />
              </Box>
            </Grid>

            <Grid item md={3} sm={12} width="100%">
              <TotalsStatistic />
            </Grid>

            {isShowFooter && (
              <Grid item md={12} width="100%">
                <ModalFooter />
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default DetailsModal;
