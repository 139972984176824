import { forwardRef, PropsWithChildren } from "react";
import { LoadingButton as MuiLoadingButton, loadingButtonClasses, LoadingButtonProps } from "@mui/lab";
import { CircularProgress, styled } from "@mui/material";

const StyledMuiLoadingButton = styled(MuiLoadingButton)(({ theme }) => ({
  [`&.${loadingButtonClasses.loading}`]: {
    paddingLeft: `${theme.spacing(5)} !important`,
  },
})) as typeof MuiLoadingButton;

export const LoadingButton = forwardRef<HTMLButtonElement, PropsWithChildren<LoadingButtonProps>>(
  function LoadingButton(props, ref) {
    return (
      <StyledMuiLoadingButton
        {...props}
        ref={ref}
        loadingIndicator={
          <CircularProgress color="inherit" size={16} sx={{ marginRight: (theme) => theme.spacing(1) }} />
        }
      >
        {props.children}
      </StyledMuiLoadingButton>
    );
  },
);
