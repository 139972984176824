import { makeStyles } from "@mui/styles";

const infoWidth = "100%";
const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: infoWidth,
      margin: 0,
      padding: "2rem 1rem",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
    },
    heading: {
      color: theme.palette.secondary.light,
      fontStyle: "italic",
      fontSize: "0.875rem",
    },
    captionTitle: {
      color: theme.palette.secondary.light,
      fontStyle: "italic",
      fontSize: "0.75rem",
      margin: 0,
      padding: 0,
    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(9),
      marginTop: "1rem",
    },
    title: {
      fontSize: "1.2rem",
      color: "#4B4B4B",
      fontWeight: 600,
    },
    infoContLetterStyle: {
      "& p": {
        fontSize: "0.875rem",
      },
      "& p:nth-child(1)": {
        color: "#6E6E6E",
      },
      "& p:nth-child(2)": {
        fontWeight: 600,
        color: "#4B4B4B",
      },
    },
    qRCode: {
      height: "5rem",
      width: "5rem",
    },
    imgOpacity: {
      opacity: 0.5,
    },
    download: {
      textTransform: "none",
      textDecoration: "underline",
      fontStyle: "italic",
      color: "blue",
      padding: 0,
      justifyContent: "flex-start",

      "&:disabled": {
        color: "#D3202F",
      },
    },
    toolbar: theme.mixins.toolbar,
    drawer: {
      width: infoWidth,
      backgroundColor: "pink",
      flexShrink: 0,
    },
    drawerPaper: {
      width: infoWidth,
    },
    link: {
      color: "#4262FF",
      fontStyle: "italic",
    },
  };
});

export default useStyles;
