import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  message: {
    "&.MuiAlert-root": {
      backgroundColor: "#FFF2E2",
      color: "#5C2122",
      fontSize: "0.75rem",
      padding: "2px 4px",
      marginTop: "2px",

      "& .MuiAlert-icon": {
        color: "#FF983B",
        margin: 0,
        padding: 0,
        alignSelf: "center",

        "& MuiSvgIcon-root": {
          width: "10px",
          height: "10px",
        },
      },

      "& .MuiAlert-message": {
        margin: "0 0 0 6px",
        padding: 0,
        overflow: "hidden",
        alignSelf: "center",
      },
    },
  },
}));
