export enum CategoryName {
  Campaign = "Campaign",
  Experiences = "Experiences",
  Jackpot = "Jackpot",
}

export enum QrType {
  CAMPAIGN = "CAMPAIGN",
  EXPERIENCES = "EXPERIENCES",
  JACKPOT = "JACKPOT",
}

export interface ITtykchaseCampaign {
  id: string;
  title: string;
  endDate: string;
  defaultCoverPhoto?: string;
}

export interface ICategory {
  type: QrType;
  triggers: ITtykchaseCampaign[];
}

export interface IQrCode {
  id: string;
  name: string;
  qrType: QrType;
  url: string;
  tykchaseCampaign: ITtykchaseCampaign;
  tykchaseCampaignId: string;
}

export interface ITab {
  title: CategoryName;
  type: QrType;
}
