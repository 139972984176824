import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    wrap: {
      "& .promotersHeader": {
        "& .inactive": {
          opacity: 0.5,
        },
      },

      "& .promotersItemWrap": {
        display: "flex",
        flexDirection: "column",
        minHeight: "109px",
        padding: "12px 12px 16px",
        margin: "8px 0 0",
        borderRadius: "6px",
        backgroundColor: "#CBDFFB",

        [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
          margin: "4px 0",
        },

        "& .MuiButtonBase-root": {
          padding: 0,
        },

        "& .promotersItemContent": {
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginTop: "10px",

          "& .promotersItemStatistic": {
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            height: "30px",
          },

          "& .promotersItemStatisticDivider": {
            height: "32px",
            width: "1px",
            backgroundColor: "#34305D",
            alignSelf: "end",
            margin: "0 10px",
          },
        },
      },
    },
  };
});

export default useStyles;
