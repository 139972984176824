import { FC } from "react";
import { Grid, Typography, Button, Paper } from "@mui/material";
import { FormattedMessage } from "react-intl";
import useStyles from "./styles";
import { IBusinessCardReceipt } from "./types";

export const BusinessCardReceipt: FC<IBusinessCardReceipt> = ({
  id,
  storeAddress,
  storeTitle,
  uploadedDate,
  receiptUrl,
  phoneNumber,
  onDelete,
  onDownload,
}) => {
  const { root, receiptImg, cardAddress, uploadDate, deleteButton, successButton, dateButtonContainer, cardPadding } =
    useStyles();

  const handleDownload = () => {
    onDownload(receiptUrl);
  };

  const handleDelete = () => {
    onDelete(id);
  };

  return (
    <Grid container direction="column" className={root} xs={10} sm={10} md={7} lg={5}>
      <Paper elevation={2} className={cardPadding}>
        <Grid container>
          <Grid item direction="column" xs={4} sm={4} lg={4}>
            <img src={receiptUrl} alt="receipt-img" className={receiptImg} />
          </Grid>
          <Grid item xs={7} sm={8} lg={7}>
            <Typography>{storeTitle}</Typography>
            <Typography className={cardAddress}>{storeAddress}</Typography>
            <Typography className={cardAddress}>{phoneNumber}</Typography>
          </Grid>
        </Grid>
        <Grid container className={dateButtonContainer}>
          <Grid item xs={4} sm={4} md={4} lg={4}>
            <Typography className={uploadDate}>
              <FormattedMessage id="upload_date" />: {uploadedDate}
            </Typography>
          </Grid>
          <Grid item xs={7} sm={8} md={7} lg={7} justifyContent="flex-end">
            <Button className={successButton} variant="contained" onClick={handleDownload}>
              <FormattedMessage id="btn_text_download" />
            </Button>
            <Button className={deleteButton} variant="contained" color="error" onClick={handleDelete}>
              <FormattedMessage id="btn_text_delete" />
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
