import { makeStyles } from "@mui/styles";
import theme from "../../../../theme";

const useStyles = makeStyles(() => ({
  qrCreateWrap: {
    padding: `${theme.spacing(3)} ${theme.spacing(6)} ${theme.spacing(5)}`,
    [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
      padding: 0,
    },
    "& .mainContent": {
      backgroundColor: theme.palette.technology.white,
      width: "100%",
      padding: `${theme.spacing(3)} ${theme.spacing(8)}`,
      [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
        padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
      },
    },
    "& .MuiTypography-root": {
      fontFamily: `${theme.typography.fontFamily}`,
    },
  },
}));

export { useStyles };
