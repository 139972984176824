import { Grid, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { useTheme } from "@mui/styles";
import Icon from "../../../utils/icon";
import { ReactComponent as HighestTyksIcon } from "../../../assets/icons/rewards/Highest_ICON.svg";
import { ReactComponent as BirthdayIcon } from "../../../assets/icons/rewards/Birthday_ICON.svg";
import { ReactComponent as SignUpIcon } from "../../../assets/icons/rewards/Earn_Tyks_SignUp_ICON.svg";
import { ReactComponent as EngagedIcon } from "../../../assets/icons/rewards/Engaged_ICON.svg";
import { ReactComponent as FirstPurchaseIcon } from "../../../assets/icons/rewards/First_Purchase_ICON.svg";
import { ReactComponent as InviteFriendIcon } from "../../../assets/icons/rewards/Invite_friend_ICON.svg";
import { ReactComponent as PurchasesIcon } from "../../../assets/icons/rewards/Purchases_ICON.svg";
import { ReactComponent as RepeatPurchasesIcon } from "../../../assets/icons/rewards/Repeat_Purchases_ICON.svg";
import { ReactComponent as VisitLocationIcon } from "../../../assets/icons/rewards/Visiting_ICON.svg";

import { RuleName } from "../../../generated/types";
import { RewardWidgedtext } from "./types";
import { formatDate } from "../../../utils/formatDate";

type MarginLeft = {
  xs: string;
  lg: string;
};

type RewardData = {
  rule: string;
  ruleName: RuleName;
  status: string;
  centorsDistributed: number;
  deactivationDate?: Date;
};

export type RewardsWidgetProps = {
  ml?: string | MarginLeft;
  mb?: string | MarginLeft;
  mr?: string | MarginLeft;
  data?: RewardData;
};

const ruleIcons = {
  [RuleName.Birthday]: BirthdayIcon,
  [RuleName.HighestTyks]: HighestTyksIcon,
  [RuleName.EnrollmentInBusinessProgram]: SignUpIcon,
  [RuleName.MostEngaged]: EngagedIcon,
  [RuleName.PremiumRewardFirstPurchase]: FirstPurchaseIcon,
  [RuleName.InvitingFriendsToTykbiz]: InviteFriendIcon,
  [RuleName.Purchases]: PurchasesIcon,
  [RuleName.FrequentCustomer]: RepeatPurchasesIcon,
  [RuleName.VisitingLocation]: VisitLocationIcon,
};

const FORMAT_DATE = "MMM, Do";

const RewardIconView = ({ data }) => {
  const RewardIcon = ruleIcons[data && data.ruleName] ?? PurchasesIcon;

  return <RewardIcon width={20} height={20} fill="white" />;
};

const YourRewardsWidget = ({ ml, mb, mr, data }: RewardsWidgetProps) => {
  const theme = useTheme();
  const isPublished = data?.status === "Published";
  const intl = useIntl();
  return (
    <Grid
      height="160px"
      width="200px"
      p="1.125rem 0.75rem"
      ml={ml}
      mr={mr}
      marginBottom={{ xs: "10px", lg: "10px" }}
      borderRadius="0.375rem"
      bgcolor={isPublished ? theme.palette.dashBoard?.lightCyan : theme.palette.dashBoard?.light}
      boxShadow="0px 3px 5px #51515129"
    >
      <Grid container flexWrap="nowrap">
        <Grid
          height="60px"
          width="70px"
          mr="0.5rem"
          borderRadius="0.375rem"
          bgcolor={theme.palette.primary?.dark}
          flexDirection="row"
        >
          <Grid alignSelf="center" textAlign="center" paddingTop="20px">
            <RewardIconView data={data} />
          </Grid>
        </Grid>

        <Typography width="75%" fontSize="0.625rem" fontWeight="bold" color={theme.palette.analytics?.black}>
          {data?.rule}
        </Typography>
      </Grid>

      <Grid container alignItems="end" flexWrap="nowrap" mt="20px">
        <Grid flexDirection="row" width="60%" borderRight={`0.5px solid ${theme.palette.dashBoard?.borderGrey}`}>
          <Grid display="flex" flexDirection="row" alignItems="center">
            <Icon.CentorsFull width="25px" height="25px" mb="4px" marginRight="5px" />

            <Typography fontWeight="bold" fontSize="12px" color={theme.palette.primary.dark}>
              {data?.centorsDistributed}
            </Typography>
          </Grid>
          <Typography fontSize="8px" color={theme.palette.primary.dark}>
            {RewardWidgedtext(intl).CENTORS_DISTRIBUTED}
          </Typography>
        </Grid>

        <Grid
          container
          flexDirection="column"
          alignSelf="start"
          justifyContent="end"
          alignContent="end"
          alignItems="end"
          width="40%"
        >
          <Grid
            borderRadius="3px"
            paddingTop="3px"
            paddingBottom="3px"
            paddingLeft="8px"
            paddingRight="8px"
            border={`1px solid ${isPublished ? theme.palette.analytics?.lightGreen : theme.palette.analytics?.red}`}
            bgcolor={isPublished ? theme.palette.analytics?.lightGreenAlt : theme.palette.analytics?.lightRed}
          >
            <Typography
              textAlign="center"
              fontSize="8px"
              color={isPublished ? theme.palette.analytics?.lightGreen : theme.palette.analytics?.red}
            >
              {isPublished ? RewardWidgedtext(intl).ACTIVE : RewardWidgedtext(intl).ENDED}
            </Typography>
          </Grid>
          {!isPublished && data?.deactivationDate && (
            <Typography fontSize="0.565rem" mt="4px" color={theme.palette.primary.dark}>
              {formatDate(data?.deactivationDate, { month: "short", day: "numeric" })}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default YourRewardsWidget;
