import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    detailsContainer: {
      width: "375px",
      "& .title-wrapper": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "56px 20px 32px",

        "& .MuiSvgIcon-root": {
          width: "24px",
          height: "24px",
          marginRight: "10px",
          fill: "#34305D",
        },
        "& .title": {
          fontSize: "1rem",
          color: "#34305D",
        },
      },

      "& .winner-name": {
        padding: "7px 20px",
        fontSize: "0.875rem",
        color: "#4262FF",
      },

      "& .section-item": {
        padding: "8px 20px",
        "& .section-label": {
          fontSize: "0.875rem",
          fontWeight: 400,
          color: "#797882",
        },

        "& .section-value": {
          fontSize: "0.875rem",
          fontWeight: 600,
          color: "#34305D",
        },
      },
    },
  };
});

export default useStyles;
