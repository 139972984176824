import { createStyles, makeStyles } from "@mui/styles";
import theme from "../../../../theme";

export const useStyles = makeStyles(() =>
  createStyles({
    selectComp: {
      marginLeft: "-30px",
      width: "300px",
      color: "#12111A",
      fontFamily: theme.typography.fontFamily,

      "& .MuiSelect-select": {
        paddingLeft: "0px",
        height: "20px",
        minHeight: "20px",
      },
    },
    container:{
      flexDirection:"row",
      justifyContent:'space-between',
    },
    avatarBox:{
      display:'flex',
      width:'100%',
      justifyContent:"space-between" },
    location: {
      fontFamily: theme.typography.fontFamily,
      fontSize: 18,
      color: "#33315A",
    },
    priceTitle: {
      fontFamily: theme.typography.fontFamily,
      fontSize: 16,
      textAlign: "right",
      color :"#707070",
    },
    price: {
      fontFamily: theme.typography.fontFamily,
      fontSize: 18,
      textAlign: "right",
      fontWeight: "bold",
    },
    membershipDetails:{
      fontFamily:theme.typography.fontFamily,
      fontSize:"0.875rem",
      color:"#707070",
      marginLeft:65,
      marginBottom:8,
    },
    planTitle:{
      marginLeft:'1rem',
      fontFamily:theme.typography.fontFamily,
      fontSize:'0.875rem',
    },
  }),
);
