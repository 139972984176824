import { ReactNode } from "react";
import { defineMessages, IntlShape } from "react-intl";
import { Link } from "react-router-dom";

import { Routes } from "../constants";

enum TooltipType {
  NO_PLAN = "NO_PLAN",
  COMING_SOON = "COMING_SOON",
  UPGRADE_PLAN = "UPGRADE_PLAN",
  REWARD_SPLIT_INFO = "REWARD_SPLIT_INFO",
  CENTORS_FIELD_INFO = "CENTORS_FIELD_INFO",
  DOLLAR_FIELD_INFO = "DOLLAR_FIELD_INFO",
  RECEIPT_ADDRESS_VARIATIONS = "RECEIPT_ADDRESS_VARIATIONS",
  FREQUENT_CUSTOMER_REWARDS = "FREQUENT_CUSTOMER_REWARDS",
}

const messages = defineMessages({
  comingSoon: {
    id: "tooltip_coming_soon",
    defaultMessage: "This feature is not available right now, but we're working hard to deliver it to you soon!",
  },
  noPlan: {
    id: "tooltip_no_plan",
    defaultMessage: "You need to {link} to access this feature",
  },
  upgradePlan: {
    id: "tooltip_upgrade_plan",
    defaultMessage: "You need to {link} in order to access this feature",
  },
  enrollInTykchase: {
    id: "tooltip_enroll_in_tykchase",
    defaultMessage: "enroll in TykChase",
  },
  upgradeYourMembership: {
    id: "tooltip_upgrade_your_membership",
    defaultMessage: "upgrade your membership",
  },
  rewardSplitInfo: {
    id: "your_rewards_diagram_reward_split_tooltip",
    defaultMessage:
      "These are the percentages of how your budget will be distributed every time a user earns a reward.",
  },
  centorsFieldInfo: {
    id: "your_rewards_diagram_centors_field_tooltip",
    defaultMessage:
      "These are the total of Centors your client will receive according to your program distribution formula.",
  },
  dollarFieldInfo: {
    id: "your_rewards_diagram_dollar_field_tooltip",
    defaultMessage: "Set the monetary value you want to distribute every time a client earns a reward.",
  },
  receiptAddressVariations: {
    id: "receipt_address_variations",
    defaultMessage:
      "Upload the image of a receipt, so the system can learn to read the address and store name on it. If there are many variations on your receipts,please upload different receipt examples.",
  },
  frequentCustomerRewards: {
    id: "tooltip_frequent_customer_rewards",
    defaultMessage:
      'You need to set up and publish the "Earn Tyks for Purchases" reward before setting up a "Frequent Customer Rewards".',
  },
});

const AppTooltips = (intl: IntlShape): { [key: string]: string | ReactNode } => {
  return {
    COMING_SOON: intl.formatMessage(messages.comingSoon),
    NO_PLAN: intl.formatMessage(messages.noPlan, {
      link: <Link to={Routes.MEMBERSHIPS}>{intl.formatMessage(messages.enrollInTykchase)}</Link>,
    }),
    UPGRADE_PLAN: intl.formatMessage(messages.upgradePlan, {
      link: <Link to={Routes.MEMBERSHIPS}>{intl.formatMessage(messages.upgradeYourMembership)}</Link>,
    }),
    REWARD_SPLIT_INFO: intl.formatMessage(messages.rewardSplitInfo),
    CENTORS_FIELD_INFO: intl.formatMessage(messages.centorsFieldInfo),
    DOLLAR_FIELD_INFO: intl.formatMessage(messages.dollarFieldInfo),
    RECEIPT_ADDRESS_VARIATIONS: intl.formatMessage(messages.receiptAddressVariations),
    FREQUENT_CUSTOMER_REWARDS: intl.formatMessage(messages.frequentCustomerRewards),
  };
};

export { AppTooltips, TooltipType };
