import { gql } from "@apollo/client";

export const PAY_WITH_SAVED_STRIPE_METHOD = gql`
  mutation chargeOffSessionUser($userId: String!, $amount: Float!, $token: String!) {
    chargeOffSessionUser(userId: $userId, amount: $amount, token: $token) {
      data
    }
  }
`;

export const CREATE_CAMPAIGN = gql`
  mutation (
    $triggerType: TykchaseCampaignTriggers
    $title: String!
    $startDate: DateTime!
    $endDate: DateTime!
    $holidayId: String
    $description: String
    $defaultCoverPhoto: String
    $dayTrigger: BusinessScheduleType
    $tykchaseProducts: [CreateTykchaseProductInput]
    $surpriseNumber: Int
    $receiptTotalPriceTrigger: Float
    $boostedTykchaseCampaignId: String
    $multiplierNumber: Int
    $tykchaseConfigId: String
    $purchaseRewardRate: PurchaseRewardRate
    $dollarReward: Float
    $campaignType: TykchaseCampaignTypes!
    $customCoverBase64: String
  ) {
    createCampaign(
      input: {
        triggerType: $triggerType
        title: $title
        startDate: $startDate
        endDate: $endDate
        holidayId: $holidayId
        description: $description
        defaultCoverPhoto: $defaultCoverPhoto
        dayTrigger: $dayTrigger
        tykchaseProducts: $tykchaseProducts
        surpriseNumber: $surpriseNumber
        receiptTotalPriceTrigger: $receiptTotalPriceTrigger
        boostedTykchaseCampaignId: $boostedTykchaseCampaignId
        multiplierNumber: $multiplierNumber
        tykchaseConfigId: $tykchaseConfigId
        purchaseRewardRate: $purchaseRewardRate
        dollarReward: $dollarReward
        campaignType: $campaignType
        customCoverBase64: $customCoverBase64
      }
    ) {
      status
      message
    }
  }
`;

export const CANCEL_MEMBERSHIP = gql`
  mutation ($userId: String!) {
    cancelMembership(userId: $userId) {
      status
      message
      errorMessageId
      payload {
        id
      }
    }
  }
`;

export const UPDATE_REWARDS_DISTRIBUTION = gql`
  mutation updateRewardDistribution(
    $centorsRate: String
    $loneStarsRate: String
    $jackpotAwardRate: String
    $promoterAwardRate: String
    $minimumLoneStarAward: String
    $features: [String]
    $userId: String!
  ) {
    updateRewardDistribution(
      centorsRate: $centorsRate
      loneStarsRate: $loneStarsRate
      jackpotAwardRate: $jackpotAwardRate
      promoterAwardRate: $promoterAwardRate
      minimumLoneStarAward: $minimumLoneStarAward
      features: $features
      userId: $userId
    ) {
      centorsRate
      loneStarsRate
      jackpotAwardRate
      promoterAwardRate
      minimumLoneStarAward
    }
  }
`;

export const CREATE_QR_CODE = gql`
  mutation ($name: String!, $qrType: String!, $tykchaseCampaignId: String!, $userId: String!) {
    createQrCode(name: $name, qrType: $qrType, tykchaseCampaignId: $tykchaseCampaignId, userId: $userId) {
      qrCodePayload {
        id
        name
        url
        qrType
        tykchaseCampaignId
      }
    }
  }
`;

export const UPDATE_QR_CODE = gql`
  mutation ($id: String!, $name: String, $qrType: String, $tykchaseCampaignId: String) {
    updateQrCode(id: $id, name: $name, qrType: $qrType, tykchaseCampaignId: $tykchaseCampaignId) {
      message
      qrCodePayload {
        id
        name
        url
        qrType
        tykchaseCampaignId
      }
    }
  }
`;

export const DELETE_QR_CODE = gql`
  mutation ($id: String!) {
    deleteQrCode(id: $id) {
      message
    }
  }
`;

export const UPDATE_MONTHLY_REWARD_LIMIT_MUTATION = gql`
  mutation ($userId: String!, $rewardLimit: String!) {
    updateMonthlyRewardLimit(userId: $userId, rewardLimit: $rewardLimit) {
      status
      message
      errorMessageId
      payload {
        id
        monthlyRewardLimit
      }
    }
  }
`;

export const UPDATE_RULE_CONFIGURATION_MUTATION = gql`
  mutation ($input: CreateTykchaseRuleConfigurationInput!) {
    updateRuleConfiguration(input: $input) {
      status
      message
      errorMessageId
      payload {
        id
        rule {
          internalId
        }
        status {
          id
          title
        }
      }
    }
  }
`;

export const DISABLE_RULE_CONFIGURATION_MUTATION = gql`
  mutation ($input: DisableTykchaseRuleConfigurationInput!) {
    disableRuleConfiguration(input: $input) {
      status
      message
      errorMessageId
      payload
    }
  }
`;

export const UPDATE_ENROLL_QR_CODE_STATUS_MUTATION = gql`
  mutation ($userId: String!, $isActive: Boolean!) {
    updateEnrollQrCodeStatus(userId: $userId, isActive: $isActive) {
      status
      message
      qrCodePayload {
        id
        isActive
      }
    }
  }
`;
