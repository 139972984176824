import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  container: {
    width: "100%",

    backgroundColor: "#FFF2E2",
    color: "#5C2122",
    fontSize: "0.875rem",

    padding: "1rem",
    gap: "1rem",

    textAlign: "center",
  },
}));
