import { FC } from "react";
import { Grid, Typography, Button } from "@mui/material";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import useStyles from "./styles";
import qrCodeImage from "../../../assets/images/qrCode.png";
import { formatDate } from "../../../utils/formatDate";
import { useBusinessProfile } from "../../../hooks/useBusinessProfile.hook";
import { PageLoader } from "../../PageLoader";
import { RenewalPeriod, TykchaseConfigStatus } from "../../../gql/graphql";

const BusinessInfo: FC = () => {
  const { root, captionTitle, heading, title, infoContLetterStyle, qRCode, imgOpacity, download } = useStyles();

  const [businessProfile, isLoadingBusinessProfile, isSubscribed] = useBusinessProfile();

  const opacityQrCode = !isSubscribed && imgOpacity;
  const onDownload = async () => {
    const resp = await fetch(businessProfile?.qrCode ?? "");
    const blob = await resp.blob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "qrcode.png";
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(url);
    link.remove();
  };

  const joinedDate = isSubscribed && businessProfile?.joinedDate && formatDate(businessProfile.joinedDate);

  const paymentPeriod = isSubscribed && businessProfile?.renewalPeriod && (
    <FormattedMessage
      id={
        `dashboard_payment_period_${businessProfile.renewalPeriod.toLowerCase() as Lowercase<RenewalPeriod>}` as const
      }
      defaultMessage="payment_period"
    />
  );

  const renewalDate = !!isSubscribed && businessProfile?.renewalDate && formatDate(businessProfile.renewalDate);

  return isLoadingBusinessProfile ? (
    <PageLoader />
  ) : (
    <Grid container direction="column" className={root} spacing={2}>
      <Grid item container alignItems="center" xs={12}>
        <Grid container item xs={12}>
          <Typography className={heading}>
            <FormattedMessage id="dashboard.business_info.title" />
          </Typography>
        </Grid>
        <Grid container item direction="column" xs={4} sm={6} lg={4}>
          <Grid item>
            <Grid container item direction="column">
              <img src={businessProfile?.qrCode || qrCodeImage} alt="Qr Code" className={clsx(qRCode, opacityQrCode)} />
              <Typography className={captionTitle}>
                <FormattedMessage id="dashboard.business_info.your_qr_code" />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={8} sm={6} lg={8}>
          <Typography className={title}>{businessProfile?.name}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button className={download} onClick={onDownload} disabled={!isSubscribed}>
            <FormattedMessage id={businessProfile?.qrCode ? "btn_text_download" : "btn_text_not_available"} />
          </Button>
        </Grid>
      </Grid>
      <Grid item className={infoContLetterStyle}>
        <Typography>
          <FormattedMessage id="dashboard.business_info.joined_date" />
        </Typography>
        <Typography>{joinedDate || <FormattedMessage id="label_none" />}</Typography>
      </Grid>
      <Grid item className={infoContLetterStyle}>
        <Typography>
          <FormattedMessage id="dashboard.business_info.membership" />
        </Typography>
        <Typography>
          {isSubscribed && businessProfile?.membership ? (
            `${businessProfile.membership.title}`
          ) : (
            <FormattedMessage id="label_none" />
          )}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default BusinessInfo;
