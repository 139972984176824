import { Button, InputBase } from "@mui/material";
import { FormattedMessage } from "react-intl";
import Modal from "../../../Modal";
import useStyles from "./styles";

interface PaymodalTypes {
  modalActive: boolean;
  handleModal: () => void;
}

const Paymodal = ({ modalActive, handleModal }: PaymodalTypes) => {
  const { wrap, row, buttonsContainer } = useStyles();

  const handleCancel = () => {
    handleModal();
  };

  const handleSave = () => {
    handleModal();
  };

  return (
    <Modal isOpen={modalActive} onClose={handleModal}>
      <div className={wrap}>
        <FormattedMessage id="modals.payment_modal.title" />

        <div className={row}>
          <div className="item">
            <p>
              <FormattedMessage id="card_number" />
            </p>
            <InputBase placeholder="0000 0000 0000 0000" />
          </div>

          <div className="item">masterCard</div>
        </div>

        <div className={`${row} middleRow`}>
          <div className="item">
            <p>
              <FormattedMessage id="card_expiry" />
            </p>
            <InputBase placeholder="MM / YY" />
          </div>

          <div className="item">
            <p>
              <FormattedMessage id="card_cvc" />
            </p>
            <InputBase placeholder="000" />
          </div>
        </div>

        <div className={buttonsContainer}>
          <Button onClick={handleCancel}>
            <FormattedMessage id="btn_text_cancel" />
          </Button>
          <Button onClick={handleSave}>
            <FormattedMessage id="btn_text_save" />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default Paymodal;
