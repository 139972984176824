import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { getPaymentMethodsQuery } from "../../Checkout/PaymentMethods/graphql/GetPaymentMethods.query";
import { IPaymentMethod } from "../PaymentMethod/PaymentMethod.interfaces";

const CART_NUMBER_PREFIX = "**** **** ****";

export const useActivePaymentMethods = (
  userId: string,
): [activePaymentMethods: IPaymentMethod[], isActivePaymentMethodsLoading: boolean] => {
  const { data, loading } = useQuery(getPaymentMethodsQuery, {
    variables: { userId },
  });

  const activePaymentMethods = useMemo(
    () => [
      ...(data?.getActivePaymentMethods?.map((paymentMethod) => ({
        id: paymentMethod?.id ?? "",
        cardBrand: paymentMethod?.brand ?? "",
        cardOwner: paymentMethod?.owner ?? "",
        cardExpDate: `${paymentMethod?.expMonth ?? ""}/${paymentMethod?.expYear ?? ""}`,
        cardNumber: `${CART_NUMBER_PREFIX} ${paymentMethod?.lastFour ?? ""}`,
        addressLine: paymentMethod?.billingAddress?.addressLine1 ?? "",
        addressLineSecond: paymentMethod?.billingAddress?.addressLine2 ?? "",
        city: paymentMethod?.billingAddress?.city ?? "",
        state: paymentMethod?.billingAddress?.state ?? "",
        zipCode: paymentMethod?.billingAddress?.zipCode ?? "",
        countryCode: paymentMethod?.billingAddress?.countryCode ?? "",
        isDefault: paymentMethod?.isDefault ?? false,
        userPaymentMethodId: paymentMethod?.userPaymentMethodId ?? "",
      })) ?? []),
    ],
    [data],
  );

  return [activePaymentMethods, loading];
};
