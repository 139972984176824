import { FC, MouseEvent } from "react";
import { FormikProps } from "formik";
import { Alert, Box, Grid, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";

import { FormattedMessage } from "react-intl";
import useStyles from "../../styles";

import { BusinessScheduleType } from "../../../../../../../generated/types";
import { TriggerStepFormikValues, StepsFormikValues } from "../../../../../../../types/campaigns";

type Props = {
  formik: FormikProps<StepsFormikValues>;
};

const DayTrigger: FC<Props> = ({ formik }: Props) => {
  const theme = useTheme();
  const { wrap, fieldWrapper } = useStyles();

  const lowTrafficDayHandler = (event: MouseEvent<HTMLElement>, value: string) => {
    formik.setFieldValue("dayTrigger", value);
  };

  return (
    <Grid mt={{ md: "90px" }} pl="80px" container className={wrap} flexDirection="column">
      <Grid container item mb="35px">
        <Typography fontSize="0.75rem" color={theme.palette.label?.grey}>
          <FormattedMessage id="modals.triggers.select_the_trigger" />
        </Typography>
      </Grid>

      <Grid container item justifyContent={{ xs: "center", md: "start" }} className={fieldWrapper}>
        <ToggleButtonGroup
          exclusive
          value={formik.values.dayTrigger}
          onChange={lowTrafficDayHandler}
          className="lowTrafficDayTrigger"
          id="dayTrigger"
        >
          <ToggleButton value={BusinessScheduleType.Monday}>
            <FormattedMessage id="modals.triggers.mon" />
          </ToggleButton>

          <ToggleButton value={BusinessScheduleType.Tuesday}>
            <FormattedMessage id="modals.triggers.tue" />
          </ToggleButton>

          <ToggleButton value={BusinessScheduleType.Wednesday}>
            <FormattedMessage id="modals.triggers.wed" />
          </ToggleButton>

          <ToggleButton value={BusinessScheduleType.Thursday}>
            <FormattedMessage id="modals.triggers.th" />
          </ToggleButton>

          <ToggleButton value={BusinessScheduleType.Friday}>
            <FormattedMessage id="modals.triggers.fri" />
          </ToggleButton>

          <ToggleButton value={BusinessScheduleType.Saturday}>
            <FormattedMessage id="modals.triggers.sat" />
          </ToggleButton>

          <ToggleButton value={BusinessScheduleType.Sunday}>
            <FormattedMessage id="modals.triggers.sun" />
          </ToggleButton>
        </ToggleButtonGroup>

        {formik.touched.dayTrigger && formik.errors.dayTrigger && (
          <Box width="526px" marginTop="16px">
            <Alert severity="error">{formik.errors.dayTrigger}</Alert>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default DayTrigger;
