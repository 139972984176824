import { Dispatch, SetStateAction, useContext } from "react";
import { InputBase, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import { FormattedMessage } from "react-intl";
import useStyles from "./styles";
import { Periods } from "../../../../generated/types";
import { LanguageContext } from "../../../../contexts";

interface HeaderSelectTypes {
  rewardPurchase?: boolean;
  period?: Periods;
  setPeriod: Dispatch<SetStateAction<Periods | undefined>>;
  rewardOnPurchaseCount?: number | null;
  setRewardOnPurchaseCount?: any;
  isSubmitting?: boolean;
}

const HeaderSelect = ({
  rewardPurchase = false,
  period,
  rewardOnPurchaseCount,
  setRewardOnPurchaseCount,
  setPeriod,
  isSubmitting,
}: HeaderSelectTypes) => {
  const { language } = useContext(LanguageContext);
  const { wrap } = useStyles(language)();

  return (
    <div className={wrap}>
      <p className="title">
        <FormattedMessage id="modals.header_select.period" />
      </p>
      <FormControl sx={{ maxWidth: "250px" }}>
        <InputLabel sx={{ fontSize: 10 }} shrink size="small" id="demo-simple-select-label">
          <FormattedMessage id="modals.header_select.title" />
        </InputLabel>
        <Select
          disabled={!!isSubmitting}
          value={period ?? ""}
          onChange={(event) => {
            setPeriod(event.target.value as Periods);
          }}
        >
          <MenuItem value={Periods.Daily}>
            <FormattedMessage id="modals.header_select.daily" />
          </MenuItem>
          <MenuItem value={Periods.Weekly}>
            <FormattedMessage id="modals.header_select.weekly" />
          </MenuItem>
          <MenuItem value={Periods.Monthly}>
            <FormattedMessage id="modals.header_select.monthly" />
          </MenuItem>
          <MenuItem value={Periods.Quarterly}>
            <FormattedMessage id="modals.header_select.every_three" />
          </MenuItem>
          <MenuItem value={Periods.Yearly}>
            <FormattedMessage id="modals.header_select.yearly" />
          </MenuItem>
        </Select>
      </FormControl>

      {rewardPurchase && (
        <div className="purchaseInput">
          <FormattedMessage id="modals.header_select.reward_client" />
          <InputBase
            className="input"
            placeholder="0"
            value={rewardOnPurchaseCount ?? ""}
            onChange={(event) => {
              setRewardOnPurchaseCount(event.target.value);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default HeaderSelect;
