import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    wrap: {
      "& .MuiInputLabel-root": {
        transform: "translate(14px, 13px) scale(1)",
        fontSize: "12px",
        "&.MuiFormLabel-filled": {
          color: "transparent !important",
        },

        "&.Mui-focused": {
          color: theme.palette.diagram?.grey,
        },
      },

      "& .MuiOutlinedInput-root": {
        height: "33px",
        width: "180px",
        margin: "5px 0",

        "& .MuiOutlinedInput-notchedOutline": {
          border: `1px solid ${theme.palette.diagram?.grey} !important`,
        },
      },

      "& .details": {
        textDecoration: "underline",
        color: theme.palette.dashBoard?.blue,
      },

      "& .createCampaignWidget": {
        cursor: "pointer",
      },
    },
  };
});

export default useStyles;
