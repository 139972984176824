import { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import FakeGraph from "../../../../assets/images/fakeGraph.png";
import OnlineShop from "../../../../assets/images/online-shop.png";
import Social from "../../../../assets/images/medios-de-comunicacion-social.png";
import Centor from "../../../../assets/icons/tykIcons/Centor-icon@2x.png";
import { ReactComponent as CentorCharacter } from "../../../../assets/icons/tykIcons/rewardsIcon.svg";
import LoneStar from "../../../../assets/icons/tykIcons/LoneStar-icon@2x.png";
import LoneStarCharacter from "../../../../assets/icons/tykIcons/rewardsIconYelow.png";
import Polygon from "../../../../assets/icons/tykIcons/polygon.svg";
import { isValueNullOrUndefined } from "../../../../utils/functions";
import theme from "../../../../theme";
import useStyles from "./styles";
import { Data, data } from "./data";

const MainWidget = () => {
  const { wrap } = useStyles();
  const [state, setState] = useState<Data>({});

  useEffect(() => {
    setState(data);
  }, []);

  return (
    <Box className={wrap}>
      <Box className="cardHeader">
        <Typography
          fontFamily={theme.typography.fontFamily}
          fontSize="1rem"
          fontWeight={600}
          color={theme.palette.primary.dark}
        >
          <FormattedMessage id="analytics.main_widget.general_kpi" />
        </Typography>
      </Box>

      <Grid container justifyContent="space-between" flexDirection={{ sm: "column", lg: "row" }} mb="30px">
        <Box className="totalDistributed">
          <Box display="flex" flexDirection="row" alignItems="end">
            <CentorCharacter className="reward" />

            <img className="yellowCharacter" src={LoneStarCharacter} alt="LoneStarCharacter" height={46} />
          </Box>

          <Grid container alignItems="end" mb="0.375rem">
            <Typography
              mr="0.938rem"
              className={clsx("totalDistributedNumber", {
                inactive: isValueNullOrUndefined(state.totalTyksDistributed),
              })}
            >
              {state.totalTyksDistributed?.toLocaleString() || "0000"}
            </Typography>

            {!isValueNullOrUndefined(state.changesPercent) && (
              <Grid container width="max-content" alignItems="center">
                <img src={Polygon} alt="" className="totalDistributedIcon" />

                <Typography
                  fontFamily={theme.typography.fontFamily}
                  fontSize="1rem"
                  fontWeight={600}
                  color={theme.palette.analytics?.lightGreen}
                >
                  + ${state.changesPercent}
                </Typography>
              </Grid>
            )}
          </Grid>

          <Typography color={theme.palette.analytics?.grey} fontFamily={theme.typography.fontFamily} fontSize="0.75rem">
            <FormattedMessage id="analytics.main_widget.total_tyks_distributed" />
          </Typography>
        </Box>

        <Box className="chartDistributed">
          <Grid container alignItems="end">
            <Typography
              className={clsx("chartDistributedPercent", {
                inactive: isValueNullOrUndefined(state.tyksFromDistributedPercent),
              })}
            >
              {state.tyksFromDistributedPercent || "0"}%
            </Typography>

            <Typography
              fontFamily={theme.typography.fontFamily}
              fontSize="0.688rem"
              color={theme.palette.analytics?.grey}
            >
              <FormattedMessage id="analytics.main_widget.of_total_distributed" />
            </Typography>
          </Grid>

          <Box className="chartContainer">
            <img src={FakeGraph} alt="chart" className="fakeChart" />
          </Box>
        </Box>
      </Grid>

      <Grid container>
        <Typography
          mb="0.813rem"
          fontFamily={theme.typography.fontFamily}
          fontSize="0.688rem"
          color={theme.palette.analytics?.grey}
        >
          <FormattedMessage id="analytics.main_widget.tyks_break_down" />
        </Typography>

        <Box className="breakDownWrap">
          <Box className="breakDownItem">
            <Typography className="breakDownLabel">
              <FormattedMessage id="analytics.main_widget.social_interactions" />
            </Typography>

            <Box className="breakDownStatistic">
              <Typography
                className={clsx("breakDownStatisticValue", {
                  inactive: isValueNullOrUndefined(state.onlineShopInteractions),
                })}
              >
                {state.socialInteractions?.toLocaleString() || "000"}
              </Typography>

              <img src={Social} alt="" height={27} width={27} />
            </Box>
          </Box>

          <Box className="breakDownItem">
            <p className="breakDownLabel">
              <FormattedMessage id="analytics.main_widget.in_store_interactions" />
            </p>

            <Box className="breakDownStatistic">
              <Typography
                className={clsx("breakDownStatisticValue", {
                  inactive: isValueNullOrUndefined(state.onlineShopInteractions),
                })}
              >
                {state.onlineShopInteractions?.toLocaleString() || "000"}
              </Typography>

              <img src={OnlineShop} alt="" height={20} width={20} />
            </Box>
          </Box>

          <Box className="breakDownItem">
            <p className="breakDownLabel">
              <FormattedMessage id="tyks_name.centors" />
            </p>

            <Box className="breakDownStatistic">
              <Typography
                className={clsx("breakDownStatisticValue", {
                  inactive: isValueNullOrUndefined(state.onlineShopInteractions),
                })}
              >
                {state.centors?.toLocaleString() || "000"}
              </Typography>

              <img src={Centor} alt="" height={20} />
            </Box>
          </Box>

          <Box className="breakDownItem">
            <p className="breakDownLabel">
              <FormattedMessage id="tyks_name.lone_stars" />
            </p>

            <Box className="breakDownStatistic">
              <Typography
                className={clsx("breakDownStatisticValue", {
                  inactive: isValueNullOrUndefined(state.onlineShopInteractions),
                })}
              >
                {state.loneStars?.toLocaleString() || "000"}
              </Typography>

              <img src={LoneStar} alt="" height={26} />
            </Box>
          </Box>
        </Box>
      </Grid>
    </Box>
  );
};

export default MainWidget;
