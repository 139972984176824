import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    mainContainer: {
      backgroundColor: "#fffff",
      padding: "50px",
    },
    border: {
      "& .MuiTableCell-root": {
        fontSize: "16px",
        border: "1px solid  #DFE1E1",
        fontFamily: '"AdobeClean", "AdobeCleanSerif", "Poppins", "sans-serif"',
      },
    },
    color: {
      background: "#F3F3F3",
      "& .MuiTableCell-root": {
        fontSize: "14px",
        border: "none",
        textTransform: "uppercase",
      },
    },
    enrolledState: {
      "&:hover": {
        color: "#1473E6",
        cursor:'pointer',
      },
    },
  };
});

export default useStyles;
