import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    buttonsContainer: {
      [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
        justifyContent: "center",
      },

      "& .MuiButton-root": {
        height: "32px",
        padding: "0 27px",
        marginRight: "10px",
        color: theme.palette.diagram?.purple,
        border: `2px solid ${theme.palette.diagram?.purple}`,
        borderRadius: "16px",
        textTransform: "unset",
      },

      "&>.MuiButton-root": {
        "&:last-child": {
          marginRight: "0",
          color: theme.palette.diagram?.white,
          borderColor: theme.palette.diagram?.blue,
          background: theme.palette.diagram?.blue,
        },
      },
    },
  };
});

export default useStyles;
