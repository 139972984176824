import { FC } from "react";
import { Box } from "@mui/material";
import PsychographicsCard from "../PsychographicsCard";
import AnalyticsChart from "../../AnalyticsChart";
import { getOption } from "../../AnalyticsChart/helpers";

const values = {
  "Liberal Arts": 530,
  "Visual Arts": 400,
  "Culinary Arts": 460,
  Sports: 210,
  "Science / Technology": 380,
  Literature: 460,
  Music: 380,
  Others: 380,
};

const AudienceInterest: FC = () => {
  return (
    <PsychographicsCard headerTitle="analytics.rules.psychographics_card_titles.audience_interest">
      <Box minWidth="600px">
        <AnalyticsChart values={values} getOptions={getOption} />
      </Box>
    </PsychographicsCard>
  );
};

export default AudienceInterest;
