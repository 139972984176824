import { Box, IconButton, Typography } from "@mui/material";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import CashierIcon from "../../../../../assets/icons/tykIcons/cashier-icon@2x.png";
import theme from "../../../../../theme";
import { OpenIcon } from "../../../../../utils/icon";
import Centor from "../../../../../assets/icons/tykIcons/Centor-icon@2x.png";
import LoneStar from "../../../../../assets/icons/tykIcons/LoneStar-icon@2x.png";
import useStyles from "./styles";

const Promoters = () => {
  const { wrap } = useStyles();
  const isAccumulated = true;
  const isRegistered = true;

  return (
    <Box className={wrap} display="flex" flexDirection="column" marginTop="20px">
      <Box className="promotersHeader" display="flex" flexDirection="row" alignItems="flex-start">
        <img src={CashierIcon} alt="" height="32px" className={clsx({ inactive: !isAccumulated && !isRegistered })} />

        <Box display="flex" flexDirection="column" width="100%" marginLeft="10px">
          <Typography
            variant="h3"
            color={isAccumulated ? theme.palette.black : "#818FD4"}
            fontFamily={theme.typography.fontFamily}
            fontSize="1rem"
            fontWeight="bold"
          >
         <FormattedMessage id="analytics.promoters"/>
          </Typography>
          <Typography
            color={isAccumulated && !isRegistered ? "#D3202F" : "#797882"}
            fontFamily={theme.typography.fontFamily}
            fontSize="0.75rem"
          >
            {isAccumulated && isRegistered ? "1 Active" : "0 Active"}
          </Typography>
        </Box>
      </Box>

      <Box className="promotersItemWrap">
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <IconButton>
            <OpenIcon fill="#0364FF" />
          </IconButton>
        </Box>

        <Box className="promotersItemContent">
          <Box display="flex" flexDirection="row" marginTop="12px" marginRight="10px">
            <Typography color="#797882" fontFamily={theme.typography.fontFamily} fontSize="0.625rem">
              <FormattedMessage id="analytics.jackpot.accumulated" />
            </Typography>
          </Box>

          <Box className="promotersItemStatistic" marginRight="10px">
            <Typography
              color={theme.palette.black}
              fontFamily={theme.typography.fontFamily}
              fontSize="0.875rem"
              fontWeight="bold"
              marginRight="6px"
            >
              {isAccumulated ? "200".toLocaleString() : 0}
            </Typography>

            <img src={Centor} alt="Tyk" height={20} />
          </Box>

          <Box className="promotersItemStatistic">
            <Typography
              color={theme.palette.black}
              fontFamily={theme.typography.fontFamily}
              fontSize="0.875rem"
              fontWeight="bold"
              marginRight="6px"
            >
              {isAccumulated ? "50".toLocaleString() : 0}
            </Typography>

            <img src={LoneStar} alt="LoneStars" height={26} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Promoters;
