import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  summarySection: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  paymentMethodsSection: {
    width: "100%",
  },
  checkoutButton: {
    width: "50%",
    height: "46px",
    alignSelf: "flex-end",
    fontWeight: 600,
  },
  loader: {
    color: "#FFFFFF",
  },
}));
