import { ReactChild, KeyboardEvent } from "react";
import Box from "@mui/material/Box";
import { Modal as MuiModal } from "@mui/material";
import { useStyles, modalStyles } from "./styles";

interface ModalProps {
  isOpen: boolean;
  onClose: VoidFunction;
  children: ReactChild;
  isClosable?: boolean;
  [x: string]: any;
}
export default function Modal({ isOpen, onClose, children, isClosable = true, ...restProps }: ModalProps) {
  const { wrapContent, modal } = useStyles();

  const handleCloseEscape = (e: KeyboardEvent) => {
    if (e.keyCode) {
      onClose();
    }
  };

  return (
    <MuiModal open={isOpen} onClose={onClose} className={modal} sx={{ ...modalStyles.modal }}>
      <Box className={wrapContent} {...restProps}>
        {isClosable && (
          <span
            className="close-button"
            role="button"
            aria-label="Close Modal"
            tabIndex={0}
            onClick={onClose}
            onKeyPress={handleCloseEscape}
          />
        )}
        {children}
      </Box>
    </MuiModal>
  );
}
