import { FC, useRef } from "react";
import { FormikProps } from "formik";
import { useTheme } from "@mui/styles";
import { useIntl } from "react-intl";
import Icon from "../../../../../../utils/icon";
import { ITykchaseGlobalConfig } from "../../../../../../types/tykchaseGlobalConfig";

import { TykchaseCampaignTypes, TykchaseCampaignTriggers } from "../../../../../../generated/types";
import { CampaignSteps, StepsFormikValues } from "../../../../../../types/campaigns";
import CampaingLayout from "../Layout/CampaingLayout";

type Props = {
  active: boolean;
  handlerModal: () => void;
  tykchaseGlobalConfig?: ITykchaseGlobalConfig;
};

const LowTraffic: FC<Props> = ({ active, handlerModal, tykchaseGlobalConfig }: Props) => {
  const theme = useTheme();

  const informationRef = useRef<FormikProps<StepsFormikValues>>(null);
  const triggerRef = useRef<FormikProps<StepsFormikValues>>(null);
  const rewardRef = useRef<FormikProps<StepsFormikValues>>(null);
  const { formatMessage } = useIntl();

  return (
    <CampaingLayout
      active={active}
      handlerModal={handlerModal}
      informationRef={informationRef}
      triggerRef={triggerRef}
      rewardRef={rewardRef}
      title={formatMessage({ id: "rewards.campaigns.low_traffic.title" })}
      subTitle={formatMessage({ id: "rewards.campaigns.low_traffic.sub_title" })}
      mainBgColor={theme.palette.campaigns.purple}
      secondaryBgColor={theme.palette.campaigns.darkPurple}
      Icon={Icon.LowTrafficDays}
      paginationTitles={[
        formatMessage({ id: "rewards.campaigns.pagination_titles.information" }),
        formatMessage({ id: "rewards.campaigns.pagination_titles.trigger" }),
        formatMessage({ id: "rewards.campaigns.pagination_titles.reward_settings" }),
      ]}
      informationStepKey={`${TykchaseCampaignTypes.LowTrafficDays}-${CampaignSteps.Information}`}
      triggerType={TykchaseCampaignTriggers.Day}
      campaignType={TykchaseCampaignTypes.LowTrafficDays}
      rewardStepKey={`${TykchaseCampaignTypes.LowTrafficDays}-${CampaignSteps.Reward}`}
      triggerStepKey={`${TykchaseCampaignTypes.LowTrafficDays}-${CampaignSteps.Trigger}`}
      summaryStepKey={`${TykchaseCampaignTypes.LowTrafficDays}-${CampaignSteps.Summary}`}
      tykchaseGlobalConfig={tykchaseGlobalConfig}
    />
  );
};

export default LowTraffic;
