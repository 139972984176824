import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { ReactChild } from "react";

const SectionLayout = ({
  title,
  child,
  gridArea,
}: {
  title?: string;
  child: ReactChild;
  gridArea: string;
}) => {
  const theme = useTheme();
  return (
    <Grid gridArea={gridArea}>
      {title && (
        <Typography
          fontSize="0.75rem"
          color={theme.palette.secondary.main}
          mb="0.625rem"
        >
          {title} &gt;
        </Typography>
      )}
      {child}
    </Grid>
  );
};

export default SectionLayout;
