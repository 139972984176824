import { FC } from "react";
import { Box } from "@mui/material";
import PsychographicsCard from "../PsychographicsCard";
import AnalyticsChart from "../../AnalyticsChart";
import { getOption } from "../../AnalyticsChart/helpers";

const values = {
  "<20": 530,
  "20-30": 400,
  "31-40": 460,
  "41-50": 210,
  "51>": 380,
};

const AgeRange: FC = () => {
  return (
    <PsychographicsCard headerTitle="analytics.rules.psychographics_card_titles.age_range">
      <Box minWidth="280px">
        <AnalyticsChart values={values} getOptions={getOption} />
      </Box>
    </PsychographicsCard>
  );
};

export default AgeRange;
