import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    rootContainer: {
      backgroundColor: "#f3f3f3",
      minHeight: "100vh",
      "@media(max-width: 600px)": {
        paddingTop: 0,
      },
      "& .activeRulesAmount": {
        display: "flex",
        alignItems: "center",
        marginLeft: "auto",
        fontSize: "0.9rem",
        color: "#4B4B4B",

        "& .MuiTypography-root": {
          fontSize: "0.9rem",
          fontWeight: "600",
        },
      },

      "& .emptyTooltipIcon": {
        display: "flex",
        opacity: 0,
        height: "1.1rem",
      },

      "& .tabsContainer": {
        position: "relative",
        maxWidth: "100% !important",
        paddingLeft: "48px",
        "@media(max-width: 600px)": {
          paddingLeft: "12px",
        },

        "& .MuiTabs-scroller": {
          "@media(max-width: 600px)": {
            overflowX: "scroll !important",
          },
        },

        msOverflowStyle: "none",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
          display: "none",
        },

        "& .MuiGrid-container": {
          flexWrap: "nowrap",
          cursor: "pointer",

          "& .MuiGrid-item": {
            marginRight: "32px",
            "&:last-child": {
              marginRight: "0",
            },
          },
          "& p": {
            whiteSpace: "nowrap",
          },
        },

        "& .MuiTabs-indicator": {
          backgroundColor: "#4262FF",
        },

        "& .MuiTab-root": {
          fontFamily: theme.typography.fontFamily,
          fontWeight: 600,
          color: theme.palette.black,
          textTransform: "none",
          opacity: 0.5,

          "&:hover": {
            color: theme.palette.black,
            opacity: 1,
          },

          "&:focus": {
            color: theme.palette.black,
            opacity: 1,
          },
        },

        "& .Mui-selected": {
          color: theme.palette.black,
          borderBottom: "2px solid #4262FF",
          opacity: 1,
        },

        "& .Mui-disabled": {
          color: theme.palette.experiences.grey,
        },
      },

      "& .rewardsContainer": {
        "@media(max-width: 1200px)": {
          padding: "40px",
        },
        "@media(max-width: 600px)": {
          padding: "27px",
        },
        "& .buttonsContainer": {
          "@media(max-width: 900px)": {
            flexDirection: "column",
            "& button": {
              margin: "0",
              marginBottom: "5px",
            },
          },
        },
      },

      "& .filtersPanel": {
        flexWrap: "nowrap",
        "@media (max-width: 1200px)": {
          flexWrap: "wrap",
        },
      },
    },
  };
});

export default useStyles;
