import { Grid, InputBase, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import Modal from "../../../../Modal";
import Layout from "../Layout";
import useStyles from "./styles";
import Icon from "../../../../../utils/icon";

const RewardsAccumulated = ({
  modalActive,
  handlerModal,
}: {
  modalActive: boolean;
  handlerModal: () => void;
}) => {
  const { wrap } = useStyles();
  const theme = useTheme();

  return (
    <Modal isOpen={modalActive} onClose={handlerModal}>
      <Layout title="Rewards accumulated" mbTitle="25px">
        <Grid
          className={wrap}
          flexWrap="nowrap"
          flexDirection={{ xs: "column", md: "column" }}
          container
          p={{ xs: "15px 0 0", sm: "15px 50px", md: "15px 0 0" }}
          borderTop={`1px solid ${theme.palette.diagram?.purple}`}
        >
          <Typography m="0 auto 40px">Your actual Tyks accumulated</Typography>

          <Grid
            container
            flexWrap="nowrap"
            flexDirection={{ xs: "column", md: "row" }}
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              container
              flexDirection="column"
              alignItems="end"
              maxWidth="105px"
              mr={{ xs: "0", md: "100px" }}
              mb={{ xs: "20px", md: "0" }}
            >
              <Grid container alignItems="end" flexWrap="nowrap" mb="35px">
                <Icon.CentorsFull />
                <Typography
                  ml="5px"
                  fontWeight="bold"
                  fontSize="1.375rem"
                  color={theme.palette.diagram?.purple}
                >
                  2,000
                </Typography>
              </Grid>

              <Grid mb="10px">
                <InputBase />
              </Grid>

              <Typography mb="25px" color={theme.palette.diagram?.purple}>
                amount to add
              </Typography>

              <Grid container alignItems="center" flexWrap="nowrap">
                <Typography mr="26px" color={theme.palette.diagram?.purple}>
                  Total
                </Typography>
                <Typography fontSize="1.375rem" fontWeight="bold" color={theme.palette.black}>
                  2,000
                </Typography>
              </Grid>
            </Grid>
            <Grid container flexDirection="column" alignItems="end" maxWidth="105px">
              <Grid container alignItems="end" flexWrap="nowrap" mb="35px">
                <Icon.LoneStars />
                <Typography
                  ml="5px"
                  mb="4px"
                  fontWeight="bold"
                  fontSize="1.375rem"
                  color={theme.palette.diagram?.purple}
                >
                  2,000
                </Typography>
              </Grid>

              <Grid mb="10px">
                <InputBase />
              </Grid>

              <Typography mb="25px" color={theme.palette.diagram?.purple}>
                amount to add
              </Typography>

              <Grid container alignItems="center" flexWrap="nowrap">
                <Typography mr="26px" color={theme.palette.diagram?.purple}>
                  Total
                </Typography>
                <Typography fontSize="1.375rem" fontWeight="bold" color={theme.palette.black}>
                  2,000
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Layout>
    </Modal>
  );
};

export default RewardsAccumulated;
