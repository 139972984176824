import { CircularProgress } from "@mui/material";
import { FC } from "react";

import { useStyles } from "./styles";

interface PageLoaderProps {
  isInline?: boolean;
}

const DEFAULT_PROGRESS_SIZE = 40;
const INLINE_PROGRESS_SIZE = 16;

export const PageLoader: FC<PageLoaderProps> = ({ isInline }) => {
  const styles = useStyles();

  return (
    <div className={!isInline ? styles.container : styles.inlineContainer} data-testid="page-loader">
      <CircularProgress size={isInline ? INLINE_PROGRESS_SIZE : DEFAULT_PROGRESS_SIZE} />
    </div>
  );
};
