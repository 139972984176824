import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  input: {
    width: "100%",
    "& .MuiInputBase-input": {
      padding: `0 ${theme.spacing(1)}`,
      height: "32px",
      fontSize: "12px",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: '#cacaca',
      },

      "&.Mui-focused fieldset": {
        borderColor: 'blue',
      },
    },
  },

  autocomplete: {
    padding: 0,

    "& .MuiAutocomplete-inputRoot": {
      padding: "0",
      "& .MuiInputBase-input": {
        padding: `0 ${theme.spacing(1)}`,
        height: "32px",
      },
      "& .MuiSvgIcon-root": {
        width: "15px",
        height: "15px",
      },
    },

    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: '#cacaca',
      },

      "&.Mui-focused fieldset": {
        borderColor: 'blue',
      },
    },

    "& .MuiInputBase-input": {
      fontSize: "12px",
    },
  },

  onOptions: {
    display: "none",
  },

  label: {
    fontFamily: "AdobeClean, AdobeCleanSerif, Poppins, sans-serif",
    fontSize: "12px",
    color: "#6E6E6E",
  },

  alert: {
    "&.MuiAlert-root": {
      backgroundColor: "#FFF2E2",
      color: "#5C2122",
      fontSize: "0.75rem",
      padding: "2px 4px",
      marginTop: "2px",

      "& .MuiAlert-icon": {
        color: "#FF983B",
        margin: 0,
        padding: 0,
        alignSelf: "center",

        "& MuiSvgIcon-root": {
          width: "10px",
          height: "10px",
        },
      },

      "& .MuiAlert-message": {
        margin: "0 0 0 6px",
        padding: 0,
        overflow: "hidden",
        alignSelf: "center",
      },
    },
  },
}));
