import { FC, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import { isValueNullOrUndefined } from "../../../../utils/functions";
import theme from "../../../../theme";
import useStyles from "./styles";

const EMPTY_VALUE = "none";

type Summary = {
  receiptsScanTime?: string | null;
  averageReceiptAmount?: number | null;
  highestRewardsDay?: string | null;
  followersIncreaseRate?: number | null;
  unfollowingRate?: number | null;
};

const summary: Summary = {
  receiptsScanTime: "12:00 m - 2:30 pm",
  averageReceiptAmount: 180,
  highestRewardsDay: "Friday",
  followersIncreaseRate: 4,
  unfollowingRate: 0.05,
};

const Insights: FC = () => {
  const { wrap } = useStyles();
  const [summaryData, setSummaryData] = useState<Summary | null>(null);

  const getClassNames = (value?: string | number | null) => clsx("title", { inactive: isValueNullOrUndefined(value) });

  useEffect(() => {
    setSummaryData(summary);
  }, []);

  return (
    <Box className={wrap}>
      <Box className="sectionHeaderWrapper">
        <Typography
          fontFamily={theme.typography.fontFamily}
          fontSize="1rem"
          fontWeight={600}
          color={theme.palette.primary.dark}
        >
          <FormattedMessage id="analytics.insights.title" />
        </Typography>
      </Box>

      <Box className="sectionItemWrapper">
        <Typography className={getClassNames(summaryData?.receiptsScanTime)}>
          {isValueNullOrUndefined(summaryData?.receiptsScanTime) ? EMPTY_VALUE : summaryData?.receiptsScanTime}
        </Typography>

        <Typography className="text">
          <FormattedMessage id="analytics.insights.common_time" />
        </Typography>
      </Box>

      <Box className="sectionItemWrapper">
        <Typography className={getClassNames(summaryData?.averageReceiptAmount)}>
          {isValueNullOrUndefined(summaryData?.averageReceiptAmount)
            ? EMPTY_VALUE
            : `$${summaryData?.averageReceiptAmount}`}
        </Typography>

        <Typography className="text">
          <FormattedMessage id="analytics.insights.average_receipt_amount" />
        </Typography>
      </Box>

      <Box className="sectionItemWrapper">
        <Typography className={getClassNames(summaryData?.highestRewardsDay)}>
          {isValueNullOrUndefined(summaryData?.highestRewardsDay) ? EMPTY_VALUE : summaryData?.highestRewardsDay}
        </Typography>

        <Typography className="text">
          <FormattedMessage id="analytics.insights.highest_tyk_rewards" />
        </Typography>
      </Box>

      <Box className="sectionItemWrapper">
        <Typography className={getClassNames(summaryData?.followersIncreaseRate)}>
          {isValueNullOrUndefined(summaryData?.followersIncreaseRate)
            ? EMPTY_VALUE
            : `${summaryData?.followersIncreaseRate}%`}
        </Typography>

        <Typography className="text success">
          <FormattedMessage id="analytics.insights.followers_increase_rate" />
        </Typography>
      </Box>

      <Box className="sectionItemWrapper">
        <Typography className={getClassNames(summaryData?.unfollowingRate)}>
          {isValueNullOrUndefined(summaryData?.unfollowingRate) ? EMPTY_VALUE : `${summaryData?.unfollowingRate}%`}
        </Typography>

        <Typography className="text error">
          <FormattedMessage id="analytics.insights.unfollowing_rate" />
        </Typography>
      </Box>
    </Box>
  );
};

export default Insights;
