import { Box, Tooltip } from "@mui/material";
import { ReactComponent as ExclamationIcon } from "../../../assets/icons/tykIcons/exclamation.svg";
import { formatDollars } from "../../shared/PriceFormatters";
import { useStyles } from "./styles";
import { IDepositBox } from "./types";
import { PageLoader } from "../../PageLoader";

export const DepositBox = ({ title, amount, editButton, tooltipContent, isDataLoading }: IDepositBox) => {
  const { arrow, tooltip } = useStyles();

  return (
    <Box>
      {tooltipContent && (
        <Box display="flex" justifyContent="flex-end">
          <Tooltip title={tooltipContent} placement="top-end" arrow classes={{ arrow, tooltip }}>
            <ExclamationIcon />
          </Tooltip>
        </Box>
      )}
      <Box color="#34305D">{title}</Box>
      <Box display="flex" justifyContent="flex-end" gap="8px">
        <Box>{editButton}</Box>
        <Box color="#4B4B4B" fontWeight="bold">
          {isDataLoading ? <PageLoader isInline /> : formatDollars(parseFloat(amount.toString()))}
        </Box>
      </Box>
    </Box>
  );
};
