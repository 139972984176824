import { ApplicationFeatures } from "../../../../types/applicationFeaturesEnum";
import withMembershipFunctionality from "../../../HOCs/withMembershipFunctionality";
import AwardItem from "../RewardDistributionModal/Diagram/AwardItem";
import DiagramItem from "../RewardDistributionModal/Diagram/DiagramItem";

export const TotalDistributionCentorsComponent = withMembershipFunctionality(
  DiagramItem,
  ApplicationFeatures.TotalDistributionCentors,
);

export const TotalDistributionLoneStarsComponent = withMembershipFunctionality(
  DiagramItem,
  ApplicationFeatures.TotalDistributionLoneStars,
);

export const TotalDistributionJackpotComponent = withMembershipFunctionality(
  DiagramItem,
  ApplicationFeatures.TotalDistributionJackpot,
);

export const TotalDistributionCashierComponent = withMembershipFunctionality(
  DiagramItem,
  ApplicationFeatures.TotalDistributionCashier,
);

export const TotalDistributionAwardComponent = withMembershipFunctionality(
  AwardItem,
  ApplicationFeatures.TotalDistributionMinLoneStars,
);
