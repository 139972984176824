import { FC, useState } from "react";
import { Button, ButtonBase, Divider, Grid, Typography } from "@mui/material";
import SponsorIcon from "../../../../assets/icons/tykIcons/sponsor-small.png";
import FanatyksLogo from "../../../../assets/icons/tykIcons/fanatyks-logo.svg";
import Modal from "../../../Modal";
import useStyles from "./styles";

const SponsorButton: FC = () => {
  const { sponsorContainer } = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <ButtonBase onClick={handleOpenModal}>
        <img src={SponsorIcon} alt="sponsor" />
      </ButtonBase>

      <Modal onClose={handleCloseModal} isOpen={isModalOpen} isClosable={false}>
        <Grid container direction="column" className={sponsorContainer}>
          <Grid className="modal-header">
            <h1 className="modal-header__title">You are about to be redirected to</h1>

            <img src={FanatyksLogo} alt="fanatyks-logo" />
          </Grid>

          <Divider className="modal-divider" />

          <Grid className="modal-body">
            <Typography>
              Fanaktyk suites is our dedicated platform to sponsor Event Suites, make your suites more attractive by
              selecting a celebrity from our database among some other perks your customer will love.
            </Typography>

            <Typography className="extra">The process is super easy, try it right now !</Typography>
          </Grid>

          <Grid className="modal-footer">
            <Button onClick={handleCloseModal} variant="contained" className="cancel-button">
              Cancel
            </Button>
            <Button onClick={handleCloseModal} variant="contained" className="primary-button">
              Continue
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default SponsorButton;
