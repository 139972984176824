import { Box } from "@mui/material";
import Jackpot from "./Jackpot";
import Promoters from "./Promoters";
import useStyles from "./styles";

const JackpotAndPromoters = () => {
  const { wrap } = useStyles();
  return (
    <Box className={wrap}>
      <Jackpot />
      <Promoters />
    </Box>
  );
};

export default JackpotAndPromoters;
