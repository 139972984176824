import { makeStyles } from "@mui/styles";
import { Typography, Button, Grid } from "@mui/material";
import theme from "../theme";

interface IPageProps {
  image: string;
  title: string;
  description: any;
  button?: boolean;
}

const useStyles = makeStyles(() => {
  return {
    root: {
      padding: "5rem",
      [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
        padding: "2rem",
      },
      "& h5": {
        color: "#34305D",
        fontWeight: 700,
      },
      "& p": {
        color: "#8986AD",
        fontSize: "1.2rem",
      },
    },
    enroll: {
      backgroundColor: "#0364FF",
      color: "#fff",
      textTransform: "none",
      borderRadius: "3rem",
      padding: "0.5rem 1rem",
      width: "max-content",
      alignSelf: "flex-end",
    },
  };
});

const FallBackPages = ({ title, description, image, button }: IPageProps) => {
  const { root, enroll } = useStyles();

  return (
    <Grid container alignItems="stretch" justifyContent="center" className={root}>
      <Grid container item lg={6}>
        <img style={{ height: "15rem", width: "25rem" }} src={image} alt="Man Walking" />
      </Grid>

      <Grid container item lg={6} xl={6} justifyContent="flex-start" direction="column">
        <Typography variant="h5" gutterBottom fontFamily={theme.typography.fontFamily}>
          {title}
        </Typography>

        <Typography gutterBottom fontFamily={theme.typography.fontFamily} whiteSpace="pre-line">
          {description}
        </Typography>

        {button && <Button className={enroll}>Enroll in membership</Button>}
      </Grid>
    </Grid>
  );
};

export default FallBackPages;
