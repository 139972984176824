import { Button } from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { useStyles } from "./styles";
import Modal from "../../Modal";


interface DepositRechargeSuccessModalProps {
  isActive: boolean;
  onClose: (id: any) => void;
  id:string;
}

export const SuccessModal: FC<DepositRechargeSuccessModalProps> = ({
  isActive,
  onClose: handleClose,
  id,
}) => {
  const styles = useStyles();
  return (
    <Modal isOpen={isActive} onClose={()=>{handleClose(id);}}>
      <div className={styles.modalContent}>
        <h1 className={styles.modalHeader}>
          <FormattedMessage id="tyk_deposit_settings" />
        </h1>

        <div className={styles.successMessage}>
          <TaskAltIcon className={styles.successIcon} />
        </div>
        <Button className={styles.okButton} type="button" onClick={handleClose} classes={{ root: "root" }}>
          <FormattedMessage id="btn_text_ok" />
        </Button>
      </div>
    </Modal>
  );
};
