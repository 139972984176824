import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    viewDetailsButton: {
      color: "#4262FF",
      fontFamily: theme.typography.fontFamily,
      fontSize: "0.75rem",
      textDecoration: "underline",
      cursor: "pointer",
    },
  };
});

export default useStyles;
