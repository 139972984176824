import { FC, createContext, useMemo } from "react";
import { FeatureFlags } from "../types/contexts";

const isMvp = process.env.REACT_APP_IS_MVP === "true";

export const FeatureFlagsContext = createContext<FeatureFlags>({
  isAnalyticsV2Enabled: false,
  isMarketingV2Enabled: false,
  isTechnologyV2Enabled: false,
  isSpecialCampaignV2Enabled: false,
  isExperiencesV2Enabled: false,
  isJackpotV2Enabled: false,
  isPromotersV2Enabled: false,
  isRewardDistributionV2Enabled: false,
  isExperiencesTabOfQRCodeV2Enabled: false,
  isNoMembershipType: false,
  isYourPaymentMethodAtBillingPageEnabled: false,
  isEnrollControlEnabled: false,
  isCategoriesFilterForRewardsEnabled: false,
});

export const FeatureFlagsProvider: FC = ({ children }) => {
  const contextValue = useMemo(
    (): FeatureFlags => ({
      isAnalyticsV2Enabled: !isMvp,
      isMarketingV2Enabled: !isMvp,
      isTechnologyV2Enabled: !isMvp,
      isSpecialCampaignV2Enabled: !isMvp,
      isExperiencesV2Enabled: !isMvp,
      isJackpotV2Enabled: !isMvp,
      isPromotersV2Enabled: !isMvp,
      isNoMembershipType: !isMvp,
      isRewardDistributionV2Enabled: !isMvp,
      isExperiencesTabOfQRCodeV2Enabled: !isMvp,
      isYourPaymentMethodAtBillingPageEnabled: !isMvp,
      isEnrollControlEnabled: !isMvp,
      isCategoriesFilterForRewardsEnabled: isMvp,
    }),
    [],
  );

  return <FeatureFlagsContext.Provider value={contextValue}>{children}</FeatureFlagsContext.Provider>;
};
