import { ChangeEvent, useMemo, useState, useEffect } from "react";
import { Alert, Grid, InputBase, Tooltip } from "@mui/material";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import useStyles from "./styles";
import { ReactComponent as RewardsIcon } from "../../../../assets/icons/tykIcons/rewardsIcon.svg";
import { ReactComponent as Exclamation } from "../../../../assets/icons/tykIcons/exclamation.svg";
import { ReactComponent as Usd } from "../../../../assets/icons/tykIcons/usd.svg";
import { ReactComponent as PercentageIcon } from "../../../../assets/icons/tykIcons/percentage.svg";
import RewardsIconYelow from "../../../../assets/icons/tykIcons/rewardsIconYelow.png";
import Jackpot from "../../../../assets/icons/tykIcons/Jackpot-Icon(1)@2x.png";
import Cashier from "../../../../assets/icons/tykIcons/cashier-icon@2x.png";
import { DiagramData, RatesPropertyNames } from "./types";
import ComingSoonLabel from "../../../Utils/ComingSoonLabel";
import { InfoTooltip, infoTooltipStyleProps } from "../../../Tooltips";
import { TooltipType } from "../../../../types/tooltips";
import { NUMBERS_ONLY_PATTERN, TWO_DIGITS_AFTER_COMMA_DECIMAL_PATTERN } from "../../regexp";
import { PatternInput } from "../../../shared/PatternInput";
import { calculateRewards } from "./Diagram.helpers";

export const Diagram = ({
  rewardUsd,
  isPercentage,
  setInputData,
  setRewardSplit,
  margin,
  width,
  formik,
  tykchaseGlobalConfig,
  backendData,
  purchaseRewardRate,
  isSubmitting,
}: DiagramData) => {
  const { alert, diagramWrap, item, equivalent, split, hiddenDiagramMessage, greyOut } = useStyles();
  const [dollarReward, setDollarReward] = useState(rewardUsd);
  const [isFlipPercentageItem, setIsFlipPercentageItem] = useState(false);
  const rewardMonetaryValue = backendData?.purchaseRewardRate === purchaseRewardRate ? rewardUsd : "";
  const onBlurInput = (data: string) => {
    setInputData?.(data || "0");
  };
  const handlerPercentage = () => {
    if (isPercentage) setIsFlipPercentageItem(!isFlipPercentageItem);
  };

  const dollarRewardHandler = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setDollarReward(e.target.value);
    if (formik) {
      formik.getFieldProps("dollarReward").onChange(e);
    }
  };

  useEffect(() => {
    if (purchaseRewardRate) {
      setInputData?.(rewardMonetaryValue || "0");
      setDollarReward(rewardMonetaryValue);
      if (formik) {
        formik.setFieldValue(
          "dollarReward",
          parseFloat(rewardMonetaryValue as string) || 0,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseRewardRate]);

  const validateRateStyle = (rate: string) => {
    const value =
      tykchaseGlobalConfig?.customTykAwardsDistribution?.[rate] || tykchaseGlobalConfig?.tykAwardsDistribution?.[rate];

    return !!value && !!Number(value);
  };

  const getRateValue = (rate: string) => {
    const value =
      tykchaseGlobalConfig?.customTykAwardsDistribution?.[rate] || tykchaseGlobalConfig?.tykAwardsDistribution?.[rate];
    if (value || value === 0 || value === "0.00") {
      return `${value}%`;
    }
    return "N/A";
  };

  const calculatedRewards = useMemo(
    () => {
      const rewards = calculateRewards(formik.values.dollarReward, tykchaseGlobalConfig);

      setRewardSplit?.(rewards);

      return rewards;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dollarReward],
  );

  return (
    <Grid mt={formik?.touched?.dollarReward && formik?.errors?.dollarReward ? "122px" : "60px"}>
      <Grid>
        {formik?.touched?.dollarReward && formik?.errors?.dollarReward && (
          <Grid
            className={!formik.errors.dollarReward ? clsx(hiddenDiagramMessage) : ""}
            container
            item
            maxWidth="132px"
            mb={{ sm: "-130px" }}
          >
            <Alert className={alert} severity="error">
              {formik.errors.dollarReward}
            </Alert>
          </Grid>
        )}
      </Grid>
      <Grid className={clsx(diagramWrap)} flexDirection={{ xs: "column", sm: "row" }} m={margin} width={width}>
        <div
          className={clsx(item, "first", {
            isPercentage,
            isFlipPercentageItem,
          })}
          onClick={handlerPercentage}
          onKeyPress={() => {}}
          role="button"
          tabIndex={0}
        >
          <div className="itemTop">
            {isPercentage || (
              <Tooltip
                title={<InfoTooltip type={TooltipType.DOLLAR_FIELD_INFO} />}
                componentsProps={infoTooltipStyleProps}
                arrow
                placement="top"
              >
                <Exclamation />
              </Tooltip>
            )}
            {isFlipPercentageItem && (
              <Tooltip
                title={<InfoTooltip type={TooltipType.DOLLAR_FIELD_INFO} />}
                componentsProps={infoTooltipStyleProps}
                arrow
                placement="top"
              >
                <Exclamation />
              </Tooltip>
            )}
          </div>

          <div className="itemMiddle">
            <PatternInput
              {...formik?.getFieldProps("dollarReward")}
              value={formik?.values?.dollarReward || ""}
              pattern={isPercentage ? NUMBERS_ONLY_PATTERN : TWO_DIGITS_AFTER_COMMA_DECIMAL_PATTERN}
              onClick={(e) => e.stopPropagation()}
              onChange={dollarRewardHandler}
              onBlur={() => onBlurInput(formik?.values?.dollarReward || dollarReward || "")}
              placeholder={isPercentage ? "0%" : "$0.00"}
              disabled={!!isSubmitting}
            />
            {isPercentage ? <PercentageIcon className="rewardsIcon" /> : <Usd className="rewardsIcon" />}
          </div>

          <div className="itemBottom">
            {isFlipPercentageItem || <p>{isPercentage ? <FormattedMessage id="modals.purchase_percentage"/> : "USD$"}</p>}
          </div>
        </div>

        <div className={equivalent}>
          <span />
          <span />
          <span />
        </div>

        <div className={split}>
          <div className="splitTip">
            <Tooltip
              title={<InfoTooltip type={TooltipType.REWARD_SPLIT_INFO} />}
              componentsProps={infoTooltipStyleProps}
              arrow
              placement="top"
            >
              <Exclamation />
            </Tooltip>
            <p><FormattedMessage id="modals.your_reward"/></p>
          </div>

          <div className="splitTop">
            <div className="splitTopChild">
              <p>{getRateValue(RatesPropertyNames.CENTORS_RATE)}</p>
            </div>
            <div className="splitTopParent" />
            <div className="splitTopChild">
              <p>{getRateValue(RatesPropertyNames.LONE_STARS_RATE)}</p>
            </div>
            <div className="splitTopParent inside" />
            <div className="splitTopChild">
              <p>{getRateValue(RatesPropertyNames.JACKPOT_AWARD_RATE)}</p>
            </div>
            <div className="splitTopChild">
              <p>{getRateValue(RatesPropertyNames.PROMOTER_ARWARD_RATE)}</p>
            </div>
          </div>

          <div className="splitMidle">
            <div
              className={clsx(item, "splitItem", !validateRateStyle(RatesPropertyNames.CENTORS_RATE) && greyOut, {
                isPercentage,
              })}
            >
              <div className="itemTop">
                <Tooltip
                  title={<InfoTooltip type={TooltipType.CENTORS_FIELD_INFO} />}
                  componentsProps={infoTooltipStyleProps}
                  arrow
                  placement="top"
                >
                  <Exclamation />
                </Tooltip>
              </div>
              <div className="itemMiddle">
                <InputBase placeholder={calculatedRewards.centorsRewards} type="number" disabled />
                <RewardsIcon className="rewardsIcon" />
              </div>
              <div className="itemBottom">
                <p><FormattedMessage id="tyks_name.centors"/></p>
              </div>
            </div>

            <div>
              <div
                className={clsx(item, "splitItem", !validateRateStyle(RatesPropertyNames.LONE_STARS_RATE) && greyOut, {
                  isPercentage,
                })}
              >
                <div className="itemTop" />
                <div className="itemMiddle">
                  <InputBase placeholder={calculatedRewards.loneStarsRewards} type="number" disabled />
                  <img src={RewardsIconYelow} alt="icon" />
                </div>
                <div className="itemBottom">
                  <p><FormattedMessage id="tyks_name.lone_stars"/></p>
                </div>
              </div>
              <ComingSoonLabel />
            </div>
            <div>
              {" "}
              <div
                className={clsx(
                  item,
                  "splitItem",
                  !validateRateStyle(RatesPropertyNames.JACKPOT_AWARD_RATE) && greyOut,
                  {
                    isPercentage,
                  },
                )}
              >
                <div className="itemTop" />
                <div className="itemMiddle">
                  <InputBase placeholder={`$${calculatedRewards.jackpotRewards}`} type="number" disabled />
                  <img src={Jackpot} alt="icon" />
                </div>
                <div className="itemBottom">
                  <p><FormattedMessage id="modals.jackpot"/></p>
                </div>
              </div>
              <ComingSoonLabel />
            </div>
            <div>
              <div
                className={clsx(
                  item,
                  "splitItem",
                  !validateRateStyle(RatesPropertyNames.PROMOTER_ARWARD_RATE) && greyOut,
                  {
                    isPercentage,
                  },
                )}
              >
                <div className="itemTop" />
                <div className="itemMiddle">
                  <InputBase placeholder={`$${calculatedRewards.promoterRewards}`} type="number" disabled />
                  <img src={Cashier} alt="icon" />
                </div>
                <div className="itemBottom">
                  <p><FormattedMessage id="modals.cashier"/></p>
                </div>
              </div>
              <ComingSoonLabel />
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};
