import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    modalHeader: {
      display: "flex",
      justifyContent: "space-between",

      [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
        flexDirection: "column",
      },

      "& .left-side": {
        "& h1": {
          fontSize: "18px",
          fontWeight: "normal",
          color: theme.palette.primary.dark,
        },

        "& p": {
          color: theme.palette.diagram?.grey,
          fontSize: "12px",
        },
      },
    },

    modalBody: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      "& .item": {
        height: "97px",
        maxWidth: "132px",
        width: "100%",
        marginRight: "30px",
        borderRadious: "3px",
        background: "#F2F2F2",

        "&:last-child": {
          marginRight: "0",
        },
      },
    },

    modalFooter: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      marginBottom: "50px",
      "& .hint": {
        marginRight: "140px",
        color: theme.palette.diagram?.grey,
        fontSize: "12px",
      },

      "& .summaryOutput div": {
        display: "flex",
        marginBottom: "6px",
        fontSize: "10px",

        "& p:first-child": {
          color: theme.palette.primary.dark,
        },

        "& p:last-child": {
          marginLeft: "3px",
          color: theme.palette.diagram?.blue,
        },

        "&:last-child": {
          marginBottom: "0",
        },
      },
    },
    buttonsContainer: {
      display: "flex",
      justifyContent: "flex-end",

      "& .MuiButton-root.removeBtn": {
        border: "none",
        background: theme.palette.analytics?.red,
        color: theme.palette.white,
      },

      [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
        justifyContent: "center",
      },

      "& .MuiButton-root": {
        height: "32px",
        padding: "0 27px",
        marginRight: "10px",
        color: theme.palette.diagram?.purple,
        border: `2px solid ${theme.palette.diagram?.purple}`,
        borderRadius: "16px",
        fontWeight: "bold",
        textTransform: "unset",
        "&:last-child": {
          marginRight: "0",
          color: theme.palette.diagram?.white,
          borderColor: theme.palette.diagram?.blue,
          background: theme.palette.diagram?.blue,
        },
      },
    },
  };
});

export default useStyles;
