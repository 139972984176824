import { ReactChild, useState, RefObject, useImperativeHandle } from "react";
import { FormikProps } from "formik";
import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useMutation } from "@apollo/client";
import useStyles from "./styles";
import ButtonsContainer from "./ButtonsContainer";
import Pagination from "./Pagination";
import { CREATE_CAMPAIGN } from "../../../../../../apollo/mutations";
import { CAMPAIGN_MODAL_STEPS } from "../../../../../../constants";

import { TykchaseCampaignTriggers, TykchaseCampaignTypes } from "../../../../../../generated/types";
import {
  InformationStepFormikValues,
  RewardStepFormikValues,
  StepsFormikValues,
  TriggerStepFormikValues,
} from "../../../../../../types/campaigns";

interface LayoutProps {
  title: ReactChild | string;
  subtitle: string;
  children: Array<ReactChild>;
  mainBgColor: string;
  secondaryBgColor: string;
  icon: ReactChild;
  paginationTitles: Array<string>;
  handlerModal: () => void;
  informationRef: RefObject<FormikProps<StepsFormikValues>>;
  triggerRef: RefObject<FormikProps<StepsFormikValues>>;
  rewardRef: RefObject<FormikProps<StepsFormikValues>>;
  formik: FormikProps<StepsFormikValues>;
  currentStep: number;
  handlerBackCurrentStep: () => void;
  handleChangeStep: () => void;
}

const Layout = ({
  title,
  subtitle,
  mainBgColor,
  secondaryBgColor,
  icon,
  children,
  paginationTitles,
  handlerModal,
  informationRef,
  triggerRef,
  rewardRef,
  formik,
  currentStep,
  handlerBackCurrentStep,
  handleChangeStep,
}: LayoutProps) => {
  const theme = useTheme();
  const { layout } = useStyles();

  const isDoubleTripleCampaign = formik.values.campaignType === TykchaseCampaignTypes.DoubleTriple;

  const [createCampaign, { data: createCampaignData, loading: isCreateCampaignLoading, error: createCampaignError }] =
    useMutation(CREATE_CAMPAIGN);

  const handlerNextCurrentStep = async () => {
    if (currentStep === CAMPAIGN_MODAL_STEPS.INFORMATION_STEP && informationRef?.current) {
      informationRef.current.handleSubmit();

      const errors = await informationRef.current.validateForm();
      if (!Object.keys(errors).length) {
        handleChangeStep();
      }
    }

    if (currentStep === CAMPAIGN_MODAL_STEPS.TRIGGER_STEP && triggerRef?.current) {
      triggerRef.current.handleSubmit();

      const errors = await triggerRef.current.validateForm();

      if (!Object.keys(errors).length) {
        handleChangeStep();
      }
    }
    let formattedValues;

    if (!Object.keys(formik.errors).length && formik.dirty) {
      formattedValues = {
        ...formik.values,
        startDate: new Date(formik.values.startDate).toISOString(),
        endDate: new Date(formik.values.endDate).toISOString(),
        dollarReward: parseFloat(formik.values.dollarReward),
      };
    }
    const deleteUnUsedKeys = () => {
      Object.entries(formattedValues).forEach(([key, value]) => {
        if (!value) {
          delete formattedValues[key];
        }
      });
    };

    if (currentStep === CAMPAIGN_MODAL_STEPS.REWARD_STEP) {
      if (isDoubleTripleCampaign) {
        deleteUnUsedKeys();
        try {
          await createCampaign({
            variables: formattedValues,
          });
          handlerModal();
        } catch (e) {
          // ignore
        }
      } else if (rewardRef?.current) {
        rewardRef.current.handleSubmit();

        const errors = await rewardRef.current.validateForm();

        if (!Object.keys(errors).length) {
          handleChangeStep();
        }
      }
    }

    if (currentStep === CAMPAIGN_MODAL_STEPS.SUMMARY_STEP && !isDoubleTripleCampaign) {
      deleteUnUsedKeys();
      let variables = {};
      if (formattedValues.triggerType === TykchaseCampaignTriggers.ProductOrService) {
        variables = {
          ...formattedValues,
          tykchaseProducts: [
            {
              name: formattedValues.name,
              code: formattedValues.code,
              price: formattedValues.price,
            },
          ],
        };
      } else {
        variables = {
          ...formattedValues,
        };
      }

      try {
        await createCampaign({
          variables: {
            ...variables,
          },
        });
        handlerModal();
      } catch (e) {
        // ignore
      }
    }
  };

  return (
    <Grid
      className={layout}
      container
      direction="column"
      justifyContent={{ xs: "space-between", sm: "start" }}
      height={{ xs: "100%", sm: "auto" }}
    >
      <Grid
        container
        alignItems="center"
        mb="35px"
        bgcolor={mainBgColor}
        borderRadius="6px"
        overflow="hidden"
        flexWrap="nowrap"
      >
        <Grid p="20px 16px" pl="25px" bgcolor={secondaryBgColor}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            height="87px"
            width="87px"
            mr="-54px"
            bgcolor={secondaryBgColor}
            borderRadius="100px"
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              height="70px"
              width="70px"
              bgcolor={theme.palette.campaigns.dark}
              borderRadius="100px"
            >
              {icon}
            </Grid>
          </Grid>
        </Grid>

        <Grid ml={{ xs: "50px", sm: "66px" }}>
          <Typography color={theme.palette.primary.dark} fontSize="1.188rem">
            {title}
          </Typography>

          <Typography color={theme.palette.campaigns.dark} fontSize="0.938rem">
            {subtitle}
          </Typography>
        </Grid>
      </Grid>

      <Pagination titles={paginationTitles} currentStep={currentStep} />

      <Grid p={{ xs: "0 10px 20px", sm: "0 40px 60px", md: "0 62px 72px" }} height="40vh" width="100%" overflow="auto">
        {children[currentStep]}
      </Grid>

      <ButtonsContainer
        currentStep={currentStep}
        handleNext={handlerNextCurrentStep}
        handleCancel={handlerModal}
        handleBack={handlerBackCurrentStep}
        isDoubleTripleCampaign={isDoubleTripleCampaign}
      />
    </Grid>
  );
};

export default Layout;
