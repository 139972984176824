export type Data = {
  totalTyksDistributed?: number;
  changesPercent?: number;
  tyksFromDistributedPercent?: number;
  socialInteractions?: number;
  onlineShopInteractions?: number;
  centors?: number;
  loneStars?: number;
};

export const data: Data = {
  totalTyksDistributed: 2654,
  changesPercent: 9.78,
  tyksFromDistributedPercent: 75,
  socialInteractions: 1000,
  onlineShopInteractions: 1654,
  centors: 2000,
  loneStars: 654,
};
