import { MessageDescriptor } from "react-intl";
import { Memberships } from "../../types/membershipTypes";
import { isPossibleUpgradePlan } from "../Utils/helpers";

const enabledPlans: Set<Memberships | string> = new Set([Memberships.Business, "Negocios"]);

export const isEnabledPlan = (title): boolean => enabledPlans.has(title);

export const buttonText = (membershipTitle, title): MessageDescriptor["id"] => {
  if (membershipTitle === title || (membershipTitle === Memberships.Business && title === "Negocios")) {
    return "btn_text_my_membership";
  }

  if (!membershipTitle || !isEnabledPlan(title)) {
    return isEnabledPlan(title) ? "btn_text_enroll" : "btn_text_coming_soon";
  }

  return isEnabledPlan(title) && isPossibleUpgradePlan(membershipTitle, title)
    ? "btn_text_upgrade_membership"
    : "btn_text_enroll";
};
