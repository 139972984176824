import Holidays from "./Holidays";
import DoubleTriple from "./DoubleTriple";
import LowTraffic from "./LowTraffic";
import Surprise from "./Surprise";
import Product from "./Product";

export default {
  Holidays,
  DoubleTriple,
  LowTraffic,
  Surprise,
  Product,
};
