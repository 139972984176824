import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => {
  return {
    wrap: {
      paddingTop: "32px",
      paddingLeft: "32px",
      "@media(max-width:900px)": {
        marginBottom: "20px",
      },
    },

    topSection: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "6px",
      fontSize: "12px",
      "& span": {
        textDecoration: "underline",
        color: "#4262FF",
        cursor: "pointer",
      },
    },

    bellowSection: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      position: "relative",
      maxWidth: "299px",
      overflow: "hidden",
      border: "1px solid #CDCCD7",
      borderRadius: "5px",
      fontSize: "9px",
      "& .left": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "50px",
        width: "max-content",
        background: "#34305D",
        "& .wrap": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "25px",
          width: "33px",
          margin: "0 13px",
          border: "1px solid white",
          borderRadius: "3px",

          "& svg": {
            width: "14px",
            height: "11px",
          },
        },
      },

      "& p": {
        minWidth: "115px",
        margin: "0 10px",
        marginRight: "30px",
        color: "#131313",
        letterSpacing: "1.3px",
      },

      "& .mastercardIcon": {
        marginRight: "10px",
      },
    },
  };
});

export default useStyles;
