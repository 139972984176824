import { FC } from "react";
import { Grid } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { CardCvcElement } from "@stripe/react-stripe-js";
import { useStyles } from "./styles";
import { CardErrorMessage } from "../CardErrorMessage";
import { CardMessageStatus, CustomInputStyleOptions, EventStripe } from "./CardForm.types";

interface CardCvcInputProps {
  cardMessageStatus: CardMessageStatus | null;
  customInputStyleOptions: (formatMessageId: string) => CustomInputStyleOptions;
  onChangeErrorHandler: (event: EventStripe) => void;
}

export const CardCvcInput: FC<CardCvcInputProps> = ({
  cardMessageStatus,
  customInputStyleOptions,
  onChangeErrorHandler,
}) => {
  const styles = useStyles();

  return (
    <Grid className={styles.cvv}>
      <label htmlFor="card-cvc" className={styles.label}>
        <FormattedMessage id="card_cvc" />
      </label>
      <CardCvcElement
        id="card-cvc"
        className={styles.input}
        options={customInputStyleOptions("card_cvc_placeholder")}
        onChange={onChangeErrorHandler}
      />
      <CardErrorMessage
        cardMessageStatus={cardMessageStatus}
        emptyErrorMessageId="card_cvc_error_empty"
        invalidErrorMessageId="card_cvc_error_invalid"
      />
    </Grid>
  );
};
