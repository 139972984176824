import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    layout: {
      "& .MuiFormControl-root": {
        height: "32px",
        width: "324px",
        marginTop: "10px",
        "& .MuiInputLabel-root": {
          top: "-8px",
          fontSize: "0.75rem",
        },

        "& .MuiOutlinedInput-root": {
          height: "100%",
        },
      },

      "& textarea": {
        width: "100%",
        padding: "17px 12px",
        border: `1px solid ${theme.palette.campaigns.borderGrey}`,
        borderRadius: "3px",
        resize: "none",
        fontSize: "0.75rem",
      },

      "& input": {
        height: "32px",
        flexDirection: "row-reverse",
        padding: "10px",
        fontSize: "1rem",
        color: theme.palette.diagram?.purple,
        background: "transparent",
        border: `1px solid ${theme.palette.campaigns.borderGrey}`,
        borderRadius: "3px",
        textAlign: "center",
        "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
          WebkitAppearance: "none",
          margin: "0",
        },

        [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
          padding: "6px",
        },
      },

      '& input[type="date"]': {
        maxWidth: "130px",
        width: "100%",
        flexDirection: "row",
        "&::-webkit-calendar-picker-indicator": {
          marginTop: "2px",
          paddingLeft: "5px",
          opacity: "0.3",
        },
      },

      "& .labelCoverTemplate": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "120px",
        width: "90px",
        padding: "2px",
        marginRight: "5px",
        border: `1px solid ${theme.palette.campaigns.borderGrey}`,
        borderRadius: "3px",
        cursor: "pointer",
        "& .MuiTypography-root": {
          textDecoration: "underline",
        },

        "& #inputCoverTemplate": {
          display: "none",
        },
      },

      "& .details": {
        textDecoration: "underline",
        color: theme.palette.dashBoard?.blue,
        cursor: "pointer",
      },

      "& .paginationItem": {
        "&.active": {
          "& .innerCircle": {
            background: theme.palette.campaigns.dark,

            "& .MuiTypography-root": {
              color: theme.palette.white,
            },
          },

          "& .title": {
            color: theme.palette.primary.dark,
          },
        },

        "&.past": {
          "& .innerCircle": {
            background: theme.palette.analytics?.lightGreen,
            "& .MuiTypography-root": {
              color: theme.palette.white,
            },
          },
          "& .title": {
            color: theme.palette.primary.dark,
          },
        },
      },

      "& .paginationDecor.past": {
        borderColor: theme.palette.analytics?.lightGreen,
      },
    },
  };
});

export default useStyles;
