import { InputBase } from "@mui/material";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import RewardsIconYelow from "../../../../../assets/icons/tykIcons/rewardsIconYelow.png";

const AwardItem = ({ isFunctionalityAvailable, onChange, getInputsValue }) => {
  return (
    <div
      className={clsx("subContainer", {
        notAvailable: !isFunctionalityAvailable,
      })}
    >
      <p><FormattedMessage id="modals.minimum"/></p>
      <img src={RewardsIconYelow} alt="icon" />
      <p><FormattedMessage id="modals.award"/></p>
      <InputBase
        placeholder={getInputsValue || "5"}
        type="number"
        inputProps={{ min: "0" }}
        onChange={onChange}
      />
    </div>
  );
};

export default AwardItem;
