import { flatten } from "../utils/flatten.util";
import en from "./en.json";
import es from "./es.json";

export const locales = {
  EN: { value: "en", label: "English" },
  ES: { value: "es", label: "Spanish" },
};

export const messages = {
  [locales.EN.value]: flatten(en),
  [locales.ES.value]: flatten(es),
};

export enum LocalStorageKey {
  SELECTED_LOCALE = "SELECTED_LOCALE",
}
