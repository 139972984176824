import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    tableContainer: {
      "& .MuiTableCell-head": {
        padding: "10px",
        backgroundColor: "#E9E9E9CC",
        color: "#797882",
        fontFamily: theme.typography.fontFamily,
        fontSize: "0.75rem",
        lineHeight: "normal",
        border: "none",
      },

      "& .MuiTableRow-root": {
        "&:hover": {
          backgroundColor: "#E9E9E966",
        },

        "& .MuiTableCell-head:first-child": {
          paddingLeft: "50px",
        },

        "& .MuiTableCell-head:last-child": {
          paddingRight: "50px",
        },

        "& .MuiTableCell-body": {
          padding: "10px",
          color: "#797882",
          fontFamily: theme.typography.fontFamily,
          fontSize: "0.75rem",
          lineHeight: "normal",
          border: "none",

          "& .amount-container": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",

            "& .amount-item": {
              display: "flex",
              flex: 1,
              flexDirection: "row",
              alignItems: "center",

              "& img": {
                width: "20px",
                height: "24px",
              },

              "& .lonestart-container": {
                marginBottom: "5px",
              },

              "& p": {
                paddingLeft: "8px",
              },
            },
            "& .amount-item:first-child": {
              marginRight: "10px",
            },
          },

          "& .actions-container": {
            display: "flex",
            flexDirection: "row",

            "& .actions-item": {
              padding: "0 6px",

              "& .MuiSvgIcon-root": {
                width: "20px",
                height: "20px",
                fill: "#0364FF",
                "&:hover": {
                  fill: "#203ED4",
                },
              },
            },
          },
        },
        "& .MuiTableCell-body:first-child": {
          paddingLeft: "50px",
        },

        "& .MuiTableCell-body:last-child": {
          paddingRight: "50px",
        },
      },
    },

    paginationContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "30px",

      "& .MuiTablePagination-root": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "end",

        "& .MuiToolbar-root": {
          padding: 0,
        },

        "& .MuiTablePagination-selectLabel": {
          color: "#797882",
          fontFamily: theme.typography.fontFamily,
          fontSize: "0.75rem",
        },

        "& .MuiTablePagination-select": {
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          paddingLeft: "15px",
          color: "#797882",
          fontFamily: theme.typography.fontFamily,
          fontSize: "0.75rem",
        },

        "& .MuiInputBase-root": {
          width: "71px",
          height: "31px",
          marginRight: "12px",
          border: "2px solid #4262FF",
          borderRadius: "3px",

          "& .MuiSvgIcon-root": {
            width: "20px",
            height: "20px",
            fill: "#4262FF",
          },
        },

        "& .MuiTablePagination-displayedRows": {
          color: "#797882",
          fontFamily: theme.typography.fontFamily,
          fontSize: "0.75rem",
        },

        "& .MuiTablePagination-actions": {
          marginLeft: "6px",

          "& .MuiButtonBase-root": {
            padding: "6px",
          },

          "& .MuiSvgIcon-root": {
            width: "18px",
            height: "18px",
            fill: "#797882",
          },
        },
      },

      "& .MuiTypography-root": {
        color: "#4262FF",
        fontSize: "0.75rem",
        textDecoration: "underline",
        cursor: "pointer",
      },
    },
  };
});

export default useStyles;
