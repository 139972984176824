import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  modalHeader: {
    fontSize: "19px",
    fontWeight: 600,
    color: "#323232",
    width: "405px",
    borderBottom: "2px solid #EAEAEA",
    paddingBottom: "17px",
    marginBottom: "17px",
  },
  inputLabel: {
    fontSize: "15px",
    color: "#505050",
    marginBottom: "28px",
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    position: "relative",
  },
  modalFooter: {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: "30px",

    "& .address-button": {
      height: "32px",
      padding: "0 27px",
      marginRight: "10px",
      color: theme.palette.diagram?.purple,
      border: `2px solid ${theme.palette.diagram?.purple}`,
      borderRadius: "16px",
      textTransform: "unset",

      "&.select-button": {
        marginRight: "0",
        color: theme.palette.diagram?.white,
        borderColor: theme.palette.diagram?.blue,
        background: theme.palette.diagram?.blue,
      },

      "& .loading-indicator": {
        color: "#fff",
      },
    },
  },
}));
