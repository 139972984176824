import { useMemo, useState } from "react";
import { Grid } from "@mui/material";

import { useQuery } from "@apollo/client";
import { GET_ALL_RULES, GET_MEMBERSHIP } from "../../../apollo/queries";
import AnimatedLoader from "../../AnimatedLoader";
import PaymentSummary from "../PaymentSummary";
import { PlansList } from "./PlansList";
import RulesList from "./RulesList/RulesList";
import { MembershipRule } from "./types";

import useStyles from "./styles";
import { CancellationModal } from "./CancellationModal";
import { useBusinessProfile } from "../../../hooks/useBusinessProfile.hook";
import { getAllMembershipsQuery } from "../graphql/GetAllMemberships.query";
import { RenewalPeriod, TykchaseConfigStatus } from "../../../gql/graphql";
import { useQueryParameterState } from "../../../hooks/useQueryParameterState.hook";
import { filteredRules } from "../../Utils/helpers";

export interface IMembership {
  id: number;
  name: string;
  description: string;
  price: number;
  type: string;
}

export const MembershipSummary = () => {
  const { mainContainer, insideContainer, choosePlanContainer } = useStyles();

  const [currentPlan, setCurrentPlan] = useState("");
  const [internalId, setInternalId] = useState<number>();
  const [isCancellationMempershipModal, setIsCancellationMempershipModal] = useState(false);
  const [renewalPeriod, setRenewalPeriod] = useQueryParameterState<RenewalPeriod>(
    "renewalPeriod",
    RenewalPeriod.Monthly,
  );
  const [businessProfile, , isSubscribedUser] = useBusinessProfile();

  const isEditMode = businessProfile?.membership?.internalId === internalId && isSubscribedUser;
  const handleChangeCurrentPlan = (plan: string, id: number) => {
    setInternalId(id);
    setCurrentPlan(plan);
  };

  const { loading: membershipsLoading, data: membershipsData } = useQuery(getAllMembershipsQuery);
  const memberships = membershipsData?.getAllMemberships || [];
  const currentPlanId = memberships?.find((membership) => membership?.internalId === internalId)?.id ?? "";
  const { loading: membershipLoading, data: membershipData } = useQuery(GET_MEMBERSHIP, {
    variables: {
      id: currentPlanId,
    },
    skip: !currentPlanId,
  });
  const membership = membershipData?.getMembershipWithRules?.payload;
  const membershipRules = membership?.rules ?? [];
  const mandatoryDepositAmount = parseFloat(membership?.mandatoryDepositAmount) || 0;
  const locationsCountLimit = membership?.locationsCountLimit ?? 0;

  const { loading: rulesLoading, data: rulesData } = useQuery(GET_ALL_RULES);
  const allRules = useMemo(
    () => filteredRules(rulesData, businessProfile?.isStagewoodProfile) ?? {},
    [businessProfile?.isStagewoodProfile, rulesData],
  );

  const renderRulesList = () => {
    if (!membershipRules) {
      return null;
    }
    return <RulesList membershipRules={membershipRules} allRules={allRules} />;
  };

  const toggleCancellationMempershipModal = () => {
    setIsCancellationMempershipModal(!isCancellationMempershipModal);
  };

  const period = isEditMode ? businessProfile?.renewalPeriod ?? RenewalPeriod.Monthly : renewalPeriod;

  return (
    <Grid className={mainContainer}>
      <Grid container>
        <Grid item container alignItems="flex-start" xl={8} lg={7} md={12} xs={12} className={insideContainer}>
          <Grid container item className={choosePlanContainer}>
            <Grid>
              <Grid width="100%" container item flexDirection="row" justifyContent="center">
                {membershipLoading ? (
                  <AnimatedLoader width={80} height={80} />
                ) : (
                  <PlansList
                    handleChangePlan={handleChangeCurrentPlan}
                    isLoading={membershipsLoading}
                    readOnly={isSubscribedUser}
                    locationsCountLimit={locationsCountLimit}
                    mandatoryDepositAmount={mandatoryDepositAmount}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item container xs={12} display="flex" flexDirection="column" justifyContent="center">
            {rulesLoading ? <AnimatedLoader width={80} height={80} /> : renderRulesList()}
          </Grid>
        </Grid>

        <Grid
          item
          xl={4}
          lg={5}
          md={12}
          xs={12}
          container
          justifyContent="center"
          alignItems="flex-start"
          style={{ backgroundColor: "#ffffff" }}
        >
          <PaymentSummary
            name={currentPlan}
            isEditMode={isEditMode}
            membershipId={currentPlanId}
            renewalPeriod={period}
          />
        </Grid>
      </Grid>
      {isEditMode && businessProfile?.status !== TykchaseConfigStatus.CancellationScheduled && (
        <CancellationModal
          open={isCancellationMempershipModal}
          onClose={toggleCancellationMempershipModal}
          userId={businessProfile?.id ?? ""}
        />
      )}
    </Grid>
  );
};
