import { FC } from "react";
import { Box } from "@mui/material";
import PsychographicsCard from "../../Psychographics/PsychographicsCard";
import AnalyticsChart from "../../AnalyticsChart";
import { getOption } from "../../AnalyticsChart/helpers";

const values = {
  Doral: 530,
  Westchester: 250,
  "West Palm Beach": 140,
  Orlando: 210,
  Jacksonville: 330,
  "Daytona Beach": 460,
  "Palm Coast": 190,
  Others: 280,
};

const AudienceLocation: FC = () => {
  return (
    <PsychographicsCard headerTitle="analytics.chart_titles.audience_location">
      <Box minWidth="620px">
        <AnalyticsChart values={values} getOptions={getOption} height={260} />
      </Box>
    </PsychographicsCard>
  );
};

export default AudienceLocation;
