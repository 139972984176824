import clsx from 'clsx';
import { ReactChild } from 'react';
import useStyles from './styles';

const DiagramItem = ({
  isPercentage,
  isFlipProcentageItem,
  secondaryClassName,
  objectClassNames,
  children,
  isFeatureAvailable = true,
  ...props
}: {
  isPercentage?: boolean,
  isFlipProcentageItem?: boolean,
  secondaryClassName?: string,
  objectClassNames: Object,
  children: ReactChild,
  isFeatureAvailable?: boolean,
  [x: string]: any;
}) => {
  const { item } = useStyles();

  return (
    <div
      className={clsx(item, secondaryClassName, {
        ...objectClassNames,
        notAvailable: !isFeatureAvailable,
      })}
      {...props}
    >
      {children}
    </div>
  );
};

export default DiagramItem;
