import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { stringify } from "qs";
import { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import { AMOUNT_PARAM_KEY, DEPOSIT_RECHARGE_MODAL_PARAM_KEY } from "../DepositSettings.consts";
import { createDepositCartMutation } from "./graphql/CreateDepositCart.mutation";

const CENTS_IN_DOLLAR = 100;

const useCheckoutRedirect = (): ((cartId: string, amount: number) => void) => {
  const history = useHistory();
  const location = useLocation();

  return useCallback(
    (cartId: string, amount: number) => {
      const successPageUrl = `${location.pathname}${stringify(
        {
          [DEPOSIT_RECHARGE_MODAL_PARAM_KEY]: true,
          [AMOUNT_PARAM_KEY]: amount,
        },
        { addQueryPrefix: true },
      )}`;

      history.push(`/checkout/${cartId}${stringify({ successPageUrl }, { addQueryPrefix: true })}`);
    },
    [history, location],
  );
};

const useCreateDepositCartMutation = (): [(businessId: string, addressId: string, amount: number) => void, boolean] => {
  const { enqueueSnackbar } = useSnackbar();
  const redirectToCheckout = useCheckoutRedirect();
const { formatMessage } = useIntl();
  const [createDepositCart, { loading }] = useMutation(createDepositCartMutation);

  const handleCreateDepositCart = async (businessId, addressId, amount) => {
    const variables = {
      businessId,
      addressId,
      amountInCents: Number((amount * CENTS_IN_DOLLAR).toFixed(2)),
    };

    await createDepositCart({
      variables,
      onCompleted: (data) => {
        const cartId = data.createDepositCart?.payload?.id;

        if (!cartId) {
          enqueueSnackbar(formatMessage({ id: "errors.error_creating_deposit_card" }), { variant: "error" });
        } else {
          redirectToCheckout(cartId, amount);
        }
      },
    });
  };


  return [handleCreateDepositCart, loading];
};

export const useSubmitHandler = (businessId: string): [(amount: number, addressId: string) => void, boolean] => {
  const [handleCreateDepositCart, loading] = useCreateDepositCartMutation();

  const handleSubmit = useCallback(
    (amount, addressId) => {
      handleCreateDepositCart(businessId, addressId, amount);
    },
    [handleCreateDepositCart, businessId],
  );

  return [handleSubmit, loading];
};
