import { stringify, parse } from "qs";
import { useLocation } from "react-router-dom";
import { SUCCESS_PAYMENT_METHOD_UPDATE_PARAM } from "../../TykChaseMembershipNotification.consts";

export const useUpdatePaymentDetailsLink = (): string => {
  const { pathname, search } = useLocation();
  const successPaymentMethodUpdateUrl = `${pathname}${stringify(
    { ...parse(search, { ignoreQueryPrefix: true }), [SUCCESS_PAYMENT_METHOD_UPDATE_PARAM]: true },
    { addQueryPrefix: true },
  )}`;

  return `/checkout/update-payment-method${stringify(
    { redirect: successPaymentMethodUpdateUrl },
    { addQueryPrefix: true },
  )}`;
};
