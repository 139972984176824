import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    membershipRuleRow: {
      marginBottom: "2px",
      borderRadius: "3px",
      transition: "all 300ms ease-in-out",
      paddingRight: "10px",

      "@media(max-width: 600px)": {
        "& .editBtn": {
          backgroundColor: "#0364FF !important",
        },
      },
      "@media(min-width: 600px)": {
        "&:hover": {
          backgroundColor: "#F3F3F3",
          "& .editBtn": {
            backgroundColor: "#0364FF",
          },
        },
      },
      "&.selected": {
        backgroundColor: "#E8ECF6",
        "& .ruleName": {
          color: "#12111A",
        },
        "& .editBtn": {
          backgroundColor: "#0364FF",
        },
      },
      "&.active": {
        "& .ruleName": {
          color: "#12111A",
        },
        "& .editBtn": {
          backgroundColor: "#0364FF",
        },
        "& .deactivateRuleBtn": {
          backgroundColor: "#FFBE40",
        },
        "& .publishedWarning": {
          opacity: "0",
          width: 0,
        },
        "& .rewardsIcon": {
          width: "29px",
          marginLeft: "-4px",
          opacity: "1",
        },
        "& .withoutRewardsIcon": {
          opacity: "0",
          width: 0,
        },
        "& .ruleRewards": {
          color: "#12111A",
        },
      },
      "&:not(.active)": {
        "& .publishedDone": {
          opacity: "0",
          width: 0,
        },
      },
      "& .tableBodyRowContent": {
        borderBottom: "1px solid rgba(212, 212, 212, 0.4)",
      },
      "& .ruleName": {
        maxWidth: "max-content",
        marginRight: "10px",
        transition: "all 300ms ease-in-out",
        color: "#797882",
      },
      "& .publishedWarning, .publishedDone, .withoutRewardsIcon, .rewardsIcon": {
        transition: "all 300ms ease-in-out",
      },
      "& .publishedWarning": {
        height: "24px",
        width: "24px",
        "@media(max-width: 1200px)": {
          height: "18px",
          width: "18px",
        },
      },
      "& .withoutRewardsIcon path": {
        fill: "#CDCCD7",
      },
      "& .actionBtn": {
        backgroundColor: "#CDCCD7",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "28px",
        width: "28px",
        marginLeft: "10px",
        transition: "all 300ms ease-in-out",
        "@media(max-width: 1200px)": {
          height: "18px",
          width: "18px",
        },
        "& svg": {
          height: "16px",
          width: "16px",
          color: "#ffffff",
          "@media(max-width: 1200px)": {
            height: "10px",
            width: "10px",
          },
        },
        "& .editIcon": {
          height: "12px",
          width: "12px",
          marginLeft: "2px",
          "@media(max-width: 1200px)": {
            height: "8px",
            width: "8px",
          },
        },
      },
      "& .ruleRewards": {
        position: "relative",
        color: "#797882",
        fontSize: "1rem",
        fontWeight: 600,
        transition: "all 300ms ease-in-out",

        "& .withoutRewardsIcon": {
          height: "15px",
          width: "20px",
          marginLeft: "8px",
          marginBottom: "-2px",
          "@media(max-width: 1200px)": {
            height: "12px",
            width: "18px",
          },
          "& tspan": {
            fontSize: "9px",
            fontFamily: "Poppins-Bold, Poppins",
          },
        },

        "& .rewardsIcon": {
          opacity: "0",
          width: 0,
          height: "34px",
          marginBottom: "-2px",
        },
      },
      "& .ruleIcon": {
        position: "relative",
        fontSize: "1rem",
        fontWeight: 600,
        transition: "all 300ms ease-in-out",
      },
      "& .ruleActions": {
        color: "#4262FF",
        fontSize: "1rem",
        fontWeight: 600,
        transition: "all 300ms ease-in-out",
        position: "relative",
        paddingRight: "12px",
      },
    },
    checkBox: {
      "@media(max-width: 600px)": {
        paddingRight: "0 !important",
        marginLeft: "10px !important",
      },
      "& .MuiCheckbox-colorSecondary.Mui-checked": {
        color: "#4DA17E",
      },
      "& .MuiCheckbox-root": {
        color: "#797882",
      },
      "& .PrivateSwitchBase-input:checked + svg.MuiSvgIcon-root": {
        fill: "#4DA17E",
      },
    },
  };
});

export default useStyles;
