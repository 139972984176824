import { graphql } from "../../../gql";


export const getDraftLocationSetupCartQuery = graphql(`
  query getDraftLocationSetupCart($input: CreateLocationSetupCartInput!) {
    getDraftLocationSetupCart(input: $input) {
      message
      payload {
        id
        taxInCents
        status
        lineItems {
          productId
          type
          priceInCents
          label
          quantity
        }
      }
    }
  }
`);
