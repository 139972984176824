import { graphql } from "../gql";
import { AccountLanguage } from "../gql/graphql";

export const getAccountLanguageQuery = graphql(`
  query getAccountLanguageInfo($id: String!) {
    fetchAccountLanguage(id: $id) {
      payload
    }
  }
`);

export type AccountLanguagePayload = NonNullable<AccountLanguage["payload"]>;
