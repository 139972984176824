import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { ExclamationTriangle } from "../../../../utils/icon";
import theme from "../../../../theme";
import AnalyticsCard from "../../AnalyticsCard";
import ExperiencesItem from "./ExperiencesItem";
import { Campaign } from "../../types";
import { campaigns } from "../../data";
import useStyles from "./styles";

const Experiences = () => {
  const { emptyStateWrap } = useStyles();
  const [activeCampaign, setActiveCampaign] = useState<Campaign[]>([]);
  useEffect(() => {
    setActiveCampaign(campaigns);
  }, []);

  return (
    <AnalyticsCard
      headerTitle="analytics.experiences.title"
      headerSubtitle="analytics.experiences.sub_title"
      cardColor="#FFEFE2"
      dataLength={activeCampaign ? activeCampaign.length : 0}
      isExperiences
    >
      {activeCampaign && activeCampaign.length ? (
        activeCampaign
          .slice(0, 2)
          .map((campaign) => <ExperiencesItem itemData={campaign} itemBackgroundColor="#FFDDC2" key={campaign.id} />)
      ) : (
        <Box className={emptyStateWrap}>
          <ExclamationTriangle fill="#827164" />
          <Typography marginLeft="10px" color="#797882" fontFamily={theme.typography.fontFamily} fontSize="0.75rem">
            <FormattedMessage id="analytics.experiences.no_experience" />
          </Typography>
        </Box>
      )}
    </AnalyticsCard>
  );
};

export default Experiences;
