import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { getClassNames, renderValue } from "../helpers";
import theme from "../../../../theme";
import useStyles from "./styles";

const EMPTY_VALUE = "none";

type InsightsData = {
  higherRevenues?: string | null;
  higherRevenuesAgeRange?: string | null;
};

const data: InsightsData = {
  higherRevenues: "Female",
  higherRevenuesAgeRange: "20 - 30",
};

const PsychographicsInsights: FC = () => {
  const { wrap } = useStyles();

  return (
    <Box className={wrap}>
      <Box className="sectionHeaderWrapper">
        <Typography
          fontFamily={theme.typography.fontFamily}
          fontSize="1rem"
          fontWeight={600}
          color={theme.palette.primary.dark}
        >
          <FormattedMessage id="analytics.rules.psychographics_insights.title" />
        </Typography>
      </Box>

      <Box className="sectionItemWrapper">
        <Typography className={getClassNames(data?.higherRevenues)}>
          {renderValue(EMPTY_VALUE, data?.higherRevenues)}
        </Typography>

        <Typography className="text">
          <FormattedMessage id="analytics.rules.psychographics_insights.higher_revenues" />
        </Typography>
      </Box>

      <Box className="sectionItemWrapper">
        <Typography className={getClassNames(data?.higherRevenuesAgeRange)}>
          {renderValue(EMPTY_VALUE, data?.higherRevenuesAgeRange)}
        </Typography>

        <Typography className="text">
          <FormattedMessage id="analytics.rules.psychographics_insights.higher_revenues_age_range" />
        </Typography>
      </Box>
    </Box>
  );
};

export default PsychographicsInsights;
