import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Alert } from "@mui/material";
import { useStyles } from "./styles";
import { CardMessageStatus } from "../CardForm/CardForm.types";
import { MessageIds } from "../../../../i18n";

interface CardErrorMessageProps {
  cardMessageStatus: CardMessageStatus | null;
  emptyErrorMessageId: MessageIds;
  invalidErrorMessageId: MessageIds;
}

export const CardErrorMessage: FC<CardErrorMessageProps> = ({
  cardMessageStatus,
  emptyErrorMessageId,
  invalidErrorMessageId,
}) => {
  const styles = useStyles();

  return (
    <>
      {cardMessageStatus === CardMessageStatus.Invalid && (
        <Alert severity="error" className={styles.message}>
          <FormattedMessage id={invalidErrorMessageId} />
        </Alert>
      )}
      {cardMessageStatus === CardMessageStatus.Empty && (
        <Alert severity="error" className={styles.message}>
          <FormattedMessage id={emptyErrorMessageId} />
        </Alert>
      )}
    </>
  );
};
