import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  modalHeader: {
    fontSize: "19px",
    fontWeight: 600,
    color: "#323232",
    width: "340px",
    borderBottom: "2px solid #EAEAEA",
    paddingBottom: "17px",
    marginBottom: "17px",
  },
  inputLabel: {
    fontSize: "15px",
    color: "#505050",
    marginBottom: "28px",
  },
  modalContent: {
    display: "flex",
    gap: "20px",
  },
  iconSection: {
    width: "45px",
  },
  billIcon: {
    width: "45px",
  },
  modalFooter: {
    display: "flex",
    justifyContent: "flex-start",

    "& .settings-button": {
      height: "32px",
      padding: "0 27px",
      marginRight: "10px",
      color: theme.palette.diagram?.purple,
      border: `2px solid ${theme.palette.diagram?.purple}`,
      borderRadius: "16px",
      textTransform: "unset",

      "&.recharge-button": {
        marginRight: "0",
        color: theme.palette.diagram?.white,
        borderColor: theme.palette.diagram?.blue,
        background: theme.palette.diagram?.blue,
      },

      "& .loading-indicator": {
        color: "#FFFFFF",
      },
    },
  },
  textField: {
    width: "140px",
  },
  alert: {
    width: "270px",
    height: "48px",
    "&.MuiAlert-root": {
      backgroundColor: "#FFF2E2",
      color: "#5C2122",
      fontSize: "0.75rem",

      "& .MuiAlert-icon": {
        color: "#FF983B",
      },

      "& .MuiAlert-message": {
        overflow: "hidden",
        textAlign: "center",
      },
    },
  },
}));
