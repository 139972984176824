import { FC, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ButtonBase, Grid, Input, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { Event } from "../SimpleAdvertising/types";
import useStyles from "./styles";

const AdvertisementSummary: FC = () => {
  const { advertisementSummaryContainer } = useStyles();
  const location: {
    pathname: string;
    state: { events: Event[]; campaignSettings: {} };
  } = useLocation();
  const history = useHistory();
  const [campaignName, setCampaignName] = useState("Wall Painting for kids");

  const { formatMessage } = useIntl();

  const handleCheckoutClick = () => {
    history.push({
      pathname: `${location.pathname}/Payment`,
      state: { name: "Basic", price: 0, pricePerMonth: 0 },
    });
  };

  return (
    <Grid className={advertisementSummaryContainer}>
      <Typography className="advertisement-summary-title">
        {formatMessage({ id: "marketing.create_a_simple_campaign" })}
      </Typography>

      <Grid className="advertisement-summary-content__wrapper">
        <h1 className="advertisement-summary-content__title"> {formatMessage({ id: "marketing.summary" })}</h1>

        <Grid className="advertisement-summary-content__body" container>
          <Grid className="content-item" item md={12} lg={5}>
            <h4 className="content-item-title">{formatMessage({ id: "marketing.campaign_name" })}</h4>

            <div className="campaign-name-body">
              <div className="campaign-name-wrapper">
                <Input
                  id="campaign-name"
                  className="campaign-name"
                  placeholder={formatMessage({ id: "marketing.campaign_name" })}
                  value={campaignName}
                  onChange={(event) => setCampaignName(event.target.value || "")}
                />
              </div>

              <div className="preview-image-wrapper">
                <div className="preview-image" />

                <p className="preview-image-label">{formatMessage({ id: "marketing.image_preview" })}</p>
              </div>
            </div>
          </Grid>

          <Grid className="content-item" item md={12} lg={5}>
            <h4 className="content-item-title">{formatMessage({ id: "marketing.budget" })}</h4>

            <div className="budget-body">
              <div className="budget-item">
                <h4 className="budget-item-label">{formatMessage({ id: "marketing.budget" })}</h4>

                <p className="budget-item-value">USD$500</p>
              </div>

              <div className="budget-item">
                <h4 className="budget-item-label">{formatMessage({ id: "marketing.billing_date" })}</h4>

                <p className="budget-item-value">12.25.2022</p>
              </div>

              <div className="budget-item">
                <h4 className="budget-item-label">{formatMessage({ id: "marketing.credit_limit" })}</h4>

                <p className="budget-item-value">USD$700</p>
              </div>
            </div>
          </Grid>

          <Grid className="content-item" item md={12} lg={5}>
            <h4 className="content-item-title">{formatMessage({ id: "marketing.promoted_elements" })}</h4>

            <div className="promoted-elements-wrapper">
              <Table>
                <TableBody>
                  {location.state && location.state.events.length ? (
                    location.state.events.map((element) => (
                      <TableRow key={element.id}>
                        <TableCell>
                          <div className="type-wrapper">
                            <p className="type-text">{element.type}</p>
                          </div>
                        </TableCell>

                        <TableCell className="event-image-cell">
                          <div className="event-image" />
                        </TableCell>

                        <TableCell>
                          <p>{element.name}</p>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <></>
                  )}
                </TableBody>
              </Table>

              <div className="total-items">
                <p>{formatMessage({ id: "marketing.total_elements" })} </p>
                <p className="total-items-count">{(location.state && location.state.events.length) || 0}</p>
              </div>
            </div>
          </Grid>

          <Grid className="content-item-checkout" item md={12} lg={5}>
            <ButtonBase className="checkout-button" onClick={handleCheckoutClick}>
              {formatMessage({ id: "btn_text_checkout" })}
            </ButtonBase>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdvertisementSummary;
