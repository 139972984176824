import { FC } from "react";
import { Grid, Box } from "@mui/material";
import Insights from "./Insights";
import MainWidget from "./MainWidget";
import Rules from "./Rules";
import JackpotAndPromoters from "./JackpotAndPromoters";
import Experiences from "./Experiences";
import SponsoredExperiences from "./SponsoredExperiences";
import Marketing from "./Marketing";
import Campaigns from "./Campaigns";
import TimeFrameFilter from "../TimeFrameFilter";
import ExportButton from "../ExportButton";
import useStyles from "./styles";

const General: FC = () => {
  const { wrap, contentWrap } = useStyles();
  return (
    <Box className={wrap}>
      <Box className={contentWrap}>
        <Grid container spacing={2} width="100%">
          <Grid
            item
            container
            sm={12}
            display="flex"
            justifyContent="space-between"
            marginBottom="20px"
          >
            <Grid item md={8}>
              <TimeFrameFilter />
            </Grid>

            <Grid item md={4} display="flex" justifyContent="end" alignItems="start">
              <ExportButton />
            </Grid>
          </Grid>

          <Grid item lg={8} md={12} width="100%">
            <MainWidget />
          </Grid>

          <Grid item lg={4} md={12} width="100%">
            <Insights />
          </Grid>

          <Grid item lg={8} md={12} width="100%">
            <Rules />
          </Grid>

          <Grid item lg={4} md={6} sm={12} width="100%">
            <JackpotAndPromoters />
          </Grid>

          <Grid item lg={4} md={6} sm={12} width="100%">
            <Experiences />
          </Grid>

          <Grid item lg={4} md={6} sm={12} width="100%">
            <SponsoredExperiences />
          </Grid>

          <Grid item lg={4} md={6} sm={12} width="100%">
            <Marketing />
          </Grid>

          <Grid item xl={12} width="100%">
            <Campaigns />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default General;
