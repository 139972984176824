import clsx from "clsx";
import { FC, HTMLAttributes } from "react";
import { FormattedMessage } from "react-intl";
import { MessageIds } from "../../../i18n";
import { LineItem } from "./LineItem/LineItem";
import { useStyles } from "./styles";

interface CartLineItem {
  title: string;
  priceInCents: number;
  taxInCents: number;
}

export interface LineItemsSectionProps extends HTMLAttributes<HTMLDivElement> {
  cartTitleId?: MessageIds;
  lineItems: CartLineItem[];
}

export const LineItemsSection: FC<LineItemsSectionProps> = ({ lineItems, className, ...attributes }) => {
  const styles = useStyles();

  return (
    <section className={clsx(styles.container, className)} {...attributes}>
      <div className={styles.headerContainer}>
        <p>
          <FormattedMessage id="cart_details" />
        </p>
        <p className={styles.priceColumn}>
          <FormattedMessage id="cart_price" />
        </p>
      </div>
      <ul className={styles.lineItemsContainer}>
        {lineItems.map((lineItem) => (
          <LineItem key={lineItem.title} {...lineItem} />
        ))}
      </ul>
    </section>
  );
};
