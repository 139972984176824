import { FC } from "react";
import { Box, Grid } from "@mui/material";
import TimeFrameFilter from "../TimeFrameFilter";
import ExportButton from "../ExportButton";
import AudienceLocation from "./AudienceLocation";
import DemographicsInsights from "./DemographicsInsights";
import useStyles from "../General/styles";

const Demographics: FC = () => {
  const { wrap, contentWrap } = useStyles();
  return (
    <Box className={wrap}>
      <Box className={contentWrap}>
        <Grid container spacing={2} width="100%">
          <Grid item sm={12} display="flex" justifyContent="space-between" marginBottom="20px">
            <TimeFrameFilter />

            <ExportButton />
          </Grid>

          <Grid item lg={8} md={12} width="100%">
            <AudienceLocation />
          </Grid>

          <Grid item lg={4} md={12} width="100%">
            <DemographicsInsights />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Demographics;
