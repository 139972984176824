import { ApolloQueryResult, useQuery } from "@apollo/client";
import { useUser } from "@stagewood/unified-login-library";
import { GetBusinessProfileInfoQuery, TykchaseConfigStatus } from "../gql/graphql";
import { BusinessProfileInfoPayload, getBusinessProfileInfoRequest } from "../graphql/GetBusinessProfileInfo.query";

export const useBusinessProfile = (): [
  businessProfile: BusinessProfileInfoPayload | null | undefined,
  isLoading: boolean,
  isSubscribed: boolean,
  refetch: () => Promise<ApolloQueryResult<GetBusinessProfileInfoQuery>>,
] => {
  const user = useUser();
  const userId = user?.id ?? "";
  const {
    loading: isGetBusinessProfileQueryLoading,
    data: queryResult,
    refetch,
  } = useQuery(getBusinessProfileInfoRequest, {
    variables: {
      userId,
    },
  });
  return [
    queryResult?.businessProfileInfo?.payload,
    isGetBusinessProfileQueryLoading,
    !!queryResult?.businessProfileInfo?.payload?.membership?.id &&
      queryResult?.businessProfileInfo?.payload?.status !== TykchaseConfigStatus.Inactive,
    refetch,
  ];
};
