import { defineMessages, IntlShape } from "react-intl";

const messages = defineMessages({
  active: {
    id: "active",
    defaultMessage: "Active",
  },
  ended: {
    id: "ended",
    defaultMessage: "",
  },
  centorDistributed: {
    id: "centors_distributed",
    defaultMessage: "Centors Distributed",
  },
});

const RewardWidgedtext = (intl: IntlShape) => {
  return {
    ACTIVE: intl.formatMessage(messages.active),
    ENDED: intl.formatMessage(messages.ended),
    CENTORS_DISTRIBUTED: intl.formatMessage(messages.centorDistributed),
  };
};

export { RewardWidgedtext };
