import { FC, ReactChild, MouseEvent as ReactMouseEvent } from "react";
import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";

type Props = {
  title: string;
  mainBgColor: string;
  secondaryBgColor: string;
  icon: ReactChild;
  handleToggleModal: (modalName: ReactMouseEvent<HTMLDivElement, MouseEvent>) => void;
};

const CreateCampaignWidget: FC<Props> = ({ title, mainBgColor, secondaryBgColor, icon, handleToggleModal }: Props) => {
  const theme = useTheme();

  return (
    <>
      <Grid
        className="createCampaignWidget"
        onClick={handleToggleModal}
        pb="30px"
        bgcolor={mainBgColor}
        borderRadius="6px"
        overflow="hidden"
        marginX={{ xs: "5px", md: "12px", xl: "24px" }}
        mb={{ xs: "10px", md: "24px" }}
        boxShadow={`0px 3px 6px ${theme.palette.campaigns.shadowGrey}`}
      >
        <Grid p="16px" pr="62px" mb="50px" height="60px" bgcolor={secondaryBgColor}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            height="72px"
            width="72px"
            mt="5px"
            bgcolor={secondaryBgColor}
            borderRadius="100px"
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              height="58px"
              width="58px"
              bgcolor={theme.palette.campaigns.dark}
              borderRadius="100px"
            >
              {icon}
            </Grid>
          </Grid>
        </Grid>
        <Typography fontWeight="bold" color={theme.palette.black} fontSize="0.875rem" ml="16px">
          {title}
        </Typography>
      </Grid>
    </>
  );
};

export default CreateCampaignWidget;
