import * as yup from "yup";
import { IntlShape } from "react-intl";
import { RewardInputs, RewardInputsEnum, ValidationError } from "../types";
import { TykchaseRuleConfiguration, PurchaseRewardRate } from "../../../generated/types";
import { TWO_DIGITS_AFTER_COMMA_DECIMAL_PATTERN } from "../regexp";

const MAX_PERCENTAGE_VALUE = 100;
const MIN_PERCENTAGE_VALUE = 1;
const MAX_DOLLAR_VALUE = 10;
const MIN_DOLLAR_VALUE = 0.03;

export const modalValidateSchema = (intl: IntlShape) => {
  return yup.object({
    dollarReward: yup.number().when("purchaseRewardRate", {
      is: PurchaseRewardRate.Fixed,
      then: (schema) =>
        schema
          .test("min value", `${ValidationError(intl).MIN_VALUE} ${MIN_DOLLAR_VALUE}`, (value) => {
            return value ? value >= MIN_DOLLAR_VALUE : false;
          })
          .test("max value", `${ValidationError(intl).MAX_VALUE} ${MAX_DOLLAR_VALUE}`, (value) => {
            return value ? MAX_DOLLAR_VALUE >= value : false;
          })
          .required(`${ValidationError(intl).MIN_VALUE} 0.01`),
      otherwise: (schema) =>
        schema
          .test("min value", `${ValidationError(intl).MIN_VALUE} ${MIN_PERCENTAGE_VALUE}`, (value) => {
            return value ? value >= MIN_PERCENTAGE_VALUE : false;
          })
          .test("max value", `${ValidationError(intl).MAX_VALUE} ${MAX_PERCENTAGE_VALUE}`, (value) => {
            return value ? MAX_PERCENTAGE_VALUE >= value : false;
          })
          .required(`${ValidationError(intl).MIN_VALUE} 1`),
    }),
    rewardOnPurchaseCount: yup
      .number()
      .integer(ValidationError(intl).ONLY_WHOLE_NUMBERS)
      .required(`${ValidationError(intl).MIN_VALUE} 1`)
      .min(1, `${ValidationError(intl).MIN_VALUE} 1`),
    multipleRewardCount: yup.number().when("allowMultipleReward", {
      is: true,
      then: (schema) =>
        schema
          .typeError(`${ValidationError(intl).MIN_VALUE} 2`)
          .integer(ValidationError(intl).ONLY_WHOLE_NUMBERS)
          .min(2, `${ValidationError(intl).MIN_VALUE} 2`)
          .required(`${ValidationError(intl).MIN_VALUE} 2`),
    }),
    minPurchaseForReward: yup
      .number()
      .required(`${ValidationError(intl).MIN_VALUE} 0.01`)
      .min(0.01, `${ValidationError(intl).MIN_VALUE} 0.01`)
      .test("is decimal", `${ValidationError(intl).MIN_VALUE} 0.01`, (value) => {
        return value ? TWO_DIGITS_AFTER_COMMA_DECIMAL_PATTERN.test(String(value)) : false;
      }),
    allowMultipleReward: yup.boolean(),
    purchaseRewardRate: yup.string(),
  });
};

export const getInitialValues = (backendData?: TykchaseRuleConfiguration, reward?: number): RewardInputs => {
  return {
    dollarReward: backendData?.dollarReward || reward,
    rewardOnPurchaseCount: backendData?.rewardOnPurchaseCount,
    multipleRewardCount: backendData?.multipleRewardCount,
    minPurchaseForReward: backendData?.minPurchaseForReward,
    allowMultipleReward: Boolean(backendData?.allowMultipleReward),
    purchaseRewardRate: backendData?.purchaseRewardRate ?? PurchaseRewardRate.Fixed,
  };
};

export const pickInitialValues = (
  pickedRewardInputs: RewardInputsEnum[],
  backendData?: TykchaseRuleConfiguration,
  reward?: number,
): RewardInputs => {
  const pickedValues = {};
  const allInitialValues = getInitialValues(backendData, reward);
  pickedRewardInputs.forEach((field) => {
    pickedValues[field] = allInitialValues[field];
  });

  return pickedValues;
};
