import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    wrap: {
      padding: "16px 24px",
      border: `1px solid ${theme.palette.diagram?.grey}`,
      borderRadius: "6px",
      [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
        padding: "8px 12px",
      },
      "& .cardHeader": {
        width: "40%",
        marginBottom: "20px",
        paddingBottom: "16px",
        borderBottom: "1px solid #CDCCD7",
        [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
          paddingBottom: "10px",
          marginBottom: "10px",
        },
        [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
          width: "100%",
        },
      },
      "& .totalDistributed": {
        width: "max-content",
        marginRight: "58px",
        [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
          marginRight: "0",
          marginBottom: "15px",
        },
        "& .yellowCharacter": {
          marginLeft: "20px",
          marginBottom: "10px",
        },

        "& .totalDistributedNumber": {
          fontFamily: theme.typography.fontFamily,
          fontSize: "2.25rem",
          fontWeight: "bold",
          color: theme.palette.analytics?.black,
          lineHeight: "1",
        },
        "& .inactive": {
          color: "#8986AD",
        },

        "& .totalDistributedIcon": {
          width: "15px",
          height: "16px",
          fill: "#4DA17E",
          marginRight: "6px",
        },
      },

      "& .chartDistributed": {
        width: "53%",

        [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
          width: "100%",
        },
        "& .chartDistributedPercent": {
          marginRight: "8px",
          fontFamily: theme.typography.fontFamily,
          fontSize: "1.375rem",
          fontWeight: "bold",
          lineHeight: "1",
          color: theme.palette.analytics?.black,
        },
        "& .inactive": {
          color: "#8986AD",
        },

        "& .chartContainer": {
          maxWidth: "100%",

          "& .fakeChart": {
            width: "100%",
          },
        },
      },

      "& .breakDownWrap": {
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        width: "100%",
        margin: "0 -27px",
        [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
          margin: "0",
        },

        [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
          flexDirection: "column",
        },

        "& .breakDownItem": {
          width: "25%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          padding: "0 27px",
          borderRight: `1px solid ${theme.palette.diagram?.grey}`,
          [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
            width: "121px",
            padding: "10px 17px",
            paddingLeft: "0",
            borderRight: "none",
            borderBottom: `1px solid ${theme.palette.diagram?.grey}`,
          },

          [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
            width: "100%",
          },
          "& .breakDownLabel": {
            position: "relative",
            marginLeft: "0.875rem",
            fontSize: "12px",
            "&::before": {
              content: '""',
              position: "absolute",
              top: "9px",
              left: "-14px",
              transform: "translateY(-50%)",
              height: "7px",
              width: "7px",
              borderRadius: "100px",
            },
          },

          "&:first-child": {
            "& .breakDownLabel": {
              color: theme.palette.analytics?.darkPurple,
              "&::before": {
                background: theme.palette.analytics?.darkPurple,
              },
            },
          },
          "&:nth-child(2)": {
            "& .breakDownLabel": {
              color: theme.palette.analytics?.lightPurple,
              "&::before": {
                background: theme.palette.analytics?.lightPurple,
              },
            },
          },
          "&:nth-child(3)": {
            "& .breakDownLabel": {
              color: theme.palette.analytics?.lightGreen,
              "&::before": {
                background: theme.palette.analytics?.lightGreen,
              },
            },
          },
          "&:last-child": {
            borderRight: "none",
            "& .breakDownLabel": {
              color: theme.palette.analytics?.darkYellow,
              "&::before": {
                background: theme.palette.analytics?.darkYellow,
              },
            },
          },
          "& .breakDownStatistic": {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "10px",

            "& .breakDownStatisticValue": {
              fontFamily: theme.typography.fontFamily,
              fontSize: "1.25rem",
              fontWeight: "bold",
              color: theme.palette.analytics?.black,
            },
            "& .inactive": {
              color: "#8986AD",
            },
          },
        },
      },
    },
  };
});

export default useStyles;
