import { TablePagination, TablePaginationProps, Theme } from "@mui/material";
import { makeStyles, styled, StyledComponent } from "@mui/styles";

export const Pagination: StyledComponent<TablePaginationProps> = styled(TablePagination)<Theme>(({ theme }) => ({
  "& .MuiToolbar-root": {
    padding: 0,
  },

  "& .MuiTablePagination-selectLabel": {
    color: theme.palette.experiences.grey,
    fontFamily: theme.typography.fontFamily,
    fontSize: "0.75rem",
  },

  "& .MuiTablePagination-select": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "15px",
    color: theme.palette.experiences.grey,
    fontFamily: theme.typography.fontFamily,
    fontSize: "0.75rem",
  },

  "& .MuiInputBase-root": {
    width: "71px",
    height: "31px",
    marginRight: "16px",
    border: "2px solid #4262FF",
    borderRadius: "3px",

    "& .MuiSvgIcon-root": {
      width: "20px",
      height: "20px",
      fill: theme.palette.experiences.blue,
    },
  },

  "& .MuiTablePagination-displayedRows": {
    color: theme.palette.experiences.grey,
    fontFamily: theme.typography.fontFamily,
    fontSize: "0.75rem",
  },

  "& .MuiTablePagination-actions": {
    "& .MuiSvgIcon-root": {
      width: "18px",
      height: "18px",
      fill: theme.palette.experiences.grey,
    },
  },
}));

export const selectDropdownStyles = makeStyles((theme) => {
  return {
    selectDropdown: {
      "& .MuiMenuItem-root": {
        fontFamily: theme.typography.fontFamily,
        fontSize: "0.75rem",
      },
    },
  };
});
