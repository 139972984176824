
export enum ButtonTitle {
  Enroll = "Enroll",
  MyMembership = "My Membership",
  ComingSoon = "Coming Soon",
  Upgrade = "Upgrade",
}

export enum RuleCategoryTitles {
  GeneralRewardSettings = "General Reward Settings",
  SpecialCampaigns = "Special Campaigns",
  CentorsVsLoneStars = "Centors vs LS",
  RandomLSTyksMix = "Random LS Tyks Mix",
  DedicatedLSTyksMix = "Dedicated LS Tyks Mix",
  LocationSpecificLSTyksMix = "Location Specific LS Tyks Mix",
  CategorySpecificLSTyksMix = "Category Specific LS Tyks Mix",
  DetailSpecificLSTyksMix = "Detail Specific LS Tyks Mix",
  RewardsTechnology = "Rewards Technology",
  Marketing = "Marketing",
}

export const RuleCategories = {
  [RuleCategoryTitles.GeneralRewardSettings]: 0,
  [RuleCategoryTitles.SpecialCampaigns]: 1,
  [RuleCategoryTitles.CentorsVsLoneStars]: 2,
  [RuleCategoryTitles.RandomLSTyksMix]: 3,
  [RuleCategoryTitles.DedicatedLSTyksMix]: 4,
  [RuleCategoryTitles.LocationSpecificLSTyksMix]: 5,
  [RuleCategoryTitles.CategorySpecificLSTyksMix]: 6,
  [RuleCategoryTitles.DetailSpecificLSTyksMix]: 7,
  [RuleCategoryTitles.RewardsTechnology]: 8,
  [RuleCategoryTitles.Marketing]: 9,
};
