import { Tabs, Tab } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { Link, useRouteMatch } from "react-router-dom";
import useStyles from "./styles";

const TabsTechnology = () => {
  const { tabsContainer } = useStyles();
  const routeMatch = useRouteMatch(["/Technology/abc", "/Technology"]);
  const currentTab = routeMatch?.path;

  return (
    <Tabs value={currentTab} className={tabsContainer}>
      <Tab
        label={<FormattedMessage id="technology.qr_codes.tabs.qr_code" />}
        value="/Technology"
        to="/Technology"
        component={Link}
      />
      <Tab
        label={<FormattedMessage id="technology.qr_codes.tabs.beacon" />}
        value={null}
        to="/Technology"
        component={Link}
        disabled
      />
    </Tabs>
  );
};

export default TabsTechnology;
