import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    wrap: {
      display: "flex",
      flexDirection: "column",
      flexFlow:'wrap',
    },
    editButton: {
      backgroundColor: "transparent",
      border: "none",
      color: "#4262FF",
      textDecoration: "underline",
      cursor: "pointer",
    },
    container:{
      position:"relative",
      width: "20ch", border: `1px solid ${theme.palette.diagram?.grey}`, borderRadius: "4px",
      marginVertical:10,
      "&:hover": {
        border: `1px solid ${theme.palette.diagram?.dark}`,
      },
    },
   inputStyle:{ margin:10 },
  };
});

export default useStyles;
