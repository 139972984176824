import { FC } from "react";
import { FormikProps } from "formik";
import { Alert, Box, Grid, TextField } from "@mui/material";
import { useIntl } from "react-intl";
import useStyles from "../../styles";
import { StepsFormikValues } from "../../../../../../../types/campaigns";

type Props = {
  formik: FormikProps<StepsFormikValues>;
};

const ProductServiceTrigger: FC<Props> = ({ formik }: Props) => {
  const { wrap, fieldWrapper } = useStyles();
  const { formatMessage } = useIntl();
  return (
    <Grid className={wrap}>
      <Grid container item flexDirection={{ xs: "column", md: "row" }} className="productTrigger">
        <Grid className={fieldWrapper}>
          <TextField
            placeholder={formatMessage({ id: "modals.triggers.product_name" })}
            variant="outlined"
            className="productName"
            onChange={formik.handleChange}
            value={formik.values.name}
            name="name"
          />

          {formik.touched.name && formik.errors.name && (
            <Box marginTop="28px" marginRight="18px">
              <Alert severity="error">{formik.errors.name}</Alert>
            </Box>
          )}
        </Grid>

        <Grid className={fieldWrapper}>
          <TextField
            placeholder={formatMessage({ id: "modals.triggers.product_price" })}
            variant="outlined"
            type="number"
            className="productPrice"
            onChange={formik.handleChange}
            value={formik.values.price}
            name="price"
          />

          {formik.touched.price && formik.errors.price && (
            <Box width="160px" marginTop="28px" marginRight="8px">
              <Alert severity="error">{formik.errors.price}</Alert>
            </Box>
          )}
        </Grid>

        <Grid className={fieldWrapper}>
          <TextField
            placeholder={formatMessage({ id: "modals.triggers.product_code" })}
            variant="outlined"
            className="productCode"
            onChange={formik.handleChange}
            value={formik.values.code}
            name="code"
          />

          {formik.touched.code && formik.errors.code && (
            <Box width="195px" marginTop="28px">
              <Alert severity="error">{formik.errors.code}</Alert>
            </Box>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProductServiceTrigger;
