import { ApolloClient, createHttpLink, InMemoryCache, from, ApolloLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { stringify } from "qs";
import { useUnifiedLogin } from "@stagewood/unified-login-library";
import { onError } from "@apollo/client/link/error";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import { useCallback, useMemo } from "react";
import { IMessage, messages } from "./types";
import { getOptions } from "../components/Utils/Notifications";

const notificationTimeMap = {
  NOT_AUTHENTICATED: null,
  INVALID_REFRESH_TOKEN: null,
  ACCOUNT_IS_BANNED: null,
};

const inMemoryCache = new InMemoryCache();

export const useApolloClient = () => {
  const { getToken } = useUnifiedLogin();
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();

  const showErrorNotification = useCallback(
    (message: IMessage) => {
      enqueueSnackbar(intl.formatMessage(message), getOptions("error"));
    },
    [intl, enqueueSnackbar],
  );

  const client = useMemo(() => {
    const customFetch = (uri: string, options: RequestInit) => {
      const { operationName, variables } = JSON.parse(options.body as string);
      return fetch(
        `${uri}${stringify(
          {
            operationName,
            ...variables,
          },
          { skipNulls: true, addQueryPrefix: true },
        )}`,
        options,
      );
    };

    const httpLink = createHttpLink({
      uri: process.env.REACT_APP_GRAPHQL_SERVICE,
      fetch: customFetch,
    });

    const errorLink = onError(({ graphQLErrors }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message }) => {
          const errorText = messages[message] || messages.UNEXPECTED;
          const durationTime = notificationTimeMap[message];
          showErrorNotification(errorText);
        });
      } else {
        showErrorNotification(messages.UNEXPECTED);
      }
    });

    const authLink = setContext(async (_, { headers }) => {
      const token = await getToken();

      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : "",
        },
      };
    });

    const apolloLink = new ApolloLink((operation, forward) => {
      return forward(operation).map((response) => {
        const data = response.data && Object.values(response.data)[0];

        if (data?.errorMessageId) {
          const errorText = messages[data.errorMessageId] || messages.UNEXPECTED;
          const durationTime = notificationTimeMap[data.errorMessageId];
          showErrorNotification(errorText);
        }

        return response;
      });
    });

    return new ApolloClient({
      uri: process.env.REACT_APP_GRAPHQL_SERVICE,
      cache: inMemoryCache,
      link: authLink.concat(from([apolloLink, errorLink, httpLink])),
    });
  }, [getToken, showErrorNotification]);

  return client;
};
