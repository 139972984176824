import { ITykchaseGlobalConfig } from "../../../../types/tykchaseGlobalConfig";
import { Rewards } from "./types";

const percent = (val: number): number => {
  return val / 100;
};

const getDollarsAmount = (awardRate: number, dollars: number) => {
  return dollars * percent(awardRate);
};

const getRate = (
  config: ITykchaseGlobalConfig | undefined,
  key: keyof NonNullable<ITykchaseGlobalConfig["customTykAwardsDistribution"]> &
    keyof NonNullable<ITykchaseGlobalConfig["tykAwardsDistribution"]>,
): number => {
  return (config?.customTykAwardsDistribution?.[key] || config?.tykAwardsDistribution?.[key]) ?? 0;
};

const getCoinPerDollarRate = (config: ITykchaseGlobalConfig | undefined, key: keyof ITykchaseGlobalConfig): number => {
  const centorsPerDollarRate = config?.[key]?.toString() || "0";

  return parseFloat(centorsPerDollarRate);
};

const calculateCoinReward = (dollars: number, coinRate: number, coinPerDollarRate: number): string => {
  return Math.round(getDollarsAmount(coinRate, dollars) / coinPerDollarRate).toString();
};

const calculateReward = (dollars: number, rate: number): string => {
  return Math.round(getDollarsAmount(rate, dollars)).toString();
};

export const calculateRewards = (value: string, config?: ITykchaseGlobalConfig): Rewards => {
  const parsedDollars = parseFloat(value);
  const dollars = !Number.isNaN(parsedDollars) ? parsedDollars : 0;

  const rewards: Rewards = {
    centorsRewards: calculateCoinReward(
      dollars,
      getRate(config, "centorsRate"),
      getCoinPerDollarRate(config, "centorPurchaseByTykcoinRate"),
    ),
    loneStarsRewards: calculateCoinReward(
      dollars,
      getRate(config, "loneStarsRate"),
      getCoinPerDollarRate(config, "loneStarPerDollarRate"),
    ),
    jackpotRewards: calculateReward(dollars, getRate(config, "jackpotAwardRate")),
    promoterRewards: calculateReward(dollars, getRate(config, "promoterAwardRate")),
  };

  return rewards;
};
