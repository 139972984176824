import { FC } from "react";
import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";

import { FormattedMessage } from "react-intl";
import Icon from "../../../../utils/icon";
import {
  TykchaseCampaignTriggers,
  TykchaseCampaignStatus,
  TykchaseCampaign,
  PurchaseRewardRate,
} from "../../../../generated/types";
import { CAMPAIGN_TYPE_TAGS } from "../../../../constants";

import happyGirls from "../../../../assets/images/happyGirls.png";

type Props = {
  cardPreview?: boolean;
  campaignData: TykchaseCampaign;
};

const CardCampaign: FC<Props> = ({ cardPreview, campaignData }: Props) => {
  const theme = useTheme();
  const { Fixed, ByPercentage } = PurchaseRewardRate;
  const { ReceiptTotal, ProductOrService, Day, Surprise } = TykchaseCampaignTriggers;
  const endDate = new Date(campaignData.endDate);

  // Distribution types
  const isFixedDistributionType =
    campaignData.ruleRewardConfiguration?.purchaseRewardRate === Fixed ||
    campaignData.boostedTykchaseCampaign?.ruleRewardConfiguration?.purchaseRewardRate === Fixed;
  const isPercentageDistributionType =
    campaignData.ruleRewardConfiguration?.purchaseRewardRate === ByPercentage ||
    campaignData.boostedTykchaseCampaign?.ruleRewardConfiguration?.purchaseRewardRate === ByPercentage;

  // Trigger types
  // RECEIPT_TOTAL triggers
  const isReceiptTotalTrigger = campaignData.triggerType === ReceiptTotal;
  const isReceiptTotalBoostedTrigger = campaignData.boostedTykchaseCampaign?.triggerType === ReceiptTotal;

  // DAY triggers
  const isDayTrigger = campaignData.triggerType === Day;
  const isDayBoostedTrigger = campaignData.boostedTykchaseCampaign?.triggerType === Day;

  // PRODUCT_OR_SERVICE triggers
  const isProductOrServiceTrigger = campaignData.triggerType === ProductOrService;
  const isProductOrServiceBoostedTrigger = campaignData.boostedTykchaseCampaign?.triggerType === ProductOrService;

  // SURPRISE triggers
  const isSurpriseTrigger = campaignData.triggerType === Surprise;
  const isSurpriseBoostedTrigger = campaignData.boostedTykchaseCampaign?.triggerType === Surprise;

  // Dollar reward
  const dollarReward =
    campaignData.ruleRewardConfiguration?.dollarReward ||
    campaignData.boostedTykchaseCampaign?.ruleRewardConfiguration?.dollarReward;

  return (
    <>
      <Grid container width="35%" maxHeight="160px" borderRadius="6px" overflow="hidden" mr="24px">
        <img height="100%" width="100%" src={campaignData.coverPhotos[0]?.source || happyGirls} alt="img" />
      </Grid>
      <Grid width="65%">
        <Grid container flexWrap="nowrap" justifyContent="space-between" mb="5px">
          <Typography mr="4px" fontWeight="bold" color={theme.palette.black}>
            {campaignData.title}
          </Typography>
          {!cardPreview && (
            <Grid container flexWrap="nowrap" width="max-content">
              <Icon.NounAnalytics mr="10px" />
              <Icon.NounEdit />
            </Grid>
          )}
        </Grid>
        <Grid container flexWrap="nowrap" mb="18px">
          {campaignData.status === TykchaseCampaignStatus.Active && (
            <Typography
              bgcolor={theme.palette.campaigns.deepGreen}
              borderRadius="100px"
              color={theme.palette.white}
              p="3px 9px"
              mr="5px"
              fontSize="0.625rem"
            >
              <FormattedMessage id="rewards.campaigns.card_campaign.running" />
            </Typography>
          )}
          <Typography
            bgcolor={theme.palette.campaigns.lightGreen}
            borderRadius="100px"
            color={theme.palette.campaigns.dark}
            p="3px 9px"
            fontSize="0.625rem"
          >
            {campaignData.campaignType && CAMPAIGN_TYPE_TAGS[campaignData.campaignType]}
          </Typography>
        </Grid>
        <Grid>
          <Grid container>
            <Typography mr="5px" fontSize="0.75rem" color={theme.palette.purple}>
              <FormattedMessage id="rewards.campaigns.card_campaign.trigger" /> &gt;
            </Typography>
            <Typography fontSize="0.75rem" color={theme.palette.primary.dark}>
              {/* RECEIPT_TOTAL TRIGGER */}
              {isReceiptTotalTrigger && `$${campaignData.receiptTotalPriceTrigger || "-"}`}

              {/* DAY TRIGGER */}
              {isDayTrigger && `${campaignData.dayTrigger || "-"}`}

              {/* PRODUCT_OR_SERVICE TRIGGER */}
              {isProductOrServiceTrigger && `${campaignData.productTrigger?.map((product) => product.name) || "-"}`}

              {/* SURPRISE TRIGGER */}
              {isSurpriseTrigger && `${campaignData.surpriseNumber}`}

              {/* RECEIPT_TOTAL TRIGGER boostedTykchaseCampaign */}
              {isReceiptTotalBoostedTrigger &&
                `$${campaignData.boostedTykchaseCampaign?.receiptTotalPriceTrigger || "-"}`}

              {/* DAY TRIGGER boostedTykchaseCampaign */}
              {isDayBoostedTrigger && `$${campaignData.boostedTykchaseCampaign?.dayTrigger || "-"}`}

              {/* PRODUCT_OR_SERVICE TRIGGER boostedTykchaseCampaign */}
              {isProductOrServiceBoostedTrigger &&
                `${campaignData.boostedTykchaseCampaign?.productTrigger?.map((product) => product.name) || "-"}`}

              {/* SURPRISE TRIGGER boostedTykchaseCampaign */}
              {isSurpriseBoostedTrigger && `${campaignData.boostedTykchaseCampaign?.surpriseNumber || "-"}`}
            </Typography>
          </Grid>
          <Grid container>
            <Typography mr="5px" fontSize="0.75rem" color={theme.palette.purple}>
              {isFixedDistributionType && <FormattedMessage id="rewards.campaigns.card_campaign.fixed_amount" />}
              {isPercentageDistributionType && (
                <FormattedMessage id="rewards.campaigns.card_campaign.purchase_percentage" />
              )}
            </Typography>
            <Typography fontSize="0.75rem" color={theme.palette.primary.dark}>
              {isFixedDistributionType && `Reward - US$${dollarReward}`}
              {isPercentageDistributionType && `Reward - ${dollarReward}%`}
            </Typography>
          </Grid>
        </Grid>
        <Grid container alignItems="end" width="100%" justifyContent="space-between">
          <Typography whiteSpace="nowrap" color={theme.palette.purple} fontSize="0.75rem">
            Ends - {endDate.toDateString().substring(4, 10)}
          </Typography>
          <Grid container flexWrap="nowrap" alignItems="end" width="max-content">
            <Icon.CentorsFull mr="14px" />
            <Typography
              pr="14px"
              mr="14px"
              color={theme.palette.purple}
              fontSize="0.75rem"
              borderRight={`1px solid ${theme.palette.dashBoard?.borderGrey}`}
            >
              2,258
            </Typography>
            <Icon.LoneStars mr="14px" />
            <Typography color={theme.palette.purple} fontSize="0.75rem">
              30
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CardCampaign;
