import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    wrap: {
      "& .MuiTypography-root": {
        paddingLeft: "5px",
        marginBottom: "5px",
        fontSize: "0.75rem",
        color: theme.palette.diagram?.purple,
      },

      "& .MuiInputBase-root": {
        flexDirection: "row-reverse",
        height: "33px",
        width: "100%",
        maxWidth: "210px",
        padding: "6px 12px",
        fontSize: "0.75rem",
        color: theme.palette.diagram?.purple,
        background: "transparent",
        border: `2px solid ${theme.palette.diagram?.grey}`,
        borderRadius: "3px",

        "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
          WebkitAppearance: "none",
          margin: "0",
        },
      },
    },

    wrapSelect: {
      "& .MuiInputBase-root": {
        border: "none",
      },
      "& .MuiInputLabel-root": {
        transform: "translate(14px, 13px) scale(1)",
        fontSize: "12px",
        color: theme.palette.diagram?.purple,

        "&.MuiFormLabel-filled": {
          color: "transparent !important",
        },

        "&.Mui-focused": {
          color: theme.palette.diagram?.grey,
        },
      },

      "& .MuiOutlinedInput-root": {
        height: "33px",
        width: "210px",
        margin: "5px 0",

        "& .MuiOutlinedInput-notchedOutline": {
          border: `1px solid ${theme.palette.diagram?.grey} !important`,
        },
      },
    },
  };
});

export default useStyles;
