import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    simpleAdvertisingContainer: {
      paddingTop: "120px",

      [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
        paddingTop: "80px",
      },

      "& .simple-advertising-title": {
        padding: "50px 90px 30px",
        fontFamily: theme.typography.fontFamily,
        fontSize: "0.875rem",
        fontWeight: "600",
        color: theme.palette.marketing.darkPurple,

        [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
          padding: "40px 30px 20px",
        },
      },

      "& .simple-advertising-container": {
        display: "flex",
        flexDirection: "column",
        margin: "0 30px",
        padding: "60px",
        backgroundColor: theme.palette.white,
        border: "0.5px solid #8986AD",

        [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
          padding: "10px",
        },

        [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
          flexDirection: "column",
          alignItems: "center",
          margin: "0 10px 10px 10px",
          padding: "10px",
        },

        "& .simple-advertising-content": {
          width: "100%",
          display: "flex",
          flexDirection: "column",

          "& .steps-container": {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",

            [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
              flexDirection: "column",
            },

            "& .MuiStep-root": {
              display: "flex",

              "& .step-label-container": {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                width: "50px",

                "& .step-number__outer": {
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "28px",
                  height: "28px",
                  borderRadius: "50%",
                  border: "1px solid #8986AD",
                  color: theme.palette.marketing.lightPurple,

                  "& .step-number": {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "22px",
                    height: "22px",
                    borderRadius: "50%",
                    backgroundColor: theme.palette.marketing.darkPurple,
                    color: theme.palette.white,
                  },
                },
              },

              "& .step-label": {
                padding: "6px 0",
                fontSize: "0.75rem",
                color: theme.palette.marketing.darkPurple,
              },

              "& .MuiStepContent-root": {
                border: "none",

                [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
                  padding: 0,
                },

                "& .MuiCollapse-vertical": {
                  display: "flex",
                  flexDirection: "column",

                  "& .step-content-wrapper": {
                    display: "flex",
                    flexDirection: "row",

                    "& .buttons-wrapper": {
                      "& .active-element-button": {
                        margin: "4px",
                        padding: "4px 24px",
                        borderRadius: "60px",
                        backgroundColor: theme.palette.marketing.lightGrey,
                        fontSize: "0.875rem",
                        color: theme.palette.marketing.darkGrey,
                      },

                      "& .active-element-button.active": {
                        backgroundColor: theme.palette.marketing.darkPurple,
                        color: theme.palette.white,
                      },
                    },

                    "& .date-picker-wrapper": {
                      display: "flex",
                      flexDirection: "column",

                      "& .MuiInputLabel-root": {
                        paddingBottom: "4px",
                        fontSize: "0.75rem",
                        color: "#747474",
                      },

                      "& .MuiInputBase-root": {
                        width: "120px",
                        height: "32px",
                        border: "1px solid #D3D3D3",
                        fontFamily: theme.typography.fontFamily,
                        fontSize: "0.875rem",
                        color: "#AAA9A9",
                      },

                      "& .MuiInputBase-input": {
                        padding: "4px 8px 4px 10px",
                      },

                      "& fieldset": {
                        padding: 0,
                        border: "none",
                      },
                    },

                    "& .date-picker-wrapper:first-child": {
                      marginRight: "20px",
                    },

                    "& .input-wrapper": {
                      display: "flex",
                      flexDirection: "column",

                      "& .MuiInputLabel-root": {
                        paddingBottom: "4px",
                        fontSize: "0.75rem",
                        color: "#747474",
                      },

                      "& .MuiInputBase-root": {
                        width: "120px",
                        height: "32px",
                        padding: "4px 10px",
                        border: "1px solid #D3D3D3",
                        borderRadius: "3px",
                        fontFamily: theme.typography.fontFamily,
                        fontSize: "0.875rem",
                        color: "#AAA9A9",
                      },

                      "& .MuiInputBase-input": {
                        color: "#AAA9A9",
                      },

                      "& .MuiInputBase-root:before, .MuiInputBase-root:after": {
                        border: "none",
                      },
                    },

                    "& .input-wrapper:first-child": {
                      marginRight: "20px",
                    },
                  },
                },
              },
            },

            "& .MuiStep-root.Mui-completed": {
              "& .step-label-container": {
                "& .step-number__outer": {
                  "& .step-number": {
                    backgroundColor: theme.palette.marketing.green,
                  },
                },
              },
            },

            "& .MuiStep-root.disabled": {
              "& .step-label-container": {
                "& .step-number__outer": {
                  "& .step-number": {
                    backgroundColor: theme.palette.white,
                    color: theme.palette.marketing.lightPurple,
                  },
                },
              },
            },

            "& .MuiStepConnector-root": {
              height: "50px",
              marginLeft: "24px",
              padding: "10px 0",
            },

            "& .info-wrapper": {
              width: "280px",
              height: "218px",
              display: "flex",
              flexDirection: "column",
              padding: "24px",
              margin: "0 0 0 40px",
              border: "1px solid #E9E9E9",
              borderRadius: "6px",
              color: theme.palette.marketing.darkGrey,
              fontSize: "0.75rem",

              "& img": {
                width: "34px",
                height: "34px",
                marginBottom: "12px",
              },

              [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
                height: "100%",
                width: "100%",
                padding: "10px",
                margin: "10px 0",
                flexDirection: "row",

                "& img": {
                  width: "24px",
                  height: "24px",
                  marginBottom: 0,
                  marginRight: "12px",
                },
              },
            },
          },

          "& .events-container": {
            display: "flex",
            flexDirection: "row",

            "& .preview-wrapper": {
              display: "flex",
              flexDirection: "column",
              padding: "6px 0 6px 40px",

              "& p": {
                fontSize: "0.75rem",
                marginBottom: "4px",
              },

              "& .preview-image-wrapper": {
                width: "150px",
                height: "150px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid #CDCCD7",
                borderRadius: "3px",

                "& .MuiSvgIcon-root": {
                  width: "100px",
                  height: "100px",
                  fill: theme.palette.marketing.grey,
                },
              },
            },

            [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
              flexDirection: "column",

              "& .preview-wrapper": {
                padding: "6px 0",
              },
            },
          },
        },

        "& .simple-advertising-footer": {
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
          padding: "30px 0",

          [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
            padding: "20px 0",
          },
        },
      },
    },

    buttonsWrapper: {
      padding: "10px 0",
      alignSelf: "end",

      "& .MuiButtonBase-root": {
        height: "32px",
        padding: "0 26px",
        fontFamily: theme.typography.fontFamily,
        fontSize: "0.875rem",
        fontWeight: 400,
        borderRadius: "16px",
        boxShadow: "none",
        textTransform: "none",
      },

      "& .MuiButtonBase-root.secondary-button": {
        marginRight: "8px",
        backgroundColor: theme.palette.white,
        border: "2px solid #8986AD",
        color: theme.palette.marketing.lightPurple,
        "&:hover": {
          backgroundColor: "#47474712",
        },
      },
      "& .MuiButtonBase-root.primary-button": {
        backgroundColor: "#1473E6",
        color: theme.palette.white,
        "&:hover": {
          backgroundColor: "#203ED4",
        },
      },
    },
  };
});

export default useStyles;
