import { useUser } from "@stagewood/unified-login-library";
import AnimatedLoader from "../../../AnimatedLoader";
import { Cart } from "../../../Cart";
import { useLineItems } from "../../../Checkout/CheckoutContent/useLineItems.hook";
import { MembershipCartPreviewProps } from "./interfaces/membership-cart-preview.interface";

export function MembershipCartPreview(props: MembershipCartPreviewProps): JSX.Element {
  const user = useUser();
  const cartLineItems = useLineItems(props.cart);

  return (
    <>
      {props.loading && <AnimatedLoader height={80} width={80} />}
      {!props.loading && cartLineItems && (
        <Cart lineItems={cartLineItems} cartTitleId={props.isEditMode ? "membership_details" : "cart_title"} isDraft />
      )}
    </>
  );
}
