import { useState, useEffect } from "react";
import { Switch } from "@mui/material";
import { useUser } from "@stagewood/unified-login-library";
import { useMutation, useQuery } from "@apollo/client";
import { BounceLoader } from "react-spinners";
import { FormattedMessage } from "react-intl";
import { UPDATE_ENROLL_QR_CODE_STATUS_MUTATION } from "../../../apollo/mutations";
import { GET_DEFAULT_ENROLL_QR_CODE } from "../../../apollo/queries";

import useStyles from "./styles";
import theme from "../../../theme";

const ControlUserEnroll = () => {
  const { wrap } = useStyles();
  const [switchState, setSwitchState] = useState(false);
  const user = useUser();

  const {
    loading: isQueryLoading,
    error: queryError,
    data: queryData,
  } = useQuery(GET_DEFAULT_ENROLL_QR_CODE, {
    variables: {
      userId: user?.id,
    },
  });

  const [
    updateEnrollQrCodeStatus,
    { loading: isUpdateEnrollQrCodeStatusMutationLoading, data: updateEnrollQrCodeStatusData },
  ] = useMutation(UPDATE_ENROLL_QR_CODE_STATUS_MUTATION);

  useEffect(() => {
    if (queryData?.getDefaultEnrollQrCode?.qrCodePayload) {
      setSwitchState(queryData?.getDefaultEnrollQrCode?.qrCodePayload?.isActive);
    }
  }, [queryData]);

  const handleToogle = async (event) => {
    await updateEnrollQrCodeStatus({
      variables: {
        userId: user?.id,
        isActive: event.target.checked,
      },
    });
    setSwitchState(event.target.checked);
  };

  const renderLoadingSpinner = isUpdateEnrollQrCodeStatusMutationLoading || isQueryLoading;

  return (
    <div className={wrap}>
      <div className="row">
        <FormattedMessage id="allow_new_users" />
      </div>
      <div className="row">
        {renderLoadingSpinner && <BounceLoader size={20} color={theme.palette.blue} />}
        <Switch onChange={handleToogle} checked={switchState} disabled={!!isQueryLoading} />
      </div>
    </div>
  );
};

export default ControlUserEnroll;
