import { FC, ReactNode } from "react";
import { Box, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import useStyles from "./styles";
import { MessageIds } from "../../../../i18n";

interface PsychographicsCardProps {
  headerTitle: MessageIds;
  children: ReactNode;
}

const PsychographicsCard: FC<PsychographicsCardProps> = ({ headerTitle, children }) => {
  const { wrap } = useStyles();

  return (
    <Box className={wrap}>
      <Box className="cardHeader">
        <Typography className="headerTitle">
          <FormattedMessage id={headerTitle} />
        </Typography>
      </Box>

      <Box className="cardBody">{children}</Box>
    </Box>
  );
};

export default PsychographicsCard;
