import { FC } from "react";
import { ButtonBase, Grid, Typography } from "@mui/material";
import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import Carousel from "react-elastic-carousel";
import { FormattedMessage } from "react-intl";
import One from "../../../../assets/images/suggestedExperiences/1.png";
import Two from "../../../../assets/images/suggestedExperiences/2.png";
import Three from "../../../../assets/images/suggestedExperiences/3.png";
import Four from "../../../../assets/images/suggestedExperiences/4.png";
import Five from "../../../../assets/images/suggestedExperiences/5.png";
import Six from "../../../../assets/images/suggestedExperiences/6.png";
import Seven from "../../../../assets/images/suggestedExperiences/7.png";
import SuggestedExperiencesItem from "../SuggestedExperiencesItem";
import useStyles from "./styles";

const items = [
  { id: "0", image: One },
  { id: "1", image: Two },
  { id: "2", image: Three },
  { id: "3", image: Four },
  { id: "4", image: Five },
  { id: "5", image: Six },
  { id: "6", image: Seven },
];

const breakpoints = [
  { width: 300, itemsToShow: 2 },
  { width: 400, itemsToShow: 3 },
  { width: 500, itemsToShow: 4 },
  { width: 640, itemsToShow: 5 },
  { width: 760, itemsToShow: 6 },
  { width: 800, itemsToShow: 7 },
  { width: 1200, itemsToShow: 8 },
];

const SuggestedExperiences: FC = () => {
  const { suggestedExperiencesContainer } = useStyles();
  return (
    <Grid className={suggestedExperiencesContainer}>
      <Typography className="suggestedExperiencesTitle">
        <FormattedMessage id="rewards.experiences.suggested_experiences" />
      </Typography>

      <Carousel
        isRTL
        breakPoints={breakpoints}
        pagination={false}
        renderArrow={({ type, onClick }) => (
          <ButtonBase className="switch-button" onClick={onClick}>
            {type === "PREV" ? <NavigateNext /> : <NavigateBefore />}
          </ButtonBase>
        )}
        className="carousel"
      >
        {items.map((item) => (
          <SuggestedExperiencesItem key={item.id} id={item.id} image={item.image} />
        ))}
      </Carousel>
    </Grid>
  );
};

export default SuggestedExperiences;
