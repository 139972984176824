import { graphql } from "../../../../gql";

export const getDraftSubscriptionCartQuery = graphql(`
  query getDraftSubscriptionCart($input: CreateSubscriptionCartInput!) {
    getDraftSubscriptionCart(input: $input) {
      message
      payload {
        id
        lineItems {
          label
          priceInCents
          renewalPeriod
          quantity
        }
      }
    }
  }
`);
