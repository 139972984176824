import React, { FC } from "react";
import { Button, Divider, Grid, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import Modal from "../../../Modal";
import useStyles from "./styles";
import { MessageIds } from "../../../../i18n";

interface DisableFeatureModalProps {
  isOpen: boolean;
  handleCloseModal: () => void;
  handleContinueModal: () => void;
  titleModal: MessageIds | JSX.Element;
  messageModal: MessageIds | JSX.Element;
  parentRule?: string;
  childRule?: string | null;
}

const DisableFeatureModal: FC<DisableFeatureModalProps> = ({
  isOpen,
  handleCloseModal,
  handleContinueModal,
  titleModal,
  messageModal,
  parentRule,
  childRule,
}) => {
  const { modalContainer } = useStyles();

  return (
    <Modal onClose={handleCloseModal} isOpen={isOpen} isClosable={false}>
      <Grid container direction="column" className={modalContainer}>
        <Grid className="modal-header">
          <h1 className="modal-header__title">
            {typeof titleModal === "string" ? <FormattedMessage id={titleModal} /> : titleModal}
          </h1>
        </Grid>

        <Divider className="modal-divider" />

        <Grid className="modal-body">
          {typeof messageModal === "string" ? (
            <Typography>
              <FormattedMessage id={messageModal} values={{ parentRule, childRule }} />
            </Typography>
          ) : (
            messageModal
          )}
        </Grid>

        <Grid className="modal-footer">
          <Button onClick={handleCloseModal} variant="contained" className="secondary-button">
            <FormattedMessage id="btn_text_cancel" />
          </Button>
          <Button onClick={handleContinueModal} variant="contained" className="primary-button">
            <FormattedMessage id="btn_text_continue" />
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default DisableFeatureModal;
