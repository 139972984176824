import clsx from "clsx";
import { FC, HTMLAttributes } from "react";
import { formatCentsToDollars } from "../../../shared/PriceFormatters";
import { useStyles } from "./styles";

interface LineItemProps extends HTMLAttributes<HTMLLIElement> {
  title: string;
  priceInCents: number;
}

export const LineItem: FC<LineItemProps> = ({
  title,
  priceInCents,
  className,
  ...attributes
}) => {
  const styles = useStyles();

  return (
    <li className={clsx(className, styles.container)} {...attributes}>
      <p className={styles.titleColumn}>•{title}</p>
      <p className={styles.priceColumn}>{formatCentsToDollars(priceInCents)}</p>
    </li>
  );
};
