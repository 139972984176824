import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => {
  return {
    wrap: {
      width: "288px",
      "& h4": {
        marginBottom: "25px",
        color: "#34305D",
        fontSize: "18px",
      },
    },

    row: {
      display: "flex",
      alignItems: "center",
      marginBottom: "15px",

      "&.middleRow": {
        marginBottom: "62px",
        "& .MuiInputBase-root": {
          width: "83px",
          marginRight: "30px",
        },
      },

      "& p": {
        fontSize: "12px",
      },

      "& .MuiInputBase-root": {
        width: "204px",
        marginTop: "4px",
        marginRight: "10px",
        height: "32px",
        padding: "8px 12px",
        border: "1px solid #CACACA",
        borderRadius: "4px",

        "& .MuiInputBase-input": {
          paddingBottom: "8px",
          fontSize: "12px",
          "&::placeholder": {
            color: "#000000",
            opacity: "1",
            fontSize: "12px",
          },
        },
      },
    },

    buttonsContainer: {
      display: "flex",

      "@media(max-width: 600px)": {
        justifyContent: "center",
      },

      "& .MuiButton-root": {
        height: "32px",
        padding: "0 27px",
        marginRight: "10px",
        color: "#8986AD",
        border: "2px solid #8986AD",
        borderRadius: "16px",
        textTransform: "unset",
        "&:last-child": {
          marginRight: "0",
          color: "#F3F3F3",
          borderColor: "#0364FF",
          background: "#0364FF",
        },
      },
    },
  };
});

export default useStyles;
