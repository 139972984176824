import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    wrap: {
      height: "100%",
      minWidth: "240px",
      scrollX: "auto",
      display: "flex",
      flexDirection: "column",
      padding: "16px 24px",
      borderRadius: "6px",
      border: "1px solid #CDCCD7",

      [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
        padding: "8px 12px 4px",
      },

      "& .cardHeader": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        marginBottom: "16px",

        "& .headerTitle": {
          fontSize: "1rem",
          color: theme.palette.primary.dark,
        },
      },

      "& .cardBody": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
        margin: "8px 0",
        overflow: "auto",

        [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
          margin: "4px 0",
        },
      },
    },
  };
});

export default useStyles;
