/* eslint-disable jsx-a11y/label-has-associated-control */
import { ChangeEvent, Dispatch, FC, HTMLAttributes, SetStateAction, useCallback } from "react";
import { Grid } from "@mui/material";
import clsx from "clsx";
import { FormattedMessage, useIntl } from "react-intl";
import { useStyles } from "./styles";
import { MIN_CHARACTERS_CARDHOLDER_NAME, CARDHOLDER_NAME_PATTERN } from "./CardForm.consts";
import { CardMessageStatus, CardElementType, CardInputsMessageStatus, EventStripe } from "./CardForm.types";
import { CardCvcInput } from "./CardCvcInput";
import { CardExpiryInput } from "./CardExpiryInput";
import { CardNumberInput } from "./CardNumberInput";
import { CardCardholderInput } from "./CardCardholderInput";
import { SectionTitle } from "../SectionTitle";

const errorMessageStatuses = new Set([CardMessageStatus.Invalid, CardMessageStatus.Empty]);

interface CardFormProps extends HTMLAttributes<HTMLDivElement> {
  cardholderName: string | null;
  onCardholderNameChange: Dispatch<SetStateAction<string | null>>;
  cardInputsMessageStatus: CardInputsMessageStatus;
  setCardInputsMessageStatus: Dispatch<SetStateAction<CardInputsMessageStatus>>;
}

const inputStyleOptions = {
  style: {
    base: {
      fontSize: "12px",
      fontWeight: "300",
      "::placeholder": { color: "#767676", fontWeight: "300" },
    },
  },
};

export const CardForm: FC<CardFormProps> = ({
  cardholderName,
  onCardholderNameChange: handleCardholderNameChange,
  cardInputsMessageStatus,
  setCardInputsMessageStatus,
  className,
  ...attributes
}) => {
  const styles = useStyles();
  const { formatMessage } = useIntl();

  const { cardCardholderMessageStatus, cardNumberMessageStatus, cardExpiryMessageStatus, cardCvcMessageStatus } =
    cardInputsMessageStatus;

  const hasCardholderNameErrors = cardCardholderMessageStatus && errorMessageStatuses.has(cardCardholderMessageStatus);
  const hasCardNumberErrors = cardNumberMessageStatus && errorMessageStatuses.has(cardNumberMessageStatus);
  const hasCardExpiryErrors = cardExpiryMessageStatus && errorMessageStatuses.has(cardExpiryMessageStatus);
  const hasCardCvcErrors = cardCvcMessageStatus && errorMessageStatuses.has(cardCvcMessageStatus);

  const isBottomErrors = hasCardExpiryErrors || hasCardCvcErrors;

  const setMessageStatusHandler = {
    [CardElementType.CardNumber]: (status: CardMessageStatus) =>
      setCardInputsMessageStatus({ ...cardInputsMessageStatus, cardNumberMessageStatus: status }),
    [CardElementType.CardExpiry]: (status: CardMessageStatus) =>
      setCardInputsMessageStatus({ ...cardInputsMessageStatus, cardExpiryMessageStatus: status }),
    [CardElementType.CardCvc]: (status: CardMessageStatus) =>
      setCardInputsMessageStatus({ ...cardInputsMessageStatus, cardCvcMessageStatus: status }),
  };

  const onChangeErrorHandler = (event: EventStripe) => {
    const setMessageStatus = (status: CardMessageStatus) => setMessageStatusHandler[event.elementType](status);

    if (event.complete) setMessageStatus(CardMessageStatus.Complete);
    if (event.error) setMessageStatus(CardMessageStatus.Invalid);
    if (event.empty) setMessageStatus(CardMessageStatus.Empty);
  };

  const customInputStyleOptions = useCallback(
    (formatMessageId) => {
      return {
        ...inputStyleOptions,
        placeholder: formatMessage({ id: formatMessageId }),
      };
    },
    [formatMessage],
  );

  const onChangeCardholderNameHandler = (event: ChangeEvent<HTMLInputElement>) => {
    handleCardholderNameChange(event.target.value.replace(CARDHOLDER_NAME_PATTERN, ""));

    if (event.target.value.length > 0) {
      setCardInputsMessageStatus({
        ...cardInputsMessageStatus,
        cardCardholderMessageStatus: CardMessageStatus.Complete,
      });
    } else {
      setCardInputsMessageStatus({
        ...cardInputsMessageStatus,
        cardCardholderMessageStatus: CardMessageStatus.Empty,
      });
    }
  };

  const onFocusOutCardholderName = (event: ChangeEvent<HTMLInputElement>) => {
    handleCardholderNameChange(event.target.value.trim());

    if (event.target.value.trim().length && event.target.value.trim().length < MIN_CHARACTERS_CARDHOLDER_NAME) {
      setCardInputsMessageStatus({
        ...cardInputsMessageStatus,
        cardCardholderMessageStatus: CardMessageStatus.Invalid,
      });
    }
  };

  return (
    <Grid
      className={clsx(styles.container, className)}
      {...attributes}
      style={{ marginBottom: !isBottomErrors ? "40px" : "0px" }}
    >
      <SectionTitle>
        <FormattedMessage id="payment_method_form.card_section.title" />
      </SectionTitle>
      <CardCardholderInput
        hasCardholderNameErrors={hasCardholderNameErrors}
        cardholderName={cardholderName}
        onChangeCardholderNameHandler={onChangeCardholderNameHandler}
        onFocusOutCardholderName={onFocusOutCardholderName}
        cardMessageStatus={cardCardholderMessageStatus}
      />
      <CardNumberInput
        hasCardNumberErrors={hasCardNumberErrors}
        cardMessageStatus={cardNumberMessageStatus}
        customInputStyleOptions={customInputStyleOptions}
        onChangeErrorHandler={onChangeErrorHandler}
      />
      <CardExpiryInput
        cardMessageStatus={cardExpiryMessageStatus}
        customInputStyleOptions={customInputStyleOptions}
        onChangeErrorHandler={onChangeErrorHandler}
      />
      <CardCvcInput
        cardMessageStatus={cardCvcMessageStatus}
        customInputStyleOptions={customInputStyleOptions}
        onChangeErrorHandler={onChangeErrorHandler}
      />
    </Grid>
  );
};
