import clsx from "clsx";
import { FC } from "react";
import { useStyles } from "./styles";

interface SectionTitleProps {
  className?: string;
}

export const SectionTitle: FC<SectionTitleProps> = ({ className, children }) => {
  const styles = useStyles();

  return <h1 className={clsx(styles.title, className)}>{children}</h1>;
};
