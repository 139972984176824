import { FC } from "react";
import { Grid, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import WestIcon from "@mui/icons-material/West";

import { FormattedMessage } from "react-intl";
import useStyles from "./style";

import Header from "../Layout/HeaderAlternative";

import { ReactComponent as AppInstall } from "../../assets/icons/enrollIcons/app-install.svg";
import { ReactComponent as CompleteProfile } from "../../assets/icons/enrollIcons/complete-profile.svg";
import { ReactComponent as Enter } from "../../assets/icons/enrollIcons/enter.svg";
import { ReactComponent as Business } from "../../assets/icons/enrollIcons/business.svg";

const HowToCreateBusinessProfile: FC = () => {
  const styles = useStyles();

  return (
    <Grid container>
      <Grid item>
        <Header />
      </Grid>
      <Grid container className={styles.pageRoot}>
        <Grid className={styles.container} container px={{ xs: 3, sm: 8, md: 10, lg: 1 }} direction="column">
          <Grid
            container
            alignItems={{ xs: "center", lg: "flex-end" }}
            justifyContent={{ xs: "center", md: "flex-start" }}
            className={styles.businessSection}
          >
            <Grid container item direction="column" sm={12} md={6} mb={{ sm: 4, md: 0 }}>
              <Typography className={styles.title} variant="h1">
                <FormattedMessage id="enroll.how_to_create_business_profile.title" />
              </Typography>
              <Typography className={styles.description}>
                <FormattedMessage id="enroll.how_to_create_business_profile.sub_title" />
              </Typography>
            </Grid>
            <Business className={styles.businessIcon} />
          </Grid>

          <Grid
            item
            container
            direction={{ xs: "column", sm: "row" }}
            alignItems={{ xs: "center", sm: "flex-start" }}
            justifyContent={{ sm: "space-around", md: "space-between" }}
            mt={{ xs: 6, sm: 8 }}
          >
            <Grid
              item
              container
              className={styles.step}
              direction="column"
              style={{ maxWidth: "230px" }}
              mt={{ xs: 2 }}
            >
              <Grid item container className="step-icon_container">
                <span className="index">1</span>
                <AppInstall className="step-icon" />
              </Grid>
              <Grid item container>
                <Typography className={styles.stepName} textAlign={{ xs: "center", sm: "left" }} mt={{ xs: 2, sm: 4 }}>
                  <FormattedMessage id="enroll.how_to_create_business_profile.open_app" />
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              className={styles.step}
              direction="column"
              style={{ maxWidth: "230px" }}
              mt={{ xs: 2 }}
            >
              <Grid item container className="step-icon_container">
                <span className="index">2</span>
                <CompleteProfile className="step-icon" />
              </Grid>
              <Grid item container>
                <Typography className={styles.stepName} textAlign={{ xs: "center", sm: "left" }} mt={{ xs: 2, sm: 4 }}>
                  <FormattedMessage id="enroll.how_to_create_business_profile.create_a_profile" />
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              className={styles.step}
              direction="column"
              style={{ maxWidth: "230px" }}
              mt={{ xs: 2 }}
            >
              <Grid item container className="step-icon_container" justifyContent="center">
                <span className="index">3</span>
                <Enter className="step-icon" />
              </Grid>
              <Grid item container>
                <Typography className={styles.stepName} textAlign={{ xs: "center", sm: "left" }} mt={{ xs: 2, sm: 4 }}>
                  <FormattedMessage id="enroll.how_to_create_business_profile.come_here_again_and_login" />
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item container className={styles.buttonContainer} justifyContent={{ xs: "center", md: "flex-end" }}>
            <Button className={styles.buttonProfileSelection} component={Link} to="/choose-business">
              <WestIcon className={styles.westIcon} />
              <FormattedMessage id="enroll.how_to_create_business_profile.go_to_profile" />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HowToCreateBusinessProfile;
