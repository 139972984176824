import { FC } from "react";
import { Dialog, Typography, DialogContent, DialogActions, Button, Autocomplete, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useIntl } from "react-intl";
import useStyles from "./styles";
import { IBrokerSelectionModal, BrokerSelectionModalText } from "./types";
import { TykBroker } from "../../../../generated/types";
import { LoadingButton } from "../../../LoadingButton/loading-button.component";

export const BrokerSelectionModal: FC<IBrokerSelectionModal> = ({
  open,
  onClose,
  handleChange,
  handleInputChange,
  onConfirm,
  bokers,
  isBrokerSelected,
  isLoading,
}) => {
  const { brokerSelectionModal } = useStyles();
  const intl = useIntl();

  const formatTitle = (broker: Partial<TykBroker>): string => {
    const email = broker.business?.email ? ` (${broker.business.email})` : "";
    return `${broker.business?.username}${email}`;
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="broker-selection-dialog"
      aria-describedby="broker-selection-dialog"
      fullWidth
      className={brokerSelectionModal}
    >
      <Box className="modal">
        <Box className="modal-title">
          <Typography className="modal-title-text">{BrokerSelectionModalText(intl).TITLE}</Typography>
        </Box>
        <DialogContent className="modal-content">
          <Autocomplete
            options={bokers}
            getOptionLabel={formatTitle}
            renderInput={(params) => (
              <TextField
                {...params}
                title={String(params.inputProps.value)}
                placeholder={BrokerSelectionModalText(intl).PLACEHOLDER}
              />
            )}
            onChange={(e, value) => {
              handleChange(value?.id || "");
            }}
            onInputChange={(e, newValue) => {
              handleInputChange(newValue);
            }}
            filterOptions={(options) => options}
            noOptionsText={BrokerSelectionModalText(intl).NO_MATCHES}
            disabled={isLoading}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={onClose} className="cancel-btn btn">
            {BrokerSelectionModalText(intl).CANCEL}
          </Button>
          <LoadingButton
            variant="outlined"
            onClick={onConfirm}
            disabled={!isBrokerSelected}
            loading={isLoading}
            classes={{ loadingIndicator: "loading-indicator" }}
            className="select-btn btn"
          >
            {BrokerSelectionModalText(intl).SELECT}
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
