import { FC } from "react";
import { Box, Typography } from "@mui/material";
import dummyImage from "../../../../../assets/images/NoPath.png";
import loneStar from "../../../../../assets/images/LoneStarIcon.png";
import moneyBillIcon from "../../../../../assets/icons/tykIcons/money-bill-icon.svg";
import theme from "../../../../../theme";
import { Campaign } from "../../../types";
import useStyles from "./styles";

interface ExperiencesItemProps {
  itemData: Campaign;
  itemBackgroundColor: string;
}

const ExperiencesItem: FC<ExperiencesItemProps> = ({
  itemData,
  itemBackgroundColor = "#FFDDC2",
}) => {
  const { campaignItemWrap } = useStyles();

  return (
    <Box className={campaignItemWrap} bgcolor={itemBackgroundColor}>
      <Box className="campaignItemImageWrap">
        <img className="campaignImage" src={dummyImage} alt="" />
      </Box>

      <Box className="campaignItemContentWrap">
        <Typography
          color={theme.palette.primary.dark}
          fontFamily={theme.typography.fontFamily}
          fontSize="0.75rem"
        >
          {itemData.name}
        </Typography>

        <Box className="campaignItemContent">
          <Box className="campaignItemStatistic">
            <Box>
              <img src={loneStar} alt="LoneStar" />
            </Box>

            <Typography
              color={theme.palette.primary.dark}
              fontFamily={theme.typography.fontFamily}
              fontSize="0.625rem"
              fontWeight="bold"
            >
              {itemData.spent.toLocaleString()} / {itemData.reward?.toLocaleString()}
            </Typography>
          </Box>

          <Box className="campaignItemStatisticDivider" />

          <Box className="campaignItemStatistic">
            <Box>
              <img src={moneyBillIcon} alt="Spent" className="smallImage" />
            </Box>

            <Typography
              color={theme.palette.primary.dark}
              fontFamily={theme.typography.fontFamily}
              fontSize="0.625rem"
              fontWeight="bold"
            >
              -${itemData.spent.toLocaleString()}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ExperiencesItem;
