import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    tableContainer: {
      "& .MuiTableCell-head": {
        padding: "6px 10px",
        color: "#828282",
        fontFamily: theme.typography.fontFamily,
        fontSize: "0.75rem",
        lineHeight: "normal",
      },

      "& .MuiTableBody-root": {
        "& .MuiTableRow-root": {
          backgroundColor: "transparent",
          "&:hover": {
            backgroundColor: "transparent",
          },

          "& .MuiTableCell-body": {
            padding: "10px",
            color: theme.palette.marketing.darkGrey,
            fontSize: "0.875rem",
            lineHeight: "normal",

            "& .event-image": {
              width: "30px",
              height: "30px",
              marginRight: "6px",
              borderRadius: "50%",
              backgroundColor: theme.palette.marketing.lightPurple,
            },

            "& .type-wrapper": {
              width: "27px",
              height: "27px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: theme.palette.marketing.lightGrey,
              borderRadius: "50%",

              "& .type-text": {
                fontSize: "0.75rem",
                color: "#828282",
              },
            },

            "& .MuiCheckbox-root": {
              padding: "3px",
              height: "32px",

              "& .MuiSvgIcon-root": {
                width: "18px",
                height: "18px",
                fill: theme.palette.marketing.green,
              },
            },
          },

          "& .MuiTableCell-body.event-image-cell": {
            width: "50px",
          },
        },
      },
    },
  };
});

export default useStyles;
