import { TykchaseCampaign, TykchaseCampaignTriggers, TykchaseCampaignTypes } from "../generated/types";

export type StepsFormikValues = {
  title: string;
  description: string;
  holidayId: string;
  startDate: string;
  endDate: string;
  customCoverBase64: string;
  triggerType: TykchaseCampaignTriggers | boolean;
  campaignType: TykchaseCampaignTypes;
  receiptTotalPriceTrigger: number;
  surpriseNumber: number;
  multiplierNumber: number;
  name: string;
  price: number;
  code: string;
  dayTrigger: string;
  boostedTykchaseCampaignId: string;
  boostedTykchaseCampaign: any;
  dollarReward: string;
  purchaseRewardRate: string;
};

export type InformationStepFormikValues = {
  title: string;
  description: string;
  holidayId?: string;
  startDate: string;
  endDate: string;
  customCoverBase64?: string;
  triggerType: TykchaseCampaignTriggers | boolean;
  campaignType: TykchaseCampaign;
};

export type TriggerStepFormikValues = {
  receiptTotalPriceTrigger: number;
  surpriseNumber: number;
  multiplierNumber: number;
  name: string;
  price: number;
  code: string;
  dayTrigger: string;
  boostedTykchaseCampaignId: string;
  boostedTykchaseCampaign: TykchaseCampaign;
};

export type RewardStepFormikValues = {
  dollarReward: number;
};

export enum CampaignSteps {
  Information = "Information",
  Trigger = "Trigger",
  Reward = "Reward",
  Summary = "Summary",
}
