import { FC, useEffect, useState, useRef } from "react";
import { FormikProps } from "formik";
import { useTheme } from "@mui/styles";
import { useQuery } from "@apollo/client";
import { useIntl } from "react-intl";
import Icon from "../../../../../../utils/icon";
import { GET_ALL_HOLIDAYS } from "../../../../../../apollo/queries";
import { ITykchaseGlobalConfig } from "../../../../../../types/tykchaseGlobalConfig";
import { TykchaseCampaignTypes, TykchaseCampaignTriggers, Holiday } from "../../../../../../generated/types";

import { CampaignSteps, StepsFormikValues } from "../../../../../../types/campaigns";
import CampaingLayout from "../Layout/CampaingLayout";

type Props = {
  active: boolean;
  handlerModal: () => void;
  tykchaseGlobalConfig?: ITykchaseGlobalConfig;
};

const Holidays: FC<Props> = ({ active, handlerModal, tykchaseGlobalConfig }: Props) => {
  const theme = useTheme();
  const [holidays, setHolidays] = useState<Holiday[]>([]);
  const informationRef = useRef<FormikProps<StepsFormikValues>>(null);
  const triggerRef = useRef<FormikProps<StepsFormikValues>>(null);
  const rewardRef = useRef<FormikProps<StepsFormikValues>>(null);
  const { formatMessage } = useIntl();
  const { data: holidaysData, loading: isLoadingHolidaysData } = useQuery(GET_ALL_HOLIDAYS, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (holidaysData) {
      setHolidays(holidaysData.getAllHolidays);
    }
  }, [holidaysData]);

  return (
    <CampaingLayout
      active={active}
      handlerModal={handlerModal}
      holidays={holidays}
      informationRef={informationRef}
      triggerRef={triggerRef}
      rewardRef={rewardRef}
      Icon={Icon.Holiday}
      title={formatMessage({ id: "rewards.campaigns.holiday.title" })}
      subTitle={formatMessage({ id: "rewards.campaigns.holiday.sub_title" })}
      mainBgColor={theme.palette.campaigns.lightGreen}
      secondaryBgColor={theme.palette.campaigns.green}
      paginationTitles={[
        formatMessage({ id: "rewards.campaigns.pagination_titles.information" }),
        formatMessage({ id: "rewards.campaigns.pagination_titles.trigger" }),
        formatMessage({ id: "rewards.campaigns.pagination_titles.reward_settings" }),
      ]}
      informationStepKey={`${TykchaseCampaignTypes.Holidays}-${CampaignSteps.Information}`}
      triggerType={TykchaseCampaignTriggers.ReceiptTotal}
      campaignType={TykchaseCampaignTypes.Holidays}
      rewardStepKey={`${TykchaseCampaignTypes.Holidays}-${CampaignSteps.Reward}`}
      triggerStepKey={`${TykchaseCampaignTypes.Holidays}-${CampaignSteps.Trigger}`}
      summaryStepKey={`${TykchaseCampaignTypes.Holidays}-${CampaignSteps.Summary}`}
      tykchaseGlobalConfig={tykchaseGlobalConfig}
      isLoadingHolidaysData={isLoadingHolidaysData}
    />
  );
};
export default Holidays;
