import {
  FormControl,
  Grid,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import Icon from '../../../../../utils/icon';
import Modal from '../../../../Modal';
import Layout from '../Layout';
import useStyles from './styles';

const AddNewPromoter = ({
  modalActive,
  handlerModal,
}: {
  modalActive: boolean;
  handlerModal: () => void;
}) => {
  const { wrap, wrapSelect } = useStyles();
  return (
    <Modal isOpen={modalActive} onClose={handlerModal}>
      <Layout title={<FormattedMessage id="modals.promoter.title" />}>
        <Grid
          className={wrap}
          flexWrap="nowrap"
          flexDirection={{ xs: "column", md: "row" }}
          container
          p={{ xs: "0", sm: "0 50px", md: "0" }}
        >
          <Grid
            height="100px"
            width="100px"
            m={{ xs: "0 auto 20px", md: "0 30px 0 0" }}
            overflow="hidden"
            borderRadius="100px"
          >
            <Icon.HappyGirls />
          </Grid>

          <Grid width="100%" maxWidth={{ xs: "210px", md: "301px" }} m={{ xs: "0 auto 20px", md: "0 30px 0 0" }}>
            <Grid mb="30px">
              <Typography>
                <FormattedMessage id="modals.promoter.tyket_account" />
              </Typography>
              <InputBase placeholder="steve@tyket.com" />
            </Grid>

            <Grid mb="30px">
              <Typography>
                <FormattedMessage id="modals.promoter.id" />
              </Typography>
              <InputBase placeholder="0957849" />
            </Grid>

            <Grid className={wrapSelect}>
              <FormControl>
                <InputLabel id="demo-simple-select-label">
                  <FormattedMessage id="modals.promoter.select_a_franchise" />
                </InputLabel>
                <Select defaultValue="">
                  <MenuItem value="Weekly">
                    <FormattedMessage id="modals.promoter.weekly" />
                  </MenuItem>
                  <MenuItem value="Monthly">
                    <FormattedMessage id="modals.promoter.monthly" />
                  </MenuItem>
                  <MenuItem value="Every 3 months">
                    <FormattedMessage id="modals.promoter.every_three" />
                  </MenuItem>
                  <MenuItem value="Yearly">
                    <FormattedMessage id="modals.promoter.yearly" />
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid width="100%" maxWidth={{ xs: "210px", md: "301px" }} m={{ xs: "0 auto", md: "0" }}>
            <Typography>
              <FormattedMessage id="modals.promoter.promoter_name" />
            </Typography>
            <InputBase placeholder="Steve Balmer" />
          </Grid>
        </Grid>
      </Layout>
    </Modal>
  );
};

export default AddNewPromoter;
