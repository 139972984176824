import { FC } from "react";
import { Card, CardActionArea, CardActions, CardContent, CardMedia, Typography } from "@mui/material";
import useStyles from "./styles";
import AddToLoneStarsSectionButton from "../Modals/AddToLoneStarsSectionButton";
import OpenDetailsButton from "../Modals/OpenDetailsButton";

interface SuggestedExperiencesItemProps {
  id: string;
  image: string;
}

const SuggestedExperiencesItem: FC<SuggestedExperiencesItemProps> = ({ id, image }) => {
  const { container } = useStyles();

  return (
    <Card className={container}>
      <CardActionArea>
        <CardMedia className="cardImage" image={image} title="Contemplative Reptile" />

        <CardContent>
          <Typography className="cardDescription">Buy 1 Seasonal Special Halloween Blue Moons</Typography>
        </CardContent>
      </CardActionArea>

      <CardActions>
        <AddToLoneStarsSectionButton />

        <OpenDetailsButton />
      </CardActions>
    </Card>
  );
};

export default SuggestedExperiencesItem;
