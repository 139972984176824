import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  label: {
    fontSize: "7px",
    textTransform: "uppercase",
    color: "#34305D",
    marginBottom: "4px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
  },
  logo: {
    width: "82px",
  },
}));
