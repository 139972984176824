import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    wrap: {
      width: "100%",
      marginTop: "40px",
    },

    contentWrap: {
      margin: "0 20px",
      padding: "40px 20px",
      background: theme.palette.white,
    },
  };
});

export default useStyles;
