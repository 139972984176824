import {  Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import clsx from "clsx";
import CheckTwoToneIcon from "@mui/icons-material/CheckTwoTone";
import LockIcon from "@mui/icons-material/Lock";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useStyles, membershipTableStyle } from "./styles";
import { FooterButtons } from "../../Buttons";
import { MembershipDetails } from "../types";
import { buttonText, isEnabledPlan } from "../helpers";
import ComingSoonLabel from "../../Utils/ComingSoonLabel";
import { MembershipRule } from "../MembershipSummary/types";
import { Memberships } from "../../../types/membershipTypes";




export interface TableComponentProps {
  memberships: MembershipDetails[];
  onSelectPlan: (plan: string) => void;
  membershipTitle: string | null | undefined;
  allRules: MembershipRule[][];
}
enum MembershipTable {
  Perks = "Perks",
}

const TableComponent: FC<TableComponentProps> = ({ memberships, onSelectPlan, membershipTitle, allRules }) => {
  const {
    root,
    container,
    tableHeadings,
    tableFooter,
    footerComingSoon,
    groupTitle,
    groupItems,
    enterprise,
    brands,
    priceTitle,
  } = useStyles();

  const columns = useMemo(
    () => [{ title: MembershipTable.Perks, type: MembershipTable.Perks, internalId:99 }, ...memberships],
    [memberships],
  );

  return (
    <Paper sx={{ ...membershipTableStyle.root }} className={root}>
      <TableContainer sx={{ ...membershipTableStyle.container }} className={container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.title}
                  align={column.title !== MembershipTable.Perks ? "center" : undefined}
                  className={clsx(
                    tableHeadings,
                    column.internalId === 2 && brands,
                    column.internalId === 3 && enterprise,
                  )}
                >
                  {column.title === MembershipTable.Perks ? (
                    <FormattedMessage id="membership.membership_types.title" />
                  ) : (
                    column.title
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                sx={{
                  ...membershipTableStyle.perkTitle,
                }}
                component="th"
                scope="row"
              >
                <Typography>
                  <FormattedMessage id="membership.lifetime_membership" />
                </Typography>
              </TableCell>
              {memberships.map((item) => {
                return (
                  <TableCell
                    sx={{
                      ...membershipTableStyle.priceTitle,
                    }}
                    key={item.title}
                    align="center"
                  >
                    <Typography className={priceTitle}>${item.setupFee}</Typography>
                  </TableCell>
                );
              })}
            </TableRow>

            {allRules.map(
              (perkCategory) =>
                perkCategory &&
                perkCategory.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        ...membershipTableStyle[row.perkTitle ? "groupTitle" : "perkTitle"],
                      }}
                    >
                      <Typography>{row.title || row.perkTitle}</Typography>
                      {!row.isActive && !row.perkTitle && <ComingSoonLabel isTable />}
                    </TableCell>
                    {memberships.map((item) => {
                      return (
                        <TableCell
                          key={item.title}
                          align="center"
                          className={row.perkTitle ? groupTitle : groupItems}
                          sx={{ ...membershipTableStyle[row.perkTitle ? "groupTitle" : "groupItems"] }}
                        >
                          {row.title ? <CheckTwoToneIcon /> : null}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                )),
            )}

            <TableRow>
              <TableCell align="center" sx={{ ...membershipTableStyle.tableFooter }} className={tableFooter} />
              {memberships.map((column) => (
                <TableCell
                  key={column.title}
                  align="center"
                  sx={{ ...membershipTableStyle.tableFooter }}
                  className={tableFooter}
                >
                  {isEnabledPlan(column.title) ? (
                    <FooterButtons
                      buttonText={buttonText(membershipTitle, column.title)}
                      btnOnClick={() => {
                        onSelectPlan(`${column.title}`);
                      }}
                    />
                  ) : (
                    <Grid sx={{ ...membershipTableStyle.footerComingSoon }} className={footerComingSoon}>
                      <LockIcon style={{ color: "#b04f57", fontSize: "1rem" }} />
                      <FormattedMessage id="btn_text_coming_soon" />
                    </Grid>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default TableComponent;
