import { defineMessages, IntlShape } from "react-intl";

const messages = defineMessages({
  comingSoon: {
    id: "label_coming_soon",
    defaultMessage: "Coming soon",
  },
});

const Labels = (intl: IntlShape) => {
  return {
    COMING_SOON: intl.formatMessage(messages.comingSoon),
  };
};

export { Labels };
