import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  container: {
    display: "grid",
    gridTemplateColumns: "3fr 2fr",
    padding: "0 24px",
  },
  titleColumn: {
    color: "#797882",
    fontSize: "15px",
  },
  quantityColumn: {
    textAlign: "right",
    fontSize: "18px",
  },
  priceColumn: {
    textAlign: "right",
    fontSize: "18px",
  },
}));
