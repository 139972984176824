import { FC, useState } from "react";
import { Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import DetailsModal from "./DetailsModal";
import useStyles from "./styles";

const OpenDetailsButton: FC = () => {
  const { detailsButton } = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Typography className={detailsButton} onClick={() => setIsModalOpen(true)}>
        <FormattedMessage id="rewards.experiences.details" />
      </Typography>

      <DetailsModal isOpen={isModalOpen} onClose={handleCloseModal} />
    </>
  );
};

export default OpenDetailsButton;
