import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  label: {
    fontSize: "12px",
    color: "#6E6E6E",
  },
  select: {
    fontSize: "12px",
    color: "#000000",

    "&.react-dropdown-select": {
      border: "1px solid #CACACA",
      borderRadius: "4px",
      padding: "0 8px",
      minHeight: "32px",
    },

    "& .react-dropdown-select-content, & .react-dropdown-select-input": {
      fontSize: "12px",
      margin: 0,
    },

    "& .react-dropdown-select-dropdown": {},
  },
}));
