import { PDFDownloadLink } from "@react-pdf/renderer";
import { useMemo } from "react";
import { PDFProviderProps } from "./interfaces/pdf-provider.interface";

export function PDFProvider(props: PDFProviderProps) {
  return useMemo(
    () => (
      <PDFDownloadLink document={props.children}>
        {({ loading, blob, error }) => {
          if (!loading && blob && props.onBlobReady) {
            props.onBlobReady(blob);
          }

          if (error && props.onError) {
            props.onError(error);
          }

          return null;
        }}
      </PDFDownloadLink>
    ),
    [props],
  );
}
