import { forwardRef, ReactNode, useCallback } from "react";
import { Alert, AlertColor } from "@mui/material";
import { SnackbarContent, useSnackbar } from "notistack";
import useStyles from "./styles";

interface NotificationProps {
  variant: AlertColor;
  message: string | ReactNode;
  id: string | number;
}

const Notification = forwardRef<HTMLDivElement, NotificationProps>(function Notification(
  { variant, message, id },
  ref,
) {
  const { root, alert } = useStyles();
  const { closeSnackbar } = useSnackbar();

  const handleClose = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  return (
    <SnackbarContent ref={ref} className={root} onClick={handleClose}>
      <Alert severity={variant} className={alert}>
        {message}
      </Alert>
    </SnackbarContent>
  );
});

export default Notification;
