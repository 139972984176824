import { parse } from "qs";
import { useLocation } from "react-router-dom";
import { formatDollars } from "../../../shared/PriceFormatters";
import { AMOUNT_PARAM_KEY } from "../DepositSettings.consts";

export const useFormattedRechargeAmount = () => {
  const { search } = useLocation();
  const { [AMOUNT_PARAM_KEY]: amount } = parse(search, { ignoreQueryPrefix: true }) ?? {};

  return formatDollars(amount ? parseFloat(amount as string) : 0);
};
