import { useCallback } from "react";

import { SortingOrderEnum } from "../shared/enums/sorting-order.enum";
import {
  PaginationParams,
  PaginationQueryParamsHookOptions,
  SetPaginationParams,
} from "./interfaces/use-pagination-query-params.interface";
import { useQueryParams } from "./useQueryParams.hook";
import { useSetQueryParams } from "./useSetQueryParams.hook";

export const DEFAULT_OFFSET = 0;
export const DEFAULT_LIMIT = 10;

export const usePaginationQueryParams = (
  options?: PaginationQueryParamsHookOptions,
): PaginationParams & SetPaginationParams => {
  const queryParams = useQueryParams();
  const setQueryParams = useSetQueryParams();

  const params: PaginationParams = {
    offset: parseInt(queryParams.offset as string, 10) || options?.defaultOffset || DEFAULT_OFFSET,
    limit: parseInt(queryParams.limit as string, 10) || options?.defaultLimit || DEFAULT_LIMIT,
    sortBy: (queryParams.sortBy as string) || options?.defaultSortBy || "",
    order: (queryParams.order as SortingOrderEnum) || options?.defaultOrder || SortingOrderEnum.ASC,
  };

  const setOffset = useCallback(
    (newOffset: number) => setQueryParams({ offset: newOffset }),
    [setQueryParams],
  );

  const setLimit = useCallback(
    (newLimit: number) => setQueryParams({ limit: newLimit }),
    [setQueryParams],
  );

  const setLimitAndOffset = useCallback(
    (newLimit: number, newOffset: number) => setQueryParams({ limit: newLimit, offset: newOffset }),
    [setQueryParams],
  );

  const setSortParams = useCallback(
    (newSortBy: string, newOrder: string) => setQueryParams({ sortBy: newSortBy, order: newOrder }),
    [setQueryParams],
  );

  return {
    ...params,
    setOffset,
    setLimit,
    setSortParams,
    setLimitAndOffset,
  };
};
