import { graphql } from "../../../gql";

export const removeAddressVariantMutation = graphql(`
  mutation RemoveAddressVariant($id: String!) {
    removeTykchaseLocationReceiptVariant(id: $id) {
      status
      message
      payload {
        id
        tykchaseLocationId
        storeTitle
        storeAddressNormalized
        receiptUrl
        createdAt
      }
    }
  }
`);
