import DropdownSelect, { SelectProps as DropdownSelectProps } from "react-dropdown-select";
import { useCallback, useMemo } from "react";
import { useStyles } from "./styles";

export interface SelectItem {
  value: string;
  label: string;
}

export interface SelectProps<T extends object, K extends keyof T>
  extends Omit<DropdownSelectProps<T>, "values" | "onChange" | "valueField"> {
  className?: string;
  label: string;
  value: T[K] | null;
  valueField?: K;
  onChange: (value: T[K] | null) => void;
}

export const Select = <T extends object = SelectItem, K extends keyof T = keyof T>({
  className,
  options,
  value,
  onChange: handleChange,
  valueField = "value" as K,
  label,
  ...selectProps
}: SelectProps<T, K>): JSX.Element => {
  const styles = useStyles();
  const selectedValues = useMemo(
    () => options.filter((option) => option?.[valueField] === value),
    [options, value, valueField],
  );
  const handleValueChange = useCallback(
    ([option]: T[]) => {
      handleChange(option?.[valueField] ?? null);
    },
    [handleChange, valueField],
  );

  return (
    <section className={className}>
      <span className={styles.label}>{label}</span>
      <DropdownSelect
        {...selectProps}
        options={options}
        values={selectedValues}
        valueField={valueField as string | undefined}
        onChange={handleValueChange}
        className={styles.select}
      />
    </section>
  );
};
