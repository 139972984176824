import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Grid, Paper, Typography, Button, Stack, Box, InputBase, CircularProgress, styled } from "@mui/material";
import clsx from "clsx";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useMutation } from "@apollo/client";
import CheckIcon from "@mui/icons-material/Check";
import { useUser } from "@stagewood/unified-login-library";
import { green } from "@mui/material/colors";
import { FormattedMessage } from "react-intl";
import _ from "lodash";
import { useStyles } from "./styles";
import { IPaymentMethod, PaymentMethodProps } from "./PaymentMethod.interfaces";
import { useBooleanState } from "../../../hooks/useBooleanState.hook";
import { useStyles as useCardFormStyles } from "../../Checkout/PaymentMethods/CardForm/styles";
import {
  MAX_CHARACTERS_CARDHOLDER_NAME,
  MIN_CHARACTERS_CARDHOLDER_NAME,
} from "../../Checkout/PaymentMethods/CardForm/CardForm.consts";
import { updateUserPaymentMethodMutation } from "./graphql/updateUserPaymentMethod.mutation";
import { useCreditCardExpiryFormatter } from "./hooks/use-credit-card-expiry-formatter.hook";
import { getPaymentMethodsQuery } from "../../Checkout/PaymentMethods/graphql/GetPaymentMethods.query";
import { useDefaultPaymentMethod } from "../hooks/useDefaultPaymentMethod.hook";
import { cardLogos } from "./PaymentMethod.helpers";
import { PageLoader } from "../../PageLoader";
import { BillingAddress as BillinAddressInfo } from "../BillingAddress";
import { AddressCreationForm } from "../../AddressSelectionModal/AddressCreationForm";
import { useAddressCreationForm } from "../../AddressSelectionModal/AddressCreationForm/useAddressCreationForm.hook";
import { BillingAddressFormik } from "../../../types/billingAddress.type";

dayjs.extend(customParseFormat);

const SaveButton = styled(Button)({
  "&.success": {
    backgroundColor: green[400],
  },
});

const buildBillingAddress = (paymentMethod: IPaymentMethod): BillingAddressFormik => {
  const formattedAddressLine = [paymentMethod?.city, paymentMethod?.state, paymentMethod?.countryCode]
    .filter(Boolean)
    .join(", ");
  return {
    addressCity: formattedAddressLine || "",
    addressStreet1: paymentMethod?.addressLine || "",
    addressStreet2: paymentMethod?.addressLineSecond || "",
    shortCountryCode: paymentMethod?.countryCode || "",
    zipCode: paymentMethod?.zipCode || "",
    regionName: paymentMethod?.state || "",
    isDefault: false,
    label: "",
  };
};

export const PaymentMethod: FC<PaymentMethodProps> = ({
  paymentMethod,
  onCardClick,
  chosenCardPaymentMethodId,
  onCardRemoveClick,
}) => {
  const user = useUser();
  const styles = useStyles();
  const cardFormStyles = useCardFormStyles();
  const creditCardExpiryHook = useCreditCardExpiryFormatter();
  const initialExpiry = creditCardExpiryHook.format(dayjs(paymentMethod.cardExpDate, "M/YYYY").format("MM/YY"));
  const [cardholder, setCardholder] = useState(paymentMethod.cardOwner);
  const [expiry, setExpiry] = useState(initialExpiry);
  const { value: editMode, setTrue: enableEditMode, setFalse: disableEditMode } = useBooleanState();
  const [initialBillingAddress] = useState(buildBillingAddress(paymentMethod));
  const [{ values, isValid, addressCreationProps }, submit] = useAddressCreationForm(initialBillingAddress, true);
  const [
    updateUserPaymentMethod,
    { loading: updatingPaymentMethod, data: updatedPaymentMethod, reset: resetUpdateUserMutation },
  ] = useMutation(updateUserPaymentMethodMutation);

  const handleSave = useCallback(() => {
    submit();
    if (editMode) {
      updateUserPaymentMethod({
        variables: {
          input: {
            userId: user?.id as string,
            paymentMethodId: paymentMethod.id,
            expiry: expiry.replaceAll(/\s/g, ""),
            cardholder,
            billingAddress: {
              city: values.addressCity.split(",")[0],
              state: values.regionName ?? ("" as string),
              countryCode: values.shortCountryCode?.toUpperCase() ?? ("" as string),
              addressLine1: values.addressStreet1,
              addressLine2: values.addressStreet2 || "",
              zipCode: values.zipCode,
            },
          },
        },
        refetchQueries: [getPaymentMethodsQuery],
        awaitRefetchQueries: true,
      })
        .then(() => {
          setTimeout(() => {
            disableEditMode();
            resetUpdateUserMutation();
          }, 2000);
        })
        .catch(() => {
          // catch to prevent general UI crash
        });
    }
  }, [
    cardholder,
    disableEditMode,
    editMode,
    expiry,
    paymentMethod.id,
    resetUpdateUserMutation,
    submit,
    updateUserPaymentMethod,
    user?.id,
    values.addressCity,
    values.addressStreet1,
    values.addressStreet2,
    values.regionName,
    values.shortCountryCode,
    values.zipCode,
  ]);
  const handleCardholderChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setCardholder(event.currentTarget.value);
  }, []);
  const handleExpiryChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget;

      if (creditCardExpiryHook.shouldFormat(value)) {
        setExpiry(creditCardExpiryHook.format(value));
      }
    },
    [creditCardExpiryHook],
  );

  const saveEnabled = useMemo(() => {
    const expiryChangedAndValid = expiry !== initialExpiry && /^(\d{2})\s\/\s(\d{2})$/.test(expiry);
    const cardholderChangedAndValid =
      cardholder !== paymentMethod.cardOwner &&
      cardholder.length &&
      cardholder.length > MIN_CHARACTERS_CARDHOLDER_NAME &&
      cardholder.length < MAX_CHARACTERS_CARDHOLDER_NAME &&
      /^(\s|[a-zA-Z\d ]+)$/.test(cardholder);
    const billingAddressValid = _.isEqual(initialBillingAddress, values);

    return expiryChangedAndValid || cardholderChangedAndValid || !billingAddressValid;
  }, [cardholder, expiry, initialBillingAddress, initialExpiry, paymentMethod.cardOwner, values]);

  useEffect(() => {
    if (!editMode) {
      setExpiry(initialExpiry);
      setCardholder(paymentMethod.cardOwner);
    }
  }, [editMode, initialExpiry, paymentMethod.cardOwner]);

  const { setAsDefaultMethod, setAsDefaultMethodLoading } = useDefaultPaymentMethod(paymentMethod.userPaymentMethodId);
  const handleClick = useCallback(() => {
    onCardClick(paymentMethod.userPaymentMethodId);
  }, [onCardClick, paymentMethod.userPaymentMethodId]);

  const handleRemoveClick = useCallback(
    (event) => {
      event.stopPropagation();
      onCardRemoveClick(paymentMethod.userPaymentMethodId, paymentMethod.isDefault);
    },
    [onCardRemoveClick, paymentMethod.isDefault, paymentMethod.userPaymentMethodId],
  );

  return (
    <Grid container className={styles.cardMargin} direction="row">
      <Grid item onClick={handleClick} lg={6} container direction="column" mb={2}>
        <Paper className={styles.cardWrap} elevation={4}>
          <Box display="flex" mb={3} alignItems="center" justifyContent="space-between">
            <Box display="flex">
              {cardLogos[paymentMethod.cardBrand]}
              <Typography ml={1} className={styles.cardBrand}>
                {paymentMethod.cardBrand}
              </Typography>
            </Box>
            {!setAsDefaultMethodLoading && paymentMethod.isDefault && (
              <Typography className={styles.cardBrand}>
                <FormattedMessage id="billing_payments.card_label_default" />
              </Typography>
            )}
            {!setAsDefaultMethodLoading && !paymentMethod.isDefault && (
              <Button className={styles.defaultButton} onClick={setAsDefaultMethod}>
                <FormattedMessage id="btn_text_set_as_default" />
              </Button>
            )}
            {setAsDefaultMethodLoading && <PageLoader isInline />}
          </Box>
          <Typography className={styles.cardNumber}>{paymentMethod.cardNumber}</Typography>
          {editMode && (
            <Box mb={2}>
              <InputBase
                className={clsx(cardFormStyles.input, styles.cardExpDateInput)}
                value={expiry}
                size="small"
                margin="dense"
                onChange={handleExpiryChange}
                inputProps={{
                  className: styles.input,
                }}
              />
            </Box>
          )}
          {!editMode && (
            <Typography className={styles.cardExpDate}>
              <FormattedMessage id="billing_payments.expires" /> {paymentMethod.cardExpDate}
            </Typography>
          )}
          {editMode && paymentMethod.cardOwner && (
            <Box mb={2}>
              <InputBase
                className={clsx(cardFormStyles.input)}
                value={cardholder}
                size="small"
                margin="dense"
                onChange={handleCardholderChange}
                inputProps={{
                  className: styles.input,
                }}
              />
            </Box>
          )}
          {!editMode && <Typography className={styles.cardOwner}>{paymentMethod.cardOwner}</Typography>}
          <Grid container justifyContent="space-between">
            <Button onClick={handleRemoveClick} color="error">
              <FormattedMessage id="btn_text_remove" />
            </Button>
            {!editMode && (
              <Button onClick={enableEditMode}>
                <FormattedMessage id="btn_text_edit" />
              </Button>
            )}
            {editMode && (
              <Stack spacing={1} direction="row">
                <SaveButton
                  variant="contained"
                  disabled={!isValid || !saveEnabled || updatingPaymentMethod}
                  onClick={handleSave}
                  className={clsx({ success: !updatingPaymentMethod && updatedPaymentMethod })}
                >
                  {!updatingPaymentMethod && !updatedPaymentMethod && (
                    <Typography>
                      <FormattedMessage id="btn_text_save" />
                    </Typography>
                  )}
                  {updatingPaymentMethod && <CircularProgress size={24} />}
                  {!updatingPaymentMethod && updatedPaymentMethod && (
                    <Stack spacing={2} direction="row">
                      <CheckIcon sx={{ fill: "#fff" }} />
                    </Stack>
                  )}
                </SaveButton>
                <Button onClick={disableEditMode} disabled={updatingPaymentMethod}>
                  <FormattedMessage id="btn_text_cancel" />
                </Button>
              </Stack>
            )}
          </Grid>
        </Paper>
      </Grid>
      {editMode && (
        <Grid width="50%">
          <AddressCreationForm isBilling {...addressCreationProps} />
        </Grid>
      )}
      {!editMode && (
        <BillinAddressInfo paymentMethod={paymentMethod} chosenCardPaymentMethodId={chosenCardPaymentMethodId} />
      )}
    </Grid>
  );
};
