import { useCallback } from "react";
import { UseCreditCardExpiryFormatterReturnType } from "../interfaces/use-credit-card-expiry-formatter.interface";

export const CREDIT_CARD_EXPIRY_INPUT_MAX_LEN = 4;

export function useCreditCardExpiryFormatter(
  maxLength = CREDIT_CARD_EXPIRY_INPUT_MAX_LEN,
): UseCreditCardExpiryFormatterReturnType {
  return {
    format: useCallback((input: string) => {
      const numericValue = input.replaceAll(/\D/g, "");
      const maskedValue = numericValue
        .split(/(.{2})/)
        .filter((group) => Boolean(group))
        .join(" / ")
        .trim();

      if (maskedValue.length === 2 && !input.includes("/")) {
        return `${maskedValue} / `;
      }

      return maskedValue;
    }, []),
    shouldFormat: useCallback((value: string) => value.replaceAll(/(\s|\/)/g, "").length <= maxLength, [maxLength]),
    deformat: useCallback((value: string) => value.replace(" / ", ""), []),
  };
}
