import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    footerWrap: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",

      [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
        flexDirection: "column",
      },

      "& .leftWrap": {
        [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
          alignSelf: "start",
        },
      },
    },
  };
});

export default useStyles;
