import { FC, ReactNode, useCallback } from "react";

const TAB = "tab";

interface ClickBlockerProps {
  children?: ReactNode;
}

export const ClickBlocker: FC<ClickBlockerProps> = ({ children }) => {
  const blockClicks = useCallback((event) => {
    if (event.target.role !== TAB) {
      event.stopPropagation();
      event.preventDefault();
    }
  }, []);

  return <div onClickCapture={blockClicks}>{children}</div>;
};
