import { FC } from "react";
import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { FormikProps } from "formik";
import { FormattedMessage } from "react-intl";
import useStyles from "./styles";

import Icon from "../../../../../../../utils/icon";

import { TykchaseCampaignTriggers, PurchaseRewardRate } from "../../../../../../../generated/types";
import { StepsFormikValues } from "../../../../../../../types/campaigns";
import { TykchaseCampaign } from "../../../../../../../gql/graphql";

type Props = {
  formik: FormikProps<StepsFormikValues>;
};
const SummaryStep: FC<Props> = ({ formik }) => {
  const theme = useTheme();
  const { wrap } = useStyles();
  const { ReceiptTotal, Day, ProductOrService, Surprise } = TykchaseCampaignTriggers;
  const { Fixed, ByPercentage } = PurchaseRewardRate;

  // trigger types
  const isReceiptTotalTrigger =
    formik.values.triggerType === ReceiptTotal || formik.values.boostedTykchaseCampaign?.triggerType === ReceiptTotal;
  const isDayTrigger = formik.values.triggerType === Day || formik.values.boostedTykchaseCampaign?.triggerType === Day;
  const isSurpriseTrigger =
    formik.values.triggerType === Surprise || formik.values.boostedTykchaseCampaign?.triggerType === Surprise;
  const isProductOrServiceTrigger =
    formik.values.triggerType === ProductOrService ||
    formik.values.boostedTykchaseCampaign?.triggerType === ProductOrService;

  return (
    <Grid className={wrap}>
      <Typography mb="20px" fontWeight="bold" fontSize="0.875rem" color={theme.palette.label?.grey}>
        <FormattedMessage id="rewards.campaigns.summary.title" />
      </Typography>
      <Grid
        container
        flexWrap="nowrap"
        justifyContent="space-between"
        width={{ xs: "100%", md: "60%" }}
        p="20px 33px"
        mb="15px"
        borderRadius="6px"
        bgcolor={theme.palette.campaigns.lightGrey}
      >
        <Grid mr="10px">
          <Typography
            width="max-content"
            pb="7px"
            mb="13px"
            borderBottom={`1px solid ${theme.palette.label?.grey}`}
            fontSize="0.75rem"
            color={theme.palette.label?.grey}
          >
            <FormattedMessage id="rewards.campaigns.summary.information" />
          </Typography>
          <Grid container flexWrap="nowrap" mb="11px">
            <Typography mr="5px" color={theme.palette.purple} fontSize="0.75rem">
              <FormattedMessage id="rewards.campaigns.summary.holiday" />
            </Typography>
            <Typography color={theme.palette.primary.dark} fontWeight="bold" fontSize="0.75rem">
              {formik.values.title}
            </Typography>
          </Grid>
          <Grid container flexWrap="nowrap" mb="11px">
            <Typography mr="5px" color={theme.palette.purple} fontSize="0.75rem">
              <FormattedMessage id="rewards.campaigns.summary.text" />
            </Typography>
            <Typography color={theme.palette.primary.dark} fontWeight="bold" fontSize="0.75rem">
              {formik.values.description}
            </Typography>
          </Grid>
          <Grid container>
            <Grid container flexWrap="nowrap" width="max-content" mr="60px">
              <Typography mr="5px" color={theme.palette.purple} fontSize="0.75rem">
                <FormattedMessage id="rewards.campaigns.summary.start_date" />
              </Typography>
              <Typography color={theme.palette.primary.dark} fontWeight="bold" fontSize="0.75rem">
                {formik.values.startDate.slice(0, 10)}
              </Typography>
            </Grid>
            <Grid container flexWrap="nowrap" width="max-content">
              <Typography mr="5px" color={theme.palette.purple} fontSize="0.75rem">
                <FormattedMessage id="rewards.campaigns.summary.end_date" />
              </Typography>
              <Typography color={theme.palette.primary.dark} fontWeight="bold" fontSize="0.75rem">
                {formik.values.endDate.slice(0, 10)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          <Grid
            height="77px"
            width="72px"
            mb="7px"
            borderRadius="6px"
            bgcolor={!formik.values.customCoverBase64 ? theme.palette.analytics?.lightBlue : ""}
          >
            {formik.values.customCoverBase64 && (
              <img
                height="77px"
                width="72px"
                style={{ borderRadius: "6px", marginBottom: "6px" }}
                src={formik.values.customCoverBase64 || ""}
                alt="preview"
              />
            )}
          </Grid>
          <Typography color={theme.palette.purple} fontSize="0.75rem">
            <FormattedMessage id="rewards.campaigns.summary.image_preview" />
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        flexWrap="nowrap"
        justifyContent="space-between"
        width={{ xs: "100%", md: "60%" }}
        mb="15px"
        p="20px 33px"
        borderRadius="6px"
        bgcolor={theme.palette.campaigns.lightGrey}
      >
        <Grid>
          <Typography
            width="max-content"
            pb="7px"
            mb="13px"
            borderBottom={`1px solid ${theme.palette.label?.grey}`}
            fontSize="0.75rem"
            color={theme.palette.label?.grey}
          >
            <FormattedMessage id="rewards.campaigns.summary.campaign_trigger" />
          </Typography>

          <Grid container flexWrap="nowrap" mb="11px">
            <Typography mr="5px" color={theme.palette.purple} fontSize="0.75rem">
              {isReceiptTotalTrigger && <FormattedMessage id="rewards.campaigns.summary.receipt_total" />}
              {isDayTrigger && <FormattedMessage id="rewards.campaigns.summary.day" />}
              {isSurpriseTrigger && <FormattedMessage id="rewards.campaigns.summary.surprise_number" />}
              {isProductOrServiceTrigger && <FormattedMessage id="rewards.campaigns.summary.product" />}
            </Typography>
            <Typography color={theme.palette.primary.dark} fontWeight="bold" fontSize="0.75rem">
              {isReceiptTotalTrigger &&
                (formik.values.receiptTotalPriceTrigger ||
                  formik.values.boostedTykchaseCampaign?.receiptTotalPriceTrigger)}
              {isDayTrigger && (formik.values.dayTrigger || formik.values.boostedTykchaseCampaign?.dayTrigger)}
              {isSurpriseTrigger &&
                (formik.values.surpriseNumber || formik.values.boostedTykchaseCampaign?.surpriseNumber)}
              {isProductOrServiceTrigger &&
                (formik.values.name ||
                  formik.values.boostedTykchaseCampaign?.productTrigger?.map((product) => product.name))}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        flexWrap="nowrap"
        justifyContent="space-between"
        width={{ xs: "100%", md: "60%" }}
        p="20px 33px"
        borderRadius="6px"
        bgcolor={theme.palette.campaigns.lightGrey}
      >
        <Typography
          width="max-content"
          pb="7px"
          mb="13px"
          borderBottom={`1px solid ${theme.palette.label?.grey}`}
          fontSize="0.75rem"
          color={theme.palette.label?.grey}
        >
          <FormattedMessage id="rewards.campaigns.summary.information" />
        </Typography>
        <Grid container flexWrap="nowrap">
          <Grid
            container
            alignItems={{ xs: "center", sm: "end" }}
            justifyContent={{ xs: "end", sm: "start" }}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <Icon.Usd />
            <Typography ml="5px" color={theme.palette.primary.dark} fontWeight="bold" fontSize="0.75rem">
              {formik.values.purchaseRewardRate === Fixed && `$${formik.values.dollarReward}`}
              {formik.values.purchaseRewardRate === ByPercentage && `${formik.values.dollarReward}%`}
              {formik.values.boostedTykchaseCampaign?.ruleRewardConfiguration?.purchaseRewardRate === Fixed &&
                `$${formik.values.boostedTykchaseCampaign?.ruleRewardConfiguration?.dollarReward}`}
              {formik.values.boostedTykchaseCampaign?.ruleRewardConfiguration?.purchaseRewardRate === ByPercentage &&
                `${formik.values.boostedTykchaseCampaign?.ruleRewardConfiguration?.dollarReward}%`}
            </Typography>
          </Grid>
          <Grid
            container
            alignItems={{ xs: "center", sm: "end" }}
            justifyContent={{ xs: "end", sm: "start" }}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <Icon.CentorsFull style={{ maxWidth: "30px" }} />
            <Typography ml="5px" color={theme.palette.primary.dark} fontWeight="bold" fontSize="0.75rem">
              15%
            </Typography>
          </Grid>
          <Grid
            container
            alignItems={{ xs: "center", sm: "end" }}
            justifyContent={{ xs: "end", sm: "start" }}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <Icon.LoneStars style={{ maxWidth: "30px" }} />
            <Typography ml="5px" color={theme.palette.primary.dark} fontWeight="bold" fontSize="0.75rem">
              70%
            </Typography>
          </Grid>
          <Grid
            container
            alignItems={{ xs: "center", sm: "end" }}
            justifyContent={{ xs: "end", sm: "start" }}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <Icon.Jackpot style={{ maxWidth: "30px" }} />
            <Typography ml="5px" color={theme.palette.primary.dark} fontWeight="bold" fontSize="0.75rem">
              5%
            </Typography>
          </Grid>
          <Grid
            container
            alignItems={{ xs: "center", sm: "end" }}
            justifyContent={{ xs: "end", sm: "start" }}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <Icon.Cashier style={{ maxWidth: "30px" }} />
            <Typography ml="5px" color={theme.palette.primary.dark} fontWeight="bold" fontSize="0.75rem">
              10%
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SummaryStep;
