import { makeStyles } from "@mui/styles";

const useStyles = (isRules = false) =>
  makeStyles((theme) => {
    return {
      tableContainer: {
        marginBottom: "8px",
        "& .tableBodyRow": {
          "&:nth-of-type(even)": {
            backgroundColor: "#F6F6F6",
          },
          "& td": {
            padding: "10px",
            fontFamily: theme.typography.fontFamily,
            fontSize: "0.75rem",
            fontWeight: 600,
            color: "#34305D",
            border: "none",

            "& .campaignItemImageWrap": {
              width: "30px",
              minWidth: "30px",
              height: "30px",
              backgroundColor: "#8986AD",
              borderRadius: "50%",
              marginRight: "16px",

              "& .campaignImage": {
                width: "100%",
                height: "100%",
                borderRadius: "50%",
              },
            },
          },
          "& td:first-child": {
            maxWidth: isRules ? "170px" : "50px",
          },
          "& td:nth-child(2)": {
            maxWidth: "60px",
          },
        },
        "& .tableHeadRow": {
          backgroundColor: "#F6F6F6",
          "& th": {
            padding: "10px",
            fontFamily: theme.typography.fontFamily,
            fontSize: "0.75rem",
            color: "#797882",
            borderTop: "1px solid #CDCCD7",
            borderBottom: "none",
          },

          "& th:first-child": {
            maxWidth: isRules ? "170px" : "50px",
          },
          "& th:nth-child(2)": {
            maxWidth: "60px",
          },
        },
      },

      emptyStateWrap: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignSelf: "center",
        alignItems: "center",
        minWidth: "254px",
        padding: "24px 8px",
        margin: "16px 0",
        backgroundColor: "#E9E9E9",
        borderRadius: "6px",
      },
    };
  });

export default useStyles;
