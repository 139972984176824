import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => {
  return {
    title: {
      fontSize: "1.2rem",
      color: "#4B4B4B",
      fontWeight: 600,
    },
    titleText: { fontSize: "1rem", fontWeight: "600" },

    infoContLetterStyle: {
      "& p": {
        fontSize: "0.875rem",
      },
      "& p:nth-child(1)": {
        color: "#6E6E6E",
      },
      "& p:nth-child(2)": {
        fontWeight: 600,
        color: "#4B4B4B",
      },
    },
  };
});

export default useStyles;
