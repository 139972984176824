import { makeStyles } from "@mui/styles";
import theme from "../../../theme";

const membershipTableStyle = {
  none: {},
  root: {
    width: "100%",
    margin: "1rem",
    "& .MuiTableCell-root": {
      border: "none",
      padding: "0.5rem",
      fontFamily: "AdobeClean",
    },
  },
  container: {
    maxHeight: 800,
  },
  tableHeadings: {
    backgroundColor: "#1B959A",
    width: "20%",
    height: "5rem",
    color: "#FFF",
    fontSize: "1rem",
    fontWeight: 600,
    "&:first-child": {
      fontWeight: "bold",
      width:'40%',
      backgroundColor: "#DFE1E1",
      color: "#191919",
    },
  },
  tableFooter: {
    backgroundColor: "#DFE1E1",
    color: "#b04f57",
    fontSize: "0.8rem",
  },
  footerComingSoon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  groupTitle: {
    fontSize: "1rem",
    fontWeight: "bold",
    color: "#000",
    margin: "3rem 0",
    backgroundColor: "#DFE1E1",
    "& .MuiTypography-root": {
      fontWeight: "bold",
      fontFamily: "AdobeClean",
    },
  },
  perkTitle: {
    fontSize: "0.875rem",
    color: "#191919",
    "& .MuiTypography-root": {
      fontFamily: "AdobeClean",
    },
  },
  priceTitle: {
    fontWeight: "bold",
    fontFamily: "AdobeClean",
    fontSize: "1.5rem",
    color: "#1473E6",
  },
  groupItems: {
    fontWeight: "bold",
    color: "#0038CB",
    fontSize: "0.875rem",
    "& MuiSvgIcon-root": {
      fontSize: "3rem",
    },
  },
  groupPeriodTitle: {
    fontSize: "1.125rem",
    fontWeight: "bold",
    color: "#000",
    margin: "3rem 0",
  },
  groupPeriodItems: {
    fontWeight: "bold",
    color: "#000",
  },
  goldLetters: {
    color: "#FFBE40",
  },
  enterprise: {
    backgroundColor: theme.palette?.membership?.enterprise,
    opacity: "60%",
  },
  brands: {
    backgroundColor: theme.palette?.membership?.brands,
    opacity:'60%',
  },

  blueItems: {
    color: "#1473E6",
  },
  buttonActive: {
    borderRadius: "20px",
    backgroundColor: "#1473E6",
    fontSize: "0.8rem",
    padding: "0 1rem",
    height: "2rem",
    width: "80%",
    "& .MuiButton-label": {
      textTransform: "none",
      color: "#fff",
    },
  },
};

const useStyles = makeStyles(() => {
  return { ...membershipTableStyle };
});

export { useStyles, membershipTableStyle };
