import { FC } from "react";
import { Box, Button, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import dummyImage from "../../../../../assets/images/NoPath.png";
import FileExportIcon from "../../../../../assets/icons/menuIcons/file-export.svg";
import theme from "../../../../../theme";
import useStyles from "../styles";

interface ModalHeaderProps {
  title: string;
  type?: string;
  ends?: string;
}

const ModalHeader: FC<ModalHeaderProps> = ({ title, type, ends }) => {
  const { modalHeader } = useStyles();

  return (
    <Box className={modalHeader}>
      <Box className="modalHeaderWrap">
        <Box className="previewWrap">
          <img src={dummyImage} alt="" />
        </Box>

        <Box className="titleWrap">
          <Typography
            variant="h3"
            color={theme.palette.primary.dark}
            fontFamily={theme.typography.fontFamily}
            fontSize="1.125rem"
            fontWeight="bold"
          >
            {title}
          </Typography>

          <Box className="subtitleWrap">
            <Typography color="#AAA9A9" fontFamily={theme.typography.fontFamily} fontSize="0.75rem" marginRight="6px">
              {type || ""}
            </Typography>

            <Typography color="#D3202F" fontFamily={theme.typography.fontFamily} fontSize="0.75rem">
              {ends || ""}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box>
        <Button className="exportButton" startIcon={<img src={FileExportIcon} alt="" />}>
          <FormattedMessage id="btn_text_export" />
        </Button>
      </Box>
    </Box>
  );
};

export default ModalHeader;
