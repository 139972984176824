import { FC, useMemo, useState, useCallback, useEffect, useContext } from "react";
import { Grid, Button, Typography, Checkbox, FormControlLabel, Stack, Box, Tooltip, ListItem } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { useMutation, useQuery } from "@apollo/client";
import Select from "react-dropdown-select";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import clsx from "clsx";
import { MembershipRulesTable } from "../MembershipRulesTable";
import useStyles from "./styles";
import { RewardsFilterEnum as RewardsFilterEnumEnglish, RewardsFilterEnumSpanish } from "../types";
import { dummyRules } from "../data";
import { IMembershipRule } from "../../../types/membershipTypes";
import MonthlyRewardLimit from "../MonthlyRewardLimit";
import ControlUserEnroll from "../ControlUserEnroll";
import RewardDistribution from "../RewardDistribution";
import { GET_ALL_RULES } from "../../../apollo/queries";
import { UPDATE_RULE_CONFIGURATION_MUTATION, DISABLE_RULE_CONFIGURATION_MUTATION } from "../../../apollo/mutations";
import RuleConfigurationInterface from "./RuleConfigurationInterface";
import { ITykAwardsDistribution } from "../../../types/tykchaseGlobalConfig";
import { Rewards } from "../Modals/Diagram/types";
import { CurrentBalance } from "../CurrentBalance";
import { DepositSettings } from "../DepositSettings";
import { useFeatureFlagValue } from "../../../hooks";
import { FeatureFlagEnum } from "../../../types/contexts";
import { useBusinessProfile } from "../../../hooks/useBusinessProfile.hook";
import { PageLoader } from "../../PageLoader";
import DisableFeatureModal from "../Modals/DisableFeatureModal";
import { useBooleanState } from "../../../hooks/useBooleanState.hook";
import { getRewardRulesConfigurationQuery } from "./graphql/getRewardsConfiguration.query";
import { LanguageContext } from "../../../contexts";

interface CategoriesFilterItem {
  id: string;
  label: string;
}

const TykchaseRuleStatuses = {
  ACTIVE: "Active",
  PUBLISHED: "Published",
  UNPUBLISHED: "Unpublished",
};

interface MembershipRulesFiltration {
  selectedCategoryFilters: CategoriesFilterItem[];
  isFilterByInactive: boolean;
  isFilterByPublished: boolean;
}

const RewardRules: FC = () => {
  const { language } = useContext(LanguageContext);
  const isEnglish = language === 'en';
  const RewardsFilterEnum = language === 'en' ? RewardsFilterEnumEnglish : RewardsFilterEnumSpanish;
  const [membershipRulesFiltration, setMembershipRulesFiltration] = useState<MembershipRulesFiltration>({
    selectedCategoryFilters: [
      {
        id: isEnglish ? "All Rules" : RewardsFilterEnumSpanish.ALL_RULES,
        label: isEnglish ? "All Rules" : RewardsFilterEnumSpanish.ALL_RULES,
      },
    ],
    isFilterByInactive: false,
    isFilterByPublished: false,
  });
  const { formatMessage } = useIntl();
  const [rewardSplit, setRewardSplit] = useState<Rewards>({});
  const [membershipRules, setMembershipRules] = useState<IMembershipRule[]>([]);
  const isEnrollControlEnabled = useFeatureFlagValue(FeatureFlagEnum.ENROLL_CONTROL_ENABLED);
  const isCategoriesFilterForRewardsEnabled = useFeatureFlagValue(
    FeatureFlagEnum.CATEGORIES_FILTER_FOR_REWARDS_ENABLED,
  );
  const {
    value: isDeactivateSelectedModalOpen,
    setTrue: openDeactivateSelectedModal,
    setFalse: closeDeactivateSelectedModal,
  } = useBooleanState(false);
  const {
    value: isDeactivateAllModalOpen,
    setTrue: openDeactivateAllModal,
    setFalse: closeDeactivateAllModal,
  } = useBooleanState(false);
  const [rewardRulesConfigurationState, setRewardRulesConfigurationState] = useState<any>(null);
  const [isAllRulesSelected, setIsAllRulesSelected] = useState<boolean>(false);
  const [isDeactivateSelectedActive, setIsDeactivateSelectedActive] = useState<boolean>(false);
  const [isDeactivateAllActive, setIsDeactivateAllActive] = useState<boolean>(false);
  const [disabledChildrenRules, setDisabledChildrenRules] = useState<string[] | null>([]);
  const { dropDown, stateButtons, deactivateSelected, deactivateAll, rewardsInfoContainer } = useStyles();

  const [user, , isSubscribed] = useBusinessProfile();

  const getAllRulesQuery = useQuery(GET_ALL_RULES);

  const {
    data: rewardConfigurationData,
    refetch: getRewardsConfiguration,
    loading: isGetRewardsConfigurationLoading,
  } = useQuery(getRewardRulesConfigurationQuery, {
    variables: {
      userId: user?.id ?? "",
    },
    skip: !user?.id,
    fetchPolicy: "no-cache",
  });
  const [updateRuleConfiguration] = useMutation(UPDATE_RULE_CONFIGURATION_MUTATION);
  const [disableRuleConfiguration] = useMutation(DISABLE_RULE_CONFIGURATION_MUTATION);

  const isLoadingRewardsPage = getAllRulesQuery.loading || isGetRewardsConfigurationLoading;
  const getMatchingTitle = (membershipRule) => {
    const generalRewards = getAllRulesQuery.data.getAllCategoriesWithRules[0].tykchaseRule;
    const matchingRule = generalRewards.find((rule) => rule.internalId === membershipRule.internalId);

    return matchingRule?.title || membershipRule.title;
  };
  useEffect(() => {
    if (
      rewardConfigurationData?.getRewardRulesConfiguration?.payload &&
      Array.isArray(getAllRulesQuery?.data?.getAllCategoriesWithRules)
    ) {
      const stateData = { ...rewardConfigurationData?.getRewardRulesConfiguration?.payload };
      setRewardRulesConfigurationState(stateData);
      const activeRules = rewardConfigurationData?.getRewardRulesConfiguration?.payload?.activeRules;
      const allRules = rewardConfigurationData.getRewardRulesConfiguration.payload.membershipRules;
      const childrenRules =
        rewardConfigurationData?.getRewardRulesConfiguration?.payload?.membershipRules?.filter(
          (rule) => rule?.parentRule,
        ) ?? [];

      if (activeRules?.length) {
        const newMembershipRules = dummyRules
          .map((membershipRule) => {
            const activeRule = activeRules.find((element) => element?.rule?.internalId === membershipRule.internalId);
            const ruleDefinition = allRules?.find((element) => element?.internalId === membershipRule.internalId);
            const newMembershipRule = { ...membershipRule };
            if (!user?.isStagewoodProfile && ruleDefinition?.isStagewoodPerk) {
              return null;
            }
            if (activeRule) {
              newMembershipRule.backendData = { ...activeRule };
              newMembershipRule.reward = parseFloat(activeRule.dollarReward);
              newMembershipRule.title = getMatchingTitle(newMembershipRule);

              switch (activeRule?.status?.title) {
                case TykchaseRuleStatuses.PUBLISHED:
                  newMembershipRule.isActive = true;
                  break;
                case TykchaseRuleStatuses.UNPUBLISHED:
                  newMembershipRule.isActive = false;
                  break;

                default:
                  break;
              }
            } else {
              newMembershipRule.backendData = null;
              newMembershipRule.title = getMatchingTitle(newMembershipRule);
            }
            return newMembershipRule;
          })
          .filter(Boolean) as IMembershipRule[];

        const disabledChildrenRuleNames = childrenRules
          .map((childRule) => {
            const matchingRule = activeRules.find(
              (activeRule) =>
                childRule?.parentRule?.id === activeRule?.rule?.id &&
                activeRule?.status?.title === TykchaseRuleStatuses.PUBLISHED,
            );

            if (!matchingRule) {
              return childRule?.ruleName;
            }

            return null;
          })
          .filter(Boolean) as string[];
        setDisabledChildrenRules(disabledChildrenRuleNames);
        setMembershipRules(newMembershipRules);
      } else {
        const dummyRulesWithMatchingTitles = dummyRules
          .map((dummyRule) => ({
            ...dummyRule,
            title: getMatchingTitle(dummyRule),
          }))
          .filter((item) => {
            const membershipRule = allRules?.find((ruleItem) => {
              return item.internalId === ruleItem?.internalId;
            });
            return user?.isStagewoodProfile || !membershipRule?.isStagewoodPerk;
          });
        const disabledChildrenRuleNames =
          (childrenRules?.map((childRule) => childRule?.ruleName).filter(Boolean) as string[]) ?? [];

        setDisabledChildrenRules(disabledChildrenRuleNames);
        setMembershipRules(dummyRulesWithMatchingTitles);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rewardConfigurationData, getAllRulesQuery?.data?.getAllCategoriesWithRules]);

  const filterByInactive = (membershipRule) => {
    if (membershipRulesFiltration.selectedCategoryFilters[0].id === RewardsFilterEnum.ALL_RULES)
      return !membershipRule.isActive;
    return (
  isEnglish ? !membershipRule.isActive && membershipRulesFiltration.selectedCategoryFilters[0].id === membershipRule.category
      : !membershipRule.isActive && membershipRulesFiltration.selectedCategoryFilters[0].id === membershipRule.categorySpanish
    );
  };

  const filterByPublished = (membershipRule) => {
    if (membershipRulesFiltration.selectedCategoryFilters[0].id === RewardsFilterEnum.ALL_RULES)
      return membershipRule.isActive;
    return isEnglish
      ? membershipRule.isActive && membershipRulesFiltration.selectedCategoryFilters[0].id === membershipRule.category
      : membershipRule.isActive && membershipRulesFiltration.selectedCategoryFilters[0].id === membershipRule.categorySpanish;
  };

  const isFiltrationNeeded =
    membershipRulesFiltration.isFilterByPublished ||
    membershipRulesFiltration.selectedCategoryFilters[0].id !== RewardsFilterEnum.ALL_RULES ||
    membershipRulesFiltration.isFilterByInactive;

  const filtered = useMemo(() => {
    if (isFiltrationNeeded) {
      return membershipRules.filter((membershipRule) => {
        if (membershipRulesFiltration.isFilterByPublished || membershipRulesFiltration.isFilterByInactive) {
          return (
            (membershipRulesFiltration.isFilterByPublished && filterByPublished(membershipRule)) ||
            (membershipRulesFiltration.isFilterByInactive && filterByInactive(membershipRule))
          );
        }
        return language === 'en' ? membershipRulesFiltration.selectedCategoryFilters[0].id === membershipRule.category : membershipRulesFiltration.selectedCategoryFilters[0].id === membershipRule.categorySpanish;
      });
    }

    return membershipRules;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isFiltrationNeeded,
    membershipRules,
    membershipRulesFiltration.isFilterByInactive,
    membershipRulesFiltration.isFilterByPublished,
    membershipRulesFiltration.selectedCategoryFilters,
  ]);
  useEffect(() => {
    setIsDeactivateAllActive(filtered.some((item) => item.isActive));
  }, [filtered]);

  const handleDeactivateAllClick = useCallback(() => {
    if (isDeactivateAllActive) {
      openDeactivateAllModal();
    }
  }, [isDeactivateAllActive, openDeactivateAllModal]);
  const handleDeactivateSelectedClick = useCallback(() => {
    if (isDeactivateSelectedActive) {
      openDeactivateSelectedModal();
    }
  }, [isDeactivateSelectedActive, openDeactivateSelectedModal]);

  useMemo(() => {
    setIsDeactivateSelectedActive(filtered.some((item) => item.isSelected && item.isActive));
    setIsDeactivateAllActive(filtered.some((item) => item.isActive));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membershipRulesFiltration]);

  const selectedRules = useMemo(
    () => membershipRules.filter((rule) => rule.isSelected && rule.isActive),
    [membershipRules],
  );

  const handleDeactivateAll = useCallback(() => {
    closeDeactivateAllModal();

    const ruleIds = membershipRules.map((rule) => {
      return rule.internalId;
    });

    disableRuleConfiguration({
      variables: {
        input: {
          userId: user?.id,
          tykchaseRuleIds: ruleIds,
          status: TykchaseRuleStatuses.UNPUBLISHED,
        },
      },
    }).then(() => {
      setMembershipRules((prevState) => {
        if (filtered.some((item) => item.isActive)) {
          const newState = prevState.map((membershipRule) => {
            return {
              ...membershipRule,
              isActive: false,
            };
          });
          setIsDeactivateSelectedActive(false);
          setIsDeactivateAllActive(false);
          return newState;
        }
        return prevState;
      });

      getRewardsConfiguration();
    });
  }, [disableRuleConfiguration, filtered, getRewardsConfiguration, membershipRules, user?.id, closeDeactivateAllModal]);

  const handleDeactivateSelected = useCallback(() => {
    closeDeactivateSelectedModal();

    const childRuleIds = selectedRules
      .map((rule) => {
        const childRuleName = rule.backendData?.rule.childRule?.ruleName;
        const childRule = membershipRules.find((membershipRule) => membershipRule.ruleName === childRuleName);

        if (childRule && childRule.isActive) {
          return childRule.internalId;
        }

        return null;
      })
      .filter((id) => id);

    const ruleIds = selectedRules.map((rule) => {
      return rule.internalId;
    });

    if (ruleIds.length > 0) {
      disableRuleConfiguration({
        variables: {
          input: {
            userId: user?.id,
            tykchaseRuleIds: [...ruleIds, ...childRuleIds],
            status: TykchaseRuleStatuses.UNPUBLISHED,
          },
        },
      }).then(() => {
        setMembershipRules((prevState) => {
          if (filtered.some((item) => item.isActive)) {
            const newState = prevState.map((membershipRule) => {
              if (membershipRule.isSelected) {
                return {
                  ...membershipRule,
                  isActive: false,
                  isSelected: false,
                };
              }
              return membershipRule;
            });
            setIsAllRulesSelected(false);
            setIsDeactivateSelectedActive(false);
            setIsDeactivateAllActive(newState.some((rule) => rule.isActive));
            return newState;
          }
          return prevState;
        });

        getRewardsConfiguration();
      });
    }
  }, [
    disableRuleConfiguration,
    filtered,
    getRewardsConfiguration,
    membershipRules,
    user?.id,
    closeDeactivateSelectedModal,
    selectedRules,
  ]);

  const updateRuleConfigurationRequest = async (ruleConfiguration: RuleConfigurationInterface, status?: string) => {
    const ruleConfig = ruleConfiguration;
    const selectedRule = membershipRules.findIndex((rule) => rule.id === ruleConfig.ruleId);

    delete ruleConfig.ruleId;

    const configStatus = status || membershipRules[selectedRule].backendData?.status?.title;

    await updateRuleConfiguration({
      variables: {
        input: {
          userId: user?.id,
          tykchaseRuleId: membershipRules[selectedRule].internalId,
          ...ruleConfig,
          dollarReward: parseFloat(ruleConfig.dollarReward),
          centorReward: parseFloat(rewardSplit.centorsRewards || ""),
          loneStarReward: parseFloat(rewardSplit.loneStarsRewards || ""),
          status: configStatus,
        },
      },
    }).then(async () => {
      if (configStatus === TykchaseRuleStatuses.PUBLISHED) {
        setMembershipRules((prevState) => {
          const newState = [...prevState];
          newState[selectedRule] = {
            ...newState[selectedRule],
            reward: ruleConfig.dollarReward,
            isActive: true,
          };
          setIsDeactivateSelectedActive(newState[selectedRule].isSelected && newState[selectedRule].isActive);
          setIsDeactivateAllActive(true);
          return newState;
        });
      } else {
        setMembershipRules((prevState) => {
          const newState = [...prevState];
          newState[selectedRule] = {
            ...newState[selectedRule],
            reward: ruleConfig.dollarReward,
            isActive: false,
          };
          return newState;
        });
      }

      await getRewardsConfiguration();
    });
  };

  const handlePublishModal = async (ruleConfiguration: RuleConfigurationInterface) => {
    await updateRuleConfigurationRequest(ruleConfiguration, TykchaseRuleStatuses.PUBLISHED);
  };

  const handleSaveModal = async (ruleConfiguration: RuleConfigurationInterface) => {
    await updateRuleConfigurationRequest(ruleConfiguration, TykchaseRuleStatuses.UNPUBLISHED);
  };

  const handleSelectAllRulesToggle = () => {
    setIsAllRulesSelected(!isAllRulesSelected);

    setMembershipRules((prevState) => {
      const newState = prevState.map((membershipRule) => {
        const isChildDisabledRule =
          disabledChildrenRules && disabledChildrenRules.find((ruleName) => ruleName === membershipRule.ruleName);
        return isChildDisabledRule ? membershipRule : { ...membershipRule, isSelected: !isAllRulesSelected };
      });
      setIsDeactivateSelectedActive(filtered.some((item) => item.isActive) && !isAllRulesSelected);
      return newState;
    });
  };

  const handleSelectRuleToggle = (ruleId) => {
    setMembershipRules((prevState) => {
      const newState = [...prevState];
      const selectRuleIndex = newState.findIndex((rule) => rule.id === ruleId);

      newState[selectRuleIndex] = {
        ...newState[selectRuleIndex],
        isSelected: !newState[selectRuleIndex].isSelected,
      };

      setIsDeactivateSelectedActive(newState.some((item) => item.isSelected && item.isActive));

      setIsAllRulesSelected(newState.every((rule) => rule.isSelected));
      return newState;
    });
  };

  const handleActiveRuleToggle = async (ruleId: string) => {
    const selectedRule = membershipRules.find((rule) => rule.id === ruleId);

    const childRuleName = selectedRule?.backendData?.rule.childRule?.ruleName;
    const childRule = membershipRules.find((rule) => rule.ruleName === childRuleName);

    if (selectedRule && selectedRule.isActive) {
      const tykchaseRuleIds =
        childRule && childRule?.isActive ? [selectedRule.internalId, childRule.internalId] : [selectedRule.internalId];

      await disableRuleConfiguration({
        variables: {
          input: {
            userId: user?.id,
            tykchaseRuleIds,
            status: TykchaseRuleStatuses.UNPUBLISHED,
          },
        },
      }).then(async () => {
        setMembershipRules((prevState) => {
          const newState = [...prevState];

          newState[selectedRule.id] = {
            ...newState[selectedRule.id],
            isActive: false,
          };

          setIsDeactivateSelectedActive(newState.every((rule) => rule.isActive));
          setIsDeactivateAllActive(newState.every((rule) => rule.isActive));
          return newState;
        });

        await getRewardsConfiguration();
      });
    }
  };

  const categoriesFilters: CategoriesFilterItem[] = Object.values(RewardsFilterEnum).map((rewardFilter) => ({
    id: rewardFilter,
    label: rewardFilter,
  }));

  const handleCategoriesChange = (newFilter: CategoriesFilterItem[]) => {
    setMembershipRulesFiltration({
      ...membershipRulesFiltration,
      selectedCategoryFilters: newFilter,
    });
  };

  const createHandleStateFilterToggle = (checkboxField: string) => () => {
    setMembershipRulesFiltration({
      ...membershipRulesFiltration,
      [checkboxField]: !membershipRulesFiltration[checkboxField],
    });
  };

  const handleOnRewardDistributionSave = (customRewards: ITykAwardsDistribution) => {
    if (customRewards) {
      setRewardRulesConfigurationState((prevState) => {
        return {
          ...prevState,
          customTykAwardsDistribution: {
            ...prevState.customTykAwardsDistribution,
            ...customRewards,
          },
        };
      });
    }
  };
  if (isLoadingRewardsPage) {
    return <PageLoader />;
  }
  return (
    <>
      <Grid container item direction="row" width="auto" className={rewardsInfoContainer}>
        <Grid container item direction="row" justifyContent="space-between">
          <Grid item>
            <Stack direction={{ xs: "column", md: "row" }} alignItems={{ xs: "flex-end", md: "unset" }} spacing={5}>
              {rewardRulesConfigurationState && !isLoadingRewardsPage ? (
                <MonthlyRewardLimit
                  limit={rewardRulesConfigurationState?.monthlyRewardLimit}
                  getRewardsConfiguration={getRewardsConfiguration}
                />
              ) : null}

              <Box className="padBoxTop2">
                <RewardDistribution
                  customTykAwardsDistribution={rewardRulesConfigurationState?.customTykAwardsDistribution}
                  handleOnRewardDistributionSave={handleOnRewardDistributionSave}
                />
              </Box>

              {isEnrollControlEnabled && (
                <Box className="padBoxTop2">
                  <ControlUserEnroll />
                </Box>
              )}
            </Stack>
          </Grid>
          <Grid item marginLeft="auto" marginRight="60px">
            <Box className="padBoxTop2">
              <CurrentBalance />
            </Box>
          </Grid>
          <Grid item>
            <Box className="padBoxTop2">
              <DepositSettings userId={user?.id ?? ""} isSubscribed={isSubscribed} />
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        item
        direction="column"
        sx={{ backgroundColor: "#ffffff" }}
        mt={{ sm: 4 }}
        px={{ lg: 8, md: 3 }}
        py={{ sm: 5 }}
        className="rewardsContainer"
      >
        <Grid container item lg={6} md={9} alignItems="flex-end" className="filtersPanel">
          <Grid container item direction="column" className={dropDown} lg={2}>
            <Grid item>
              <Grid item>
                <Grid item>
                  <Typography variant="caption">
                    <FormattedMessage id="your_rewards_categories_filter" defaultMessage="Categories filter" />
                  </Typography>
                </Grid>
                <Select
                  placeholder="All Rules"
                  options={categoriesFilters}
                  labelField="label"
                  valueField="id"
                  onChange={handleCategoriesChange}
                  values={membershipRulesFiltration.selectedCategoryFilters}
                  searchable={false}
                  disabled={!isCategoriesFilterForRewardsEnabled}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container item lg={6} sm={12} mt={{ sm: 2, lg: 0 }} ml={{ sm: 8 }} className={stateButtons}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={createHandleStateFilterToggle("isFilterByInactive")}
                  style={{ cursor: isSubscribed ? "pointer" : "default" }}
                  disableRipple={!isSubscribed}
                />
              }
              label={formatMessage({ id: "label_inactive" })}
              style={{ cursor: isSubscribed ? "pointer" : "default" }}
            />
            <FormControlLabel
              sx={{ marginLeft: "1rem" }}
              control={
                <Checkbox
                  onChange={createHandleStateFilterToggle("isFilterByPublished")}
                  style={{ cursor: isSubscribed ? "pointer" : "default" }}
                  disableRipple={!isSubscribed}
                />
              }
              label={formatMessage({ id: "label_published" })}
              style={{ cursor: isSubscribed ? "pointer" : "default" }}
            />
            <Typography className="activeRulesAmount">
              <FormattedMessage id="reward_rules_active" />
              <Typography component="span" display="inline">
                {`: ${filtered.filter((item) => item.isActive).length}`}
              </Typography>
            </Typography>
          </Grid>
        </Grid>

        <Grid item container mt={{ sm: 3 }} className="buttonsContainer">
          <Button
            className={clsx(deactivateSelected, {
              active: isDeactivateSelectedActive,
            })}
            variant="contained"
            startIcon={<PowerSettingsNewIcon />}
            onClick={handleDeactivateSelectedClick}
            disabled={!isSubscribed}
          >
            <FormattedMessage id="reward_rules_deactivate_selected" />
          </Button>
          <Button
            className={clsx(deactivateAll, {
              active: isDeactivateAllActive,
            })}
            variant="outlined"
            startIcon={<PowerSettingsNewIcon />}
            onClick={handleDeactivateAllClick}
            disabled={!isSubscribed || !isDeactivateAllActive}
          >
            <FormattedMessage id="reward_rules_deactivate_all" />
          </Button>
        </Grid>
        <Grid item mt={{ md: 2 }}>
          {filtered.length ? (
            <MembershipRulesTable
              isSubscribed={isSubscribed}
              membershipType={user?.membership?.title}
              membershipRules={filtered}
              setRewardSplit={setRewardSplit}
              handlePublishModal={handlePublishModal}
              handleSaveModal={handleSaveModal}
              handleSelectRuleToggle={handleSelectRuleToggle}
              handleActiveRuleToggle={handleActiveRuleToggle}
              handleSelectAllRulesToggle={handleSelectAllRulesToggle}
              isAllRulesSelected={isAllRulesSelected}
              tykchaseGlobalConfig={{
                ...rewardRulesConfigurationState?.tykchaseGlobalConfig,
                customTykAwardsDistribution: {
                  ...rewardRulesConfigurationState?.customTykAwardsDistribution,
                },
              }}
              disabledChildrenRules={disabledChildrenRules}
            />
          ) : (
            <Typography>
              <FormattedMessage id="no_data" />
            </Typography>
          )}
        </Grid>
      </Grid>
      <DisableFeatureModal
        isOpen={isDeactivateAllModalOpen}
        handleCloseModal={closeDeactivateAllModal}
        handleContinueModal={handleDeactivateAll}
        titleModal="reward_rules_deactivation_modal_title_multiple"
        messageModal={
          <Stack spacing={2}>
            <Typography>
              <FormattedMessage
                id="reward_rules_deactivation_modal_multiple_message_default"
                values={{ rules: filtered.map((rule) => rule.title).join(", ") }}
              />
            </Typography>
            <Box>
              {filtered.map((rule) => (
                <ListItem sx={{ display: "list-item" }} key={rule.id}>
                  {rule.title}
                </ListItem>
              ))}
            </Box>
            <Typography>
              <FormattedMessage id="reward_rules_deactivation_modal_multiple_after_list" />
            </Typography>
          </Stack>
        }
      />
      <DisableFeatureModal
        isOpen={isDeactivateSelectedModalOpen}
        handleCloseModal={closeDeactivateSelectedModal}
        handleContinueModal={handleDeactivateSelected}
        titleModal="reward_rules_deactivation_modal_title_multiple"
        messageModal={
          <Stack spacing={2}>
            <Typography>
              <FormattedMessage
                id="reward_rules_deactivation_modal_multiple_message_default"
                values={{ rules: filtered.map((rule) => rule.title).join(", ") }}
              />
            </Typography>
            <Box>
              {selectedRules.map((rule) => (
                <ListItem sx={{ display: "list-item" }} key={rule.id}>
                  {rule.title}
                </ListItem>
              ))}
            </Box>
            <Typography>
              <FormattedMessage id="reward_rules_deactivation_modal_multiple_after_list" />
            </Typography>
          </Stack>
        }
      />
    </>
  );
};

export default RewardRules;
