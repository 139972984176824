import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    experiencesContainer: {
      marginTop: "36px",
      padding: "28px 44px",
      backgroundColor: theme.palette.white,
      [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
        width: "100vw",
        padding: "10px",
        marginTop: "20px",
      },
    },

    eventExperiencesSelectionContainer: {
      "& .experiencesTitle": {
        marginBottom: "12px",
        fontFamily: theme.typography.fontFamily,
        fontSize: "0.875rem",
        fontWeight: 600,
        color: theme.palette.experiences.dark,
      },

      "& .experiencesDescription": {
        fontFamily: theme.typography.fontFamily,
        fontSize: "0.75rem",
        fontWeight: 500,
        color: theme.palette.experiences.grey,
      },
    },

    experiencesSearchContainer: {
      display: "flex",
      margin: "24px 0 10px",
      alignItems: "center",

      "& .inputWrapper": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: "31px",
        width: "100%",
        padding: "0 6px",
        marginRight: "15px",
        borderRadius: "3px",
        border: `2px solid ${theme.palette.experiences.lightGrey}`,

        "& .searchIcon": {
          color: theme.palette.experiences.lightGrey,
        },

        "& .MuiInputBase-root": {
          width: "100%",
          "& .MuiInputBase-input": {
            paddingLeft: "10px",
            paddingRight: "10px",
            fontFamily: theme.typography.fontFamily,
            fontSize: "0.75rem",
          },
        },
      },

      "& .searchButton": {
        width: "165px",
        height: "31px",
        padding: "0 48px",
        border: "1px solid #34305D",
        borderRadius: "1rem",
        backgroundColor: theme.palette.experiences.darkPurple,
        fontFamily: theme.typography.fontFamily,
        fontSize: "0.875rem",
        color: theme.palette.white,
        "&:hover": {
          backgroundColor: theme.palette.experiences.darkPurple,
        },

        [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
          padding: "0 14px",
          borderRadius: "20px",
        },
      },
    },

    experiencesFilter: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",

      "& .experiencesFilterTitle": {
        marginRight: "28px",
        fontFamily: theme.typography.fontFamily,
        fontSize: "0.75rem",
      },

      "& .MuiFormControlLabel-root": {
        marginLeft: 0,

        "& .MuiFormControlLabel-label": {
          marginLeft: "4px",
          fontFamily: theme.typography.fontFamily,
          fontSize: "0.75rem",
          cursor: "pointer",
        },

        "& .MuiCheckbox-root": {
          padding: "3px",
          height: "32px",

          "& .MuiSvgIcon-root": {
            width: "18px",
            height: "18px",
            fill: theme.palette.experiences.green,
          },
        },
      },
    },
  };
});

export default useStyles;
