import { Button } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { ButtonsStyle } from "../../Buttons/styles";
import { AddPaymentMethodButtonProps } from "./interfaces/add-payment-method-button.interface";

export function AddPaymentMethodButton(props: AddPaymentMethodButtonProps): JSX.Element {
  return (
    <Button sx={{ ...ButtonsStyle(null).mainBtn }} variant="contained" onClick={props.onClick}>
      <FormattedMessage id="add-payment-method.trigger-button.text" />
    </Button>
  );
}
