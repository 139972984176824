import { defineMessages, MessageDescriptor } from "react-intl";

export type IMessage = MessageDescriptor;

export const messages = defineMessages({
  UNEXPECTED: {
    id: "errors.unexpected",
    defaultMessage: "An unexpected error occurred. Please, try reloading the page!",
  },

  COULD_NOT_CANCEL_MEMBERSHIP: {
    id: "error_could_not_cancel_membership",
    defaultMessage: "Unfortunately, we couldn't cancel your membership. Please, try again later.",
  },

  ACCOUNT_IS_BANNED: {
    id: "errors.account_is_banned",
    defaultMessage: "Unfortunately, your account is banned!",
  },

  NOT_AUTHENTICATED: {
    id: "errors.not_authenticated",
    defaultMessage: "Unfortunately, you are having authentication issues!",
  },

  INVALID_REFRESH_TOKEN: {
    id: "errors.invalid_refresh_token",
    defaultMessage: "Invalid refresh token!",
  },

  ERROR_GETTING_MONTHLY_TOTAL_CENTOR_AMOUNT: {
    id: "errors.error_getting_monthly_total_centor_amount",
    defaultMessage: "Unfortunately, issues with receiving the monthly total amount of the centor!",
  },

  ERROR_GETTING_MONTHLY_SCANNED_RECEIPTS: {
    id: "errors.error_getting_monthly_scanned_receipts",
    defaultMessage: "Unfortunately, issues with receiving the monthly scanned receipts!",
  },

  ERROR_GETTING_WEEKLY_AVG_SCANNED_RECEIPT_INFO: {
    id: "errors.error_getting_weekly_avg_scanned_receipt_info",
    defaultMessage: "Unfortunately, issues with receiving the weekly average scanned receipts info!",
  },

  ERROR_GETTING_BUSINESS_PROFILE_INFO: {
    id: "errors.error_getting_business_profile_info",
    defaultMessage: "Unfortunately, issues with receiving business profile info!",
  },

  ERROR_GETTING_MEMBERSHIP: {
    id: "errors.error_getting_membership",
    defaultMessage: "Unfortunately, issues with receiving membership info!",
  },

  ERROR_CANCEL_MEMBERSHIP: {
    id: "errors.error_cancel_membership",
    defaultMessage: "Unfortunately, issues with canceling membership!",
  },

  ERROR_REMOVING_PAYMENT_METHOD: {
    id: "errors.error_removing_payment_method",
    defaultMessage: "Unfortunately, issues with removing payment method!",
  },

  ERROR_FETCHING_USER_TRANSACTIONS: {
    id: "errors.error_fetching_user_transactions",
    defaultMessage: "Unfortunately, issues with fetching user transactions!",
  },

  ERROR_GETTING_REWARD_RULES_DASHBOARD_INFO: {
    id: "errors.error_getting_reward_rules_dashboard_info",
    defaultMessage: "Unfortunately, issues with receiving reward rules dashboard info!",
  },

  ERROR_GETTING_TYKCHASE_CONFIGURATION: {
    id: "errors.error_getting_tykchase_configuration",
    defaultMessage: "Unfortunately, issues with receiving tykchase configuration!",
  },

  ERROR_CART_PAYMENT_PROCESSING_INFO_NOT_FOUND: {
    id: "errors.error_cart_payment_processing_info_not_found",
    defaultMessage: "Unfortunately, cart payment processing info not found!",
  },

  ERROR_ACCESS_TO_THIS_BUSINESS: {
    id: "errors.error_access_to_this_business",
    defaultMessage: "Unfortunately, you don't have access to this business!",
  },

  ERROR_GETTING_BUSINESS_PROFILE: {
    id: "errors.error_getting_business_profile",
    defaultMessage: "Unfortunately, issues with receiving business profile!",
  },

  ERROR_GETTING_ACCOUNTS_BUSINESS_PROFILES: {
    id: "errors.error_getting_accounts_business_profiles",
    defaultMessage: "Unfortunately, issues with receiving account business profiles!",
  },

  ERROR_BUSINESS_ALREADY_HAVE_THIS_MEMBERSHIP: {
    id: "errors.error_business_already_have_this_membership",
    defaultMessage: "Unfortunately, business already have this membership!",
  },

  ERROR_UPDATING_MONTHLY_REWARD_LIMIT: {
    id: "errors.error_updating_monthly_reward_limit",
    defaultMessage: "Unfortunately, issues with updating monthly reward limit!",
  },

  ERROR_UPDATING_RULE_CONFIGURATION: {
    id: "errors.error_updating_rule_configuration",
    defaultMessage: "Unfortunately, issues with updating rule configuration!",
  },

  ERROR_DISABLING_RULE_CONFIGURATION: {
    id: "errors.error_disabling_rule_configuration",
    defaultMessage: "Unfortunately, issues with disabling rule configuration!",
  },

  ERROR_CREATING_ACCOUNT_ADDRESS: {
    id: "errors.error_address_creation",
    defaultMessage: "An error occurred while creating a new address. Please try again",
  },
});
