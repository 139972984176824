import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    openHistoryButtonContainer: {
      "& .MuiButtonBase-root": {
        fontSize: "0.875rem",
        textDecoration: "underline",
        color: theme.palette.analytics?.grey,
      },

      "& .MuiButtonBase-root.active": {
        color: theme.palette.blue,
      },
    },

    modal: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },

    modalContent: {
      display: "flex",
      flexDirection: "row",
      maxHeight: "90vh",
      maxWidth: "90vw",
      width: "max-content",
      borderRadius: "5px",
      background: theme.palette.white,
      position: "relative",

      "@media(max-width: 600px)": {
        width: "100%",
      },

      "& .left-section": {
        "& .left-section-header": {
          display: "flex",
          flexDirection: "row",
          padding: "70px 50px 50px",

          "& .left-section-header__title": {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",

            "& h1": {
              fontSize: "1.125rem",
              fontWeight: 600,
              color: "#34305D",
            },

            "& h3": {
              fontSize: "0.75rem",
              fontWeight: 400,
              color: "#AAA9A9",
            },
          },

          "& .left-section-header__title.left": {
            marginRight: "50px",
          },
        },

        "& .left-section-body": {
          paddingBottom: "50px",
        },
      },

      "& .MuiDivider-vertical": {
        height: "auto",
      },

      "& .right-section": {
        "& .right-section-header": {
          "& .MuiButtonBase-root": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "30px 20px 0",

            "& .MuiSvgIcon-root": {
              width: "20px",
              height: "20px",
              marginRight: "8px",
              fill: "#4262FF",
            },

            "& .backto-text": {
              color: "#4262FF",
              fontSize: "0.875rem",
              textDecoration: "underline",
            },
          },
        },
      },
    },
  };
});

export default useStyles;
