import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import theme from "../../../../theme";
import useStyles from "./styles";

const DemographicsInsights: FC = () => {
  const { wrap } = useStyles();

  return (
    <Box className={wrap}>
      <Box className="headerWrapper">
        <Typography
          fontFamily={theme.typography.fontFamily}
          fontSize="1rem"
          fontWeight={600}
          color={theme.palette.primary.dark}
        >
          <FormattedMessage id="analytics.chart_titles.demographics_insights" />
        </Typography>
      </Box>
    </Box>
  );
};

export default DemographicsInsights;
