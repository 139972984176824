import { FC, useEffect, useImperativeHandle, RefObject } from "react";
import { FormikProps } from "formik";
import ReceiptTotalTrigger from "./Triggers/ReceiptTotalTrigger";
import DayTrigger from "./Triggers/DayTrigger";
import ProductServiceTrigger from "./Triggers/ProductServiceTrigger";
import SurpriseTrigger from "./Triggers/SurpriseTrigger";
import MultiplierTrigger from "./Triggers/MultiplierTrigger";
import AnimatedLoader from "../../../../../AnimatedLoader";
import { TykchaseCampaign, TykchaseCampaignTriggers } from "../../../../../../generated/types";
import { TriggerStepFormikValues, StepsFormikValues } from "../../../../../../types/campaigns";

type Props = {
  triggerType?: TykchaseCampaignTriggers | boolean;
  activeCampaigns?: TykchaseCampaign[];
  isLoadingActiveCampaignsData?: boolean;
  triggerRef: RefObject<FormikProps<StepsFormikValues>>;
  formik: FormikProps<StepsFormikValues>;
};

const TriggerStep: FC<Props> = ({
  triggerType,
  activeCampaigns,
  triggerRef,
  isLoadingActiveCampaignsData,
  formik,
}: Props) => {
  const { ReceiptTotal, Day, Surprise, ProductOrService } = TykchaseCampaignTriggers;

  useImperativeHandle(triggerRef, () => ({
    ...formik,
  }));

  return (
    <>
      <form>
        {triggerType === ReceiptTotal && <ReceiptTotalTrigger formik={formik} />}

        {triggerType === Day && <DayTrigger formik={formik} />}

        {triggerType === ProductOrService && <ProductServiceTrigger formik={formik} />}

        {triggerType === Surprise && <SurpriseTrigger formik={formik} />}

        {!triggerType && <MultiplierTrigger formik={formik} activeCampaigns={activeCampaigns} />}

        {isLoadingActiveCampaignsData && <AnimatedLoader width={80} height={80} />}
      </form>
    </>
  );
};

export default TriggerStep;
