import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    detailsContainer: {
      width: "560px",
      [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
        maxWidth: "100%",
      },

      "& .modalHeader": {
        display: "flex",
        flexDirection: "column",
        width: "auto",
        margin: "0 -70px",
        padding: "10px 40px",
        backgroundColor: "#F8F8F8",

        [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
          margin: "0 -20px",
          padding: "10px 20px",
        },

        "& .imageWrapper": {
          width: "48px",
          height: "46px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginRight: "10px",
          borderTopLeftRadius: "6px",
          borderTopRightRadius: "6px",
          backgroundColor: theme.palette.marketing.lightPurple,

          "& img": {
            width: "100%",
            height: "100%",
          },
        },

        "& .headerTitleContainer": {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",

          "& .headerTitle": {
            fontSize: "1.125rem",
            fontWeight: "medium",
            color: theme.palette.primary.dark,
          },

          "& .extra": {
            fontSize: "0.75rem",
            color: "#AAA9A9",
          },
        },

        "& .MuiTableCell-head": {
          fontFamily: theme.typography.fontFamily,
          fontSize: "0.75rem",
          padding: "4px 10px",
          color: "#707070",
          lineHeight: "normal",
          border: "none",
        },
        "& tr": {
          cursor: "default",

          "& .MuiTableCell-body": {
            padding: "4px 10px",
            border: "none",
            fontFamily: theme.typography.fontFamily,
            fontSize: "0.75rem",
            color: theme.palette.primary.dark,
            lineHeight: "normal",
          },
        },
      },

      "& .modalBody": {
        width: "auto",
        margin: "0 -70px",
        padding: "16px 40px 24px",

        [`@media(max-width: ${theme.breakpoints.values.lg}px)`]: {
          margin: "0 -20px",
          padding: "16px 20px 24px",
        },

        "& .analyticsButton": {
          fontSize: "0.75rem",
          color: theme.palette.marketing.blue,
          textTransform: "capitalize",
          textDecoration: "underline",

          "& svg": {
            width: "10px",
            height: "10px",
          },
        },

        "& .MuiTableCell-head": {
          fontFamily: theme.typography.fontFamily,
          fontSize: "0.75rem",
          padding: "10px",
          color: theme.palette.marketing.lightPurple,
          lineHeight: "normal",
        },

        "& tr": {
          cursor: "default",

          "&:nth-of-type(even)": {
            backgroundColor: "#F8F8F8",
          },

          "& .MuiTableCell-body": {
            padding: "10px",
            border: "none",
            fontFamily: theme.typography.fontFamily,
            fontSize: "0.75rem",
            color: theme.palette.marketing.darkGrey,
            lineHeight: "normal",

            "& .typeWrapper": {
              width: "22px",
              height: "22px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              backgroundColor: theme.palette.marketing.lightGrey,
            },

            "& .previewWrapper": {
              width: "44px",
              height: "44px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              backgroundColor: theme.palette.marketing.lightPurple,

              "& .previewImage": {
                width: "100%",
                height: "100%",
                borderRadius: "50%",
              },
            },
          },
        },
      },
    },
  };
});

export default useStyles;
