import { graphql } from "../../../gql";

export const createSubscriptionPaymentMethodUpdateCartMutation = graphql(`
  mutation CreateSubscriptionPaymentMethodUpdateCartMutation($businessId: String!, $addressId: String!) {
    createSubscriptionPaymentMethodUpdateCart(input: { businessId: $businessId, addressId: $addressId }) {
      payload {
        id
      }
    }
  }
`);
