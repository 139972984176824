import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { Grid, Typography, Box } from "@mui/material";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { stringify } from "qs";
import Table from "./MembershipTable";
import { membershipStyle, useStyles } from "./styles";
import { useBusinessProfile } from "../../hooks/useBusinessProfile.hook";
import { getAllMembershipsQuery } from "./graphql/GetAllMemberships.query";
import { MembershipDetails } from "./types";
import { TykchaseConfigStatus } from "../../gql/graphql";
import { PageLoader } from "../PageLoader";
import MembershipCard from "./MembershipCard";
import { MembershipRule } from "./MembershipSummary/types";
import { GET_ALL_RULES } from "../../apollo/queries";
import { filteredRules } from "../Utils/helpers";

const Membership: FC = () => {
  const { headingContainer, fontDisplay, fontTitle } = useStyles();

  const [businessProfile, , isSubscribed] = useBusinessProfile();
  const { path } = useRouteMatch();
  const history = useHistory();
  const { loading: membershipsLoading, data: membershipsData } = useQuery(getAllMembershipsQuery);

  const { loading: rulesLoading, data: rulesData } = useQuery(GET_ALL_RULES);
  const allRules = useMemo(
    () => filteredRules(rulesData, businessProfile?.isStagewoodProfile) ?? {},
    [businessProfile?.isStagewoodProfile, rulesData],
  );

  const memberships = useMemo((): MembershipDetails[] => {
    return (
      membershipsData?.getAllMemberships?.map((membership) => {
        return {
          title: membership?.title ?? "",
          locationsCountLimit: membership?.locationsCountLimit ?? 0,
          internalId: membership?.internalId ?? "",
          setupFee: membership?.setupFee ?? 0,
          locationSetupFee: membership?.locationSetupFee ?? "0",
          mandatoryDepositAmount: membership?.mandatoryDepositAmount ?? 0,
        };
      }) ?? []
    );
  }, [membershipsData]);

  const formattedData = Object.keys(allRules).map((key) => [{ perkTitle: key }, allRules[key]].flat());

  const navigateToMembershipSummary = (name: string) => {
    history.push({
      pathname: `${path}/${name}`,
      search: stringify({ addQueryPrefix: true }),
    });
  };

  if (membershipsLoading) {
    return <PageLoader />;
  }

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sx={{ ...membershipStyle.headingContainer }} className={headingContainer}>
        <Box className="title-area">
          <Typography className={fontTitle}>
            <FormattedMessage id="membership.simple_pricing" />
          </Typography>
          <Grid container justifyContent="center">
            <Typography sx={{ ...membershipStyle.fontDisplay }} className={fontDisplay}>
              <FormattedMessage id="membership.premium" />
            </Typography>
          </Grid>
        </Box>
      </Grid>
      <Grid container item spacing={2} lg={12} xl={8} direction="column" alignItems="center">
        <Grid container item justifyContent="center" style={{ marginBottom: "2rem", gap: "2rem" }}>
          {memberships.map((membership) => (
            <MembershipCard
              {...membership}
              membershipTitle={
                businessProfile?.status !== TykchaseConfigStatus.Inactive
                  ? businessProfile?.membership?.title
                  : undefined
              }
              onSelectPlan={navigateToMembershipSummary}
              key={membership.title}
            />
          ))}
        </Grid>
      </Grid>
      <Table
        memberships={memberships}
        membershipTitle={
          businessProfile?.status !== TykchaseConfigStatus.Inactive ? businessProfile?.membership?.title : undefined
        }
        allRules={formattedData}
        onSelectPlan={navigateToMembershipSummary}
      />
    </Grid>
  );
};

export default Membership;
