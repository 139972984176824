import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    appBar: {
      backgroundColor: "#161C2E",
      width: "2.5rem",
      left: 0,
    },
    drawer: {
      flexShrink: 0,
    },
    drawerHeader: {
      display: "flex",
      padding: "1rem 0 1rem 2rem",
      backgroundColor: "#161C2E",
    },
    drawerHeaderDesktop: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "2rem 2rem 1rem 2rem ",
      backgroundColor: "#161C2E",
    },
    drawerPaper: {
      backgroundColor: "#161C2E",
    },
    closeIcon: {
      color: "#fff",
    },
    text: {
      color: "#fff",
      fontSize: 12,
    },
    listItem: {
      display: "flex",
      alignItems: "center",
    },
    button: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "&:focus": {
        backgroundColor: "#0364FF",
        borderRadius: "5px",
        "& svg": {
          fill: "#fff",
        },
      },
      "&:hover": {
        backgroundColor: "#283354",
        borderRadius: "5px",
      },
      "&.Mui-disabled": {
        opacity: "1",
      },
    },
    menu: {
      padding: "0.5rem 1rem",
      backgroundColor: "#161C2E",
      height: "100%",
    },
    contact: {
      padding: "22px",
      marginTop: "-2px",
      background: "#161C2E",
      color: "#ADB5C0",

      "& .wrap": {
        display: "flex",
        alignItems: "end",
        justifyContent: "space-between",
        padding: "20px",
        border: "1px solid #8986AD",
        borderRadius: "6px",

        "& .avatar": {
          height: "33px",
          width: "33px",
        },

        "& p": {
          fontSize: "12px",
        },

        "& .MuiButtonBase-root": {
          padding: "6px 20px",
          fontWeight: "bold",
          color: "#FFFFFF",
          background: "#4262FF",
          borderRadius: "3px",
        },
      },

      "& .privacy": {
        marginTop: "32px",
        fontSize: "10px",
      },
    },
  };
});

export default useStyles;
