import { FC } from "react";
import { useMutation } from "@apollo/client";
import { Dialog, Typography, Divider, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import { Box } from "@mui/system";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { CANCEL_MEMBERSHIP } from "../../../../apollo/mutations";
import { ReactComponent as WarningIcon } from "../../../../assets/icons/tykIcons/exclamation-triangle.svg";
import useStyles from "./styles";
import { ICancellationModal, CancellationModalText } from "./types";
import { GetBusinessProfileInfoDocument } from "../../../../gql/graphql";
import { LoadingButton } from "../../../LoadingButton/loading-button.component";

export const CancellationModal: FC<ICancellationModal> = ({ open, onClose, userId }) => {
  const { cancellationModal } = useStyles();
  const intl = useIntl();
  const history = useHistory();
  const [cancelMembership, { loading }] = useMutation(CANCEL_MEMBERSHIP, {
    variables: {
      userId,
    },
    refetchQueries: [GetBusinessProfileInfoDocument],
    awaitRefetchQueries: true,
    onCompleted: (data) => {
      if (!data.cancelMembership.payload) {
        onClose();
      } else {
        history.push({ pathname: `/Memberships` });
      }
    },
    onError: () => {
      onClose();
    },
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="cancellation-dialog"
      aria-describedby="cancellation-dialog"
      className={cancellationModal}
    >
      <Box className="modal">
        <Box className="modal-title">
          <Typography className="modal-title-text">{CancellationModalText(intl).TITLE}</Typography>
          <WarningIcon className="warning-icon" />
        </Box>
        <Divider />
        <DialogContent className="modal-content">
          <DialogContentText>{CancellationModalText(intl).DESCR}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="outlined"
            disabled={loading}
            loading={loading}
            onClick={() => cancelMembership()}
            className="confirm-btn btn"
            classes={{ loadingIndicator: "loading-indicator" }}
          >
            {CancellationModalText(intl).CONFIRM}
          </LoadingButton>
          <Button variant="contained" disabled={loading} onClick={onClose} className="cancel-btn btn">
            {CancellationModalText(intl).CANCEL}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
