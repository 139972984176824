import { Typography, InputAdornment, Grid, Alert, Box } from "@mui/material";
import { BounceLoader } from "react-spinners";
import { useUser } from "@stagewood/unified-login-library";
import { useMutation } from "@apollo/client";
import { FormattedMessage } from "react-intl";
import { useFormik } from "formik";
import * as yup from "yup";
import useStyles from "./styles";
import { UPDATE_MONTHLY_REWARD_LIMIT_MUTATION } from "../../../apollo/mutations";
import { PatternInput } from "../../shared/PatternInput/index";
import { TWO_DIGITS_AFTER_COMMA_DECIMAL_PATTERN } from "../regexp";

const MonthlyRewardLimit = ({ limit = 0, getRewardsConfiguration }) => {
  const { wrap, editButton, inputStyle, container } = useStyles();

  const user = useUser();
  const { values, setFieldValue, submitForm, errors, setValues, handleSubmit } = useFormik({
    initialValues: {
      monthlyRewardLimit: limit,
      editRewardLimit: false,
    },
    validationSchema: yup.object().shape({
      editRewardLimit: yup.boolean(),
      monthlyRewardLimit: yup.number().when("editRewardLimit", {
        is: (editRewardLimit) => editRewardLimit === true,
        then: yup.number().min(300).required(),
        otherwise: yup.number(),
      }),
    }),
    enableReinitialize: true,
    onSubmit: () => {},
  });

  const [
    updateMonthlyRewardLimit,
    { loading: isUpdateMonthlyRewardLimitMutationLoading, data: updateMonthlyRewardLimitData },
  ] = useMutation(UPDATE_MONTHLY_REWARD_LIMIT_MUTATION);

  const onEditClick = async () => {
    await submitForm();
    if (values.editRewardLimit && !errors.monthlyRewardLimit && limit !== values.monthlyRewardLimit) {
      await updateMonthlyRewardLimit({
        variables: {
          userId: user?.id,
          rewardLimit: values.monthlyRewardLimit.toString(),
        },
      });
      await getRewardsConfiguration();
    }
    if (!errors.monthlyRewardLimit) setFieldValue("editRewardLimit", !values.editRewardLimit);
  };

  const onCancelClick = async () => {
    await submitForm();
    setValues({
      monthlyRewardLimit: limit || 0,
      editRewardLimit: false,
    });
  };
  const handleChange = (event) => {
    setFieldValue("monthlyRewardLimit", event.target.value);
  };

  return (
    <form onSubmit={handleSubmit} className={wrap}>
      <p className="lable">
        <FormattedMessage id="your_rewards_monthly_reward_limit" />
      </p>
      <Box className={container}>
        <PatternInput
          className={inputStyle}
          value={values.editRewardLimit ? values.monthlyRewardLimit : limit}
          onChange={handleChange}
          pattern={TWO_DIGITS_AFTER_COMMA_DECIMAL_PATTERN}
          placeholder="0.00"
          readOnly={!values.editRewardLimit}
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
          endAdornment={
            <InputAdornment position="end">
              {isUpdateMonthlyRewardLimitMutationLoading ? (
                <BounceLoader size={20} color="#EE2970" />
              ) : (
                <Grid direction="row">
                  <Typography className={editButton} onClick={() => onEditClick()}>
                    <FormattedMessage id={values.editRewardLimit ? "btn_text_save" : "btn_text_edit"} />
                  </Typography>
                  {values.editRewardLimit && (
                    <Typography className={editButton} onClick={onCancelClick}>
                      <FormattedMessage id="btn_text_cancel" />
                    </Typography>
                  )}
                </Grid>
              )}
            </InputAdornment>
          }
        />
        {errors.monthlyRewardLimit && (
          <Alert severity="warning">
            {errors.monthlyRewardLimit && (
              <p>
                <FormattedMessage
                  id="tyk_monthly_reward_amount_minimum"
                  defaultMessage="Minimum monthly reward limit recharge is $300"
                  values={{ minDeposit: <b>${300}</b> }}
                />
              </p>
            )}
          </Alert>
        )}
      </Box>
    </form>
  );
};

export default MonthlyRewardLimit;
