import { makeStyles } from "@mui/styles";

const drawerWidth = 300;

const useStyles = makeStyles((theme) => {
  return {
    content: {
      flexGrow: 1,
      backgroundColor: "#FAFAFA",
      marginLeft: drawerWidth,
      marginTop: 0,
      minHeight: "100vh",
      overflow: "auto",
      msOverflowStyle: "none",
      scrollbarWidth: "none",
      paddingTop: "99px",

      "&::-webkit-scrollbar": {
        display: "none",
      },
      "@media (max-width: 600px)": {
        width: "100%",
        marginLeft: 0,
      },
    },
  };
});

export default useStyles;
