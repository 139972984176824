import { ProfileButton as Button, User, UserType as Type } from "@stagewood/unified-login-library";
import { useApolloClient } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";
import { Routes } from "../../constants";
import { UserType } from "../../types/userType";
import { getBusinessProfileInfoRequest } from "../../graphql/GetBusinessProfileInfo.query";

const loginRoutes = new Set([
  Routes.ENROLL,
  Routes.CHOOSE_BUSINESS,
  Routes.HOW_TO_CREATE_BUSINESS_PROFILE,
  Routes.COOKIES,
  Routes.TERMS_OF_SERVICE,
]);

const allowedUserTypes: Type[] = [UserType.BUSINESS];

const userFilter = (u) => u.userType === UserType.BUSINESS;

export default function ProfileButton() {
  const history = useHistory();
  const location = useLocation();
  // Using useApolloClient instead of useLazyQuery because of https://github.com/apollographql/apollo-client/issues/9354
  const client = useApolloClient();

  const currentURL = `${window.location.origin}${location.pathname}${location.search}`;

  const onUserSwitchedCallback = async (newUser: User) => {
    const { data } = await client.query({
      query: getBusinessProfileInfoRequest,
      variables: {
        userId: newUser.id,
      },
    });

    if (data?.businessProfileInfo?.payload) {
      if (loginRoutes.has(location.pathname as Routes)) {
        history.push(!data?.businessProfileInfo?.payload?.membership ? Routes.MEMBERSHIPS : Routes.DASHBOARD);
      }
    }
  };

  return (
    <Button
      allowedUserTypes={allowedUserTypes}
      userSelector={userFilter}
      beforeSwitchingUser={userFilter}
      afterUserSwitched={onUserSwitchedCallback}
      system="windows-popup"
      origin="tykbroker"
      currentUrl={currentURL}
    />
  );
}
