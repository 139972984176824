export enum ApplicationFeatures {
  TotalDistributionCentors = "TotalDistributionCentors",
  TotalDistributionLoneStars = "TotalDistributionLoneStars",
  TotalDistributionJackpot = "TotalDistributionJackpot",
  TotalDistributionCashier = "TotalDistributionCashier",
  TotalDistributionMinLoneStars = "TotalDistributionMinLoneStars",
}

export enum ServerResponseStatus {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}
