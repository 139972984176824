import qs from "qs";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";

import { useQueryParams } from "./useQueryParams.hook";

export function useSetQueryParams(): (params: Record<string, unknown>) => void {
  const history = useHistory();
  const oldParams = useQueryParams();

  return useCallback(
    (params: Record<string, unknown>) => {
      const path = qs.stringify(
        {
          ...oldParams,
          ...params,
        },
        { skipNulls: true, arrayFormat: "comma", addQueryPrefix: true },
      );

      history.push(path);
    },
    [oldParams, history],
  );
}
