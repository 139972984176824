import { useMutation, useQuery } from "@apollo/client";

import { Button } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { LoadingButton } from "../LoadingButton/loading-button.component";
import Modal from "../Modal";
import { PageLoader } from "../PageLoader";
import { Select } from "../Select";
import { getOptions } from "../Utils/Notifications";
import { AddressCreationForm } from "./AddressCreationForm";
import { createAccountAddressMutation } from "./AddressCreationForm/graphql/CreateAccountAddress.mutation";
import { useAddressCreationForm } from "./AddressCreationForm/useAddressCreationForm.hook";
import { getAccountAddressesQuery } from "./graphql/GetAccountAddresses.query";
import { useStyles } from "./styles";

export interface AddressSelectionModalProps {
  isActive: boolean;
  onClose: VoidFunction;
  onSubmit: (addressId: string) => void;
  allowAccountCreation: boolean;
}

const NEW_ADDRESS = "add_new_address";

export const AddressSelectionModal: FC<AddressSelectionModalProps> = ({
  isActive,
  onClose: handleClose,
  onSubmit: handleSubmit,
  allowAccountCreation = true,
}) => {
  const styles = useStyles();
  const [addressId, setAddressId] = useState<string | null>(null);

  const [{ values, isValid, addressCreationProps }, submit ] = useAddressCreationForm({
    addressCity: "",
    addressStreet1: "",
    addressStreet2: "",
    label: "",
    zipCode: "",
    isDefault: false,
  });

  const { formatMessage } = useIntl();
  const { data, loading } = useQuery(getAccountAddressesQuery, {
    skip: !isActive,
  });
  const { enqueueSnackbar } = useSnackbar();

  const options = useMemo(
    () =>
      (data?.getAccount?.payload?.accountAddresses ?? []).map((address) => ({
        value: address.id,
        label: `${address.addressCity}, ${address.zipCode}, ${address.addressStreet1} (${address.label})`,
      })),
    [data?.getAccount?.payload?.accountAddresses],
  );

  const getSelectOptions = useMemo(() => {
    return allowAccountCreation
      ? [
          { value: NEW_ADDRESS, label: formatMessage({ id: "create_account_address_form.add_new_address" }) },
          ...options,
        ]
      : options;
  }, [allowAccountCreation, options, formatMessage]);

  const [createAccountAddress, { loading: creatingAddress }] = useMutation(createAccountAddressMutation, {
    variables: {
      input: {
        addressCity: values?.addressCity as string,
        addressStreet1: values?.addressStreet1 as string,
        addressStreet2: values?.addressStreet2 as string,
        label: values?.label as string,
        zipCode: values?.zipCode as string,
        isDefault: values?.isDefault as boolean,
      },
    },
    onCompleted: (createAccountAddressData) => {
      if (!createAccountAddressData.createAccountAddress?.payload) {
        enqueueSnackbar(formatMessage({ id: "errors.error_address_creation" }), getOptions("error"));
        return;
      }
      handleSubmit(createAccountAddressData.createAccountAddress?.payload?.id as string);
      setAddressId(null);
    },
    refetchQueries: [getAccountAddressesQuery],
    awaitRefetchQueries: true,
  });

  const handleModalSubmit = useCallback(() => {
    submit();
    if (addressId === NEW_ADDRESS && isValid) {
      createAccountAddress();
    }

    if (addressId && addressId !== NEW_ADDRESS) {
      handleSubmit(addressId);
    }
  }, [submit, addressId, isValid, createAccountAddress, handleSubmit]);

  return (
    <Modal isOpen={isActive} onClose={handleClose}>
      <div className={styles.modalContent}>
        <header className={styles.modalHeader}>
          <h1>
            <FormattedMessage id="modals.address_selection.title" />
          </h1>
        </header>
        {loading ? (
          <PageLoader />
        ) : (
          <>
            <Select
              backspaceDelete={false}
              clearable={false}
              searchable={false}
              options={getSelectOptions}
              value={addressId}
              onChange={setAddressId}
              placeholder={formatMessage({ id: "modals.address_selection.select_placeholder" })}
              label={formatMessage({ id: "modals.address_selection.select_label" })}
            />
            {allowAccountCreation && addressId === NEW_ADDRESS && (
              <AddressCreationForm  {...addressCreationProps} />
            )}
          </>
        )}
        <footer className={styles.modalFooter}>
          <Button type="button" onClick={handleClose} classes={{ root: "address-button" }}>
            <FormattedMessage id="btn_text_cancel" />
          </Button>
          <LoadingButton
            className="select-button"
            disabled={!addressId}
            onClick={handleModalSubmit}
            classes={{ root: "address-button", loadingIndicator: "loading-indicator" }}
            variant="contained"
            loadingPosition="start"
            loading={creatingAddress}
          >
            <FormattedMessage id="btn_text_select" />
          </LoadingButton>
        </footer>
      </div>
    </Modal>
  );
};
