import { FC } from "react";
import Header from "../Layout/Header";
import NavigationBar from "../Layout/NavigationBar";
import Main from "../Layout/Main";

const Menu: FC = () => (
  <div>
    <Header />
    <NavigationBar />
    <Main />
  </div>
);

export default Menu;
