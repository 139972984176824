import { useMutation } from "@apollo/client";
import { useUser } from "@stagewood/unified-login-library";
import { useStripe } from "@stripe/react-stripe-js";
import { CreatePaymentMethodCardData, SetupIntentResult, StripeCardNumberElement } from "@stripe/stripe-js";
import { useCallback } from "react";
import { UserPaymentMethod } from "../../../../gql/graphql";
import { getPaymentMethodsQuery } from "../../../Checkout/PaymentMethods/graphql/GetPaymentMethods.query";
import { savePaymentMethodMutation } from "../graphql/save-payment-method.mutation";
import { BillingAddress } from "../../../../types/billingAddress.type";

export function useSavePaymentMethod() {
  const user = useUser();
  const stripe = useStripe();
  const [savePaymentMethod] = useMutation(savePaymentMethodMutation);

  const getPaymentMethod = (
    cardholderName: string | null,
    billingAddress: BillingAddress,
    card: StripeCardNumberElement,
  ): Omit<CreatePaymentMethodCardData, "type"> => {
    return {
      card,
      billing_details: {
        name: cardholderName?.toUpperCase(),
        address: {
          city: billingAddress.city || "",
          country: billingAddress.country || "",
          line1: billingAddress.line1 || "",
          line2: billingAddress.line2 || "",
          postal_code: billingAddress.postal_code || "",
          state: billingAddress.state || "",
        },
      },
    };
  };

  return useCallback(
    (
      card: StripeCardNumberElement,
      clientSecret: string,
      cardholderName: string | null,
      billingAddress: BillingAddress,

      callbacks?: {
        onCardConfirmed?: (data: SetupIntentResult) => void;
        onCardConfirmationFailed?: VoidFunction;
        onPaymentMethodSaved?: (data: UserPaymentMethod) => void;
        onPaymentMethodSavingFailed?: VoidFunction;
        onPaymentMethodSavingSettled?: VoidFunction;
      },
    ) => {
      const paymentMethodToSave = getPaymentMethod(cardholderName, billingAddress, card);
      stripe
        ?.confirmCardSetup(clientSecret, {
          payment_method: paymentMethodToSave,
        })
        .then((data) => {
          if (callbacks?.onCardConfirmed) {
            callbacks?.onCardConfirmed(data);
          }

          savePaymentMethod({
            variables: {
              input: {
                userId: user?.id,
                paymentMethodToken: data.setupIntent?.payment_method,
              },
            },
            refetchQueries: [getPaymentMethodsQuery],
            awaitRefetchQueries: true,
          })
            .then((paymentMethod) => {
              if (callbacks?.onPaymentMethodSaved) {
                callbacks?.onPaymentMethodSaved(paymentMethod.data);
              }
            })
            .catch(() => {
              if (callbacks?.onPaymentMethodSavingFailed) {
                callbacks?.onPaymentMethodSavingFailed();
              }
            })
            .finally(() => {
              if (callbacks?.onPaymentMethodSavingSettled) {
                callbacks?.onPaymentMethodSavingSettled();
              }
            });
        })
        .catch(() => {
          if (callbacks?.onCardConfirmationFailed) {
            callbacks?.onCardConfirmationFailed();
          }
        });
    },
    [savePaymentMethod, stripe, user?.id],
  );
}
