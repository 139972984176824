import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    wrap: {
      color: "#505050",
    },

    header: {
      maxWidth: "460px",
      width: "100%",
      marginBottom: "35px",
      "& .topContainer": {
        display: "flex",
        justifyContent: "space-between",
        paddingBottom: "23px",
        marginBottom: "23px",
        borderBottom: "2px solid #EAEAEA",

        "& h2": {
          fontSize: "19px",

          "@media(max-width: 600px)": {
            fontSize: "16px",
          },
        },
      },

      "& .subTitle": {
        fontSize: "15px",
        "@media(max-width: 600px)": {
          fontSize: "13px",
        },
      },
    },

    messageWrapper: {
      position: "relative",
      marginBottom: "20px",

      "& p": {
        marginBottom: "5px",
        fontSize: "12px",
      },

      "& textarea": {
        width: "100%",
        maxWidth: "460px",
        padding: "15px",
        border: `1px solid ${theme.palette.inputs.border}`,
        borderRadius: "5px",
        resize: "none",
        "&:focus": {
          border: `1px solid ${theme.palette.inputs.active}`,
        },
      },

      "& .errorContainer": {
        width: "100%",
        position: "absolute",
        bottom: "6px",
      },
    },

    contact: {
      "& h3": {
        marginBottom: "20px",
        fontSize: "15px",
        "@media(max-width: 600px)": {
          fontSize: "13px",
        },
      },

      "& .content": {
        display: "flex",
        marginBottom: "40px",
        "& .item": {
          width: "172px",
          padding: "18px 20px 23px",
          borderRadius: "6px",
          transition: "300ms ease-in-out",
          cursor: "pointer",
          wordWrap: "break-word",
          "&.active": {
            background: "#F3F3F3",

            "& .contactMethod": {
              fontWeight: 600,
              color: theme.palette.primary.dark,
            },

            "& .contactData": {
              fontSize: "0.875rem",
              color: theme.palette.primary.dark,
              opacity: "1",
              visibility: "visible",
            },
          },

          "& div": {
            display: "flex",
            alignItems: "center",
            "& .MuiButtonBase-root": {
              height: "11px",
              width: "11px",
              color: "#CDCCD7",
              "&.Mui-checked": {
                color: "#4262FF",
              },

              "& .MuiSvgIcon-root": {
                height: "11px",
                width: "11px",
                transform: "scale(1)",
              },
            },
          },

          "& .contactData": {
            opacity: "0",
            visibility: "hidden",
            marginTop: "13px",
            marginLeft: "6px",
            fontSize: "10px",
            transition: "300ms ease-in-out",
          },
        },
      },
    },

    buttonsContainer: {
      display: "flex",
      justifyContent: "flex-end",

      "@media(max-width: 600px)": {
        justifyContent: "center",
      },

      "& .MuiButtonBase-root": {
        height: "40px",
        padding: "0 26px",
        fontFamily: theme.typography.fontFamily,
        fontSize: "1rem",
        fontWeight: 600,
        borderRadius: "20px",
        boxShadow: "none",
        textTransform: "none",
      },

      "& .MuiButtonBase-root.secondary-button": {
        marginRight: "16px",
        backgroundColor: "#C8000000",
        border: "2px solid #747474",
        color: "#747474",
        "&:hover": {
          backgroundColor: "#47474712",
        },
      },
      "& .MuiButtonBase-root.primary-button": {
        backgroundColor: "#1473E6",
        color: theme.palette.white,
        "&:hover": {
          backgroundColor: "#203ED4",
        },
      },

      "& .link": {
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },

    warningAlert: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",

      "& .MuiButton-root": {
        fontFamily: theme.typography.fontFamily,
        fontSize: "0.875rem",
        fontWeight: 600,
        color: "#603D11",
      },
    },
  };
});

export default useStyles;
