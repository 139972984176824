import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  arrow: {
    color: "#161C2E",
  },
  tooltip: {
    backgroundColor: "#161C2E",
  },
});
