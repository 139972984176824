import { FC, useState } from "react";
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import AddToLoneStarsSectionButton from "../Modals/AddToLoneStarsSectionButton";
import SponsorButton from "../Modals/SponsorButton";
import DetailsModal from "../Modals/DetailsModal";
import { DEFAULT_ROWS_PER_PAGE, ROWS_PER_PAGE_OPTIONS } from "../../../../constants";
import { Pagination, selectDropdownStyles } from "../../../shared/Pagination";
import useStyles from "./styles";

const rows = [
  {
    id: 1,
    experience: {
      main: "Marlins Vs. Pittsburg",
      extra: "LoanDepot Park, Miami Oct 15th, 2021",
    },
    closeDate: "Oct 9th, 2021",
    goal: "5,000 LS",
    awardsAvailable: 1,
    availableAndDistributedLS: "100 / 200",
    followingEvent: "3,000",
    participatingBusiness: 20,
    sponsor: "",
  },
];

const ExperiencesTable: FC = () => {
  const { experiencesTableWrapper, paginationContainer } = useStyles();
  const { selectDropdown } = selectDropdownStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenModal = (event) => {
    event.preventDefault();
    setIsModalOpen(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Grid className={experiencesTableWrapper}>
      <Typography className="experiencesTableTitle">
        <FormattedMessage id="rewards.experiences.search_results" />
      </Typography>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="left">
                <FormattedMessage id="rewards.experiences.experience" />
              </TableCell>

              <TableCell align="center">
                <FormattedMessage id="rewards.experiences.swepstake" />
              </TableCell>

              <TableCell align="center">
                <FormattedMessage id="rewards.experiences.goal" />
              </TableCell>

              <TableCell align="center">
                <FormattedMessage id="rewards.experiences.awards_available" />
              </TableCell>

              <TableCell align="center">
                <FormattedMessage id="rewards.experiences.available_ls" />
              </TableCell>

              <TableCell align="center">
                <FormattedMessage id="rewards.experiences.following_event" />
              </TableCell>

              <TableCell align="center">
                <FormattedMessage id="rewards.experiences.participating_business" />
              </TableCell>

              <TableCell align="center">
                <FormattedMessage id="rewards.experiences.sponsor" />
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id} onClick={handleOpenModal}>
                <TableCell align="center" onClick={(e) => e.stopPropagation()}>
                  <AddToLoneStarsSectionButton />
                </TableCell>

                <TableCell align="left">
                  <div className="experience-title-wrapper">
                    <div className="avatar-icon" />

                    <div className="experience-title">
                      <Typography className="main-text">{row.experience.main}</Typography>

                      <Typography className="extra-text">{row.experience.extra}</Typography>
                    </div>
                  </div>
                </TableCell>

                <TableCell align="center">{row.closeDate}</TableCell>

                <TableCell align="center">{row.goal}</TableCell>

                <TableCell align="center">{row.awardsAvailable}</TableCell>

                <TableCell align="center">{row.availableAndDistributedLS}</TableCell>

                <TableCell align="center">{row.followingEvent}</TableCell>

                <TableCell align="center">{row.participatingBusiness}</TableCell>

                <TableCell align="center" onClick={(e) => e.stopPropagation()}>
                  <SponsorButton />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid className={paginationContainer}>
        <Table>
          <TableBody>
            <TableRow>
              <Pagination
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                count={rows.length}
                labelRowsPerPage={<FormattedMessage id="items_per_page" />}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                showFirstButton
                showLastButton
                SelectProps={{
                  MenuProps: { classes: { paper: selectDropdown } },
                }}
                sx={{ border: "none" }}
              />
            </TableRow>
          </TableBody>
        </Table>

        <Typography>
          <FormattedMessage id="btn_text_next" />
        </Typography>
      </Grid>

      <DetailsModal isOpen={isModalOpen} onClose={handleCloseModal} />
    </Grid>
  );
};

export default ExperiencesTable;
