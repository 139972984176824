import { gql } from "@apollo/client";

export const GET_ALL_HOLIDAYS = gql`
  query GetAllHolidays {
    getAllHolidays {
      id
      title
      description
      celebrationDate
      createdAt
      updatedAt
    }
  }
`;

export const GET_CAMPAIGN = gql`
  query ($id: String!) {
    getCampaign(id: $id) {
      tykchaseCampaignPayload {
        id
        title
        description
        holiday {
          id
          title
          description
          celebrationDate
          createdAt
          updatedAt
        }
        startDate
        endDate
        coverPhotos {
          id
          source
        }
        defaultCoverPhoto
        triggerType
        receiptTotalPriceTrigger
        dayTrigger
        surpriseNumber
        multiplierNumber
        productTrigger {
          id
          name
          price
          code
        }
        ruleRewardConfiguration {
          id
          loneStarReward
          centorReward
          dollarReward
          period
          purchaseRewardRate
          minPurchaseForReward
          rewardOnceHitLimit
          allowMultipleReward
          multipleRewardCount
        }
        status
        campaignType
        boostedTykchaseCampaign {
          id
          title
          description
          holiday {
            id
            title
            description
            celebrationDate
            createdAt
            updatedAt
          }
          startDate
          endDate
          coverPhotos {
            id
            source
          }
          defaultCoverPhoto
          triggerType
          receiptTotalPriceTrigger
          dayTrigger
          surpriseNumber
          multiplierNumber
          productTrigger {
            id
            name
            price
            code
          }
          ruleRewardConfiguration {
            id
            loneStarReward
            centorReward
            dollarReward
            period
            purchaseRewardRate
            minPurchaseForReward
            rewardOnceHitLimit
            allowMultipleReward
            multipleRewardCount
          }
          status
          campaignType
        }
      }
    }
  }
`;

export const GET_ACTIVE_CAMPAIGNS = gql`
  query GetActiveCampaigns {
    getActiveCampaigns {
      id
      title
    }
  }
`;

export const GET_ALL_CAMPAIGNS = gql`
  query GetAllCampaigns {
    getAllCampaigns {
      id
      title
      description
      holiday {
        id
        title
        description
        celebrationDate
        createdAt
        updatedAt
      }
      startDate
      endDate
      coverPhotos {
        id
        source
      }
      defaultCoverPhoto
      triggerType
      receiptTotalPriceTrigger
      dayTrigger
      surpriseNumber
      multiplierNumber
      productTrigger {
        id
        name
        price
        code
      }
      ruleRewardConfiguration {
        id
        loneStarReward
        centorReward
        dollarReward
        period
        purchaseRewardRate
        minPurchaseForReward
        rewardOnceHitLimit
        allowMultipleReward
        multipleRewardCount
      }
      status
      campaignType
      boostedTykchaseCampaign {
        id
        title
        description
        holiday {
          id
          title
          description
          celebrationDate
          createdAt
          updatedAt
        }
        startDate
        endDate
        coverPhotos {
          id
          source
        }
        defaultCoverPhoto
        triggerType
        receiptTotalPriceTrigger
        dayTrigger
        surpriseNumber
        multiplierNumber
        productTrigger {
          id
          name
          price
          code
        }
        ruleRewardConfiguration {
          id
          loneStarReward
          centorReward
          dollarReward
          period
          purchaseRewardRate
          minPurchaseForReward
          rewardOnceHitLimit
          allowMultipleReward
          multipleRewardCount
        }
        status
        campaignType
      }
    }
  }
`;

export const GET_MEMBERSHIP = gql`
  query ($id: String!) {
    getMembershipWithRules(id: $id) {
      payload {
        id
        title
        description
        locationsCountLimit
        locationSetupFee
        mandatoryDepositAmount
        rules {
          id
          title
          isActive
          category {
            id
            title
          }
        }
      }
      status
      message
      errorMessageId
    }
  }
`;

export const GET_ALL_RULES = gql`
  query GetAllRules {
    getAllCategoriesWithRules {
      title
      tykchaseRule {
        id
        internalId
        title
        isStagewoodPerk
        isActive
        ruleName
      }
    }
  }
`;

export const GET_USER_PAYMENT_METHODS = gql`
  query ($userId: String!, $isDefault: Boolean) {
    userPaymentMethods(userId: $userId, isDefault: $isDefault) {
      response {
        success
        message
      }
      data
    }
  }
`;

export const GET_USER_TRANSACTIONS = gql`
  query fetchUserTransactions($userId: String!, $limit: Int, $offset: Int) {
    fetchUserTransactions(userId: $userId, limit: $limit, offset: $offset) {
      status
      message
      errorMessageId
      payload {
        id
        amount
        createdAt
        currency
        serviceName
        updatedAt
      }
    }
  }
`;

export const GET_ALL_BUSINESS_PROFILES = gql`
  query accountBusinessProfiles($accountId: String!) {
    accountBusinessProfiles(accountId: $accountId) {
      status
      message
      errorMessageId
      payload {
        id
        profilePicture
        email
        name
        businessCategories {
          id
          title
          isActive
        }
      }
    }
  }
`;

export const GET_ALL_REWARD_DISTRIBUTION = gql`
  query getAllRewardDistribution($userId: String!) {
    getAllRewardDistribution(userId: $userId) {
      centorsRate
      loneStarsRate
      jackpotAwardRate
      promoterAwardRate
    }
  }
`;

export const GET_ALL_AVAILABLE_FEATURES = gql`
  query getAllAvailableFeatures($userId: String!) {
    getAllAvailableFeatures(userId: $userId) {
      featureId
    }
  }
`;

export const GET_ALL_QR_CODES = gql`
  query getAllQrCodes {
    getAllQrCodes {
      id
      name
      qrType
      url
      tykchaseCampaign {
        title
        defaultCoverPhoto
        endDate
      }
    }
  }
`;

export const GET_QR_CODE = gql`
  query ($id: String!) {
    getQrCode(id: $id) {
      qrCodePayload {
        id
        name
        qrType
        url
        tykchaseCampaign {
          id
          title
          defaultCoverPhoto
          endDate
        }
      }
    }
  }
`;

export const GET_DEFAULT_ENROLL_QR_CODE = gql`
  query ($userId: String!) {
    getDefaultEnrollQrCode(userId: $userId) {
      qrCodePayload {
        id
        isActive
      }
    }
  }
`;

export const GET_IROHA_ACCOUNT_ASSETS = gql`
  query ($userId: String!) {
    getIrohaAccountAssets(userId: $userId) {
      status
      message
      payload {
        accountId
        assetId
        balance
      }
    }
  }
`;

export const GET_TYKCHASE_CONFIGURATION_QUERY = gql`
  query getTykchaseConfigurationQuery($userId: String!) {
    getTykchaseConfiguration(userId: $userId) {
      errorMessageId
      payload {
        customTykAwardsDistribution {
          jackpotAwardRate
          promoterAwardRate
          centorsRate
          loneStarsRate
          minimumLoneStarAward
          maximumLoneStarPerEvent
          maximumLoneStarRateOnDemand
        }
        tykchaseGlobalConfig {
          id
          dollarPerTykcoinRate
          centorPerDollarRate
          loneStarPerDollarRate
          loneStarOnDemandPerDollarRate
          tykAwardsDistribution {
            tykBrokerComission
            stagewoodComission
            jackpotAwardRate
            promoterAwardRate
            centorsRate
            loneStarsRate
            minimumLoneStarAward
            maximumLoneStarPerEvent
            maximumLoneStarRateOnDemand
            isPrincipal
          }
        }
      }
      status
      message
    }
  }
`;

export const GET_USER_MONTHLY_TOTAL_CENTOR_AMOUNT = gql`
  query getMonthlyTotalCentorAmount($userId: String!) {
    getMonthlyTotalCentorAmount(userId: $userId) {
      status
      message
      payload
      errorMessageId
    }
  }
`;

export const GET_USER_MONTHLY_SCANNED_RECEIPTS = gql`
  query getMonthlyScannedReceipts($userId: String!) {
    getMonthlyScannedReceipts(userId: $userId) {
      status
      message
      errorMessageId
      payload {
        scannedReceipts
        totalScannedReceipts
      }
    }
  }
`;

export const GET_BROKERS_BY_MATCH = gql`
  query findTykBrokersByNameOrEmail($inputText: String!) {
    findTykBrokersByNameOrEmail(inputText: $inputText) {
      id
      business {
        email
        username
      }
    }
  }
`;
