import { useLazyQuery } from "@apollo/client";
import { useState, useEffect } from "react";
import { GET_ALL_CAMPAIGNS } from "../../../../../apollo/queries";
import { ICategory, QrType } from "../../../types";

const useCategory = (): [ICategory | null, (key: any) => void] => {
  const [category, setCategory] = useState<ICategory | null>(null);

  const [getCampaings] = useLazyQuery(GET_ALL_CAMPAIGNS, {
    onCompleted: (getCampaingsData) => {
      setCategory({ type: QrType.CAMPAIGN, triggers: getCampaingsData.getAllCampaigns });
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    getCampaings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCategoryChange = (key) => {
    const triggerMap = {
      [QrType.CAMPAIGN]: () => getCampaings(),
      // TODO: replace methods for EXPERIENCES and JACKPOT when these features will be implemented
      [QrType.EXPERIENCES]: () => setCategory({ type: QrType.EXPERIENCES, triggers: [] }),
      [QrType.JACKPOT]: () => setCategory({ type: QrType.JACKPOT, triggers: [] }),
    };
    triggerMap[key]();
  };

  return [category, onCategoryChange];
};

export default useCategory;
